import {OBJ} from '../../../obj';
import {ElObj, ElObjOpts} from '../../../elobj';
import {DataTable} from './datatable';
import {Body} from './body';

@OBJ
export class Table extends ElObj {
	static tagName: TagName = 'table';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__table',
		);
		super(opts);
		const obj = new Body({
			parent: this,
		});
		obj.show();
	}

	body(): Body | null {
		for (const child of this.children()) {
			if (child instanceof Body) {
				return child;
			}
		}
		return null;
	}

	datatable(): DataTable | null {
		let par = this.parentEl();
		while (par) {
			if (par instanceof DataTable) {
				return par;
			}
			par = par.parentEl();
		}
		return null;
	}
}
