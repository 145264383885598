import {OBJ, SLOT} from '../../obj';
import {Layout, LayoutOpts, LayoutPrivate} from './layout';
import {FancyList} from '../../ui/list';
import {RowItem} from './row';
import {ElObj} from '../../elobj';

export class RowLayoutPrivate extends LayoutPrivate {
	list: FancyList | null;

	constructor() {
		super();
		this.list = null;
	}

	init(opts: Partial<RowLayoutOpts>): void {
		super.init(opts);
		const q = this.q;
		this.list = new FancyList({
			twoLine: true,
		});
		q.addEl(this.list, 12);
	}

	get q(): RowLayout {
		return <RowLayout>super.q;
	}
}

export interface RowLayoutOpts extends LayoutOpts {
	dd: RowLayoutPrivate;
}

@OBJ
export class RowLayout extends Layout {
	constructor(opts: Partial<RowLayoutOpts> = {}) {
		opts.dd = opts.dd || new RowLayoutPrivate();
		super(opts);
	}

	addItem(item: RowItem): void {
		const d = this.d;
		if (d.list) {
			super.addItem(item);
			d.list.addItem(item);
		}
	}

	@SLOT
	clear(): void {
		const d = this.d;
		if (d.list) {
			d.list.clear();
		}
	}

	get d(): RowLayoutPrivate {
		return <RowLayoutPrivate>super.d;
	}

	newItem(): RowItem {
		return new RowItem();
	}

	takeEl(index: number): ElObj | null {
		return null;
	}

	takeItem(index: number): RowItem | null {
		const d = this.d;
		if (d.list) {
			return <RowItem | null>d.list.takeItem(index);
		}
		return null;
	}
}
