import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {Obj, OBJ, SIGNAL} from '../obj';
import {ToolButton} from './toolbutton';
import {Icon} from './icon';
import {list} from '../tools';
import {Action, ElObjAction} from '../action';
import {ActionEvt} from '../evt';
import {assert, clamp} from '../util';
import {AlignmentFlag, ElAttr} from '../constants';

export class ToolBarPrivate extends ElObjPrivate {
	align: AlignmentFlag;
	items: list<ToolBarItem>;

	constructor() {
		super();
		this.align = 0;
		this.items = new list();
	}

	createItem(action: Action): ToolBarItem {
		const q = this.q;
		let customObj = false;
		let obj: ElObj | null = null;
		const objAction = (action instanceof ElObjAction) ?
			action :
			null;
		if (objAction) {
			obj = objAction.requestElObj(q);
			if (obj) {
				customObj = true;
			}
		} else if (action.isSeparator()) {
			obj = new ToolBarSeparator({parent: q});
			obj.addAction(action);
		}
		if (!obj) {
			const btn = new ToolButton({
				parent: q,
			});
			btn.setDefaultAction(action);
			Obj.connect(
				btn, 'triggered',
				q, 'actionTriggered',
			);
			obj = btn;
		}
		obj.hide();
		const item = new ToolBarItem(obj);
		item.action = action;
		item.customObj = customObj;
		return item;
	}

	indexOf(action: Action | null): number {
		if (!action) {
			return -1;
		}
		for (let i = 0; i < this.items.size(); ++i) {
			if (this.items.at(i).action === action) {
				return i;
			}
		}
		return -1;
	}

	init(opts: Partial<ToolBarOpts>): void {
		super.init(opts);
		if (opts.alignment !== undefined) {
			this.align = opts.alignment;
		}
		this.setStyleForAlignment(this.align);
	}

	insertAction(index: number, action: Action): void {
		index = clamp(
			0,
			index,
			this.items.size(),
		);
		const item = this.createItem(action);
		if (item) {
			const q = this.q;
			this.items.insert(index, item);
			if (item.obj && !(q.isHidden() && q.testAttribute(ElAttr.ExplicitShowHide))) {
				item.obj.setVisible(true);
			}
		}
	}

	get q(): ToolBar {
		return <ToolBar>super.q;
	}

	setStyleForAlignment(align: AlignmentFlag): void {
		const q = this.q;
		q.setClass(
			Boolean(align & AlignmentFlag.AlignLeft),
			'align-left',
		);
		q.setClass(
			Boolean(align & AlignmentFlag.AlignCenter),
			'align-center',
		);
		q.setClass(
			Boolean(align & AlignmentFlag.AlignRight),
			'align-right',
		);
	}

	takeAt(index: number): ToolBarItem | null {
		if (!((index >= 0) && (index < this.items.size()))) {
			return null;
		}
		const item = this.items.takeAt(index);
		const objAxn = (item.action && (item.action instanceof ElObjAction)) ?
			item.action :
			null;
		if (objAxn && item.customObj) {
			objAxn.releaseElObj(item.obj);
		} else {
			if (item.obj) {
				item.obj.hide();
				item.obj.deleteLater();
			}
		}
		return item;
	}
}

export interface ToolBarOpts extends ElObjOpts {
	alignment: AlignmentFlag;
	dd: ToolBarPrivate;
}

@OBJ
export class ToolBar extends ElObj {
	constructor(opts: Partial<ToolBarOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-toolbar',
		);
		opts.dd = opts.dd || new ToolBarPrivate();
		super(opts);
	}

	protected actionEvent(event: ActionEvt): void {
		const d = this.d;
		const axn = event.action();
		const objAxn = (axn instanceof ElObjAction) ?
			axn :
			null;
		switch (event.type()) {
			case ActionEvt.ActionAdded: {
				assert(!objAxn || d.indexOf(objAxn) < 0, 'Objects cannot be inserted multiple times');
				let idx = this.children().size();
				if (event.before()) {
					idx = d.indexOf(event.before());
					assert(idx >= 0, 'insertAction: Internal error');
				}
				d.insertAction(idx, axn);
				break;
			}
			case ActionEvt.ActionChanged: {
				break;
			}
			case ActionEvt.ActionRemoved: {
				const idx = d.indexOf(axn);
				if (idx >= 0) {
					const item = d.takeAt(idx);
					if (item) {
						item.destroy();
					}
				}
				break;
			}
			default: {
				assert(false, 'actionEvent: Internal error');
			}
		}
	}

	@SIGNAL
	protected actionTriggered(action: Action): void {
	}

	addAction(action: Action): Action;
	addAction(text: string): Action;
	addAction(icon: Icon, text?: string): Action;
	addAction(a: Action | Icon | string, b?: string): Action | void {
		let axn: Action;
		if (typeof a === 'string') {
			// SIG: addAction(text: string): Action
			axn = new Action(a, this);
		} else if (a instanceof Action) {
			// SIG: addAction(action: Action): void
			axn = a;
		} else {
			// SIG: addAction(icon: Icon, text: string): Action
			axn = new Action(a, <string>b, this);
		}
		super.addAction(axn);
		return axn;
	}

	addObj(obj: ElObj | null): Action {
		const axn = new ElObjAction({
			parent: this,
		});
		axn.setDefaultElObj(obj);
		axn.d.autoCreated = true;
		this.addAction(axn);
		return axn;
	}

	addSeparator(): Action {
		const axn = new Action({parent: this});
		axn.setSeparator(true);
		this.addAction(axn);
		return axn;
	}

	alignment(): AlignmentFlag {
		return this.d.align;
	}

	@SIGNAL
	protected alignmentChanged(align: AlignmentFlag): void {
	}

	clear(): void {
		for (const axn of this.actions()) {
			this.removeAction(axn);
		}
	}

	get d(): ToolBarPrivate {
		return <ToolBarPrivate>super.d;
	}

	insertObj(before: Action | null, obj: ElObj | null): Action {
		const axn = new ElObjAction({
			parent: this,
		});
		axn.setDefaultElObj(obj);
		axn.d.autoCreated = true;
		this.insertAction(before, axn);
		return axn;
	}

	insertSeparator(before: Action | null): Action {
		/**
		 * Inserts a separator into the toolbar in front of the toolbar item
		 * associated with the `before` action.
		 */
		const axn = new Action({parent: this});
		axn.setSeparator(true);
		this.insertAction(before, axn);
		return axn;
	}

	objForAction(action: Action): ElObj | null {
		const d = this.d;
		for (const item of d.items) {
			if (item.action === action) {
				return item.obj;
			}
		}
		return null;
	}

	setAlignment(align: AlignmentFlag): void {
		const d = this.d;
		if (align === d.align) {
			return;
		}
		d.align = align;
		d.setStyleForAlignment(d.align);
		this.alignmentChanged(d.align);
	}
}

class ToolBarItem {
	action: Action | null;
	customObj: boolean;
	obj: ElObj | null;

	constructor(obj: ElObj | null) {
		this.action = null;
		this.customObj = false;
		this.obj = obj;
	}

	destroy(): void {
		this.action = null;
		this.obj = null;
	}
}

interface ToolBarSeparatorOpts extends ElObjOpts {
	parent: ToolBar
}

@OBJ
class ToolBarSeparator extends ElObj {
	constructor(opts: Partial<ToolBarSeparatorOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-toolbar-separator',
		);
		super(opts);
	}

	protected actionEvent(event: ActionEvt): void {
		if (event.type() === ActionEvt.ActionChanged) {
			this.setVisible(
				event.action().isVisible(),
			);
		}
	}
}
