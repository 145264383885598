import mapboxgl from 'mapbox-gl';

import {Evt} from '../../../evt';
import {bind, setFlag} from '../../../util';
import {OBJ, SIGNAL, SLOT} from '../../../obj';
import {InteractiveMap, MapEventType} from '../map';
import {ElObj, ElObjOpts, ElObjPrivate} from '../../../elobj';
import {
	Cursor,
	MapControlType,
	InteractiveMapControlPosition,
} from '../../../constants';

const cursorClassName: Record<Cursor, string> = {
	[Cursor.Default]: '',
	[Cursor.Add]: 'lb-cursor--add',
	[Cursor.Crosshair]: 'lb-cursor--crosshair',
	[Cursor.Drag]: 'lb-cursor--drag',
	[Cursor.Move]: 'lb-cursor--move',
	[Cursor.None]: 'lb-cursor--none',
	[Cursor.Pointer]: 'lb-cursor--pointer',
};

export class InteractiveMapControlPrivate extends ElObjPrivate {
	active: boolean;
	cursor: Cursor;
	map: mapboxgl.Map | null;
	pos: InteractiveMapControlPosition;

	constructor() {
		super();
		this.active = false;
		this.cursor = Cursor.Default;
		this.map = null;
		this.pos = InteractiveMapControlPosition.TopRight;
	}

	init(opts: Partial<InteractiveMapControlOpts>): void {
		super.init(opts);
		if (opts.position !== undefined) {
			this.pos = opts.position;
		}
	}

	get q(): InteractiveMapControl {
		return <InteractiveMapControl>super.q;
	}

	setMapClassName(add: boolean, ...token: Array<string>): void {
		const nonEmptyTokens: Array<string> = token
			.map(x => x.trim())
			.filter(x => x.length > 0);
		if (nonEmptyTokens.length < 1) {
			return;
		}
		if (!this.map) {
			return;
		}
		const el = this.map.getCanvasContainer();
		if (add) {
			el.classList.add(...token);
		} else {
			el.classList.remove(...token);
		}
	}
}

export interface InteractiveMapControlOpts extends ElObjOpts {
	dd: InteractiveMapControlPrivate;
	position: InteractiveMapControlPosition;
}

@OBJ
export class InteractiveMapControl extends ElObj implements mapboxgl.IControl {
	constructor(opts: Partial<InteractiveMapControlOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mapboxgl-ctrl',
			'mapboxgl-ctrl-group',
			'lb-map-control',
		);
		opts.dd = opts.dd || new InteractiveMapControlPrivate();
		super(opts);
	}

	@SLOT
	activate(): void {
		this.setActive(true);
	}

	@SIGNAL
	protected activated(type: MapControlType): void {
	}

	@SIGNAL
	protected activationChanged(type: MapControlType, active: boolean): void {
	}

	protected changeEvent(event: Evt): void {
		super.changeEvent(event);
		if ((event.type() === Evt.EnabledChange) && this.isActive() && !this.isEnabled()) {
			this.deactivate();
		}
	}

	cursor(): Cursor {
		return this.d.cursor;
	}

	get d(): InteractiveMapControlPrivate {
		return <InteractiveMapControlPrivate>super.d;
	}

	@SLOT
	deactivate(): void {
		this.setActive(false);
	}

	@SIGNAL
	protected deactivated(type: MapControlType): void {
	}

	destroy(): void {
		this.setCursor(Cursor.Default);
		this.d.map = null;
		super.destroy();
	}

	element(): HTMLElement {
		return <HTMLElement>super.element();
	}

	getDefaultPosition(): string {
		return this.position();
	}

	isActive(): boolean {
		return this.d.active;
	}

	protected mapDataEvent(event: mapboxgl.MapDataEvent): void {
	}

	@bind
	protected mapEvent(event: mapboxgl.MapboxEvent<unknown>): void {
		switch (event.type) {
			case MapEventType.Data: {
				this.mapDataEvent(<mapboxgl.MapDataEvent>event);
				break;
			}
			case MapEventType.StyleDataLoading: {
				this.mapStyleDataLoadingEvent(<mapboxgl.MapDataEvent>event);
				break;
			}
			case MapEventType.Load: {
				this.mapLoadEvent(<mapboxgl.MapboxEvent>event);
				break;
			}
		}
	}

	protected mapLoadEvent(event: mapboxgl.MapboxEvent): void {
	}

	protected mapStyleDataLoadingEvent(event: mapboxgl.MapDataEvent): void {
	}

	onAdd(map: mapboxgl.Map): HTMLElement {
		const d = this.d;
		d.map = map;
		this.setCursor(d.cursor);
		return this.element();
	}

	onRemove(map: mapboxgl.Map): void {
		const d = this.d;
		this.setCursor(Cursor.Default);
		d.map = null;
	}

	parentMap(): InteractiveMap | null {
		let curr: ElObj | null = this.parentEl();
		while (curr) {
			if (curr instanceof InteractiveMap) {
				return curr;
			}
			curr = curr.parentEl();
		}
		return null;
	}

	position(): InteractiveMapControlPosition {
		return this.d.pos;
	}

	@SLOT
	setActive(active: boolean): void {
		const d = this.d;
		if (active === d.active) {
			return;
		}
		d.active = active;
		this.setClass(
			d.active,
			'lb-map-control--active',
		);
		const par = this.parentMap();
		if (!par) {
			return;
		}
		const myType = par.mapControlType(this);
		const pd = par.d;
		if (d.active && par.hasActiveMapControl()) {
			// We're activating. First, deactivate any currently active
			// controls.
			const activeType = par.activeMapControlType();
			// Sanity check
			if (activeType !== myType) {
				par.setMapControlActive(
					activeType,
					false,
				);
			}
		}
		pd.activeCtrls = setFlag(
			pd.activeCtrls,
			myType,
			active,
		);
		this.activationChanged(
			myType,
			d.active,
		);
		if (d.active) {
			this.activated(myType);
		} else {
			this.deactivated(myType);
		}
	}

	setCursor(cursor: Cursor): void {
		const d = this.d;
		if (cursor === d.cursor) {
			return;
		}
		if (d.cursor !== Cursor.Default) {

			d.setMapClassName(
				false,
				cursorClassName[d.cursor],
			);
		}
		d.cursor = cursor;
		if (d.cursor !== Cursor.Default) {
			d.setMapClassName(
				true,
				cursorClassName[d.cursor],
			);
		}
	}

	setPosition(pos: InteractiveMapControlPosition): void {
		const d = this.d;
		if (pos === d.pos) {
			return;
		}
		d.pos = pos;
	}
}
