import {Obj, OBJ, SLOT} from '../../obj';
import {list} from '../../tools';
import {Proj} from '../../models';
import {ListItem, ListView, ListViewOpts, ListViewPrivate} from '../../ui/itemviews';
import {Layout} from './layout';
import {CardLayout} from './cardlayout';
import {RowLayout} from './rowlayout';
import {AlignmentFlag, CheckState, ProjectFilterParam, ProjectOption, SortOrder} from '../../constants';
import {IProjectListOpt, svc} from '../../request';
import {numberFormat} from '../../util';
import {ToolBar} from '../../ui/toolbar';
import {GridLayout} from '../../ui/gridlayout';
import {Icon} from '../../ui/icon';
import {FancyPushButton} from '../../ui/pushbutton';
import {ModelIndex} from '../../abstractitemmodel';
import {Action} from '../../action';
import {SegmentedButtonGroup} from '../../ui/segmentedbutton';
import {Menu} from '../../ui/menu';
import {App} from '../../app';

enum LayoutMode {
	NoMode,
	CardMode,
	RowMode,
}

type LayoutCfg = {
	cls: typeof Layout;
	iconName: string;
	mode: LayoutMode;
}
const layoutCfgs: Array<LayoutCfg> = [
	{
		cls: RowLayout,
		iconName: 'view_list',
		mode: LayoutMode.RowMode,
	},
	{
		cls: CardLayout,
		iconName: 'view_module',
		mode: LayoutMode.CardMode,
	},
];

enum MenuContext {
	SortField = 1,
	Project,
}

type MenuOpt = {label: string; value: string;};
type MenuCfg = {
	context: MenuContext;
	opts: list<MenuOpt>;
	project: Proj | null;
}

export class ProjectListViewPrivate extends ListViewPrivate {
	checkedAxns: list<Action>;
	layout: Layout | null;
	listFilter: ProjectFilterParam;
	menu: Menu | null;
	menuCfg: MenuCfg | null;
	mode: LayoutMode;
	modeToggle: SegmentedButtonGroup | null;
	projects: list<Proj>;
	sortFieldBtn: FancyPushButton | null;
	sortOrderAxn: Action | null;
	toolbar: ToolBar | null;
	ui: IUI;

	constructor() {
		super();
		this.checkedAxns = new list();
		this.layout = null;
		this.listFilter = ProjectFilterParam.NotArchived;
		this.menu = null;
		this.menuCfg = null;
		this.mode = LayoutMode.NoMode;
		this.modeToggle = null;
		this.projects = new list();
		this.sortFieldBtn = null;
		this.sortOrderAxn = null;
		this.toolbar = null;
		this.ui = staticIui;
	}

	async addLayoutItem(row: number): Promise<void> {
		if (!this.layout || (row < 0) || (row >= this.projects.size())) {
			return;
		}
		const q = this.q;
		const item = q.item(row);
		if (!item) {
			return;
		}
		let layoutItem = this.layout.item(row);
		if (!layoutItem) {
			layoutItem = this.layout.newItem();
			this.layout.addItem(layoutItem);
		}
		layoutItem.setBusy(true);
		const proj = this.projects.at(row);
		const listUrl = proj.absoluteListUrl().trim();
		if ((listUrl.length > 0) && !proj.isPaymentRequired()) {
			layoutItem.setPrimaryButtonText('Download');
		} else {
			layoutItem.setPrimaryButtonText('Pay');
		}
		const url = proj.absoluteUrl();
		if (url.length > 0) {
			layoutItem.setUrl(url);
		}
		layoutItem.setTitle(proj.title());
		const invId = proj.invoiceId();
		if (invId) {
			const inv = await svc.invoice.get(
				invId,
				false,
				proj.slug(),
			);
			layoutItem.setSubTitle(
				`Mailing addresses: ${numberFormat(inv.totalQuantity)}`,
			);
		}
		const cfgId = proj.geoMapConfigurationId();
		if (cfgId) {
			const cfg = await svc.map.get(cfgId);
			if (cfg.imageUrl.length > 0) {
				layoutItem.setImage(cfg.imageUrl);
			}
		}
		layoutItem.setBusy(false);
	}

	async addLayoutItems(parent: ModelIndex, first: number, last: number): Promise<void> {
		if (!this.layout) {
			return;
		}
		if (this.projects.isEmpty()) {
			return;
		}
		for (let row = first; row <= last; ++row) {
			if (row >= this.projects.size()) {
				continue;
			}
			await this.addLayoutItem(row);
		}
	}

	currentSortFieldIndex(): number {
		return this.ui.sortFieldNames.findIndex(
			x => (x[0] === this.ui.projectListSortField),
		);
	}

	currentSortOrder(): SortOrder {
		return this.ui.projectListSortAsc ?
			SortOrder.AscendingOrder :
			SortOrder.DescendingOrder;
	}

	currentSortOrderIconName(): string {
		return (this.currentSortOrder() === SortOrder.AscendingOrder) ?
			'arrow_upward' :
			'arrow_downward';
	}

	async fetchProjects(opts: Partial<IProjectListOpt> = {}): Promise<list<Proj>> {
		if (!opts.sortField && (this.ui.projectListSortField.length > 0)) {
			opts.sortField = this.ui.projectListSortField;
		}
		if (!opts.sortOrder) {
			opts.sortOrder = (this.currentSortOrder() === SortOrder.AscendingOrder) ?
				'asc' :
				'desc';
		}
		return await Proj.list(opts);
	}

	init(opts: Partial<ProjectListViewOpts>): void {
		super.init(opts);
		if (opts.listFilter !== undefined) {
			this.listFilter = opts.listFilter;
		}
		const q = this.q;
		const layout = new GridLayout({
			parent: q,
		});
		this.toolbar = new ToolBar();
		this.toolbar.setAlignment(AlignmentFlag.AlignRight);
		layout.addEl(this.toolbar, 12);
		const mergeAxn = this.toolbar.addAction(
			new Icon({
				name: 'merge_type',
				outlined: true,
			}),
		);
		this.checkedAxns.append(mergeAxn);
		mergeAxn.setVisible(false);
		Obj.connect(
			mergeAxn, 'triggered',
			q, 'mergeProjects',
		);
		mergeAxn.setToolTip('Merge');
		let iconName: string;
		let toolTip: string;
		if (this.listFilter === ProjectFilterParam.Archived) {
			iconName = 'unarchive';
			toolTip = 'Restore';
		} else {
			iconName = 'archive';
			toolTip = 'Archive';
		}
		const archiveAxn = this.toolbar.addAction(
			new Icon({
				name: iconName,
				outlined: true,
			}),
		);
		archiveAxn.setToolTip(toolTip);
		this.checkedAxns.append(archiveAxn);
		archiveAxn.setVisible(false);
		Obj.connect(
			archiveAxn, 'triggered',
			q, 'archiveProjects',
		);
		const checkedSep = this.toolbar.addSeparator();
		checkedSep.setVisible(false);
		this.checkedAxns.append(checkedSep);
		this.sortFieldBtn = new FancyPushButton();
		this.toolbar.addObj(
			this.sortFieldBtn,
		);
		Obj.connect(
			this.sortFieldBtn, 'clicked',
			q, 'openSortFieldOptionMenu',
		);
		this.sortOrderAxn = this.toolbar.addAction(
			new Icon(),
		);
		Obj.connect(
			this.sortOrderAxn, 'triggered',
			q, 'sortOrderClicked',
		);
		this.toolbar.addSeparator();
		this.modeToggle = new SegmentedButtonGroup({
			multiSelect: false,
		});
		this.toolbar.addObj(
			this.modeToggle,
		);
		Obj.connect(
			this.modeToggle, 'clicked',
			q, 'modeToggled',
		);
	}

	layoutCfg(index: number): LayoutCfg | null {
		if ((index >= 0) && (index < layoutCfgs.length)) {
			return layoutCfgs[index];
		}
		return null;
	}

	layoutCfgForMode(mode: LayoutMode): LayoutCfg | null {
		return this.layoutCfg(
			layoutCfgs.findIndex(
				x => (x.mode === mode),
			),
		);
	}

	layoutModeForLayoutStyle(style: string): LayoutMode {
		switch (style) {
			case 'list': {
				return LayoutMode.RowMode;
			}
			case 'tile': {
				return LayoutMode.CardMode;
			}
			default: {
				return LayoutMode.NoMode;
			}
		}
	}

	layoutStyleForMode(mode: LayoutMode): string {
		switch (mode) {
			case LayoutMode.CardMode: {
				return 'tile';
			}
			case LayoutMode.RowMode: {
				return 'list';
			}
			default: {
				return '';
			}
		}
	}

	nextSortOrder(): SortOrder {
		return (this.currentSortOrder() === SortOrder.AscendingOrder) ?
			SortOrder.DescendingOrder :
			SortOrder.AscendingOrder;
	}

	projectOptions(proj: Proj): list<ProjectOption> {
		const rv = new list<ProjectOption>();
		if (this.mode === LayoutMode.CardMode) {
			rv.extend([
				ProjectOption.Copy,
				proj.isArchived() ?
					ProjectOption.Restore :
					ProjectOption.Archive,
			]);
			return rv;
		}
		if (proj.isPaymentRequired()) {
			if (proj.isPaymentAllowed()) {
				rv.append(
					ProjectOption.Pay,
				);
			}
		} else {
			const url = proj.absoluteListUrl();
			if (url.length > 0) {
				rv.append(
					ProjectOption.Download,
				);
			}
		}
		rv.extend([
			ProjectOption.Copy,
			proj.isArchived() ?
				ProjectOption.Restore :
				ProjectOption.Archive,
		]);
		return rv;
	}

	get q(): ProjectListView {
		return <ProjectListView>super.q;
	}

	async setNextUiSortOrder(): Promise<void> {
		await this.updateUiCfg({
			projectListSortAsc: this.nextSortOrder() === SortOrder.AscendingOrder,
		});
	}

	setUi(ui: IUI): void {
		this.ui = ui;
		const fieldIdx = this.currentSortFieldIndex();
		if ((fieldIdx >= 0) && this.sortFieldBtn) {
			this.sortFieldBtn.setText(
				this.ui.sortFieldNames[fieldIdx][1],
			);
		}
		if (this.sortOrderAxn) {
			const icon = this.sortOrderAxn.icon();
			icon.setName(
				this.currentSortOrderIconName(),
			);
			this.sortOrderAxn.setIcon(icon);
		}
		if (this.modeToggle) {
			const idx = layoutCfgs.findIndex(
				x => (x.mode === this.mode),
			);
			if (idx >= 0) {
				this.modeToggle.selectSegment(idx);
			}
		}
	}

	async setup(): Promise<void> {
		super.setup();
		const m = this.listModel();
		const q = this.q;
		Obj.connect(
			m, 'modelAboutToBeReset',
			q, 'clearLayout',
		);
		this.setUi(
			await this.uiCfg(),
		);
		const mode = this.layoutModeForLayoutStyle(
			this.ui.projectListLayoutStyle,
		);
		if (this.modeToggle) {
			this.modeToggle.setSegments(
				layoutCfgs.map(
					x => ({
						icon: x.iconName,
						selected: x.mode === mode,
					}),
				),
			);
		}
		this.projects = await q.projects();
		q.setLayoutMode(mode);
	}

	async uiCfg(): Promise<IUI> {
		return await svc.ui.get();
	}

	async updateUiCfg(data: Partial<IUI>, notify: boolean = true): Promise<IUI> {
		const curr = await this.uiCfg();
		const next = {
			...curr,
			...data,
		};
		const ui = await svc.ui.update(next);
		this.setUi(ui);
		if (notify) {
			await this.q.uiCfgChanged();
		}
		return ui;
	}

	async updateUiLayoutStyleForCurrentMode(): Promise<void> {
		const style = this.layoutStyleForMode(this.mode);
		if ((this.ui.projectListLayoutStyle.length > 0) && (this.ui.projectListLayoutStyle === style)) {
			return;
		}
		await this.updateUiCfg(
			{
				projectListLayoutStyle: style,
			},
			false,
		);
	}
}

export interface ProjectListViewOpts extends ListViewOpts {
	dd: ProjectListViewPrivate;
	listFilter: ProjectFilterParam;
}

@OBJ
export class ProjectListView extends ListView {
	constructor(opts: Partial<ProjectListViewOpts> = {}) {
		opts.dd = opts.dd || new ProjectListViewPrivate();
		super(opts);
	}

	@SLOT
	async archiveProjects(): Promise<void> {
		const d = this.d;
		if (d.projects.isEmpty()) {
			return;
		}
		const checked = this.checkedItems();
		if (checked.isEmpty()) {
			return;
		}
		for (const item of checked) {
			const idx = this.indexFromItem(item);
			if (!idx.isValid() || (idx.row() >= d.projects.size())) {
				continue;
			}
			const row = idx.row();
			const layoutItem = d.layout && d.layout.item(row);
			if (layoutItem) {
				layoutItem.setBusy(true);
			}
			await this.setArchived(
				d.projects.at(idx.row()),
				true,
			);
		}
	}

	protected checkedItems(): list<ListItem> {
		const mdl = this.d.listModel();
		const rv = new list<ListItem>();
		for (let row = 0; row < mdl.rowCount(); ++row) {
			const item = this.item(row);
			if (!item) {
				continue;
			}
			const state = item.checkState();
			if (state === CheckState.Checked) {
				rv.append(item);
			}
		}
		return rv;
	}

	@SLOT
	clearLayout(): void {
		const d = this.d;
		if (d.layout) {
			d.layout.clear();
		}
	}

	async clone(proj: Proj): Promise<void> {
		const other = await proj.clone();
		window.location.assign(
			other.absoluteUrl(),
		);
	}

	get d(): ProjectListViewPrivate {
		return <ProjectListViewPrivate>super.d;
	}

	@SLOT
	protected dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles: Array<number> = []): void {
		super.dataChanged(topLeft, bottomRight, roles);
		this.setCheckedActionsVisible(
			this.checkedItems().size() > 0,
		);
	}

	destroy(): void {
		const d = this.d;
		d.layout = null;
		d.checkedAxns.clear();
		d.projects.clear();
		d.toolbar = null;
		super.destroy();
	}

	@SLOT
	protected destroyMenu(): void {
		const d = this.d;
		if (d.menu) {
			d.menu.destroy();
		}
		d.menu = null;
		d.menuCfg = null;
	}

	downloadList(proj: Proj): void {
		const url = proj.absoluteListUrl();
		if (url.length > 0) {
			window.location.assign(url);
		}
	}

	indexFromProject(proj: Proj | null): ModelIndex {
		if (!proj) {
			return new ModelIndex();
		}
		const d = this.d;
		const row = d.projects.indexOf(proj);
		if (row < 0) {
			return new ModelIndex();
		}
		return d.listModel().index(row, 0);
	}

	@SLOT
	protected menuOptionSelected(optIdx: number): void {
		const d = this.d;
		const cfg = d.menuCfg;
		if (!cfg) {
			this.destroyMenu();
			return;
		}
		const ctx = cfg.context;
		const proj = cfg.project;
		const opts = cfg.opts;
		d.menuCfg = null;
		this.destroyMenu();
		if ((optIdx < 0) || (optIdx >= opts.size()) || ((ctx === MenuContext.Project) && !proj)) {
			return;
		}
		const opt = opts.at(optIdx);
		if ((ctx === MenuContext.Project) && proj) {
			switch (opt.value) {
				case ProjectOption.Download: {
					this.downloadList(proj);
					break;
				}
				case ProjectOption.Pay: {
					// Start payment stuff
					break;
				}
				case ProjectOption.Copy: {
					this.clone(proj);
					break;
				}
				case ProjectOption.Archive: {
					this.setArchived(proj, true);
					break;
				}
				case ProjectOption.Restore: {
					this.setArchived(proj, false);
					break;
				}
			}
		} else if (ctx === MenuContext.SortField) {
			const fieldName = opt.value;
			if (d.ui.projectListSortField === fieldName) {
				return;
			}
			d.updateUiCfg({
				projectListSortField: fieldName,
			});
		}
	}

	@SLOT
	async mergeProjects(): Promise<void> {
		const d = this.d;
		if (d.projects.isEmpty()) {
			return;
		}
		const checked = this.checkedItems();
		if (checked.isEmpty()) {
			return;
		}
		const slugs = new list<string>();
		for (const item of checked) {
			const idx = this.indexFromItem(item);
			if (!idx.isValid() || (idx.row() >= d.projects.size())) {
				continue;
			}
			const row = idx.row();
			const layoutItem = d.layout && d.layout.item(row);
			if (layoutItem) {
				layoutItem.setBusy(true);
			}
			slugs.append(
				d.projects.at(
					idx.row(),
				).slug(),
			);
		}
		if (slugs.isEmpty()) {
			return;
		}
		const newProject = await Proj.merge(
			slugs.toArray(),
		);
		window.location.assign(
			newProject.absoluteUrl(),
		);
	}

	@SLOT
	protected modeToggled(index: number, checked: boolean): void {
		if (!checked) {
			return;
		}
		const d = this.d;
		const cfg = d.layoutCfg(index);
		if (!cfg) {
			return;
		}
		if (cfg.mode === d.mode) {
			return;
		}
		const style = d.layoutStyleForMode(cfg.mode);
		if (style.length < 1) {
			return;
		}
		this.setLayoutMode(cfg.mode);
		d.updateUiLayoutStyleForCurrentMode();
	}

	protected openMenu(cfg: MenuCfg): void {
		this.destroyMenu();
		const d = this.d;
		d.menuCfg = cfg;
		d.menu = new Menu();
		for (const opt of d.menuCfg.opts) {
			d.menu.addItem(opt.label);
		}
		Obj.connect(
			d.menu, 'closed',
			this, 'destroyMenu',
		);
		Obj.connect(
			d.menu, 'selectionChanged',
			this, 'menuOptionSelected',
		);
		d.menu.setPosition(
			App.lastCursorPos(),
		);
		d.menu.show();
	}

	openProjectOptionMenu(index: number): void {
		const d = this.d;
		if ((index < 0) || (index >= d.projects.size())) {
			this.destroyMenu();
			return;
		}
		const proj = d.projects.at(index);
		const opts = d.projectOptions(proj);
		if (opts.isEmpty()) {
			this.destroyMenu();
			return;
		}
		this.openMenu({
			context: MenuContext.Project,
			opts: opts.map(
				x => ({label: x, value: x}),
			),
			project: proj,
		});
	}

	@SLOT
	openSortFieldOptionMenu(): void {
		this.destroyMenu();
		const d = this.d;
		if (d.ui.sortFieldNames.length < 1) {
			return;
		}
		this.openMenu({
			context: MenuContext.SortField,
			opts: new list(
				d.ui.sortFieldNames.map(
					x => ({label: x[1], value: x[0]}),
				),
			),
			project: null,
		});
	}

	protected populate(projs: Iterable<Proj>): void {
		for (const proj of projs) {
			this.addItem(
				new ListItem(
					proj.title(),
				),
			);
		}
	}

	async projects(opts: Partial<IProjectListOpt> = {}): Promise<list<Proj>> {
		const d = this.d;
		if (!opts.filter) {
			opts.filter = d.listFilter;
		}
		return await d.fetchProjects(opts);
	}

	@SLOT
	protected rowsInserted(parent: ModelIndex, start: number, end: number): void {
		super.rowsInserted(parent, start, end);
		this.d.addLayoutItems(parent, start, end);
	}

	@SLOT
	protected rowsRemoved(parent: ModelIndex, first: number, last: number): void {
		super.rowsRemoved(parent, first, last);
		const d = this.d;
		for (let row = last; row >= first; --row) {
			d.projects.takeAt(row).destroy();
			const layoutItem = d.layout && d.layout.takeItem(row);
			if (layoutItem) {
				layoutItem.destroy();
			}
		}
		this.setCheckedActionsVisible(
			this.checkedItems().size() > 0,
		);
	}

	async setArchived(proj: Proj, archived: boolean): Promise<void> {
		const d = this.d;
		const idx = this.indexFromProject(proj);
		if (!idx.isValid() || (idx.row() >= d.projects.size())) {
			return;
		}
		const row = idx.row();
		const layoutItem = d.layout && d.layout.item(row);
		if (layoutItem) {
			layoutItem.setBusy(true);
		}
		await proj.setArchived(archived);
		const it = this.takeItem(row);
		it && it.destroy();
		if (layoutItem) {
			layoutItem.setBusy(false);
		}
	}

	setCheckedActionsVisible(visible: boolean): void {
		const tb = this.toolBar();
		if (!tb) {
			return;
		}
		for (const axn of this.d.checkedAxns) {
			axn.setVisible(visible);
		}
	}

	setLayoutMode(mode: LayoutMode): void {
		const d = this.d;
		if (d.mode === mode) {
			return;
		}
		d.mode = mode;
		this.clear();
		if (d.layout) {
			d.layout.hide();
			d.layout.destroy();
			d.layout = null;
		}
		const cfg = d.layoutCfgForMode(d.mode);
		if (!cfg) {
			return;
		}
		d.layout = new cfg.cls({
			parent: this,
		});
		d.layout.view = this;
		d.layout.show();
		this.populate(d.projects);
	}

	@SLOT
	protected sortOrderClicked(): void {
		this.d.setNextUiSortOrder();
	}

	toolBar(): ToolBar | null {
		return this.d.toolbar;
	}

	async uiCfgChanged(): Promise<void> {
		// FIXME: This whole thing (uiCfgChanged()) is awful. Fix it.
		this.clear();
		const d = this.d;
		d.projects = await this.projects();
		this.populate(d.projects);
	}
}

const staticIui: IUI = Object.freeze({
	expressions: {
		attributes: [],
		operators: [],
	},
	projectListLayoutStyle: '',
	projectListSortAsc: true,
	projectListSortField: '',
	sortFieldNames: [],
});
