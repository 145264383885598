import {Obj, OBJ, SLOT} from '../obj';
import {ToolButton, ToolButtonOpts} from './toolbutton';

@OBJ
export class EnabledToggle extends ToolButton {
	constructor(opts: Partial<ToolButtonOpts> = {}) {
		super(opts);
		this.setCheckable(true);
		Obj.connect(
			this, 'toggled',
			this, 'stateChanged',
		);
		this.stateChanged(false);
	}

	@SLOT
	private stateChanged(checked: boolean): void {
		let iconName: string;
		let title: string;
		if (checked) {
			iconName = 'toggle_on';
			title = 'Click to disable';
			this.setStyleProperty(
				'color',
				'#00CC75',
			);
		} else {
			iconName = 'toggle_off';
			title = 'Click to enable';
			this.removeStyleProperty(
				'color',
			);
		}
		this.icon().setName(iconName);
		this.setAttribute(
			'title',
			title,
		);
	}
}
