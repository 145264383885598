import axios from 'axios';

import {ProjectFilterParam, REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY} from './constants';
import {isNumber, trailingslashurljoin} from './util';
import {GeoCoordinate} from './tools';

const STANDARD_HEADERS = {
	'Accept': 'application/json',
	'Content-Type': 'application/json',
};
const XSRF_CFG = {
	xsrfCookieName: 'csrftoken',
	xsrfHeaderName: 'X-CSRFToken',
};

class RequestSvc {
	static cancelTokenSource(): ICancelTokenSource {
		return axios.CancelToken.source();
	}

	static coordToParam(coord: GeoCoordinate): string {
		return `${coord.longitude()},${coord.latitude()}`;
	}

	static isCancellation(obj: any): obj is ICancellation {
		return axios.isCancel(obj);
	}

	private buildUrl(base: string, parts?: Array<string>): string {
		return trailingslashurljoin(base, ...(parts || []));
	}

	cancelTokenSource(): ICancelTokenSource {
		return RequestSvc.cancelTokenSource();
	}

	DELETE<T>(url: string, data?: any, cfg?: UnsafeReqCfg): Promise<IResponse<T>> {
		return this.request({data: data, method: 'DELETE', url: url, ...cfg});
	}

	GET<T>(url: string, params?: any, cfg?: SafeReqCfg): Promise<IResponse<T>> {
		return this.request({method: 'GET', params: params, url: url, ...cfg});
	}

	isCancellation(obj: any): obj is ICancellation {
		return RequestSvc.isCancellation(obj);
	}

	POST<T>(url: string, data?: any, cfg?: UnsafeReqCfg): Promise<IResponse<T>> {
		return this.request({data: data, method: 'POST', url: url, ...cfg});
	}

	preparedRequestConfig(cfg: IRequestConfig): IRequestConfig {
		const headers: any = cfg.headers ?
			{...STANDARD_HEADERS, ...cfg.headers} :
			{...STANDARD_HEADERS};
		let rv: IRequestConfig = {
			cancelToken: cfg.cancelToken,
			data: cfg.data,
			headers: headers,
			method: cfg.method,
			params: cfg.params,
			responseType: cfg.responseType ? cfg.responseType : 'json',
			timeout: cfg.timeout,
			url: cfg.url,
			validateStatus: cfg.validateStatus,
			wellKnown: Symbol.for(REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY),
		};
		switch (cfg.method) {
			case 'DELETE':
			case 'PATCH':
			case 'POST':
			case 'PUT':
				rv = {...rv, ...XSRF_CFG};
				break;
		}
		return rv;
	}

	PUT<T>(url: string, data?: any, cfg?: UnsafeReqCfg): Promise<IResponse<T>> {
		return this.request({data: data, method: 'PUT', url: url, ...cfg});
	}

	async request<T>(cfg: IRequestConfig): Promise<IResponse<T>> {
		const prepped = this.preparedRequestConfig(cfg);
		const rv = await axios.request<T>(prepped);
		rv.config = prepped;
		return <IResponse<T>>rv;
	}

	url(baseUri: string, ...parts: Array<number | string>): string {
		const strings = parts
			.filter(p => ((typeof p === 'string') || isNumber(p)))
			.map(x => String(x));
		return this.buildUrl(baseUri, strings);
	}
}

class AccountSvc extends RequestSvc {
	async get(cfg?: SafeReqCfg): Promise<IAccount> {
		const url = this.url('/api', 'account');
		return (await this.GET<IAccount>(url, undefined, cfg)).data;
	}
}

class AreaSvc extends RequestSvc {
	async list(pks: Array<AreaPk> | AreaPk): Promise<Array<IArea>> {
		const params = {
			id: Array.isArray(pks) ?
				pks :
				[pks],
		};
		const url = this.url('/api', 'geo', 'area');
		return (await this.GET<Array<IArea>>(url, params)).data;
	}
}

class D extends RequestSvc {
	async batchCreateParcelTags(tagK: string, tagV: string, enabled: boolean): Promise<IResponse<void>> {
		const url = this.url(
			'/api',
			'd',
			'parcel',
			'tags',
		);
		return await this.POST(url, {tagK, tagV, enabled});
	}

	async deleteParcelTag(parcelId: string, tagK: string, tagV: string): Promise<IResponse<void>> {
		const url = this.url(
			'/api',
			'd',
			'parcel',
			parcelId,
			'tags',
			tagK,
			tagV,
		);
		return await this.DELETE(url);
	}

	async deleteTag(k: string, v: string): Promise<IResponse<void>> {
		const url = this.url(
			'/api',
			'd',
			'tag',
			k,
			v,
		);
		return await this.DELETE(url);
	}

	async newParcelTag(data: IParcelTag): Promise<IResponse<IParcelTag>> {
		const url = this.url(
			'/api',
			'd',
			'parcel',
			data.parcelId,
			'tags',
		);
		return await this.POST(url, data);
	}

	async newTag(data: ITag): Promise<IResponse<ITag>> {
		const url = this.url(
			'/api',
			'd',
			'tag',
		);
		return await this.POST(url, data);
	}

	async parcelTags(parcelPk: string): Promise<IResponse<Array<IParcelTag>>> {
		const url = this.url(
			'/api',
			'd',
			'parcel',
			parcelPk,
			'tags',
		);
		return await this.GET(url);
	}

	async tag(k: string, v: string): Promise<IResponse<ITag>> {
		const url = this.url(
			'/api',
			'd',
			'tag',
			k,
			v,
		);
		return await this.GET(url);
	}

	async tags(): Promise<IResponse<Array<ITag>>> {
		const url = this.url(
			'/api',
			'd',
			'tag',
		);
		return await this.GET(url);
	}

	async updateParcelTag(data: IParcelTag): Promise<IResponse<IParcelTag>> {
		const url = this.url(
			'/api',
			'd',
			'parcel',
			data.parcelId,
			'tags',
			data.tagK,
			data.tagV,
		);
		return await this.PUT(url, data);
	}

	async updateTag(data: ITag): Promise<IResponse<ITag>> {
		const url = this.url(
			'/api',
			'd',
			'tag',
			data.k,
			data.v,
		);
		return await this.PUT(url, data);
	}
}

class DoNotMailSvc extends RequestSvc {
	async list(opt?: Partial<IGeoRefListOpt>, cfg?: SafeReqCfg): Promise<IResponse<Array<IDoNotMail>>> {
		let params: Partial<IGeoRefURLParam> | undefined = undefined;
		if (opt !== undefined) {
			params = {};
			if (opt.parcelPk) {
				params.parcel = opt.parcelPk;
			}
			if (opt.coord) {
				params.point = `${opt.coord.longitude},${opt.coord.latitude}`;
			}
		}
		const url = this.url('/api', 'do-not-mail');
		return await this.GET<Array<IDoNotMail>>(url, params, cfg);
	}
}

export interface IFilterListOpt {
	point: GeoCoordinate;
	projectSlug: string;
	type: 'data';
}

class FilterSvc extends RequestSvc {
	async children(pk: FilterPk, isData: boolean = false): Promise<IResponse<Array<IFilter>>> {
		const url = this.url(
			'/api',
			'filters',
			pk,
			'children',
		);
		return await this.GET(url, isData ? {type: 'data'} : undefined);
	}

	async create(data: INewFilter, isData: boolean = false, cfg?: UnsafeReqCfg): Promise<IResponse<IFilter>> {
		const url = this.url('/api', 'filters');
		return await this.request({
			data,
			method: 'POST',
			params: (data.dataFilter || isData) ?
				{type: 'data'} :
				undefined,
			url,
			...cfg,
		});
	}

	async delete(pk: FilterPk, cfg?: UnsafeReqCfg): Promise<void> {
		const url = this.url('/api', 'filters', pk);
		return (await this.DELETE<void>(url, undefined, cfg)).data;
	}

	async get(pk: FilterPk, cfg?: SafeReqCfg): Promise<IFilter> {
		const url = this.url('/api', 'filters', pk);
		return (await this.GET<IFilter>(url, undefined, cfg)).data;
	}

	async list(opt?: Partial<IFilterListOpt>, cfg?: SafeReqCfg): Promise<Array<IFilter>> {
		const url = this.url('/api', 'filters');
		let params: Partial<IFilterURLParam> | undefined = undefined;
		if (opt) {
			if (opt.projectSlug) {
				if (!params) {
					params = {};
				}
				params['project'] = opt.projectSlug;
			}
			if (opt.point) {
				if (!params) {
					params = {};
				}
				params['point'] = RequestSvc.coordToParam(opt.point);
			}
			if (opt.type) {
				if (!params) {
					params = {};
				}
				params['type'] = opt.type;
			}
		}
		return (await this.GET<Array<IFilter>>(url, params, cfg)).data;
	}

	async update(pk: FilterPk, data: Partial<IFilter>, isData: boolean = false, cfg?: UnsafeReqCfg): Promise<IResponse<IFilter>> {
		const url = this.url(
			'/api',
			'filters',
			pk,
		);
		return await this.request({
			data,
			method: 'PUT',
			params: isData ?
				{type: 'data'} :
				undefined,
			url,
			...cfg,
		});
	}
}

interface IGeoMapConfigurationListOpt {
	image: 'sync';
	projectSlug: string;
}

class GeoMapConfigurationSvc extends RequestSvc {
	async get(pk: GeoMapConfigurationPk, syncImage: boolean = false, cfg?: SafeReqCfg): Promise<IGeoMapConfiguration> {
		let params: Partial<IGeoMapConfigurationURLParam> | undefined = undefined;
		if (syncImage) {
			params = {
				image: 'sync',
			};
		}
		const url = this.url('/api', 'maps', pk);
		return (await this.GET<IGeoMapConfiguration>(url, params, cfg)).data;
	}

	async list(opt?: Partial<IGeoMapConfigurationListOpt>, cfg?: SafeReqCfg): Promise<Array<IGeoMapConfiguration>> {
		let params: Partial<IGeoMapConfigurationURLParam> | undefined = undefined;
		if (opt) {
			if (opt.projectSlug) {
				params = {project: opt.projectSlug};
				if (opt.image) {
					params.image = opt.image;
				}
			}
		}
		const url = this.url('/api', 'maps');
		return (await this.GET<Array<IGeoMapConfiguration>>(url, params, cfg)).data;
	}

	async update(data: Pick<IGeoMapConfiguration, 'id'> & Partial<ModifiableGeoMapConfiguration>, cfg?: UnsafeReqCfg): Promise<IGeoMapConfiguration> {
		const url = this.url('/api', 'maps', data.id);
		return (await this.PUT<IGeoMapConfiguration>(url, data, cfg)).data;
	}
}

class GeoRefSvc extends RequestSvc {
	async create(data: Partial<INewGeoRef>, cfg?: UnsafeReqCfg): Promise<IResponse<IGeoRef | null>> {
		// FIXME: This sucks. Do something about it.
		const url = this.url('/api', 'geo-refs');
		return await this.POST<IGeoRef | null>(url, data, cfg);
	}

	async delete(pk: GeoRefPk, cfg?: UnsafeReqCfg): Promise<IResponse<void>> {
		const url = this.url('/api', 'geo-refs', pk);
		return await this.DELETE<void>(url, cfg);
	}

	async get(pk: GeoRefPk, cfg?: SafeReqCfg): Promise<IResponse<IGeoRef>> {
		const url = this.url('/api', 'geo-refs', pk);
		return await this.GET<IGeoRef>(url, undefined, cfg);
	}

	async list(opt?: Partial<IGeoRefListOpt>, cfg?: SafeReqCfg): Promise<IResponse<Array<IGeoRef>>> {
		let params: Partial<IGeoRefURLParam> | undefined = undefined;
		if (opt !== undefined) {
			params = {};
			if (opt.parcelPk) {
				params.parcel = opt.parcelPk;
			}
			if (opt.coord) {
				params.point = `${opt.coord.longitude},${opt.coord.latitude}`;
			}
		}
		const url = this.url('/api', 'geo-refs');
		return await this.GET<Array<IGeoRef>>(url, params, cfg);
	}

	async update(pk: GeoRefPk, data: Partial<IGeoRef>, cfg?: UnsafeReqCfg): Promise<IResponse<IGeoRef>> {
		const url = this.url('/api', 'geo-refs', pk);
		return await this.PUT<IGeoRef>(url, data, cfg);
	}
}

interface IInvoiceListOpt {
	projectSlug: string;
}

class InvoiceSvc extends RequestSvc {
	async get(pk: InvoicePk, recalculate: boolean = false, slug?: string, cancelToken?: ICancelToken, cfg?: SafeReqCfg): Promise<IInvoice> {
		if (recalculate) {
			return await this.tmpUpdateGet(
				pk,
				slug,
				{
					...(cfg || {}),
					cancelToken,
				},
			);
		}
		const url = this.url(
			'/api',
			'invoices',
			pk,
		);
		const resp = await this.GET<IInvoice>(
			url,
			undefined,
			{
				...(cfg || {}),
				cancelToken,
			},
		);
		return resp.data;
	}

	async list(opt?: Partial<IInvoiceListOpt>, cfg?: SafeReqCfg): Promise<Array<IInvoice>> {
		let params: Partial<IInvoiceURLParam> | undefined = undefined;
		if (opt) {
			if (opt.projectSlug) {
				params = {
					project: opt.projectSlug,
				};
			}
		}
		const url = this.url('/api', 'invoices');
		return (await this.GET<Array<IInvoice>>(url, params, cfg)).data;
	}

	async tmpUpdateGet(pk: InvoicePk, slug?: string, cfg?: SafeReqCfg): Promise<IInvoice> {
		const url = this.url('/api', 'invoices', pk);
		const params = {
			project: slug,
			rpc: 'RECALC',
		};
		return (await this.GET<IInvoice>(url, params, cfg)).data;
	}
}

class ODataSvc extends RequestSvc {
	async entity(pathExpr: string): Promise<IResponse<IODataEntity>> {
		const url = this.url(
			'/api',
			'od',
			pathExpr,
		);
		return await this.GET(url);
	}

	async entityCollection(name: string, opts: Partial<IODataSystemQuery> = {}): Promise<IResponse<IODataEntityCollection>> {
		const url = this.url(
			'/api',
			'od',
			name,
		);
		let params: any = undefined;
		if ((opts.skip !== undefined) || (opts.top !== undefined)) {
			params = {};
			if (opts.skip !== undefined) {
				params['$skip'] = opts.skip;
			}
			if (opts.top !== undefined) {
				params['$top'] = opts.top;
			}
		}
		return await this.GET(url, params);
	}

	async entitySetCount(entitySetName: string): Promise<IResponse<number>> {
		const url = this.url(
			'/api',
			'od',
			entitySetName,
			'$count',
		);
		return await this.GET(url);
	}

	async invokeFunction(funcSig: string, opts: Partial<IODataSystemQuery> = {}): Promise<IResponse<unknown>> {
		let params: any = undefined;
		if ((opts.skip !== undefined) || (opts.top !== undefined)) {
			params = {};
			if (opts.skip !== undefined) {
				params['$skip'] = opts.skip;
			}
			if (opts.top !== undefined) {
				params['$top'] = opts.top;
			}
		}
		const url = this.url(
			'/api',
			'od',
			funcSig,
		);
		return await this.GET(url, params);
	}

	async metadataDocument(): Promise<IResponse<string>> {
		const url = this.url(
			'/api',
			'od',
			'$metadata',
		);
		return await this.request({
			method: 'GET',
			url,
			responseType: 'text',
			headers: {
				Accept: 'application/xml',
			},
		});
	}

	async relatedEntity(dependentEntitySetName: string, dependentEntityId: string, principalEntityTypeName: string): Promise<IResponse<IODataEntity>> {
		const url = this.url(
			'/api',
			'od',
			`${dependentEntitySetName}('${dependentEntityId}')`,
			principalEntityTypeName,
		);
		return await this.GET(url);
	}

	async serviceDocument(): Promise<IResponse<IODataServiceDocument>> {
		const url = this.url(
			'/api',
			'od',
		);
		return await this.GET(url);
	}

	async updateEntitySet(entitySetName: string, data: {[k: string]: any}): Promise<IResponse<void>> {
		const url = this.url(
			'/api',
			'od',
			entitySetName,
		);
		return await this.request({method: 'PATCH', data, url});
	}
}

class ParcelSvc extends RequestSvc {
	async list(point: GeoCoordinate, cfg?: SafeReqCfg): Promise<Array<IParcel>> {
		const params: Partial<IParcelURLParam> | undefined = {
			point: RequestSvc.coordToParam(point),
		};
		const url = this.url('/api', 'parcels');
		return (await this.GET<Array<IParcel>>(url, params, cfg)).data;
	}

	async projectAreaPks(slug: string, z: number, x: number, y: number, cfg?: SafeReqCfg): Promise<Array<AreaPk>> {
		const params: Partial<IParcelURLParam> = {
			field: 'pk',
			project: slug,
		};
		const url = this.url('/api', 'parcels', z, x, y);
		return (await this.GET<Array<AreaPk>>(url, params, cfg)).data;
	}
}

class PaymentSvc extends RequestSvc {
	async create(data: INewPayment, cfg?: UnsafeReqCfg): Promise<IResponse<IPaymentIntent>> {
		const url = this.url('/api', 'payments');
		return await this.POST<IPaymentIntent>(url, data, cfg);
	}
}

class PaymentMethodSvc extends RequestSvc {
	async delete(pk: PaymentMethodPk, cfg?: UnsafeReqCfg): Promise<void> {
		const url = this.url('/api', 'payment-methods', pk);
		return (await this.DELETE<void>(url, cfg)).data;
	}

	async list(cfg?: SafeReqCfg): Promise<Array<IPaymentMethod>> {
		const url = this.url('/api', 'payment-methods');
		return (await this.GET<Array<IPaymentMethod>>(url, cfg)).data;
	}
}

class PlaceSvc extends RequestSvc {
	async list(name: string, cfg?: SafeReqCfg): Promise<Array<IPlace>> {
		const params: Partial<IPlaceURLParam> | undefined = {
			name,
		};
		const url = this.url('/api', 'places');
		return (await this.GET<Array<IPlace>>(url, params, cfg)).data;
	}
}

class PriceSvc extends RequestSvc {
	async get(pk: PricePk, cfg?: SafeReqCfg): Promise<IPrice> {
		const url = this.url('/api', 'prices', pk);
		return (await this.GET<IPrice>(url, cfg)).data;
	}

	async list(cfg?: SafeReqCfg): Promise<Array<IPrice>> {
		const url = this.url('/api', 'prices');
		return (await this.GET<Array<IPrice>>(url, cfg)).data;
	}
}

interface IPriceListOpt {
	pricePk: PricePk;
}

class PriceTierSvc extends RequestSvc {
	async get(pk: PriceTierPk, cfg?: SafeReqCfg): Promise<IPriceTier> {
		const url = this.url('/api', 'price-tiers', pk);
		return (await this.GET<IPriceTier>(url, cfg)).data;
	}

	async list(opt?: Partial<IPriceListOpt>, cfg?: SafeReqCfg): Promise<Array<IPriceTier>> {
		let params: Partial<IPriceTierURLParam> | undefined = undefined;
		if (opt) {
			if (opt.pricePk !== undefined) {
				params = {
					price: opt.pricePk,
				};
			}
		}
		const url = this.url('/api', 'price-tiers');
		return (await this.GET<Array<IPriceTier>>(url, params, cfg)).data;
	}
}

export interface IProjectListOpt {
	filter: ProjectFilterParam;
	sortField: string;
	sortOrder: 'asc' | 'desc';
}

class ProjectSvc extends RequestSvc {
	async archive(slug: string, cfg?: UnsafeReqCfg): Promise<IResponse<IProject>> {
		return await this.setArchived(slug, true, cfg);
	}

	async setArchived(slug: string, archived: boolean, cfg?: UnsafeReqCfg): Promise<IResponse<IProject>> {
		return await this.update(slug, {archived}, cfg);
	}

	async clone(slug: string, cfg?: UnsafeReqCfg): Promise<IProject> {
		const url = this.url('/api', 'projects', slug);
		return (await this.POST<IProject>(url, cfg)).data;
	}

	async get(slug: string, etag?: string, cfg?: SafeReqCfg): Promise<IResponse<IProject> | null> {
		etag = (etag === undefined) ?
			'' :
			etag.trim();
		cfg = cfg || {};
		if (etag.length > 0) {
			cfg.headers = {
				...(cfg.headers || {}),
			};
			cfg.headers['ETag'] = etag;
		}
		cfg.validateStatus = (status: number) =>
			(((status >= 200) && (status < 300)) || (status === 304));
		const url = this.url(
			'/api',
			'projects',
			slug,
		);
		const resp = await this.GET<IProject | null>(
			url,
			undefined,
			cfg,
		);
		if (resp.status === 304) {
			return null;
		}
		return <IResponse<IProject>>resp;
	}

	async list(opt?: Partial<IProjectListOpt>, cfg?: SafeReqCfg): Promise<IResponse<Array<IProject>>> {
		let params: Partial<IProjectURLParam> | undefined = undefined;
		if (opt) {
			params = {};
			if (opt.sortField !== undefined) {
				const prefix = ((opt.sortOrder === undefined) || (opt.sortOrder === 'asc')) ?
					'' :
					'-';
				params.sort = `${prefix}${opt.sortField}
`;
			}
			if (opt.filter !== undefined) {
				params['filter'] = opt.filter;
			}
		}
		const url = this.url('/api', 'projects');
		return await this.GET<Array<IProject>>(url, params, cfg);
	}

	async merge(slugs: Array<string>, cfg?: UnsafeReqCfg): Promise<IProject> {
		const url = this.url('/api', 'projects');
		return (await this.POST<IProject>(url, {projects: slugs}, cfg)).data;
	}

	async processPayment(slug: string, cfg?: UnsafeReqCfg): Promise<IResponse<IProject>> {
		const url = this.url('/api', 'projects', slug, 'payment');
		return this.POST(url, undefined, cfg);
	}

	async restore(slug: string, cfg?: UnsafeReqCfg): Promise<IResponse<IProject>> {
		return await this.setArchived(slug, false, cfg);
	}

	async update(slug: string, data: Partial<IProject>, cfg?: UnsafeReqCfg): Promise<IResponse<IProject>> {
		const url = this.url('/api', 'projects', slug);
		return await this.PUT<IProject>(url, data, cfg);
	}
}

class UISvc extends RequestSvc {
	async get(cfg?: SafeReqCfg): Promise<IUI> {
		const url = this.url('/api', 'ui');
		return (await this.GET<IUI>(url, cfg)).data;
	}

	async update(data: IUI, cfg?: UnsafeReqCfg): Promise<IUI> {
		const url = this.url('/api', 'ui');
		return (await this.PUT<IUI>(url, data, cfg)).data;
	}
}

class Svc {
	account: AccountSvc;
	area: AreaSvc;
	d: D;
	doNotMail: DoNotMailSvc;
	filter: FilterSvc;
	geoRef: GeoRefSvc;
	invoice: InvoiceSvc;
	map: GeoMapConfigurationSvc;
	od: ODataSvc;
	parcel: ParcelSvc;
	payment: PaymentSvc;
	paymentMethod: PaymentMethodSvc;
	place: PlaceSvc;
	price: PriceSvc;
	priceTier: PriceTierSvc;
	project: ProjectSvc;
	ui: UISvc;

	constructor() {
		this.account = new AccountSvc();
		this.area = new AreaSvc();
		this.d = new D();
		this.doNotMail = new DoNotMailSvc();
		this.filter = new FilterSvc();
		this.geoRef = new GeoRefSvc();
		this.invoice = new InvoiceSvc();
		this.map = new GeoMapConfigurationSvc();
		this.od = new ODataSvc();
		this.parcel = new ParcelSvc();
		this.payment = new PaymentSvc();
		this.paymentMethod = new PaymentMethodSvc();
		this.place = new PlaceSvc();
		this.price = new PriceSvc();
		this.priceTier = new PriceTierSvc();
		this.project = new ProjectSvc();
		this.ui = new UISvc();
	}

	cancelTokenSource(): ICancelTokenSource {
		return RequestSvc.cancelTokenSource();
	}

	isCancellation(obj: any): obj is ICancellation {
		return RequestSvc.isCancellation(obj);
	}
}

export const svc = new Svc();
