import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {DataTableView, DataTableViewOpts, DataTableViewPrivate} from '../../ui/datatable';
import {TableItem} from '../../ui/itemviews';
import {ItemDataRole} from '../../constants';
import {Variant} from '../../variant';
import {list} from '../../tools';
import {ModelIndex} from '../../abstractitemmodel';
import {Pagination} from '../../ui/datatable';
import {ItemSelection} from '../../itemselectionmodel';

class TableViewPrivate extends DataTableViewPrivate {
	highlighted: list<ModelIndex>;

	constructor() {
		super();
		this.highlighted = new list();
	}

	init(opts: Partial<TableViewOpts>): void {
		super.init(opts);
		const q = this.q;
		const m = q.model();
		if (!m) {
			return;
		}
		const signals = [
			'columnsAboutToBeInserted',
			'columnsAboutToBeMoved',
			'columnsAboutToBeRemoved',
			'layoutAboutToBeChanged',
			'modelAboutToBeReset',
			'rowsAboutToBeInserted',
			'rowsAboutToBeMoved',
			'rowsAboutToBeRemoved',
		];
		for (const sig of signals) {
			Obj.connect(
				m, sig,
				q, 'clearHighlighting',
			);
		}
		const pg = new Pagination();
		q.setPagination(pg);
		pg.addPerPageOptions([
			10,
			25,
			50,
			100,
		]);
	}

	get q(): TableView {
		return <TableView>super.q;
	}
}

interface TableViewOpts extends DataTableViewOpts {
	dd: TableViewPrivate;
}

@OBJ
export class TableView extends DataTableView {
	constructor(opts: Partial<TableViewOpts> = {}) {
		opts.dd = opts.dd || new TableViewPrivate();
		super(opts);
	}

	@SLOT
	clearContents(): void {
		// this.clearHighlighting();
		this.clearSelection();
		super.clearContents();
	}

	@SLOT
	clearHighlighting(): void {
		const d = this.d;
		const m = d.tableModel();
		for (const idx of d.highlighted) {
			if (!idx.isValid()) {
				continue;
			}
			const item = m.item(idx);
			if (item) {
				item.setData(
					ItemDataRole.BackgroundRole,
					new Variant(''),
				);
			}
		}
		d.highlighted.clear();
	}

	get d(): TableViewPrivate {
		return <TableViewPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.highlighted.clear();
		super.destroy();
	}

	highlighted(): list<ModelIndex> {
		return this.d.highlighted;
	}

	isRowHighlighted(row: number): boolean {
		if (!(row >= 0) && (row < this.rowCount())) {
			return false;
		}
		for (const idx of this.d.highlighted) {
			if (idx.isValid() && idx.row() === row) {
				return true;
			}
		}
		return false;
	}

	@SLOT
	protected selectionChanged(selected: ItemSelection, deselected: ItemSelection): void {
		for (const obj of deselected.indexes()) {
			this.setRowHighlighted(obj.row(), false);
		}
		for (const obj of selected.indexes()) {
			this.setRowHighlighted(obj.row(), true);
		}
		this.tmpStuffChanged(this, selected, deselected);
	}

	@SIGNAL
	tmpStuffChanged(tbl: TableView, selected: ItemSelection, deselected: ItemSelection): void {
	}

	scrollTo(obj: ModelIndex | TableItem): void {
		super.scrollTo(
			(obj instanceof ModelIndex) ?
				obj :
				this.d.tableModel().index(obj),
		);
	}

	setData(propNames: Array<string>, coll: IODataEntityCollection): void {
		this.clearContents();
		const rc = coll.value.length;
		this.setRowCount(rc);
		for (let row = 0; row < rc; ++row) {
			const ent = coll.value[row];
			for (let i = 0; i < propNames.length; ++i) {
				const prop = propNames[i];
				const item = new TableItem();
				this.setItem(row, i, item);
				item.setData(
					ItemDataRole.EditRole,
					new Variant(ent[prop]),
				);
				item.setData(
					ItemDataRole.BackgroundRole,
					new Variant(''),
				);
			}
		}
	}

	private setRowHighlighted(row: number, highlighted: boolean): void {
		const rc = this.rowCount();
		if (!((row >= 0) && (row < rc))) {
			return;
		}
		const cc = this.columnCount();
		const m = this.d.tableModel();
		const v = new Variant(
			highlighted ?
				'#00FF00' :
				'',
		);
		const idxs = new list<ModelIndex>();
		for (let col = 0; col < cc; ++col) {
			const idx = m.index(row, col);
			if (idx.isValid()) {
				m.setData(
					idx,
					v,
					ItemDataRole.BackgroundRole,
				);
				idxs.append(idx);
			}
		}
		if (idxs.isEmpty()) {
			return;
		}
		const d = this.d;
		const toAdd = new list<ModelIndex>();
		for (const newIdx of idxs) {
			let found = -1;
			for (let i = 0; i < d.highlighted.size(); ++i) {
				if (d.highlighted.at(i).eq(newIdx)) {
					found = i;
					break;
				}
			}
			if (highlighted) {
				if (found >= 0) {
					// Already in list, nothing to do.
				} else {
					toAdd.append(newIdx);
				}
			} else {
				if (found >= 0) {
					d.highlighted.remove(found);
				} else {
					// Nothing to do.
				}
			}
		}
		d.highlighted.extend(toAdd);
	}
}

function objKeys(obj: {[key: string]: any}): Array<string> {
	if ((obj === null) || (obj === undefined)) {
		return [];
	}
	return Object.getOwnPropertyNames(obj);
}
