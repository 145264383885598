import {Feature} from './feature';
import type {IMapboxDrawContext} from '../mapboxdraw';

type GeomNoColl = GeoJsonPoint | GeoJsonMultiPoint | GeoJsonLineString | GeoJsonMultiLineString | GeoJsonPolygon | GeoJsonMultiPolygon;

export class Polygon extends Feature {
	constructor(ctx: IMapboxDrawContext, feature: GeoJsonFeature<GeomNoColl>) {
		super(ctx, feature);
		this.coordinates = (<GeoJsonPosition[][]>this.coordinates).map(ring => ring.slice(0, -1));
	}

	addCoordinate(path: string, longitude: number, latitude: number): void {
		this.changed();
		const ids: Array<number> = path.split('.').map(x => parseInt(x, 10));
		(<GeoJsonPosition[][]>this.coordinates)[ids[0]]
			.splice(ids[1], 0, [longitude, latitude]);
	}

	getCoordinate(path: string): GeoJsonPosition {
		const ids: Array<number> = path.split('.').map(x => parseInt(x, 10));
		const ring = (<GeoJsonPosition[][]>this.coordinates)[ids[0]];
		return JSON.parse(JSON.stringify(ring[ids[1]]));
	}

	getCoordinates(): GeoJsonPosition[][] {
		return (<GeoJsonPosition[][]>this.coordinates)
			.map(coords => coords.concat([coords[0]]));
	}

	// Expects valid geoJSON polygon geometry: first and last positions must be equivalent.
	incomingCoords(coords: GeoJsonPosition[][]): void {
		this.coordinates = coords.map(ring => ring.slice(0, -1));
		this.changed();
	}

	isValid(): boolean {
		return (this.coordinates.length > 0) ?
			(<GeoJsonPosition[][]>this.coordinates).every(ring => (ring.length > 2)) :
			false;
	}

	removeCoordinate(path: string): void {
		if (this.coordinates.length > 0) {
			this.changed();
			const ids: Array<number> = path.split('.').map(x => parseInt(x, 10));
			const ring: Array<Array<number>> = (<GeoJsonPosition[][]>this.coordinates)[ids[0]];
			if (ring.length > 0) {
				ring.splice(ids[1], 1);
				if (ring.length < 3) {
					this.coordinates.splice(ids[0], 1);
				}
			}
		}
	}

	// Does NOT expect valid geoJSON polygon geometry: first and last positions should not be equivalent.
	setCoordinates(coords: GeoJsonPosition[][]): void {
		this.coordinates = coords;
		this.changed();
	}

	updateCoordinate(path: string, longitude: number, latitude: number): void {
		this.changed();
		const parts: Array<string> = path.split('.');
		const ringId: number = parseInt(parts[0], 10);
		const coordId: number = parseInt(parts[1], 10);
		if ((<GeoJsonPosition[][]>this.coordinates)[ringId] === undefined) {
			(<GeoJsonPosition[][]>this.coordinates)[ringId] = [];
		}
		(<GeoJsonPosition[][]>this.coordinates)[ringId][coordId] = [longitude, latitude];
	}
}
