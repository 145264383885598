import {list} from '../tools';
import {assert, isNumber} from '../util';
import {EdmPrimitiveType, FociisODataVocabTerm, ODataOnDeleteAction} from '../constants';
import {getLogger} from '../logging';

const logger = getLogger('odata.csdl');
const objSym = Symbol('ODataCSDLObject');
const xmlNs = 'http://www.w3.org/2000/xmlns/';
type Kwargs = {[key: string]: string | null}

class ODataCSDLObject {
	[objSym]: undefined;
	parent: ODataCSDLObject | null;

	constructor(kwargs: Kwargs = {}) {
		this.parent = null;
		if (('parent' in kwargs) && isODataCSDLObject(kwargs.parent)) {
			this.setParent(kwargs.parent);
		}
	}

	edmx(): Edmx | null {
		if (this instanceof Edmx) {
			assert(!this.parent);
			return this;
		}
		let curr = this.parent;
		while (curr) {
			if (curr instanceof Edmx) {
				assert(!curr.parent);
				return curr;
			}
			curr = curr.parent;
		}
		return null;
	}

	isAnnotated(): this is AnnotatedODataCSDLObject {
		return false;
	}

	schema(): Schema | null {
		if (this instanceof Schema) {
			return this;
		}
		let curr = this.parent;
		while (curr) {
			if (curr instanceof Schema) {
				return curr;
			}
			curr = curr.parent;
		}
		return null;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		this.parent = parent;
	}
}

class AnnotatedODataCSDLObject extends ODataCSDLObject {
	annotationList: list<Annotation>;

	constructor(kwargs?: Kwargs) {
		super(kwargs);
		this.annotationList = new list();
	}

	isAnnotated(): this is AnnotatedODataCSDLObject {
		return true;
	}
}

class Annotation extends AnnotatedODataCSDLObject {
	qualifier: string | null;
	term: string;
	// Constant Expression
	binary: string | null;
	bool: boolean | null;
	date: string | null;
	dateTimeOffset: string | null;
	decimal: string | null;
	duration: string | null;
	enumMember: string | null;
	float: number | null;
	guid: string | null;
	int: number | null;
	string: string | null;
	timeOfDay: string | null;
	// Dynamic Expression
	annotationPath: string | null;
	modelElementPath: string | null;
	navigationPropertyPath: string | null;
	path: string | null;
	propertyPath: string | null;
	urlRef: string | null;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.enumMember = strOrNull(kwargs['enumMember']);
		this.qualifier = strOrNull(kwargs['qualifier']);
		this.term = kwargs['term'] || '';
		// Constant Expression
		this.binary = strOrNull(kwargs['binary']);
		this.bool = toBooleanOrNull(kwargs['bool']);
		this.date = strOrNull(kwargs['date']);
		this.dateTimeOffset = strOrNull(kwargs['dateTimeOffset']);
		this.decimal = strOrNull(kwargs['decimal']);
		this.duration = strOrNull(kwargs['duration']);
		this.enumMember = strOrNull(kwargs['enumMember']);
		this.float = toFloatOrNull(kwargs['float']);
		this.guid = strOrNull(kwargs['guid']);
		this.int = toIntOrNull(kwargs['int']);
		this.string = strOrNull(kwargs['string']);
		this.timeOfDay = strOrNull(kwargs['timeOfDay']);
		// Dynamic Expression
		this.annotationPath = strOrNull(kwargs['annotationPath']);
		this.modelElementPath = strOrNull(kwargs['modelElementPath']);
		this.navigationPropertyPath = strOrNull(kwargs['navigationPropertyPath']);
		this.path = strOrNull(kwargs['path']);
		this.propertyPath = strOrNull(kwargs['propertyPath']);
		this.urlRef = strOrNull(kwargs['urlRef']);
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && this.parent.isAnnotated()) {
			this.parent.annotationList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && this.parent.isAnnotated()) {
			this.parent.annotationList.append(this);
		}
	}
}

class Key extends ODataCSDLObject {
	propertyRefList: list<PropertyRef>;

	constructor(kwargs?: Kwargs) {
		super(kwargs);
		this.propertyRefList = new list();
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof EntityType) && (this.parent.key === this)) {
			this.parent.key = null;
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof EntityType) && (this.parent.key !== this)) {
			if (this.parent.key) {
				this.parent.key.setParent(null);
			}
			this.parent.key = this;
		}
	}
}

class Property extends AnnotatedODataCSDLObject {
	defaultValue: any;
	maxLength: number | null;
	name: string;
	nullable: boolean | null;
	precision: number | null;
	scale: number | 'floating' | 'variable' | null;
	srid: string | null;
	type: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.defaultValue = null;
		this.maxLength = toIntOrNull(kwargs['maxLength']);
		this.name = kwargs['name'] || '';
		this.nullable = toBooleanOrNull(kwargs['nullable']);
		this.precision = toIntOrNull(kwargs['precision']);
		this.scale = (isString(kwargs['scale']) && ((kwargs['scale'] === 'floating') || (kwargs['scale'] === 'variable'))) ?
			kwargs['scale'] :
			toIntOrNull(kwargs['scale']);
		this.srid = strOrNull(kwargs['srid']);
		this.type = kwargs['type'] || '';
		if (this.type.length > 0) {
			const val = toDefaultValue(
				kwargs['defaultValue'],
				this.type,
			);
			this.defaultValue = (val === undefined) ?
				null :
				val;
		}
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof StructType)) {
			this.parent.propertyList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof StructType)) {
			this.parent.propertyList.append(this);
		}
	}
}

class PropertyRef extends ODataCSDLObject {
	alias: string | null;
	name: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.alias = strOrNull(kwargs['alias']);
		this.name = kwargs['name'] || '';
	}

	property(): Property | null {
		if (!this.parent) {
			return null;
		}
		if ((this.parent instanceof Key) && (this.parent.parent instanceof EntityType)) {
			for (const prop of this.parent.parent.propertyList) {
				if (prop.name === this.name) {
					return prop;
				}
			}
		}
		return null;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Key)) {
			this.parent.propertyRefList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Key)) {
			this.parent.propertyRefList.append(this);
		}
	}
}

class Schema extends AnnotatedODataCSDLObject {
	actionList: list<Action>;
	alias: string | null;
	annotationsList: list<Annotations>;
	complexTypeList: list<ComplexType>;
	entityContainerList: list<EntityContainer>;
	entityTypeList: list<EntityType>;
	enumTypeList: list<EnumType>;
	functionList: list<Function>;
	namespace: string;
	termList: list<Term>;
	typeDefinitionList: list<TypeDefinition>;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.actionList = new list();
		this.alias = strOrNull(kwargs['alias']);
		this.annotationsList = new list();
		this.complexTypeList = new list();
		this.entityContainerList = new list();
		this.entityTypeList = new list();
		this.enumTypeList = new list();
		this.functionList = new list();
		this.namespace = kwargs['namespace'] || '';
		this.termList = new list();
		this.typeDefinitionList = new list();
	}

	entitySet(name: string): EntitySet | null {
		const isQual = (this.namespace.length > 0) && name.startsWith(this.namespace);
		for (const ec of this.entityContainerList) {
			for (const es of ec.entitySetList) {
				// if (es.name === name) {
				// 	return es;
				// }
				const cmp = isQual ?
					`${this.namespace}.${es.name}` :
					es.name;
				if (cmp === name) {
					return es;
				}
			}
		}
		return null;
	}

	entitySetForEntityType(entityTypeNameOrObj: EntityType | string): EntitySet | null {
		if (isString(entityTypeNameOrObj) && (entityTypeNameOrObj.length < 1)) {
			return null;
		}
		const et = isString(entityTypeNameOrObj) ?
			this.entityType(entityTypeNameOrObj) :
			entityTypeNameOrObj;
		if (!et) {
			return null;
		}
		const qn = et.qualifiedName();
		for (const ec of this.entityContainerList) {
			for (const es of ec.entitySetList) {
				if (es.entityType === qn) {
					return es;
				}
			}
		}
		return null;
	}

	entityType(name: string): EntityType | null {
		// Fociis.OData.Model.us_nc_new_hanover_county.taxparcel
		// us_nc_new_hanover_county.taxparcel
		// taxparcel
		const parts = name.split('.').filter(x => (x.trim().length > 0));
		let key: (obj: EntityType) => boolean;
		if (parts.length > 1) {
			// Fociis.OData.Model.us_nc_new_hanover_county.taxparcel
			// us_nc_new_hanover_county.taxparcel
			if (name.startsWith(this.namespace)) {
				// Fociis.OData.Model.us_nc_new_hanover_county.taxparcel
				key = (obj: EntityType) => (obj.qualifiedName() === name);
			} else if (this.namespace.endsWith(parts[0])) {
				// us_nc_new_hanover_county.taxparcel
				const mod = `${this.namespace}.${parts.slice(1).join('.')}`;
				key = (obj: EntityType) => (obj.qualifiedName() === mod);
			} else {
				key = (obj: EntityType) => (obj.name === name);
			}
		} else {
			// taxparcel
			key = (obj: EntityType) => (obj.name === name);
		}
		for (const et of this.entityTypeList) {
			if (key(et)) {
				return et;
			}
		}
		return null;
	}

	function(name: string): Function | null {
		const isQual = (this.namespace.length > 0) && name.startsWith(this.namespace);
		for (const f of this.functionList) {
			const cmp = isQual ?
				f.qualifiedName() :
				f.name;
			if (cmp === name) {
				return f;
			}
		}
		return null;
	}

	functionImport(name: string): FunctionImport | null {
		for (const ec of this.entityContainerList) {
			for (const fi of ec.functionImportList) {
				if (fi.name === name) {
					return fi;
				}
			}
		}
		return null;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof DataServices)) {
			this.parent.schemaList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof DataServices)) {
			this.parent.schemaList.append(this);
		}
	}

	term(name: string): Term | null {
		const isQual = (this.namespace.length > 0) && name.startsWith(this.namespace);
		for (const t of this.termList) {
			const cmp = isQual ?
				t.qualifiedName() :
				t.name;
			if (cmp === name) {
				return t;
			}
		}
		return null;
	}
}

class Annotations extends AnnotatedODataCSDLObject {
	qualifier: string | null;
	target: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.qualifier = strOrNull(kwargs['qualifier']);
		this.target = kwargs['target'] || '';
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.annotationsList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.annotationsList.append(this);
		}
	}
}

class StructType extends AnnotatedODataCSDLObject {
	abstract: boolean | null;
	baseType: string | null;
	name: string;
	navigationPropertyList: list<NavigationProperty>;
	openType: boolean | null;
	propertyList: list<Property>;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.abstract = toBooleanOrNull(kwargs['abstract']);
		this.baseType = strOrNull(kwargs['baseType']);
		this.name = kwargs['name'] || '';
		this.navigationPropertyList = new list();
		this.openType = toBooleanOrNull(kwargs['openType']);
		this.propertyList = new list();
	}

	property(index: number): Property | null {
		if ((index >= 0) && (index < this.propertyList.size())) {
			return this.propertyList.at(index);
		}
		return null;
	}

	propertyIndex(propNameOrObject: Property | string): number {
		const propName = isString(propNameOrObject) ?
			propNameOrObject :
			propNameOrObject.name;
		return this.propertyList.findIndex(
			x => (x.name === propName),
		);
	}

	qualifiedName(): string {
		const sch = this.schema();
		return sch ?
			`${sch.namespace}.${this.name}` :
			this.name;
	}
}

class EntityType extends StructType {
	hasStream: boolean | null;
	key: Key | null;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.key = null;
		this.hasStream = toBooleanOrNull(kwargs['hasStream']);
	}

	entitySet(): EntitySet | null {
		const sch = this.schema();
		return sch ?
			sch.entitySetForEntityType(
				this,
			) :
			null;
	}

	keyProperty(): Property | null {
		if (this.key && (this.key.propertyRefList.size() > 0)) {
			return this.key.propertyRefList.first().property();
		}
		return null;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.entityTypeList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.entityTypeList.append(this);
		}
	}

	supports(term: FociisODataVocabTerm): boolean {
		const root = this.edmx();
		if (!root) {
			return false;
		}
		const t = root.term(term);
		if (!t) {
			return false;
		}
		assert(t.type === EdmPrimitiveType.Boolean);
		const qn = t.qualifiedName();
		let anno: Annotation | null = null;
		for (const an of this.annotationList) {
			if (an.term === qn) {
				anno = an;
				break;
			}
		}
		if (anno) {
			assert(typeof anno.bool === 'boolean');
			return anno.bool;
		}
		if (typeof t.defaultValue === 'boolean') {
			return t.defaultValue;
		}
		return false;
	}
}

class OnDelete extends AnnotatedODataCSDLObject {
	action: ODataOnDeleteAction;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		const axn = kwargs['action'] || '';
		switch (axn) {
			case ODataOnDeleteAction.Cascade:
			case ODataOnDeleteAction.None:
			case ODataOnDeleteAction.SetNull:
			case ODataOnDeleteAction.SetDefault: {
				this.action = axn;
				break;
			}
			default: {
				this.action = ODataOnDeleteAction.None;
				break;
			}
		}
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof NavigationProperty) && (this.parent.onDelete === this)) {
			this.parent.onDelete = null;
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof NavigationProperty) && (this.parent.onDelete !== this)) {
			if (this.parent.onDelete) {
				this.parent.onDelete.setParent(null);
			}
			this.parent.onDelete = this;
		}
	}
}

class NavigationProperty extends AnnotatedODataCSDLObject {
	containsTarget: boolean | null;
	name: string;
	nullable: boolean | null;
	onDelete: OnDelete | null;
	partner: string | null;
	referentialConstraintList: list<ReferentialConstraint>;
	type: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.containsTarget = toBooleanOrNull(kwargs['containsTarget']);
		this.name = kwargs['name'] || '';
		this.nullable = toBooleanOrNull(kwargs['nullable']);
		this.onDelete = null;
		this.partner = strOrNull(kwargs['partner']);
		this.referentialConstraintList = new list();
		this.type = kwargs['type'] || '';
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.partner) {
			return;
		}
		if (this.parent && (this.parent instanceof StructType)) {
			this.parent.navigationPropertyList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof StructType)) {
			this.parent.navigationPropertyList.append(this);
		}
	}
}

class ReferentialConstraint extends AnnotatedODataCSDLObject {
	property: string;
	referencedProperty: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.property = kwargs['property'] || '';
		this.referencedProperty = kwargs['referencedProperty'] || '';
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent == this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof NavigationProperty)) {
			this.parent.referentialConstraintList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof NavigationProperty)) {
			this.parent.referentialConstraintList.append(this);
		}
	}
}

class ComplexType extends StructType {
	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.complexTypeList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.complexTypeList.append(this);
		}
	}
}

class Member extends AnnotatedODataCSDLObject {
	name: string;
	value: number;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.name = kwargs['name'] || '';
		const val = toIntOrNull(kwargs['value']);
		this.value = isNumber(val) ?
			val :
			0;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof EnumType)) {
			this.parent.memberList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof EnumType)) {
			this.parent.memberList.append(this);
		}
	}
}

class EnumType extends AnnotatedODataCSDLObject {
	isFlags: boolean | null;
	memberList: list<Member>;
	name: string;
	underlyingType: EdmPrimitiveType.Byte | EdmPrimitiveType.SByte | EdmPrimitiveType.Int16 | EdmPrimitiveType.Int32 | EdmPrimitiveType.Int64 | null;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.isFlags = toBooleanOrNull(kwargs['isFlags']);
		this.memberList = new list();
		this.name = kwargs['name'] || '';
		const typ = kwargs['underlyingType'];
		switch (typ) {
			case EdmPrimitiveType.Byte:
			case EdmPrimitiveType.SByte:
			case EdmPrimitiveType.Int16:
			case EdmPrimitiveType.Int32:
			case EdmPrimitiveType.Int64: {
				this.underlyingType = typ;
				break;
			}
			default: {
				this.underlyingType = null;
			}
		}
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.enumTypeList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.enumTypeList.append(this);
		}
	}
}

class TypeDefinition extends AnnotatedODataCSDLObject {
	name: string;
	underlyingType: EdmPrimitiveType;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.name = kwargs['name'] || '';
		const typ = kwargs['underlyingType'] || '';
		this.underlyingType = (typ === '') ?
			EdmPrimitiveType.String :
			<EdmPrimitiveType>typ;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.typeDefinitionList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.typeDefinitionList.append(this);
		}
	}
}

class ParameterReturnTypeType extends AnnotatedODataCSDLObject {
	maxLength: number | null;
	nullable: boolean | null;
	precision: number | null;
	scale: number | 'floating' | 'variable' | null;
	srid: string | null;
	type: string;
	unicode: boolean | null;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.maxLength = toIntOrNull(kwargs['maxLength']);
		this.nullable = toBooleanOrNull(kwargs['nullable']);
		this.precision = toIntOrNull(kwargs['precision']);
		const scale = kwargs['scale'];
		this.scale = (isString(scale) && ((scale === 'floating') || (scale === 'variable'))) ?
			scale :
			toIntOrNull(scale);
		this.srid = strOrNull(kwargs['srid']);
		this.type = kwargs['type'] || '';
		this.unicode = toBooleanOrNull(kwargs['unicode']);
	}
}

class Parameter extends ParameterReturnTypeType {
	name: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.name = kwargs['name'] || '';
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof ActionFunctionType)) {
			this.parent.parameterList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof ActionFunctionType)) {
			this.parent.parameterList.append(this);
		}
	}
}

class ReturnType extends ParameterReturnTypeType {
	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof ActionFunctionType) && (this.parent.returnType === this)) {
			this.parent.returnType = null;
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof ActionFunctionType) && (this.parent.returnType !== this)) {
			if (this.parent.returnType) {
				this.parent.returnType.setParent(null);
			}
			this.parent.returnType = this;
		}
	}
}

class ActionFunctionType extends AnnotatedODataCSDLObject {
	entitySetPath: string | null;
	isBound: boolean | null;
	name: string;
	parameterList: list<Parameter>;
	returnType: ReturnType | null;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.entitySetPath = strOrNull(kwargs['entitySetPath']);
		this.isBound = toBooleanOrNull(kwargs['isBound']);
		this.name = kwargs['name'] || '';
		this.parameterList = new list();
		this.returnType = null;
	}

	qualifiedName(): string {
		const sch = this.schema();
		return sch ?
			`${sch.namespace}.${this.name}` :
			this.name;
	}
}

class Action extends ActionFunctionType {
	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.actionList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.actionList.append(this);
		}
	}
}

class Function extends ActionFunctionType {
	isComposable: boolean | null;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.isComposable = toBooleanOrNull(kwargs['isComposable']);
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.functionList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.functionList.append(this);
		}
	}
}

class ActionImportFunctionImport extends AnnotatedODataCSDLObject {
	entitySet: string | null;
	name: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.entitySet = strOrNull(kwargs['entitySet']);
		this.name = kwargs['name'] || '';
	}
}

class ActionImport extends ActionImportFunctionImport {
	action: string;

	constructor(kwargs: Kwargs) {
		super(kwargs);
		this.action = kwargs['action'] || '';
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof EntityContainer)) {
			this.parent.actionImportList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof EntityContainer)) {
			this.parent.actionImportList.append(this);
		}
	}
}

class FunctionImport extends ActionImportFunctionImport {
	function: string;
	includeInServiceDocument: boolean | null;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.function = kwargs['function'] || '';
		this.includeInServiceDocument = toBooleanOrNull(kwargs['includeInServiceDocument']);
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof EntityContainer)) {
			this.parent.functionImportList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof EntityContainer)) {
			this.parent.functionImportList.append(this);
		}
	}
}

class NavigationPropertyBinding extends ODataCSDLObject {
	path: string;
	target: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.path = kwargs['path'] || '';
		this.target = kwargs['target'] || '';
	}

	setParent(parent: ODataCSDLObject | Singleton | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && ((this.parent instanceof EntitySet) || (this.parent instanceof Singleton))) {
			this.parent.navigationPropertyBindingList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && ((this.parent instanceof EntitySet) || (this.parent instanceof Singleton))) {
			this.parent.navigationPropertyBindingList.append(this);
		}
	}
}

export class EntitySet extends AnnotatedODataCSDLObject {
	entityType: string;
	includeInServiceDocument: boolean | null;
	name: string;
	navigationPropertyBindingList: list<NavigationPropertyBinding>;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.entityType = kwargs['entityType'] || '';
		this.includeInServiceDocument = toBooleanOrNull(kwargs['includeInServiceDocument']);
		this.name = kwargs['name'] || '';
		this.navigationPropertyBindingList = new list();
	}

	entityTypeObject(): EntityType | null {
		const sch = this.schema();
		if (!sch) {
			return null;
		}
		for (const et of sch.entityTypeList) {
			if (et.qualifiedName() === this.entityType) {
				return et;
			}
		}
		return null;
	}

	qualifiedName(): string {
		const sch = this.schema();
		return sch ?
			`${sch.namespace}.${this.name}` :
			this.name;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof EntityContainer)) {
			this.parent.entitySetList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof EntityContainer)) {
			this.parent.entitySetList.append(this);
		}
	}
}

class Singleton extends AnnotatedODataCSDLObject {
	name: string;
	navigationPropertyBindingList: list<NavigationPropertyBinding>;
	nullable: boolean | null;
	type: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.name = kwargs['name'] || '';
		this.navigationPropertyBindingList = new list();
		this.nullable = toBooleanOrNull(kwargs['nullable']);
		this.type = kwargs['type'] || '';
	}

	qualifiedName(): string {
		const sch = this.schema();
		return sch ?
			`${sch.namespace}.${this.name}` :
			this.name;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof EntityContainer)) {
			this.parent.singletonList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof EntityContainer)) {
			this.parent.singletonList.append(this);
		}
	}
}

class EntityContainer extends AnnotatedODataCSDLObject {
	actionImportList: list<ActionImport>;
	entitySetList: list<EntitySet>;
	extends: string | null;
	functionImportList: list<FunctionImport>;
	name: string;
	singletonList: list<Singleton>;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.actionImportList = new list();
		this.entitySetList = new list();
		this.extends = strOrNull(kwargs['extends']);
		this.functionImportList = new list();
		this.name = kwargs['name'] || '';
		this.singletonList = new list();
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.entityContainerList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.entityContainerList.append(this);
		}
	}
}

class Term extends AnnotatedODataCSDLObject {
	appliesTo: string | null;
	baseTerm: string | null;
	defaultValue: any;
	maxLength: number | null;
	name: string;
	nullable: boolean | null;
	precision: number | null;
	scale: number | 'floating' | 'variable' | null;
	srid: string | null;
	type: string;
	unicode: boolean | null;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.appliesTo = strOrNull(kwargs['appliesTo']);
		this.baseTerm = strOrNull(kwargs['baseTerm']);
		this.defaultValue = null;
		this.maxLength = toIntOrNull(kwargs['maxLength']);
		this.name = kwargs['name'] || '';
		this.nullable = toBooleanOrNull(kwargs['nullable']);
		this.precision = toIntOrNull(kwargs['precision']);
		const scale = kwargs['scale'];
		this.scale = (isString(scale) && ((scale === 'floating') || (scale === 'variable'))) ?
			scale :
			toIntOrNull(scale);
		this.srid = strOrNull(kwargs['srid']);
		this.type = kwargs['type'] || '';
		this.unicode = toBooleanOrNull(kwargs['unicode']);
		if (this.type.length > 0) {
			const val = toDefaultValue(
				kwargs['defaultValue'],
				this.type,
			);
			this.defaultValue = (val === undefined) ?
				null :
				val;
		}
	}

	qualifiedName(): string {
		const sch = this.schema();
		return sch ?
			`${sch.namespace}.${this.name}` :
			this.name;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.termList.removeAll(this);
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Schema)) {
			this.parent.termList.append(this);
		}
	}
}

class DataServices extends ODataCSDLObject {
	schemaList: list<Schema>;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.schemaList = new list();
	}

	entitySet(name: string): EntitySet | null {
		const isQual = name.indexOf('.') >= 0;
		for (const sch of this.schemaList) {
			if (isQual) {
				if (name.startsWith(sch.namespace)) {
					return sch.entitySet(name);
				}
			} else {
				const et = sch.entitySet(name);
				if (et) {
					return et;
				}
			}
		}
		return null;
	}

	entitySetForEntityType(name: string): EntitySet | null {
		const isQual = name.indexOf('.') >= 0;
		for (const sch of this.schemaList) {
			if (isQual) {
				if (name.startsWith(sch.namespace)) {
					return sch.entitySet(name);
				}
			} else {
				const et = sch.entitySet(name);
				if (et) {
					return et;
				}
			}
		}
		return null;
	}

	entityType(name: string): EntityType | null {
		/**
		 * If name is qualified, returns object from its Schema. Otherwise
		 * returns the first object matching name.
		 */
		const isQual = (name.length > 0) && (name.indexOf('.') >= 0);
		for (const sch of this.schemaList) {
			if (isQual && name.startsWith(sch.namespace)) {
				return sch.entityType(name);
			} else {
				const et = sch.entityType(name);
				if (et) {
					return et;
				}
			}
		}
		return null;
	}

	function(name: string): Function | null {
		const isQual = name.indexOf('.') >= 0;
		for (const sch of this.schemaList) {
			if (isQual) {
				if (name.startsWith(sch.namespace)) {
					return sch.function(name);
				}
			} else {
				const et = sch.function(name);
				if (et) {
					return et;
				}
			}
		}
		return null;
	}

	functionImport(name: string): FunctionImport | null {
		const isQual = name.indexOf('.') >= 0;
		for (const sch of this.schemaList) {
			if (isQual) {
				if (name.startsWith(sch.namespace)) {
					return sch.functionImport(name);
				}
			} else {
				const et = sch.functionImport(name);
				if (et) {
					return et;
				}
			}
		}
		return null;
	}

	setParent(parent: ODataCSDLObject | null): void {
		if (parent === this.parent) {
			return;
		}
		if (this.parent && (this.parent instanceof Edmx) && (this.parent.dataServices === this)) {
			this.parent.dataServices = new DataServices();
		}
		super.setParent(parent);
		if (this.parent && (this.parent instanceof Edmx) && (this.parent.dataServices !== this)) {
			if (this.parent.dataServices) {
				this.parent.dataServices.setParent(null);
			}
			this.parent.dataServices = this;
		}
	}

	term(name: string): Term | null {
		/**
		 * If name is qualified, returns object from its Schema. Otherwise
		 * returns the first object matching name.
		 */
		const isQual = name.indexOf('.') >= 0;
		for (const sch of this.schemaList) {
			if (isQual) {
				if (name.startsWith(sch.namespace)) {
					return sch.term(name);
				}
			} else {
				const et = sch.term(name);
				if (et) {
					return et;
				}
			}
		}
		return null;
	}
}

export class Edmx extends ODataCSDLObject {
	dataServices: DataServices;
	version: string;

	constructor(kwargs: Kwargs = {}) {
		super(kwargs);
		this.dataServices = new DataServices();
		this.version = kwargs['version'] || '';
		this.dataServices.setParent(this);
	}

	entitySet(name: string): EntitySet | null {
		return this.dataServices.entitySet(name);
	}

	entityType(name: string): EntityType | null {
		return this.dataServices.entityType(name);
	}

	function(name: string): Function | null {
		return this.dataServices.function(name);
	}

	functionImport(name: string): FunctionImport | null {
		return this.dataServices.functionImport(name);
	}

	term(name: string): Term | null {
		return this.dataServices.term(name);
	}
}

function isODataCSDLObject(obj: any): obj is ODataCSDLObject {
	const syms = obj ?
		Object.getOwnPropertySymbols(obj) :
		[];
	return (syms.length === 1) && (syms[0] === objSym);
}

function isString(obj: any): obj is string {
	return typeof obj === 'string';
}

function strOrNull(obj: any): string | null {
	return isString(obj) ?
		obj :
		null;
}

function toBooleanOrNull(obj: any): boolean | null {
	if (!obj && (obj !== '')) {
		return null;
	}
	if (isString(obj)) {
		return ((obj === 'true') || (obj === 'false')) ?
			(obj === 'true') :
			null;
	}
	return (typeof obj === 'boolean') ?
		obj :
		null;
}

function toDefaultValue(val: any, typ: string): any {
	switch (typ) {
		case EdmPrimitiveType.Boolean:
			return ((val === 'true') || (val === 'false')) ?
				(val === 'true') :
				val;
		case EdmPrimitiveType.Double:
			if ((typeof val === 'string') || isNumber(val)) {
				return toFloatOrNull(val);
			}
			return val;
		case EdmPrimitiveType.Int16:
		case EdmPrimitiveType.Int32:
		case EdmPrimitiveType.Int64:
			if ((typeof val === 'string') || isNumber(val)) {
				return toIntOrNull(val);
			}
			return val;
		default: {
			return val;
		}
	}
}

function toFloatOrNull(obj: any): number | null {
	if (!obj && (obj !== 0)) {
		return null;
	}
	if (isString(obj)) {
		const n = Number.parseFloat(obj);
		if (isNumber(n)) {
			return n;
		}
	}
	return isNumber(obj) ?
		obj :
		null;
}

function toIntOrNull(obj: any): number | null {
	if (!obj && (obj !== 0)) {
		return null;
	}
	if (isString(obj)) {
		const n = Number.parseInt(obj);
		if (isNumber(n)) {
			return n;
		}
	}
	return (isNumber(obj) && Number.isInteger(obj)) ?
		obj :
		null;
}

const types = [
	Annotation,
	Key,
	Property,
	PropertyRef,
	Schema,
	Annotations,
	EntityType,
	OnDelete,
	NavigationProperty,
	ReferentialConstraint,
	ComplexType,
	Member,
	EnumType,
	TypeDefinition,
	Parameter,
	ReturnType,
	Action,
	Function,
	ActionImport,
	FunctionImport,
	NavigationPropertyBinding,
	EntitySet,
	Singleton,
	EntityContainer,
	Term,
	DataServices,
	Edmx,
];
const typeMap = new Map(types.map(x => ([x.name, x])));

function parseXMLElement(elem: Element): ODataCSDLObject | null {
	const cls = typeMap.get(elem.localName);
	if (!cls) {
		logger.warning('parseXMLElement: No type class for %s', elem.localName);
		return null;
	}
	const kwargs: Kwargs = {};
	for (let i = 0; i < elem.attributes.length; ++i) {
		const elAttr = elem.attributes[i];
		if (elAttr.namespaceURI === xmlNs) {
			continue;
		}
		const key = `${elAttr.localName[0].toLowerCase()}${elAttr.localName.slice(1)}`;
		kwargs[key] = elAttr.value;
	}
	const obj = new cls(kwargs);
	for (const childEl of elem.children) {
		const rv = parseXMLElement(childEl);
		if (rv) {
			rv.setParent(obj);
		}
	}
	return obj;
}

export function parseCSDLDocument(str: string): Edmx | null {
	const parser = new DOMParser();
	const doc = parser.parseFromString(
		str,
		'application/xml',
	);
	const rv = parseXMLElement(doc.documentElement);
	assert(rv instanceof Edmx);
	return rv;
}
