import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../obj';
import {FancyList, FancyListItem} from './list';
import {pixelString} from '../util';
import {getLogger} from '../logging';
import {list} from '../tools';

const logger = getLogger('ui.completer');

export class CompleterPrivate extends ElObjPrivate {
	items: list<ICompleterItem>;
	list: FancyList | null;

	constructor() {
		super();
		this.items = new list();
		this.list = null;
		this.temporarilyAppendToDocumentBody = true;
	}

	init(opts: Partial<CompleterOpts>): void {
		super.init(opts);
		const q = this.q;
		q.hide();
		this.list = new FancyList({
			parent: q,
			twoLine: true,
		});
		Obj.connect(
			this.list, 'itemActivated',
			q, 'listItemActivated',
		);
	}

	get q(): Completer {
		return <Completer>super.q;
	}
}

export interface CompleterOpts extends ElObjOpts {
	dd: CompleterPrivate;
}

@OBJ
export class Completer extends ElObj {
	constructor(opts: Partial<CompleterOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-completer',
		);
		opts.dd = opts.dd || new CompleterPrivate();
		super(opts);
	}

	get d(): CompleterPrivate {
		return <CompleterPrivate>super.d;
	}

	@SIGNAL
	activated(index: number): void {
	}

	@SLOT
	protected listItemActivated(item: FancyListItem): void {
		const d = this.d;
		if (!d.list) {
			return;
		}
		const index = d.list.index(item);
		if (index >= 0) {
			d.list.currentRow();
			for (let i = 0; i < d.items.size(); ++i) {
				if (i === index) {
					this.activated(index);
					return;
				}
			}
		} else {
			logger.warning('listItemActivated: List returned invalid index for item.');
		}
	}

	setItems(items: Iterable<ICompleterItem>): void {
		const d = this.d;
		d.items.clear();
		if (d.list) {
			d.list.clear();
		}
		d.items = new list(items);
		if (d.items.isEmpty()) {
			this.hide();
		} else if (d.list) {
			this.show();
			for (const item of d.items) {
				d.list.addItem({
					secondaryText: item.label,
					text: item.text,
				});
			}
		}
		this.updateHeight();
	}

	updateHeight(): void {
		const d = this.d;
		if (!d.list) {
			return;
		}
		let height: number = 0;
		if (d.list.count() > 0) {
			const item = d.list.item(0);
			if (item) {
				height = Math.min(336, item.rect().height * d.list.count());
			} else {
				logger.error('updateHeight: Non-empty list does not return first item.');
			}
		}
		const listStyle = d.list.computedStyle();
		const listHeightStr = listStyle.height.trim().toLowerCase();
		const heightWithoutPadding = extractStyleNumber(listHeightStr);
		const heightWithPadding = d.list.rect().height;
		const paddingHeight = heightWithPadding - heightWithoutPadding;
		if (paddingHeight > 0) {
			height += paddingHeight;
		}
		this.setStyleProperty(
			'height',
			pixelString(height),
		);
	}

	updatePosition(): void {
		const par = this.parentEl();
		if (!par) {
			return;
		}
		const bodyAbsY = Math.abs(
			document.body.getBoundingClientRect().y,
		);
		const {height, width, x, y} = par.rect();
		this.setStyleProperty(
			'top',
			pixelString(y + height + bodyAbsY),
		);
		this.setStyleProperty(
			'left',
			pixelString(x - 1),
		);
		this.setStyleProperty(
			'width',
			pixelString(width),
		);
	}
}

function extractStyleNumber(s: string): number {
	s = s.replace(/px$/, '');
	return (s.indexOf('.') >= 0) ?
		Number.parseFloat(s) :
		Number.parseInt(s);
}
