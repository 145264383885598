import {Feature} from './feature';

export class LineString extends Feature {
	addCoordinate(path: string, longitude: number, latitude: number): void {
		this.changed();
		this.coordinates.splice(
			parseInt(path, 10),
			0,
			[longitude, latitude]);
	}

	getCoordinate(path: string): GeoJsonPosition {
		return <GeoJsonPosition>this.coordinates[parseInt(path, 10)];
	}

	isValid(): boolean {
		return this.coordinates.length > 1;
	}

	removeCoordinate(path: string): void {
		this.changed();
		this.coordinates.splice(parseInt(path, 10), 1);
	}

	updateCoordinate(path: string, longitude: number, latitude: number): void {
		this.coordinates[parseInt(path, 10)] = [longitude, latitude];
		this.changed();
	}
}
