import type {IMapboxDrawContext} from '../mapboxdraw';
import {hat} from '../../../../util';
type GeomNoColl = GeoJsonPoint | GeoJsonMultiPoint | GeoJsonLineString | GeoJsonMultiLineString | GeoJsonPolygon | GeoJsonMultiPolygon;
export class Feature {
	coordinates: GeomNoColl['coordinates'];
	ctx: IMapboxDrawContext;
	id: string;
	properties: {[key: string]: any};
	type: GeoJsonGeometry['type'];

	constructor(ctx: IMapboxDrawContext, feature: GeoJsonFeature<GeomNoColl>) {
		this.coordinates = feature.geometry.coordinates;
		this.ctx = ctx;
		this.id = (feature.id === undefined) ?
			hat() :
			String(feature.id);
		this.properties = feature.properties || {};
		this.type = feature.geometry.type;
	}

	addCoordinate(path: string, longitude: number, latitude: number): void {
	}

	changed(): void {
		this.ctx.store.featureChanged(this.id);
	}

	getCoordinate(path?: string): GeoJsonPosition {
		return [];
	}

	getCoordinates(): GeomNoColl['coordinates'] {
		return JSON.parse(JSON.stringify(this.coordinates));
	}

	incomingCoords(coords: GeomNoColl['coordinates']): void {
		this.setCoordinates(coords);
	}

	internal(modeName: string): FeatureInternalFeature {
		const properties: FeatureInternalFeatureProperties = {
			active: 'false',
			center: this.properties.center,
			circle: this.properties.circle,
			id: this.id,
			meta: 'feature',
			mode: modeName,
		};
		if (this.ctx.options.userProperties) {
			for (const name in this.properties) {
				properties[`user_${name}`] = this.properties[name];
			}
		}
		return <FeatureInternalFeature>{
			type: 'Feature',
			properties,
			geometry: {
				coordinates: this.getCoordinates(),
				type: this.type,
			},
		};
	}

	isValid(): boolean {
		return false;
	}

	removeCoordinate(path: string): void {
	}

	setCoordinates(coords: GeomNoColl['coordinates']): void {

		this.coordinates = coords;
		this.changed();
	}

	setProperty(propertyName: string, value: any): void {
		this.properties[propertyName] = value;
	}

	toGeoJSON<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeature<G, P> {
		return JSON.parse(JSON.stringify({
			geometry: <G>{
				coordinates: this.getCoordinates(),
				type: this.type,
			},
			id: this.id,
			properties: <P>this.properties,
			type: 'Feature',
		}));
	}

	updateCoordinate(path: string, longitude: number, latitude: number): void {
	}
}
