import {leStore} from './store';
import {UIComponentType, UnitOfMeasurement} from './constants';

interface IMeasured {
	unit: UnitOfMeasurement;
}

interface ISized extends IMeasured {
	height: number;
	width: number;
}

interface IPositionedMeasure extends IMeasured {
	x: number;
	y: number;
}

export interface IUIComponent {
	coordinates: IPositionedMeasure | null;
	dimensions: ISized | null;
	id: string;
	offset: IPositionedMeasure | null;
	parent: string | null;
	type: UIComponentType;
}

export interface IWindowUIComponent extends IUIComponent {
	dimensions: ISized;
	minimized: boolean;
	offset: IPositionedMeasure;
	title: string;
	type: UIComponentType.Window;
}

export type Comps = IWindowUIComponent;

type RepoObjId = number | string;
const keyEncodingPrefix = 'lb.ui.';
const keyPrefixPatt = new RegExp(`^${keyEncodingPrefix}`);
const mapCfgId = 'map.cfg';

function decodeKey(encodedKey: string): string {
	return encodedKey.replace(keyPrefixPatt, '');
}

function encodeKey(decodedKey: RepoObjId): string {
	return `${keyEncodingPrefix}${decodedKey}`;
}

function isEncodedUiRepoKey(key: string): boolean {
	return keyPrefixPatt.test(key);
}

class UIRepo {
	static emptyWindowComponent(): IWindowUIComponent {
		return {
			coordinates: null,
			dimensions: {
				height: 0,
				width: 0,
				unit: UnitOfMeasurement.Pixel,
			},
			id: '',
			minimized: false,
			offset: {
				x: 0,
				y: 0,
				unit: UnitOfMeasurement.Pixel,
			},
			parent: null,
			title: '',
			type: UIComponentType.Window,
		};
	}

	static version: string = '1';

	component(id: RepoObjId | null): Comps | null {
		if (id === null) {
			return null;
		}
		return this._component(
			encodeKey(id),
		);
	}

	protected _component(encodedKey: string): Comps | null {
		const s = leStore.getItem(encodedKey);
		return (s === null) ?
			null :
			this.unmarshal(s);
	}

	*components(): IterableIterator<Comps> {
		const count = leStore.count();
		for (let i = 0; i < count; ++i) {
			const encodedKey = leStore.key(i);
			if (encodedKey && isEncodedUiRepoKey(encodedKey)) {
				const obj = this._component(encodedKey);
				if (obj) {
					yield obj;
				}
			}
		}
	}

	deleteComponent(id: RepoObjId | null): void {
		if (id === null) {
			return;
		}
		leStore.deleteItem(
			encodeKey(id),
		);
	}

	emptyNotSavedWindowComponent(): IWindowUIComponent {
		return (<typeof UIRepo>this.constructor).emptyWindowComponent();
	}

	protected encodedKeyExists(encodedKey: string): boolean {
		return leStore.getItem(encodedKey) !== null;
	}

	*keys(): IterableIterator<string> {
		for (const encodedKey of leStore.keys()) {
			if (isEncodedUiRepoKey(encodedKey)) {
				yield decodeKey(encodedKey);
			}
		}
	}

	mapCfg(): IInteractiveMapConfiguration | null {
		const s = leStore.getItem(
			encodeKey(mapCfgId),
		);
		if (s !== null) {
			return this.unmarshal(s);
		}
		return null;
	}

	protected marshal(obj: any): string {
		return JSON.stringify(obj);
	}

	saveComponent(data: Comps): void {
		leStore.setItem(
			encodeKey(data.id),
			this.marshal(data),
		);
	}

	saveMapCfg(data: IInteractiveMapConfiguration): void {
		leStore.setItem(
			encodeKey(mapCfgId),
			this.marshal(data),
		);
	}

	protected unmarshal<T>(str: string): T {
		return JSON.parse(str);
	}

	updateComponent(id: RepoObjId, data: Partial<Comps>): boolean {
		const obj = this.component(id);
		if (obj) {
			this.saveComponent({
				...obj,
				...data,
			});
			return true;
		}
		return false;
	}

	updateMapCfg(data: Partial<IInteractiveMapConfiguration>): boolean {
		const obj = this.mapCfg();
		if (obj) {
			this.saveMapCfg({
				...obj,
				...data,
			});
			return true;
		}
		return false;
	}

	windowComponent(id: RepoObjId | null): IWindowUIComponent | null {
		const obj = this.component(id);
		return (obj && (obj.type === UIComponentType.Window)) ?
			obj :
			null;
	}
}

export const uiRepo: UIRepo = new UIRepo();
