import {MDCSegmentedButton, MDCSegmentedButtonEvent} from '@material/segmented-button';

import {ElObj, ElObjOpts} from '../elobj';
import {OBJ, SIGNAL} from '../obj';
import {iterableToArray} from '../util';
import {list} from '../tools';
import {getLogger} from '../logging';

const logger = getLogger('ui.segmentedbutton');

interface SegmentOpts {
	icon: string;
	label: string;
	selected: boolean;
}

interface SegmentedButtonGroupOpts extends ElObjOpts {
	multiSelect: boolean;
	segments: Iterable<Partial<SegmentOpts>>
}

@OBJ
export class SegmentedButtonGroup extends ElObj {
	private buttons: list<SegmentedButton>;
	private ctrl: MDCSegmentedButton | null;
	private multi: boolean;

	constructor(opts: Partial<SegmentedButtonGroupOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['role', opts.multiSelect ? 'group' : 'radiogroup'],
		);
		const classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-segmented-button',
			'lb-segmented-button',
		);
		if (!opts.multiSelect) {
			classNames.unshift('mdc-segmented-button--single-select');
		}
		opts.classNames = classNames;
		super(opts);
		this.buttons = new list<SegmentedButton>();
		this.ctrl = null;
		this.multi = Boolean(opts.multiSelect);
		if (opts.segments) {
			this.setSegments(opts.segments);
		}
		this.addEventListener('change');
	}

	private addButton(data: Partial<SegmentOpts>): void {
		const {icon, label, selected} = data;
		const btn = new SegmentedButton({
			icon,
			label,
			multiSelect: this.multi,
			parent: this,
			selected,
		});
		btn.show();
		this.buttons.append(btn);
	}

	clear(): void {
		for (const obj of this.buttons) {
			obj.destroy();
		}
		this.buttons.clear();
		super.clear();
	}

	@SIGNAL
	private clicked(index: number, checked: boolean, segmentId: string): void {
	}

	destroy(): void {
		this.destroyCtrl();
		this.clear();
		super.destroy();
	}

	private destroyCtrl(): void {
		if (this.ctrl) {
			this.ctrl.destroy();
		}
		this.ctrl = null;
	}

	protected _domChangeEvent(event: Event): void {
		super._domChangeEvent(event);
		const {index, selected, segmentId} = (<MDCSegmentedButtonEvent>event).detail;
		this.clicked(index, selected, segmentId || '');
	}

	isSegmentSelected(index: number): boolean {
		return this.ctrl ?
			this.ctrl.isSegmentSelected(index) :
			false;
	}

	segmentIndex(segment: SegmentedButton): number {
		return this.buttons.indexOf(segment);
	}

	selectSegment(index: number): void {
		this.ctrl && this.ctrl.selectSegment(index);
	}

	setSegments(segments: Iterable<Partial<SegmentOpts>>): void {
		this.destroyCtrl();
		this.clear();
		const segs = iterableToArray(segments);
		if (!this.multi && (segs.length > 0) && (segs.filter(s => Boolean(s.selected)).length < 1)) {
			logger.warning('setSegments: Radio button group without defining selected button.');
			segs[0].selected = true;
		}
		for (const obj of segs) {
			this.addButton(obj);
		}
		if (!this.buttons.isEmpty()) {
			this.ctrl = new MDCSegmentedButton(<HTMLElement>this.element());
		}
	}
}

interface SegmentedButtonOpts extends ElObjOpts {
	icon: string;
	label: string;
	multiSelect: boolean;
	selected: boolean;
	text: string;
}

@OBJ
class SegmentedButton extends ElObj {
	static tagName: TagName = 'button';

	private iconEl: ElObj | null;
	private labelEl: ElObj | null;
	private multi: boolean;

	constructor(opts: Partial<SegmentedButtonOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['type', 'button'],
		);
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-segmented-button__segment',
		);
		super(opts);
		this.multi = Boolean(opts.multiSelect);
		this.iconEl = null;
		this.labelEl = null;
		if (opts.icon) {
			this.setIcon(opts.icon);
		}
		if (opts.label) {
			this.setLabel(opts.label);
		} else if (opts.text) {
			this.setLabel(opts.text);
		}
		this.setSelected(Boolean(opts.selected));
	}

	destroy(): void {
		if (this.iconEl) {
			this.iconEl.destroy();
		}
		this.iconEl = null;
		if (this.labelEl) {
			this.labelEl.destroy();
		}
		this.labelEl = null;
		super.destroy();
	}

	icon(): string {
		return this.iconEl ?
			this.iconEl.text() :
			'';
	}

	isChecked(): boolean {
		return this.isSelected();
	}

	isSelected(): boolean {
		return this.hasClass('mdc-segmented-button__segment--selected');
	}

	setChecked(checked: boolean): void {
		this.setSelected(checked);
	}

	setIcon(icon: string | null): void {
		const str = (icon || '').trim();
		if (str.length < 1) {
			if (this.iconEl) {
				this.iconEl.destroy();
			}
			this.iconEl = null;
			return;
		}
		if (!this.iconEl) {
			this.iconEl = new ElObj({
				classNames: [
					'material-icons',
					'mdc-segmented-button__icon',
				],
				tagName: 'i',
			});
			this.iconEl.show();
			this.iconEl.setParent(this, 0);
		}
		this.iconEl.setText(str);
	}

	setLabel(label: string | null): void {
		const str = (label || '').trim();
		if (str.length < 1) {
			if (this.labelEl) {
				this.labelEl.destroy();
			}
			this.labelEl = null;
			return;
		}
		if (!this.labelEl) {
			this.labelEl = new ElObj({
				classNames: 'mdc-segmented-button__label',
				tagName: 'span',
			});
			this.labelEl.show();
			this.labelEl.setParent(this, 1);
		}
		this.labelEl.setText(str);
	}

	setSelected(checked: boolean): void {
		this.setClass(checked, 'mdc-segmented-button__segment--selected');
		if (this.multi) {
			this.setAttribute('aria-pressed', checked ? 'true' : 'false');
		} else {
			this.setAttribute('aria-checked', checked ? 'true' : 'false');
		}
	}

	setText(text?: string | null): void {
		this.setLabel(text || null);
	}

	text(): string {
		return this.labelEl ?
			this.labelEl.text() :
			'';
	}
}
