import {ElObj, ElObjOpts, ElObjPrivate} from '../../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {list} from '../../../tools';
import {HideEvt, ShowEvt} from '../../../evt';

class ListPrivate extends ElObjPrivate {
	checkable: boolean;
	indentation: number;

	constructor() {
		super();
		this.checkable = false;
		this.indentation = 40;
	}

	init(opts: Partial<ElObjOpts>): void {
		super.init(opts);
		this.q.setStyleProperty('margin-block', 0);
		this.setIndentationStyle(this.indentation);
	}

	get q(): List {
		return <List>super.q;
	}

	setIndentationStyle(indentation: number): void {
		this.q.setStyleProperty(
			'padding-inline-start',
			indentation,
		);
	}
}

@OBJ
export class List extends ElObj {
	static tagName: TagName = 'ul';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.dd = new ListPrivate();
		super(opts);
	}

	addItem(item: ListItem): void {
		this.insertItem(
			this.children().size(),
			item,
		);
	}

	children(): list<ListItem> {
		return <list<ListItem>>super.children();
	}

	count(): number {
		return this.children().size();
	}

	get d(): ListPrivate {
		return <ListPrivate>super.d;
	}

	destroy(): void {
		const par = this.parentListItem();
		if (par && (par.d.childTree === this)) {
			par.d.childTree = null;
		}
		super.destroy();
	}

	indentation(): number {
		return this.d.indentation;
	}

	insertItem(index: number, item: ListItem): void {
		const d = this.d;
		if ((index >= 0) && (index <= d.children.size())) {
			if ((index < d.children.size()) && (item === d.children.at(index))) {
				return;
			}
			item.setCheckable(d.checkable);
			item.setParent(this, index);
			item.show();
		}
	}

	isCheckable(): boolean {
		return this.d.checkable;
	}

	item(index: number): ListItem | null {
		if ((index >= 0) && (index < this.children().size())) {
			return this.children().at(index);
		}
		return null;
	}

	itemIndex(item: ListItem | null): number {
		return item ?
			this.children().indexOf(item) :
			-1;
	}

	parentListItem(): ListItem | null {
		const par = this.parentEl();
		return (par && (par instanceof ListItem)) ?
			par :
			null;
	}

	removeItem(index: number): void {
		const item = this.item(index);
		if (item) {
			item.destroy();
		}
	}

	setCheckable(checkable: boolean): void {
		const d = this.d;
		if (checkable === d.checkable) {
			return;
		}
		d.checkable = checkable;
		for (const item of this.children()) {
			item.setCheckable(d.checkable);
		}
	}

	setIndentation(indentation: number): void {
		const d = this.d;
		if (indentation === d.indentation) {
			return;
		}
		d.indentation = indentation;
		d.setIndentationStyle(d.indentation);
	}
}

class ListItemPrivate extends ElObjPrivate {
	checkbox: LabeledCheckbox | null;
	childTree: List | null;
	expanded: boolean;
	expHandle: ExpansionHandle;

	constructor() {
		super();
		this.checkbox = null;
		this.childTree = null;
		this.expanded = false;
		this.expHandle = new ExpansionHandle();
	}

	init(opts: Partial<ListItemOpts>): void {
		super.init(opts);
		const q = this.q;
		Obj.connect(
			this.expHandle, 'clicked',
			q, '_expansionHandleClicked',
		);
		this.expHandle.setParent(q, 0);
		this.expHandle.show();
	}

	get q(): ListItem {
		return <ListItem>super.q;
	}
}

interface ListItemOpts extends ElObjOpts {
	dd: ListItemPrivate;
}

@OBJ
export class ListItem extends ElObj {
	static tagName: TagName = 'li';

	constructor(opts: Partial<ListItemOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-tree-list-item',
		);
		opts.dd = opts.dd || new ListItemPrivate();
		super(opts);
	}

	addItem(item: ListItem): void {
		const d = this.d;
		this.insertItem(
			d.childTree ?
				d.childTree.count() :
				0,
			item,
		);
	}

	@SIGNAL
	private checkStateChanged(item: ListItem): void {
	}

	@SLOT
	private _checkStateChanged(): void {
		this.checkStateChanged(this);
	}

	get d(): ListItemPrivate {
		return <ListItemPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.checkbox = null;
		d.childTree = null;
		super.destroy();
	}

	@SLOT
	private _expansionHandleClicked(): void {
		this.setExpanded(!this.isExpanded());
	}

	insertItem(index: number, item: ListItem): void {
		if (index < 0) {
			return;
		}
		const d = this.d;
		if ((index > 0) && !d.childTree) {
			return;
		}
		if (!d.childTree) {
			d.childTree = new List({parent: this});
			d.childTree.setCheckable(this.isCheckable());
			d.childTree.setVisible(d.expanded);
		}
		d.childTree.insertItem(index, item);
		d.expHandle.show();
	}

	isCheckable(): boolean {
		return Boolean(this.d.checkbox);
	}

	isExpanded(): boolean {
		return this.d.expanded;
	}

	parentList(): List | null {
		const par = this.parentEl();
		return (par && (par instanceof List)) ?
			par :
			null;
	}

	setCheckable(checkable: boolean): void {
		if (checkable === this.isCheckable()) {
			return;
		}
		const d = this.d;
		if (checkable) {
			if (!d.checkbox) {
				const text = this.text().trim();
				this.setText();
				d.checkbox = new LabeledCheckbox();
				Obj.connect(
					d.checkbox, 'stateChanged',
					this, '_checkStateChanged',
				);
				d.checkbox.setLabel(text);
				d.checkbox.setParent(this, 1);
				d.checkbox.show();
			}
		} else {
			let text = '';
			if (d.checkbox) {
				text = d.checkbox.label();
				d.checkbox.destroy();
			}
			d.checkbox = null;
			if (text) {
				this.setText(text);
			}
		}
		if (d.childTree) {
			d.childTree.setCheckable(checkable);
		}
	}

	setExpanded(expanded: boolean): void {
		const d = this.d;
		if (expanded === d.expanded) {
			return;
		}
		d.expanded = expanded;
		d.expHandle.setExpanded(d.expanded);
		if (d.childTree) {
			d.childTree.setVisible(d.expanded);
		}
		if (d.expanded) {
			const par = this.parentList();
			const item = par && par.parentListItem();
			if (item) {
				item.setExpanded(d.expanded);
			}
		}
	}

	setText(text?: string | null): void {
		const d = this.d;
		if (d.checkbox) {
			d.checkbox.setLabel(text);
		} else {
			super.setText(text, 1);
		}
	}

	text(): string {
		const d = this.d;
		return d.checkbox ?
			d.checkbox.label() :
			super.text();
	}
}

@OBJ
class ExpansionHandle extends ElObj {
	static tagName: TagName = 'span';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'material-icons',
		);
		opts.styles = ElObj.mergeStyles(
			opts.styles,
			['cursor', 'pointer'],
			['vertical-align', 'middle'],
		);
		super(opts);
		this.setText('arrow_right');
	}

	@SIGNAL
	private clicked(): void {
	}

	protected hideEvent(event: HideEvt): void {
		this.setTransparent(true);
		super.hideEvent(event);
	}

	setExpanded(expanded: boolean): void {
		this.setText(
			expanded ?
				'arrow_drop_down' :
				'arrow_right',
		);
	}

	protected showEvent(event: ShowEvt): void {
		super.showEvent(event);
		this.setTransparent(false);
	}
}

@OBJ
class LabeledCheckbox extends ElObj {
	static tagName: TagName = 'label';

	inputEl: ElObj;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.styles = ElObj.mergeStyles(
			opts.styles,
			['font-size', '0.82rem'],
			['letter-spacing', '0.04rem'],
		);
		super(opts);
		this.inputEl = new ElObj({
			attributes: [
				['type', 'checkbox'],
			],
			parent: this,
			tagName: 'input',
		});
		this.inputEl.show();
		this.addEventListener('change');
	}

	label(): string {
		return this.text().trim();
	}

	setLabel(label?: string | null): void {
		this.setText(label);
	}

	setText(text?: string | null): void {
		super.setText(text, -1);
	}

	@SIGNAL
	private stateChanged(state: number): void {
	}
}
