import {AbstractObj, Obj, OBJ, ObjOpts, ObjPrivate, PROP, SIGNAL, SLOT} from './obj';
import {AbstractItemModel, AbstractItemModelPrivate, ModelIndex, PersistentModelIndex} from './abstractitemmodel';
import {list} from './tools';
import {ItemFlag, LayoutChangeHint, SelectionFlag} from './constants';
import {assert, isNumber} from './util';
import {getLogger} from './logging';

const logger = getLogger('itemselectionmodel');

export class ItemSelectionRange {
	br: PersistentModelIndex;
	tl: PersistentModelIndex;

	constructor(topLeft: ModelIndex, bottomRight: ModelIndex);
	constructor(index?: ModelIndex);
	constructor(a?: ModelIndex, b?: ModelIndex) {
		let br: PersistentModelIndex;
		let tl: PersistentModelIndex;
		if (a && b) {
			tl = a.cast();
			br = b.cast();
		} else if (a) {
			tl = a.cast();
			br = tl;
		} else {
			tl = new PersistentModelIndex();
			br = new PersistentModelIndex();
		}
		this.br = br;
		this.tl = tl;
	}

	bottom(): number {
		return this.br.row();
	}

	bottomRight(): PersistentModelIndex {
		return this.br;
	}

	contains(row: number, column: number, parent?: ModelIndex): boolean;
	contains(index: ModelIndex): boolean;
	contains(a: ModelIndex | number, b?: number, c?: ModelIndex): boolean {
		if (isNumber(a) && isNumber(b)) {
			// SIG: contains(row: number, column: number, parent?: ModelIndex): boolean
			const row = a;
			const column = b;
			const parent = c || new ModelIndex();
			return this.parent().eq(parent)
				&& (this.tl.row() <= row)
				&& (this.tl.column() <= column)
				&& (this.br.row() >= row)
				&& (this.br.column() >= column);
		} else {
			// SIG: contains(index: ModelIndex): boolean
			const index = <ModelIndex>a;
			return this.parent().eq(index.parent())
				&& (this.tl.row() <= index.row())
				&& (this.tl.column() <= index.column())
				&& (this.br.row() >= index.row())
				&& (this.br.column() >= index.column());
		}
	}

	eq(other: ItemSelectionRange): boolean {
		return this.tl.eq(other.tl) && this.br.eq(other.br);
	}

	height(): number {
		return this.br.row() - this.tl.row() + 1;
	}

	indexes(): list<ModelIndex> {
		/**
		 * Returns the list of model index items stored in the selection.
		 */
		const result = new list<ModelIndex>();
		indexesFromRange(this, result);
		return result;
	}

	intersected(other: ItemSelectionRange): ItemSelectionRange {
		/**
		 * Returns a new selection range containing only the items that are
		 * found in both the selection range and the other selection range.
		 */
		const myMdl = this.model();
		const theirMdl = other.model();
		const eqMdl = ((myMdl && theirMdl) && myMdl.eq(theirMdl))
			|| (!myMdl && !theirMdl);
		if (eqMdl && (myMdl && theirMdl) && this.parent().eq(other.parent())) {
			const topLeft = myMdl.index(
				Math.max(
					this.top(),
					other.top(),
				),
				Math.max(
					this.left(),
					other.left(),
				),
				other.parent(),
			);
			const bottomRight = myMdl.index(
				Math.min(
					this.bottom(),
					other.bottom(),
				),
				Math.min(
					this.right(),
					other.right(),
				),
				other.parent(),
			);
			return new ItemSelectionRange(
				topLeft,
				bottomRight,
			);
		}
		return new ItemSelectionRange();
	}

	intersects(other: ItemSelectionRange): boolean {
		/**
		 * Returns true if this selection range intersects (overlaps with) the
		 * other range given; otherwise returns false.
		 */
			// isValid() and parent() last since they are more expensive
		const myMdl = this.model();
		const theirMdl = other.model();
		let mdlEq: boolean;
		if (myMdl && theirMdl) {
			mdlEq = myMdl.eq(theirMdl);
		} else if (!myMdl && !theirMdl) {
			mdlEq = true;
		} else {
			mdlEq = false;
		}
		return (mdlEq
			&& ((this.top() <= other.top() && this.bottom() >= other.top())
				|| (this.top() >= other.top() && this.top() <= other.bottom()))
			&& ((this.left() <= other.left() && this.right() >= other.left())
				|| (this.left() >= other.left() && this.left() <= other.right()))
			&& this.parent().eq(other.parent())
			&& this.isValid() && other.isValid());
	}

	isEmpty(): boolean {
		/**
		 * Returns true if the selection range contains either no items or
		 * only items which are either disabled or marked as not selectable.
		 */
		const mdl = this.model();
		if (!this.isValid() || !mdl) {
			return false;
		}
		for (let column = this.left(); column <= this.right(); ++column) {
			for (let row = this.top(); row <= this.bottom(); ++row) {
				const index = mdl.index(
					row,
					column,
					this.parent(),
				);
				if (isSelectableAndEnabled(mdl.flags(index))) {
					return false;
				}
			}
		}
		return true;
	}

	isValid(): boolean {
		return this.tl.isValid()
			&& this.br.isValid()
			&& this.tl.parent().eq(this.br.parent())
			&& (this.top() <= this.bottom())
			&& (this.left() <= this.right());
	}

	left(): number {
		return this.tl.column();
	}

	model(): AbstractItemModel | null {
		return this.tl.model();
	}

	ne(other: ItemSelectionRange): boolean {
		return !this.eq(other);
	}

	parent(): ModelIndex {
		return this.tl.parent();
	}

	right(): number {
		return this.br.column();
	}

	top(): number {
		return this.tl.row();
	}

	topLeft(): PersistentModelIndex {
		return this.tl;
	}

	width(): number {
		return this.br.column() - this.tl.column() + 1;
	}
}

export class ItemSelection extends list<ItemSelectionRange> {
	static split(range: ItemSelectionRange, other: ItemSelectionRange, result: ItemSelection): void {
		/**
		 * Splits the selection range using the selection other range. Removes
		 * all items in other from range and puts the result in result. This
		 * can be compared with the semantics of the subtract operation of a
		 * set.
		 */
		if (range.parent().ne(other.parent()) || !modelEq(range.model(), other.model())) {
			return;
		}
		const parent = other.parent();
		let top = range.top();
		let left = range.left();
		let bottom = range.bottom();
		let right = range.right();
		const otherTop = other.top();
		const otherLeft = other.left();
		const otherBottom = other.bottom();
		const otherRight = other.right();
		const model = range.model();
		assert(model);
		if (otherTop > top) {
			const tl = model.index(top, left, parent);
			const br = model.index(otherTop - 1, right, parent);
			result.append(new ItemSelectionRange(tl, br));
			top = otherTop;
		}
		if (otherBottom < bottom) {
			const tl = model.index(otherBottom + 1, left, parent);
			const br = model.index(bottom, right, parent);
			result.append(new ItemSelectionRange(tl, br));
			bottom = otherBottom;
		}
		if (otherLeft > left) {
			const tl = model.index(top, left, parent);
			const br = model.index(bottom, otherLeft - 1, parent);
			result.append(new ItemSelectionRange(tl, br));
			left = otherLeft;
		}
		if (otherRight < right) {
			const tl = model.index(top, otherRight + 1, parent);
			const br = model.index(bottom, right, parent);
			result.append(new ItemSelectionRange(tl, br));
			right = otherRight;
		}
	}

	constructor(topLeft: ModelIndex, bottomRight: ModelIndex);
	constructor(other: ItemSelection);
	constructor();
	constructor(a?: ModelIndex | ItemSelection, bottomRight?: ModelIndex) {
		if (a instanceof ItemSelection) {
			super(a);
		} else {
			super();
		}
		if ((a instanceof ModelIndex) && bottomRight) {
			this.select(a, bottomRight);
		}
	}

	contains(value: ItemSelectionRange): boolean;
	contains(value: ModelIndex): boolean;
	contains(value: ItemSelectionRange | ModelIndex): boolean {
		/**
		 * Returns true if the selection contains the given index; otherwise
		 * returns false.
		 */
		if (value instanceof ModelIndex) {
			if (isSelectableAndEnabled(value.flags())) {
				for (const obj of this) {
					if (obj.contains(value)) {
						return true;
					}
				}
			}
			return false;
		} else {
			return super.contains(value);
		}
	}

	indexes(): list<ModelIndex> {
		return selectionIndexes(this);
	}

	merge(other: ItemSelection, command: SelectionFlag): void {
		/**
		 * Merges the other selection with this ItemSelection using the
		 * command given. This method guarantees that no ranges are
		 * overlapping.
		 *
		 * NB: Only SelectionFlag.Select, SelectionFlag.Deselect,
		 *     and SelectionFlag.Toggle are supported.
		 */
		if (other.isEmpty() || !(Boolean(command & SelectionFlag.Select) || Boolean(command & SelectionFlag.Deselect) || Boolean(command & SelectionFlag.Toggle))) {
			return;
		}
		const newSelection = other;
		// Collect intersections
		const intersections = new ItemSelection();
		for (const obj of newSelection) {
			if (!obj.isValid()) {
				newSelection.removeOne(obj);
				continue;
			}
			for (let t = 0; t < this.size(); ++t) {
				if (obj.intersects(this.at(t))) {
					intersections.append(
						this.at(t).intersected(obj),
					);
				}
			}
		}
		// Split the old (and new) ranges using the intersections
		for (let i = 0; i < intersections.size(); ++i) {
			for (let t = 0; t < this.size();) { // Split each old range
				if (this.at(t).intersects(intersections.at(i))) {
					(<typeof ItemSelection>this.constructor).split(
						this.at(t),
						intersections.at(i),
						this,
					);
				} else {
					++t;
				}
			}
			// Only split newSelection if Toggle is specified
			for (let n = 0; (command & SelectionFlag.Toggle) && (n < newSelection.size());) {
				if (newSelection.at(n).intersects(intersections.at(i))) {
					(<typeof ItemSelection>this.constructor).split(
						newSelection.at(n),
						intersections.at(i),
						newSelection,
					);
					newSelection.removeAt(n);
				} else {
					++n;
				}
			}
		}
		// Do not add newSelection for Deselect
		if (!Boolean(command & SelectionFlag.Deselect)) {
			this.plusEq(newSelection);
		}
	}

	select(topLeft: ModelIndex, bottomRight: ModelIndex): void {
		/**
		 * Adds the items in the range that extends from the top-left model
		 * item, specified by the topLeft index, to the bottom-right item,
		 * specified by bottomRight to the list.
		 *
		 * NB: topLeft and bottomRight must have the same parent.
		 */
		if (!topLeft.isValid() || !bottomRight.isValid()) {
			return;
		}
		if (!modelEq(topLeft.model(), bottomRight.model()) || topLeft.parent().ne(bottomRight.parent())) {
			logger.warning('Can\'t select indexes from different model or with different parents');
			return;
		}
		if ((topLeft.row() > bottomRight.row()) || (topLeft.column() > bottomRight.column())) {
			const top = Math.min(topLeft.row(), bottomRight.row());
			const bottom = Math.max(topLeft.row(), bottomRight.row());
			const left = Math.min(topLeft.column(), bottomRight.column());
			const right = Math.max(topLeft.column(), bottomRight.column());
			const tl = topLeft.sibling(top, left);
			const br = bottomRight.sibling(bottom, right);
			this.append(
				new ItemSelectionRange(
					tl,
					br,
				),
			);
			return;
		}
		this.append(
			new ItemSelectionRange(
				topLeft,
				bottomRight,
			),
		);
	}
}

export class ItemSelectionModelPrivate extends ObjPrivate {
	currentCommand: SelectionFlag;
	currentIndex: PersistentModelIndex;
	currentSelection: ItemSelection;
	model: AbstractItemModel;
	ranges: ItemSelection;
	savedPersistentCurrentIndexes: list<PersistentModelIndex>;
	savedPersistentCurrentRowLengths: list<Pair<PersistentModelIndex, number>>;
	savedPersistentIndexes: list<PersistentModelIndex>;
	savedPersistentRowLengths: list<Pair<PersistentModelIndex, number>>;
	tableColCount: number;
	tableParent: PersistentModelIndex;
	tableRowCount: number;
	tableSelected: boolean; // Optimization when all indexes are selected

	constructor() {
		super();
		this.currentCommand = SelectionFlag.NoUpdate;
		this.currentIndex = new PersistentModelIndex();
		this.currentSelection = new ItemSelection();
		this.model = AbstractItemModelPrivate.staticEmptyModel();
		this.ranges = new ItemSelection();
		this.savedPersistentCurrentIndexes = new list();
		this.savedPersistentCurrentRowLengths = new list();
		this.savedPersistentIndexes = new list();
		this.savedPersistentRowLengths = new list();
		this.tableColCount = 0;
		this.tableParent = new PersistentModelIndex();
		this.tableRowCount = 0;
		this.tableSelected = false;
	}

	columnsAboutToBeInserted(parent: ModelIndex, start: number, end: number): void {
		// Split selection ranges if columns are about to be inserted in the
		// middle.
		this.finalize();
		const split = new list<ItemSelectionRange>();
		let it = 0;
		while (it < this.ranges.size()) {
			const range = this.ranges.at(it);
			const itParent = range.parent();
			if (range.isValid() && itParent.eq(parent) && (range.left() < start) && (range.right() >= start)) {
				const bottomMiddle = this.model.index(range.bottom(), start - 1, itParent);
				const left = new ItemSelectionRange(range.topLeft().cast(), bottomMiddle);
				const topMiddle = this.model.index(range.top(), start, itParent);
				const right = new ItemSelectionRange(topMiddle, range.bottomRight().cast());
				this.ranges.removeAt(it);
				split.append(left);
				split.append(right);
			} else {
				++it;
			}
		}
		this.ranges.plusEq(split);
	}

	columnsAboutToBeRemoved(parent: ModelIndex, start: number, end: number): void {
		const q = this.q;
		// Update current index
		if (this.currentIndex.isValid() && parent.eq(this.currentIndex.parent()) && (this.currentIndex.column() >= start) && this.currentIndex.column() <= end) {
			const old = this.currentIndex;
			if (start > 0) {
				// There are columns to the left of the change.
				this.currentIndex = this.model.index(old.row(), start - 1, parent).cast();
			} else if ((this.model !== AbstractItemModelPrivate.staticEmptyModel()) && (end < (this.model.columnCount() - 1))) {
				// There are columns to the right of the change.
				this.currentIndex = this.model.index(old.row(), end + 1, parent).cast();
			} else {
				// There are no columns left in the table
				this.currentIndex = (new ModelIndex()).cast();
			}
			q.currentChanged(
				this.currentIndex.cast(),
				old.cast(),
			);
			if (this.currentIndex.row() !== old.row()) {
				q.currentRowChanged(
					this.currentIndex.cast(),
					old.cast(),
				);
			}
			q.currentColumnChanged(
				this.currentIndex.cast(),
				old.cast(),
			);
		}
		// Update selections
		const tl = this.model.index(0, start, parent);
		const br = this.model.index(this.model.rowCount(parent) - 1, end, parent);
		q.select(
			new ItemSelection(tl, br),
			SelectionFlag.Deselect,
		);
		this.finalize();
	}

	expandSelection(selection: ItemSelection, command: SelectionFlag): ItemSelection {
		if (selection.isEmpty() && !((command & SelectionFlag.Rows) || (command & SelectionFlag.Columns))) {
			return selection;
		}
		const expanded = new ItemSelection();
		if (command & SelectionFlag.Rows) {
			for (let i = 0; i < selection.size(); ++i) {
				const parent = selection.at(i).parent();
				const colCount = this.model.columnCount(parent);
				const tl = this.model.index(selection.at(i).top(), 0, parent);
				const br = this.model.index(selection.at(i).bottom(), colCount - 1, parent);
				// We need to merge because the same row could have already
				// been inserted.
				expanded.merge(
					new ItemSelection(tl, br),
					SelectionFlag.Select,
				);
			}
		}
		if (command & SelectionFlag.Columns) {
			for (let i = 0; i < selection.size(); ++i) {
				const parent = selection.at(i).parent();
				const rowCount = this.model.rowCount(parent);
				const tl = this.model.index(0, selection.at(i).left(), parent);
				const br = this.model.index(rowCount - 1, selection.at(i).right(), parent);
				// We need to merge because the same column could have already
				// been inserted.
				expanded.merge(
					new ItemSelection(tl, br),
					SelectionFlag.Select,
				);
			}
		}
		return expanded;
	}

	finalize(): void {
		this.ranges.merge(
			this.currentSelection,
			this.currentCommand,
		);
		if (!this.currentSelection.isEmpty()) {
			this.currentSelection.clear();
		}
	}

	initModel(model: AbstractItemModel | null): void {
		if ((model && model.eq(this.model)) || (!model && (this.model === AbstractItemModelPrivate.staticEmptyModel()))) {
			return;
		}
		const cx: Array<[string, string]> = [
			['rowsAboutToBeRemoved', '_rowsAboutToBeRemoved'],
			['columnsAboutToBeRemoved', '_columnsAboutToBeRemoved'],
			['rowsAboutToBeInserted', '_rowsAboutToBeInserted'],
			['columnsAboutToBeInserted', '_columnsAboutToBeInserted'],
			['rowsAboutToBeMoved', '_layoutAboutToBeChanged'],
			['columnsAboutToBeMoved', '_layoutAboutToBeChanged'],
			['rowsMoved', '_layoutChanged'],
			['columnsMoved', '_layoutChanged'],
			['layoutAboutToBeChanged', '_layoutAboutToBeChanged'],
			['layoutChanged', '_layoutChanged'],
			['modelReset', 'reset'],
			['destroyed', '_modelDestroyed'],
		];
		const q = this.q;
		if (this.model !== AbstractItemModelPrivate.staticEmptyModel()) {
			for (const [sig, slot] of cx) {
				Obj.disconnect(
					this.model, sig,
					q, slot,
				);
			}
			q.reset();
		}
		this.model = model ? model : AbstractItemModelPrivate.staticEmptyModel();
		if (this.model !== AbstractItemModelPrivate.staticEmptyModel()) {
			for (const [sig, slot] of cx) {
				Obj.connect(
					this.model, sig,
					q, slot,
				);
			}
		}
	}

	layoutAboutToBeChanged(parents: list<PersistentModelIndex> = new list<PersistentModelIndex>(), hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		// Split selection into individual (persistent) indexes. This is done
		// in preparation for the layoutChanged() signal, where the indexes
		// can be merged again.
		this.savedPersistentIndexes.clear();
		this.savedPersistentCurrentIndexes.clear();
		this.savedPersistentRowLengths.clear();
		this.savedPersistentCurrentRowLengths.clear();
		// Optimization for when all indexes are selected (only if there is
		// lots of items (1000) because this is not entirely correct)
		if (this.ranges.isEmpty() && (this.currentSelection.size() === 1)) {
			const range = this.currentSelection.first();
			const parent = range.parent();
			this.tableRowCount = this.model.rowCount(parent);
			this.tableColCount = this.model.columnCount(parent);
			if (((this.tableRowCount * this.tableColCount) > 1000) && (range.top() === 0) && (range.left() === 0) && (range.bottom() === (this.tableRowCount - 1) && (range.right() === (this.tableColCount - 1)))) {
				this.tableSelected = true;
				this.tableParent = parent.cast();
				return;
			}
		}
		this.tableSelected = false;
		if (hint === LayoutChangeHint.VerticalSortHint) {
			// Special case when we know we're sorting vertically. We can
			// assume that all indexes for columns are displaced the same way,
			// and therefore we only need to track an index from one column
			// per row with a PersistentModelIndex together with the length of
			// items to the right of it which are displaced the same way.
			//
			// An algorithm which contains the same assumption is used to
			// process layoutChanged.
			this.savedPersistentRowLengths = selectionPersistentRowLengths(this.ranges);
			this.savedPersistentCurrentRowLengths = selectionPersistentRowLengths(this.currentSelection);
		} else {
			this.savedPersistentIndexes = selectionIndexes(this.ranges).map(x => x.cast());
			this.savedPersistentCurrentIndexes = selectionIndexes(this.currentSelection).map(x => x.cast());
		}
	}

	layoutChanged(parents: list<PersistentModelIndex> = new list<PersistentModelIndex>(), hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		// Special case for when all indexes are selected
		if (this.tableSelected && (this.tableColCount === this.model.columnCount(this.tableParent)) && (this.tableRowCount === this.model.rowCount(this.tableParent))) {
			this.ranges.clear();
			this.currentSelection.clear();
			const bottom = this.tableRowCount - 1;
			const right = this.tableColCount - 1;
			const tl = this.model.index(0, 0, this.tableParent);
			const br = this.model.index(bottom, right, this.tableParent);
			this.currentSelection.append(new ItemSelectionRange(tl, br));
			this.tableParent = (new ModelIndex()).cast();
			this.tableSelected = false;
			return;
		}
		if (((hint !== LayoutChangeHint.VerticalSortHint) && this.savedPersistentCurrentIndexes.isEmpty() && this.savedPersistentIndexes.isEmpty()) || ((hint === LayoutChangeHint.VerticalSortHint) && this.savedPersistentRowLengths.isEmpty() && this.savedPersistentCurrentRowLengths.isEmpty())) {
			// Either the selection was actually empty, or we didn't get the
			// layoutAboutToBeChanged() signal.
			return;
		}
		// Clear the "old" selection
		this.ranges.clear();
		this.currentSelection.clear();
		if (hint !== LayoutChangeHint.VerticalSortHint) {
			// Sort the "new" selection, as preparation for merging
			this.savedPersistentIndexes.sort(persistentModelIndexLessThan);
			this.savedPersistentCurrentIndexes.sort(persistentModelIndexLessThan);
			// Update the selection by merging the individual indexes
			this.ranges = mergeIndexes(this.savedPersistentIndexes);
			this.currentSelection = mergeIndexes(this.savedPersistentCurrentIndexes);
			// Release the persistent indexes
			this.savedPersistentIndexes.clear();
			this.savedPersistentCurrentIndexes.clear();
		} else {
			// Sort the "new" selection, as preparation for merging
			this.savedPersistentRowLengths.sort(pairSortKey);
			this.savedPersistentCurrentRowLengths.sort(pairSortKey);
			// Update the selection by merging the individual indexes
			this.ranges = mergeRowLengths(this.savedPersistentRowLengths);
			this.currentSelection = mergeRowLengths(this.savedPersistentCurrentRowLengths);
			// Release the persistent indexes
			this.savedPersistentRowLengths.clear();
			this.savedPersistentCurrentRowLengths.clear();
		}
	}

	modelDestroyed(): void {
		this.model = AbstractItemModelPrivate.staticEmptyModel();
	}

	get q(): ItemSelectionModel {
		return <ItemSelectionModel>super.q;
	}

	remove(r: list<ItemSelectionRange>): void {
		for (const range of r) {
			this.ranges.removeAll(range);
		}
	}

	rowsAboutToBeInserted(parent: ModelIndex, start: number, end: number): void {
		// Split selection ranges if rows are about to be inserted in the
		// middle.
		this.finalize();
		const split = new list<ItemSelectionRange>();
		let indexesOfSelectionChanged = false;
		let it = 0;
		while (it < this.ranges.size()) {
			const range = this.ranges.at(it);
			const itParent = range.parent();
			if (range.isValid() && itParent.eq(parent) && (range.top() < start) && (range.bottom() >= start)) {
				const middleRight = this.model.index(start - 1, range.right(), itParent);
				const top = new ItemSelectionRange(range.topLeft().cast(), middleRight);
				const middleLeft = this.model.index(start, range.left(), itParent);
				const bottom = new ItemSelectionRange(middleLeft, range.bottomRight().cast());
				this.ranges.removeAt(it);
				split.append(top);
				split.append(bottom);
			} else if (range.isValid() && itParent.eq(parent) && (range.top() >= start)) { // Insertion before selection
				indexesOfSelectionChanged = true;
				++it;
			} else {
				++it;
			}
		}
		this.ranges.plusEq(split);
		if (indexesOfSelectionChanged) {
			this.q.selectionChanged(new ItemSelection(), new ItemSelection());
		}
	}

	rowsAboutToBeRemoved(parent: ModelIndex, start: number, end: number): void {
		assert(start <= end);
		this.finalize();
		const q = this.q;
		// Update current index
		if (this.currentIndex.isValid() && parent.eq(this.currentIndex.parent()) && (this.currentIndex.row() >= start) && (this.currentIndex.row() <= end)) {
			const old = this.currentIndex;
			if (start > 0) {
				//There are rows left above the change
				this.currentIndex = this.model.index(
					start - 1,
					old.column(),
					parent,
				).cast();
			} else if ((this.model !== AbstractItemModelPrivate.staticEmptyModel()) && (end < this.model.rowCount(parent) - 1)) {
				// There are rows left below the change.
				this.currentIndex = this.model.index(end + 1, old.column(), parent).cast();
			} else {
				// There are no rows left in the table
				this.currentIndex = (new ModelIndex()).cast();
			}
			q.currentChanged(
				this.currentIndex.cast(),
				old.cast(),
			);
			q.currentRowChanged(
				this.currentIndex.cast(),
				old.cast(),
			);
			if (this.currentIndex.column() !== old.column()) {
				q.currentColumnChanged(
					this.currentIndex.cast(),
					old.cast(),
				);
			}
		}
		const deselected = new ItemSelection();
		const newParts = new ItemSelection();
		let indexesOfSelectionChanged = false;
		let it = 0;
		while (it < this.ranges.size()) {
			const range = this.ranges.at(it);
			// Check parents until reaching root or contained in range
			if (range.topLeft().parent().ne(parent)) {
				let itParent = range.topLeft().parent();
				while (itParent.isValid() && itParent.parent().ne(parent)) {
					itParent = itParent.parent();
				}
				if (itParent.isValid() && (start <= itParent.row()) && (itParent.row() <= end)) {
					deselected.append(range);
					this.ranges.removeAt(it);
				} else {
					if (itParent.isValid() && (end < itParent.row())) {
						indexesOfSelectionChanged = true;
					}
					++it;
				}
			} else if ((start <= range.bottom()) && (range.bottom() <= end) && (start <= range.top()) && (range.top() <= end)) { // Full inclusion
				deselected.append(range);
				this.ranges.removeAt(it);
			} else if ((start <= range.top()) && (range.top() <= end)) { // Top intersection
				deselected.append(
					new ItemSelectionRange(
						range.topLeft().cast(),
						this.model.index(
							end,
							range.right(),
							range.parent(),
						),
					),
				);
				this.ranges.replace(
					it,
					new ItemSelectionRange(
						this.model.index(end + 1, range.left(), range.parent()),
						range.bottomRight().cast(),
					),
				);
				++it;
			} else if ((start <= range.bottom()) && (range.bottom() <= end)) { // Bottom intersection
				deselected.append(
					new ItemSelectionRange(
						this.model.index(
							start,
							range.left(),
							range.parent(),
						),
						range.bottomRight().cast(),
					),
				);
				this.ranges.replace(
					it,
					new ItemSelectionRange(
						range.topLeft().cast(),
						this.model.index(
							start - 1,
							range.right(),
							range.parent(),
						),
					),
				);
				++it;
			} else if ((range.top() < start) && (end < range.bottom())) { // Middle intersection
				// If the parent contains (1, 2, 3, 4, 5, 6, 7, 8) and
				// [3, 4, 5, 6] is selected, and [4, 5] is removed, we need
				// to split [3, 4, 5, 6] into [3], [4, 5] and [6]. [4, 5] is
				// appended to deselected, and [3] and [6] remain part of the
				// selection in ranges.
				const removedRange = new ItemSelectionRange(
					this.model.index(
						start,
						range.left(),
						range.parent(),
					),
					this.model.index(
						end,
						range.right(),
						range.parent(),
					),
				);
				deselected.append(removedRange);
				ItemSelection.split(
					range,
					removedRange,
					newParts,
				);
				this.ranges.removeAt(it);
			} else if (end < range.top()) { // Deleted row before selection
				indexesOfSelectionChanged = true;
				++it;
			} else {
				++it;
			}
		}
		this.ranges.extend(
			newParts,
		);
		if (!deselected.isEmpty() || indexesOfSelectionChanged) {
			q.selectionChanged(
				new ItemSelection(),
				deselected,
			);
		}
	}
}

export interface ItemSelectionModelOpts extends ObjOpts {
	dd: ItemSelectionModelPrivate;
}

@OBJ
export class ItemSelectionModel extends Obj {
	constructor(model: AbstractItemModel | null, parent?: AbstractObj | null);
	constructor(model?: AbstractItemModel | null);
	constructor(opts?: Partial<ItemSelectionModelOpts>);
	constructor(a?: AbstractItemModel | Partial<ItemSelectionModelOpts> | null, b?: AbstractObj | null) {
		let model: AbstractItemModel | null = null;
		let opts: Partial<ItemSelectionModelOpts> = {};
		let par: AbstractObj | null = null;
		if (a) {
			if (a instanceof AbstractItemModel) {
				model = a;
			} else {
				opts = a;
			}
		}
		if (b) {
			par = b;
		}
		if (par) {
			opts.parent = par;
		}
		if (!opts.parent) {
			opts.parent = model;
		}
		opts.dd = opts.dd || new ItemSelectionModelPrivate();
		super(opts);
		if (model) {
			opts.dd.initModel(model);
		}
	}

	@SLOT
	clear(): void {
		this.clearSelection();
		this.clearCurrentIndex();
	}

	@SLOT
	clearCurrentIndex(): void {
		const d = this.d;
		const previous = d.currentIndex;
		d.currentIndex = (new ModelIndex()).cast();
		if (previous.isValid()) {
			this.currentChanged(d.currentIndex.cast(), previous.cast());
			this.currentRowChanged(d.currentIndex.cast(), previous.cast());
			this.currentColumnChanged(d.currentIndex.cast(), previous.cast());
		}
	}

	@SLOT
	clearSelection(): void {
		const d = this.d;
		if ((d.ranges.size() === 0) && (d.currentSelection.size() === 0)) {
			return;
		}
		this.select(new ItemSelection(), SelectionFlag.Clear);
	}

	columnIntersectsSelection(column: number, parent: ModelIndex = new ModelIndex()): boolean {
		const d = this.d;
		if (d.model === AbstractItemModelPrivate.staticEmptyModel()) {
			return false;
		}
		if (parent.isValid() && !d.model.eq(parent.model())) {
			return false;
		}
		const sel = new ItemSelection(d.ranges);
		sel.merge(d.currentSelection, d.currentCommand);
		for (const range of sel) {
			if (range.parent().ne(parent)) {
				return false;
			}
			const top = range.top();
			const bottom = range.bottom();
			const left = range.left();
			const right = range.right();
			if ((left <= column) && (right >= column)) {
				for (let k = top; k <= bottom; ++k) {
					if (isSelectableAndEnabled(d.model.index(k, column, parent).flags())) {
						return true;
					}
				}
			}
		}
		return false;
	}

	@SLOT
	_columnsAboutToBeInserted(parent: ModelIndex, start: number, end: number): void {
		this.d.columnsAboutToBeInserted(parent, start, end);
	}

	@SLOT
	_columnsAboutToBeRemoved(parent: ModelIndex, start: number, end: number): void {
		this.d.columnsAboutToBeRemoved(parent, start, end);
	}

	@SIGNAL
	currentChanged(current: ModelIndex, previous: ModelIndex): void {
	}

	@SIGNAL
	currentColumnChanged(current: ModelIndex, previous: ModelIndex): void {
	}

	@PROP({NOTIFY: 'currentChanged'})
	currentIndex(): ModelIndex {
		return this.d.currentIndex.cast();
	}

	@SIGNAL
	currentRowChanged(current: ModelIndex, previous: ModelIndex): void {
	}

	get d(): ItemSelectionModelPrivate {
		return <ItemSelectionModelPrivate>super.d;
	}

	protected emitSelectionChanged(newSelection: ItemSelection, oldSelection: ItemSelection): void {
		if ((oldSelection.isEmpty() && newSelection.isEmpty()) || oldSelection.eq(newSelection)) {
			return;
		}
		if (oldSelection.isEmpty() || newSelection.isEmpty()) {
			this.selectionChanged(newSelection, oldSelection);
			return;
		}
		const deselected = new ItemSelection(oldSelection);
		const selected = new ItemSelection(newSelection);
		for (let o = 0; o < deselected.size(); ++o) {
			let advance = true;
			for (let s = 0; (s < selected.size()) && (o < deselected.size());) {
				if (deselected.at(o).eq(selected.at(s))) {
					deselected.removeAt(o);
					selected.removeAt(s);
					advance = false;
				} else {
					++s;
				}
			}
			if (advance) {
				++o;
			}
		}
		const intersections = new ItemSelection();
		for (let o = 0; o < deselected.size(); ++o) {
			for (let s = 0; s < selected.size(); ++s) {
				if (deselected.at(o).intersects(selected.at(s))) {
					intersections.append(deselected.at(o).intersected(selected.at(s)));
				}
			}
		}
		for (let i = 0; i < intersections.size(); ++i) {
			for (let o = 0; o < deselected.size();) {
				if (deselected.at(o).intersects(intersections.at(i))) {
					ItemSelection.split(deselected.at(o), intersections.at(i), deselected);
					deselected.removeAt(o);
				} else {
					++o;
				}
			}
			for (let s = 0; s < selected.size();) {
				if (selected.at(s).intersects(intersections.at(i))) {
					ItemSelection.split(selected.at(s), intersections.at(i), selected);
					selected.removeAt(s);
				} else {
					++s;
				}
			}
		}
		if (!selected.isEmpty() || !deselected.isEmpty()) {
			this.selectionChanged(selected, deselected);
		}
	}

	@PROP({NOTIFY: 'selectionChanged'})
	hasSelection(): boolean {
		const d = this.d;
		if (d.currentCommand & (SelectionFlag.Toggle | SelectionFlag.Deselect)) {
			const sel = new ItemSelection(d.ranges);
			sel.merge(d.currentSelection, d.currentCommand);
			return !selectionIsEmpty(sel);
		}
		return !(selectionIsEmpty(d.ranges) && selectionIsEmpty(d.currentSelection));
	}

	isColumnSelected(column: number, parent: ModelIndex = new ModelIndex()): boolean {
		const d = this.d;
		if (d.model === AbstractItemModelPrivate.staticEmptyModel()) {
			return false;
		}
		if (parent.isValid() && !d.model.eq(parent.model())) {
			return false;
		}
		// Return false if column exist in currentSelection (Deselect)
		if ((d.currentCommand & SelectionFlag.Deselect) && (d.currentSelection.size() > 0)) {
			for (let i = 0; i < d.currentSelection.size(); ++i) {
				if (d.currentSelection.at(i).parent().eq(parent) && (column >= d.currentSelection.at(i).left()) && (column <= d.currentSelection.at(i).right())) {
					return false;
				}
			}
		}
		// Return false if ranges in both currentSelection and the selection
		// model intersect and have the same column contained.
		if ((d.currentCommand & SelectionFlag.Toggle) && (d.currentSelection.size() > 0)) {
			for (let i = 0; i < d.currentSelection.size(); ++i) {
				if ((d.currentSelection.at(i).left() <= column) && (d.currentSelection.at(i).right() >= column)) {
					for (let k = 0; k < d.ranges.size(); ++k) {
						if ((d.ranges.at(k).left() <= column) && (d.ranges.at(k).right() >= column) && (d.currentSelection.at(i).intersected(d.ranges.at(k)).isValid())) {
							return false;
						}
					}
				}
			}
		}
		const isSelectable = (r: number, c: number) => isSelectableAndEnabled(d.model.index(r, c, parent).flags());
		const rowCount = d.model.rowCount(parent);
		let unselectable = 0;
		// Add ranges and currentSelection and check through them all
		const joined = new list<ItemSelectionRange>(d.ranges);
		if (d.currentSelection.size() > 0) {
			joined.plusEq(d.currentSelection);
		}
		for (let row = 0; row < rowCount; ++row) {
			if (!isSelectable(row, column)) {
				++unselectable;
				continue;
			}
			let broke = false;
			for (const it of joined) {
				if (it.contains(row, column, parent)) {
					for (let i = row; i <= it.bottom(); ++i) {
						if (!isSelectable(i, column)) {
							++unselectable;
						}
					}
					row = Math.max(row, it.bottom());
					broke = true;
					break;
				}
			}
			if (!broke) {
				return false;
			}
		}
		return unselectable < rowCount;
	}

	isRowSelected(row: number, parent: ModelIndex = new ModelIndex()): boolean {
		const d = this.d;
		if (d.model === AbstractItemModelPrivate.staticEmptyModel()) {
			return false;
		}
		if (parent.isValid() && !d.model.eq(parent.model())) {
			return false;
		}
		// Return false if row exist in currentSelection (Deselect)
		if ((d.currentCommand & SelectionFlag.Deselect) && (d.currentSelection.size() > 0)) {
			for (let i = 0; i < d.currentSelection.size(); ++i) {
				if (d.currentSelection.at(i).parent().eq(parent) && (row >= d.currentSelection.at(i).top()) && (row <= d.currentSelection.at(i).bottom())) {
					return false;
				}
			}
		}
		// Return false if ranges in both currentSelection and ranges
		// intersect and have the same row contained
		if ((d.currentCommand & SelectionFlag.Toggle) && (d.currentSelection.size() > 0)) {
			for (let i = 0; i < d.currentSelection.size(); ++i) {
				if ((d.currentSelection.at(i).top() <= row) && (d.currentSelection.at(i).bottom() >= row)) {
					for (let k = 0; k < d.ranges.size(); ++k) {
						if ((d.ranges.at(k).top() <= row) && (d.ranges.at(k).bottom() >= row) && (d.currentSelection.at(i).intersected(d.ranges.at(k)).isValid())) {
							return false;
						}
					}
				}
			}
		}
		const isSelectable = (r: number, c: number) => isSelectableAndEnabled(d.model.index(r, c, parent).flags());
		const colCount = d.model.columnCount(parent);
		let unselectable = 0;
		// Add ranges and currentSelection and check through them all
		const joined = new list<ItemSelectionRange>(d.ranges);
		if (d.currentSelection.size() > 0) {
			joined.plusEq(d.currentSelection);
		}
		for (let column = 0; column < colCount; ++column) {
			if (!isSelectable(row, column)) {
				++unselectable;
				continue;
			}
			let broke = false;
			for (const it of joined) {
				if (it.contains(row, column, parent)) {
					for (let i = column; i <= it.right(); ++i) {
						if (!isSelectable(row, i)) {
							++unselectable;
						}
					}
					column = Math.max(column, it.right());
					broke = true;
					break;
				}
			}
			if (!broke) {
				return false;
			}
		}
		return unselectable < colCount;
	}

	isSelected(index: ModelIndex): boolean {
		const d = this.d;
		if (!d.model.eq(index.model()) || !index.isValid()) {
			return false;
		}
		let selected = false;
		for (const range of d.ranges) {
			if (range.isValid() && range.contains(index)) {
				selected = true;
				break;
			}
		}
		if (d.currentSelection.size() > 0) {
			if ((d.currentCommand & SelectionFlag.Deselect) && selected) {
				selected = !d.currentSelection.contains(index);
			} else if (d.currentCommand & SelectionFlag.Toggle) {
				selected = Boolean(Number(selected) ^ Number(d.currentSelection.contains(index)));
			} else if ((d.currentCommand & SelectionFlag.Select) && !selected) {
				selected = d.currentSelection.contains(index);
			}
		}
		if (selected) {
			return isSelectableAndEnabled(d.model.flags(index));
		}
		return false;
	}

	@SLOT
	_layoutAboutToBeChanged(parents: list<PersistentModelIndex> = new list<PersistentModelIndex>(), hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		this.d.layoutAboutToBeChanged(parents, hint);
	}

	@SLOT
	_layoutChanged(parents: list<PersistentModelIndex> = new list<PersistentModelIndex>(), hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		this.d.layoutChanged(parents, hint);
	}

	@PROP({NOTIFY: 'modelChanged', WRITE: 'setModel'})
	model(): AbstractItemModel {
		return this.d.model;
	}

	@SIGNAL
	protected modelChanged(model: AbstractItemModel | null): void {
	}

	@SLOT
	_modelDestroyed(): void {
		this.d.modelDestroyed();
	}

	@SLOT
	reset(): void {
		const blocked = this.blockSignals(true);
		this.clear();
		this.blockSignals(blocked);
	}

	rowIntersectsSelection(row: number, parent: ModelIndex = new ModelIndex()): boolean {
		const d = this.d;
		if (d.model === AbstractItemModelPrivate.staticEmptyModel()) {
			return false;
		}
		if (parent.isValid() && !d.model.eq(parent.model())) {
			return false;
		}
		const sel = new ItemSelection(d.ranges);
		sel.merge(d.currentSelection, d.currentCommand);
		for (const range of sel) {
			if (range.parent().ne(parent)) {
				return false;
			}
			const top = range.top();
			const bottom = range.bottom();
			const left = range.left();
			const right = range.right();
			if ((top <= row) && (bottom >= row)) {
				for (let k = left; k <= right; ++k) {
					if (isSelectableAndEnabled(d.model.index(row, k, parent).flags())) {
						return true;
					}
				}
			}
		}
		return false;
	}

	@SLOT
	_rowsAboutToBeInserted(parent: ModelIndex, start: number, end: number): void {
		this.d.rowsAboutToBeInserted(parent, start, end);
	}

	@SLOT
	_rowsAboutToBeRemoved(parent: ModelIndex, start: number, end: number): void {
		this.d.rowsAboutToBeRemoved(parent, start, end);
	}

	@SLOT
	select(obj: ModelIndex | ItemSelection, command: SelectionFlag): void {
		if (obj instanceof ModelIndex) {
			this.select(new ItemSelection(obj, obj), command);
		} else {
			const d = this.d;
			if (d.model === AbstractItemModelPrivate.staticEmptyModel()) {
				logger.warning('select: Selecting when no model has been set will result in a no-op');
				return;
			}
			if (command === SelectionFlag.NoUpdate) {
				return;
			}
			let sel = new ItemSelection(obj);
			// If d.ranges is non-empty when the source model is reset the
			// persistent indexes it contains will be invalid. We can't clear
			// them in a modelReset slot because that might already be too
			// late if another model observer is connected to the same
			// modelReset slot and is invoked first it might call select() on
			// this selection model before any such
			// ItemSelectionModelPrivate.modelReset() slot is invoked, so it
			// would not be cleared yet. We clear it invalid ranges in it here.
			const keep = new ItemSelection();
			for (const range of d.ranges) {
				if (range.isValid()) {
					keep.append(range);
				}
			}
			d.ranges = keep;
			const old = new ItemSelection(d.ranges);
			old.merge(d.currentSelection, d.currentCommand);
			// Expand selection according to SelectionFlag
			if ((command & SelectionFlag.Rows) || (command & SelectionFlag.Columns)) {
				sel = new ItemSelection(d.expandSelection(sel, command));
			}
			// Clear ranges and currentSelection
			if (command & SelectionFlag.Clear) {
				d.ranges.clear();
				d.currentSelection.clear();
			}
			// Merge and clear currentSelection if Current was not set
			// (ie. start new currentSelection)
			if (!(command & SelectionFlag.Current)) {
				d.finalize();
			}
			// Update currentSelection
			if ((command & SelectionFlag.Toggle) || (command & SelectionFlag.Select) || (command & SelectionFlag.Deselect)) {
				d.currentCommand = command;
				d.currentSelection = new ItemSelection(sel);
			}
			// Generate new selection, compare with old and emit
			// selectionChanged()
			const newSelection = new ItemSelection(d.ranges);
			newSelection.merge(d.currentSelection, d.currentCommand);
			this.emitSelectionChanged(newSelection, old);
		}
	}

	selectedColumns(row: number = 0): list<ModelIndex> {
		const indexes = new list<ModelIndex>();
		const ranges = this.selection();
		for (let i = 0; i < ranges.size(); ++i) {
			const range = ranges.at(i);
			const parent = range.parent();
			for (let column = range.left(); column <= range.right(); ++column) {
				if (this.isColumnSelected(column, parent)) {
					indexes.append(this.model().index(row, column, parent));
				}
			}
		}
		return indexes;
	}

	@PROP({NOTIFY: 'selectionChanged'})
	selectedIndexes(): list<ModelIndex> {
		const d = this.d;
		const selected = new ItemSelection(d.ranges);
		selected.merge(d.currentSelection, d.currentCommand);
		return selected.indexes();
	}

	selectedRows(column: number = 0): list<ModelIndex> {
		const indexes = new list<ModelIndex>();
		const ranges = this.selection();
		for (let i = 0; i < ranges.size(); ++i) {
			const range = ranges.at(i);
			const parent = range.parent();
			for (let row = range.top(); row <= range.bottom(); ++row) {
				if (this.isRowSelected(row, parent)) {
					indexes.append(this.model().index(row, column, parent));
				}
			}
		}
		return indexes;
	}

	@PROP({NOTIFY: 'selectionChanged'})
	selection(): ItemSelection {
		const d = this.d;
		const selected = new ItemSelection(d.ranges);
		selected.merge(d.currentSelection, d.currentCommand);
		const keep = new ItemSelection();
		for (const obj of selected) {
			if (obj.isValid()) {
				keep.append(obj);
			}
		}
		return keep;
	}

	@SIGNAL
	selectionChanged(selected: ItemSelection, deselected: ItemSelection): void {
	}

	@SLOT
	setCurrentIndex(index: ModelIndex, command: SelectionFlag): void {
		const d = this.d;
		if (d.model === AbstractItemModelPrivate.staticEmptyModel()) {
			logger.warning('setCurrentIndex: Setting the current index when no model has been set will result in a no-op.');
			return;
		}
		if (index.eq(d.currentIndex)) {
			if (command !== SelectionFlag.NoUpdate) {
				this.select(index, command);
			}
			return;
		}
		const previous = d.currentIndex;
		// Set current before emitting selection changed below
		d.currentIndex = index.cast();
		if (command !== SelectionFlag.NoUpdate) {
			this.select(d.currentIndex.cast(), command);
		}
		this.currentChanged(d.currentIndex.cast(), previous.cast());
		if ((d.currentIndex.row() !== previous.row()) || d.currentIndex.parent().ne(previous.parent())) {
			this.currentRowChanged(d.currentIndex.cast(), previous.cast());
		}
		if ((d.currentIndex.column() !== previous.column()) || d.currentIndex.parent().ne(previous.parent())) {
			this.currentColumnChanged(d.currentIndex.cast(), previous.cast());
		}
	}

	setModel(model: AbstractItemModel | null): void {
		const d = this.d;
		if ((model && (d.model.eq(model))) || (!model && (d.model === AbstractItemModelPrivate.staticEmptyModel()))) {
			return;
		}
		d.initModel(model);
	}
}

function indexesFromRange(range: ItemSelectionRange, result: list<ModelIndex>): void {
	const mdl = range.model();
	if (range.isValid() && mdl) {
		const topLeft = range.topLeft();
		const bottom = range.bottom();
		const right = range.right();
		for (let row = topLeft.row(); row <= bottom; ++row) {
			const columnLeader = topLeft.sibling(
				row,
				topLeft.column(),
			);
			for (let column = topLeft.column(); column <= right; ++column) {
				const index = columnLeader.sibling(row, column);
				if (isSelectableAndEnabled(mdl.flags(index))) {
					result.append(index);
				}
			}
		}
	}
}

function isSelectableAndEnabled(flags: ItemFlag): boolean {
	return Boolean(flags & (ItemFlag.ItemIsSelectable | ItemFlag.ItemIsEnabled));
}

function mergeIndexes(indexes: list<PersistentModelIndex>): ItemSelection {
	const colSpans = new ItemSelection();
	// Merge columns
	let i = 0;
	while (i < indexes.size()) {
		const tl = indexes.at(i);
		if (!tl.isValid()) {
			++i;
			continue;
		}
		let br = tl;
		let brParent = br.parent();
		let brRow = br.row();
		let brColumn = br.column();
		while (++i < indexes.size()) {
			const next = indexes.at(i);
			if (!next.isValid()) {
				continue;
			}
			const nextParent = next.parent();
			const nextRow = next.row();
			const nextColumn = next.column();
			if (nextParent.eq(brParent) && (nextRow === brRow) && (nextColumn === (brColumn + 1))) {
				br = next;
				brParent = nextParent;
				brRow = nextRow;
				brColumn = nextColumn;
			} else {
				break;
			}
		}
		colSpans.append(new ItemSelectionRange(tl.cast(), br.cast()));
	}
	// Merge rows
	const rowSpans = new ItemSelection();
	i = 0;
	while (i < colSpans.size()) {
		const tl = colSpans.at(i).topLeft();
		let br = colSpans.at(i).bottomRight();
		let prevTl = tl;
		while (++i < colSpans.size()) {
			const nextTl = colSpans.at(i).topLeft();
			const nextBr = colSpans.at(i).bottomRight();
			if (nextTl.parent().ne(tl.parent())) {
				// We can't merge selection ranges from different parents
				break;
			}
			if ((nextTl.column() === prevTl.column()) && (nextBr.column() === br.column()) && (nextTl.row() === (prevTl.row() + 1)) && (nextBr.row() === (br.row() + 1))) {
				br = nextBr;
				prevTl = nextTl;
			} else {
				break;
			}
		}
		rowSpans.append(new ItemSelectionRange(tl.cast(), br.cast()));
	}
	return rowSpans;
}

function mergeRowLengths(rowLengths: list<Pair<PersistentModelIndex, number>>): ItemSelection {
	if (rowLengths.isEmpty()) {
		return new ItemSelection();
	}
	const result = new ItemSelection();
	let i = 0;
	while (i < rowLengths.size()) {
		const tl = rowLengths.at(i)[0];
		if (!tl.isValid()) {
			++i;
			continue;
		}
		let br = tl;
		const length = rowLengths.at(i)[1];
		while (++i < rowLengths.size()) {
			const next = rowLengths.at(i)[0];
			if (!next.isValid()) {
				continue;
			}
			const nextLength = rowLengths.at(i)[1];
			if ((nextLength === length) && (next.row() === (br.row() + 1)) && (next.column() === br.column()) && next.parent().eq(br.parent())) {
				br = next;
			} else {
				break;
			}
		}
		result.append(new ItemSelectionRange(tl.cast(), br.sibling(br.row(), br.column() + length - 1)));
	}
	return result;
}

function modelEq(a: AbstractItemModel | null, b: AbstractItemModel | null): boolean {
	if (a && b) {
		return a.eq(b);
	}
	return !a && !b;
}

function pairSortKey(a: Pair<PersistentModelIndex, number>, b: Pair<PersistentModelIndex, number>): number {
	if (a[0].eq(b[0])) {
		if (a[1] > b[1]) {
			return 1;
		}
		if (a[1] < b[1]) {
			return -1;
		}
		return 0;
	}
	if (a[0].lt(b[0])) {
		return -1;
	}
	return 1;
}

function persistentModelIndexLessThan(a: PersistentModelIndex, b: PersistentModelIndex): number {
	const ap = a.parent();
	const bp = b.parent();
	if (ap.eq(bp)) {
		return Number(a.lt(b));
	}
	return Number(ap.lt(bp));
}

function rowLengthsFromRange(range: ItemSelectionRange, result: list<Pair<PersistentModelIndex, number>>): void {
	const mdl = range.model();
	if (range.isValid() && mdl) {
		const topLeft = range.topLeft();
		const bottom = range.bottom();
		const width = range.width();
		const column = topLeft.column();
		for (let row = topLeft.row(); row <= bottom; ++row) {
			// We don't need to keep track of ItemIsSelectable and
			// ItemIsEnabled here. That is required in indexesFromRange()
			// because that method is called from public API which requires
			// the limitation.
			result.append(
				[
					new PersistentModelIndex(topLeft.sibling(row, column)),
					width,
				],
			);
		}
	}
}

function selectionIndexes(selection: ItemSelection): list<ModelIndex> {
	const result = new list<ModelIndex>();
	for (const range of selection) {
		indexesFromRange(range, result);
	}
	return result;
}

function selectionIsEmpty(sel: ItemSelection): boolean {
	for (const obj of sel) {
		if (!obj.isEmpty()) {
			return false;
		}
	}
	return true;
}

function selectionPersistentRowLengths(sel: ItemSelection): list<Pair<PersistentModelIndex, number>> {
	const rv = new list<Pair<PersistentModelIndex, number>>();
	for (const range of sel) {
		rowLengthsFromRange(range, rv);
	}
	return rv;
}
