import {MDCDrawer} from '@material/drawer';
import {MDCTabBar} from '@material/tab-bar';
import {MDCTopAppBar} from '@material/top-app-bar';

import {App} from './app';

new App();

class FauxFocusTrap {
	constructor(root: HTMLElement, options?: any) {
	}

	releaseFocus(): void {
	}

	trapFocus(): void {
	}
}

export function fauxFocusTrapFactory(el: HTMLElement, opts: any): FauxFocusTrap {
	return new FauxFocusTrap(el, opts);
}

let elem = document.getElementById('id_lb-primary-nav-drawer');
if (elem) {
	const drawer = new MDCDrawer(elem, undefined, fauxFocusTrapFactory);
	elem = document.querySelector('.mdc-top-app-bar');
	if (elem) {
		const obj = new MDCTopAppBar(elem);
		obj.listen(
			'MDCTopAppBar:nav',
			() => (drawer.open = !drawer.open));
	}
}
elem = document.getElementById('id_lb-primary-nav-drawer');
if (elem) {
	new MDCTabBar(elem);
}
