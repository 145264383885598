import {getLogger} from './logging';
import {Variant} from './variant';
import {assert, testFlag} from './util';
import {defaultdict, list, stack} from './tools';
import {MetaType} from './metatype';
import {Obj, OBJ, ObjOpts, ObjPrivate, SIGNAL, SLOT} from './obj';
import {CaseSensitivity, CheckIndexOption, ItemDataRole, ItemFlag, LayoutChangeHint, MatchFlag, Orientation, SortOrder} from './constants';

const logger = getLogger('abstractitemmodel');

export class ModelRoleData {
	private dat: Variant;
	private rol: number;

	constructor(role: number) {
		this.dat = new Variant();
		this.rol = role;
	}

	clearData(): void {
		this.dat.clear();
	}

	data(): Variant {
		return this.dat;
	}

	role(): number {
		return this.rol;
	}

	setData<T>(value: T, typeId?: number): void {
		this.dat.setValue(value, typeId);
	}
}

export class ModelRoleDataSpan {
	private modelRoleData: Array<ModelRoleData>;

	constructor(data?: Iterable<ModelRoleData> | ModelRoleData) {
		if (data) {
			if (data instanceof ModelRoleData) {
				this.modelRoleData = [data];
			} else {
				this.modelRoleData = Array.from(data);
			}
		} else {
			this.modelRoleData = [];
		}
	}

	at(index: number): ModelRoleData {
		return this.modelRoleData[index];
	}

	data(): list<ModelRoleData> {
		return new list(this.modelRoleData);
	}

	dataForRole(role: number): Variant {
		for (const obj of this.modelRoleData) {
			if (obj.role() === role) {
				return obj.data();
			}
		}
		logger.warning('dataForRole: fall-through for role %s', role);
		return new Variant();
	}

	length(): number {
		return this.modelRoleData.length;
	}

	size(): number {
		return this.modelRoleData.length;
	}

	*[Symbol.iterator](): IterableIterator<ModelRoleData> {
		yield *this.modelRoleData[Symbol.iterator]();
	}
}

export class ModelIndex {
	i: unknown;
	r: number;
	c: number;
	m: AbstractItemModel | null;

	constructor(row: number = -1, column: number = -1, model: AbstractItemModel | null = null, i?: unknown) {
		this.i = i;
		this.r = row;
		this.c = column;
		this.m = model;
	}

	cast(): PersistentModelIndex {
		return new PersistentModelIndex(this);
	}

	column(): number {
		return this.c;
	}

	data(role: ItemDataRole = ItemDataRole.DisplayRole): Variant {
		return this.m ?
			this.m.data(this, role) :
			new Variant();
	}

	eq(other: ModelIndex | PersistentModelIndex): boolean {
		let r: number;
		let c: number;
		let m: AbstractItemModel | null;
		if (other instanceof ModelIndex) {
			r = other.r;
			c = other.c;
			m = other.m;
		} else {
			r = other.row();
			c = other.column();
			m = other.model();
		}
		return (r === this.r) && (c === this.c) && (m === this.m);
	}

	flags(): ItemFlag {
		return this.m ?
			this.m.flags(this) :
			ItemFlag.NoItemFlags;
	}

	internalPointer(): unknown {
		return this.i;
	}

	isValid(): boolean {
		return (this.r >= 0) && (this.c >= 0) && (this.m !== null);
	}

	lt(other: ModelIndex): boolean {
		return (this.r < other.r)
			|| ((this.r === other.r) && ((this.c < other.c)
				|| ((this.c === other.c) && (this.m !== null) && this.m.lt(other.m))));
	}

	model(): AbstractItemModel | null {
		return this.m;
	}

	multiData(roleDataSpan: ModelRoleDataSpan): void {
		if (this.m) {
			this.m.multiData(this, roleDataSpan);
		}
	}

	ne(other: ModelIndex | PersistentModelIndex): boolean {
		return !this.eq(other);
	}

	parent(): ModelIndex {
		return this.m ?
			this.m.parentIndex(this) :
			new ModelIndex();
	}

	row(): number {
		return this.r;
	}

	sibling(row: number, column: number): ModelIndex {
		return this.m ?
			((this.r === row) && (this.c === column)) ?
				this :
				this.m.sibling(row, column, this) :
			new ModelIndex();
	}

	siblingAtColumn(column: number): ModelIndex {
		return this.m ?
			(this.c === column) ?
				this :
				this.m.sibling(this.r, column, this) :
			new ModelIndex();
	}

	siblingAtRow(row: number): ModelIndex {
		return this.m ?
			(this.r === row) ?
				this :
				this.m.sibling(row, this.c, this) :
			new ModelIndex();
	}

	toString(): string {
		return `ModelIndex(${this.r}, ${this.c}, ${this.m ? this.m.metaObject().className() : 'null'})`;
	}
}

class PersistentModelIndexData {
	static create(index: ModelIndex): PersistentModelIndexData {
		// Never insert an invalid index
		assert(index.isValid());
		const model = <AbstractItemModel>index.model();
		let d: PersistentModelIndexData;
		const indexes = model.d.persistent.indexes;
		const data = indexes.get(index);
		if (data.length > 0) {
			d = data[data.length - 1];
		} else {
			d = new PersistentModelIndexData(index);
			data.push(d);
		}
		assert(d);
		return d;
	}

	static destroy(data: PersistentModelIndexData): void {
		assert(data);
		assert(data.ref === null);
		const model = data.index.model();
		// A valid persistent model index with a null model pointer can only
		// happen if the model was destroyed
		if (model) {
			const modelD = model.d;
			assert(modelD);
			modelD.removePersistentIndexData(data);
		}
	}

	index: ModelIndex;
	ref: unknown | null;

	constructor(index: ModelIndex) {
		this.index = index;
		this.ref = null;
	}
}

export class PersistentModelIndex {
	d: PersistentModelIndexData | null;

	constructor(index?: PersistentModelIndex | ModelIndex) {
		this.d = null;
		if (index) {
			if (index instanceof PersistentModelIndex) {
				this.d = index.d;
				index.d = null;
			} else {
				if (index.isValid()) {
					this.d = PersistentModelIndexData.create(index);
				}
			}
		}
	}

	cast(): ModelIndex {
		if (this.d) {
			return this.d.index;
		}
		return new ModelIndex();
	}

	column(): number {
		if (this.d) {
			return this.d.index.column();
		}
		return -1;
	}

	data(role: ItemDataRole = ItemDataRole.DisplayRole): Variant {
		if (this.d) {
			return this.d.index.data(role);
		}
		return new Variant();
	}

	destroy(): void {
		if (this.d) {
			PersistentModelIndexData.destroy(this.d);
			this.d = null;
		}
	}

	eq(other: PersistentModelIndex | ModelIndex): boolean {
		if (other instanceof PersistentModelIndex) {
			if (this.d === other.d) {
				return true;
			}
			if (this.d && other.d) {
				return this.d.index.eq(other.d.index);
			}
			return false;
		} else {
			if (this.d) {
				return this.d.index.eq(other);
			}
			return !other.isValid();
		}
	}

	flags(): ItemFlag {
		if (this.d) {
			return this.d.index.flags();
		}
		return 0;
	}

	internalPointer(): unknown {
		if (this.d) {
			return this.d.index.internalPointer();
		}
		return null;
	}

	isValid(): boolean {
		if (this.d) {
			return this.d.index.isValid();
		}
		return false;
	}

	lt(other: PersistentModelIndex | ModelIndex): boolean {
		if (other instanceof PersistentModelIndex) {
			if (this.d && other.d) {
				return this.d.index.lt(other.d.index);
			}
		} else {
			if (this.d) {
				return this.d.index.lt(other);
			}
		}
		return false;
	}

	model(): AbstractItemModel | null {
		if (this.d) {
			return this.d.index.model();
		}
		return null;
	}

	multiData(roleDataSpan: ModelRoleDataSpan): void {
		if (this.d) {
			this.d.index.multiData(roleDataSpan);
		}
	}

	ne(other: PersistentModelIndex | ModelIndex): boolean {
		return !this.eq(other);
	}

	parent(): ModelIndex {
		if (this.d) {
			return this.d.index.parent();
		}
		return new ModelIndex();
	}

	row(): number {
		if (this.d) {
			return this.d.index.row();
		}
		return -1;
	}

	sibling(row: number, column: number): ModelIndex {
		if (this.d) {
			return this.d.index.sibling(row, column);
		}
		return new ModelIndex();
	}

	toString(): string {
		const m = this.model();
		return `PersistentModelIndex(${this.row()}, ${this.column()}, ${m ? m.metaObject().className() : 'null'})`;
	}
}

class Change {
	first: number;
	last: number;
	needsAdjust: boolean;
	parent: ModelIndex;

	constructor(parent: ModelIndex = new ModelIndex(), first: number = -1, last: number = -1) {
		this.first = first;
		this.last = last;
		this.needsAdjust = false;
		this.parent = parent;
	}

	isValid(): boolean {
		return (this.first >= 0) && (this.last >= 0);
	}
}

class Persistent {
	indexes: defaultdict<ModelIndex, Array<PersistentModelIndexData>> = new defaultdict<ModelIndex, Array<PersistentModelIndexData>>(Array);
	moved: stack<Array<PersistentModelIndexData>> = new stack();
	invalidated: stack<Array<PersistentModelIndexData>> = new stack();

	insertMultiAtEnd(key: ModelIndex, data: PersistentModelIndexData | Array<PersistentModelIndexData>): void {
		const dataList = this.indexes.get(key);
		dataList.unshift(...(Array.isArray(data) ? data : [data]));
	}
}

const DefaultRoleNames: Map<number, string> = new Map([
	[ItemDataRole.DisplayRole, 'display'],
	[ItemDataRole.DecorationRole, 'decoration'],
	[ItemDataRole.EditRole, 'edit'],
	[ItemDataRole.ToolTipRole, 'toolTip'],
	[ItemDataRole.StatusTipRole, 'statusTip'],
	[ItemDataRole.WhatsThisRole, 'whatsThis'],
]);

function typeOfVariant(value: Variant): number {
	// Return 0 for number, 1 for floating/decimal, 2 for other
	switch (value.type()) {
		case MetaType.Number: {
			return 0;
		}
		case MetaType.Decimal: {
			return 1;
		}
		default: {
			return 2;
		}
	}
}

export class AbstractItemModelPrivate extends ObjPrivate {
	static defaultRoleNames(): Map<number, string> {
		return DefaultRoleNames;
	}

	static isVariantLessThan(left: Variant, right: Variant, cs: CaseSensitivity): boolean {
		if (left.type() === MetaType.Invalid) {
			return false;
		}
		if (right.type() === MetaType.Invalid) {
			return true;
		}
		switch (left.type()) {
			case MetaType.Number: {
				return left.toNumber() < right.toNumber();
			}
			case MetaType.Date: {
				return left.toDate().lt(right.toDate());
			}
			case MetaType.Time: {
				return left.toTime().lt(right.toTime());
			}
			case MetaType.DateTime: {
				return left.toDateTime().lt(right.toDateTime());
			}
			default: {
				return (new Intl.Collator(undefined, {sensitivity: cs === CaseSensitivity.CaseInsensitive ? 'accent' : 'variant'})).compare(left.toString(), right.toString()) < 0;
			}
		}
	}

	static staticEmptyModel(): AbstractItemModel {
		return staticEmptyModel;
	}

	static variantLessThan(v1: Variant, v2: Variant): boolean {
		switch (Math.max(typeOfVariant(v1), typeOfVariant(v2))) {
			case 0: {
				return v1.toNumber() < v2.toNumber();
			}
			case 1: {
				return v1.toDecimal().lt(v2.toDecimal());
			}
			default: {
				return v1.toString().localeCompare(v2.toString()) < 0;
			}
		}
	}

	changes: stack<Change> = new stack();
	persistent: Persistent = new Persistent();
	roleNames: Map<number, string> = AbstractItemModelPrivate.defaultRoleNames();

	get q(): AbstractItemModel {
		return <AbstractItemModel>super.q;
	}

	allowMove(srcParent: ModelIndex, srcFirst: number, srcLast: number, destinationParent: ModelIndex, destinationStart: number, orientation: Orientation): boolean {
		/**
		 * Returns whether a move operation is valid.
		 *
		 * A move operation is not allowed if it moves a continuous range of
		 * rows to a destination within itself, or if it attempts to move a
		 * row to one of its own descendants.
		 */
		if (destinationParent === srcParent) {
			// Don't move the range within itself.
			return !((destinationStart >= srcFirst) && (destinationStart <= (srcLast + 1)));
		}
		let destinationAncestor: ModelIndex = destinationParent;
		let pos: number = (orientation === Orientation.Vertical) ?
			destinationAncestor.row() :
			destinationAncestor.column();
		while (true) {
			if (destinationAncestor.eq(srcParent)) {
				if ((pos >= srcFirst) && (pos <= srcLast)) {
					return false;
				}
				break;
			}
			if (!destinationAncestor.isValid()) {
				break;
			}
			pos = (orientation === Orientation.Vertical) ?
				destinationAncestor.row() :
				destinationAncestor.column();
			destinationAncestor = destinationAncestor.parent();
		}
		return true;
	}

	createIndex(row: number, column: number, data?: unknown): ModelIndex {
		return new ModelIndex(row, column, this.q, data);
	}

	columnsAboutToBeInserted(parent: ModelIndex, first: number, last: number): void {
		const q = this.q;
		const persistentMoved: Array<PersistentModelIndexData> = [];
		if (first < q.columnCount(parent)) {
			for (const data of iterPersistentModelIndexData(this.persistent.indexes)) {
				const index = data.index;
				if ((index.column() >= first) && index.isValid() && index.parent().eq(parent)) {
					persistentMoved.push(data);
				}
			}
		}
		this.persistent.moved.push(persistentMoved);
	}

	columnsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved: Array<PersistentModelIndexData> = [];
		const persistentInvalidated: Array<PersistentModelIndexData> = [];
		// Find the persistent indexes that are affected by the change,
		// either by being in the removed subtree or by being on the same
		// level and to the right of the removed columns.
		for (const data of iterPersistentModelIndexData(this.persistent.indexes)) {
			let levelChanged: boolean = false;
			let current: ModelIndex = data.index;
			while (current.isValid()) {
				const currentParent = current.parent();
				if (currentParent.eq(parent)) {
					// On the same level as the change
					if (!levelChanged && (current.column() > last)) {
						// Right of the removed columns
						persistentMoved.push(data);
					} else if ((current.column() <= last) && (current.column() >= first)) {
						// In the removed subtree
						persistentInvalidated.push(data);
					}
					break;
				}
				current = currentParent;
				levelChanged = true;
			}
		}
		this.persistent.moved.push(persistentMoved);
		this.persistent.invalidated.push(persistentInvalidated);
	}

	columnsInserted(parent: ModelIndex, first: number, last: number): void {
		const q = this.q;
		const persistentMoved = this.persistent.moved.pop();
		// It is important to only use the delta, because the change could
		// be nested
		const count = (last - first) + 1;
		for (const data of persistentMoved) {
			const old = data.index;
			this.persistent.indexes.delete(old);
			data.index = q.index(old.row(), old.column() + count, parent);
			if (data.index.isValid()) {
				this.persistent.insertMultiAtEnd(data.index, data);
			} else {
				logger.warning('AbstractItemModel::endInsertColumns: Invalid index (%s, %s) in model %s', old.row(), (old.column() + count), q);
			}
		}
	}

	columnsRemoved(parent: ModelIndex, first: number, last: number): void {
		const q = this.q;
		const persistentMoved = this.persistent.moved.pop();
		// It is important to only use the delta, because the change could
		// be nested
		const count = (last - first) + 1;
		for (const data of persistentMoved) {
			const old = data.index;
			this.persistent.indexes.delete(old);
			data.index = q.index(old.row(), old.column() - count, parent);
			if (data.index.isValid()) {
				this.persistent.insertMultiAtEnd(data.index, data);
			} else {
				logger.warning('AbstractItemModel::endRemoveColumns: Invalid index (%s, %s) in model %s', old.row(), (old.column() - count), q);
			}
		}
		const persistentInvalidated = this.persistent.invalidated.pop();
		for (const data of persistentInvalidated) {
			this.persistent.indexes.delete(data.index);
			data.index = new ModelIndex();
		}
	}

	indexValid(index: ModelIndex): boolean {
		return (index.row() >= 0) && (index.column() >= 0) && (index.model() === this.q);
	}

	invalidatePersistentIndex(index: ModelIndex): void {
		const data = this.persistent.indexes.get(index);
		if (data.length > 0) {
			const d = <PersistentModelIndexData>data.pop();
			d.index = new ModelIndex();
		}
	}

	invalidatePersistentIndexes(): void {
		for (const d of iterPersistentModelIndexData(this.persistent.indexes)) {
			d.index = new ModelIndex();
		}
		this.persistent.indexes.clear();
	}

	itemsAboutToBeMoved(srcParent: ModelIndex, srcFirst: number, srcLast: number, destinationParent: ModelIndex, destinationChild: number, orientation: Orientation): void {
		const persistentMovedExplicitly: Array<PersistentModelIndexData> = [];
		const persistentMovedInSource: Array<PersistentModelIndexData> = [];
		const persistentMovedInDestination: Array<PersistentModelIndexData> = [];
		const sameParent = srcParent.eq(destinationParent);
		const movingUp = (srcFirst > destinationChild);
		for (const data of iterPersistentModelIndexData(this.persistent.indexes)) {
			const index = data.index;
			const parent = index.parent();
			const isSourceIndex = parent.eq(srcParent);
			const isDestinationIndex = parent.eq(destinationParent);
			const childPosition = (orientation === Orientation.Vertical) ?
				index.row() :
				index.column();
			if (!index.isValid() || !(isSourceIndex || isDestinationIndex)) {
				continue;
			}
			if (!sameParent && isDestinationIndex) {
				if (childPosition >= destinationChild) {
					persistentMovedInDestination.push(data);
				}
				continue;
			}
			if (sameParent && movingUp && (childPosition < destinationChild)) {
				continue;
			}
			if (sameParent && !movingUp && (childPosition < srcFirst)) {
				continue;
			}
			if (!sameParent && (childPosition < srcFirst)) {
				continue;
			}
			if (sameParent && (childPosition > srcLast) && (childPosition >= destinationChild)) {
				continue;
			}
			if ((childPosition <= srcLast) && (childPosition >= srcFirst)) {
				persistentMovedExplicitly.push(data);
			} else {
				persistentMovedInSource.push(data);
			}
		}
		this.persistent.moved.push(persistentMovedExplicitly);
		this.persistent.moved.push(persistentMovedInSource);
		this.persistent.moved.push(persistentMovedInDestination);
	}

	itemsMoved(srcParent: ModelIndex, srcFirst: number, srcLast: number, destinationParent: ModelIndex, destinationChild: number, orientation: Orientation): void {
		const movedInDestination = this.persistent.moved.pop();
		const movedInSource = this.persistent.moved.pop();
		const movedExplicitly = this.persistent.moved.pop();
		const sameParent = srcParent.eq(destinationParent);
		const movingUp = (srcFirst > destinationChild);
		const explicitChange = (!sameParent || movingUp) ? (destinationChild - srcFirst) : (destinationChild - srcLast - 1);
		const sourceChange = (!sameParent || !movingUp) ? (-1 * (srcLast - srcFirst + 1)) : (srcLast - srcFirst + 1);
		const destinationChange = (srcLast - srcFirst + 1);
		this.movePersistentIndexes(movedExplicitly, explicitChange, destinationParent, orientation);
		this.movePersistentIndexes(movedInSource, sourceChange, srcParent, orientation);
		this.movePersistentIndexes(movedInDestination, destinationChange, destinationParent, orientation);
	}

	movePersistentIndexes(indexes: Array<PersistentModelIndexData>, change: number, parent: ModelIndex, orientation: Orientation): void {
		const q = this.q;
		for (const data of iterPersistentModelIndexData(this.persistent.indexes)) {
			let row = data.index.row();
			let column = data.index.column();
			if (orientation === Orientation.Vertical) {
				row += change;
			} else {
				column += change;
			}
			this.persistent.indexes.delete(data.index);
			data.index = q.index(row, column, parent);
			if (data.index.isValid()) {
				this.persistent.insertMultiAtEnd(data.index, data);
			} else {
				logger.warning('AbstractItemModel::endMoveRows: Invalid index (%s, %s) in model %s', row, column, q);
			}
		}
	}

	removePersistentIndexData(data: PersistentModelIndexData): void {
		if (data.index.isValid()) {
			const deleted = this.persistent.indexes.delete(data.index);
			assert(deleted, 'PersistentModelIndex::destroy persistent model indexes corrupted');
			// This assert may happen if the model use changePersistentIndex
			// in a way that could result on two PersistentModelIndex pointing
			// to the same index.
		}
		// Make sure our optimization still works
		for (let i = this.persistent.moved.count() - 1; i >= 0; --i) {
			const idx = this.persistent.moved.at(i).indexOf(data);
			if (idx >= 0) {
				this.persistent.moved.at(i).splice(idx, 1);
			}
		}
		// Update the references to invalidated persistent indexes
		for (let i = this.persistent.invalidated.count() - 1; i >= 0; --i) {
			const idx = this.persistent.invalidated.at(i).indexOf(data);
			if (idx >= 0) {
				this.persistent.invalidated.at(i).splice(idx, 1);
			}
		}
	}

	rowsAboutToBeInserted(parent: ModelIndex, first: number, last: number): void {
		const q = this.q;
		const persistentMoved: Array<PersistentModelIndexData> = [];
		if (first < q.rowCount(parent)) {
			for (const d of iterPersistentModelIndexData(this.persistent.indexes)) {
				const index = d.index;
				if (index.row() >= first && index.isValid() && index.parent().eq(parent)) {
					persistentMoved.push(d);
				}
			}
		}
		this.persistent.moved.push(persistentMoved);
	}

	rowsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
		const persistentMoved: Array<PersistentModelIndexData> = [];
		const persistentInvalidated: Array<PersistentModelIndexData> = [];
		// Find the persistent indexes that are affected by the change, either
		// by being in the removed subtree or by being on the same level and
		// below the removed rows.
		for (const data of iterPersistentModelIndexData(this.persistent.indexes)) {
			let levelChanged: boolean = false;
			let current: ModelIndex = data.index;
			while (current.isValid()) {
				const currentParent = current.parent();
				if (currentParent.eq(parent)) {
					// On the same level as the change
					if (!levelChanged && (current.row() > last)) {
						// Below the removed rows
						persistentMoved.push(data);
					} else if ((current.row() <= last) && (current.row() >= first)) {
						// In the removed subtree
						persistentInvalidated.push(data);
					}
					break;
				}
				current = currentParent;
				levelChanged = true;
			}
		}
		this.persistent.moved.push(persistentMoved);
		this.persistent.invalidated.push(persistentInvalidated);
	}

	rowsInserted(parent: ModelIndex, first: number, last: number): void {
		const q = this.q;
		const persistentMoved: Array<PersistentModelIndexData> = this.persistent.moved.pop();
		// It is important to only use the delta, because the change could
		// be nested
		const count = (last - first) + 1;
		for (const data of persistentMoved) {
			const old = data.index;
			this.persistent.indexes.delete(old);
			data.index = q.index(old.row() + count, old.column(), parent);
			if (data.index.isValid()) {
				this.persistent.insertMultiAtEnd(data.index, data);
			} else {
				logger.warning('AbstractItemModel::endInsertRows: Invalid index (%s, %s) in model %s', (old.row() + count), old.column(), q);
			}
		}
	}

	rowsRemoved(parent: ModelIndex, first: number, last: number): void {
		const q = this.q;
		const persistentMoved = this.persistent.moved.pop();
		// It is important to only use the delta, because the change could
		// be nested
		const count = (last - first) + 1;
		for (const data of persistentMoved) {
			const old = data.index;
			this.persistent.indexes.delete(old);
			data.index = q.index(old.row() - count, old.column(), parent);
			if (data.index.isValid()) {
				this.persistent.insertMultiAtEnd(data.index, data);
			} else {
				logger.warning('AbstractItemModel::endRemoveRows: Invalid index (%s, %s) in model %s', (old.row() - count), old.column(), q);
			}
		}
		const persistentInvalidated = this.persistent.invalidated.pop();
		for (const data of persistentInvalidated) {
			this.persistent.indexes.delete(data.index);
			data.index = new ModelIndex();
		}
	}
}

export interface AbstractItemModelOpts extends ObjOpts {
	dd: AbstractItemModelPrivate;
}

@OBJ
export class AbstractItemModel extends Obj {
	/**
	 * When subclassing AbstractItemModel, at the very least you must
	 * implement:
	 * 		columnCount()
	 * 		data()
	 * 		index()
	 * 		parentIndex()
	 * 		rowCount()
	 * These functions are used in all read-only models, and form the basis of
	 * editable models.
	 */
	constructor(opts: Partial<AbstractItemModelOpts> = {}) {
		if (!opts.dd) {
			opts.dd = new AbstractItemModelPrivate();
		}
		super(opts);
	}

	get d(): AbstractItemModelPrivate {
		return <AbstractItemModelPrivate>super.d;
	}

	beginInsertColumns(parent: ModelIndex, first: number, last: number): void {
		/**
		 * Begins a column insertion operation.
		 *
		 * When reimplementing insertColumns() in a subclass, you must call
		 * this function BEFORE inserting data into the model's underlying
		 * data store.
		 *
		 * The `parent` index corresponds to the parent into which the new
		 * columns are inserted; `first` and `last` are the column numbers of
		 * the new columns will have after they have been inserted.
		 *
		 * Note: This function emits the columnsAboutToBeInserted() signal
		 * which connected views (or proxies) must handle before the data is
		 * inserted. Otherwise, the views may end up in an invalid state.
		 */
		assert(first >= 0);
		assert(first <= this.columnCount(parent));
		assert(last >= first);
		const d = this.d;
		d.changes.push(new Change(parent, first, last));
		this.columnsAboutToBeInserted(parent, first, last);
		d.columnsAboutToBeInserted(parent, first, last);
	}

	beginInsertRows(parent: ModelIndex, first: number, last: number): void {
		/**
		 * Begins a row insertion operation.
		 *
		 * When reimplementing insertRows() in a subclass, you must call this
		 * function BEFORE inserting data into the model's underlying data
		 * store.
		 *
		 * The `parent` index corresponds to the parent into which the new
		 * rows are inserted; `first` and `last` are the row numbers that the
		 * new rows will have after they have been inserted.
		 *
		 * Note: This function emits the rowsAboutToBeInserted() signal which
		 * connected views (or proxies) must handle before the data is
		 * inserted. Otherwise, the views may end up in an invalid state.
		 */
		assert(first >= 0);
		assert(first <= this.rowCount(parent)); // == is allowed, to insert at the end
		assert(last >= first);
		const d = this.d;
		d.changes.push(new Change(parent, first, last));
		this.rowsAboutToBeInserted(parent, first, last);
		d.rowsAboutToBeInserted(parent, first, last);
	}

	beginMoveColumns(sourceParent: ModelIndex, sourceFirst: number, sourceLast: number, destinationParent: ModelIndex, destinationColumn: number): boolean {
		/**
		 * Begins a column move operation.
		 *
		 * When reimplementing a subclass, this method simplifies moving
		 * entities in your model. This method is responsible for moving
		 * persistent indexes in the model, which you would otherwise be
		 * required to do yourself. Using beginMoveColumns and endMoveColumns
		 * is an alternative to emitting layoutAboutToBeChanged and
		 * layoutChanged directly along with changePersistentIndex.
		 *
		 * The `sourceParent` index corresponds to the parent from which the
		 * columns are moved; `sourceFirst` and `sourceLast` are the first and
		 * last column numbers of the columns to be moved. The
		 * `destinationParent` index corresponds to the parent into which
		 * those columns are moved. The `destinationChild` is the column to
		 * which the columns will be moved. That is, the index at column
		 * `sourceFirst` in `sourceParent` will become column
		 * `destinationChild` in `destinationParent`, followed by all other
		 * columns up to `sourceLast`.
		 *
		 * However, when moving columns down in the same parent (`sourceParent`
		 * and `destinationParent` are equal), the columns will be placed
		 * before the `destinationChild` index. That is, if you wish to move
		 * columns 0 and 1 so they will become columns 1 and 2,
		 * `destinationChild` should be 3. In this case, the new index for the
		 * source column `i` (which is between `sourceFirst` and `sourceLast`)
		 * is equal to destinationChild - sourceLast - 1 + i.
		 *
		 * Note that if `sourceParent` and `destinationParent` are the same,
		 * you must ensure that the `destinationChild` is not within the range
		 * of `sourceFirst` and `sourceLast` + 1 ou must also ensure that you
		 * do not attempt to move a column to one of its own children or
		 * ancestors. This method returns false if either condition is true,
		 * in which case you should abort your move operation.
		 */
		if (this.beginMoveSections(sourceParent, sourceFirst, sourceLast, destinationParent, destinationColumn, Orientation.Horizontal)) {
			this.d.itemsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationColumn, Orientation.Horizontal);
			this.columnsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationColumn);
			return true;
		}
		return false;
	}

	beginMoveRows(sourceParent: ModelIndex, sourceFirst: number, sourceLast: number, destinationParent: ModelIndex, destinationRow: number): boolean {
		/**
		 * Begins a row move operation.
		 *
		 * When reimplementing a subclass, this method simplifies moving
		 * entities in your model. This method is responsible for moving
		 * persistent indexes in the model, which you would otherwise be
		 * required to do yourself. Using beginMoveRows and endMoveRows is an
		 * alternative to emitting layoutAboutToBeChanged and layoutChanged
		 * directly along with changePersistentIndex.
		 *
		 * The `sourceParent` index corresponds to the parent from which the
		 * rows are moved; `sourceFirst` and `sourceLast` are the first and
		 * last row numbers of the rows to be moved. The `destinationParent`
		 * index corresponds to the parent into which those rows are moved.
		 * The `destinationChild` is the row to which the rows will be moved.
		 * That is, the index at row `sourceFirst` in `sourceParent` will
		 * become row `destinationChild` in `destinationParent`, followed by
		 * all other rows up to `sourceLast`.
		 *
		 * However, when moving rows down in the same parent (`sourceParent`
		 * and `destinationParent` are equal), the rows will be placed before
		 * the `destinationChild` index. That is, if you wish to move rows 0
		 * and 1 so they will become rows 1 and 2, `destinationChild` should
		 * be 3. In this case, the new index for the source row `i` (which is
		 * between `sourceFirst` and `sourceLast`) is equal to
		 * destinationChild - sourceLast - 1 + i.
		 *
		 * Note that if `sourceParent` and `destinationParent` are the same,
		 * you must ensure that the `destinationChild` is not within the range
		 * of `sourceFirst` and `sourceLast` + 1.  You must also ensure that
		 * you do not attempt to move a row to one of its own children or
		 * ancestors. This method returns false if either condition is true,
		 * in which case you should abort your move operation.
		 */
		if (this.beginMoveSections(sourceParent, sourceFirst, sourceLast, destinationParent, destinationRow, Orientation.Vertical)) {
			this.rowsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationRow);
			this.d.itemsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationRow, Orientation.Vertical);
			return true;
		}
		return false;
	}

	private beginMoveSections(sourceParent: ModelIndex, sourceFirst: number, sourceLast: number, destinationParent: ModelIndex, destinationSection: number, orientation: Orientation): boolean {
		assert(sourceFirst >= 0);
		assert(sourceLast >= sourceFirst);
		assert(destinationSection >= 0);
		const d = this.d;
		if (!d.allowMove(sourceParent, sourceFirst, sourceLast, destinationParent, destinationSection, orientation)) {
			return false;
		}
		const sourceChange = new Change(sourceParent, sourceFirst, sourceLast);
		sourceChange.needsAdjust = sourceParent.isValid() && (sourceParent.row() >= destinationSection) && sourceParent.parent().eq(destinationParent);
		d.changes.push(sourceChange);
		const destinationLast = destinationSection + (sourceLast - sourceFirst);
		const destinationChange = new Change(destinationParent, destinationSection, destinationLast);
		destinationChange.needsAdjust = destinationParent.isValid() && (destinationParent.row() >= sourceLast) && destinationParent.parent().eq(sourceParent);
		d.changes.push(destinationChange);
		this.rowsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationSection);
		d.itemsAboutToBeMoved(sourceParent, sourceFirst, sourceLast, destinationParent, destinationSection, orientation);
		return true;
	}

	beginRemoveColumns(parent: ModelIndex, first: number, last: number): void {
		/**
		 * Begins a column removal operation.
		 *
		 * When reimplementing removeColumns() in a subclass, you must call
		 * this function BEFORE removing data from the model's underlying data
		 * store.
		 *
		 * The `parent` index corresponds to the parent from which the new
		 * columns are removed; `first` and `last` are the column numbers of
		 * the first and last columns to be removed.
		 *
		 * Note: This function emits the columnsAboutToBeRemoved() signal
		 * which connected views (or proxies) must handle before the data is
		 * removed. Otherwise, the views may end up in an invalid state.
		 */
		assert(first >= 0);
		assert(last >= first);
		assert(last < this.columnCount(parent));
		const d = this.d;
		d.changes.push(new Change(parent, first, last));
		this.columnsAboutToBeRemoved(parent, first, last);
		d.columnsAboutToBeRemoved(parent, first, last);
	}

	beginRemoveRows(parent: ModelIndex, first: number, last: number): void {
		/**
		 * Begins a row removal operation.
		 *
		 * When reimplementing removeRows() in a subclass, you must call this
		 * function BEFORE removing data from the model's underlying data
		 * store.
		 *
		 * The `parent` index corresponds to the parent from which the new
		 * rows are removed; `first` and `last` are the row numbers of the
		 * rows to be removed.
		 *
		 * Note: This function emits the rowsAboutToBeRemoved() signal which
		 * connected views (or proxies) must handle before the data is
		 * removed. Otherwise, the views may end up in an invalid state.
		 */
		assert(first >= 0);
		assert(last >= first);
		assert(last < this.rowCount(parent));
		const d = this.d;
		d.changes.push(new Change(parent, first, last));
		this.rowsAboutToBeRemoved(parent, first, last);
		d.rowsAboutToBeRemoved(parent, first, last);
	}

	beginResetModel(): void {
		/**
		 * Begins a model reset operation.
		 *
		 * A reset operation resets the model to its current state in any
		 * attached views.
		 *
		 * Note: Any views attached to this model will be reset as well.
		 *
		 * When a model is reset it means that any previous data reported from
		 * the model is now invalid and has to be queried for again. This also
		 * means that the current item and any selected items will become
		 * invalid.
		 *
		 * When a model radically changes its data it can sometimes be easier
		 * to just call this function rather than emit dataChanged() to inform
		 * other components when the underlying data source, or its structure,
		 * has changed.
		 *
		 * You must call this function before resetting any internal data
		 * structures in your model or proxy model.
		 *
		 * This function emits the signal modelAboutToBeReset().
		 */
		this.modelAboutToBeReset();
	}

	buddy(index: ModelIndex): ModelIndex {
		/**
		 * Returns a model index for the buddy of the item represented by
		 * `index`. When the user wants to edit an item, the view will call
		 * this function to check whether another item in the model should be
		 * edited instead. Then, the view will construct a delegate using the
		 * model index returned by the buddy item.
		 *
		 * Note: The default implementation of this function has each item as
		 * its own buddy.
		 */
		return index;
	}

	canFetchMore(parent: ModelIndex): boolean {
		/**
		 * Returns true if there is more data available for parent; otherwise
		 * returns false.
		 *
		 * If canFetchMore() returns true, the fetchMore() function should be
		 * called. This is the behavior of AbstractItemView, for example.
		 *
		 * Note: The default implementation always returns false.
		 */
		return false;
	}

	changePersistentIndex(from: ModelIndex, to: ModelIndex): void {
		const d = this.d;
		if (d.persistent.indexes.size < 1) {
			return;
		}
		// Find the data and reinsert it sorted
		const dataList = d.persistent.indexes.get(from);
		if (dataList.length > 0) {
			const data = dataList[dataList.length - 1];
			d.persistent.indexes.delete(from);
			data.index = to;
			if (data.index.isValid()) {
				d.persistent.insertMultiAtEnd(data.index, data);
			}
		}
	}

	changePersistentIndexList(from: list<ModelIndex>, to: list<ModelIndex>): void {
		const d = this.d;
		if (d.persistent.indexes.size < 1) {
			return;
		}
		const toBeInserted = new list<PersistentModelIndexData>();
		for (let i = 0; i < from.size(); ++i) {
			if (to.at(i) && (from.at(i).eq(to.at(i)))) {
				continue;
			}
			const dataList = d.persistent.indexes.get(from.at(i));
			if (dataList.length > 0) {
				const data = dataList[dataList.length - 1];
				d.persistent.indexes.delete(from.at(i));
				data.index = to.at(i) || new ModelIndex();
				if (data.index.isValid()) {
					toBeInserted.append(data);
				} else {
					logger.warning('AbstractItemModel::changePersistentIndexList: Invalid index derived from arguments: to[%s] in model %s', to.at(i), this);
				}
			}
		}
		for (const data of toBeInserted) {
			d.persistent.insertMultiAtEnd(data.index, data);
		}
	}

	checkIndex(index: ModelIndex, options: CheckIndexOption = CheckIndexOption.NoOption): boolean {
		/**
		 * This function checks whether `index` is a legal model index for
		 * this model. A legal model index is either an invalid model index,
		 * or a valid model index for which all the following holds:
		 *
		 * - the index' model is this;
		 * - the index' row is greater or equal than zero;
		 * - the index' row is less than the row count for the index' parent;
		 * - the index' column is greater or equal than zero;
		 * - the index' column is less than the column count for the index' parent.
		 *
		 * The `options` argument may change some of these checks. If
		 * `options` contains `IndexIsValid`, then `index` must be a valid
		 * index; this is useful when reimplementing functions such as data()
		 * or setData(), which expect valid indexes.
		 *
		 * If `options` contains `DoNotUseParent`, then the checks that would
		 * call parent() are omitted; this allows calling this function from a
		 * parent() reimplementation (otherwise, this would result in endless
		 * recursion and a crash).
		 *
		 * If `options` does not contain `DoNotUseParent`, and it contains
		 * `ParentIsInvalid`, then an additional check is performed: the
		 * parent index is checked for not being valid. This is useful when
		 * implementing flat models such as lists or tables, where no model
		 * index should have a valid parent index.
		 *
		 * This function returns true if all the checks succeeded, and false
		 * otherwise. This allows to use the function in debugging mechanisms.
		 * If some check failed, a warning message will be printed containing
		 * some information that may be useful for debugging the failure.
		 *
		 * Note: This function is a debugging helper for implementing your own
		 * item models. When developing complex models, as well as when
		 * building complicated model hierarchies (e.g. using proxy models),
		 * it is useful to call this function in order to catch bugs relative
		 * to illegal model indices (as defined above) accidentally passed to
		 * some AbstractItemModel API.
		 *
		 * Warning: Note that it's undefined behavior to pass illegal indices
		 * to item models, so applications must refrain from doing so, and not
		 * rely on any "defensive" programming that item models could employ
		 * to handle illegal indexes gracefully.
		 */
		if (!index.isValid()) {
			if (options & CheckIndexOption.IndexIsValid) {
				logger.warning(`Warning: Index ${index} is not valid (expected valid)`);
				return false;
			}
			return true;
		}
		if (index.model() !== this) {
			const m = index.model();
			logger.warning(`Warning: Index ${index} is for model ${m ? m : 'null'} which is different from this model ${this}`);
			return false;
		}
		if (index.row() < 0) {
			logger.warning(`Warning: Index ${index} has negative row ${index.row()}`);
			return false;
		}
		if (index.column() < 0) {
			logger.warning(`Warning: Index ${index} has negative column ${index.column()}`);
			return false;
		}
		if (!(options & CheckIndexOption.DoNotUseParent)) {
			const parentIndex = index.parent();
			if (options & CheckIndexOption.ParentIsInvalid) {
				if (parentIndex.isValid()) {
					logger.warning(`Warning: Index ${index} has valid parent ${parentIndex} (expected an invalid parent)`);
					return false;
				}
			}
			const rc = this.rowCount(parentIndex);
			if (index.row() >= rc) {
				logger.warning(`Warning: Index ${index} has out of range row ${index.row()} rowCount() is ${rc}`);
				return false;
			}
			const cc = this.columnCount(parentIndex);
			if (index.column() >= cc) {
				logger.warning(`Warning: Index ${index} has out of range column ${index.column()} columnCount() is ${cc}`);
				return false;
			}
		}
		return true;
	}

	clearItemData(index: ModelIndex): boolean {
		/**
		 * Removes the data stored in all the roles for the given index.
		 * Returns true if successful; otherwise returns false. The
		 * dataChanged() signal should be emitted if the data was successfully
		 * removed.
		 *
		 * The base class implementation returns false.
		 */
		return false;
	}

	columnCount(parent: ModelIndex | PersistentModelIndex = new ModelIndex()): number {
		return 0;
	}

	@SIGNAL
	columnsAboutToBeInserted(parent: ModelIndex, first: number, last: number): void {
	}

	@SIGNAL
	columnsAboutToBeMoved(sourceParent: ModelIndex, sourceStart: number, sourceEnd: number, destinationParent: ModelIndex, destinationColumn: number): void {
	}

	@SIGNAL
	columnsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	@SIGNAL
	columnsInserted(parent: ModelIndex, first: number, last: number): void {
	}

	@SIGNAL
	columnsMoved(parent: ModelIndex, start: number, end: number, destination: ModelIndex, column: number): void {
	}

	@SIGNAL
	columnsRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	createIndex(row: number, column: number, data?: unknown): ModelIndex {
		return new ModelIndex(row, column, this, data);
	}

	data(index: ModelIndex, role: number = ItemDataRole.DisplayRole): Variant {
		/**
		 * Returns the data stored under the given role for the item referred
		 * to by the index. If you do not have a value to return, return an
		 * invalid Variant instead of returning null or undefined.
		 */
		return new Variant();
	}

	@SIGNAL
	dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles?: Array<number>): void {
	}

	destroy(): void {
		this.d.invalidatePersistentIndexes();
		super.destroy();
	}

	private doSetRoleNames(roleNames: Map<number, string>): void {
		this.d.roleNames = roleNames;
	}

	endInsertColumns(): void {
		/**
		 * Ends a column insertion operation.
		 *
		 * When reimplementing insertColumns() in a subclass, you must call
		 * this function AFTER inserting data into the model's underlying data
		 * store.
		 */
		const d = this.d;
		const change = d.changes.pop();
		const {parent, first, last} = change;
		d.columnsInserted(parent, first, last);
		this.columnsInserted(parent, first, last);
	}

	endInsertRows(): void {
		/**
		 * Ends a row insertion operation.
		 *
		 * When reimplementing insertRows() in a subclass, you must call this
		 * function AFTER inserting data into the model's underlying data
		 * store.
		 */
		const d = this.d;
		const change = d.changes.pop();
		const {parent, first, last} = change;
		d.rowsInserted(parent, first, last);
		this.rowsInserted(parent, first, last);
	}

	endMoveColumns(): void {
		/**
		 * Ends a column move operation.
		 *
		 * When implementing a subclass, you must call this function AFTER
		 * moving data within the model's underlying data store.
		 */
		const d = this.d;
		const insertChange = d.changes.pop();
		const removeChange = d.changes.pop();
		let adjustedSource = removeChange.parent;
		let adjustedDestination = insertChange.parent;
		const numMoved = removeChange.last - removeChange.first + 1;
		if (insertChange.needsAdjust) {
			adjustedDestination = this.createIndex(adjustedDestination.row(), adjustedDestination.column() - numMoved);
		}
		if (removeChange.needsAdjust) {
			adjustedSource = this.createIndex(adjustedSource.row(), adjustedSource.column() + numMoved);
		}
		d.itemsMoved(adjustedSource, removeChange.first, removeChange.last, adjustedDestination, insertChange.first, Orientation.Horizontal);
		this.columnsMoved(adjustedSource, removeChange.first, removeChange.last, adjustedDestination, insertChange.first);
	}

	endMoveRows(): void {
		/**
		 * Ends a row move operation.
		 *
		 * When implementing a subclass, you must call this function AFTER
		 * moving data within the model's underlying data store.
		 */
		const d = this.d;
		const insertChange = d.changes.pop();
		const removeChange = d.changes.pop();
		let adjustedSource = removeChange.parent;
		let adjustedDestination = insertChange.parent;
		const numMoved = removeChange.last - removeChange.first + 1;
		if (insertChange.needsAdjust) {
			adjustedDestination = this.createIndex(adjustedDestination.row() - numMoved, adjustedDestination.column());
		}
		if (removeChange.needsAdjust) {
			adjustedSource = this.createIndex(adjustedSource.row() + numMoved, adjustedSource.column());
		}
		d.itemsMoved(adjustedSource, removeChange.first, removeChange.last, adjustedDestination, insertChange.first, Orientation.Vertical);
		this.rowsMoved(adjustedSource, removeChange.first, removeChange.last, adjustedDestination, insertChange.first);
	}

	endRemoveColumns(): void {
		/**
		 * Ends a column removal operation.
		 *
		 * When reimplementing removeColumns() in a subclass, you must call
		 * this function AFTER removing data from the model's underlying data
		 * store.
		 */
		const d = this.d;
		const change = d.changes.pop();
		const {parent, first, last} = change;
		d.columnsRemoved(parent, first, last);
		this.columnsRemoved(parent, first, last);
	}

	endRemoveRows(): void {
		/**
		 * Ends a row removal operation.
		 *
		 * When reimplementing removeRows() in a subclass, you must call this
		 * function AFTER removing data from the model's underlying data
		 * store.
		 */
		const d = this.d;
		const change = d.changes.pop();
		const {parent, first, last} = change;
		d.rowsRemoved(parent, first, last);
		this.rowsRemoved(parent, first, last);
	}

	endResetModel(): void {
		/**
		 * Completes a model reset operation.
		 *
		 * You must call this function AFTER resetting any internal data
		 * structure in your model or proxy model.
		 *
		 * This function emits the signal modelReset().
		 */
		this.d.invalidatePersistentIndexes();
		this.resetInternalData();
		this.modelReset();
	}

	eq(other: AbstractItemModel | null): boolean {
		return this === other;
	}

	fetchMore(parent: ModelIndex): void {
		/**
		 * Fetches any available data for the items with the parent specified
		 * by the `parent` index.
		 *
		 * Reimplement this if you are populating your model incrementally.
		 *
		 * Note: The default implementation does nothing.
		 */
	}

	flags(index: ModelIndex): ItemFlag {
		/**
		 * Returns the item flags for the given `index`.
		 *
		 * The base class implementation returns a combination of flags that
		 * enables the item (ItemIsEnabled) and allows it to be selected
		 * (ItemIsSelectable).
		 */
		if (!this.d.indexValid(index)) {
			return ItemFlag.NoItemFlags;
		}
		return ItemFlag.ItemIsSelectable | ItemFlag.ItemIsEnabled;
	}

	gt(other: AbstractItemModel | null): boolean {
		if (other === null) {
			return true;
		}
		return this.d.createdNumber > other.d.createdNumber;
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		return (this.rowCount(parent) > 0) && (this.columnCount(parent) > 0);
	}

	hasIndex(row: number, column: number, parent: ModelIndex = new ModelIndex()): boolean {
		if ((row < 0) || (column < 0)) {
			return false;
		}
		return (row < this.rowCount(parent)) && (column < this.columnCount(parent));
	}

	headerData(section: number, orientation: Orientation, role: number = ItemDataRole.DisplayRole): Variant {
		/**
		 * Returns the data for the given `role` and `section` in the header
		 * with the specified `orientation`.
		 *
		 * For horizontal headers, the section number corresponds to the
		 * column number. Similarly, for vertical headers, the section number
		 * corresponds to the row number.
		 */
		if (role === ItemDataRole.DisplayRole) {
			return new Variant(section + 1);
		}
		return new Variant();
	}

	@SIGNAL
	headerDataChanged(orientation: Orientation, first: number, last: number): void {
	}

	index(row: number, column: number, parent: ModelIndex | PersistentModelIndex = new ModelIndex()): ModelIndex {
		return new ModelIndex();
	}

	insertColumn(column: number, parent: ModelIndex = new ModelIndex()): boolean {
		return this.insertColumns(column, 1, parent);
	}

	insertColumns(column: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		/**
		 * On models that support this, inserts `count` new columns into the
		 * model before the given `column`. The items in each new column will
		 * be children of the item represented by the `parent` model index.
		 *
		 * If `column` is 0, the columns are prepended to any existing
		 * columns.
		 *
		 * If `column` is columnCount(), the columns are appended to any
		 * existing columns.
		 *
		 * If `parent` has no children, a single row with `count` columns is
		 * inserted.
		 *
		 * Returns true if the columns were successfully inserted; otherwise
		 * returns false.
		 *
		 * If you implement your own model, you can reimplement this function
		 * if you want to support insertions. Alternatively, you can provide
		 * your own API for altering the data.
		 *
		 * Note: The base class implementation does nothing and returns false.
		 */
		return false;
	}

	insertRow(row: number, parent: ModelIndex = new ModelIndex()): boolean {
		return this.insertRows(row, 1, parent);
	}

	insertRows(row: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		/**
		 * On models that support this, inserts `count` rows into the model
		 * before the given `row`. Items in the new row will be children of
		 * the item represented by the `parent` model index.
		 *
		 * If `row` is 0, the rows are prepended to any existing rows in the
		 * parent.
		 *
		 * If `row` is rowCount(), the rows are appended to any existing rows
		 * in the parent.
		 *
		 * If `parent` has no children, a single column with `count` rows is
		 * inserted.
		 *
		 * Returns true if the rows were successfully inserted; otherwise
		 * returns false.
		 *
		 * If you implement your own model, you can reimplement this function
		 * if you want to support insertions. Alternatively, you can provide
		 * your own API for altering the data. In either case, you will need
		 * to call beginInsertRows() and endInsertRows() to notify other
		 * components that the model has changed.
		 *
		 * Note: The base class implementation of this function does nothing
		 * and returns false.
		 */
		return false;
	}

	itemData(index: ModelIndex): Map<number, Variant> {
		const rv: Map<number, Variant> = new Map();
		for (let i = 0; i < ItemDataRole.UserRole; ++i) {
			const variantData = this.data(index, i);
			if (variantData.isValid()) {
				rv.set(i, variantData);
			}
		}
		return rv;
	}

	@SIGNAL
	layoutAboutToBeChanged(parents: Array<PersistentModelIndex> = [], hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
	}

	@SIGNAL
	layoutChanged(parents: Array<PersistentModelIndex> = [], hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
	}

	lt(other: AbstractItemModel | null): boolean {
		if (other === null) {
			return false;
		}
		return this.d.createdNumber < other.d.createdNumber;
	}

	match(start: ModelIndex, role: number, value: Variant, hits: number = -1, flags: MatchFlag = MatchFlag.MatchStartsWith | MatchFlag.MatchWrap): list<ModelIndex> {
		/**
		 * Returns a list of indexes for the items in the column of the
		 * `start` index where data stored under the given `role` matches the
		 * specified `value`. The way the search is performed is defined by
		 * the `flags` given. The list that is returned may be empty. Note
		 * also that the order of results in the list may not correspond to
		 * the order in the model, if for example a proxy model is used. The
		 * order of the results cannot be relied upon.
		 *
		 * The search begins from the `start` index, and continues until the
		 * number of matching data items equals `hits`, the search reaches the
		 * last row, or the search reaches `start` again - depending on
		 * whether MatchWrap is specified in `flags`. If you want to search
		 * for all matching items, use `hits` = -1.
		 *
		 * By default, this function will perform a wrapping, string-based
		 * comparison on all items, searching for items that begin with the
		 * search term specified by `value`.
		 *
		 * Note: The default implementation of this function only searches
		 * columns. Reimplement this function to include a different search
		 * behavior.
		 */
		const result = new list<ModelIndex>();
		const matchType = flags & 0x0F;
		const cs: CaseSensitivity = testFlag(flags, MatchFlag.MatchCaseSensitive) ?
			CaseSensitivity.CaseSensitive :
			CaseSensitivity.CaseInsensitive;
		const recurse = testFlag(flags, MatchFlag.MatchRecursive);
		const wrap = testFlag(flags, MatchFlag.MatchWrap);
		const allHits = (hits === -1);
		let text: string = '';
		let rx: RegExp = new RegExp('');
		const column = start.column();
		const p = this.parentIndex(start);
		let from = start.row();
		let to = this.rowCount(p);
		// Iterates twice if wrapping
		for (let i = 0; (wrap && (i < 2)) || (!wrap && (i < 1)); ++i) {
			for (let r = from; (r < to) && (allHits || (result.size() < hits)); ++r) {
				const idx = this.index(r, column, p);
				if (!idx.isValid()) {
					continue;
				}
				const v = this.data(idx, role);
				// QVariant based matching
				if (matchType === MatchFlag.MatchExactly) {
					if (value.eq(v)) {
						result.append(idx);
					}
				} else {
					// String or regular expression based matching
					if (matchType === MatchFlag.MatchRegularExpression) {
						if (!rx.source.trim()) {
							let rxFlags: string | undefined = undefined;
							if (cs === CaseSensitivity.CaseInsensitive) {
								rxFlags = 'i';
							}
							rx = new RegExp(value.toString(), rxFlags);
						}
					} else if (matchType === MatchFlag.MatchWildcard) {
						if (!rx.source.trim()) {
							let rxFlags: string | undefined = undefined;
							if (cs === CaseSensitivity.CaseInsensitive) {
								rxFlags = 'i';
							}
							rx = new RegExp(value.toString(), rxFlags);
						}
					} else {
						if (!text.trim()) {
							// Lazy conversion
							text = value.toString();
						}
					}
					const t = v.toString();
					switch (matchType) {
						case MatchFlag.MatchRegularExpression:
						case MatchFlag.MatchWildcard:
							if (t.match(rx)) {
								result.append(idx);
							}
							break;
						case MatchFlag.MatchStartsWith:
							if (cs === CaseSensitivity.CaseInsensitive) {
								if (t.toLocaleLowerCase().startsWith(text.toLocaleLowerCase())) {
									result.append(idx);
								}
							} else {
								if (t.startsWith(text)) {
									result.append(idx);
								}
							}
							break;
						case MatchFlag.MatchEndsWith:
							if (cs === CaseSensitivity.CaseInsensitive) {
								if (t.toLocaleLowerCase().endsWith(text.toLocaleLowerCase())) {
									result.append(idx);
								}
							} else {
								if (t.endsWith(text)) {
									result.append(idx);
								}
							}
							break;
						case MatchFlag.MatchFixedString:
							if (cs === CaseSensitivity.CaseInsensitive) {
								if (t.toLocaleLowerCase() === text.toLocaleLowerCase()) {
									result.append(idx);
								}
							} else {
								if (t === text) {
									result.append(idx);
								}
							}
							break;
						case MatchFlag.MatchContains:
						default:
							if (cs === CaseSensitivity.CaseInsensitive) {
								if (t.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) >= 0) {
									result.append(idx);
								}
							} else {
								if (t.indexOf(text) >= 0) {
									result.append(idx);
								}
							}
							break;
					}
				}
				if (recurse) {
					const parent = (column !== 0) ? idx.sibling(idx.row(), 0) : idx;
					if (this.hasChildren(parent)) {
						// Search the hierarchy
						result.plusEq(this.match(
							this.index(0, column, parent),
							role,
							(!text.trim() ? value : new Variant(text)),
							(allHits ? -1 : hits - result.size()),
							flags));
					}
				}
			}
			// Prepare for the next iteration
			from = 0;
			to = start.row();
		}
		return result;
	}

	@SIGNAL
	modelAboutToBeReset(): void {
	}

	@SIGNAL
	modelReset(): void {
	}

	moveColumn(sourceParent: ModelIndex, sourceColumn: number, destinationParent: ModelIndex, destinationChild: number): boolean {
		return this.moveColumns(sourceParent, sourceColumn, 1, destinationParent, destinationChild);
	}

	moveColumns(sourceParent: ModelIndex, sourceColumn: number, count: number, destinationParent: ModelIndex, destinationChild: number): boolean {
		/**
		 * On models that support this, moves `count` columns starting with
		 * the given `sourceColumn` under parent `sourceParent` to column
		 * `destinationChild` under parent `destinationParent`.
		 *
		 * Returns true if the columns were successfully moved; otherwise
		 * returns false.
		 *
		 * If you implement your own model, you can reimplement this function
		 * if you want to support moving. Alternatively, you can provide your
		 * own API for altering the data.
		 *
		 * Note: The base class implementation does nothing and returns false.
		 */
		return false;
	}

	moveRow(sourceParent: ModelIndex, sourceRow: number, destinationParent: ModelIndex, destinationChild: number): boolean {
		return this.moveRows(sourceParent, sourceRow, 1, destinationParent, destinationChild);
	}

	moveRows(sourceParent: ModelIndex, sourceRow: number, count: number, destinationParent: ModelIndex, destinationChild: number): boolean {
		/**
		 * On models that support this, moves `count` rows starting with the
		 * given `sourceRow` under parent `sourceParent` to row
		 * `destinationChild` under parent `destinationParent`.
		 *
		 * Returns true if the rows were successfully moved; otherwise returns
		 * false.
		 *
		 * If you implement your own model, you can reimplement this function
		 * if you want to support moving. Alternatively, you can provide your
		 * own API for altering the data.
		 *
		 * Note: The base class implementation does nothing and returns false.
		 */
		return false;
	}

	multiData(index: ModelIndex, roleDataSpan: ModelRoleDataSpan): void {
		/**
		 * Fills the roleDataSpan with the requested data for the given index.
		 *
		 * The default implementation will call data() for each role in the
		 * span. A subclass can reimplement this function to provide data to
		 * views more efficiently.
		 *
		 * Note that views may call multiData() with spans that have been
		 * used in previous calls, and therefore may already contain some
		 * data. Therefore, it is imperative that if the model cannot return
		 * the data for a given role, then it must clear the data in the
		 * corresponding ModelRoleData object. This can be done by calling
		 * ModelRoleData::clearData(), or similarly by setting a default
		 * constructed Variant, and so on. Failure to clear the data will
		 * result in the view believing that the "old" data is meant to be
		 * used for the corresponding role.
		 *
		 * Finally, in order to avoid code duplication, a subclass may also
		 * decide to reimplement data() in terms of multiData(), by supplying
		 * a span of just one element.
		 *
		 * Note: Models are not allowed to modify the roles in the span, or
		 * to rearrange the span elements. Doing so results in undefined
		 * behavior.
		 *
		 * Note: It is illegal to pass an invalid model index to this
		 * function.
		 */
		assert(this.checkIndex(index, CheckIndexOption.IndexIsValid));
		for (const obj of roleDataSpan) {
			obj.setData(this.data(index, obj.role()));
		}
	}

	parentIndex(child: ModelIndex): ModelIndex {
		return new ModelIndex();
	}

	persistentIndexList(): list<ModelIndex> {
		const d = this.d;
		const rv = new list<ModelIndex>();
		for (const data of iterPersistentModelIndexData(d.persistent.indexes)) {
			rv.append(data.index);
		}
		return rv;
	}

	removeColumn(column: number, parent: ModelIndex = new ModelIndex()): boolean {
		return this.removeColumns(column, 1, parent);
	}

	removeColumns(column: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		/**
		 * On models that support this, removes `count` columns starting with
		 * the given `column` under parent `parent` from the model.
		 *
		 * Returns true if the columns were successfully removed; otherwise
		 * returns false.
		 *
		 * If you implement your own model, you can reimplement this function
		 * if you want to support removing. Alternatively, you can provide
		 * your own API for altering the data.
		 *
		 * Note: The base class implementation does nothing and returns false.
		 */
		return false;
	}

	removeRow(row: number, parent: ModelIndex = new ModelIndex()): boolean {
		return this.removeRows(row, 1, parent);
	}

	removeRows(row: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		/**
		 * On models that support this, removes `count` rows starting with the
		 * given `row` under parent `parent` from the model.
		 *
		 * Returns true if the rows were successfully removed; otherwise
		 * returns false.
		 *
		 * If you implement your own model, you can reimplement this function
		 * if you want to support removing. Alternatively, you can provide
		 * your own API for altering the data.
		 *
		 * Note: The base class implementation does nothing and returns false.
		 */
		return false;
	}

	@SLOT
	resetInternalData(): void {
	}

	@SLOT
	revert(): void {
		/**
		 * Lets the model know that it should discard cached information. This
		 * function is typically used for row editing.
		 */
	}

	roleNames(): Map<number, string> {
		return this.d.roleNames;
	}

	rowCount(parent: ModelIndex | PersistentModelIndex = new ModelIndex()): number {
		return 0;
	}

	@SIGNAL
	rowsAboutToBeInserted(parent: ModelIndex, first: number, last: number): void {
	}

	@SIGNAL
	rowsAboutToBeMoved(sourceParent: ModelIndex, sourceStart: number, sourceEnd: number, destinationParent: ModelIndex, destinationRow: number): void {
	}

	@SIGNAL
	rowsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	@SIGNAL
	rowsInserted(parent: ModelIndex, first: number, last: number): void {
	}

	@SIGNAL
	rowsMoved(parent: ModelIndex, start: number, end: number, destination: ModelIndex, row: number): void {
	}

	@SIGNAL
	rowsRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	setData(index: ModelIndex, value: Variant, role: number = ItemDataRole.EditRole): boolean {
		/**
		 * The base class implementation returns false. This function and
		 * data() must be reimplemented for editable models.
		 */
		return false;
	}

	setHeaderData(section: number, orientation: Orientation, value: Variant, role: number = ItemDataRole.EditRole): boolean {
		/**
		 * Sets the data for the given `role` and `section` in the header with
		 * the specified `orientation` to the `value` supplied.
		 *
		 * Returns true if the header's data was updated; otherwise returns
		 * false.
		 *
		 * When reimplementing this function, the headerDataChanged() signal
		 * must be emitted explicitly.
		 */
		return false;
	}

	setItemData(index: ModelIndex, roles: Map<number, Variant>): boolean {
		/**
		 * Sets the role data for the item at index to the associated value in roles, for every ItemDataRole.
		 *
		 * Returns true if successful; otherwise returns false.
		 *
		 * Roles that are not in roles will not be modified.
		 */
		for (const [role, variant] of roles) {
			if (!this.setData(index, variant, role)) {
				return false;
			}
		}
		return true;
	}

	sibling(row: number, column: number, index: ModelIndex): ModelIndex {
		return ((row === index.row()) && (column === index.column())) ?
			index :
			this.index(row, column, this.parentIndex(index));
	}

	sort(column: number, order: SortOrder = SortOrder.AscendingOrder): void {
		/*
		* Sorts the model by `column` in the given `order`.
		*
		* Note: The base class implementation does nothing.
		*/
	}

	@SLOT
	submit(): boolean {
		/**
		 * Lets the model know that it should submit cached information to
		 * permanent storage. This function is typically used for row editing.
		 *
		 * Returns true if there is no error; otherwise returns false.
		 */
		return true;
	}
}

@OBJ
class EmptyItemModel extends AbstractItemModel {
	constructor() {
		super(undefined);
	}

	columnCount(parent: ModelIndex = new ModelIndex()): number {
		return 0;
	}

	data(index: ModelIndex, role: number = ItemDataRole.DisplayRole): Variant {
		return new Variant();
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		return false;
	}

	index(row: number, column: number, parent: ModelIndex = new ModelIndex()): ModelIndex {
		return new ModelIndex();
	}

	parentIndex(child: ModelIndex): ModelIndex {
		return new ModelIndex();
	}

	rowCount(parent: ModelIndex = new ModelIndex()): number {
		return 0;
	}
}

const staticEmptyModel = new EmptyItemModel();

@OBJ
export class AbstractListModel extends AbstractItemModel {
	columnCount(parent: ModelIndex = new ModelIndex()): number {
		return parent.isValid() ? 0 : 1;
	}

	flags(index: ModelIndex): ItemFlag {
		let rv = super.flags(index);
		if (index.isValid()) {
			rv |= ItemFlag.ItemNeverHasChildren;
		}
		return rv;
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		return parent.isValid() ?
			false :
			(this.rowCount() > 0);
	}

	index(row: number, column: number, parent: ModelIndex = new ModelIndex()): ModelIndex {
		return this.hasIndex(row, column, parent) ?
			this.createIndex(row, column) :
			new ModelIndex();
	}

	parentIndex(child: ModelIndex): ModelIndex {
		return new ModelIndex();
	}

	sibling(row: number, column: number, index: ModelIndex): ModelIndex {
		return this.index(row, column);
	}
}

@OBJ
export class AbstractTableModel extends AbstractItemModel {
	flags(index: ModelIndex): ItemFlag {
		let rv = super.flags(index);
		if (index.isValid()) {
			rv |= ItemFlag.ItemNeverHasChildren;
		}
		return rv;
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		if (!parent.isValid()) {
			return (this.rowCount(parent) > 0) && (this.columnCount(parent) > 0);
		}
		return false;
	}

	index(row: number, column: number, parent: ModelIndex = new ModelIndex()): ModelIndex {
		return this.hasIndex(row, column, parent) ?
			this.createIndex(row, column) :
			new ModelIndex();
	}

	parentIndex(child: ModelIndex): ModelIndex {
		return new ModelIndex();
	}

	sibling(row: number, column: number, index: ModelIndex): ModelIndex {
		return this.index(row, column);
	}
}

function *iterPersistentModelIndexData(indices: defaultdict<ModelIndex, Array<PersistentModelIndexData>>): IterableIterator<PersistentModelIndexData> {
	for (const dataList of indices.values()) {
		yield *dataList;
	}
}
