import {MDCTextField} from '@material/textfield';

import {OBJ, PROP, SIGNAL, SLOT} from '../../obj';
import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {CSS_CLASS_FULL_WIDTH, Key} from '../../constants';
import {Variant} from '../../variant';
import {TextInputIcon, TextInputIconOpts, TextInputIconPosition} from './icon';
import {Evt, KeyEvt} from '../../evt';
import {Completer} from '../completer';

export class TextInputPrivate extends ElObjPrivate {
	completer: Completer | null;
	ctrl: MDCTextField | null;
	icons: Map<TextInputIconPosition, TextInputIcon>;
	input: ElObj | null;
	label: ElObj | null;
	lineRipple: ElObj | null;
	notchedOutlined: ElObj | null;

	constructor() {
		super();
		this.completer = null;
		this.ctrl = null;
		this.icons = new Map();
		this.input = null;
		this.label = null;
		this.lineRipple = null;
		this.notchedOutlined = null;
	}

	init(opts: Partial<TextInputOpts>): void {
		super.init(opts);
		const q = this.q;
		q.setClass(
			Boolean(opts.noLabel), 'mdc-text-field--no-label',
		);
		if (!opts.textarea) {
			if (opts.underlinedLessDense) {
				q.addClass(
					'lb-text-input--underlined-less-dense',
				);
			} else if (opts.underlinedLessDenseNoPaddingWhiteBackground) {
				q.addClass(
					'lb-text-input--underlined-less-dense-no-padding-white-background',
				);
			} else if (opts.underlinedLessDenseNoPadding) {
				q.addClass(
					'lb-text-input--underlined-less-dense-no-padding',
				);
			} else if (opts.underlinedLessDenseWhiteBackground) {
				q.addClass(
					'lb-text-input--underlined-less-dense-white-background',
				);
			}
		}
		if (opts.outlined) {
			q.addClass('mdc-text-field--outlined');
			this.notchedOutlined = new ElObj({
				classNames: 'mdc-notched-outline',
				parent: q,
				tagName: 'div',
			});
			this.notchedOutlined.show();
			let obj = new ElObj({
				classNames: 'mdc-notched-outline__leading',
				parent: this.notchedOutlined,
				tagName: 'span',
			});
			obj.show();
			const notch = new ElObj({
				classNames: 'mdc-notched-outline__notch',
				parent: this.notchedOutlined,
				tagName: 'div',
			});
			notch.show();
			if (!opts.noLabel) {
				this.label = new ElObj({
					classNames: 'mdc-floating-label',
					parent: notch,
					tagName: 'label',
				});
				this.label.show();
			}
			obj = new ElObj({
				classNames: 'mdc-notched-outline__trailing',
				parent: this.notchedOutlined,
				tagName: 'div',
			});
			obj.show();
			if (opts.textarea) {
				q.addClass('mdc-text-field--textarea');
				let textAreaParent: ElObj = q;
				if (opts.resizable) {
					textAreaParent = new ElObj({
						classNames: 'mdc-text-field__resizer',
						parent: q,
						tagName: 'div',
					});
					textAreaParent.show();
				}
				this.input = new ElObj({
					classNames: 'mdc-text-field__input',
					parent: textAreaParent,
					tagName: 'textarea',
				});
				this.input.show();
				if (opts.columns) {
					this.input.setAttribute(
						'cols', String(opts.columns),
					);
				}
				if (opts.rows) {
					this.input.setAttribute(
						'rows', String(opts.rows),
					);
				}
			} else {
				this.input = new ElObj({
					attributes: [['type', 'text']],
					classNames: 'mdc-text-field__input',
					parent: q,
					tagName: 'input',
				});
				this.input.show();
			}
		} else {
			q.addClass('mdc-text-field--filled');
			let obj = new ElObj({
				classNames: 'mdc-text-field__ripple',
				parent: q,
				tagName: 'div',
			});
			obj.show();
			if (!opts.noLabel) {
				this.label = new ElObj({
					classNames: 'mdc-floating-label',
					parent: q,
					tagName: 'label',
				});
				this.label.show();
			}
			if (opts.textarea) {
				q.addClass('mdc-text-field--textarea');
				let textAreaParent: ElObj = q;
				if (opts.resizable) {
					textAreaParent = new ElObj({
						classNames: 'mdc-text-field__resizer',
						parent: q,
						tagName: 'div',
					});
					textAreaParent.show();
				}
				this.input = new ElObj({
					classNames: 'mdc-text-field__input',
					parent: textAreaParent,
					tagName: 'textarea',
				});
				this.input.show();
				if (opts.columns) {
					this.input.setAttribute('cols', String(opts.columns));
				}
				if (opts.rows) {
					this.input.setAttribute('rows', String(opts.rows));
				}
			} else {
				this.input = new ElObj({
					attributes: [['type', 'text']],
					classNames: 'mdc-text-field__input',
					parent: q,
					tagName: 'input',
				});
				this.input.show();
			}
			this.lineRipple = new ElObj({
				classNames: 'mdc-line-ripple',
				parent: q,
				tagName: 'div',
			});
			this.lineRipple.show();
		}
		if (opts.type) {
			if (this.input) {
				this.input.setAttribute('type', opts.type);
				this.input.show();
			}
		}
		if (opts.name) {
			q.setName(opts.name);
		}
		if (opts.inputId) {
			q.setInputId(opts.inputId);
		}
		if (opts.labelText) {
			q.setLabelText(opts.labelText);
		}
		if (opts.required) {
			q.setRequired(opts.required);
		}
		if (opts.fullWidth) {
			q.setFullWidth(opts.fullWidth);
		}
		if (opts.leadingIcon) {
			q.setLeadingIcon(opts.leadingIcon);
		}
		if (opts.trailingIcon) {
			q.setTrailingIcon(opts.trailingIcon);
		}
		if (opts.placeholder) {
			q.setPlaceHolder(opts.placeholder);
		}
		q.addEventListener('input');
		q.addEventListener('keydown');
		q.addEventListener('keyup');
		this.ctrl = new MDCTextField(q.element());
		this.ctrl.layout();
	}

	get q(): TextInput {
		return <TextInput>super.q;
	}
}

export interface TextInputOpts extends ElObjOpts {
	columns: number | string | null;
	dd: TextInputPrivate;
	fullWidth: boolean;
	inputId: string;
	labelText: string;
	leadingIcon: Partial<TextInputIconOpts> | string;
	name: string;
	noLabel: boolean;
	outlined: boolean;
	placeholder: string;
	required: boolean;
	resizable: boolean;
	rows: number | string | null;
	textarea: boolean;
	trailingIcon: Partial<TextInputIconOpts> | string;
	type: string;
	underlinedLessDense: boolean;
	underlinedLessDenseNoPadding: boolean;
	underlinedLessDenseNoPaddingWhiteBackground: boolean;
	underlinedLessDenseWhiteBackground: boolean;
}

@OBJ
export class TextInput extends ElObj {
	constructor(opts: Partial<TextInputOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-text-field',
			'lb-text-input',
		);
		opts.dd = opts.dd || new TextInputPrivate();
		super(opts);
	}

	blur(): void {
		const d = this.d;
		if (d.input) {
			d.input.blur();
		}
	}

	protected changeEvent(event: Evt): void {
		const d = this.d;
		if (event.type() === Evt.EnabledChange) {
			if (d.ctrl) {
				d.ctrl.disabled = !this.isEnabled();
			}
		}
		super.changeEvent(event);
	}

	clear(): void {
		this.setText('');
		// we don't get an domEvent when setting value to the empty
		// string. Don't know but I've also not spent any time looking
		// into it. Probably just Google being crappy at writing software
		// again.
		this.textChanged(this.text());
	}

	completer(): Completer | null {
		return this.d.completer;
	}

	get d(): TextInputPrivate {
		return <TextInputPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.destroy();
		}
		d.ctrl = null;
		super.destroy();
	}

	protected _domInputEvent(event: InputEvent): void {
		super._domInputEvent(event);
		this.textChanged(this.text());
	}

	protected keyPressEvent(event: KeyEvt): void {
		super.keyPressEvent(event);
		if (event.key() === Key.Enter) {
			this.returnPressed();
		}
	}

	focus(): void {
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.focus();
		}
	}

	hasFocus(): boolean {
		return super.hasFocus() || this.d.elem.contains(document.activeElement);
	}

	icon(position: TextInputIconPosition): TextInputIcon | null {
		return this.d.icons.get(position) || null;
	}

	@SIGNAL
	iconActivated(position: TextInputIconPosition): void {
	}

	private isFullWidth(): boolean {
		return this.hasClass(CSS_CLASS_FULL_WIDTH);
	}

	isOutlined(): boolean {
		return this.hasClass('mdc-text-field--outlined');
	}

	max(): string {
		const d = this.d;
		return d.ctrl ?
			d.ctrl.max :
			'';
	}

	min(): string {
		const d = this.d;
		return d.ctrl ?
			d.ctrl.min :
			'';
	}

	name(): string {
		const d = this.d;
		const el = d.input && <HTMLInputElement | null>d.input.element();
		return el ?
			el.name :
			'';
	}

	property(name: string): Variant {
		const d = this.d;
		switch (name) {
			case 'text':
				return new Variant(this.text());
			case 'maxLength':
				return d.ctrl ?
					new Variant(d.ctrl.maxLength) :
					new Variant();
			case 'leadingIconName': {
				const icon = this.icon(TextInputIconPosition.Leading);
				return new Variant(icon ?
					icon.icon() :
					'');
			}
			case 'trailingIconName': {
				const icon = this.icon(TextInputIconPosition.Trailing);
				return new Variant(icon ?
					icon.icon() :
					'');
			}
			default:
				return super.property(name);
		}
	}

	@SIGNAL
	returnPressed(): void {
	}

	setCompleter(completer: Completer | null): void {
		const d = this.d;
		if (completer === d.completer) {
			return;
		}
		if (d.completer) {
			d.completer.hide();
			d.completer.setParent(null);
			d.completer.destroy();
			d.completer = null;
		}
		d.completer = completer;
		if (d.completer) {
			d.completer.setParent(this);
		}
	}

	setFullWidth(fullWidth: boolean): void {
		if (fullWidth === this.isFullWidth()) {
			return;
		}
		this.setClass(fullWidth, CSS_CLASS_FULL_WIDTH);
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.layout();
		}
	}

	private setIcon(position: TextInputIconPosition, opts: Partial<TextInputIconOpts> | string): void {
		const o: Partial<TextInputIconOpts> = (typeof opts === 'string') ?
			{icon: opts} :
			opts;
		const d = this.d;
		const existing = d.icons.get(position);
		if (existing) {
			if (o.icon !== undefined) {
				existing.setIcon(o.icon, o.outlined);
			}
			if (o.interactive !== undefined) {
				existing.setInteractive(o.interactive);
			}
			if (o.title !== undefined) {
				existing.setTitle(o.title);
			}
			return;
		}
		o.position = position;
		const obj = new TextInputIcon(o);
		let ok: boolean;
		if (position === TextInputIconPosition.Leading) {
			if (this.isOutlined()) {
				if (d.notchedOutlined) {
					const idx = this.children().indexOf(
						d.notchedOutlined,
					);
					obj.setParent(
						this,
						(idx >= 0) ?
							(idx + 1) :
							idx,
					);
					ok = true;
				} else if (d.input) {
					const par = d.input.parentEl();
					if (par) {
						const idx = par.children().indexOf(d.input);
						obj.setParent(par, idx);
						ok = true;
					} else {
						ok = false;
					}
				} else {
					ok = false;
				}
			} else {
				if (d.label) {
					const par = d.label.parentEl();
					if (par) {
						const idx = par.children().indexOf(d.label);
						obj.setParent(
							par,
							(idx >= 0) ?
								idx + 1 :
								idx,
						);
						ok = true;
					} else {
						ok = false;
					}
				} else if (d.input) {
					const par = d.input.parentEl();
					if (par) {
						const idx = par.children().indexOf(d.input);
						obj.setParent(par, idx);
						ok = true;
					} else {
						ok = false;
					}
				} else {
					ok = false;
				}
			}
		} else {
			if (this.isOutlined()) {
				obj.setParent(this);
				ok = true;
			} else {
				if (d.input) {
					const par = d.input.parentEl();
					if (par) {
						const idx = par.children().indexOf(d.input);
						obj.setParent(
							par,
							(idx >= 0) ?
								idx + 1 :
								idx,
						);
						ok = true;
					} else {
						ok = false;
					}
				} else if (d.lineRipple) {
					obj.setParent(
						this,
						this.children().indexOf(d.lineRipple),
					);
					ok = true;
				} else {
					ok = false;
				}
			}
		}
		if (ok) {
			obj.setTextInput(this);
			obj.show();
		} else {
		}
		d.icons.set(position, obj);
	}

	setInputId(id: string): void {
		const d = this.d;
		if (d.input) {
			d.input.setAttribute('id', id);
		}
		if (d.label) {
			d.label.setAttribute('for', id);
		}
	}

	setLabelText(text: string): void {
		const d = this.d;
		if (d.label) {
			d.label.setText(text);
		}
	}

	setLeadingIcon(opts: Partial<TextInputIconOpts> | string): void {
		this.setIcon(TextInputIconPosition.Leading, opts);
	}

	setName(name: string): void {
		const d = this.d;
		if (d.input) {
			d.input.setAttribute('name', name);
		}
	}

	setPlaceHolder(placeHolder?: string): void {
		const d = this.d;
		if (d.input) {
			if (placeHolder) {
				d.input.setAttribute('placeholder', placeHolder);
			} else {
				d.input.removeAttribute('placeholder');
			}
		}
	}

	setProperty(name: string, value: Variant): boolean {
		switch (name) {
			case 'text':
				this.setText(value.toString());
				return true;
			case 'label':
				this.setLabelText(value.toString());
				return true;
			case 'disabled':
				this.setDisabled(value.toBoolean());
				return true;
			case 'maxLength': {
				const d = this.d;
				if (d.ctrl) {
					d.ctrl.maxLength = value.toNumber();
					return true;
				}
				return false;
			}
			case 'leadingIconName':
				this.setLeadingIcon(value.toString());
				return true;
			case 'trailingIconName':
				this.setTrailingIcon(value.toString());
				return true;
			default:
				return super.setProperty(name, value);
		}
	}

	setRequired(required: boolean): void {
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.required = required;
		}
	}

	@SLOT
	setText(text: string): void {
		if (text === this.text()) {
			return;
		}
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.value = text;
		}
	}

	setTrailingIcon(opts: Partial<TextInputIconOpts> | string): void {
		this.setIcon(TextInputIconPosition.Trailing, opts);
	}

	@PROP({WRITE: 'setText', USER: true, NOTIFY: 'textChanged'})
	text(): string {
		const d = this.d;
		return d.ctrl ?
			d.ctrl.value :
			'';
	}

	@SIGNAL
	textChanged(value: string): void {
	}

	trailingIcon(): TextInputIcon | null {
		return this.icon(TextInputIconPosition.Trailing);
	}
}
