import mapboxgl from 'mapbox-gl';

import {IMapboxDrawContext} from '../mapboxdraw';
import {Point as PointFeature} from '../features/point';
import {LineString} from '../features/linestring';
import {getLogger} from '../../../../logging';
import {Polygon} from '../features/polygon';
import {MultiFeature} from '../features/multi';
import {Feature} from '../features/feature';
import {Point} from '../../../../tools';
import {MapDrawMode} from '../../../../constants';
import {
	featuresAtClick,
	featuresAtTouch,
} from '../featuresat';
import {
	Evt,
	KeyboardEvt,
	MapMouseFeatureEvt,
	MapMouseEvt,
	MouseEvt,
	MapTouchFeatureEvt,
	MapTouchEvt,
	EvtType,
} from '../events';

const logger = getLogger('draw.mode');

export abstract class Mode<State = any> {
	protected readonly ctx: IMapboxDrawContext;
	private transient: boolean;
	abstract readonly name: MapDrawMode;
	abstract state: State;

	constructor(ctx: IMapboxDrawContext) {
		this.ctx = ctx;
		this.transient = false;
	}

	addFeature(feature: Feature): void {
		this.ctx.store.add(feature);
	}

	clearSelectedCoordinates(): void {
		this.ctx.store.clearSelectedCoordinates();
	}

	clearSelectedFeatures(): void {
		this.ctx.store.clearSelected();
	}

	combineFeatures(): void {
	}

	deleteFeature(featureIds: Array<string>, opts?: InternalEventOpt): void {
		this.ctx.store.delete(featureIds, opts);
	}

	deselect(featureIds: Array<string>): void {
		this.ctx.store.deselect(featureIds);
	}

	doRender(featureId: string): void {
		this.ctx.store.featureChanged(featureId);
	}

	protected dragEvent(evt: MapMouseEvt | MapTouchEvt): void {
		evt.needsRender = false;
	}

	event(evt: Evt): boolean {
		switch (evt.type) {
			case EvtType.MouseDrag:
				this.mouseDragEvent(<MapMouseEvt>evt);
				break;
			case EvtType.TouchDrag:
				this.touchDragEvent(<MapTouchEvt>evt);
				break;
			case EvtType.MouseMove:
				this.mouseMoveEvent(<MapMouseFeatureEvt>evt);
				break;
			case EvtType.TouchUpdate:
				this.touchMoveEvent(<MapTouchEvt>evt);
				break;
			case EvtType.MouseButtonClick:
				this.mouseClickEvent(<MapMouseFeatureEvt>evt);
				break;
			case EvtType.MouseButtonPress:
				this.mousePressEvent(<MapMouseFeatureEvt>evt);
				break;
			case EvtType.MouseButtonRelease:
				this.mouseReleaseEvent(<MapMouseFeatureEvt>evt);
				break;
			case EvtType.KeyPress:
				this.keyPressEvent(<KeyboardEvt>evt);
				break;
			case EvtType.KeyRelease:
				this.keyReleaseEvent(<KeyboardEvt>evt);
				break;
			case EvtType.TouchBegin:
				this.touchBeginEvent(<MapTouchFeatureEvt>evt);
				break;
			case EvtType.TouchTap:
				this.touchTapEvent(<MapTouchFeatureEvt>evt);
				break;
			case EvtType.TouchEnd:
				this.touchEndEvent(<MapTouchFeatureEvt>evt);
				break;
			case EvtType.Leave:
				this.mouseOutEvent(<MouseEvt>evt);
				break;
			default:
				logger.warning('Got invalid event type %s', evt.type);
				return false;
		}
		// In an attempt to say as close as possible to the original
		// implementation, this is where the delegate would decide whether to
		// call the Store's render routine.
		//
		// If the event (Evt) has its "needsRender" flag flipped, the render
		// will proceed as usual; otherwise no render is carried out.
		//
		// Also, currently, the return value from this routine serves no
		// purpose.
		if (evt.needsRender) {
			this.ctx.store.render();
		}
		return evt.needsRender;
	}

	featuresAt(point: Point | null, bbox: [[number, number], [number, number]] | null, bufferType: 'click' | 'tap' = 'click'): Array<mapboxgl.MapboxGeoJSONFeature> {
		switch (bufferType) {
			case 'click':
				return featuresAtClick(
					point,
					bbox,
					this.ctx,
				);
			case 'tap':
				return featuresAtTouch(
					point,
					bbox,
					this.ctx,
				);
		}
		return [];
	}

	protected fireEvent(eventType: string, data?: {[key: string]: any}): void {
		this.ctx.map && this.ctx.map.fire(eventType, data);
	}

	getFeature(featureId: string): Feature | null {
		return this.ctx.store.get(featureId);
	}

	getSelected(): Array<Feature> {
		return this.ctx.store.getSelected();
	}

	getSelectedIds(): Array<string> {
		return this.ctx.store.getSelectedIds();
	}

	isInstance(type: string, feature: Feature): boolean {
		if (type === 'Point') {
			return feature.type === 'Point';
		}
		if (type === 'LineString') {
			return feature.type === 'LineString';
		}
		if (type === 'Polygon') {
			return feature.type === 'Polygon';
		}
		if (type === 'MultiFeature') {
			return feature instanceof MultiFeature;
		}
		logger.warning('Unknown feature class: %s', type);
		return false;
	}

	isSelected(featureId?: string): boolean {
		return this.ctx.store.isSelected(featureId);
	}

	isTransient(): boolean {
		return this.transient;
	}

	protected keyPressEvent(evt: KeyboardEvt): void {
		evt.needsRender = false;
	}

	protected keyReleaseEvent(evt: KeyboardEvt): void {
		evt.needsRender = false;
	}

	protected mouseClickEvent(evt: MapMouseFeatureEvt): void {
		evt.needsRender = false;
	}

	protected mouseDragEvent(evt: MapMouseEvt): void {
		this.dragEvent(evt);
	}

	protected mouseMoveEvent(evt: MapMouseFeatureEvt): void {
		evt.needsRender = false;
	}

	protected mouseOutEvent(evt: MouseEvt): void {
		evt.needsRender = false;
	}

	protected mousePressEvent(evt: MapMouseFeatureEvt): void {
		evt.needsRender = false;
	}

	protected mouseReleaseEvent(evt: MapMouseFeatureEvt): void {
		evt.needsRender = false;
	}

	newFeature(feature: GeoJsonFeature<Exclude<GeoJsonGeometry, GeoJsonGeometryCollection>>): Feature {
		switch (feature.geometry.type) {
			case 'Point':
				return new PointFeature(this.ctx, feature);
			case 'Polygon':
				return new Polygon(this.ctx, feature);
			case 'LineString':
				return new LineString(this.ctx, <GeoJsonFeature<GeoJsonLineString>>feature);
			default:
				return new MultiFeature(this.ctx, <GeoJsonFeature<GeoJsonMultiPoint> | GeoJsonFeature<GeoJsonMultiLineString> | GeoJsonFeature<GeoJsonMultiPolygon>>feature);
		}
	}

	render(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		this.toDisplayFeatures(feature, display);
	}

	select(featureIds: Array<string>): void {
		this.ctx.store.select(featureIds);
	}

	setMode(mode: MapDrawMode, cfg: Partial<DrawModeCfg> = {}): void {
		this.ctx.api.setMode(mode, cfg);
	}

	setSelected(featureIds: Array<string>): void {
		this.ctx.store.setSelected(featureIds);
	}

	setSelectedCoordinates(coords: Array<CoordObj>): void {
		this.ctx.store.setSelectedCoordinates(coords);
		coords.reduce((acc, coord) => {
			if (acc[coord.feature_id] === undefined) {
				acc[coord.feature_id] = true;
				const feat = this.ctx.store.get(coord.feature_id);
				feat && feat.changed();
			}
			return acc;
		}, <{[key: string]: boolean;}>{});
	}

	setTransient(transient: boolean): void {
		this.transient = transient;
	}

	setup(options?: any): void {
	}

	start(): void {
	}

	stop(): void {
		this.ctx.api.clearMapClasses();
	}

	toDisplayFeatures(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
	}

	protected touchBeginEvent(evt: MapTouchFeatureEvt): void {
		evt.needsRender = false;
	}

	protected touchDragEvent(evt: MapTouchEvt): void {
		this.dragEvent(evt);
	}

	protected touchEndEvent(evt: MapTouchFeatureEvt): void {
		evt.needsRender = false;
	}

	protected touchMoveEvent(evt: MapTouchEvt): void {
		evt.needsRender = false;
	}

	protected touchTapEvent(evt: MapTouchFeatureEvt): void {
		evt.needsRender = false;
	}

	trash(): void {
		this.ctx.store.render();
	}

	uncombineFeatures(): void {
	}
}
