import {MDCDrawer as POSdrawer} from '@material/drawer';

import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {OBJ, SIGNAL} from '../obj';
import {HideEvt, ShowEvt} from '../evt';
import {FancyPushButton} from './pushbutton';
import {bind} from '../util';
import {Icon} from './icon';

class BetterButStillPOSdrawer extends POSdrawer {
	initialSyncWithDOM(): void {
		this.listen('transitionend', this.howCanGoogleBeThisBad);
	}

	@bind
	howCanGoogleBeThisBad(event: TransitionEvent): void {
		this.foundation.handleTransitionEnd(event);
	}
}

class DrawerPrivate extends ElObjPrivate {
	cont: ElObj | null;
	ctrl: BetterButStillPOSdrawer | null;

	constructor() {
		super();
		this.cont = null;
		this.ctrl = null;
		this.temporarilyDoNotApplyTheHiddenCssClass = true;
	}

	init(opts: Partial<DrawerOpts>): void {
		super.init(opts);
		const q = this.q;
		this.cont = new ElObj({
			classNames: 'mdc-drawer__content',
			parent: q,
		});
		const head = new ElObj({
			classNames: [
				'mdc-drawer__header',
				'lb-drawer-header',
			],
			parent: this.cont,
		});
		const btn = new FancyPushButton({
			attributes: [
				['href', '/'],
			],
			icon: new Icon({name: 'navigate_before'}),
			parent: head,
			tagName: 'a',
			text: 'Dashboard',
		});
		btn.setToolTip('Back to dashboard');
		q.addEventListener('MDCDrawer:opened');
		q.addEventListener('MDCDrawer:closed');
		if (!this.ctrl) {
			this.ctrl = new BetterButStillPOSdrawer(q.element());
		}
	}

	get q(): Drawer {
		return <Drawer>super.q;
	}
}

interface DrawerOpts extends ElObjOpts {
	dd: DrawerPrivate;
}

@OBJ
export class Drawer extends ElObj {
	static tagName: TagName = 'aside';

	constructor(opts: Partial<DrawerOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-drawer',
			'mdc-drawer--dismissible',
			'lb-drawer',
		);
		opts.dd = opts.dd || new DrawerPrivate();
		super(opts);
	}

	@SIGNAL
	protected closed(): void {
	}

	get d(): DrawerPrivate {
		return <DrawerPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.destroy();
		}
		d.ctrl = null;
		d.cont = null;
		super.destroy();
	}

	element(): HTMLElement {
		return <HTMLElement>super.element();
	}

	protected parentElDelegate(): ElObj | null {
		return this.d.cont || super.parentElDelegate();
	}

	protected _domMdcEvent(event: Event): void {
		super._domMdcEvent(event);
		switch (event.type) {
			case 'MDCDrawer:opened':
				this.opened();
				break;
			case 'MDCDrawer:closed':
				this.closed();
				break;
		}
	}

	protected hideEvent(event: HideEvt): void {
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.open = false;
		}
	}

	@SIGNAL
	protected opened(): void {
	}

	protected showEvent(event: ShowEvt): void {
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.open = true;
		}
	}
}
