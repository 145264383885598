import {MDCComponent, MDCFoundation, SpecificEventListener} from '@material/base';
import {MDCCheckbox, MDCCheckboxFactory} from '@material/checkbox';
import {MDCDataTableRowSelectionChangedEventDetail, ProgressIndicatorStyles} from '@material/data-table';
import {MDCLinearProgress} from '@material/linear-progress';

import {closestMatchingElement} from '../../../util';
import {SortOrder} from '../../../constants';

interface SortActionEventData {
	columnId: string | null;
	columnIndex: number;
	sortOrder: SortOrder;
}

export interface SortActionEventDetail {
	columnId: string | null;
	columnIndex: number;
	sortOrder: SortOrder;
}

export type SortActionEvent = Event & {detail: SortActionEventDetail};

const attributes = {
	ARIA_SELECTED: 'aria-selected',
	ARIA_SORT: 'aria-sort',
};
const cssClasses = {
	CELL: 'mdc-data-table__cell',
	CELL_NUMERIC: 'mdc-data-table__cell--numeric',
	CONTENT: 'mdc-data-table__content',
	HEADER_CELL: 'mdc-data-table__header-cell',
	HEADER_CELL_LABEL: 'mdc-data-table__header-cell-label',
	HEADER_CELL_SORTED: 'mdc-data-table__header-cell--sorted',
	HEADER_CELL_SORTED_DESCENDING: 'mdc-data-table__header-cell--sorted-descending',
	HEADER_CELL_WITH_SORT: 'mdc-data-table__header-cell--with-sort',
	HEADER_CELL_WRAPPER: 'mdc-data-table__header-cell-wrapper',
	HEADER_ROW: 'mdc-data-table__header-row',
	HEADER_ROW_CHECKBOX: 'mdc-data-table__header-row-checkbox',
	IN_PROGRESS: 'mdc-data-table--in-progress',
	LINEAR_PROGRESS: 'mdc-data-table__linear-progress',
	PAGINATION_ROWS_PER_PAGE_LABEL: 'mdc-data-table__pagination-rows-per-page-label',
	PAGINATION_ROWS_PER_PAGE_SELECT: 'mdc-data-table__pagination-rows-per-page-select',
	PROGRESS_INDICATOR: 'mdc-data-table__progress-indicator',
	ROOT: 'mdc-data-table',
	ROW: 'mdc-data-table__row',
	ROW_CHECKBOX: 'mdc-data-table__row-checkbox',
	ROW_SELECTED: 'mdc-data-table__row--selected',
	SORT_ICON_BUTTON: 'mdc-data-table__sort-icon-button',
	SORT_STATUS_LABEL: 'mdc-data-table__sort-status-label',
	TABLE_CONTAINER: 'mdc-data-table__table-container',
};
const dataAttributes = {
	COLUMN_ID: 'data-column-id',
	ROW_ID: 'data-row-id',
};
const events = {
	ROW_SELECTION_CHANGED: 'MDCDataTable:rowSelectionChanged',
	SELECTED_ALL: 'MDCDataTable:selectedAll',
	UNSELECTED_ALL: 'MDCDataTable:unselectedAll',
	SORTED: 'MDCDataTable:sorted',
};
const messages = {
	SORTED_IN_DESCENDING: 'Sorted in descending order',
	SORTED_IN_ASCENDING: 'Sorted in ascending order',
};
const selectors = {
	CONTENT: `.${cssClasses.CONTENT}`,
	HEADER_CELL: `.${cssClasses.HEADER_CELL}`,
	HEADER_CELL_WITH_SORT: `.${cssClasses.HEADER_CELL_WITH_SORT}`,
	HEADER_ROW: `.${cssClasses.HEADER_ROW}`,
	HEADER_ROW_CHECKBOX: `.${cssClasses.HEADER_ROW_CHECKBOX}`,
	PROGRESS_INDICATOR: `.${cssClasses.PROGRESS_INDICATOR}`,
	ROW: `.${cssClasses.ROW}`,
	ROW_CHECKBOX: `.${cssClasses.ROW_CHECKBOX}`,
	ROW_SELECTED: `.${cssClasses.ROW_SELECTED}`,
	SORT_ICON_BUTTON: `.${cssClasses.SORT_ICON_BUTTON}`,
	SORT_STATUS_LABEL: `.${cssClasses.SORT_STATUS_LABEL}`,
};
const strings = {
	ARIA_SELECTED: attributes.ARIA_SELECTED,
	ARIA_SORT: attributes.ARIA_SORT,
	DATA_ROW_ID_ATTR: dataAttributes.ROW_ID,
	HEADER_ROW_CHECKBOX_SELECTOR: selectors.HEADER_ROW_CHECKBOX,
	ROW_CHECKBOX_SELECTOR: selectors.ROW_CHECKBOX,
	ROW_SELECTED_SELECTOR: selectors.ROW_SELECTED,
	ROW_SELECTOR: selectors.ROW,
};

export enum SortValue {
	ASCENDING = 'ascending',
	DESCENDING = 'descending',
	NONE = 'none',
	OTHER = 'other',
}

interface ISortInfo {
	sortOrder: SortOrder
	visibleIndex: number;
}

interface MDCDataTableAdapter {
	addClass(className: string): void;
	addClassAtRowIndex(rowIndex: number, cssClasses: string): void;
	getAttributeByHeaderCellIndex(columnIndex: number, attribute: string): string | null;
	getHeaderCellCount(): number;
	getHeaderCellElements(): Array<Element>;
	getHeaderCellSortOrderByIndex(index: number): SortOrder;
	getRowCount(): number;
	getRowElements(): Array<Element>;
	getRowIdAtIndex(rowIndex: number): string | null;
	getRowIndexByChildElement(el: Element): number;
	getSelectedRowCount(): number;
	getTableContainerHeight(): number;
	getTableHeaderHeight(): number;
	isCheckboxAtRowIndexChecked(rowIndex: number): boolean;
	isHeaderCellSorted(index: number): boolean;
	isHeaderRowCheckboxChecked(): boolean;
	isRowsSelectable(): boolean;
	notifyRowSelectionChanged(data: MDCDataTableRowSelectionChangedEventDetail): void;
	notifySelectedAll(): void;
	notifySortAction(data: SortActionEventDetail): void;
	notifyUnselectedAll(): void;
	registerHeaderRowCheckbox(): Promise<void> | void;
	registerRowCheckboxes(): Promise<void> | void;
	removeAttributeByHeaderCellIndex(index: number, name: string): void;
	removeClass(className: string): void;
	removeClassAtRowIndex(rowIndex: number, cssClasses: string): void;
	removeClassNameByHeaderCellIndex(columnIndex: number, className: string): void;
	setAttributeAtRowIndex(rowIndex: number, attr: string, value: string): void;
	setAttributeByHeaderCellIndex(columnIndex: number, attribute: string, value: string): void;
	setClassNameByHeaderCellIndex(columnIndex: number, className: string): void;
	setHeaderRowCheckboxChecked(checked: boolean): void;
	setHeaderRowCheckboxIndeterminate(indeterminate: boolean): void;
	setProgressIndicatorStyles(styles: ProgressIndicatorStyles): void;
	setRowCheckboxCheckedAtIndex(rowIndex: number, checked: boolean): void;
	setSortStatusLabelByHeaderCellIndex(columnIndex: number, sortOrder: SortOrder): void;
}

class MDCDataTableFoundation extends MDCFoundation<MDCDataTableAdapter> {
	static get defaultAdapter(): MDCDataTableAdapter {
		return {
			addClass: () => undefined,
			addClassAtRowIndex: () => undefined,
			getAttributeByHeaderCellIndex: () => '',
			getHeaderCellCount: () => 0,
			getHeaderCellElements: () => [],
			getHeaderCellSortOrderByIndex: idx => SortOrder.NoOrder,
			getRowCount: () => 0,
			getRowElements: () => [],
			getRowIdAtIndex: () => '',
			getRowIndexByChildElement: () => 0,
			getSelectedRowCount: () => 0,
			getTableContainerHeight: () => 0,
			getTableHeaderHeight: () => 0,
			isCheckboxAtRowIndexChecked: () => false,
			isHeaderCellSorted: idx => false,
			isHeaderRowCheckboxChecked: () => false,
			isRowsSelectable: () => false,
			notifyRowSelectionChanged: () => undefined,
			notifySelectedAll: () => undefined,
			notifySortAction: () => undefined,
			notifyUnselectedAll: () => undefined,
			registerHeaderRowCheckbox: () => undefined,
			registerRowCheckboxes: () => undefined,
			removeAttributeByHeaderCellIndex: (index: number, name: string) => undefined,
			removeClass: () => undefined,
			removeClassAtRowIndex: () => undefined,
			removeClassNameByHeaderCellIndex: () => undefined,
			setAttributeAtRowIndex: () => undefined,
			setAttributeByHeaderCellIndex: () => undefined,
			setClassNameByHeaderCellIndex: () => undefined,
			setHeaderRowCheckboxChecked: () => undefined,
			setHeaderRowCheckboxIndeterminate: () => undefined,
			setProgressIndicatorStyles: () => undefined,
			setRowCheckboxCheckedAtIndex: () => undefined,
			setSortStatusLabelByHeaderCellIndex: () => undefined,
		};
	}

	constructor(adapter?: Partial<MDCDataTableAdapter>) {
		super({...MDCDataTableFoundation.defaultAdapter, ...adapter});
	}

	currentSort(): ISortInfo {
		const rv: ISortInfo = {
			visibleIndex: -1,
			sortOrder: SortOrder.NoOrder,
		};
		for (let i = 0; i < this.adapter.getHeaderCellCount(); ++i) {
			if (this.adapter.isHeaderCellSorted(i)) {
				rv.visibleIndex = i;
				rv.sortOrder = this.adapter.getHeaderCellSortOrderByIndex(i);
				break;
			}
		}
		return rv;
	}

	getHeaderCells(): Array<Element> {
		return this.adapter.getHeaderCellElements();
	}

	getRowIds(): Array<string | null> {
		const rv = [];
		for (let i = 0; i < this.adapter.getRowCount(); ++i) {
			rv.push(this.adapter.getRowIdAtIndex(i));
		}
		return rv;
	}

	getRows(): Array<Element> {
		return this.adapter.getRowElements();
	}

	getSelectedRowIds(): Array<string | null> {
		const rv: Array<string | null> = [];
		for (let i = 0; i < this.adapter.getRowCount(); ++i) {
			if (this.adapter.isCheckboxAtRowIndexChecked(i)) {
				rv.push(this.adapter.getRowIdAtIndex(i));
			}
		}
		return rv;
	}

	handleHeaderRowCheckboxChange() {
		const isChecked = this.adapter.isHeaderRowCheckboxChecked();
		for (let i = 0; i < this.adapter.getRowCount(); ++i) {
			this.adapter.setRowCheckboxCheckedAtIndex(i, isChecked);
			this.selectRowAtIndex(i, isChecked);
		}
		if (isChecked) {
			this.adapter.notifySelectedAll();
		} else {
			this.adapter.notifyUnselectedAll();
		}
	}

	handleRowCheckboxChange(event: Event) {
		const idx = this.adapter.getRowIndexByChildElement(event.target as HTMLInputElement);
		if (idx >= 0) {
			const checked = this.adapter.isCheckboxAtRowIndexChecked(idx);
			this.selectRowAtIndex(idx, checked);
			this.setHeaderRowCheckboxState();
			this.adapter.notifyRowSelectionChanged({
				rowId: this.adapter.getRowIdAtIndex(idx),
				rowIndex: idx,
				selected: checked,
			});
		}
	}

	updateForSortOrder(index: number, order: SortOrder): void {
		switch (order) {
			case SortOrder.AscendingOrder:
				this.adapter.setClassNameByHeaderCellIndex(index, cssClasses.HEADER_CELL_SORTED);
				this.adapter.removeClassNameByHeaderCellIndex(index, cssClasses.HEADER_CELL_SORTED_DESCENDING);
				this.adapter.setAttributeByHeaderCellIndex(index, strings.ARIA_SORT, SortValue.ASCENDING);
				break;
			case SortOrder.DescendingOrder:
				this.adapter.setClassNameByHeaderCellIndex(index, cssClasses.HEADER_CELL_SORTED);
				this.adapter.setClassNameByHeaderCellIndex(index, cssClasses.HEADER_CELL_SORTED_DESCENDING);
				this.adapter.setAttributeByHeaderCellIndex(index, strings.ARIA_SORT, SortValue.DESCENDING);
				break;
			default:
				this.adapter.removeClassNameByHeaderCellIndex(index, cssClasses.HEADER_CELL_SORTED);
				this.adapter.removeAttributeByHeaderCellIndex(index, strings.ARIA_SORT);
				this.adapter.removeClassNameByHeaderCellIndex(index, cssClasses.HEADER_CELL_SORTED_DESCENDING);
				break;
		}
		this.adapter.setSortStatusLabelByHeaderCellIndex(index, order);
	}

	handleSortAction(eventData: SortActionEventData, notify: boolean = true) {
		const {columnId, columnIndex: index, sortOrder} = eventData;
		for (let i = 0; i < this.adapter.getHeaderCellCount(); ++i) {
			if (i === index) {
				continue;
			}
			this.adapter.removeClassNameByHeaderCellIndex(i, cssClasses.HEADER_CELL_SORTED);
			this.adapter.removeClassNameByHeaderCellIndex(i, cssClasses.HEADER_CELL_SORTED_DESCENDING);
			this.adapter.setAttributeByHeaderCellIndex(i, strings.ARIA_SORT, SortValue.NONE);
			this.adapter.setSortStatusLabelByHeaderCellIndex(i, SortOrder.NoOrder);
		}
		this.updateForSortOrder(
			index,
			sortOrder);
		if (notify) {
			this.adapter.notifySortAction({
				columnId,
				columnIndex: index,
				sortOrder,
			});
		}
	}

	hideProgress() {
		this.adapter.removeClass(cssClasses.IN_PROGRESS);
	}

	layout() {
		if (this.adapter.isRowsSelectable()) {
			this.adapter.registerHeaderRowCheckbox();
			this.adapter.registerRowCheckboxes();
			this.setHeaderRowCheckboxState();
		}
	}

	async layoutAsync(): Promise<void> {
		if (this.adapter.isRowsSelectable()) {
			await this.adapter.registerHeaderRowCheckbox();
			await this.adapter.registerRowCheckboxes();
			this.setHeaderRowCheckboxState();
		}
	}

	private selectRowAtIndex(index: number, selected: boolean) {
		if (selected) {
			this.adapter.addClassAtRowIndex(index, cssClasses.ROW_SELECTED);
			this.adapter.setAttributeAtRowIndex(index, strings.ARIA_SELECTED, 'true');
		} else {
			this.adapter.removeClassAtRowIndex(index, cssClasses.ROW_SELECTED);
			this.adapter.setAttributeAtRowIndex(index, strings.ARIA_SELECTED, 'false');
		}
	}

	private setHeaderRowCheckboxState() {
		let checked: boolean;
		let indeterminate: boolean;
		const count = this.adapter.getSelectedRowCount();
		if (count === 0) {
			checked = false;
			indeterminate = false;
		} else if (
			count === this.adapter.getRowCount()) {
			checked = true;
			indeterminate = false;
		} else {
			checked = false;
			indeterminate = true;
		}
		this.adapter.setHeaderRowCheckboxChecked(checked);
		this.adapter.setHeaderRowCheckboxIndeterminate(indeterminate);
	}

	setSelectedRowIds(ids: Array<string>) {
		for (let i = 0; i < this.adapter.getRowCount(); ++i) {
			const id = this.adapter.getRowIdAtIndex(i);
			const selected = id ?
				(ids.indexOf(id) >= 0) :
				false;
			this.adapter.setRowCheckboxCheckedAtIndex(i, selected);
			this.selectRowAtIndex(i, selected);
		}
		this.setHeaderRowCheckboxState();
	}

	showProgress() {
		let tableHeaderHeight = this.adapter.getTableHeaderHeight();
		let height = this.adapter.getTableContainerHeight() - tableHeaderHeight;
		if (height < 1) {
			height = 4;
			tableHeaderHeight -= 4;
		}
		this.adapter.setProgressIndicatorStyles({
			height: `${height}px`,
			top: `${tableHeaderHeight}px`,
		});
		this.adapter.addClass(cssClasses.IN_PROGRESS);
	}

}

export class MDCDataTable extends MDCComponent<MDCDataTableFoundation> {
	static attachTo(root: Element): MDCDataTable {
		return new MDCDataTable(root);
	}

	private checkboxFactory: MDCCheckboxFactory | null = null;
	private handleHeaderRowCheckboxChange: SpecificEventListener<'change'> | null = null;
	private handleRowCheckboxChange: SpecificEventListener<'change'> | null = null;
	private headerRowCheckbox: MDCCheckbox | null = null;
	private linearProgress: MDCLinearProgress | null = null;
	private rowCheckboxList: Array<MDCCheckbox> = [];

	private get content(): HTMLElement | null {
		return this.root.querySelector(`.${cssClasses.CONTENT}`);
	}

	destroy() {
		const headerRow = this.headerRow;
		if (this.handleHeaderRowCheckboxChange && headerRow) {
			headerRow.removeEventListener('change', this.handleHeaderRowCheckboxChange);
		}
		if (this.handleRowCheckboxChange) {
			const el = this.content;
			if (el) {
				el.removeEventListener('change', this.handleRowCheckboxChange);
			}
		}
		if (this.headerRowCheckbox) {
			this.headerRowCheckbox.destroy();
		}
		if (this.rowCheckboxList) {
			for (const checkbox of this.rowCheckboxList) {
				checkbox.destroy();
			}
		}
	}

	getCurrentSortInfo(): ISortInfo {
		return this.foundation.currentSort();
	}

	getDefaultFoundation() {
		const adapter: MDCDataTableAdapter = {
			addClass: className => this.root.classList.add(className),
			removeClass: className => this.root.classList.remove(className),
			getHeaderCellElements: () => this.getHeaderCells(),
			getHeaderCellCount: () => this.getHeaderCells().length,
			getHeaderCellSortOrderByIndex: (index: number): SortOrder => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					if (cells[index].classList.contains(cssClasses.HEADER_CELL_SORTED)) {
						if (cells[index].classList.contains(cssClasses.HEADER_CELL_SORTED_DESCENDING)) {
							return SortOrder.DescendingOrder;
						}
						return SortOrder.AscendingOrder;
					}
				}
				return SortOrder.NoOrder;
			},
			getAttributeByHeaderCellIndex: (index, name) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					return cells[index].getAttribute(name);
				}
				return null;
			},
			setAttributeByHeaderCellIndex: (index, name, value) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					cells[index].setAttribute(name, value);
				}
			},
			removeAttributeByHeaderCellIndex: (index, name) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					cells[index].removeAttribute(name);
				}
			},
			setClassNameByHeaderCellIndex: (index, className) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					cells[index].classList.add(className);
				}
			},
			removeClassNameByHeaderCellIndex: (index, className) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					cells[index].classList.remove(className);
				}
			},
			notifySortAction: data => this.emit(events.SORTED, data, true),
			getTableContainerHeight: () => {
				const el = this.root.querySelector<HTMLElement>(`.${cssClasses.TABLE_CONTAINER}`);
				return el ?
					el.getBoundingClientRect().height :
					0;
			},
			getTableHeaderHeight: () => {
				const el = this.root.querySelector<HTMLElement>(selectors.HEADER_ROW);
				return el ?
					el.getBoundingClientRect().height :
					0;
			},
			setProgressIndicatorStyles: styles => {
				const el = this.root.querySelector<HTMLElement>(selectors.PROGRESS_INDICATOR);
				if (el) {
					el.style.setProperty('height', styles.height);
					el.style.setProperty('top', styles.top);
				}
			},
			addClassAtRowIndex: (index: number, className: string) => {
				const rows = this.getRows();
				if ((index >= 0) && (index < rows.length)) {
					rows[index].classList.add(className);
				}
			},
			getRowCount: () => this.getRows().length,
			getRowElements: () => Array.from(this.root.querySelectorAll(selectors.ROW)),
			getRowIdAtIndex: (index: number) => {
				const rows = this.getRows();
				if ((index >= 0) && (index < rows.length)) {
					return rows[index].getAttribute(dataAttributes.ROW_ID);
				}
				return null;
			},
			getRowIndexByChildElement: (child: Element) => {
				const el = closestMatchingElement(child, selectors.ROW);
				return el ?
					this.getRows().indexOf(el) :
					-1;
			},
			getSelectedRowCount: () => this.root.querySelectorAll(selectors.ROW_SELECTED).length,
			isCheckboxAtRowIndexChecked: (index: number) => {
				if ((index >= 0) && (index < this.rowCheckboxList.length)) {
					return this.rowCheckboxList[index].checked;
				}
				return false;
			},
			isHeaderCellSorted: (index: number): boolean => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					return cells[index].classList.contains(cssClasses.HEADER_CELL_SORTED);
				}
				return false;
			},
			isHeaderRowCheckboxChecked: () =>
				this.headerRowCheckbox ?
					this.headerRowCheckbox.checked :
					false,
			isRowsSelectable: () => Boolean(this.root.querySelector(selectors.ROW_CHECKBOX) || this.root.querySelector(selectors.HEADER_ROW_CHECKBOX)),
			notifyRowSelectionChanged: (data: MDCDataTableRowSelectionChangedEventDetail) =>
				this.emit(
					events.ROW_SELECTION_CHANGED,
					{
						row: this.getRowByIndex(data.rowIndex),
						rowId: this.getRowIdByIndex(data.rowIndex),
						rowIndex: data.rowIndex,
						selected: data.selected,
					},
					true),
			notifySelectedAll: () => this.emit(events.SELECTED_ALL, {}, true),
			notifyUnselectedAll: () => this.emit(events.UNSELECTED_ALL, {}, true),
			registerHeaderRowCheckbox: () => {
				if (this.headerRowCheckbox) {
					this.headerRowCheckbox.destroy();
				}
				const el = this.root.querySelector(selectors.HEADER_ROW_CHECKBOX);
				this.headerRowCheckbox = this.checkboxFactory && el && this.checkboxFactory(el);
			},
			registerRowCheckboxes: () => {
				for (const el of this.rowCheckboxList) {
					el.destroy();
				}
				this.rowCheckboxList = [];
				if (this.checkboxFactory) {
					for (const row of this.getRows()) {
						const rowChk = row.querySelector(selectors.ROW_CHECKBOX);
						if (rowChk) {
							this.rowCheckboxList.push(this.checkboxFactory(rowChk));
						}
					}
				}
			},
			removeClassAtRowIndex: (index: number, className: string) => {
				const rows = this.getRows();
				if ((index >= 0) && (index < rows.length)) {
					rows[index].classList.remove(className);
				}
			},
			setAttributeAtRowIndex: (index: number, name: string, value: string) => {
				const rows = this.getRows();
				if ((index >= 0) && (index < rows.length)) {
					rows[index].setAttribute(name, value);
				}
			},
			setHeaderRowCheckboxChecked: (checked: boolean) => {
				if (this.headerRowCheckbox) {
					this.headerRowCheckbox.checked = checked;
				}
			},
			setHeaderRowCheckboxIndeterminate: (indeterminate: boolean) => {
				if (this.headerRowCheckbox) {
					this.headerRowCheckbox.indeterminate = indeterminate;
				}
			},
			setRowCheckboxCheckedAtIndex: (index: number, checked: boolean) => {
				if ((index >= 0) && (index < this.rowCheckboxList.length)) {
					this.rowCheckboxList[index].checked = checked;
				}
			},
			setSortStatusLabelByHeaderCellIndex: (index: number, sortOrder: SortOrder) => {
				const cells = this.getHeaderCells();
				if ((index >= 0) && (index < cells.length)) {
					const el = cells[index].querySelector<HTMLElement>(selectors.SORT_STATUS_LABEL);
					if (el) {
						el.textContent = this.getSortStatusMessageBySortValue(sortOrder);
					}
				}
			},
		};
		return new MDCDataTableFoundation(adapter);
	}

	getHeaderCells(): Array<Element> {
		return Array.from(this.root.querySelectorAll(selectors.HEADER_CELL));
	}

	private getLinearProgress(): MDCLinearProgress | null {
		if (!this.linearProgress) {
			const el = this.getLinearProgressElement();
			if (el) {
				this.linearProgress = new MDCLinearProgress(el);
			}
		}
		return this.linearProgress;
	}

	private getLinearProgressElement(): HTMLElement | null {
		return this.root.querySelector<HTMLElement>(`.${cssClasses.LINEAR_PROGRESS}`);
	}

	private getRowByIndex(index: number): Element {
		return this.getRows()[index];
	}

	private getRowIdByIndex(index: number): string | null {
		return this.getRowByIndex(index).getAttribute(dataAttributes.ROW_ID);
	}

	getRows(): Array<Element> {
		return this.foundation.getRows();
	}

	getSelectedRowIds(): Array<string | null> {
		return this.foundation.getSelectedRowIds();
	}

	private getSortStatusMessageBySortValue(sortOrder: SortOrder): string {
		switch (sortOrder) {
			case SortOrder.AscendingOrder:
				return messages.SORTED_IN_ASCENDING;
			case SortOrder.DescendingOrder:
				return messages.SORTED_IN_DESCENDING;
			default:
				return '';
		}
	}

	private get headerRow(): HTMLElement | null {
		return this.root.querySelector(`.${cssClasses.HEADER_ROW}`);
	}

	hideProgress() {
		this.foundation.hideProgress();
		const obj = this.getLinearProgress();
		if (obj) {
			obj.close();
		}
	}

	initialize(checkboxFactory: MDCCheckboxFactory = (el: Element) => new MDCCheckbox(el)) {
		this.checkboxFactory = checkboxFactory;
	}

	initialSyncWithDOM() {
		this.handleHeaderRowCheckboxChange = () => this.foundation.handleHeaderRowCheckboxChange();
		this.handleRowCheckboxChange = (event) => this.foundation.handleRowCheckboxChange(event);
		this.layout();
	}

	layout() {
		this.foundation.layout();
	}

	setSort(visibleIndex: number, sortOrder: SortOrder, columnId: string | null = null, notify: boolean = false): void {
		this.foundation.handleSortAction({
				columnId,
				columnIndex: visibleIndex,
				sortOrder,
			},
			notify);
	}

	setSelectedRowIds(rowIds: Array<string>) {
		this.foundation.setSelectedRowIds(rowIds);
	}

	showProgress() {
		const obj = this.getLinearProgress();
		if (obj) {
			obj.open();
		}
		this.foundation.showProgress();
	}
}
