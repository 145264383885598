import {AbstractItemView, AbstractItemViewOpts, AbstractItemViewPrivate} from './abstractitemview';
import {OBJ} from '../../obj';

export class TreeViewPrivate extends AbstractItemViewPrivate {
	get q(): TreeView {
		return <TreeView>super.q;
	}
}

export interface TreeViewOpts extends AbstractItemViewOpts {
	dd: TreeViewPrivate;
}

@OBJ
export class TreeView extends AbstractItemView {
	constructor(opts: Partial<TreeViewOpts> = {}) {
		opts.dd = opts.dd || new TreeViewPrivate();
		super(opts);
	}

	get d(): TreeViewPrivate {
		return <TreeViewPrivate>super.d;
	}
}
