import {OBJ, SIGNAL, SLOT} from '../obj';
import {ElObj} from '../elobj';
import {ActionEvt, Evt} from '../evt';
import {AbstractButton, AbstractButtonOpts, AbstractButtonPrivate} from './abstractbutton';
import {Icon} from './icon';
import {Action} from '../action';

export class ToolButtonPrivate extends AbstractButtonPrivate {
	defaultAction: Action | null;

	constructor() {
		super();
		this.defaultAction = null;
	}

	init(opts: Partial<ToolButtonOpts>): void {
		super.init(opts);
		const q = this.q;
		q.setAttribute('type', 'button');
		if (opts.icon) {
			q.setIcon(opts.icon);
		} else {
			this.icon.addClass(
				'mdc-icon-button__icon',
			);
			this.icon.setParent(q);
		}
		q.addEventListener('mousedown');
		q.addEventListener('mouseup');
		q.addEventListener('keydown');
		q.addEventListener('keyup');
	}

	get q(): ToolButton {
		return <ToolButton>super.q;
	}
}

export interface ToolButtonOpts extends AbstractButtonOpts {
	dd: ToolButtonPrivate;
	icon: Icon;
}

@OBJ
export class ToolButton extends AbstractButton {
	static tagName: TagName = 'button';

	constructor(opts: Partial<ToolButtonOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-icon-button',
			'lb-tool-button',
		);
		opts.dd = opts.dd || new ToolButtonPrivate();
		super(opts);
	}

	protected actionEvent(event: ActionEvt): void {
		const axn = event.action();
		switch (event.type()) {
			case ActionEvt.ActionChanged: {
				// Update button state
				this.setDefaultAction(axn);
				break;
			}
			case ActionEvt.ActionAdded: {
				ToolButton.connect(
					axn, 'triggered',
					this, '_actionTriggered',
				);
				break;
			}
			case ActionEvt.ActionRemoved: {
				const d = this.d;
				if (d.defaultAction === axn) {
					d.defaultAction = null;
				}
				axn.disconnect(this);
				break;
			}
		}
		super.actionEvent(event);
	}

	@SLOT
	protected _actionTriggered(): void {
		const obj = this.sender();
		if (obj && (obj instanceof Action)) {
			this.triggered(obj);
		}
	}

	checkStateSet(): void {
		const d = this.d;
		if (d.defaultAction && d.defaultAction.isCheckable()) {
			d.defaultAction.setChecked(this.isChecked());
		}
	}

	defaultAction(): Action | null {
		return this.d.defaultAction;
	}

	get d(): ToolButtonPrivate {
		return <ToolButtonPrivate>super.d;
	}

	protected enterEvent(event: Evt): void {
		super.enterEvent(event);
		this.pointerEntered();
	}

	protected leaveEvent(event: Evt): void {
		super.leaveEvent(event);
		this.pointerLeft();
	}

	nextCheckState(): void {
		const d = this.d;
		if (d.defaultAction) {
			d.defaultAction.trigger();
		} else {
			super.nextCheckState();
		}
	}

	@SIGNAL
	private pointerEntered(): void {
	}

	@SIGNAL
	private pointerLeft(): void {
	}

	setDefaultAction(action: Action | null): void {
		const d = this.d;
		d.defaultAction = action;
		if (!d.defaultAction) {
			return;
		}
		if (!this.actions().contains(d.defaultAction)) {
			this.addAction(d.defaultAction);
		}
		this.icon().setName(d.defaultAction.icon().name());
		this.setIcon(d.defaultAction.icon());
		// FIXME: Temp. impl. 'til you figure out what to do about tooltips and whatnot.
		const t = d.defaultAction.toolTip().trim();
		if (t.length > 0) {
			this.setAttribute('title', t);
		} else {
			this.removeAttribute('title');
		}
		this.setCheckable(d.defaultAction.isCheckable());
		this.setChecked(d.defaultAction.isChecked());
		this.setEnabled(d.defaultAction.isEnabled());
		this.setVisible(d.defaultAction.isVisible());
	}

	setIcon(icon: Icon): void {
		const d = this.d;
		if (icon.eq(d.icon)) {
			return;
		}
		d.icon.hide();
		d.icon.removeClass('mdc-icon-button__icon');
		super.setIcon(icon);
		d.icon.hide();
		d.icon.addClass('mdc-icon-button__icon');
		d.icon.setParent(this);
		d.icon.show();
	}

	@SIGNAL
	protected triggered(action: Action): void {
	}
}

