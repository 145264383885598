import {ElObj} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {Menu, MenuOpts, MenuPrivate} from '../../ui/menu';
import {FancyListItem} from '../../ui/list';
import {EnabledToggle} from '../../ui/enabletoggle';
import {ToolButton} from '../../ui/toolbutton';
import {ParcelTag} from '../../models';
import {getLogger} from '../../logging';

const logger = getLogger('views.data.parceltagmenu');

export class ParcelTagMenuPrivate extends MenuPrivate {
	listItemParent(obj: ElObj | null): FancyListItem | null {
		if (!obj) {
			return null;
		}
		let par = obj.parentEl();
		while (par) {
			if (par instanceof FancyListItem) {
				return par;
			}
			par = par.parentEl();
		}
		return null;
	}

	get q(): ParcelTagMenu {
		return <ParcelTagMenu>super.q;
	}
}

export interface ParcelTagMenuOpts extends MenuOpts {
	dd: ParcelTagMenuPrivate;
}

@OBJ
export class ParcelTagMenu extends Menu {
	constructor(opts: Partial<ParcelTagMenuOpts> = {}) {
		opts.dd = opts.dd || new ParcelTagMenuPrivate();
		super(opts);
	}

	addTag(parcelTag: ParcelTag): void {
		const item = new ParcelTagMenuItem();
		Obj.connect(
			parcelTag, 'destroyed',
			item, 'parcelTagDestroyed',
		);
		const leading = new EnabledToggle();
		leading.setChecked(
			parcelTag.isEnabled(),
		);
		leading.setToolTip('Disable this tag');
		item.setLeadingObj(leading);
		Obj.connect(
			leading, 'toggled',
			parcelTag, 'setEnabled',
		);
		Obj.connect(
			parcelTag, 'enabledChanged',
			leading, 'setChecked',
		);
		let label: string;
		const relatedTag = parcelTag.tag();
		if (relatedTag) {
			label = relatedTag.toString();
		} else {
			logger.warning(
				'addTag: Related Tag was not found for (%s, %s, %s)',
				parcelTag.tagK(),
				parcelTag.tagV(),
				parcelTag.parcelId(),
			);
			label = `${parcelTag.tagK()}=${parcelTag.tagV()}`;
		}
		item.setText(label);
		const trailing = new ToolButton();
		trailing.icon().setName(
			'remove_circle_outline',
		);
		trailing.setToolTip('Remove this tag');
		item.setTrailingObj(trailing);
		Obj.connect(
			trailing, 'clicked',
			this, '_removeClicked',
		);
		this.addItem(item);
	}

	get d(): ParcelTagMenuPrivate {
		return <ParcelTagMenuPrivate>super.d;
	}

	@SIGNAL
	private enabledChanged(index: number, enabled: boolean): void {
	}

	@SLOT
	private enabledToggled(checked: boolean): void {
		const d = this.d;
		const obj = this.sender();
		const li = d.listItemParent(
			(obj && obj.isElType()) ?
				obj :
				null,
		);
		if (!li) {
			return;
		}
		const idx = li.index();
		if (idx >= 0) {
			this.enabledChanged(idx, checked);
			return;
		}
	}

	@SIGNAL
	private removeClicked(index: number): void {
	}

	@SLOT
	private _removeClicked(): void {
		const obj = this.sender();
		const d = this.d;
		const li = d.listItemParent(
			(obj && obj.isElType()) ?
				obj :
				null,
		);
		if (!li) {
			return;
		}
		const idx = li.index();
		if (idx >= 0) {
			this.removeClicked(idx);
		}
	}

	removeTag(index: number): void {
		this.removeItem(index);
	}
}

@OBJ
class ParcelTagMenuItem extends FancyListItem {
	@SLOT
	private parcelTagDestroyed(): void {
		const par = this.parentList();
		const idx = this.index();
		if (par && (idx >= 0)) {
			par.removeItem(idx);
		}
	}
}
