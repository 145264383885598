import {getLogger} from '../../../logging';

import {Checkbox} from '../../checkbox';
import {FancyListItem, FancyListItemOpts} from '../../list';
import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {ElObj, ElObjOpts} from '../../../elobj';
import {list} from '../../../tools';
import {Menu, MenuOpts} from '../../menu';
import {ToolButton} from '../../toolbutton';
import {CheckState} from '../../../constants';
import {FancyPushButton} from '../../pushbutton';
import {Icon} from '../../icon';

const logger = getLogger('ui.datatable');

class ColumnItem {
	checkbox: Checkbox;
	column: IColumnItem;
	listItemOpts: Partial<FancyListItemOpts>;

	constructor(column: IColumnItem, checkbox: Checkbox, listItemOpts: Partial<FancyListItemOpts>) {
		this.checkbox = checkbox;
		this.column = column;
		this.listItemOpts = listItemOpts;
	}
}

interface ColumnSelectOpts extends MenuOpts {
	icon: string;
	items: Iterable<IColumnItem>;
}

@OBJ
export class ColumnSelect extends Menu {
	private closeButton: MenuButton | null;
	private items: list<ColumnItem>;
	private openButton: ToolButton;

	constructor(opts: Partial<ColumnSelectOpts> = {}) {
		super(opts);
		this.closeButton = null;
		this.items = new list();
		this.openButton = new ToolButton({
			classNames: [
				'lb-table-column-select-menu-btn',
			],
			parent: this,
		});
		this.openButton.setIcon(
			new Icon({
				name: opts.icon || 'settings',
			}),
		);
		Obj.connect(
			this.openButton, 'clicked',
			this, 'openButtonClicked');
		this.openButton.show();
		if (opts.items) {
			for (const item of opts.items) {
				this.addItem(item);
			}
		}
	}

	addItem(item: IColumnItem | Partial<FancyListItemOpts> | FancyListItem | string): void {
		if ((item instanceof FancyListItem) || (typeof item === 'string') || !isColumnItem(item)) {
			return super.addItem(item);
		}
		const opts: Partial<FancyListItemOpts> = {};
		opts.text = item.label;
		const check = new Checkbox();
		opts.leadingObj = check;
		if (item.visible) {
			check.setCheckState(CheckState.Checked);
		}
		Obj.connect(
			check, 'stateChanged',
			this, 'checkboxStateChanged',
		);
		check.show();
		this.items.append(
			new ColumnItem(item, check, opts),
		);
	}

	@SLOT
	private checkboxStateChanged(state: number): void {
		const obj = <Checkbox | null>this.sender();
		if (obj) {
			const idx = this.items.findIndex(x => (x.checkbox === obj));
			if (idx >= 0) {
				this.selectionChanged(idx);
			} else {
				logger.critical('checkboxStateChanged: Invalid sender.');
			}
		} else {
			logger.critical('checkboxStateChanged: Sender is null.');
		}
	}

	clear(): void {
		this.destroyItems();
		super.clear();
	}

	destroy(): void {
		if (this.closeButton) {
			this.closeButton.destroy();
		}
		this.closeButton = null;
		this.destroyItems();
		this.openButton.destroy();
		super.destroy();
	}

	private destroyItems(): void {
		for (const item of this.items) {
			item.checkbox.destroy();
			item.listItemOpts = {};
		}
		this.items.clear();
	}

	@SLOT
	private menuSelectionChanged(index: number): void {
		if ((index >= 0) && (index < this.items.size())) {
			const check = this.items.at(index).checkbox;
			check.setCheckState(
				(check.checkState() === CheckState.Checked) ?
					CheckState.Unchecked :
					CheckState.Checked);
		}
	}

	protected setOpen(open: boolean): void {
		if (open && !this.isVisible()) {
			for (const item of this.items) {
				this.addItem(item.listItemOpts);
			}
			this.closeButton = new MenuButton({parent: this});
			Obj.connect(
				this.closeButton, 'clicked',
				this, 'close');
			this.closeButton.show();
			Obj.connect(
				this, 'closed',
				this, 'menuClosed');
			Obj.connect(
				this, 'selectionChanged',
				this, 'menuSelectionChanged');
		}
		super.setOpen(open);
	}

	@SLOT
	private openButtonClicked(): void {
		this.show();
	}
}

@OBJ
class MenuButton extends ElObj {
	private button: FancyPushButton;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.styles = ElObj.mergeStyles(
			opts.styles,
			['padding', '0 16px 8px 16px'],
		);
		super(opts);
		this.button = new FancyPushButton({
			classNames: [
				'width--100-percent',
			],
			parent: this,
			text: 'OK',
		});
		this.button.setToolTip('Close Menu');
		Obj.connect(
			this.button, 'clicked',
			this, 'clicked');
		this.button.show();
	}

	@SIGNAL
	clicked(): void {
	}

	destroy(): void {
		this.button.destroy();
		super.destroy();
	}
}

function isColumnItem(obj: any): obj is IColumnItem {
	return !!obj && (typeof obj === 'object') && !Array.isArray(obj) && ('label' in obj) && ('value' in obj) && ('visible' in obj);
}
