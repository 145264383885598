import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {ControlItem, ControlView} from '../itemviews/controlview';
import {ToolButton} from '../toolbutton';
import {CheckState, ControlType, ExpressionSegment, ItemDataRole} from '../../constants';
import {Expression} from './expression';
import {getLogger} from '../../logging';
import {Variant} from '../../variant';
import {TreeItemIterator} from '../tree';
import {debounce} from '../../util';
import {Icon} from '../icon';
import {EnabledToggle} from '../enabletoggle';
import {ExprTok} from '../../models';

const logger = getLogger('views.projectdetail.filter');
const DEBOUNCE_HIGH_MS = 650;
const DEBOUNCE_LOW_MS = 10;
const staticExpr = new Expression();

export class FilterPrivate extends ElObjPrivate {
	ctrlView: ControlView;
	delButton: ToolButton;
	enabledToggle: EnabledToggle;
	expr: Expression;
	exprRhsIsTree: boolean;
	tok: ExprTok;

	constructor() {
		super();
		this.ctrlView = new ControlView({
			classNames: [
				'lb-filter-ctrls',
			],
		});
		this.delButton = new ToolButton();
		this.delButton.setIcon(new Icon({name: 'delete_outline'}));
		this.enabledToggle = new EnabledToggle({
			attributes: [
				['title', 'Enable/disable this filter'],
			],
		});
		this.expr = staticExpr;
		this.exprRhsIsTree = false;
		this.tok = new ExprTok();
	}

	attrControlType(attr: IExpressionAttribute | null): ControlType {
		return attr ?
			Expression.controlTypeForString(
				attr.controlType,
			) :
			ControlType.NoType;
	}

	ensureLabelControl(): ControlItem {
		let item = this.ctrlView.item(0);
		if ((this.ctrlView.sectionCount() === 1) && item && (item.type() === ControlType.LineEdit)) {
			return item;
		}
		this.ctrlView.setSectionCount(1);
		item = new ControlItem(ControlType.LineEdit);
		this.ctrlView.setItem(0, item);
		return item;
	}

	expressionBase(): IExpressionBase | null {
		if (this.expr === staticExpr) {
			return null;
		}
		let lhs: string = '';
		let operator: string = '';
		let rhs: string = '';
		let data = this.expr.segmentData(ExpressionSegment.LeftHandSide);
		if (data.isValid() && !data.isNull()) {
			lhs = data.toString().trim();
		}
		data = this.expr.segmentData(ExpressionSegment.Operator);
		if (data.isValid() && !data.isNull()) {
			operator = data.toString().trim();
		}
		data = this.expr.segmentData(ExpressionSegment.RightHandSide);
		if (data.isValid() && !data.isNull()) {
			rhs = data.toString().trim();
		}
		return {
			lhs,
			operator,
			rhs,
			negated: lhs.startsWith('-'),
		};
	}

	init(opts: Partial<FilterOpts>): void {
		if (opts.tok) {
			this.tok = opts.tok;
		}
		super.init(opts);
		const q = this.q;
		Obj.connect(
			this.delButton, 'clicked',
			q, 'deleteClicked',
		);
		Obj.connect(
			this.enabledToggle, 'clicked',
			q, 'enabledToggled',
		);
		Obj.connect(
			this.ctrlView, 'itemChanged',
			q, '_ctrlViewItemChanged',
		);
		this.enabledToggle.setParent(q);
		this.expr.setParent(q);
		this.ctrlView.setParent(q);
		this.delButton.setParent(q);
	}

	get q(): Filter {
		return <Filter>super.q;
	}

	setExpression(expr: Expression | null): void {
		if ((expr === this.expr) || (!expr && (this.expr === staticExpr))) {
			return;
		}
		this.exprRhsIsTree = false;
		if (this.expr !== staticExpr) {
			this.expr.destroy();
			if (!expr) {
				this.expr.hide();
				this.expr.clear();
			}
		}
		if (!expr) {
			this.expr = staticExpr;
			// Nothing left to do.
			return;
		}
		this.expr = expr;
		if (this.expr) {
			this.expr.show();
		}
		const idx = this.children.indexOf(this.enabledToggle);
		this.expr.setParent(
			this.q,
			(idx >= 0) ?
				idx + 1 :
				idx,
		);
		this.expr.show();
		this.expr.setSegmentVisible(
			ExpressionSegment.LeftHandSide,
			true,
			ControlType.ComboBox,
		);
		this.expr.setSegmentVisible(
			ExpressionSegment.Operator,
			true,
			ControlType.ComboBox,
		);
		Obj.connect(
			this.expr, 'itemChanged',
			this.q, '_expressionItemChanged',
		);
	}

	setExpressionOperatorOptsByLhs(lhsData: Variant): void {
		let value: string = '';
		if (lhsData.isValid() && !lhsData.isNull()) {
			value = lhsData.toString().trim();
		}
		this.expr.setSegmentOptions(
			ExpressionSegment.Operator,
			this.tok.operators(value),
		);
	}

	setExpressionRhsByLhs(lhsData: Variant): void {
		let lhs: string = '';
		if (lhsData.isValid() && !lhsData.isNull()) {
			lhs = lhsData.toString().trim();
		}
		const attr = this.tok.attribute(lhs);
		const ctrlType = this.attrControlType(attr);
		this.exprRhsIsTree = (ctrlType === ControlType.Tree);
		this.expr.setSegmentVisible(
			ExpressionSegment.RightHandSide,
			ctrlType !== ControlType.NoType,
			ctrlType,
		);
		if (attr && (attr.choices.length > 0)) {
			this.expr.setSegmentOptions(
				ExpressionSegment.RightHandSide,
				attr.choices,
			);
		}
		this.q.setTooTall(ctrlType === ControlType.Tree);
	}

	validExpressionSegment(seg: number): boolean {
		switch (seg) {
			case ExpressionSegment.LeftHandSide:
			case ExpressionSegment.Operator:
			case ExpressionSegment.RightHandSide: {
				return true;
			}
			default: {
				logger.warning('validExpressionSegment: Invalid expression segment (%s) for event item', seg);
				return false;
			}
		}
	}
}

export interface FilterOpts extends ElObjOpts {
	dd: FilterPrivate;
	tok: ExprTok;
}

@OBJ
export class Filter extends ElObj {
	static TooTallCssClassName: string = 'too-tall';

	private _debouncedExpressionChangedHigh: GenericDebounced;
	private _debouncedExpressionChangedLow: GenericDebounced;

	constructor(opts: Partial<FilterOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-filter',
		);
		opts.dd = opts.dd || new FilterPrivate();
		super(opts);
		this._debouncedExpressionChangedHigh = debounce(
			this._expressionChanged,
			DEBOUNCE_HIGH_MS,
		);
		this._debouncedExpressionChangedLow = debounce(
			this._expressionChanged,
			DEBOUNCE_LOW_MS,
		);
	}

	@SLOT
	private _ctrlViewItemChanged(item: ControlItem): void {
		if ((item.section() === 0) && (item.type() === ControlType.LineEdit)) {
			const data = item.data(ItemDataRole.EditRole);
			if (data.isValid() && !data.isNull()) {
				this.labelChanged(data.toString());
			}
		}
	}

	get d(): FilterPrivate {
		return <FilterPrivate>super.d;
	}

	@SIGNAL
	private deleteClicked(): void {
	}

	@SIGNAL
	private enabledToggled(on: boolean): void {
	}

	expression(): Expression | null {
		const expr = this.d.expr;
		return (expr === staticExpr) ?
			null :
			expr;
	}

	@SIGNAL
	private expressionChanged(newExpression: IExpressionBase | null): void {
	}

	private _expressionChanged(segment: ExpressionSegment): void {
		const d = this.d;
		if (segment === ExpressionSegment.LeftHandSide) {
			const lhsData = d.expr.segmentData(
				ExpressionSegment.LeftHandSide,
			);
			d.setExpressionOperatorOptsByLhs(lhsData);
			// Reset selected operator in case previous set are invalid for
			// current LHS.
			//
			// NB: Seeing as we collect the current value after this call, we
			//     block signals here to avoid being called again right after
			//     we set the operator.
			const blocked = d.expr.blockSignals(true);
			d.expr.setSegmentData(
				ExpressionSegment.Operator,
				new Variant(''),
			);
			d.expr.blockSignals(blocked);
			d.setExpressionRhsByLhs(lhsData);
		}
		this.expressionChanged(
			d.expressionBase(),
		);
	}

	@SLOT
	private _expressionItemChanged(item: ControlItem): void {
		const d = this.d;
		if (d.expr === staticExpr) {
			logger.warning('_expressionItemChanged: Got event from static item');
			return;
		}
		const itemSeg = d.expr.itemSegment(item);
		if (d.validExpressionSegment(itemSeg)) {
			// NB: If RHS just changed, it may be due to text input in which
			//     case we want to wait a little while longer to collect as
			//     many characters from the input as is reasonable before
			//     updating the view.
			if ((itemSeg === ExpressionSegment.RightHandSide) && !d.exprRhsIsTree) {
				this._debouncedExpressionChangedHigh(itemSeg);
			} else {
				this._debouncedExpressionChangedLow(itemSeg);
			}
		}
	}

	isTooTall(): boolean {
		return this.hasClass(
			(<typeof Filter>this.constructor).TooTallCssClassName,
		);
	}

	label(): string {
		const item = this.d.ctrlView.item(0);
		if (item) {
			const data = item.data(ItemDataRole.EditRole);
			if (data.isValid() && !data.isNull()) {
				return data.toString();
			}
		}
		return '';
	}

	@SIGNAL
	private labelChanged(label: string): void {
	}

	@SLOT
	setExpression(data: IExpressionBase | null): void {
		const d = this.d;
		if (!data) {
			d.setExpression(null);
			// Nothing left to do.
			return;
		}
		if (d.expr === staticExpr) {
			d.setExpression(new Expression());
		}
		// NB: Blocking signals because they will be emitting during
		//     instantiation of the controls and that is not what we want.
		//     ...
		//     That instantiation issue should probably be addressed.
		const blocked = d.expr.blockSignals(true);
		const exprData = new ExprData();
		exprData.expr = data;
		exprData.tok = d.tok;
		// LHS
		const attr = exprData.lhsAttr;
		if (attr && attr.legacy) {
			// Legacy attribute
			//
			// These attributes are no modifiable and are presented solely
			// for reference.
			d.expr.setSegmentOptions(
				ExpressionSegment.LeftHandSide,
				[
					attr,
				]);
			d.expr.setSegmentEnabled(
				ExpressionSegment.LeftHandSide,
				false,
			);
		} else {
			d.expr.setSegmentOptions(
				ExpressionSegment.LeftHandSide,
				d.tok.sortedAttributes().filter(x => !x.legacy),
			);
		}
		const lhsData = exprData.lhsData;
		d.expr.setSegmentData(
			ExpressionSegment.LeftHandSide,
			lhsData,
		);
		// OPERATOR
		//
		// Set options first...
		d.setExpressionOperatorOptsByLhs(lhsData);
		if (attr && attr.legacy) {
			// If LHS is disabled, so is OPERATOR.
			d.expr.setSegmentEnabled(
				ExpressionSegment.Operator,
				false,
			);
		}
		// ...then the selected option (if applicable)
		d.expr.setSegmentData(
			ExpressionSegment.Operator,
			exprData.operatorData,
		);
		// RHS
		//
		// Set options first...
		d.setExpressionRhsByLhs(lhsData);
		if (attr && attr.legacy) {
			// If LHS is disabled, so it RHS.
			d.expr.setSegmentEnabled(
				ExpressionSegment.RightHandSide,
				false,
			);
		}
		// FIXME: This is here but should be (re-written) placed within the
		//        control item's setData() routine with the rest of the
		//        control data logic. The reason this is not there right now
		//        is because you haven't implemented the ability to define
		//        complex data structures for a Variant. Do that and things
		//        might be better (not good, mind you, but better).
		if (attr && (attr.controlType === ControlType.Tree)) {
			d.exprRhsIsTree = true;
			const ids: Array<string> = [];
			const rhs = exprData.rhs;
			if (attr.rhsIsList) {
				ids.push(...rhs.split(attr.rhsListSep).map(x => x.toUpperCase()));
			} else {
				ids.push(rhs.toUpperCase());
			}
			if (ids.length > 0) {
				const ctrlItem = d.expr.segmentItem(ExpressionSegment.RightHandSide);
				if (ctrlItem) {
					const tr = ctrlItem.treeControl();
					if (tr) {
						const it = new TreeItemIterator(tr);
						let curr = it.current;
						while (curr) {
							const d = curr.data(0, ItemDataRole.EditRole);
							if (d.isValid() && !d.isNull()) {
								const s = d.toString().toUpperCase();
								if (ids.indexOf(s) >= 0) {
									curr.setData(
										0,
										ItemDataRole.CheckStateRole,
										new Variant(CheckState.Checked),
									);
									curr.setExpanded(true);
								}
							}
							it.forward();
							curr = it.current;
						}
					}
				}
			}
		}
		// ...then the selected option (if applicable)
		d.expr.setSegmentData(
			ExpressionSegment.RightHandSide,
			exprData.rhsData,
		);
		d.expr.blockSignals(blocked);
	}

	@SLOT
	setLabel(label: string | null): void {
		// Pass null if label component should no longer render.
		const d = this.d;
		if (typeof label === 'string') {
			// Set
			//
			// Can set a label or have an expression. Not both.
			this.setExpression(null);
			const blocked = d.ctrlView.blockSignals(true);
			const item = d.ensureLabelControl();
			item.setData(
				ItemDataRole.EditRole,
				new Variant(label),
			);
			d.ctrlView.blockSignals(blocked);
		} else {
			// Remove
			d.ctrlView.clear();
			d.ctrlView.setSectionCount(0);
		}
	}

	setTooTall(tooTall: boolean): void {
		if (tooTall === this.isTooTall()) {
			return;
		}
		this.setClass(
			tooTall,
			(<typeof Filter>this.constructor).TooTallCssClassName,
		);
	}
}

class ExprData {
	expr: IExpressionBase = {lhs: '', operator: '', rhs: '', negated: false};
	tok: ExprTok = new ExprTok();

	get lhs(): string {
		return this.expr.lhs;
	}

	get lhsAttr(): IExpressionAttribute | null {
		return this.tok.attribute(this.expr.lhs.trim());
	}

	get lhsData(): Variant {
		return new Variant(this.expr.lhs.trim());
	}

	get lhsOptions(): Array<IExpressionAttribute> {
		return this.tok.sortedAttributes().filter(x => !x.legacy);
	}

	get operator(): string {
		return this.expr.operator;
	}

	get operatorData(): Variant {
		return new Variant(this.expr.operator.trim());
	}

	get operatorOptions(): Array<ILabeledValue> {
		return this.tok.operators(this.expr.lhs.trim());
	}

	get rhs(): string {
		return this.expr.rhs;
	}

	get rhsData(): Variant {
		return new Variant(this.expr.rhs.trim());
	}
}
