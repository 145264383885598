export async function init(slug?: string): Promise<void> {
	if (!slug) {
		return;
	}
	const mod = await import('./view');
	const obj = new mod.ProjectDetailView({
		slug,
		tmpAppendToDocBody: true,
	});
	obj.show();
}
