import mapboxgl from 'mapbox-gl';

import {Obj, OBJ, SLOT} from '../../obj';
import {MapLayerId, MapSourceId, MapStyle, MapStyleTheme} from '../../constants';
import {featureOrFeatureIdLikeToFeatureIds, isIterable} from '../../util';
import {FilterMdl, GeoRef} from '../../models';
import {InteractiveMap, InteractiveMapOpts, InteractiveMapPrivate, ISource, Layer, MapEventType, mapLayers, mapSources} from '../../ui/map';
import {list, set} from '../../tools';

interface IFeatureEventInfo {
	id?: GeoJsonFeatureId;
	sourceId: string;
	sourceLayerId?: string;
}

interface IHighlightFeatures {
	ids: set<GeoJsonFeatureId>;
	sourceLayerId?: string;
}

class ParcelMapPrivate extends InteractiveMapPrivate {
	featEvent: IFeatureEventInfo;
	highlightedFeats: Map<string, list<IHighlightFeatures>>; // key is source ID

	constructor() {
		super();
		this.featEvent = {
			id: undefined,
			sourceId: '',
			sourceLayerId: undefined,
		};
		this.highlightedFeats = new Map();
	}

	init(opts: Partial<ParcelMapOpts>): void {
		super.init(opts);
		const q = this.q;
		const srcs = opts.sources || mapSources;
		const lyrs = opts.layers || mapLayers;
		for (const obj of srcs) {
			q.addSource(obj.id, obj.source);
		}
		for (const obj of lyrs) {
			q.addLayer(obj.id, obj);
		}
		Obj.connect(
			q, 'layerToggled',
			q, 'setLayerVisible',
		);
		Obj.connect(
			q, 'layerVisibilityChanged',
			q, 'setRelatedLayerVisibility',
		);
		Obj.connect(
			q, 'styleChanged',
			q, 'setThemeForStyle',
		);
	}

	get q(): ParcelMap {
		return <ParcelMap>super.q;
	}
}

interface ParcelMapOpts extends InteractiveMapOpts {
	dd: ParcelMapPrivate;
	layers: Iterable<Layer>;
	sources: Iterable<ISource>;
}

@OBJ
export class ParcelMap extends InteractiveMap {
	constructor(opts: Partial<ParcelMapOpts> = {}) {
		opts.dd = opts.dd || new ParcelMapPrivate();
		super(opts);
	}

	@SLOT
	addFilterAsFeature(filter: FilterMdl): void {
		this.addFeatures(
			filter.toGeoJsonFeature(),
		);
	}

	@SLOT
	clearHighlighting(): void {
		const d = this.d;
		for (const [sourceId, objs] of d.highlightedFeats) {
			for (const obj of objs) {
				this.setFeatureHighlight(
					obj.ids,
					false,
					sourceId,
					obj.sourceLayerId,
				);
				obj.ids.clear();
			}
			objs.clear();
		}
		d.highlightedFeats.clear();
	}

	get d(): ParcelMapPrivate {
		return <ParcelMapPrivate>super.d;
	}

	isFeatureHighlighted(id: GeoJsonFeatureId, sourceId: string, sourceLayerId?: string): boolean {
		const objs = this.d.highlightedFeats.get(sourceId);
		if (!objs) {
			return false;
		}
		let obj: IHighlightFeatures | null = null;
		for (const o of objs) {
			if (o.sourceLayerId === sourceLayerId) {
				obj = o;
				break;
			}
		}
		return obj ?
			obj.ids.has(id) :
			false;
	}

	isParcelFeatureHighlighted(id: GeoJsonFeatureId): boolean {
		return this.isFeatureHighlighted(
			id,
			MapSourceId.Parcel,
			MapLayerId.ParcelSourceArea,
		);
	}

	protected mapLoadEvent(event: mapboxgl.MapboxEvent): void {
		this.setMapEventListener(
			MapEventType.MouseMove,
			true,
			MapLayerId.DoNotMailParcelFill,
		);
		this.setMapEventListener(
			MapEventType.MouseLeave,
			true,
			MapLayerId.DoNotMailParcelFill,
		);
		this.setMapEventListener(
			MapEventType.MouseMove,
			true,
			MapLayerId.ParcelFill,
		);
		this.setMapEventListener(
			MapEventType.MouseLeave,
			true,
			MapLayerId.ParcelFill,
		);
		this.setThemeForStyle(
			this.style(),
		);
	}

	protected mapLayerMouseLeaveEvent(event: mapboxgl.MapLayerMouseEvent): void {
		const d = this.d;
		if (d.featEvent.id) {
			this.setFeatureState(
				d.featEvent.id,
				{hover: false},
				d.featEvent.sourceId,
				d.featEvent.sourceLayerId,
			);
		}
		d.featEvent = {
			id: undefined,
			sourceId: '',
			sourceLayerId: undefined,
		};
	}

	protected mapLayerMouseMoveEvent(event: mapboxgl.MapLayerMouseEvent): void {
		const d = this.d;
		if (event.features && (event.features.length > 0) && (event.features[0].id === d.featEvent.id)) {
			return;
		}
		if (d.featEvent.id) {
			this.setFeatureState(
				d.featEvent.id,
				{hover: false},
				d.featEvent.sourceId,
				d.featEvent.sourceLayerId,
			);
		}
		if (!event.features || (event.features.length < 1)) {
			d.featEvent = {
				id: undefined,
				sourceId: '',
				sourceLayerId: undefined,
			};
			return;
		}
		d.featEvent = {
			id: event.features[0].id,
			sourceId: event.features[0].source,
			sourceLayerId: event.features[0].sourceLayer,
		};
		this.setFeatureState(
			d.featEvent.id,
			{hover: true},
			d.featEvent.sourceId,
			d.featEvent.sourceLayerId,
		);
	}

	selectFilterFeature(obj: FilterMdl | FilterPk): void {
		let id: FilterPk;
		if (obj instanceof FilterMdl) {
			id = obj.id();
		} else {
			id = obj;
		}
		this.setSelectedFeatures(id);
	}

	setFeatureHighlight<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(feature: GeoJsonFeature<G, P> | Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | Iterable<GeoJsonFeatureId> | GeoJsonFeatureId, highlighted: boolean, sourceId: string, sourceLayerId?: string): void {
		const d = this.d;
		const ids = featureOrFeatureIdLikeToFeatureIds(
			feature,
		);
		if (ids.length < 1) {
			return;
		}
		const existingList = d.highlightedFeats.get(sourceId);
		if (existingList) {
			let existingObj: IHighlightFeatures | null = null;
			for (let i = 0; i < existingList.size(); ++i) {
				if (existingList.at(i).sourceLayerId === sourceLayerId) {
					existingObj = existingList.at(i);
					break;
				}
			}
			if (existingObj) {
				const idSet = new set(ids);
				existingObj.ids = highlighted ?
					existingObj.ids.union(idSet) :
					existingObj.ids.difference(idSet);
			} else if (highlighted) {
				existingList.append({
					sourceLayerId,
					ids: new set(ids),
				});
			}
		} else {
			if (highlighted) {
				const lst = new list<IHighlightFeatures>();
				d.highlightedFeats.set(sourceId, lst);
				lst.append({
					sourceLayerId,
					ids: new set(ids),
				});
			}
		}
		this.setFeatureState(
			ids,
			{highlighted},
			sourceId,
			sourceLayerId,
		);
	}

	setFilterSourceData(filters: Iterable<FilterMdl> | FilterMdl): void {
		this.setSourceData(
			MapSourceId.FilterPolygon,
			filtersToFeatureCollection(filters),
		);
	}

	setGeoRefSourceData(geoRefs: Iterable<GeoRef> | GeoRef): void {
		const objs = isIterable(geoRefs) ?
			geoRefs :
			[geoRefs];
		const points: Array<GeoJsonFeature<IGeoRefPoint>> = [];
		const polys: Array<GeoJsonFeature<IGeoRefMultiPolygon>> = [];
		for (const obj of objs) {
			if (!obj.doNotMail()) {
				continue;
			}
			const id = obj.pk();
			const pt = obj.point();
			if (pt) {
				points.push({
					geometry: pt,
					id,
					properties: {},
					type: 'Feature',
				});
			}
			const poly = obj.multipolygon();
			if (poly) {
				polys.push({
					geometry: poly,
					id,
					properties: {},
					type: 'Feature',
				});
			}
		}
		this.setSourceData(MapSourceId.DoNotMailParcel, polys);
		this.setSourceData(MapSourceId.DoNotMailParcelCentroid, points);
	}

	setParcelFeatureHighlight<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(feature: GeoJsonFeature<G, P> | Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | Iterable<GeoJsonFeatureId> | GeoJsonFeatureId, highlighted: boolean): void {
		this.setFeatureHighlight(
			feature,
			highlighted,
			MapSourceId.Parcel,
			MapLayerId.ParcelSourceArea,
		);
	}

	@SLOT
	private setRelatedLayerVisibility(layerId: string, visible: boolean): void {
		let related: Array<string>;
		switch (layerId) {
			case MapLayerId.FilterFill: {
				related = [
					MapLayerId.FilterStroke,
					MapLayerId.FilterSymbol,
				];
				break;
			}
			case MapLayerId.ParcelFill: {
				related = [
					MapLayerId.ParcelLine,
				];
				break;
			}
			case MapLayerId.DoNotMailParcelFill: {
				related = [
					MapLayerId.DoNotMailParcelCentroid,
				];
				break;
			}
			default: {
				return;
			}
		}
		for (const str of related) {
			this.setLayerVisible(str, visible);
		}
	}

	@SLOT
	private setThemeForStyle(style: MapStyle): void {
		switch (style) {
			case MapStyle.SatelliteStreet: {
				this.setTheme(MapStyleTheme.Light);
				break;
			}
			default: {
				this.setTheme(MapStyleTheme.Dark);
				break;
			}
		}
	}
}

function filtersToFeatureCollection<G extends GeoJsonGeometry, P extends GeoJsonFilterFeatureProperties>(filters: Iterable<FilterMdl> | FilterMdl): {features: Array<GeoJsonFilterFeature<G, P>>; type: 'FeatureCollection'} {
	const rv: Array<GeoJsonFilterFeature<G, P>> = [];
	const objs = isIterable(filters) ?
		filters :
		[filters];
	for (const fil of objs) {
		const feat = fil.toGeoJsonFeature<G, P>();
		if (feat.geometry) {
			rv.push(feat);
		}
	}
	return {
		features: rv,
		type: 'FeatureCollection',
	};
}
