import {AbstractItemView, AbstractItemViewOpts, AbstractItemViewPrivate} from './abstractitemview';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {Icon} from '../icon';
import {CheckIndexOption, CheckState, ItemDataRole, ItemFlag} from '../../constants';
import {Variant} from '../../variant';
import {list} from '../../tools';
import {AbstractItemModelOpts, AbstractListModel, ModelIndex, PersistentModelIndex} from '../../abstractitemmodel';
import {ItemData} from './itemdata';
import {assert, clamp, isNumber} from '../../util';

class ListItemPrivate {
	id: number;
	q: ListItem | null;
	values: list<ItemData>;

	constructor(q: ListItem | null) {
		this.id = -1;
		this.q = q;
		this.values = new list();
	}
}

export class ListItem {
	d: ListItemPrivate;
	f: ItemFlag;
	view: ListView | null;

	constructor(other: ListItem | null);
	constructor(icon: Icon, text: string, parent: ListView | null);
	constructor(text: string, parent?: ListView | null);
	constructor(parent?: ListView | null);
	constructor(a?: ListItem | Icon | string | ListView | null, b?: string | ListView | null, c?: ListView | null) {
		this.d = new ListItemPrivate(this);
		let cloned = false;
		let icon: Icon | null = null;
		let flags: ItemFlag = ItemFlag.ItemIsSelectable
			| ItemFlag.ItemIsUserCheckable
			| ItemFlag.ItemIsEnabled
			| ItemFlag.ItemIsDragEnabled;
		let text: string = '';
		let parent: ListView | null = null;
		if (a) {
			if (a instanceof ListView) {
				// SIG: constructor(parent?: ListView | null)
				parent = a;
			} else if (a instanceof Icon) {
				// SIG: constructor(icon: Icon, text: string, parent: ListView | null)
				icon = a;
				if (typeof b === 'string' && (b.trim().length > 0)) {
					text = b;
				}
				if (c && (c instanceof ListView)) {
					parent = c;
				}
			} else if (typeof a === 'string') {
				// SIG: constructor(text: string, parent: ListView | null)
				text = a;
				if (b && (b instanceof ListView)) {
					parent = b;
				}
			} else {
				// SIG: constructor(other: ListItem | null)
				cloned = true;
				parent = null;
				flags = a.f;
				this.d.values = new list(a.d.values);
			}
		}
		this.f = flags;
		this.view = parent;
		const mdl = this.listModel();
		if (!cloned && mdl) {
			if (icon || (text.length > 0)) {
				const viewBlocked = this.view ? this.view.blockSignals(true) : false;
				const mdlBlocked = mdl.blockSignals(true);
				if (text.length > 0) {
					this.setData(
						ItemDataRole.DisplayRole,
						new Variant(text),
					);
				}
				if (icon) {
					this.setData(
						ItemDataRole.DecorationRole,
						new Variant(icon),
					);
				}
				if (this.view) {
					this.view.blockSignals(viewBlocked);
				}
				mdl.blockSignals(mdlBlocked);
			}
			mdl.insert(
				mdl.rowCount(),
				this,
			);
		}
	}

	checkState(): CheckState {
		return this.data(ItemDataRole.CheckStateRole).toNumber();
	}

	clone(): ListItem {
		return new ListItem(this);
	}

	data(role: number): Variant {
		role = (role === ItemDataRole.EditRole) ?
			ItemDataRole.DisplayRole :
			role;
		for (let i = 0; i < this.d.values.size(); ++i) {
			if (this.d.values.at(i).role === role) {
				return this.d.values.at(i).value;
			}
		}
		return new Variant();
	}

	destroy(): void {
		const mdl = this.listModel();
		if (mdl) {
			mdl.remove(this);
		}
		this.d.id = -1;
		this.d.q = null;
		this.d.values.clear();
	}

	eq(other: ListItem): boolean {
		return this.d.values.eq(other.d.values)
			&& (this.f === other.f);
	}

	flags(): ItemFlag {
		return this.f;
	}

	icon(): Icon {
		return this.data(ItemDataRole.DecorationRole).toIcon();
	}

	listModel(): ListModel | null {
		if (this.view) {
			const mdl = this.view.model();
			if (mdl && (mdl instanceof ListModel)) {
				return mdl;
			}
		}
		return null;
	}

	listView(): ListView | null {
		return this.view;
	}

	setCheckState(state: CheckState): void {
		this.setData(
			ItemDataRole.CheckStateRole,
			new Variant(state),
		);
	}

	setData(role: number, value: Variant): void {
		let found = false;
		role = (role === ItemDataRole.EditRole) ?
			ItemDataRole.DisplayRole :
			role;
		for (let i = 0; i < this.d.values.size(); ++i) {
			if (this.d.values.at(i).role === role) {
				if (this.d.values.at(i).value.eq(value)) {
					return;
				}
				this.d.values.at(i).value = value;
				found = true;
				break;
			}
		}
		if (!found) {
			this.d.values.append(
				new ItemData(role, value),
			);
		}
		const mdl = this.listModel();
		if (mdl) {
			const roles = (role === ItemDataRole.DisplayRole) ?
				[
					ItemDataRole.DisplayRole,
					ItemDataRole.EditRole,
				] :
				[
					role,
				];
			mdl.itemChanged(this, roles);
		}
	}

	setFlags(flags: ItemFlag): void {
		if (flags === this.f) {
			return;
		}
		this.f = flags;
		const mdl = this.listModel();
		if (mdl) {
			mdl.itemChanged(this);
		}
	}

	setIcon(icon: Icon): void {
		this.setData(
			ItemDataRole.DecorationRole,
			new Variant(icon),
		);
	}

	setText(text: string): void {
		this.setData(
			ItemDataRole.DisplayRole,
			new Variant(text),
		);
	}

	text(): string {
		return this.data(ItemDataRole.DisplayRole).toString();
	}
}

interface ListModelOpts extends AbstractItemModelOpts {
	parent: ListView;
}

@OBJ
class ListModel extends AbstractListModel {
	private items: list<ListItem>;

	constructor(opts: Partial<ListModelOpts> = {}) {
		super(opts);
		this.items = new list();
	}

	at(row: number): ListItem | null {
		if ((row >= 0) && (row < this.items.size())) {
			return this.items.at(row);
		}
		return null;
	}

	clear(): void {
		this.beginResetModel();
		for (let i = 0; i < this.items.size(); ++i) {
			const item = this.items.at(i);
			if (item) {
				item.d.id = -1;
				item.view = null;
				this.items.takeAt(i).destroy();
			}
		}
		this.items.clear();
		this.endResetModel();
	}

	clearItemData(index: ModelIndex): boolean {
		if (!this.checkIndex(index, CheckIndexOption.IndexIsValid)) {
			return false;
		}
		const item = this.items.at(index.row());
		let allInvalid = true;
		for (const data of item.d.values) {
			if (data.value.isValid()) {
				allInvalid = false;
				break;
			}
		}
		if (allInvalid) {
			// Already clear
			return true;
		}
		item.d.values.clear();
		this.dataChanged(
			index,
			index,
		);
		return true;
	}

	data(index: ModelIndex, role: number = ItemDataRole.DisplayRole): Variant {
		if (!index.isValid() || (index.row() >= this.items.size())) {
			return new Variant();
		}
		return this.items.at(
			index.row(),
		).data(
			role,
		);
	}

	destroy(): void {
		this.clear();
		super.destroy();
	}

	flags(index: ModelIndex): ItemFlag {
		if (!index.isValid() || (index.row() >= this.items.size()) || (index.model() !== this)) {
			// Allow drops outside the items
			return ItemFlag.ItemIsDropEnabled;
		}
		return this.items.at(
			index.row(),
		).flags();
	}

	index(row: number, column: number, parent?: ModelIndex): ModelIndex;
	index(item: ListItem | null): ModelIndex;
	index(a: ListItem | number | null, b?: number, c?: ModelIndex): ModelIndex {
		if (isNumber(a) && isNumber(b)) {
			// SIG: index(row: number, column: number, parent?: ModelIndex)
			if (this.hasIndex(a, b, c)) {
				return this.createIndex(
					a,
					b,
					this.items.at(a),
				);
			}
			return new ModelIndex();
		} else {
			// SIG: index(item: ListItem | null)
			const item = <ListItem | null>a;
			if (!item || !item.view || (item.view.model() !== this) || this.items.isEmpty()) {
				return new ModelIndex();
			}
			let row: number;
			const id = item.d.id;
			if ((id >= 0) && (id < this.items.size()) && (this.items.at(id) === item)) {
				row = id;
			} else {
				row = this.items.lastIndexOf(item);
				if (row === -1) {
					return new ModelIndex();
				}
				item.d.id = row;
			}
			return this.createIndex(
				row,
				0,
				item,
			);
		}
	}

	insert(row: number, item: ListItem | null): void {
		if (!item) {
			return;
		}
		item.view = this.parent();
		row = clamp(
			0,
			row,
			this.items.size(),
		);
		this.beginInsertRows(
			new ModelIndex(),
			row,
			row,
		);
		this.items.insert(row, item);
		item.d.id = row;
		this.endInsertRows();
	}

	insertRows(row: number, count: number, parent?: ModelIndex): boolean {
		if ((count < 1) || (row < 0) || (row > this.rowCount() || (parent && parent.isValid()))) {
			return false;
		}
		this.beginInsertRows(
			new ModelIndex(),
			row,
			row + count - 1,
		);
		const view = this.parent();
		for (let r = row; r < (row + count); ++r) {
			const item = new ListItem();
			item.view = view;
			item.d.id = r;
			this.items.insert(r, item);
		}
		this.endInsertRows();
		return true;
	}

	itemChanged(item: ListItem, roles?: Array<ItemDataRole>): void {
		const idx = this.index(item);
		this.dataChanged(
			idx,
			idx,
			roles,
		);
	}

	itemData(index: ModelIndex): Map<number, Variant> {
		const rv = new Map<number, Variant>();
		if (!index.isValid() || (index.row() >= this.items.size())) {
			return rv;
		}
		const item = this.items.at(index.row());
		for (let i = 0; i < item.d.values.size(); ++i) {
			rv.set(
				item.d.values.at(i).role,
				item.d.values.at(i).value,
			);
		}
		return rv;
	}

	parent(): ListView | null {
		const par = this.dd.parent;
		return (par && (par instanceof ListView)) ?
			par :
			null;
	}

	remove(item: ListItem | null): void {
		if (!item) {
			return;
		}
		const idx = this.index(item);
		assert(idx.isValid());
		const row = idx.row();
		this.beginRemoveRows(
			new ModelIndex(),
			row,
			row,
		);
		this.items.at(row).d.id = -1;
		this.items.at(row).view = null;
		this.items.removeAt(row);
		this.endRemoveRows();
	}

	removeRows(row: number, count: number, parent?: ModelIndex): boolean {
		if ((count < 1) || (row < 0) || (row + count) > this.rowCount() || (parent && parent.isValid())) {
			return false;
		}
		this.beginRemoveRows(
			new ModelIndex(),
			row,
			row + count - 1,
		);
		for (let r = row; r < (row + count); ++r) {
			const item = this.items.takeAt(row);
			item.view = null;
			item.d.id = -1;
			item.destroy();
		}
		this.endRemoveRows();
		return true;
	}

	rowCount(parent: ModelIndex | PersistentModelIndex = new ModelIndex()): number {
		return parent.isValid() ?
			0 :
			this.items.size();
	}

	setData(index: ModelIndex, value: Variant, role: number = ItemDataRole.EditRole): boolean {
		if (!index.isValid() || (index.row() >= this.items.size())) {
			return false;
		}
		this.items.at(
			index.row(),
		).setData(
			role,
			value,
		);
		return true;
	}

	take(row: number): ListItem | null {
		if ((row < 0) || (row >= this.items.size())) {
			return null;
		}
		this.beginRemoveRows(
			new ModelIndex(),
			row,
			row,
		);
		this.items.at(row).d.id = -1;
		this.items.at(row).view = null;
		const item = this.items.takeAt(row);
		this.endRemoveRows();
		return item;
	}
}

export class ListViewPrivate extends AbstractItemViewPrivate {
	listModel(): ListModel {
		return <ListModel>this.model;
	}

	get q(): ListView {
		return <ListView>super.q;
	}

	setup(): void {
		const q = this.q;
		const mdl = new ListModel({
			parent: q,
		});
		q.setModel(mdl);
		Obj.connect(
			q, 'clicked',
			q, '_emitItemClicked',
		);
		Obj.connect(
			mdl, 'dataChanged',
			q, '_emitItemChanged',
		);
	}
}

export interface ListViewOpts extends AbstractItemViewOpts {
	dd: ListViewPrivate;
}

@OBJ
export class ListView extends AbstractItemView {
	constructor(opts: Partial<ListViewOpts> = {}) {
		opts.dd = opts.dd || new ListViewPrivate();
		super(opts);
		opts.dd.setup();
	}

	addItem(item: ListItem | string): void {
		this.insertItem(
			this.count(),
			item,
		);
	}

	@SLOT
	clear(): void {
		this.d.listModel().clear();
	}

	count(): number {
		return this.d.listModel().rowCount();
	}

	get d(): ListViewPrivate {
		return <ListViewPrivate>super.d;
	}

	@SLOT
	protected _emitItemChanged(index: ModelIndex): void {
		const item = this.d.listModel(
		).at(
			index.row(),
		);
		if (item) {
			this.itemChanged(item);
		}
	}

	@SLOT
	protected _emitItemClicked(index: ModelIndex): void {
		const item = this.d.listModel(
		).at(
			index.row(),
		);
		if (item) {
			this.itemClicked(item);
		}
	}

	indexFromItem(item: ListItem | null): ModelIndex {
		return this.d.listModel().index(item);
	}

	insertItem(row: number, item: ListItem | null | string): void {
		if (typeof item === 'string') {
			this.d.listModel(
			).insert(
				row,
				new ListItem(item),
			);
		} else {
			if (item && !item.view) {
				this.d.listModel(
				).insert(
					row,
					item,
				);
			}
		}
	}

	item(row: number): ListItem | null {
		const mdl = this.d.listModel();
		if ((row >= 0) && (row < mdl.rowCount())) {
			return mdl.at(row);
		}
		return null;
	}

	@SIGNAL
	protected itemChanged(item: ListItem): void {
	}

	@SIGNAL
	protected itemClicked(item: ListItem): void {
	}

	itemFromIndex(index: ModelIndex): ListItem | null {
		const d = this.d;
		if (d.isIndexValid(index)) {
			return d.listModel().at(index.row());
		}
		return null;
	}

	row(item: ListItem | null): number {
		return this.d.listModel(
		).index(
			item,
		).row();
	}

	takeItem(row: number): ListItem | null {
		const mdl = this.d.listModel();
		if ((row >= 0) && (row < mdl.rowCount())) {
			return mdl.take(row);
		}
		return null;
	}
}
