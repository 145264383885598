import {MDCCheckbox} from '@material/checkbox';

import {AbstractButton, AbstractButtonOpts, AbstractButtonPrivate} from './abstractbutton';
import {Obj, OBJ, SIGNAL, SLOT} from '../obj';
import {ElObj, ElObjOpts} from '../elobj';
import {CheckState} from '../constants';
import {Variant} from '../variant';

@OBJ
class CheckboxInput extends ElObj {
	static tagName: TagName = 'input';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['type', 'checkbox'],
		);
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-checkbox__native-control',
		);
		super(opts);
	}

	checkState(): CheckState {
		const el = this.element();
		if (el.indeterminate) {
			return CheckState.PartiallyChecked;
		}
		return el.checked ?
			CheckState.Checked :
			CheckState.Unchecked;
	}

	element(): HTMLInputElement {
		return <HTMLInputElement>super.element();
	}

	setCheckState(state: CheckState): void {
		if (state === CheckState.PartiallyChecked) {
			this.element().indeterminate = true;
		} else {
			this.element().checked = state === CheckState.Checked;
		}
	}
}

export class CheckboxPrivate extends AbstractButtonPrivate {
	ctrl: MDCCheckbox | null;
	input: CheckboxInput | null;
	publishedState: CheckState;
	tristate: boolean;

	constructor() {
		super();
		this.ctrl = null;
		this.input = null;
		this.publishedState = CheckState.Unchecked;
		this.tristate = false;
	}

	init(opts: Partial<CheckboxOpts>): void {
		super.init(opts);
		const q = this.q;
		this.input = new CheckboxInput({
			parent: q,
		});
		if (opts.inputId) {
			this.input.setAttribute('id', opts.inputId);
		}
		if (opts.inputName) {
			this.input.setAttribute('name', opts.inputName);
		}
		const bg = new ElObj({
			classNames: 'mdc-checkbox__background',
			parent: q,
			tagName: 'div',
		});
		const checkmark = new ElObj({
			attributes: [
				['viewBox', '0 0 24 24'],
			],
			classNames: 'mdc-checkbox__checkmark',
			namespace: 'http://www.w3.org/2000/svg',
			parent: bg,
			tagName: 'svg',
		});
		new ElObj({
			attributes: [
				['d', 'M1.73,12.91 8.1,19.28 22.79,4.59'],
				['fill', 'none'],
			],
			classNames: 'mdc-checkbox__checkmark-path',
			namespace: 'http://www.w3.org/2000/svg',
			parent: checkmark,
			tagName: 'path',
		});
		new ElObj({
			classNames: 'mdc-checkbox__mixedmark',
			parent: bg,
			tagName: 'div',
		});
		new ElObj({
			classNames: 'mdc-checkbox__ripple',
			parent: q,
			tagName: 'div',
		});
		q.setCheckable(true);
		q.addEventListener('change');
		Obj.connect(
			q, 'stateChanged',
			q, 'syncInputState',
		);
		this.ctrl = new MDCCheckbox(q.element());
		if (opts.checkState !== undefined) {
			q.setCheckState(opts.checkState);
		}
	}

	get q(): Checkbox {
		return <Checkbox>super.q;
	}
}

export interface CheckboxOpts extends AbstractButtonOpts {
	checkState: CheckState;
	dd: CheckboxPrivate;
	inputId: string;
	inputName: string;
}

@OBJ
export class Checkbox extends AbstractButton {
	constructor(opts: Partial<CheckboxOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-checkbox',
		);
		opts.dd = opts.dd || new CheckboxPrivate();
		super(opts);
	}

	checkState(): CheckState {
		const d = this.d;
		return d.tristate ?
			CheckState.PartiallyChecked :
			d.checked ?
				CheckState.Checked :
				CheckState.Unchecked;
	}

	checkStateSet(): void {
		const d = this.d;
		const state = this.checkState();
		if (state !== d.publishedState) {
			d.publishedState = state;
			this.stateChanged(state);
		}
	}

	get d(): CheckboxPrivate {
		return <CheckboxPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.destroy();
		}
		d.ctrl = null;
		d.input = null;
		super.destroy();
	}

	protected _domChangeEvent(event: Event): void {
		const d = this.d;
		if (d.input) {
			const state = d.input.checkState();
			if (state !== this.checkState()) {
				this.setCheckState(state);
			}
		}
	}

	isTristate(): boolean {
		return this.d.tristate;
	}

	nextCheckState(): void {
		const d = this.d;
		if (d.tristate) {
			this.setCheckState(
				(this.checkState() + 1) % 3,
			);
		} else {
			super.nextCheckState();
			this.checkStateSet();
		}
	}

	property(name: string): Variant {
		switch (name) {
			case 'checked':
				return new Variant(this.isChecked());
			case 'checkState':
				return new Variant(this.checkState());
			default:
				return super.property(name);
		}
	}

	setCheckState(state: CheckState): void {
		const d = this.d;
		if (state === CheckState.PartiallyChecked) {
			d.tristate = true;
		}
		this.setChecked(state !== CheckState.Unchecked);
		if (state !== d.publishedState) {
			d.publishedState = state;
			this.stateChanged(d.publishedState);
		}
	}

	setProperty(name: string, value: Variant): boolean {
		switch (name) {
			case 'checked':
				this.setChecked(value.toBoolean());
				return true;
			case 'checkState':
				this.setCheckState(value.toNumber());
				return true;
			case 'disabled':
				this.setDisabled(value.toBoolean());
				return true;
			default:
				return super.setProperty(name, value);
		}
	}

	setTristate(tristate: boolean): void {
		this.d.tristate = tristate;
	}

	@SIGNAL
	stateChanged(state: number): void {
	}

	@SLOT
	private syncInputState(): void {
		const d = this.d;
		const state = this.checkState();
		if (d.ctrl) {
			if ((state === CheckState.PartiallyChecked) && !d.ctrl.indeterminate) {
				d.ctrl.indeterminate = true;
			} else {
				const checked = state === CheckState.Checked;
				if (d.ctrl.checked !== checked) {
					d.ctrl.checked = checked;
				}
			}
		} else {
			if (d.input && (d.input.checkState() !== state)) {
				d.input.setCheckState(state);
			}
		}
	}
}
