export class Url implements Ieq {
	private u: string;

	constructor(url?: string);
	constructor(other?: Url);
	constructor(a?: Url | string) {
		let other: Url | null = null;
		let url: string = '';
		if (a !== undefined) {
			if (typeof a === 'string') {
				url = a;
			} else {
				other = a;
			}
		}
		if (other) {
			this.u = other.u;
		} else {
			this.u = url;
		}
	}

	clear(): void {
		this.u = '';
	}

	destroy(): void {
		this.clear();
	}

	eq(other: Url | string): boolean {
		const mine = this.toString();
		const theirs = (typeof other === 'string') ? other : other.toString();
		return mine === theirs;
	}

	fragment(): string {
		const obj = this.toURL();
		return obj ? obj.hash : '';
	}

	hasFragment(): boolean {
		return this.fragment().trim().length > 0;
	}

	hasQuery(): boolean {
		return this.query().trim().length > 0;
	}

	host(): string {
		const obj = this.toURL();
		return obj ? obj.host : '';
	}

	isValid(): boolean {
		return Boolean(this.toURL());
	}

	password(): string {
		const obj = this.toURL();
		return obj ? obj.password : '';
	}

	path(): string {
		const obj = this.toURL();
		return obj ? obj.pathname : '';
	}

	port(): string {
		const obj = this.toURL();
		return obj ? obj.port : '';
	}

	query(): string {
		const obj = this.toURL();
		return obj ? obj.search : '';
	}

	setFragment(fragment: string): void {
		const obj = this.toURL();
		if (obj) {
			obj.hash = fragment;
		}
	}

	setHost(host: string): void {
		const obj = this.toURL();
		if (obj) {
			obj.host = host;
		}
	}

	setPassword(pw: string): void {
		const obj = this.toURL();
		if (obj) {
			obj.password = pw;
		}
	}

	setPath(path: string): void {
		const obj = this.toURL();
		if (obj) {
			obj.pathname = path;
		}
	}

	setPort(port: string): void {
		const obj = this.toURL();
		if (obj) {
			obj.port = port;
		}
	}

	setQuery(query: string | UrlQuery): void {
		const obj = this.toURL();
		if (obj) {
			if (typeof query === 'string') {
				obj.search = query;
			} else {
				obj.search = query.toString();
			}
		}
	}

	setScheme(scheme: string): void {
		const obj = this.toURL();
		if (obj) {
			obj.protocol = scheme.replace(/:$/, '') + ':';
		}
	}

	setUrl(url: string): void {
		this.u = url;
	}

	setUserName(userName: string): void {
		const obj = this.toURL();
		if (obj) {
			obj.username = userName;
		}
	}

	scheme(): string {
		// Returns the scheme of the URL. If an empty string is returned, this
		// means the scheme is undefined and the URL is then relative.
		//
		// The scheme can only contain US-ASCII letters or digits, which means
		// it cannot contain any character that would otherwise require
		// encoding. Additionally, schemes are always returned in lowercase
		const obj = this.toURL();
		return obj ? obj.protocol.replace(/:$/, '') : '';
	}

	toString(): string {
		return this.url();
	}

	private toURL(): URL | null {
		let rv: URL | null = null;
		try {
			rv = new URL(this.u);
		} catch {
		}
		return rv;
	}

	url(): string {
		const obj = this.toURL();
		return obj ? obj.toString() : '';
	}

	userName(): string {
		const obj = this.toURL();
		return obj ? obj.username : '';
	}
}

export class UrlQuery {
}
