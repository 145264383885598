import {GridLayout, GridLayoutOpts, GridLayoutPrivate} from '../../ui/gridlayout';
import {OBJ, SLOT} from '../../obj';
import {IViewItem} from './item';
import {list} from '../../tools';
import {ProjectListView} from './view';
import {assert} from '../../util';

export class LayoutPrivate extends GridLayoutPrivate {
	items: list<IViewItem>;

	constructor() {
		super();
		this.items = new list();
	}

	get q(): Layout {
		return <Layout>super.q;
	}
}

export interface LayoutOpts extends GridLayoutOpts {
	dd: LayoutPrivate;
}

@OBJ
export class Layout extends GridLayout {
	view: ProjectListView | null;

	constructor(opts: Partial<LayoutOpts> = {}) {
		opts.dd = opts.dd || new LayoutPrivate();
		super(opts);
		this.view = null;
	}

	addItem(item: IViewItem): void {
		item.layout = this;
		this.d.items.append(item);
	}

	@SLOT
	clear(): void {
		const d = this.d;
		for (const item of d.items) {
			item.layout = null;
		}
		if (d.inner) {
			for (let i = d.inner.children().size() - 1; i >= 0; --i) {
				const child = d.inner.children().at(i);
				child.destroy();
			}
		}
		d.items.clear();
	}

	get d(): LayoutPrivate {
		return <LayoutPrivate>super.d;
	}

	destroy(): void {
		this.view = null;
		this.d.items.clear();
		super.destroy();
	}

	index(item: IViewItem | null): number {
		return item ?
			this.d.items.indexOf(item) :
			-1;
	}

	item(index: number): IViewItem | null {
		const d = this.d;
		if ((index >= 0) && (index < d.items.size())) {
			return d.items.at(index);
		}
		return null;
	}

	newItem(): IViewItem {
		throw new Error('Not Implemented');
	}

	takeItem(index: number): IViewItem | null {
		const d = this.d;
		const el = <IViewItem | null>this.takeEl(index);
		const item = d.items.takeAt(index);
		assert(el === item, 'items don\'t match');
		item.layout = null;
		return item;
	}
}
