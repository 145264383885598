import {MDCLinearProgress} from '@material/linear-progress';

import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {OBJ} from '../../obj';
import {HideEvt, ShowEvt} from '../../evt';

const INDETERMINATE_CLASSNAME = 'mdc-linear-progress--indeterminate';

interface LinearProgressOpts extends ElObjOpts {
	indeterminate: boolean;
}

@OBJ
export class LinearProgress extends ElObj {
	private ctrl: MDCLinearProgress;

	constructor(opts: Partial<LinearProgressOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['role', 'progressbar'],
			['aria-label', 'Progress bar'],
			['aria-valuemin', '0'],
			['aria-valuemax', '1'],
			['aria-valuenow', '0'],
		);
		const classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-linear-progress',
			'mdc-linear-progress--closed',
			'lb-linear-progress',
		);
		opts.dd = opts.dd || new ElObjPrivate();
		opts.dd.temporarilyDoNotApplyTheHiddenCssClass = true;
		if (opts.indeterminate) {
			classNames.unshift(INDETERMINATE_CLASSNAME);
		}
		opts.classNames = classNames;
		super(opts);
		// this.vis = false;
		const buf = new ElObj({
			classNames: 'mdc-linear-progress__buffer',
			parent: this,
			tagName: 'div',
		});
		buf.show();
		let obj = new ElObj({
			classNames: 'mdc-linear-progress__buffer-bar',
			parent: buf,
			tagName: 'div',
		});
		obj.show();
		obj = new ElObj({
			classNames: 'mdc-linear-progress__buffer-dots',
			parent: buf,
			tagName: 'div',
		});
		obj.show();
		const primary = new ElObj({
			classNames: [
				'mdc-linear-progress__bar',
				'mdc-linear-progress__primary-bar',
			],
			parent: this,
			tagName: 'div',
		});
		primary.show();
		obj = new ElObj({
			classNames: 'mdc-linear-progress__bar-inner',
			parent: primary,
			tagName: 'span',
		});
		obj.show();
		const secondary = new ElObj({
			classNames: [
				'mdc-linear-progress__bar',
				'mdc-linear-progress__secondary-bar',
			],
			parent: this,
			tagName: 'div',
		});
		secondary.show();
		obj = new ElObj({
			classNames: 'mdc-linear-progress__bar-inner',
			parent: secondary,
			tagName: 'span',
		});
		obj.show();
		this.ctrl = new MDCLinearProgress(<HTMLElement>this.element());
		if (opts.indeterminate !== undefined) {
			this.setIndeterminate(opts.indeterminate);
		}
	}

	destroy(): void {
		try {
			this.hide();
		} catch {
		}
		this.ctrl.destroy();
		super.destroy();
	}

	protected hideEvent(event: HideEvt): void {
		super.hideEvent(event);
		this.ctrl.close();
	}

	isIndeterminate(): boolean {
		return this.hasClass(INDETERMINATE_CLASSNAME);
	}

	setIndeterminate(indeterminate: boolean): void {
		this.ctrl.determinate = !indeterminate;
	}

	protected showEvent(event: ShowEvt): void {
		super.showEvent(event);
		this.ctrl.open();
	}
}
