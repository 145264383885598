import {AbstractItemView, AbstractItemViewOpts, AbstractItemViewPrivate} from './abstractitemview';
import {OBJ, PROP, SLOT} from '../../obj';
import {AbstractItemModelPrivate, AbstractTableModel, ModelIndex, PersistentModelIndex} from '../../abstractitemmodel';
import {list} from '../../tools';
import {CheckIndexOption, CheckState, ItemDataRole, ItemFlag, LayoutChangeHint, Orientation, SelectionFlag, SortOrder} from '../../constants';
import {Variant} from '../../variant';
import {divmod, isNumber, lowerBound} from '../../util';
import {ItemData} from './itemdata';
import {getLogger} from '../../logging';
import {HeaderView} from './headerview';

const logger = getLogger('ui.itemviews.tableview');

export class TableViewPrivate extends AbstractItemViewPrivate {
	horizontalHeader: HeaderView;
	verticalHeader: HeaderView;

	constructor() {
		super();
		this.horizontalHeader = new HeaderView();
		this.verticalHeader = new HeaderView();
	}

	init(opts: Partial<TableViewOpts>): void {
		super.init(opts);
		this.horizontalHeader.show();
		this.verticalHeader.show();
		const q = <TableView>this.q;
		const dim: Partial<{rows: number; columns: number;}> = opts.dimensions || {};
		q.setModel(new TableModel(dim.rows || 0, dim.columns || 0, q));
	}

	get q(): TableView {
		return <TableView>super.q;
	}

	tableModel(): TableModel {
		return <TableModel>this.model;
	}
}

export interface TableViewOpts extends AbstractItemViewOpts {
	dd: TableViewPrivate;
	dimensions: {rows: number; columns: number;};
}

@OBJ
export class TableView extends AbstractItemView {
	constructor(opts: Partial<TableViewOpts> = {}) {
		opts.dd = opts.dd || new TableViewPrivate();
		super(opts);
	}

	@SLOT
	clearContents(): void {
		this.d.tableModel().clearContents();
	}

	column(item: TableItem): number {
		return this.d.tableModel().index(item).column();
	}

	@PROP({WRITE: 'setColumnCount'})
	columnCount(): number {
		return this.d.model.columnCount();
	}

	get d(): TableViewPrivate {
		return <TableViewPrivate>super.d;
	}

	item(row: number, column: number): TableItem | null {
		return this.d.tableModel().item(row, column);
	}

	@SLOT
	removeRow(row: number): void {
		this.d.tableModel().removeRows(row);
	}

	row(item: TableItem): number {
		return this.d.tableModel().index(item).row();
	}

	@PROP({WRITE: 'setRowCount'})
	rowCount(): number {
		return this.d.model.rowCount();
	}

	setColumnCount(count: number): void {
		this.d.tableModel().setColumnCount(count);
	}

	setHorizontalHeaderItem(column: number, item: TableItem | null): void {
		if (item) {
			item.view = this;
			this.d.tableModel().setHorizontalHeaderItem(column, item);
		} else {
			const obj = this.takeHorizontalHeaderItem(column);
			if (obj) {
				obj.destroy();
			}
		}
	}

	setHorizontalHeaderLabels(labels: Iterable<string>): void {
		const model = this.d.tableModel();
		let item: TableItem | null = null;
		const lbls = (labels instanceof list) ? labels : new list(labels);
		for (let i = 0; (i < model.columnCount()) && (i < lbls.size()); ++i) {
			item = model.horizontalHeaderItem(i);
			if (!item) {
				item = model.createItem();
				this.setHorizontalHeaderItem(i, item);
			}
			item.setText(lbls.at(i));
		}
	}

	setItem(row: number, column: number, item: TableItem | null): void {
		// Sets the item for the given row and column to item.
		//
		// The table takes ownership of the item.
		//
		// Note: If sorting is enabled and column is the current sort column,
		// the row will be moved to the sorted position determined by item.
		//
		// If you want to set several items of a particular row (say, by
		// calling setItem() in a loop), you may want to turn off sorting
		// before doing so, and turn it back on afterwards; this will allow
		// you to use the same row argument for all items in the same row.
		const d = this.d;
		if (item) {
			if (item.view) {
				logger.warning('Tableview: cannot insert an item that is already owned by another TableView.');
			} else {
				item.view = this;
				d.tableModel().setItem(row, column, item);
			}
		} else {
			const obj = this.takeItem(row, column);
			if (obj) {
				obj.destroy();
			}
		}
	}

	setRowCount(count: number): void {
		this.d.tableModel().setRowCount(count);
	}

	takeHorizontalHeaderItem(column: number): TableItem | null {
		const itm = this.d.tableModel().takeHorizontalHeaderItem(column);
		if (itm) {
			itm.view = null;
		}
		return itm;
	}

	takeItem(row: number, column: number): TableItem | null {
		const d = this.d;
		const item = d.tableModel().takeItem(row, column);
		if (item) {
			item.view = null;
		}
		return item;
	}
}

class TableItemPrivate {
	id: number;
	q: TableItem;

	constructor(item: TableItem) {
		this.id = -1;
		this.q = item;
	}
}

export class TableItem {
	d: TableItemPrivate;
	itemFlags: ItemFlag;
	values: list<ItemData>;
	view: TableView | null;

	constructor(other?: TableItem) {
		this.view = null;
		if (other) {
			this.itemFlags = other.itemFlags;
			this.values = new list(other.values);
		} else {
			this.itemFlags = ItemFlag.ItemIsEditable | ItemFlag.ItemIsSelectable | ItemFlag.ItemIsUserCheckable | ItemFlag.ItemIsEnabled | ItemFlag.ItemIsDragEnabled | ItemFlag.ItemIsDropEnabled;
			this.values = new list();
		}
		this.d = new TableItemPrivate(this);
	}

	background(): string {
		return this.data(ItemDataRole.BackgroundRole).toString();
	}

	checkState(): CheckState {
		return this.data(ItemDataRole.CheckStateRole).toNumber();
	}

	clone(): TableItem {
		return new TableItem(this);
	}

	column(): number {
		return this.view ? this.view.column(this) : -1;
	}

	data(role: number): Variant {
		role = (role == ItemDataRole.EditRole ? ItemDataRole.DisplayRole : role);
		for (const value of this.values) {
			if (value.role === role) {
				return value.value;
			}
		}
		return new Variant();
	}

	destroy(): void {
		const model = this.tableModel();
		if (model) {
			model.removeItem(this);
		}
		this.d.id = -1;
	}

	flags(): ItemFlag {
		return this.itemFlags;
	}

	foreground(): string {
		return this.data(ItemDataRole.ForegroundRole).toString();
	}

	icon(): string {
		return this.data(ItemDataRole.DecorationRole).toString();
	}

	isSelected(): boolean {
		if (!this.view) {
			return false;
		}
		const mdl = this.view.d.tableModel();
		const sel = this.view.selectionModel();
		if (!mdl || !sel) {
			return false;
		}
		return sel.isSelected(
			mdl.index(this),
		);
	}

	lt(other: TableItem): boolean {
		return AbstractItemModelPrivate.variantLessThan(
			this.data(ItemDataRole.DisplayRole),
			other.data(ItemDataRole.DisplayRole));
	}

	row(): number {
		return this.view ? this.view.row(this) : -1;
	}

	setBackground(background: string): void {
		this.setData(
			ItemDataRole.BackgroundRole,
			new Variant(background));
	}

	setCheckState(state: CheckState): void {
		this.setData(
			ItemDataRole.CheckStateRole,
			new Variant(state));
	}

	setData(role: number, value: Variant): void {
		let found: boolean = false;
		role = ((role === ItemDataRole.EditRole) ?
			ItemDataRole.DisplayRole :
			role);
		for (let i = 0; i < this.values.size(); ++i) {
			if (this.values.at(i).role === role) {
				if (this.values.at(i).value.eq(value)) {
					return;
				}
				this.values.at(i).value = value;
				found = true;
				break;
			}
		}
		if (!found) {
			this.values.append(new ItemData(role, value));
		}
		const model = this.tableModel();
		if (model) {
			const roles = (role === ItemDataRole.DisplayRole) ? [ItemDataRole.DisplayRole, ItemDataRole.EditRole] : [role];
			model.itemChanged(this, roles);
		}
	}

	setFlags(flags: ItemFlag): void {
		this.itemFlags = flags;
		const model = this.tableModel();
		if (model) {
			model.itemChanged(this);
		}
	}

	setForeground(foreground: string): void {
		this.setData(ItemDataRole.ForegroundRole, (foreground.length > 0) ? new Variant(foreground) : new Variant());
	}

	setIcon(icon: string): void {
		this.setData(ItemDataRole.DecorationRole, (icon.length > 0) ? new Variant(icon) : new Variant());
	}

	setSelected(selected: boolean): void {
		if (!this.view) {
			return;
		}
		const mdl = this.view.d.tableModel();
		const sel = this.view.selectionModel();
		if (!mdl || !sel) {
			return;
		}
		sel.select(
			mdl.index(this),
			selected ?
				SelectionFlag.Select :
				SelectionFlag.Deselect,
		);
	}

	setText(text: string): void {
		this.setData(ItemDataRole.DisplayRole, new Variant(text));
	}

	setTextAlignment(alignment: number): void {
		this.setData(ItemDataRole.TextAlignmentRole, new Variant(alignment));
	}

	private tableModel(): TableModel | null {
		return this.view && this.view.d.tableModel();
	}

	tableView(): TableView | null {
		return this.view;
	}

	text(): string {
		return this.data(ItemDataRole.DisplayRole).toString();
	}

	textAlignment(): number {
		return this.data(ItemDataRole.TextAlignmentRole).toNumber();
	}
}

const ItemIsHeaderItem = 128;

@OBJ
class TableModel extends AbstractTableModel {
	static itemCmp(left: Pair<TableItem, number>, right: Pair<TableItem, number>): number {
		if (left[0].lt(right[0])) {
			return -1;
		}
		if (right[0].lt(left[0])) {
			return 1;
		}
		return 0;
	}

	static itemCmpDesc(left: Pair<TableItem, number>, right: Pair<TableItem, number>): number {
		if (left[0].lt(right[0])) {
			return 1;
		}
		if (right[0].lt(left[0])) {
			return -1;
		}
		return 0;
	}

	static itemLessThan(left: Pair<TableItem, number>, right: Pair<TableItem, number>): boolean {
		return left[0].lt(right[0]);
	}

	static itemGreaterThan(left: Pair<TableItem, number>, right: Pair<TableItem, number>): boolean {
		return right[0].lt(left[0]);
	}

	private horizontalHeaderItems: list<TableItem | null>;
	private proto: TableItem | null;
	private tableItems: list<TableItem | null>;
	private verticalHeaderItems: list<TableItem | null>;

	constructor(rows: number, columns: number, parent: TableView) {
		super({parent});
		this.horizontalHeaderItems = new list<TableItem | null>(columns, null);
		this.proto = null;
		this.tableItems = new list<TableItem | null>(rows * columns, null);
		this.verticalHeaderItems = new list<TableItem | null>(rows, null);
	}

	clear(): void {
		for (let j = 0; j < this.verticalHeaderItems.size(); ++j) {
			const itm = this.verticalHeaderItems.at(j);
			if (itm) {
				itm.view = null;
				itm.destroy();
				this.verticalHeaderItems.replace(j, null);
			}
		}
		for (let k = 0; k < this.horizontalHeaderItems.size(); ++k) {
			const itm = this.horizontalHeaderItems.at(k);
			if (itm) {
				itm.view = null;
				itm.destroy();
				this.horizontalHeaderItems.replace(k, null);
			}
		}
		this.clearContents();
	}

	clearContents(): void {
		this.beginResetModel();
		for (let i = 0; i < this.tableItems.size(); ++i) {
			const itm = this.tableItems.at(i);
			if (itm) {
				itm.view = null;
				itm.destroy();
				this.tableItems.replace(i, null);
			}
		}
		this.endResetModel();
	}

	clearItemData(index: ModelIndex): boolean {
		if (!this.checkIndex(index, CheckIndexOption.IndexIsValid)) {
			return false;
		}
		const itm = this.item(index);
		if (!itm) {
			return false;
		}
		let anyValid: boolean = false;
		for (const val of itm.values) {
			if (val.value.isValid()) {
				anyValid = true;
				break;
			}
		}
		if (!anyValid) {
			return true; //it's already cleared
		}
		itm.values.clear();
		this.dataChanged(index, index, []);
		return true;
	}

	columnCount(parent?: ModelIndex | PersistentModelIndex): number {
		return (parent && parent.isValid()) ?
			0 :
			this.horizontalHeaderItems.size();
	}

	columnItems(column: number): list<TableItem> {
		const items = new list<TableItem>();
		const rc = this.rowCount();
		for (let row = 0; row < rc; ++row) {
			const itm = this.item(row, column);
			if (itm === null) {
				// no more sortable items (all 0-items are
				// at the end of the table when it is sorted)
				break;
			}
			items.append(itm);
		}
		return items;
	}

	createItem(): TableItem {
		return this.proto ? this.proto.clone() : new TableItem();
	}

	data(index: ModelIndex, role: ItemDataRole = ItemDataRole.DisplayRole): Variant {
		const itm = this.item(index);
		if (itm) {
			return itm.data(role);
		}
		return new Variant();
	}

	destroy(): void {
		this.clear();
		if (this.proto) {
			this.proto.destroy();
		}
		this.proto = null;
		super.destroy();
	}

	ensureSorted(column: number, order: SortOrder, start: number, end: number): void {
		// Ensures that rows in the interval [start, end] are sorted according
		// to the contents of column and the given sort order.
		const count = end - start + 1;
		const sorting = new list<Pair<TableItem, number>>();
		for (let row = start; row <= end; ++row) {
			const itm = this.item(row, column);
			if (itm === null) {
				// no more sortable items (all 0-items are at the end of the
				// table when it is sorted)
				break;
			}
			sorting.append([itm, row]);
		}
		const compare = (order == SortOrder.AscendingOrder ? TableModel.itemCmp : TableModel.itemCmpDesc);
		sorting.sort(compare);
		let oldPersistentIndexes = new list<ModelIndex>();
		let newPersistentIndexes = new list<ModelIndex>();
		const newTable = new list<TableItem | null>(this.tableItems);
		const newVertical = new list<TableItem | null>(this.verticalHeaderItems);
		const colItems = new list<TableItem>(this.columnItems(column));
		let vit = 0;
		let distanceFromBegin = 0;
		let changed = false;
		for (let i = 0; i < sorting.size(); ++i) {
			distanceFromBegin = vit;
			const oldRow = sorting.at(i)[1];
			const item = colItems.at(oldRow);
			colItems.remove(oldRow);
			const colItemsArr = colItems.toArray();
			const colItemsSlice = colItemsArr.slice(distanceFromBegin);
			vit = sortedInsertionIterator(colItemsSlice, order, item);
			let newRow = Math.max(vit, 0);
			if ((newRow < oldRow) && !(item.lt(colItems.at(oldRow - 1))) && !(colItems.at(oldRow - 1).lt(item))) {
				newRow = oldRow;
			}
			colItems.insert(vit, item);
			if (newRow !== oldRow) {
				if (!changed) {
					this.layoutAboutToBeChanged([], LayoutChangeHint.VerticalSortHint);
					oldPersistentIndexes = new list(this.persistentIndexList());
					newPersistentIndexes = new list(oldPersistentIndexes);
					changed = true;
				}
				// move the items @ oldRow to newRow
				const cc = this.columnCount();
				const rowItems = new list<TableItem | null>(cc, null);
				for (let j = 0; j < cc; ++j) {
					rowItems.replace(j, newTable.at(this.tableIndex(oldRow, j)));
				}
				newTable.remove(this.tableIndex(oldRow, 0), cc);
				newTable.insert(this.tableIndex(newRow, 0), cc, null);
				for (let j = 0; j < cc; ++j) {
					newTable.replace(this.tableIndex(newRow, j), rowItems.at(j));
				}
				const header = newVertical.at(oldRow);
				newVertical.remove(oldRow);
				newVertical.insert(newRow, header);
				// update persistent indexes
				this.updateRowIndexes(newPersistentIndexes, oldRow, newRow);
				// the index of the remaining rows may have changed
				for (let j = i + 1; j < sorting.size(); ++j) {
					const otherRow = sorting.at(j)[1];
					if ((oldRow < otherRow) && (newRow >= otherRow)) {
						--sorting.at(j)[1];
					} else if (oldRow > otherRow && newRow <= otherRow) {
						++sorting.at(j)[1];
					}
				}
			}
		}
		if (changed) {
			this.tableItems = new list(newTable);
			this.verticalHeaderItems = new list(newVertical);
			this.changePersistentIndexList(oldPersistentIndexes, newPersistentIndexes);
			this.layoutChanged([], LayoutChangeHint.VerticalSortHint);
		}
	}

	flags(index: ModelIndex): ItemFlag {
		if (!index.isValid()) {
			return ItemFlag.ItemIsDropEnabled;
		}
		const itm = this.item(index);
		if (itm) {
			return itm.flags();
		}
		return (ItemFlag.ItemIsEditable
			| ItemFlag.ItemIsSelectable
			| ItemFlag.ItemIsUserCheckable
			| ItemFlag.ItemIsEnabled
			| ItemFlag.ItemIsDragEnabled
			| ItemFlag.ItemIsDropEnabled);
	}

	headerData(section: number, orientation: Orientation, role: number): Variant {
		if (section < 0) {
			return new Variant();
		}
		let itm: TableItem | null = null;
		if ((orientation === Orientation.Horizontal) && (section < this.horizontalHeaderItems.size())) {
			itm = this.horizontalHeaderItems.at(section);
		} else if ((orientation === Orientation.Vertical) && (section < this.verticalHeaderItems.size())) {
			itm = this.verticalHeaderItems.at(section);
		} else {
			return new Variant(); // section is out of bounds
		}
		if (itm) {
			return itm.data(role);
		}
		if (role === ItemDataRole.DisplayRole) {
			return new Variant(section + 1);
		}
		return new Variant();
	}

	horizontalHeaderItem(section: number): TableItem | null {
		if ((section >= 0) && (section < this.horizontalHeaderItems.size())) {
			return this.horizontalHeaderItems.at(section);
		}
		return null;
	}

	index(row: number, column: number, parent?: ModelIndex): ModelIndex;
	index(item: TableItem | null): ModelIndex;
	index(a: TableItem | number | null, b?: number, parent?: ModelIndex): ModelIndex {
		let item: TableItem | null = null;
		if (isNumber(a) && isNumber(b)) {
			return this.createIndex(
				a,
				b,
				this.item(
					super.index(
						a,
						b,
						parent,
					),
				),
			);
		} else {
			item = <TableItem | null>a;
			if (!item) {
				return new ModelIndex();
			}
			let i: number;
			const id = item.d.id;
			if ((id >= 0) && (id < this.tableItems.size()) && (this.tableItems.at(id) === item)) {
				i = id;
			} else {
				// we need to search for the item
				i = this.tableItems.indexOf(item);
				if (i === -1) {
					// not found
					return new ModelIndex();
				}
			}
			return this.createIndex(
				...divmod(
					i,
					this.columnCount(),
				),
				item,
			);
		}
	}

	insertColumns(index: number, count: number = 1, parent?: ModelIndex): boolean {
		if ((count < 1) || (index < 0) || (index > this.horizontalHeaderItems.size())) {
			return false;
		}
		this.beginInsertColumns(new ModelIndex(), index, index + count - 1);
		const rowCount = this.verticalHeaderItems.size();
		const columnCount = this.horizontalHeaderItems.size();
		this.horizontalHeaderItems.insert(index, count, null);
		if (columnCount === 0) {
			this.tableItems.resize(rowCount * count, null);
		} else {
			for (let row = 0; row < rowCount; ++row) {
				this.tableItems.insert(this.tableIndex(row, index), count, null);
			}
		}
		this.endInsertColumns();
		return true;
	}

	insertRows(index: number, count: number = 1, parent?: ModelIndex): boolean {
		if ((count < 1) || (index < 0) || (index > this.verticalHeaderItems.size())) {
			return false;
		}
		this.beginInsertRows(new ModelIndex(), index, index + count - 1);
		const rowCount = this.verticalHeaderItems.size();
		const columnCount = this.horizontalHeaderItems.size();
		this.verticalHeaderItems.insert(index, count, null);
		if (rowCount === 0) {
			this.tableItems.resize(columnCount * count, null);
		} else {
			this.tableItems.insert(this.tableIndex(index, 0), columnCount * count, null);
		}
		this.endInsertRows();
		return true;
	}

	isValid(index: ModelIndex): boolean {
		return (index.isValid() && (index.row() < this.verticalHeaderItems.size()) && (index.column() < this.horizontalHeaderItems.size()));
	}

	item(row: number, column: number): TableItem | null;
	item(index: ModelIndex | PersistentModelIndex): TableItem | null;
	item(a: ModelIndex | PersistentModelIndex | number, b?: number): TableItem | null {
		if (isNumber(a) && isNumber(b)) {
			return this.item(this.index(a, b));
		} else {
			const index = <ModelIndex>a;
			if (!this.isValid(index)) {
				return null;
			}
			return this.tableItems.at(this.tableIndex(index.row(), index.column()));
		}
	}

	itemChanged(item: TableItem | null, roles?: Array<number>): void {
		if (!item) {
			return;
		}
		if (item.flags() & ItemIsHeaderItem) {
			const row = this.verticalHeaderItems.indexOf(item);
			if (row >= 0) {
				this.headerDataChanged(Orientation.Vertical, row, row);
			} else {
				const column = this.horizontalHeaderItems.indexOf(item);
				if (column >= 0) {
					this.headerDataChanged(Orientation.Horizontal, column, column);
				}
			}
		} else {
			const idx = this.index(item);
			if (idx.isValid()) {
				this.dataChanged(idx, idx, roles);
			}
		}
	}

	itemData(index: ModelIndex): Map<number, Variant> {
		const roles = new Map<number, Variant>();
		const itm = this.item(index);
		if (itm) {
			for (let i = 0; i < itm.values.size(); ++i) {
				roles.set(itm.values.at(i).role, itm.values.at(i).value);
			}
		}
		return roles;
	}

	itemPrototype(): TableItem | null {
		return this.proto;
	}

	removeColumns(index: number, count: number = 1, parent?: ModelIndex): boolean {
		if ((count < 1) || (index < 0) || ((index + count) > this.horizontalHeaderItems.size())) {
			return false;
		}
		this.beginRemoveColumns(new ModelIndex(), index, index + count - 1);
		let oldItem: TableItem | null = null;
		for (let row = this.rowCount() - 1; row >= 0; --row) {
			const i = this.tableIndex(row, index);
			for (let j = i; j < (i + count); ++j) {
				oldItem = this.tableItems.at(j);
				if (oldItem) {
					oldItem.view = null;
					oldItem.destroy();
				}
			}
			this.tableItems.remove(i, count);
		}
		for (let h = index; h < (index + count); ++h) {
			oldItem = this.horizontalHeaderItems.at(h);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
		}
		this.horizontalHeaderItems.remove(index, count);
		this.endRemoveColumns();
		return true;
	}

	removeItem(item: TableItem): void {
		let i = this.tableItems.indexOf(item);
		if (i !== -1) {
			const idx = this.index(item);
			this.tableItems.replace(i, null);
			this.dataChanged(idx, idx);
			return;
		}
		i = this.verticalHeaderItems.indexOf(item);
		if (i !== -1) {
			this.verticalHeaderItems.replace(i, null);
			this.headerDataChanged(Orientation.Vertical, i, i);
			return;
		}
		i = this.horizontalHeaderItems.indexOf(item);
		if (i !== -1) {
			this.horizontalHeaderItems.replace(i, null);
			this.headerDataChanged(Orientation.Horizontal, i, i);
			return;
		}
	}

	removeRows(index: number, count: number = 1, parent?: ModelIndex): boolean {
		if ((count < 1) || (index < 0) || ((index + count) > this.verticalHeaderItems.size())) {
			return false;
		}
		this.beginRemoveRows(new ModelIndex(), index, index + count - 1);
		const i = this.tableIndex(index, 0);
		const n = count * this.columnCount();
		let oldItem: TableItem | null = null;
		for (let j = i; j < (n + i); ++j) {
			oldItem = this.tableItems.at(j);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
		}
		this.tableItems.remove(Math.max(i, 0), n);
		for (let v = index; v < (index + count); ++v) {
			oldItem = this.verticalHeaderItems.at(v);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
		}
		this.verticalHeaderItems.remove(index, count);
		this.endRemoveRows();
		return true;
	}

	rowCount(parent?: ModelIndex): number {
		return (parent && parent.isValid()) ?
			0 :
			this.verticalHeaderItems.size();
	}

	setColumnCount(count: number): void {
		const cc = this.horizontalHeaderItems.size();
		if ((count < 0) || (cc === count)) {
			return;
		}
		if (cc < count) {
			this.insertColumns(Math.max(cc, 0), count - cc);
		} else {
			this.removeColumns(Math.max(count, 0), cc - count);
		}
	}

	setData(index: ModelIndex, value: Variant, role: number): boolean {
		if (!index.isValid()) {
			return false;
		}
		let itm = this.item(index);
		if (itm) {
			itm.setData(role, value);
			return true;
		}
		// don't create dummy table items for empty values
		if (!value.isValid()) {
			return false;
		}
		const view = <TableView | null>this.parent();
		if (!view) {
			return false;
		}
		itm = this.createItem();
		itm.setData(role, value);
		view.setItem(index.row(), index.column(), itm);
		return true;
	}

	setHeaderData(section: number, orientation: Orientation, value: Variant, role: number): boolean {
		if ((section < 0) || ((orientation === Orientation.Horizontal) && (this.horizontalHeaderItems.size() <= section)) || ((orientation === Orientation.Vertical) && (this.verticalHeaderItems.size() <= section))) {
			return false;
		}
		let itm: TableItem | null;
		if (orientation === Orientation.Horizontal) {
			itm = this.horizontalHeaderItems.at(section);
		} else {
			itm = this.verticalHeaderItems.at(section);
		}
		if (itm) {
			itm.setData(role, value);
			return true;
		}
		return false;
	}

	private setHeaderItem(section: number, item: TableItem, items: list<TableItem | null>, orientation: Orientation): void {
		if ((section < 0) || (section >= items.size())) {
			return;
		}
		const oldItem = items.at(section);
		if (item === oldItem) {
			return;
		}
		if (oldItem) {
			oldItem.view = null;
			oldItem.destroy();
		}
		const view = <TableView | null>this.parent();
		if (item) {
			item.view = view;
			item.itemFlags = item.itemFlags | ItemIsHeaderItem;
		}
		items.replace(section, item);
		this.headerDataChanged(orientation, section, section);
	}

	setHorizontalHeaderItem(section: number, item: TableItem): void {
		this.setHeaderItem(
			section,
			item,
			this.horizontalHeaderItems,
			Orientation.Horizontal);
	}

	setItem(row: number, column: number, item: TableItem): void {
		const i = this.tableIndex(row, column);
		if ((i < 0) || (i >= this.tableItems.size())) {
			return;
		}
		let oldItem: TableItem | null = this.tableItems.at(i);
		if (item === oldItem) {
			return;
		}
		if (oldItem) {
			oldItem.view = null;
			oldItem.destroy();
		}
		if (item) {
			item.d.id = i;
		}
		this.tableItems.replace(i, item);
		const idx = super.index(row, column);
		this.dataChanged(idx, idx);
	}

	setItemData(index: ModelIndex, roles: Map<number, Variant>): boolean {
		if (!index.isValid()) {
			return false;
		}
		const view = <TableView | null>this.parent();
		let itm = this.item(index);
		if (itm) {
			itm.view = null; // prohibits item from calling itemChanged()
			const rolesVec = new list<number>();
			for (const [k, value] of roles) {
				const role = (k === ItemDataRole.EditRole) ?
					ItemDataRole.DisplayRole :
					k;
				if (itm.data(role).ne(value)) {
					itm.setData(role, value);
					rolesVec.append(role);
					if (role === ItemDataRole.DisplayRole) {
						rolesVec.append(ItemDataRole.EditRole);
					}
				}
			}
			itm.view = view;
			if (!rolesVec.isEmpty()) {
				this.itemChanged(itm, rolesVec.toArray());
			}
			return true;
		}
		if (!view) {
			return false;
		}
		itm = this.createItem();
		for (const [k, v] of roles) {
			itm.setData(k, v);
		}
		view.setItem(index.row(), index.column(), itm);
		return true;
	}

	setItemPrototype(item: TableItem): void {
		if (this.proto !== item) {
			if (this.proto) {
				this.proto.destroy();
			}
			this.proto = item;
		}
	}

	setRowCount(count: number): void {
		const rc = this.verticalHeaderItems.size();
		if ((count < 0) || (rc === count)) {
			return;
		}
		if (rc < count) {
			this.insertRows(Math.max(rc, 0), count - rc);
		} else {
			this.removeRows(Math.max(count, 0), rc - count);
		}
	}

	setVerticalHeaderItem(section: number, item: TableItem): void {
		this.setHeaderItem(
			section,
			item,
			this.verticalHeaderItems,
			Orientation.Vertical);
	}

	sort(column: number, order: SortOrder): void {
		const sortable = new list<Pair<TableItem, number>>();
		const unsortable = new list<number>();
		for (let row = 0; row < this.rowCount(); ++row) {
			const itm = this.item(row, column);
			if (itm) {
				sortable.append([itm, row]);
			} else {
				unsortable.append(row);
			}
		}
		const compare = (order == SortOrder.AscendingOrder ? TableModel.itemCmp : TableModel.itemCmpDesc);
		sortable.sort(compare);
		const sortedTable = new list<TableItem | null>(this.tableItems.size(), null);
		const from = new list<ModelIndex>();
		const to = new list<ModelIndex>();
		const numRows = this.rowCount();
		const numColumns = this.columnCount();
		for (let i = 0; i < numRows; ++i) {
			const r = (i < sortable.size() ?
				sortable.at(i)[1] :
				unsortable.at(i - sortable.size()));
			for (let c = 0; c < numColumns; ++c) {
				sortedTable.replace(this.tableIndex(i, c), this.item(r, c));
				from.append(this.createIndex(r, c));
				to.append(this.createIndex(i, c));
			}
		}
		this.layoutAboutToBeChanged([], LayoutChangeHint.VerticalSortHint);
		this.tableItems = new list(sortedTable);
		this.changePersistentIndexList(from, to); // ### slow
		this.layoutChanged([], LayoutChangeHint.VerticalSortHint);
	}

	private takeHeaderItem(section: number, items: list<TableItem | null>): TableItem | null {
		if ((section < 0) || (section >= items.size())) {
			return null;
		}
		const item = items.at(section);
		if (item) {
			item.view = null;
			item.itemFlags &= ~ItemIsHeaderItem;
			items.replace(section, null);
		}
		return item;
	}

	tableIndex(row: number, column: number): number {
		return (row * this.horizontalHeaderItems.size()) + column;
	}

	takeHorizontalHeaderItem(section: number): TableItem | null {
		return this.takeHeaderItem(section, this.horizontalHeaderItems);
	}

	takeItem(row: number, column: number): TableItem | null {
		const i = this.tableIndex(row, column);
		const itm = ((i >= 0) && (i < this.tableItems.size())) ? this.tableItems.at(i) : null;
		if (itm) {
			itm.view = null;
			itm.d.id = -1;
			this.tableItems.replace(i, null);
			const ind = this.index(row, column);
			this.dataChanged(ind, ind);
		}
		return itm;
	}

	takeVerticalHeaderItem(section: number): TableItem | null {
		return this.takeHeaderItem(section, this.verticalHeaderItems);
	}

	updateRowIndexes(indices: list<ModelIndex>, movedFromRow: number, movedToRow: number): void {
		for (let i = 0; i < indices.size(); ++i) {
			const oldRow = indices.at(i).row();
			let newRow = oldRow;
			if (oldRow === movedFromRow) {
				newRow = movedToRow;
			} else if ((movedFromRow < oldRow) && (movedToRow >= oldRow)) {
				newRow = oldRow - 1;
			} else if ((movedFromRow > oldRow) && (movedToRow <= oldRow)) {
				newRow = oldRow + 1;
			}
			if (newRow !== oldRow) {
				indices.replace(i, this.index(newRow, indices.at(i).column(), indices.at(i).parent()));
			}
		}
	}

	verticalHeaderItem(section: number): TableItem | null {
		if ((section >= 0) && (section < this.verticalHeaderItems.size())) {
			return this.verticalHeaderItems.at(section);
		}
		return null;
	}
}

function sortedInsertionIterator(items: Array<TableItem>, order: SortOrder, item: TableItem): number {
	if (order === SortOrder.AscendingOrder) {
		return lowerBound(items, item, tableModelLessThan);
	}
	return lowerBound(items, item, tableModelGreaterThan);
}

function tableModelGreaterThan(a: TableItem, b: TableItem): boolean {
	return b.lt(a);
}

function tableModelLessThan(a: TableItem, b: TableItem): boolean {
	return a.lt(b);
}
