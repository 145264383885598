import {GeoCoordinate} from './geocoordinate';
import {getLogger} from '../logging';

const logger = getLogger('tools.georectangle');

export enum ShapeType {
	UnknownType,
	RectangleType,
	CircleType,
	PathType,
	PolygonType,
}

export class GeoShapePrivate {
	m_type: ShapeType;

	constructor(shapeType: ShapeType) {
		this.m_type = shapeType;
	}

	boundingGeoRectangle(): GeoRectangle {
		return new GeoRectangle();
	}

	center(): GeoCoordinate {
		return new GeoCoordinate();
	}

	contains(obj: GeoRectangle | GeoCoordinate): boolean {
		return false;
	}

	equal(other?: GeoShapePrivate): boolean {
		if (!other) {
			return false;
		}
		return this.m_type === other.m_type;
	}

	isEmpty(): boolean {
		return true;
	}

	isValid(): boolean {
		return false;
	}
}

export class GeoShape {
	d?: GeoShapePrivate;

	constructor(other?: GeoShape) {
		this.d = undefined;
		if (other) {
			this.d = other.d_func();
		}
	}

	boundingGeoRectangle(): GeoRectangle {
		const d = this.d_func();
		if (d) {
			return d.boundingGeoRectangle();
		}
		return new GeoRectangle();
	}

	center(): GeoCoordinate {
		const d = this.d_func();
		if (d) {
			return d.center();
		}
		return new GeoCoordinate();
	}

	contains(obj: GeoRectangle | GeoCoordinate): boolean {
		const d = this.d_func();
		if (d) {
			return d.contains(obj);
		}
		return false;
	}

	d_func(): GeoShapePrivate | undefined {
		return this.d;
	}

	equal(other: GeoShape): boolean {
		const d = this.d_func();
		if (d === other.d_func()) {
			return true;
		}
		if (!d || !other.d_func()) {
			return false;
		}
		return d.equal(other.d_func());
	}

	extendShape(coordinate: GeoCoordinate): void {
	}

	isEmpty(): boolean {
		const d = this.d_func();
		if (d) {
			return d.isEmpty();
		}
		return true;
	}

	isValid(): boolean {
		const d = this.d_func();
		if (d) {
			return d.isValid();
		}
		return false;
	}

	notEqual(other: GeoShape): boolean {
		return !this.equal(other);
	}

	toString(): string {
		return `GeoShape(${this.type()})`;
	}

	type(): ShapeType {
		const d = this.d_func();
		if (d) {
			return d.m_type;
		}
		return ShapeType.UnknownType;
	}
}

function wrapLong(longitude: number): number {
	if (longitude > 180.0) {
		longitude -= 360.0;
	} else if (longitude < -180.0) {
		longitude += 360.0;
	}
	return longitude;
}

export class GeoRectanglePrivate extends GeoShapePrivate {
	topLeft: GeoCoordinate;
	bottomRight: GeoCoordinate;

	constructor(topLeft?: GeoCoordinate, bottomRight?: GeoCoordinate)
	constructor(other: GeoRectanglePrivate)
	constructor(topLeftOrOther?: GeoCoordinate | GeoRectanglePrivate, bottomRight?: GeoCoordinate) {
		super(ShapeType.RectangleType);
		if (topLeftOrOther instanceof GeoRectanglePrivate) {
			this.topLeft = topLeftOrOther.topLeft;
			this.bottomRight = topLeftOrOther.bottomRight;
		} else if (topLeftOrOther && bottomRight) {
			this.topLeft = topLeftOrOther;
			this.bottomRight = bottomRight;
		} else {
			this.topLeft = new GeoCoordinate();
			this.bottomRight = new GeoCoordinate();
		}

	}

	boundingGeoRectangle(): GeoRectangle {
		return new GeoRectangle(this.topLeft, this.bottomRight);
	}

	center(): GeoCoordinate {
		if (!this.isValid()) {
			return new GeoCoordinate();
		}
		let cLat = (this.topLeft.latitude() + this.bottomRight.latitude()) / 2.0;
		let cLon = (this.bottomRight.longitude() + this.topLeft.longitude()) / 2.0;
		if (this.topLeft.longitude() > this.bottomRight.longitude()) {
			cLon = cLon - 180.0;
		}
		cLon = wrapLong(cLon);
		return new GeoCoordinate(cLat, cLon);
	}

	contains(coordinate: GeoCoordinate): boolean {
		if (!this.isValid() || !coordinate.isValid()) {
			return false;
		}
		let left = this.topLeft.longitude();
		let right = this.bottomRight.longitude();
		let top = this.topLeft.latitude();
		let bottom = this.bottomRight.latitude();
		let lon = coordinate.longitude();
		let lat = coordinate.latitude();
		if (lat > top) {
			return false;
		}
		if (lat < bottom) {
			return false;
		}
		if ((lat === 90.0) && (top === 90.0)) {
			return true;
		}
		if ((lat === -90.0) && (bottom === -90.0)) {
			return true;
		}
		if (left <= right) {
			if ((lon < left) || (lon > right)) {
				return false;
			}
		} else {
			if ((lon < left) && (lon > right)) {
				return false;
			}
		}
		return true;
	}

	equal(other?: GeoShapePrivate): boolean {
		if (!super.equal(other)) {
			return false;
		}
		const otherBox = <GeoRectanglePrivate>other;
		return this.topLeft.eq(otherBox.topLeft) && this.bottomRight.eq(otherBox.bottomRight);
	}

	isEmpty(): boolean {
		if (!this.isValid()) {
			return true;
		}
		return (this.topLeft.latitude() === this.bottomRight.latitude()) || (this.topLeft.longitude() === this.bottomRight.longitude());
	}

	isValid(): boolean {
		return this.topLeft.isValid()
			&& this.bottomRight.isValid()
			&& (this.topLeft.latitude() >= this.bottomRight.longitude());
	}
}

export class GeoRectangle extends GeoShape {
	static fromBBox(bbox: GeoJsonBBox): GeoRectangle {
		const [bottomLeftLon, bottomLeftLat, topRightLon, topRightLat] = bbox;
		const topLeft = new GeoCoordinate(topRightLat, bottomLeftLon);
		const bottomRight = new GeoCoordinate(bottomLeftLat, topRightLon);
		return new GeoRectangle(topLeft, bottomRight);
	}

	static fromBounds(bounds: [GeoCoordinate, GeoCoordinate]): GeoRectangle {
		const [bottomLeft, topRight] = bounds;
		const topLeft = new GeoCoordinate(topRight.latitude(), bottomLeft.longitude());
		const bottomRight = new GeoCoordinate(bottomLeft.latitude(), topRight.longitude());
		return new GeoRectangle(topLeft, bottomRight);
	}

	d: GeoRectanglePrivate;

	constructor(center: GeoCoordinate, degreesWidth: number, degreesHeight: number);
	constructor(topLeft: GeoCoordinate, bottomRight: GeoCoordinate);
	constructor(other: GeoRectangle);
	constructor();
	constructor(...args: any[]) {
		if (args.length === 1) {
			super(args[0]);
		} else {
			super();
		}
		if (args.length === 3) {
			const center = args[0];
			const degreesWidth = args[1];
			const degreesHeight = args[2];
			this.d = new GeoRectanglePrivate(center, center);
			this.setWidth(degreesWidth);
			this.setHeight(degreesHeight);
		} else if (args.length === 2) {
			const topLeft = args[0];
			const bottomRight = args[1];
			this.d = new GeoRectanglePrivate(topLeft, bottomRight);
		} else {
			this.d = new GeoRectanglePrivate();
		}
	}

	bottomLeft(): GeoCoordinate {
		if (!this.isValid()) {
			return new GeoCoordinate();
		}
		const d = this.d_func();
		return new GeoCoordinate(d.bottomRight.latitude(), d.topLeft.longitude());
	}

	bottomRight(): GeoCoordinate {
		const d = this.d_func();
		return d.bottomRight;
	}

	center(): GeoCoordinate {
		const d = this.d_func();
		return d.center();
	}

	contains(rectangle: GeoRectangle): boolean {
		const d = this.d_func();
		return (d.contains(rectangle.topLeft())
			&& d.contains(rectangle.topRight())
			&& d.contains(rectangle.bottomLeft())
			&& d.contains(rectangle.bottomRight()));
	}

	d_func(): GeoRectanglePrivate {
		return this.d;
	}

	equal(other: GeoRectangle): boolean {
		const d = this.d_func();
		return d.equal(other.d_func());
	}

	height(): number {
		if (!this.isValid()) {
			return Number.NaN;
		}
		const d = this.d_func();
		return d.topLeft.latitude() - d.bottomRight.latitude();
	}

	intersects(rectangle: GeoRectangle): boolean {
		const d = this.d_func();
		let left1 = d.topLeft.longitude();
		let right1 = d.bottomRight.longitude();
		let top1 = d.topLeft.latitude();
		let bottom1 = d.bottomRight.latitude();
		let left2 = rectangle.d_func().topLeft.longitude();
		let right2 = rectangle.d_func().bottomRight.longitude();
		let top2 = rectangle.d_func().topLeft.latitude();
		let bottom2 = rectangle.d_func().bottomRight.latitude();
		if (top1 < bottom2) {
			return false;
		}
		if (bottom1 > top2) {
			return false;
		}
		if ((top1 === 90.0) && (top1 === top2)) {
			return true;
		}
		if ((bottom1 === -90.0) && (bottom1 === bottom2)) {
			return true;
		}
		if (left1 < right1) {
			if (left2 < right2) {
				if ((left1 > right2) || (right1 < left2)) {
					return false;
				}
			} else {
				if ((left1 > right2) && (right1 < left2)) {
					return false;
				}
			}
		} else {
			if (left2 < right2) {
				if ((left2 > right1) && (right2 < left1)) {
					return false;
				}
			} else {
				// if both wrap then they have to intersect
			}
		}
		return true;
	}

	notEqual(other: GeoRectangle): boolean {
		const d = this.d_func();
		return !(d.equal(other.d_func()));
	}

	setBottomLeft(bottomLeft: GeoCoordinate): void {
		const d = this.d_func();
		d.bottomRight.setLatitude(bottomLeft.latitude());
		d.topLeft.setLongitude(bottomLeft.longitude());
	}

	setBottomRight(bottomRight: GeoCoordinate): void {
		const d = this.d_func();
		d.bottomRight = bottomRight;
	}

	setCenter(center: GeoCoordinate): void {
		const d = this.d_func();
		if (!this.isValid()) {
			d.topLeft = center;
			d.bottomRight = center;
			return;
		}
		let width = this.width();
		let height = this.height();
		let tlLat = center.latitude() + height / 2.0;
		let tlLon = center.longitude() - width / 2.0;
		let brLat = center.latitude() - height / 2.0;
		let brLon = center.longitude() + width / 2.0;
		tlLon = wrapLong(tlLon);
		brLon = wrapLong(brLon);
		if (tlLat > 90.0) {
			brLat = 2 * center.latitude() - 90.0;
			tlLat = 90.0;
		}
		if (tlLat < -90.0) {
			brLat = -90.0;
			tlLat = -90.0;
		}
		if (brLat > 90.0) {
			tlLat = 90.0;
			brLat = 90.0;
		}
		if (brLat < -90.0) {
			tlLat = 2 * center.latitude() + 90.0;
			brLat = -90.0;
		}
		if (width === 360.0) {
			tlLon = -180.0;
			brLon = 180.0;
		}
		d.topLeft = new GeoCoordinate(tlLat, tlLon);
		d.bottomRight = new GeoCoordinate(brLat, brLon);
	}

	setHeight(height: number): void {
		if (!this.isValid()) {
			return;
		}
		if (height < 0.0) {
			return;
		}
		if (height >= 180.0) {
			height = 180.0;
		}
		const d = this.d_func();
		let tlLon = d.topLeft.longitude();
		let brLon = d.bottomRight.longitude();
		const center = this.center();
		let tlLat = center.latitude() + height / 2.0;
		let brLat = center.latitude() - height / 2.0;
		if (tlLat > 90.0) {
			brLat = 2 * center.latitude() - 90.0;
			tlLat = 90.0;
		}
		if (tlLat < -90.0) {
			brLat = -90.0;
			tlLat = -90.0;
		}
		if (brLat > 90.0) {
			tlLat = 90.0;
			brLat = 90.0;
		}
		if (brLat < -90.0) {
			tlLat = 2 * center.latitude() + 90.0;
			brLat = -90.0;
		}
		d.topLeft = new GeoCoordinate(tlLat, tlLon);
		d.bottomRight = new GeoCoordinate(brLat, brLon);
	}

	setTopLeft(topLeft: GeoCoordinate): void {
		const d = this.d_func();
		d.topLeft = topLeft;
	}

	setTopRight(topRight: GeoCoordinate): void {
		const d = this.d_func();
		d.topLeft.setLatitude(topRight.latitude());
		d.bottomRight.setLongitude(topRight.longitude());
	}

	setWidth(degreesWidth: number): void {
		if (!this.isValid()) {
			return;
		}
		if (degreesWidth < 0.0) {
			return;
		}
		const d = this.d_func();
		if (degreesWidth >= 360.0) {
			d.topLeft.setLongitude(-180.0);
			d.bottomRight.setLongitude(180.0);
			return;
		}
		let tlLat = d.topLeft.latitude();
		let brLat = d.bottomRight.latitude();
		const c = this.center();
		let tlLon = c.longitude() - degreesWidth / 2.0;
		tlLon = wrapLong(tlLon);
		let brLon = c.longitude() + degreesWidth / 2.0;
		brLon = wrapLong(brLon);
		d.topLeft = new GeoCoordinate(tlLat, tlLon);
		d.bottomRight = new GeoCoordinate(brLat, brLon);
	}

	toLinearRing(): [[number, number], [number, number], [number, number], [number, number], [number, number]] {
		// Polygon
		// https://tools.ietf.org/html/rfc7946#section-3.1.6
		//
		// A linear ring is a closed LineString with four or more positions.
		// The first and last positions are equivalent, and they MUST contain identical values.
		// A linear ring MUST follow the right-hand rule with respect to the area it bounds, i.e., exterior rings are counterclockwise holes are clockwise
		// For type "Polygon", the "coordinates" member MUST be an array of linear ring coordinate arrays.
		// For Polygons with more than one of these rings, the first MUST be the exterior ring, and any others MUST be interior rings.  The exterior ring bounds the surface, and the interior rings (if present) bound holes within the surface.
		const bottomLeftCoord = this.bottomLeft();
		const bottomRightCoord = this.bottomRight();
		const topRightCoord = this.topRight();
		const topLeftCoord = this.topLeft();
		const bottomLeftPosition: [number, number] = [bottomLeftCoord.longitude(), bottomLeftCoord.latitude()];
		const bottomRightPosition: [number, number] = [bottomRightCoord.longitude(), bottomRightCoord.latitude()];
		const topRightPosition: [number, number] = [topRightCoord.longitude(), topRightCoord.latitude()];
		const topLeftPosition: [number, number] = [topLeftCoord.longitude(), topLeftCoord.latitude()];
		return [bottomLeftPosition, bottomRightPosition, topRightPosition, topLeftPosition, bottomLeftPosition];
	}

	topLeft(): GeoCoordinate {
		const d = this.d_func();
		return d.topLeft;
	}

	toPolygon(): GeoJsonPolygon {
		return {
			coordinates: [this.toLinearRing()],
			type: 'Polygon',
		};
	}

	topRight(): GeoCoordinate {
		if (!this.isValid()) {
			return new GeoCoordinate();
		}
		const d = this.d_func();
		return new GeoCoordinate(d.topLeft.latitude(), d.bottomRight.longitude());
	}

	toString(): string {
		if (this.type() !== ShapeType.RectangleType) {
			logger.warning(`Not a rectangle a ${this.type()}\n`);
			return 'GeoRectangle(not a rectangle)';
		}
		return `GeoRectangle(${this.topLeft().toString()}, ${this.bottomRight().toString()})`;
	}

	union(rectangle: GeoRectangle): this {
		const d = this.d_func();
		// If non-intersecting goes for most narrow box
		const left1 = d.topLeft.longitude();
		const right1 = d.bottomRight.longitude();
		const top1 = d.topLeft.latitude();
		const bottom1 = d.bottomRight.latitude();
		const left2 = rectangle.d_func().topLeft.longitude();
		const right2 = rectangle.d_func().bottomRight.longitude();
		const top2 = rectangle.d_func().topLeft.latitude();
		const bottom2 = rectangle.d_func().bottomRight.latitude();
		const top = Math.max(top1, top2);
		const bottom = Math.min(bottom1, bottom2);
		let left;
		let right;
		const wrap1 = (left1 > right1);
		const wrap2 = (left2 > right2);
		if ((wrap1 && wrap2) || (!wrap1 && !wrap2)) {
			const w = Math.abs((left1 + right1 - left2 - right2) / 2.0);
			if (w < 180.0) {
				left = Math.min(left1, left2);
				right = Math.max(right1, right2);
			} else if (w > 180.0) {
				left = Math.max(left1, left2);
				right = Math.min(right1, right2);
			} else {
				left = -180.0;
				right = 180.0;
			}
		} else {
			let wrapLeft;
			let wrapRight;
			let nonWrapLeft;
			let nonWrapRight;
			if (wrap1) {
				wrapLeft = left1;
				wrapRight = right1;
				nonWrapLeft = left2;
				nonWrapRight = right2;
			} else {
				wrapLeft = left2;
				wrapRight = right2;
				nonWrapLeft = left1;
				nonWrapRight = right1;
			}
			const joinWrapLeft = (nonWrapRight >= wrapLeft);
			const joinWrapRight = (nonWrapLeft <= wrapRight);
			if (wrapLeft <= nonWrapLeft) { // The wrapping rectangle contains the non-wrapping one entirely
				left = wrapLeft;
				right = wrapRight;
			} else {
				if (joinWrapLeft) {
					if (joinWrapRight) {
						left = -180.0;
						right = 180.0;
					} else {
						left = nonWrapLeft;
						right = wrapRight;
					}
				} else {
					if (joinWrapRight) {
						left = wrapLeft;
						right = nonWrapRight;
					} else {
						const wrapRightDistance = nonWrapLeft - wrapRight;
						const wrapLeftDistance = wrapLeft - nonWrapRight;
						if (wrapLeftDistance == wrapRightDistance) {
							left = -180.0;
							right = 180.0;
						} else if (wrapLeftDistance < wrapRightDistance) {
							left = nonWrapLeft;
							right = wrapRight;
						} else {
							left = wrapLeft;
							right = nonWrapRight;
						}
					}
				}
			}
		}
		if (((left1 == -180) && (right1 == 180.0)) || ((left2 == -180) && (right2 == 180.0))) {
			left = -180;
			right = 180;
		}
		d.topLeft = new GeoCoordinate(top, left);
		d.bottomRight = new GeoCoordinate(bottom, right);
		return this;
	}

	united(rectangle: GeoRectangle): GeoRectangle {
		const result = new GeoRectangle(this);
		if (rectangle.isValid()) {
			result.union(rectangle);
		}
		return result;
	}

	width(): number {
		if (!this.isValid()) {
			return Number.NaN;
		}
		const d = this.d_func();
		let result = d.bottomRight.longitude() - d.topLeft.longitude();
		if (result < 0.0) {
			result += 360.0;
		}
		if (result > 360.0) {
			result -= 360.0;
		}
		return result;
	}
}
