import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj} from '../../elobj';
import {TableView, TableViewOpts, TableViewPrivate} from '../itemviews';
import {ColumnSelect, DataTable} from './el';
import {ItemDataRole, Orientation, SelectionFlag} from '../../constants';
import {Pagination} from './pagination';
import {ModelIndex} from '../../abstractitemmodel';
import {list, Point, Rect} from '../../tools';
import {TextInputIconPosition} from '../textinput';

export class DataTableViewPrivate extends TableViewPrivate {
	headerInputState: {
		[Orientation.Horizontal]: boolean;
		[Orientation.Vertical]: boolean;
	};
	primaryTable: DataTable;
	rowsCheckable: boolean;

	constructor() {
		super();
		this.headerInputState = {
			[Orientation.Horizontal]: false,
			[Orientation.Vertical]: false,
		};
		this.primaryTable = new DataTable();
		this.rowsCheckable = false;
	}

	columnsInserted(parent: ModelIndex, first: number, last: number): void {
		this.primaryTable.insertColumns(
			first,
			last - first + 1,
		);
	}

	columnsRemoved(parent: ModelIndex, first: number, last: number): void {
		this.primaryTable.removeColumns(
			first,
			last - first + 1,
		);
	}

	dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles?: Array<number>): void {
		roles = roles || [];
		if (roles.length < 1) {
			roles.push(ItemDataRole.EditRole);
		}
		const q = this.q;
		const model = q.model();
		if (!model) {
			return;
		}
		const tbl = this.primaryTable;
		for (let row = topLeft.row(); row <= bottomRight.row(); ++row) {
			for (let column = topLeft.column(); column <= bottomRight.column(); ++column) {
				const index = model.index(row, column);
				if (index.isValid()) {
					const row = index.row();
					const column = index.column();
					for (const role of roles) {
						tbl.setCellData(
							row,
							column,
							model.data(index, role),
							role,
						);
					}
				}
			}
		}
	}

	headerDataChanged(orientation: Orientation, first: number, last: number): void {
		if (orientation === Orientation.Horizontal) {
			const model = this.q.model();
			if (!model) {
				return;
			}
			const tbl = this.primaryTable;
			for (let column = first; column <= last; ++column) {
				let role = ItemDataRole.EditRole;
				tbl.setHeaderCellData(
					column,
					model.headerData(column, orientation, role),
					role,
				);
				role = ItemDataRole.BackgroundRole;
				tbl.setHeaderCellData(
					column,
					model.headerData(column, orientation, role),
					role,
				);
			}
		}
	}

	insertPrimaryTable(primaryTable: DataTable): void {
		primaryTable.setParent(this.q);
		primaryTable.show();
	}

	modelReset(): void {
	}

	get q(): DataTableView {
		return <DataTableView>super.q;
	}

	rowsInserted(parent: ModelIndex, first: number, last: number): void {
		this.primaryTable.insertRows(
			first,
			last - first + 1,
		);
	}

	rowsRemoved(parent: ModelIndex, first: number, last: number): void {
		this.primaryTable.removeRows(
			first,
			last - first + 1,
		);
	}

	init(opts: Partial<DataTableViewOpts>): void {
		super.init(opts);
		const q = this.q;
		this.setPrimaryTable(null);
		Obj.connect(
			this.model,
			'columnsInserted',
			q,
			'_p_columnsInserted',
		);
		Obj.connect(
			this.model,
			'columnsRemoved',
			q,
			'_p_columnsRemoved',
		);
		Obj.connect(
			this.model,
			'dataChanged',
			q,
			'_p_dataChanged',
		);
		Obj.connect(
			this.model,
			'headerDataChanged',
			q,
			'_p_headerDataChanged',
		);
		Obj.connect(
			this.model,
			'modelReset',
			q,
			'_p_modelReset',
		);
		Obj.connect(
			this.model,
			'rowsInserted',
			q,
			'_p_rowsInserted',
		);
		Obj.connect(
			this.model,
			'rowsRemoved',
			q,
			'_p_rowsRemoved',
		);
	}

	setPrimaryTable(table: DataTable | null): void {
		if (table === this.primaryTable) {
			return;
		}
		const q = this.q;
		this.primaryTable.destroy();
		this.primaryTable = table ?
			table :
			new DataTable();
		this.insertPrimaryTable(
			this.primaryTable,
		);
		Obj.connect(
			this.primaryTable,
			'cellClicked',
			q,
			'cellClicked',
		);
		Obj.connect(
			this.primaryTable,
			'cellDoubleClicked',
			q,
			'cellDoubleClicked',
		);
		Obj.connect(
			this.primaryTable,
			'cellInputTextChanged',
			q,
			'_p_cellInputTextChanged',
		);
		Obj.connect(
			this.primaryTable,
			'cellInputReturnPressed',
			q,
			'_p_cellInputReturnPressed',
		);
		Obj.connect(
			this.primaryTable,
			'cellInputIconActivated',
			q,
			'_p_cellInputIconActivated',
		);
		Obj.connect(
			this.primaryTable.horizontalHeader(),
			'sortIndicatorChanged',
			this.primaryTable,
			'setVisualSortIndicator',
		);
		const columnCount = q.columnCount();
		const rowCount = q.rowCount();
		this.primaryTable.setColumnCount(columnCount);
		this.primaryTable.setRowCount(rowCount);
		const model = q.model();
		if (!model) {
			return;
		}
		for (let column = 0; column < columnCount; ++column) {
			this.primaryTable.setHeaderCellData(
				column,
				model.headerData(
					column,
					Orientation.Horizontal,
					ItemDataRole.EditRole,
				),
				ItemDataRole.EditRole,
			);
		}
		for (let row = 0; row < rowCount; ++row) {
			for (let column = 0; column < columnCount; ++column) {
				const index = model.index(row, column);
				if (index.isValid()) {
					this.primaryTable.setCellData(
						index.row(),
						index.column(),
						model.data(
							index,
							ItemDataRole.EditRole,
						),
						ItemDataRole.EditRole,
					);
				}
			}
		}
	}
}

export interface DataTableViewOpts extends TableViewOpts {
	dd: DataTableViewPrivate;
	primaryTable: DataTable;
}

@OBJ
export class DataTableView extends TableView {
	constructor(opts: Partial<DataTableViewOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-data-table-view',
		);
		opts.dd = opts.dd || new DataTableViewPrivate();
		// NB: We need to set dimensions (if provided) as opposed to sending
		//     them to super b/c we won't be completely initialized when super
		//     calls procedures which subsequent trigger our procedures.
		const dims = opts.dimensions;
		opts.dimensions = undefined;
		super(opts);
		if (opts.primaryTable) {
			opts.dd.setPrimaryTable(opts.primaryTable);
		}
		if (dims) {
			this.setColumnCount(dims.columns);
			this.setRowCount(dims.rows);
		}
	}

	indexAt(pos: Point): ModelIndex {
		const d = this.d;
		const tbl = d.primaryTable;
		const res = tbl.cellAt(pos);
		if (res) {
			const [row, col] = res._idx();
			return d.tableModel().index(row, col);
		}
		return super.indexAt(pos);
	}

	setSelection(rect: Rect, flags: SelectionFlag): void {
		const d = this.d;
		if (!d.selectionModel) {
			return;
		}
		const idx = this.indexAt(rect.center());
		if (d.selectionModel.isSelected(idx)) {
			// d.selectionModel.select(new ModelIndex(), flags);
			d.selectionModel.clear();
		} else {
			d.selectionModel.select(idx, flags);
		}
	}

	@SIGNAL
	cellClicked(row: number, column: number): void {
	}

	@SIGNAL
	cellDoubleClicked(row: number, column: number): void {
	}

	@SIGNAL
	protected cellInputIconActivated(row: number, column: number, text: string, position: TextInputIconPosition): void {
	}

	@SLOT
	protected _p_cellInputIconActivated(row: number, column: number, text: string, position: TextInputIconPosition): void {
		if ((row < 0) && (column >= 0)) {
			this.headerInputIconActivated(column, Orientation.Horizontal, text, position);
		} else {
			this.cellInputIconActivated(row, column, text, position);
		}
	}

	@SIGNAL
	protected cellInputReturnPressed(row: number, column: number, text: string): void {
	}

	@SLOT
	protected _p_cellInputReturnPressed(row: number, column: number, text: string): void {
		if ((row < 0) && (column >= 0)) {
			this.headerInputReturnPressed(column, Orientation.Horizontal, text);
		} else {
			this.cellInputReturnPressed(row, column, text);
		}
	}

	@SIGNAL
	protected cellInputTextChanged(row: number, column: number, text: string): void {
	}

	@SLOT
	protected _p_cellInputTextChanged(row: number, column: number, text: string): void {
		if ((row < 0) && (column >= 0)) {
			this.headerInputTextChanged(column, Orientation.Horizontal, text);
		} else {
			this.cellInputTextChanged(row, column, text);
		}
	}

	columnSelect(): ColumnSelect | null {
		return this.d.primaryTable.columnSelect();
	}

	@SLOT
	protected _p_columnsInserted(parent: ModelIndex, first: number, last: number): void {
		this.d.columnsInserted(parent, first, last);
	}

	@SLOT
	protected _p_columnsRemoved(parent: ModelIndex, first: number, last: number): void {
		this.d.columnsRemoved(parent, first, last);
	}

	get d(): DataTableViewPrivate {
		return <DataTableViewPrivate>super.d;
	}

	@SLOT
	protected _p_dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles?: Array<number>): void {
		this.d.dataChanged(topLeft, bottomRight, roles);
	}

	destroy(): void {
		const d = this.d;
		d.headerInputState = {
			[Orientation.Horizontal]: false,
			[Orientation.Vertical]: false,
		};
		d.primaryTable.destroy();
		d.rowsCheckable = false;
		super.destroy();
	}

	@SLOT
	protected _p_headerDataChanged(orientation: Orientation, first: number, last: number): void {
		this.d.headerDataChanged(orientation, first, last);
	}

	@SIGNAL
	protected headerInputIconActivated(section: number, orientation: Orientation, text: string, position: TextInputIconPosition): void {
	}

	@SIGNAL
	protected headerInputReturnPressed(section: number, orientation: Orientation, text: string): void {
	}

	headerInputText(section: number, orientation: Orientation): string {
		if (orientation === Orientation.Horizontal) {
			return this.d.primaryTable.headerInputText(section);
		}
		return '';
	}

	@SIGNAL
	protected headerInputTextChanged(section: number, orientation: Orientation, text: string): void {
	}

	hideProgressIndicator(): void {
		this.setProgressIndicatorVisible(false);
	}

	indexEl(index: ModelIndex): ElObj | null {
		if (index.isValid()) {
			return this.d.primaryTable.cell(
				index.row(),
				index.column(),
			);
		}
		return null;
	}

	@SLOT
	protected _p_modelReset(): void {
		this.d.modelReset();
	}

	pagination(): Pagination | null {
		return this.d.primaryTable.pagination();
	}

	rowsCheckable(): boolean {
		return this.d.rowsCheckable;
	}

	@SIGNAL
	protected rowSelectionChanged(row: number, selected: boolean): void {
	}

	@SLOT
	protected _p_rowsInserted(parent: ModelIndex, first: number, last: number): void {
		this.d.rowsInserted(parent, first, last);
	}

	@SLOT
	protected _p_rowsRemoved(parent: ModelIndex, first: number, last: number): void {
		this.d.rowsRemoved(parent, first, last);
	}

	scrollTo(index: ModelIndex): void {
		this.d.primaryTable.scrollToRow(index.row());
	}

	@SIGNAL
	protected selectAllRowsChanged(selectAll: boolean): void {
	}

	selectedRows(): list<number> {
		return this.d.primaryTable.selectedRows();
	}

	setColumnSelect(columnSelect: ColumnSelect | null): void {
		this.d.primaryTable.setColumnSelect(columnSelect);
	}

	setHeaderDocked(orientation: Orientation, docked: boolean): void {
		if (orientation === Orientation.Horizontal) {
			this.d.primaryTable.setStickyHeader(!docked);
		}
	}

	setHeaderInputText(section: number, orientation: Orientation, text: string, setFocused: boolean = false): void {
		if (orientation === Orientation.Horizontal) {
			const d = this.d;
			d.primaryTable.setHeaderInputText(
				section,
				text,
			);
			if (setFocused) {
				d.primaryTable.focusHeaderInput(section);
			}
		}
	}

	setHeaderInputShown(orientation: Orientation, shown: boolean): void {
		const d = this.d;
		if (shown === d.headerInputState[orientation]) {
			return;
		}
		d.headerInputState[orientation] = shown;
		if (orientation === Orientation.Horizontal) {
			d.primaryTable.setHeaderInputEnabled(
				d.headerInputState[orientation],
			);
		}
	}

	setPagination(pagination: Pagination | null): void {
		this.d.primaryTable.setPagination(pagination);
	}

	setProgressIndicatorVisible(visible: boolean): void {
		this.d.primaryTable.setProgressIndicatorVisible(visible);
	}

	setRowsCheckable(checkable: boolean): void {
		const d = this.d;
		if (checkable === d.rowsCheckable) {
			return;
		}
		d.rowsCheckable = checkable;
		if (d.rowsCheckable) {
			Obj.connect(
				d.primaryTable,
				'rowSelectionChanged',
				this,
				'rowSelectionChanged',
			);
			Obj.connect(
				d.primaryTable,
				'selectAllRowsChanged',
				this,
				'selectAllRowsChanged',
			);
		} else {
			Obj.disconnect(
				d.primaryTable,
				'rowSelectionChanged',
				this,
				'rowSelectionChanged',
			);
			Obj.disconnect(
				d.primaryTable,
				'selectAllRowsChanged',
				this,
				'selectAllRowsChanged',
			);
		}
		d.primaryTable.setRowsCheckable(d.rowsCheckable);
	}

	showProgressIndicator(): void {
		this.setProgressIndicatorVisible(true);
	}
}
