import {
	MDCComponent,
	MDCFoundation,
	SpecificEventListener,
} from '@material/base';
import {
	cssClasses as listCssClasses,
	MDCList,
	MDCListFoundation,
	MDCListActionEvent,
} from '@material/list';
import {
	Corner,
	MDCMenuDistance,
	MDCMenuSurface,
	MDCMenuSurfaceFoundation,
} from './surface';
import {bind, closestMatchingElement} from '../../../util';

interface MDCMenuItemEventDetail {
	index: number;
}

interface MDCMenuItemComponentEventDetail extends MDCMenuItemEventDetail {
	item: Element;
}

interface MDCMenuItemEvent extends Event {
	readonly detail: MDCMenuItemEventDetail;
}

export interface MDCMenuItemComponentEvent extends Event {
	readonly detail: MDCMenuItemComponentEventDetail;
}

const cssClasses = {
	MENU_SELECTED_LIST_ITEM: 'mdc-menu-item--selected',
	MENU_SELECTION_GROUP: 'mdc-menu__selection-group',
	ROOT: 'mdc-menu',
};
const strings = {
	ARIA_CHECKED_ATTR: 'aria-checked',
	ARIA_DISABLED_ATTR: 'aria-disabled',
	CHECKBOX_SELECTOR: 'input[type="checkbox"]',
	LIST_SELECTOR: '.mdc-list',
	SELECTED_EVENT: 'MDCMenu:selected',
};
const numbers = {
	FOCUS_ROOT_INDEX: -1,
};

enum DefaultFocusState {
	NONE = 0,
	LIST_ROOT = 1,
	FIRST_ITEM = 2,
	LAST_ITEM = 3,
}

interface MDCMenuAdapter {
	addAttributeToElementAtIndex(index: number, attr: string, value: string): void;
	addClassToElementAtIndex(index: number, className: string): void;
	closeSurface(skipRestoreFocus?: boolean): void;
	elementContainsClass(element: Element, className: string): boolean;
	focusItemAtIndex(index: number): void;
	focusListRoot(): void;
	getElementIndex(element: Element): number;
	getMenuItemCount(): number;
	getSelectedSiblingOfItemAtIndex(index: number): number;
	isSelectableItemAtIndex(index: number): boolean;
	notifySelected(evtData: MDCMenuItemEventDetail): void;
	removeAttributeFromElementAtIndex(index: number, attr: string): void;
	removeClassFromElementAtIndex(index: number, className: string): void;
}

class MDCMenuFoundation extends MDCFoundation<MDCMenuAdapter> {
	static get cssClasses() {
		return cssClasses;
	}

	static get strings() {
		return strings;
	}

	static get numbers() {
		return numbers;
	}

	private closeAnimationEndTimerId_ = 0;
	private defaultFocusState_ = DefaultFocusState.LIST_ROOT;

	static get defaultAdapter(): MDCMenuAdapter {
		return {
			addAttributeToElementAtIndex: () => undefined,
			addClassToElementAtIndex: () => undefined,
			closeSurface: () => undefined,
			elementContainsClass: () => false,
			focusItemAtIndex: () => undefined,
			focusListRoot: () => undefined,
			getElementIndex: () => -1,
			getMenuItemCount: () => 0,
			getSelectedSiblingOfItemAtIndex: () => -1,
			isSelectableItemAtIndex: () => false,
			notifySelected: () => undefined,
			removeAttributeFromElementAtIndex: () => undefined,
			removeClassFromElementAtIndex: () => undefined,
		};
	}

	constructor(adapter?: Partial<MDCMenuAdapter>) {
		super({...MDCMenuFoundation.defaultAdapter, ...adapter});
	}

	destroy() {
		if (this.closeAnimationEndTimerId_) {
			clearTimeout(this.closeAnimationEndTimerId_);
		}
		this.adapter.closeSurface();
	}

	handleKeydown(evt: KeyboardEvent) {
		const {key, keyCode} = evt;
		const isTab = key === 'Tab' || keyCode === 9;
		if (isTab) {
			this.adapter.closeSurface(/** skipRestoreFocus */ true);
		}
	}

	handleItemAction(listItem: Element) {
		const index = this.adapter.getElementIndex(listItem);
		if (index < 0) {
			return;
		}
		this.adapter.notifySelected({index});
		// Wait for the menu to close before adding/removing classes that affect styles.
		// this.closeAnimationEndTimerId_ = setTimeout(() => {
		// 	// Recompute the index in case the menu contents have changed.
	}

	handleMenuSurfaceOpened() {
		switch (this.defaultFocusState_) {
			case DefaultFocusState.FIRST_ITEM:
				this.adapter.focusItemAtIndex(0);
				break;
			case DefaultFocusState.LAST_ITEM:
				this.adapter.focusItemAtIndex(this.adapter.getMenuItemCount() - 1);
				break;
			case DefaultFocusState.NONE:
				// Do nothing.
				break;
			default:
				this.adapter.focusListRoot();
				break;
		}
	}

	setDefaultFocusState(focusState: DefaultFocusState) {
		this.defaultFocusState_ = focusState;
	}

	setSelectedIndex(index: number) {
		this.validatedIndex_(index);
		if (!this.adapter.isSelectableItemAtIndex(index)) {
			throw new Error('MDCMenuFoundation: No selection group at specified index.');
		}
		const prevSelectedIndex =
			this.adapter.getSelectedSiblingOfItemAtIndex(index);
		if (prevSelectedIndex >= 0) {
			this.adapter.removeAttributeFromElementAtIndex(
				prevSelectedIndex, strings.ARIA_CHECKED_ATTR);
			this.adapter.removeClassFromElementAtIndex(
				prevSelectedIndex, cssClasses.MENU_SELECTED_LIST_ITEM);
		}
		this.adapter.addClassToElementAtIndex(
			index, cssClasses.MENU_SELECTED_LIST_ITEM);
		this.adapter.addAttributeToElementAtIndex(
			index, strings.ARIA_CHECKED_ATTR, 'true');
	}

	setEnabled(index: number, isEnabled: boolean): void {
		this.validatedIndex_(index);
		if (isEnabled) {
			this.adapter.removeClassFromElementAtIndex(
				index, listCssClasses.LIST_ITEM_DISABLED_CLASS);
			this.adapter.addAttributeToElementAtIndex(
				index, strings.ARIA_DISABLED_ATTR, 'false');
		} else {
			this.adapter.addClassToElementAtIndex(
				index, listCssClasses.LIST_ITEM_DISABLED_CLASS);
			this.adapter.addAttributeToElementAtIndex(
				index, strings.ARIA_DISABLED_ATTR, 'true');
		}
	}

	private validatedIndex_(index: number): void {
		const menuSize = this.adapter.getMenuItemCount();
		const isIndexInRange = index >= 0 && index < menuSize;
		if (!isIndexInRange) {
			throw new Error('MDCMenuFoundation: No list item at specified index.');
		}
	}
}

let googleSucksAtBuildingSoftware: boolean = false;

export class MDCMenu extends MDCComponent<MDCMenuFoundation> {
	private menuSurface_: MDCMenuSurface | null;
	private handleKeydown_: SpecificEventListener<'keydown'> | null;
	private handleMenuSurfaceOpened_: EventListener | null;
	private list_: MDCList | null;

	constructor(root: Element) {
		super(root);
		this.menuSurface_ = null;
		this.handleKeydown_ = null;
		this.handleMenuSurfaceOpened_ = null;
		this.list_ = null;
		if (googleSucksAtBuildingSoftware) {
			this.menuSurface_ = new MDCMenuSurface(this.root);
			this.handleKeydown_ = (evt) => this.foundation.handleKeydown(evt);
			this.handleMenuSurfaceOpened_ = () => this.foundation.handleMenuSurfaceOpened();
			this.menuSurface_.listen(MDCMenuSurfaceFoundation.strings.OPENED_EVENT, this.handleMenuSurfaceOpened_);
			this.listen('keydown', this.handleKeydown_);
			const listEl = this.root.querySelector(strings.LIST_SELECTOR);
			if (listEl) {
				this.list_ = new MDCList(listEl);
				this.list_.wrapFocus = true;
				this.listen(MDCListFoundation.strings.ACTION_EVENT, this.handleItemAction_);
			}
		}
	}

	@bind
	private handleItemAction_(event: MDCListActionEvent): void {
		const items = this.items;
		if ((event.detail.index >= 0) && (event.detail.index < items.length)) {
			this.foundation.handleItemAction(items[event.detail.index]);
		}
	}

	initialSyncWithDOM() {
		googleSucksAtBuildingSoftware = true;
	}

	get items(): Array<Element> {
		return this.list_ ?
			this.list_.listElements :
			[];
	}

	destroy() {
		if (this.list_) {
			this.list_.destroy();
		}
		if (this.menuSurface_) {
			this.menuSurface_.destroy();
			if (this.handleMenuSurfaceOpened_) {
				this.menuSurface_.unlisten(MDCMenuSurfaceFoundation.strings.OPENED_EVENT, this.handleMenuSurfaceOpened_);
			}
		}
		if (this.handleKeydown_) {
			this.unlisten('keydown', this.handleKeydown_);
		}
		this.unlisten(MDCListFoundation.strings.ACTION_EVENT, this.handleItemAction_);
		super.destroy();
	}

	get open(): boolean {
		return this.menuSurface_ ?
			this.menuSurface_.isOpen() :
			false;
	}

	set open(value: boolean) {
		if (!this.menuSurface_) {
			return;
		}
		if (value) {
			this.menuSurface_.open();
		} else {
			this.menuSurface_.close();
		}
	}

	set quickOpen(quickOpen: boolean) {
		if (this.menuSurface_) {
			this.menuSurface_.quickOpen = quickOpen;
		}
	}

	setDefaultFocusState(focusState: DefaultFocusState) {
		this.foundation.setDefaultFocusState(focusState);
	}

	setAnchorCorner(corner: Corner) {
		if (this.menuSurface_) {
			this.menuSurface_.setAnchorCorner(corner);
		}
	}

	setAnchorMargin(margin: Partial<MDCMenuDistance>) {
		if (this.menuSurface_) {
			this.menuSurface_.setAnchorMargin(margin);
		}
	}

	setEnabled(index: number, isEnabled: boolean): void {
		this.foundation.setEnabled(index, isEnabled);
	}

	setFixedPosition(isFixed: boolean) {
		if (this.menuSurface_) {
			this.menuSurface_.setFixedPosition(isFixed);
		}
	}

	setIsHoisted(isHoisted: boolean) {
		if (this.menuSurface_) {
			this.menuSurface_.setIsHoisted(isHoisted);
		}
	}

	setAbsolutePosition(x: number, y: number) {
		if (this.menuSurface_) {
			this.menuSurface_.setAbsolutePosition(x, y);
		}
	}

	setAnchorElement(element: Element) {
		if (this.menuSurface_) {
			this.menuSurface_.anchorElement = element;
		}
	}

	getDefaultFoundation() {
		const adapter: MDCMenuAdapter = {
			addClassToElementAtIndex: (index, className) => {
				const items = this.items;
				if ((index >= 0) && (index < items.length)) {
					items[index].classList.add(className);
				}
			},
			removeClassFromElementAtIndex: (index, className) => {
				const items = this.items;
				if ((index >= 0) && (index < items.length)) {
					items[index].classList.remove(className);
				}
			},
			addAttributeToElementAtIndex: (index, attr, value) => {
				const items = this.items;
				if ((index >= 0) && (index < items.length)) {
					items[index].setAttribute(attr, value);
				}
			},
			removeAttributeFromElementAtIndex: (index, attr) => {
				const items = this.items;
				if ((index >= 0) && (index < items.length)) {
					items[index].removeAttribute(attr);
				}
			},
			elementContainsClass: (element, className) => element.classList.contains(className),
			closeSurface: (skipRestoreFocus: boolean) => this.menuSurface_ && this.menuSurface_.close(skipRestoreFocus),
			getElementIndex: el => this.items.indexOf(el),
			notifySelected: (evtData) => {
				const items = this.items;
				if ((evtData.index >= 0) && (evtData.index < items.length)) {
					this.emit<MDCMenuItemComponentEventDetail>(
						strings.SELECTED_EVENT,
						{
							index: evtData.index,
							item: items[evtData.index],
						});
				}
			},
			getMenuItemCount: () => this.items.length,
			focusItemAtIndex: index => {
				const items = this.items;
				if ((index >= 0) && (index < items.length)) {
					(<HTMLElement>items[index]).focus();
				}
			},
			focusListRoot: () => (this.root.querySelector(strings.LIST_SELECTOR) as HTMLElement).focus(),
			isSelectableItemAtIndex: (index) => {
				const items = this.items;
				if ((index >= 0) && (index < items.length)) {
					return Boolean(closestMatchingElement(items[index], `.${cssClasses.MENU_SELECTION_GROUP}`));
				}
				return false;
			},
			getSelectedSiblingOfItemAtIndex: (index) => {
				const items = this.items;
				if ((index >= 0) && (index < items.length)) {
					const groupEl = closestMatchingElement(
						items[index],
						`.${cssClasses.MENU_SELECTION_GROUP}`);
					const selectedItemEl = groupEl && groupEl.querySelector(`.${cssClasses.MENU_SELECTED_LIST_ITEM}`);
					return selectedItemEl ?
						items.indexOf(selectedItemEl) :
						-1;
				}
				return -1;
			},
		};
		return new MDCMenuFoundation(adapter);
	}
}
