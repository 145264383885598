import {OBJ, Obj, SLOT} from '../../obj';
import {assert, numberFormat} from '../../util';
import {svc} from '../../request';
import {getLogger} from '../../logging';
import {TextInput} from '../../ui/textinput';
import {ElObj} from '../../elobj';
import {InteractiveMapControlMode, InteractiveMapFlag, MapDrawMode, PROJECT_DETAIL_BODY_ELEMENT_ID} from '../../constants';
import {FilterList} from './filterlist';
import {list} from '../../tools';
import {ProjectDetailStats} from './stats';
import {ActiveDownloadPayBtn, DownloadPay} from './downloadpay';
import {PaymentDialogView} from '../paymentdialog';
import {UndoView} from '../parcel/undoview';
import {SearchInput} from '../parcel/searchinput';
import {FilterMdl, GeoRef, Parcel, PaymentIntent, Place, Proj} from '../../models';
import {FancyList, FancyListItem} from '../../ui/list';
import {ParcelMap, ParcelView, ParcelViewOpts, ParcelViewPrivate} from '../parcel';
import {Drawer} from '../../ui/drawer';
import {Completer} from '../../ui/completer';

const logger = getLogger('projectdetailview');

enum BottomLink {
	CopyProject = 1,
	ArchiveProject,
}

class ProjectDetailViewPrivate extends ParcelViewPrivate {
	bottomLinkItems: Map<BottomLink, FancyListItem>;
	cancelSrc: {syncCounts: ICancelTokenSource | null};
	downloadPay: DownloadPay | null;
	filterList: FilterList | null;
	body: ElObj | null;
	paymentDialog: PaymentDialogView | null;
	places: list<Place>;
	proj: Proj | null;
	stats: ProjectDetailStats | null;
	titleInput: TextInput | null;

	constructor() {
		super();
		this.body = null;
		this.bottomLinkItems = new Map();
		this.cancelSrc = {
			syncCounts: null,
		};
		this.downloadPay = null;
		this.filterList = null;
		this.parcelInfo = null;
		this.paymentDialog = null;
		this.places = new list();
		this.proj = null;
		this.stats = null;
		this.titleInput = null;
	}

	async fetchFilters(): Promise<list<FilterMdl>> {
		if (this.proj) {
			return await FilterMdl.list({
				projectSlug: this.proj.slug(),
			});
		}
		return await super.fetchFilters();
	}

	init(opts: Partial<ProjectDetailViewOpts>): void {
		super.init(opts);
		const q = this.q;
		Obj.connect(
			q, 'queryUpdated',
			q, 'syncStats',
		);
		this.pkBs.pkFetcher = async (z: number, x: number, y: number) => {
			if (this.proj) {
				return svc.parcel.projectAreaPks(
					this.proj.slug(),
					z,
					x,
					y,
				);
			}
			return [];
		};
		q.setDrawer(
			new Drawer({
				parent: q,
			}),
		);
		this.body = new ElObj({
			attributes: [
				['id', PROJECT_DETAIL_BODY_ELEMENT_ID],
			],
			classNames: 'mdc-drawer-app-content',
			parent: q,
		});
		const inp = new SearchInput({
			parent: q,
		});
		q.setTextInput(inp);
		const compl = new Completer();
		compl.hide();
		Obj.connect(
			compl, 'activated',
			q, 'completerActivated',
		);
		inp.setCompleter(compl);
		q.setMap(
			new ParcelMap({
				flags: InteractiveMapFlag.MapNavControlIsEnabled
					| InteractiveMapFlag.MapDrawingIsEnabled
					| InteractiveMapFlag.MapInfoControlIsEnabled
					| InteractiveMapFlag.MapLayerControlIsEnabled,
				parent: q,
			}),
		);
		this.titleInput = new TextInput({
			attributes: [
				['id', 'id_lb-project-detail-title-input'],
			],
			labelText: 'Project title',
			parent: this.drawer,
			trailingIcon: {
				icon: 'save',
				interactive: true,
				outlined: false,
				title: 'Save project title',
			},
			underlinedLessDenseWhiteBackground: true,
		});
		const icon = this.titleInput.trailingIcon();
		if (icon) {
			icon.setDisabled(true);
		}
		Obj.connect(
			this.titleInput, 'textChanged',
			q, 'titleChanged',
		);
		Obj.connect(
			this.titleInput, 'returnPressed',
			q, 'saveCurrentTitle',
		);
		Obj.connect(
			this.titleInput, 'iconActivated',
			q, 'saveCurrentTitle',
		);
		const filterListHeader = new ElObj({
			classNames: 'mdc-list-group__subheader',
			parent: this.drawer,
			tagName: 'h5',
		});
		filterListHeader.setText('Filters');
		this.filterList = new FilterList({
			parent: this.drawer,
		});
		Obj.connect(
			q, 'filterAdded',
			this.filterList, 'addFilter',
		);
		Obj.connect(
			q, 'filterRemoved',
			this.filterList, 'removeFilter',
		);
		Obj.connect(
			this.filterList, 'filterClicked',
			q, 'flyToFilter',
		);
		new ElObj({
			classNames: 'mdc-list-divider',
			parent: this.drawer,
			tagName: 'hr',
		});
		this.stats = new ProjectDetailStats({
			parent: this.drawer,
		});
		new ElObj({
			classNames: 'mdc-list-divider',
			parent: this.drawer,
			tagName: 'hr',
		});
		q.setUndoView(
			new UndoView({
				parent: this.drawer,
			}),
		);
		this.downloadPay = new DownloadPay({
			parent: this.drawer,
		});
		Obj.connect(
			this.downloadPay, 'buttonClicked',
			q, 'downloadPayButtonClicked',
		);
		new ElObj({
			classNames: 'mdc-list-divider',
			parent: this.drawer,
			tagName: 'hr',
		});
		const bottomLinksListHeader = new ElObj({
			classNames: 'mdc-list-group__subheader',
			parent: this.drawer,
			styles: [
				['margin-bottom', '8px'],
			],
			tagName: 'h5',
		});
		bottomLinksListHeader.setText('Manage');
		const bottomLinks = new FancyList({
			compact: true,
			parent: this.drawer,
		});
		Obj.connect(
			bottomLinks, 'itemActivated',
			q, 'bottomLinksItemActivated',
		);
		const copyItem = new FancyListItem({
			leadingIcon: {
				name: 'content_copy',
				outlined: true,
			},
			text: 'Copy project',
		});
		this.bottomLinkItems.set(
			BottomLink.CopyProject,
			copyItem,
		);
		bottomLinks.addItem(copyItem);
		const archiveItem = new FancyListItem({
			leadingIcon: {
				name: 'archive',
				outlined: true,
			},
			text: 'Archive project',
		});
		this.bottomLinkItems.set(
			BottomLink.ArchiveProject,
			archiveItem,
		);
		bottomLinks.addItem(archiveItem);
		if (opts.slug) {
			Proj.get(opts.slug).then(proj => {
				this.proj = proj;
				if (this.proj) {
					Obj.connect(
						this.proj, 'paymentProcessed',
						q, 'syncDownloadPayButton',
					);
					const title = this.proj.title();
					q.setDocumentTitle(title);
					if (this.titleInput) {
						this.titleInput.setText(title);
					}
					if (this.downloadPay) {
						const url = this.proj.absoluteListUrl() || undefined;
						this.downloadPay.showButton(
							(url && (url.length > 0)) ?
								ActiveDownloadPayBtn.Download :
								ActiveDownloadPayBtn.Pay,
							url,
						);
					}
				}
				q.setFilters();
			});
		}
		this.map && this.map.load(
			q.mapConfig(),
		);
	}

	newFilterData(data: Partial<INewFilter>): Partial<INewFilter> {
		if (this.proj) {
			data.projectSlug = this.proj.slug();
		}
		return super.newFilterData(data);
	}

	get q(): ProjectDetailView {
		return <ProjectDetailView>super.q;
	}
}

interface ProjectDetailViewOpts extends ParcelViewOpts {
	dd: ProjectDetailViewPrivate;
	slug: string;
}

@OBJ
export class ProjectDetailView extends ParcelView {
	constructor(opts: Partial<ProjectDetailViewOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['id', 'id_lb-project-detail'],
		);
		opts.dd = opts.dd || new ProjectDetailViewPrivate();
		super(opts);
	}

	@SLOT
	private async beginPayment(): Promise<void> {
		const d = this.d;
		if (!d.proj || d.paymentDialog) {
			return;
		}
		const invId = d.proj.invoiceId();
		if (invId === null) {
			return;
		}
		const invoice = await svc.invoice.get(invId);
		assert(invoice);
		const slug = await d.proj.slug();
		const title = `${slug} - ${numberFormat(invoice.totalQuantity)} mailing addresses`;
		const paymentData = await PaymentIntent.create(slug);
		d.paymentDialog = new PaymentDialogView({
			amount: invoice.total,
			paymentIntent: paymentData,
			title,
		});
		Obj.connect(
			d.paymentDialog, 'finished',
			this, 'endPayment',
		);
		d.paymentDialog.beginPayment();
	}

	async clone(): Promise<IProject | null> {
		const d = this.d;
		if (d.proj) {
			return await svc.project.clone(
				await d.proj.slug(),
			);
		}
		return null;
	}

	get d(): ProjectDetailViewPrivate {
		return <ProjectDetailViewPrivate>super.d;
	}

	@SLOT
	protected async completerActivated(index: number): Promise<void> {
		const d = this.d;
		if ((index >= 0) && (index < d.places.size())) {
			this.closeCompleter();
			await this.createFiltersFromPlaces(
				await d.places.at(index).pk(),
			);
		}
	}

	private destroyParcelInfo(): void {
		const d = this.d;
		if (d.parcelInfo) {
			d.parcelInfo.destroy();
		}
		d.parcelInfo = null;
	}

	private destroyPaymentDialog(): void {
		const d = this.d;
		if (d.paymentDialog) {
			d.paymentDialog.destroy();
		}
		d.paymentDialog = null;
	}

	@SLOT
	private downloadButtonClicked(): void {
		window.location.assign('list/');
	}

	@SLOT
	private async downloadPayButtonClicked(): Promise<void> {
		const d = this.d;
		if (!d.downloadPay) {
			return;
		}
		if (d.downloadPay.currentButton() === ActiveDownloadPayBtn.Pay) {
			await this.payButtonClicked();
		} else {
			if ((d.downloadPay.currentButton() === ActiveDownloadPayBtn.Download) && d.downloadPay.isButtonFlashing()) {
				d.downloadPay.setButtonFlashing(false);
			}
		}
	}

	async setTitle(title: string): Promise<void> {
		const d = this.d;
		if (d.proj) {
			await d.proj.setTitle(title);
			this.setDocumentTitle(
				await d.proj.title(),
			);
		}
	}

	@SLOT
	private async endPayment(success: boolean): Promise<void> {
		setTimeout(() => this.paymentEnded(success), 0);
		this.destroyPaymentDialog();
	}

	@SLOT
	private geoMapPopupClosed(): void {
		this.destroyParcelInfo();
	}

	@SLOT
	private async titleChanged(title: string): Promise<void> {
		const d = this.d;
		const icon = d.titleInput && d.titleInput.trailingIcon();
		if (icon && d.proj) {
			const curr = await d.proj.title();
			icon.setDisabled(title === curr);
		}
	}

	@SLOT
	private async saveCurrentTitle(): Promise<void> {
		const d = this.d;
		if (!d.titleInput || !d.proj) {
			return;
		}
		const curr = await d.proj.title();
		const newTitle = d.titleInput.text();
		if (newTitle === curr) {
			return;
		}
		await this.setTitle(newTitle);
		const icon = d.titleInput.trailingIcon();
		if (icon) {
			icon.setEnabled(false);
		}
	}

	@SLOT
	drawModeChanged(mode: MapDrawMode): void {
		switch (mode) {
			case MapDrawMode.NoMode: {
				if (this.isEditingFilterGeometry()) {
					this.stopEditingFilterGeometry();
				}
				break;
			}
		}
	}

	@SLOT
	private async bottomLinksItemActivated(item: FancyListItem): Promise<void> {
		const d = this.d;
		if (!d.proj) {
			return;
		}
		for (const [act, listItem] of d.bottomLinkItems) {
			if (listItem === item) {
				switch (act) {
					case BottomLink.ArchiveProject: {
						await d.proj.setArchived(
							!d.proj.isArchived(),
						);
						let icon: string;
						let text: string;
						if (d.proj.isArchived()) {
							icon = 'unarchive';
							text = 'Restore project';
						} else {
							icon = 'archive';
							text = 'Archive project';
						}
						item.setLeadingIcon(icon, true);
						item.setText(text);
						break;
					}
					case BottomLink.CopyProject: {
						const res = await d.proj.clone();
						window.location.assign(
							await res.absoluteUrl(),
						);
						break;
					}
					default: {
						logger.error('bottomLinksItemActivated: Invalid action type: %s', act);
						return;
					}
				}
			}
		}
	}

	@SLOT
	private mapInteractiveControlModeChanged(newMode: InteractiveMapControlMode): void {
		switch (newMode) {
			case InteractiveMapControlMode.DrawShape:
			case InteractiveMapControlMode.InfoMode: {
				this.closeFilterBox();
				break;
			}
		}
		if ((newMode !== InteractiveMapControlMode.InfoMode) && this.d.parcelInfo) {
			this.closePopups();
		}
	}

	@SLOT
	async ignoreParcel(parcel: Parcel | null): Promise<boolean> {
		if (!parcel) {
			return false;
		}
		const doNotMail = !(await parcel.isIgnored());
		const ref = await GeoRef.setDoNotMail(
			doNotMail,
			{parcelPk: await parcel.pk()},
		);
		await parcel.setIgnored(ref ? ref.doNotMail() : doNotMail);
		setTimeout(() => this.syncDoNotMailGeoRefs(), 0);
		setTimeout(() => this.syncStats(), 0);
		return true;
	}

	protected parentElDelegate(): ElObj | null {
		return this.d.body;
	}

	@SLOT
	private async payButtonClicked(): Promise<void> {
		const d = this.d;
		if (!d.downloadPay) {
			return;
		}
		d.downloadPay.setButtonEnabled(false);
		d.downloadPay.showProgress();
		await this.beginPayment();
	}

	@SLOT
	private async paymentEnded(success: boolean): Promise<void> {
		const d = this.d;
		if (d.proj) {
			await d.proj.processPayment();
		}
	}

	private async recalcInvoice(cb: (inv: IInvoice | null) => any): Promise<void> {
		const d = this.d;
		if (!d.proj) {
			return;
		}
		const invId = d.proj.invoiceId();
		if (invId === null) {
			return;
		}
		let canceled: boolean = false;
		if (d.cancelSrc.syncCounts) {
			// Currently awaiting response from previous request.
			d.cancelSrc.syncCounts.cancel();
		}
		d.cancelSrc.syncCounts = svc.cancelTokenSource();
		try {
			const invoice = await svc.invoice.get(
				invId,
				true,
				d.proj.slug(),
				d.cancelSrc.syncCounts.token,
			);
			// If we're here we our request was not canceled. Send it out.
			cb(invoice);
		} catch (exc) {
			if (svc.isCancellation(exc)) {
				// Our request was canceled by a subsequent call.
				canceled = true;
			} else {
				throw exc;
			}
		} finally {
			if (!canceled) {
				// This request was not canceled.
				// We're done.
				d.cancelSrc.syncCounts = null;
			}
		}
	}

	@SLOT
	private async syncDoNotMailGeoRefs(): Promise<void> {
		const d = this.d;
		d.map && d.map.setGeoRefSourceData(
			await GeoRef.list(),
		);
	}

	@SLOT
	async syncStats(): Promise<void> {
		const d = this.d;
		if (!d.stats) {
			return;
		}
		d.stats.beginSync();
		const cb = (inv: IInvoice | null) => {
			if (inv && d.stats) {
				d.stats.setAddressCount(inv.totalQuantity);
				d.stats.setPrice(inv.total);
			}
			d.stats && d.stats.endSync();
		};
		await this.recalcInvoice(cb);
	}

	@SLOT
	private async syncDownloadPayButton(): Promise<void> {
		const d = this.d;
		if (!d.downloadPay || !d.proj) {
			return;
		}
		let buttonEnabled: boolean;
		const absListUrl = await d.proj.absoluteListUrl();
		if (absListUrl.length > 0) {
			d.downloadPay.showDownloadButton(absListUrl);
			buttonEnabled = true;
		} else {
			d.downloadPay.showPayButton();
			buttonEnabled = !d.proj.isReadOnly();
		}
		d.downloadPay.setButtonEnabled(buttonEnabled);
		if (d.downloadPay.currentButton() === ActiveDownloadPayBtn.Download) {
			d.downloadPay.setButtonFlashing(true);
		}
	}

	@SLOT
	protected async textInputTextChanged(text: string): Promise<void> {
		const d = this.d;
		if (!d.textInput) {
			return;
		}
		if (text.trim().length > 0) {
			d.places = await Place.list(text);
			const items: Array<ICompleterItem> = [];
			for (const obj of d.places) {
				items.push({
					label: await obj.label(),
					text: await obj.name(),
				});
			}
			const compl = d.textInput.completer();
			if (compl) {
				compl.setItems(items);
				compl.show();
				compl.updatePosition();
			}
		} else {
			this.closeCompleter();
		}
	}

	@SLOT
	protected undoActionActivated(action: 'undo' | 'redo'): void {
		if (this.isEditingFilterGeometry()) {
			this.stopEditingFilterGeometry();
		}
		super.undoActionActivated(action);
	}
}
