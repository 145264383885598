import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {AbstractObj, OBJ, SIGNAL} from '../obj';
import {clamp} from '../util';
import {getLogger} from '../logging';
import {list, Point} from '../tools';
import {Evt, MouseEvt} from '../evt';
import {Icon} from './icon';

const logger = getLogger('ui.list');

export class FancyListItemPrivate extends ElObjPrivate {
	leadingObj: ElObj | null;
	lineText: {primary: ElObj; secondary: ElObj} | null;
	ripEl: ElObj | null;
	textEl: ElObj | null;
	trailingObj: ElObj | null;
	twoLine: boolean;

	constructor() {
		super();
		this.leadingObj = null;
		this.lineText = null;
		this.ripEl = null;
		this.textEl = null;
		this.trailingObj = null;
		this.twoLine = false;
	}

	init(opts: Partial<FancyListItemOpts>): void {
		super.init(opts);
		const q = this.q;
		this.ripEl = new ElObj({
			classNames: 'mdc-list-item__ripple',
			parent: q,
			tagName: 'span',
		});
		this.textEl = this.textElProto();
		this.textEl.addClass(
			'mdc-list-item__text',
			'lb-list-item__text',
		);
		this.textEl.setParent(q);
		if (opts.twoLine !== undefined) {
			q.setTwoLine(opts.twoLine);
		}
		if (opts.leadingObj) {
			q.setLeadingObj(
				opts.leadingObj,
			);
		} else if (opts.leadingIcon) {
			q.setLeadingIcon(
				opts.leadingIcon.name,
				opts.leadingIcon.outlined,
			);
		}
		if (opts.text) {
			q.setText(opts.text);
		}
		if (opts.secondaryText) {
			q.setSecondaryText(
				opts.secondaryText,
			);
		}
		if (opts.trailingObj) {
			q.setTrailingObj(
				opts.trailingObj,
			);
		} else if (opts.trailingIcon) {
			q.setTrailingIcon(
				opts.trailingIcon.name,
				opts.trailingIcon.outlined,
			);
		}
		q.addEventListener('mousedown');
		q.addEventListener('mouseup');
		q.addEventListener('dblclick');
	}

	primaryTextEl(): ElObj | null {
		return this.lineText ?
			this.lineText.primary :
			this.textEl;
	}

	get q(): FancyListItem {
		return <FancyListItem>super.q;
	}

	textElProto(): ElObj {
		return new ElObj({
			tagName: 'span',
		});
	}
}

export interface FancyListItemOpts extends ElObjOpts {
	dd: FancyListItemPrivate;
	leadingIcon: {name: string; outlined?: boolean;},
	leadingObj: ElObj;
	secondaryText: string;
	text: string;
	trailingIcon: {name: string; outlined?: boolean;},
	trailingObj: ElObj;
	twoLine: boolean;
}

@OBJ
export class FancyListItem extends ElObj {
	static tagName: TagName = 'li';

	constructor(opts: Partial<FancyListItemOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-list-item',
			'lb-list-item',
		);
		opts.dd = opts.dd || new FancyListItemPrivate();
		super(opts);
	}

	@SIGNAL
	private clicked(point: Point): void {
	}

	get d(): FancyListItemPrivate {
		return <FancyListItemPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.leadingObj = null;
		d.lineText = null;
		d.ripEl = null;
		d.textEl = null;
		d.trailingObj = null;
		super.destroy();
	}

	index(): number {
		const par = this.parentList();
		return par ?
			par.index(this) :
			-1;
	}

	leadingObj(): ElObj | null {
		return this.d.leadingObj;
	}

	protected mousePressEvent(event: MouseEvt): void {
		event.accept();
	}

	protected mouseReleaseEvent(event: MouseEvt): void {
		const par = this.parentList();
		if (par && (this.index() >= 0)) {
			par.itemActivated(this);
		}
	}

	parentList(): FancyList | null {
		let curr = this.parentEl();
		while (curr) {
			if (curr instanceof FancyList) {
				return curr;
			}
			curr = curr.parentEl();
		}
		return null;
	}

	primaryText(): string {
		return this.text();
	}

	secondaryText(): string {
		const d = this.d;
		if (d.lineText) {
			return d.lineText.secondary.text();
		}
		logger.warning('secondaryText: Multi-line not set.');
		return '';
	}

	setLeadingIcon(icon: string | null, outlined: boolean = false): void {
		const d = this.d;
		if ((typeof icon !== 'string') || (icon.trim().length < 1)) {
			const par = d.leadingObj && d.leadingObj.parentEl();
			if (par) {
				par.destroy();
			}
			d.leadingObj = null;
			return;
		}
		const par = new ElObj({
			classNames: [
				'mdc-list-item__graphic',
				'lb-list-item__leading-obj',
			],
			tagName: 'span',
		});
		par.setParent(this, 0);
		d.leadingObj = new Icon({
			name: icon,
			outlined,
			parent: par,
		});
		par.show();
	}

	setLeadingObj(obj: ElObj | null): void {
		const d = this.d;
		if (!obj) {
			const par = d.leadingObj && d.leadingObj.parentEl();
			if (par) {
				par.destroy();
			}
			d.leadingObj = null;
			return;
		}
		d.leadingObj = obj;
		const par = new ElObj({
			classNames: [
				'mdc-list-item__graphic',
				'lb-list-item__leading-obj',
			],
		});
		par.setParent(this, 1);
		obj.setParent(par);
		par.show();
	}

	setPrimaryText(text?: string | null): void {
		this.setText(text);
	}

	setSecondaryText(text?: string | null): void {
		const d = this.d;
		if (d.lineText) {
			d.lineText.secondary.setText(text);
		} else {
			logger.warning('setSecondaryText: Multi-line not set.');
		}
	}

	setSelected(selected: boolean): void {
		this.setClass(selected, 'mdc-list-item--selected');
		this.setAttribute('aria-selected', String(selected));
	}

	setText(text?: string | null): void {
		const el = this.d.primaryTextEl();
		if (el) {
			el.setText(text);
		}
	}

	setTrailingIcon(icon: string | null, outlined: boolean = false): void {
		const d = this.d;
		if ((typeof icon !== 'string') || (icon.trim().length < 1)) {
			const par = d.trailingObj && d.trailingObj.parentEl();
			if (par) {
				par.destroy();
			}
			d.trailingObj = null;
			return;
		}
		const par = new ElObj({
			classNames: [
				'mdc-list-item__graphic',
				'lb-list-item__trailing-obj',
			],
			parent: this,
			tagName: 'span',
		});
		d.trailingObj = new Icon({
			name: icon,
			outlined,
			parent: par,
		});
		par.show();
	}

	setTrailingObj(obj: ElObj | null): void {
		const d = this.d;
		if (!obj) {
			const par = d.trailingObj && d.trailingObj.parentEl();
			if (par) {
				par.destroy();
			}
			d.trailingObj = null;
			return;
		}
		d.trailingObj = obj;
		const par = new ElObj({
			classNames: [
				'mdc-list-item__meta',
				'lb-list-item__trailing-obj',
			],
			parent: this,
		});
		obj.setParent(par);
		par.show();
	}

	setTwoLine(twoLine: boolean): void {
		const d = this.d;
		if (twoLine === d.twoLine) {
			return;
		}
		d.twoLine = twoLine;
		if (d.twoLine) {
			if (!d.lineText) {
				d.lineText = {
					primary: new ElObj({
						classNames: 'mdc-list-item__primary-text',
						parent: d.textEl,
						tagName: 'span',
					}),
					secondary: new ElObj({
						classNames: 'mdc-list-item__secondary-text',
						parent: d.textEl,
						tagName: 'span',
					}),
				};
			}
		} else {
			if (d.lineText) {
				d.lineText.primary.destroy();
				d.lineText.secondary.destroy();
			}
			d.lineText = null;
		}
	}

	text(): string {
		const el = this.d.primaryTextEl();
		return el ?
			el.text() :
			'';
	}

	trailingObj(): ElObj | null {
		return this.d.trailingObj;
	}
}

interface FancyItemPrototype {
	new(...args: Array<any>): FancyListItem;
}

export class FancyListPrivate extends ElObjPrivate {
	compact: boolean;
	itemProto: FancyItemPrototype;
	twoLine: boolean;

	constructor() {
		super();
		this.compact = false;
		this.itemProto = FancyListItem;
		this.twoLine = false;
	}

	init(opts: Partial<FancyListOpts>): void {
		super.init(opts);
		if (opts.itemPrototype) {
			this.itemProto = opts.itemPrototype;
		}
		const q = this.q;
		if (opts.compact !== undefined) {
			this.compact = opts.compact;
		}
		q.setClass(this.compact, 'lb-table-column-picker-list');
		if (opts.twoLine !== undefined) {
			this.twoLine = opts.twoLine;
		}
		q.setClass(this.twoLine, 'mdc-list--two-line');
	}

	get q(): FancyList {
		return <FancyList>super.q;
	}
}

export interface FancyListOpts extends ElObjOpts {
	compact: boolean;
	dd: FancyListPrivate;
	itemPrototype: FancyItemPrototype;
	twoLine: boolean;
}

@OBJ
export class FancyList extends ElObj {
	static tagName: TagName = 'ul';

	constructor(opts: Partial<FancyListOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-list',
			'lb-ugh-list',
		);
		opts.dd = opts.dd || new FancyListPrivate();
		super(opts);
	}

	addItem(item: Partial<FancyListItemOpts> | FancyListItem): void {
		this.insertItem(
			this.children().size(),
			item,
		);
	}

	clear(): void {
		this.d.deleteChildren();
	}

	children(): list<FancyListItem> {
		return <list<FancyListItem>>super.children();
	}

	count(): number {
		return this.size();
	}

	currentRow(): number {
		for (let i = 0; i < this.children().size(); ++i) {
			if (this.children().at(i).hasFocus()) {
				return i;
			}
		}
		return -1;
	}

	get d(): FancyListPrivate {
		return <FancyListPrivate>super.d;
	}

	// eventFilter(watched: AbstractObj, event: Evt): boolean {
	// 	// NB: No type checking occurs here b/c the only object being
	// 	//     filtered is the ListItem. If you start filtering on other
	// 	//     objects, be sure to place type checks.
	// 	switch (event.type()) {
	// 		case Evt.MouseButtonPress:
	// 		case Evt.MouseButtonRelease: {
	// 			if (event.type() === Evt.MouseButtonRelease) {
	// 				this.itemActivated(
	// 					<FancyListItem>watched,
	// 					(<MouseEvt>event).pos(),
	// 				);
	// 			}
	// 			return true;
	// 		}
	// 		default: {
	// 			return super.eventFilter(watched, event);
	// 		}
	// 	}
	// }

	index(item: FancyListItem): number {
		return this.children().indexOf(item);
	}

	insertItem(index: number, item: Partial<FancyListItemOpts> | FancyListItem): void {
		const d = this.d;
		index = clamp(0, index, this.children().size());
		let obj: FancyListItem;
		if (item instanceof FancyListItem) {
			obj = item;
			obj.setTwoLine(d.twoLine);
		} else {
			item.twoLine = d.twoLine;
			obj = new d.itemProto(item);
		}
		// obj.installEventFilter(this);
		obj.setParent(this, index);
		obj.show();
	}

	isEmpty(): boolean {
		return this.children().isEmpty();
	}

	item(index: number): FancyListItem | null {
		if ((index >= 0) && (index < this.children().size())) {
			return this.children().at(index);
		}
		return null;
	}

	@SIGNAL
	itemActivated(item: FancyListItem): void {
	}

	itemPrototype(): FancyItemPrototype {
		return this.d.itemProto;
	}

	removeItem(index: number): boolean {
		if ((index >= 0) && (index < this.children().size())) {
			const obj = this.children().at(index);
			obj.removeEventFilter(this);
			obj.setParent(null);
			obj.destroy();
			return true;
		}
		return false;
	}

	setItemPrototype(itemPrototype: FancyItemPrototype | null): void {
		this.d.itemProto = itemPrototype || FancyListItem;
	}

	size(): number {
		return this.children().size();
	}

	takeItem(index: number): FancyListItem | null {
		const item = this.item(index);
		if (item) {
			item.setParent(null);
		}
		return item;
	}

	*[Symbol.iterator](): IterableIterator<FancyListItem> {
		yield *this.children();
	}
}
