import {FancyListItem, FancyListItemOpts, FancyListItemPrivate} from '../../ui/list';
import {Obj, OBJ, SLOT} from '../../obj';
import {Checkbox} from '../../ui/checkbox';
import {CheckState} from '../../constants';
import {ElObj} from '../../elobj';
import {ToolButton} from '../../ui/toolbutton';
import {Icon} from '../../ui/icon';
import {IViewItem} from './item';
import {LinearProgress} from '../../ui/progress';
import {Layout} from './layout';

export class RowItemPrivate extends FancyListItemPrivate {
	btn: ToolButton | null;
	check: Checkbox | null;
	prog: LinearProgress | null;

	constructor() {
		super();
		this.btn = null;
		this.check = null;
		this.prog = null;
	}

	init(opts: Partial<RowItemOpts>): void {
		opts.twoLine = true;
		super.init(opts);
		const q = this.q;
		this.check = new Checkbox();
		q.setLeadingObj(
			this.check,
		);
		Obj.connect(
			this.check, 'stateChanged',
			q, 'checkStateChanged',
		);
		this.btn = new ToolButton();
		this.btn.setIcon(
			new Icon({name: 'more_vert'}),
		);
		q.setTrailingObj(
			this.btn,
		);
		Obj.connect(
			this.btn, 'clicked',
			q, 'openMenu',
		);
		this.prog = new LinearProgress({
			indeterminate: true,
			parent: q,
		});
		this.prog.hide();
	}

	get q(): RowItem {
		return <RowItem>super.q;
	}

	textElProto(): ElObj {
		return new ElObj({
			attributes: [
				['href', '#'],
			],
			classNames: [
				'lb-project-list-layout-item__text',
			],
			tagName: 'a',
		});
	}
}

export interface RowItemOpts extends FancyListItemOpts {
	dd: RowItemPrivate;
}

@OBJ
export class RowItem extends FancyListItem implements IViewItem {
	layout: Layout | null;

	constructor(opts: Partial<RowItemOpts> = {}) {
		opts.classNames = FancyListItem.mergeClassNames(
			opts.classNames,
			'lb-project-list-layout-item',
		);
		opts.dd = opts.dd || new RowItemPrivate();
		super(opts);
		this.layout = null;
	}

	checkBox(): Checkbox | null {
		return this.d.check;
	}

	checkState(): CheckState {
		const d = this.d;
		return d.check ?
			d.check.checkState() :
			CheckState.Unchecked;
	}

	@SLOT
	protected checkStateChanged(newState: CheckState): void {
		if (!this.layout || !this.layout.view) {
			return;
		}
		const idx = this.layout.index(this);
		if (idx < 0) {
			return;
		}
		const item = this.layout.view.item(idx);
		if (!item) {
			return;
		}
		item.setCheckState(newState);
	}

	get d(): RowItemPrivate {
		return <RowItemPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		if (this.layout) {
			this.layout.d.items.removeAll(this);
		}
		this.layout = null;
		d.check = null;
		super.destroy();
	}

	image(): string {
		// noop
		return '';
	}

	isBusy(): boolean {
		const d = this.d;
		return !!d.prog && d.prog.isVisible();
	}

	isChecked(): boolean {
		const d = this.d;
		return d.check ?
			d.check.isChecked() :
			false;
	}

	@SLOT
	protected openMenu(): void {
		if (this.layout && this.layout.view) {
			this.layout.view.openProjectOptionMenu(
				this.layout.index(this),
			);
		}
	}

	primaryButtonText(): string {
		// noop
		return '';
	}

	setBusy(busy: boolean): any {
		const d = this.d;
		if (d.prog) {
			d.prog.setVisible(busy);
		}
	}

	setChecked(checked: boolean): void {
		const d = this.d;
		if (d.check) {
			d.check.setChecked(checked);
		}
	}

	setCheckState(state: CheckState): void {
		const d = this.d;
		if (d.check) {
			d.check.setCheckState(state);
		}
	}

	setImage(url: string): any {
		// noop
	}

	setPrimaryButtonText(text: string): any {
		// noop
	}

	setSubTitle(subTitle: string): any {
		this.setSecondaryText(subTitle);
	}

	setTitle(title: string): any {
		this.setPrimaryText(title);
	}

	setUrl(url: string): any {
		const el = this.d.textEl;
		if (el) {
			el.setAttribute(
				'href',
				(url.length > 0) ?
					url :
					'#',
			);
		}
	}

	subTitle(): string {
		return this.secondaryText();
	}

	title(): string {
		return this.primaryText();
	}

	url(): string {
		const el = this.d.textEl;
		return (el && el.attribute('href')) || '';
	}
}
