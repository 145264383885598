import {assert, stringCmp} from './util';
import {CaseSensitivity, INFINITY_CHAR_CODE} from './constants';
import {date, datetime, time, timedelta} from './datetime';
import {Url} from './tools';
import {Decimal} from './decimal';
import {MetaType} from './metatype';
import {Icon} from './ui/icon';

class D {
	ptr: unknown = null;

	get b(): boolean {
		return <boolean>this.ptr;
	}

	set b(val: boolean) {
		this.ptr = val;
	}

	get n(): number {
		return <number>this.ptr;
	}

	set n(val: number) {
		this.ptr = val;
	}
}

class Private {
	data: D;
	isNull: boolean;
	type: number;

	constructor(other: Private);
	constructor(variantType: number);
	constructor();
	constructor(a?: Private | number) {
		if (a === undefined) {
			this.data = new D();
			this.isNull = true;
			this.type = MetaType.Invalid;
		} else {
			if (typeof a === 'number') {
				this.data = new D();
				this.isNull = false;
				this.type = a;
			} else {
				this.data = a.data;
				this.isNull = a.isNull;
				this.type = a.type;
			}
		}
	}
}

interface Result {
	data: unknown;
}

function convertToNumber(d: Private): [number, boolean] {
	switch (d.type) {
		case MetaType.String:
			return [(d.data.ptr === String.fromCharCode(INFINITY_CHAR_CODE)) ? Number.POSITIVE_INFINITY : Number(d.data.ptr), true];
		case MetaType.Boolean:
			return [Number(d.data.b), true];
		case MetaType.TimeDelta:
			return [(<timedelta>d.data.ptr).totalSeconds(), true];
		case MetaType.Number:
		case MetaType.Decimal:
			return [metaTypeNumber(d), true];
	}
	return [0, false];
}

function metaTypeNumber(d: Private): number {
	switch (d.type) {
		case MetaType.Number:
			return d.data.n;
		case MetaType.Decimal:
			return Number(d.data.ptr);
	}
	assert(false);
	return 0;
}

function isNumericType(type: number): boolean {
	switch (type) {
		case MetaType.Number:
		case MetaType.Boolean:
		case MetaType.Decimal:
			return true;
		default:
			return false;
	}
}

function eqCmp<T extends Ieq>(a: T, b: T): boolean {
	return a.eq(b);
}

function construct(type: MetaType.Type): unknown {
	switch (type) {
		case MetaType.Boolean:
			return false;
		case MetaType.Number:
			return 0;
		case MetaType.String:
			return '';
		case MetaType.Icon:
			return new Icon();
		case MetaType.Url:
			return new Url();
		case MetaType.DateTime:
			return new datetime(1, 1, 1);
		case MetaType.Date:
			return new date(1, 1, 1);
		case MetaType.Time:
			return new time();
		case MetaType.TimeDelta:
			return new timedelta();
		case MetaType.Decimal:
			return new Decimal();
		case MetaType.Variant:
			return new Variant();
		case MetaType.Null:
			return null;
	}
	assert(false);
}

export class Variant {
	static convert(d: Private, targetType: number, result: Result): boolean {
		assert(d.type !== targetType);
		switch (targetType) {
			case MetaType.Url:
				switch (d.type) {
					case MetaType.String:
						result.data = new Url(<string>d.data.ptr);
						break;
					default:
						return false;
				}
				break;
			case MetaType.String: {
				switch (d.type) {
					case MetaType.Number:
						result.data = d.data.n.toLocaleString(undefined, {useGrouping: false});
						break;
					case MetaType.Icon:
						result.data = (<Icon>d.data.ptr).name();
						break;
					case MetaType.Url:
						result.data = (<Url>d.data.ptr).toString();
						break;
					case MetaType.DateTime:
						result.data = (<datetime>d.data.ptr).isoformat();
						break;
					case MetaType.TimeDelta:
						result.data = (<timedelta>d.data.ptr).toString();
						break;
					case MetaType.Date:
						result.data = (<date>d.data.ptr).isoformat();
						break;
					case MetaType.Time:
						result.data = (<time>d.data.ptr).isoformat();
						break;
					case MetaType.Boolean:
						result.data = d.data.b ? 'true' : 'false';
						break;
					case MetaType.Decimal:
						result.data = (<Decimal>d.data.ptr).toString();
						break;
					case MetaType.Null:
						result.data = '';
						break;
					default:
						return false;
				}
				break;
			}
			case MetaType.Icon: {
				switch (d.type) {
					case MetaType.String: {
						result.data = new Icon({name: <string>d.data.ptr});
						break;
					}
					default: {
						return false;
					}
				}
				return true;
			}
			case MetaType.DateTime: {
				switch (d.type) {
					case MetaType.String:
						try {
							result.data = datetime.fromisoformat(<string>d.data.ptr);
						} catch {
							return false;
						}
						break;
					case MetaType.Date:
						result.data = datetime.combine(<date>d.data.ptr, new time());
						break;
					default:
						return false;
				}
				return true;
			}
			case MetaType.TimeDelta: {
				switch (d.type) {
					case MetaType.String:
						try {
							result.data = new timedelta(undefined, Number.parseInt(<string>d.data.ptr));
						} catch {
							return false;
						}
						break;
					case MetaType.Number:
						result.data = new timedelta(undefined, <number>d.data.ptr);
						break;
					default:
						return false;
				}
				return true;
			}
			case MetaType.Date: {
				if (d.type === MetaType.DateTime) {
					result.data = (<datetime>d.data.ptr).date();
				} else if (d.type === MetaType.String) {
					try {
						result.data = date.fromisoformat(<string>d.data.ptr);
					} catch {
						return false;
					}
				} else {
					return false;
				}
				return true;
			}
			case MetaType.Time: {
				switch (d.type) {
					case MetaType.DateTime:
						result.data = (<datetime>d.data.ptr).time();
						break;
					case MetaType.String:
						try {
							result.data = time.fromisoformat(<string>d.data.ptr);
						} catch {
							return false;
						}
						break;
					default:
						return false;
				}
				return true;
			}
			case MetaType.Number:
			case MetaType.Decimal: {
				let ok: boolean;
				[result.data, ok] = convertToNumber(d);
				return ok;
			}
			case MetaType.Boolean: {
				switch (d.type) {
					case MetaType.String:
						const s = (<string>d.data.ptr).toLowerCase();
						result.data = !((s.length < 1) || (s === '0') || (s === 'false'));
						break;
					case MetaType.Number:
					case MetaType.Decimal:
						result.data = metaTypeNumber(d) !== 0;
						break;
					default:
						result.data = false;
						return false;
				}
				break;
			}
			case MetaType.Null:
				return false;
			default:
				return false;
		}
		return true;
	}

	static eq(a: Variant, b: Variant): boolean {
		return a.eq(b);
	}

	static fromType(type: MetaType.Type): Variant {
		return new this(type, null);
	}

	static nameToType(name: string): MetaType.Type {
		let metaType: MetaType.Type = MetaType.Invalid;
		if (name in MetaType.Type) {
			metaType = MetaType.Type[<keyof typeof MetaType.Type>name];
		}
		return metaType;
	}

	static ne(a: Variant, b: Variant): boolean {
		return a.ne(b);
	}

	static typeToName(type: number): string {
		if (type in MetaType.Type) {
			return MetaType.Type[type];
		}
		return '';
	}

	private d: Private;

	constructor(url: Url);
	constructor(dt: datetime);
	constructor(dt: timedelta);
	constructor(d: date);
	constructor(tm: time);
	constructor(dec: Decimal);
	constructor(icon: Icon);
	constructor(bool: boolean);
	constructor(num: number);
	constructor(str: string);
	constructor(nul: null);
	constructor(other: Variant);
	constructor(thing?: Icon | Url | datetime | timedelta | date | time | Decimal | boolean | number | string | null | Variant);
	constructor(type: number, val: unknown);
	constructor();
	constructor(a?: number | string | boolean | Icon | datetime | timedelta | date | time | Decimal | Url | Variant | null, b?: unknown) {
		if (a === undefined) {
			this.d = new Private();
		} else if ((typeof a === 'number') && (b !== undefined)) {
			this.d = new Private(a);
			if (b === null && a !== MetaType.Null) {
				b = construct(a);
			}
			this.d.data.ptr = b;
		} else {
			let metaType = valueMetaType(a);
			if (metaType === MetaType.Variant) {
				const val = <Variant>a;
				this.d = new Private(val.d);
				val.d = new Private();
			} else {
				this.d = new Private(metaType);
				if (metaType !== MetaType.Invalid) {
					switch (metaType) {
						case MetaType.Number:
							this.d.data.n = <number>a;
							break;
						case MetaType.Boolean:
							this.d.data.b = <boolean>a;
							break;
						case MetaType.String:
						case MetaType.Url:
						case MetaType.DateTime:
						case MetaType.TimeDelta:
						case MetaType.Date:
						case MetaType.Time:
						case MetaType.Decimal:
						case MetaType.Null:
							this.d.data.ptr = a;
							break;
					}
				}
			}
		}
	}

	canConvert(targetTypeId: number): boolean {
		const d = this.d;
		if (targetTypeId < 0) {
			return false;
		}
		if (d.type === targetTypeId) {
			return true;
		}
		const currentType = d.type;
		switch (targetTypeId) {
			case MetaType.Boolean:
				return (currentType === MetaType.Number)
					|| (currentType === MetaType.String)
					|| (currentType === MetaType.Decimal);
			case MetaType.Number:
				return (currentType === MetaType.Boolean)
					|| (currentType === MetaType.String)
					|| (currentType === MetaType.TimeDelta)
					|| (currentType === MetaType.Decimal);
			case MetaType.String:
				return (currentType === MetaType.Number)
					|| (currentType === MetaType.Boolean)
					|| (currentType === MetaType.Icon)
					|| (currentType === MetaType.Url)
					|| (currentType === MetaType.DateTime)
					|| (currentType === MetaType.TimeDelta)
					|| (currentType === MetaType.Date)
					|| (currentType === MetaType.Time)
					|| (currentType === MetaType.Decimal);
			case MetaType.Icon:
				return (currentType === MetaType.String);
			case MetaType.Url:
				return (currentType === MetaType.String);
			case MetaType.DateTime:
				return (currentType === MetaType.String)
					|| (currentType === MetaType.Date);
			case MetaType.TimeDelta:
				return (currentType === MetaType.Number)
					|| (currentType === MetaType.String);
			case MetaType.Date:
				return (currentType === MetaType.String)
					|| (currentType === MetaType.DateTime);
			case MetaType.Time:
				return (currentType === MetaType.String)
					|| (currentType === MetaType.DateTime);
			case MetaType.Decimal:
				return (currentType === MetaType.Boolean)
					|| (currentType === MetaType.String)
					|| (currentType === MetaType.TimeDelta)
					|| (currentType === MetaType.Number);
		}
		return false;
	}

	clear(): void {
		const d = this.d;
		d.type = MetaType.Invalid;
		d.isNull = true;
		d.data.ptr = null;
	}

	convert(targetTypeId: number): boolean {
		if (this.d.type === targetTypeId) {
			return true;
		}
		const oldValue = new Variant(this);
		this.clear();
		if (!oldValue.canConvert(targetTypeId)) {
			return false;
		}
		this.create(targetTypeId, null);
		if (oldValue.d.isNull && (oldValue.d.type !== MetaType.Null)) {
			return false;
		}
		let isOk: boolean = true;
		const res = {data: null};
		if (!Variant.convert(oldValue.d, targetTypeId, res)) {
			isOk = false;
		}
		this.d.data.ptr = res.data;
		this.d.isNull = !isOk;
		return isOk;
	}

	private create(typeId: number, value: unknown): void {
		this.d.type = typeId;
		this.d.data.ptr = value;
	}

	destroy(): void {
		this.clear();
	}

	eq(other: Variant): boolean {
		if (!this.isValid() || !other.isValid()) {
			return false;
		}
		if (this.isNull() && other.isNull()) {
			return true;
		}
		if (isNumericType(this.d.type) && isNumericType(other.d.type)) {
			return convertToNumber(this.d) === convertToNumber(other.d);
		}
		if (this.d.type === other.d.type) {
			switch (this.d.type) {
				case MetaType.Icon:
				case MetaType.Url:
				case MetaType.DateTime:
				case MetaType.TimeDelta:
				case MetaType.Date:
				case MetaType.Time:
					return eqCmp(<Ieq>this.d.data.ptr, <Ieq>other.d.data.ptr);
				case MetaType.String:
					return (<string>this.d.data.ptr).localeCompare(<string>other.d.data.ptr) === 0;
			}
		}
		return false;
	}

	isNull(): boolean {
		const d = this.d;
		if (d.isNull) {
			return true;
		}
		const typeName = Variant.typeToName(d.type);
		if (!typeName) {
			console.log('Variant::isNull: type %o unknown to Variant.', d.type);
		}
		return false;
	}

	isValid(): boolean {
		return this.d.type !== MetaType.Invalid;
	}

	ne(other: Variant): boolean {
		return !this.eq(other);
	}

	setValue<T>(value: T, typeId?: number): void {
		const d = this.d;
		if (typeId === undefined) {
			typeId = valueMetaType(value);
		}
		if (typeId === d.type) {
			d.type = typeId;
			d.isNull = false;
			d.data.ptr = value;
		} else {
			const v = new Variant(typeId, value);
			this.d = v.d;
			v.d = new Private();
		}
	}

	toBoolean(): boolean {
		const d = this.d;
		if (d.type === MetaType.Boolean) {
			return d.data.b;
		}
		const result = {data: false};
		Variant.convert(d, MetaType.Boolean, result);
		return result.data;
	}

	toDate(): date {
		const d = this.d;
		if (d.type === MetaType.Date) {
			return <date>d.data.ptr;
		}
		const result = <{data: date | null}>{data: null};
		if (!Variant.convert(d, MetaType.Date, result)) {
			result.data = new date(1, 1, 1);
		}
		assert(result.data);
		return result.data;
	}

	toDateTime(): datetime {
		const d = this.d;
		if (d.type === MetaType.DateTime) {
			return <datetime>d.data.ptr;
		}
		const result = <{data: datetime | null}>{data: null};
		if (!Variant.convert(d, MetaType.DateTime, result)) {
			result.data = new datetime(1, 1, 1);
		}
		assert(result.data);
		return result.data;
	}

	toDecimal(): Decimal {
		const d = this.d;
		if (d.type === MetaType.Decimal) {
			return <Decimal>d.data.ptr;
		}
		const result = {data: new Decimal()};
		Variant.convert(d, MetaType.Decimal, result);
		return result.data;
	}

	toIcon(): Icon {
		const d = this.d;
		if (d.type === MetaType.Icon) {
			return <Icon>d.data.ptr;
		}
		const result = <{data: Icon | null}>{data: null};
		if (!Variant.convert(d, MetaType.Icon, result)) {
			result.data = new Icon();
		}
		assert(result.data);
		return result.data;
	}

	toNumber(): number {
		const d = this.d;
		if (d.type === MetaType.Number) {
			return d.data.n;
		}
		const result = {data: 0};
		Variant.convert(d, MetaType.Number, result);
		return result.data;
	}

	toString(): string {
		const d = this.d;
		if (d.type === MetaType.String) {
			return <string>d.data.ptr;
		}
		const result = {data: ''};
		Variant.convert(d, MetaType.String, result);
		return result.data;
	}

	toTime(): time {
		const d = this.d;
		if (d.type === MetaType.Time) {
			return <time>d.data.ptr;
		}
		const result = <{data: time | null}>{data: null};
		if (!Variant.convert(d, MetaType.Time, result)) {
			result.data = new time();
		}
		assert(result.data);
		return result.data;
	}

	toTimeDelta(): timedelta {
		const d = this.d;
		if (d.type === MetaType.TimeDelta) {
			return <timedelta>d.data.ptr;
		}
		const result = <{data: timedelta | null}>{data: null};
		if (!Variant.convert(d, MetaType.TimeDelta, result)) {
			result.data = new timedelta();
		}
		assert(result.data);
		return result.data;
	}

	toUrl(): Url {
		const d = this.d;
		if (d.type === MetaType.Url) {
			return <Url>d.data.ptr;
		}
		const result = {data: new Url()};
		Variant.convert(d, MetaType.Url, result);
		return result.data;
	}

	type(): number {
		return this.d.type;
	}

	typeName(): string {
		return Variant.typeToName(this.d.type);
	}
}

function valueMetaType(val: unknown): MetaType.Type {
	if (val === undefined) {
		return MetaType.Invalid;
	}
	if (val === null) {
		return MetaType.Null;
	}
	if (typeof val === 'number') {
		return MetaType.Number;
	}
	if (typeof val === 'string') {
		return MetaType.String;
	}
	if (typeof val === 'boolean') {
		return MetaType.Boolean;
	}
	if (val instanceof Icon) {
		return MetaType.Icon;
	}
	if (val instanceof Url) {
		return MetaType.Url;
	}
	if (val instanceof datetime) {
		return MetaType.DateTime;
	}
	if (val instanceof timedelta) {
		return MetaType.TimeDelta;
	}
	if (val instanceof date) {
		return MetaType.Date;
	}
	if (val instanceof time) {
		return MetaType.Time;
	}
	if (val instanceof Decimal) {
		return MetaType.Decimal;
	}
	if (val instanceof Variant) {
		return MetaType.Variant;
	}
	return MetaType.Invalid;
}

export function variantCmp(left: Variant, right: Variant, cs: CaseSensitivity = CaseSensitivity.CaseSensitive, isLocaleAware: boolean = false): number {
	if (left.type() === MetaType.Invalid) {
		return 1;
	}
	if (right.type() === MetaType.Invalid) {
		return -1;
	}
	switch (left.type()) {
		case MetaType.Number: {
			const leftN = left.toNumber();
			const rightN = right.toNumber();
			if (leftN < rightN) {
				return -1;
			}
			if (leftN > rightN) {
				return 1;
			}
			return 0;
		}
		case MetaType.Icon: {
			return left.toIcon().cmp(right.toIcon());
		}
		case MetaType.DateTime: {
			return left.toDateTime()._cmp(right.toDateTime());
		}
		case MetaType.TimeDelta: {
			return left.toTimeDelta()._cmp(right.toTimeDelta());
		}
		case MetaType.Decimal: {
			return left.toDecimal().cmp(right.toDecimal());
		}
		case MetaType.Date: {
			return left.toDate()._cmp(right.toDate());
		}
		case MetaType.Time: {
			return left.toTime()._cmp(right.toTime());
		}
		default:
			return stringCmp(left.toString(), right.toString(), cs, isLocaleAware);
	}
}
