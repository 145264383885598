import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {Point} from '../../tools';
import {FancyList, FancyListItem, FancyListItemOpts} from '../list';
import {MDCMenu} from './ctrl';
import {HideEvt, KeyEvt, ShowEvt} from '../../evt';
import {assert, isNumber} from '../../util';

export class MenuPrivate extends ElObjPrivate {
	ctrl: MDCMenu | null;
	explictPos: Point | null;
	list: FancyList | null;

	constructor() {
		super();
		this.ctrl = null;
		this.explictPos = null;
		this.list = null;
	}

	init(opts: Partial<MenuOpts>): void {
		super.init(opts);
		const q = this.q;
		this.list = new FancyList({
			attributes: [
				['role', 'menu'],
				['aria-hidden', 'true'],
				['aria-orientation', 'vertical'],
				['tabindex', '-1'],
			],
			compact: opts.compact,
			parent: q,
		});
		Obj.connect(
			this.list, 'itemActivated',
			q, '_listItemActivated',
		);
	}

	get q(): Menu {
		return <Menu>super.q;
	}

	setCtrlOpen(open: boolean): void {
		const q = this.q;
		if (open) {
			if (!this.ctrl) {
				this.ctrl = new MDCMenu(
					this.q.element(),
				);
			}
			if (this.explictPos) {
				this.ctrl.setAbsolutePosition(
					this.explictPos.x(),
					this.explictPos.y(),
				);
			}
			q.addEventListener('MDCMenuSurface:closed');
			q.addEventListener('MDCMenuSurface:closing');
			q.addEventListener('MDCMenuSurface:opened');
			this.ctrl.open = true;
		} else {
			if (this.ctrl) {
				this.ctrl.open = false;
			}
			q.removeEventListener('MDCMenuSurface:closed');
			q.removeEventListener('MDCMenuSurface:closing');
			q.removeEventListener('MDCMenuSurface:opened');
		}
	}

	setExplicitPos(pos: Point): void {
		if (this.explictPos && this.explictPos.eq(pos)) {
			return;
		}
		this.explictPos = pos;
		if (this.ctrl) {
			this.ctrl.setAbsolutePosition(
				this.explictPos.x(),
				this.explictPos.y(),
			);
		}
	}
}

export interface MenuOpts extends ElObjOpts {
	compact: boolean;
	dd: MenuPrivate;
	fullWidth: boolean;
}

@OBJ
export class Menu extends ElObj {
	constructor(opts: Partial<MenuOpts> = {}) {
		const classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-menu',
			'mdc-menu',
			'mdc-menu-surface',
		);
		if (opts.fullWidth) {
			classNames.unshift('mdc-menu-surface--fullwidth');
		}
		opts.classNames = classNames;
		opts.dd = opts.dd || new MenuPrivate();
		super(opts);
	}

	addItem(item: Partial<FancyListItemOpts> | FancyListItem | string): void {
		const d = this.d;
		if (!d.list) {
			return;
		}
		if (typeof item === 'string') {
			item = {
				text: item,
			};
		}
		d.list.addItem(item);
		const ii = d.list.item(
			d.list.count() - 1,
		);
		if (ii) {
			// FIXME: What's this for?
			ii.d.temporarilyDoNotStopDomEventPropagation = true;
		}
	}

	@SLOT
	clear(): void {
		const d = this.d;
		if (d.list) {
			d.list.clear();
		}
	}

	@SIGNAL
	private closed(): void {
	}

	@SIGNAL
	private closing(): void {
	}

	count(): number {
		const d = this.d;
		return d.list ?
			d.list.count() :
			0;
	}

	private ctrlClosedEvent(): void {
		this.removeWindowEventListener('blur');
		this.removeWindowEventListener('click');
		this.removeWindowEventListener('keydown');
		this.removeWindowEventListener('resize');
		this.closed();
	}

	private ctrlClosingEvent(): void {
		this.closing();
	}

	private ctrlOpenedEvent(): void {
		this.addWindowEventListener('blur');
		this.addWindowEventListener('click');
		this.addWindowEventListener('keydown');
		this.addWindowEventListener('resize');
		this.opened();
	}

	get d(): MenuPrivate {
		return <MenuPrivate>super.d;
	}

	destroy(): void {
		try {
			this.hide();
		} catch {
		}
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.destroy();
		}
		d.ctrl = null;
		if (d.list) {
			d.list.destroy();
		}
		d.list = null;
		d.explictPos = null;
		super.destroy();
	}

	protected _domMdcEvent(event: Event): void {
		super._domMdcEvent(event);
		switch (event.type) {
			case 'MDCMenuSurface:closed':
				this.ctrlClosedEvent();
				break;
			case 'MDCMenuSurface:closing':
				this.ctrlClosingEvent();
				break;
			case 'MDCMenuSurface:opened':
				this.ctrlOpenedEvent();
				break;
		}
	}

	protected keyPressEvent(event: KeyEvt): void {
		super.keyPressEvent(event);
		if (event.key() === 'Escape') {
			this.hide();
		}
	}

	protected hideEvent(event: HideEvt): void {
		this.setOpen(false);
		super.hideEvent(event);
	}

	item(index: number): FancyListItem | null {
		const d = this.d;
		return d.list ?
			d.list.item(index) :
			null;
	}

	@SLOT
	private _listItemActivated(item: FancyListItem): void {
		const idx = item.index();
		if (idx >= 0) {
			this.selectionChanged(idx);
		}
	}

	@SIGNAL
	private opened(): void {
	}

	removeItem(index: number): void {
		const d = this.d;
		if (d.list) {
			d.list.removeItem(index);
		}
	}

	@SIGNAL
	protected selectionChanged(index: number): void {
	}

	protected setOpen(open: boolean): void {
		if (open) {
			document.body.appendChild(
				this.element(),
			);
		}
		this.d.setCtrlOpen(open);
	}

	setPosition(point: Point): void;
	setPosition(x: number, y: number): void;
	setPosition(a: Point | number, b?: number): void {
		let pos: Point;
		if (isNumber(a) && isNumber(b)) {
			pos = new Point(a, b);
		} else {
			assert(a instanceof Point);
			pos = a;
		}
		this.d.setExplicitPos(pos);
	}

	protected showEvent(event: ShowEvt): void {
		super.showEvent(event);
		this.setOpen(true);
	}

	protected _windowBlurEvent(event: Event): void {
		this.hide();
	}

	protected _windowResizeEvent(event: Event): void {
		this.hide();
	}
}
