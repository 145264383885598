import {OBJ} from '../obj';
import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';

const defaultClassName = 'material-icons';
const outlinedClassName = 'material-icons-outlined';

export class IconPrivate extends ElObjPrivate {
	outlined: boolean;

	constructor() {
		super();
		this.outlined = false;
	}

	init(opts: Partial<IconOpts>): void {
		super.init(opts);
		const q = this.q;
		if (typeof opts.outlined === 'boolean') {
			q.setOutlined(opts.outlined);
		}
		if (typeof opts.name === 'string') {
			q.setName(opts.name);
		}
	}

	get q(): Icon {
		return <Icon>super.q;
	}
}

export interface IconOpts extends ElObjOpts {
	dd: IconPrivate;
	outlined: boolean;
	name: string;
}

@OBJ
export class Icon extends ElObj implements Ieq {
	static tagName: TagName = 'i';

	constructor(opts: Partial<IconOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-icon',
			defaultClassName,
		);
		opts.dd = opts.dd || new IconPrivate();
		super(opts);
	}

	cmp(other: Icon): number {
		if (this.isNull()) {
			return -1;
		}
		if (other.isNull()) {
			return 1;
		}
		const a = this.name();
		const b = other.name();
		if (a > b) {
			return 1;
		}
		if (a < b) {
			return -1;
		}
		return 0;
	}

	get d(): IconPrivate {
		return <IconPrivate>super.d;
	}

	eq(other: Icon): boolean {
		return (this === other) || ((this.isOutlined() === other.isOutlined()) && (this.name() === other.name()));
	}

	isNull(): boolean {
		/**
		 * Returns true if the icon is empty; otherwise returns false.
		 *
		 * An icon is empty when no icon name is currently set.
		 */
		return this.name().length < 1;
	}

	isOutlined(): boolean {
		return this.d.outlined;
	}

	name(): string {
		return this.text().trim();
	}

	setName(name?: string | null): void {
		const newName = (typeof name === 'string') ?
			name.trim() :
			'';
		if (newName === this.name()) {
			return;
		}
		this.setText(newName);
	}

	setOutlined(outlined: boolean): void {
		const d = this.d;
		if (outlined === d.outlined) {
			return;
		}
		d.outlined = outlined;
		let addCls: string;
		let remCls: string;
		if (d.outlined) {
			addCls = outlinedClassName;
			remCls = defaultClassName;
		} else {
			addCls = defaultClassName;
			remCls = outlinedClassName;
		}
		this.addClass(addCls);
		this.removeClass(remCls);
	}
}
