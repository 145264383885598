import {getLogger} from '../../logging';
import {ProjectFilterParam} from '../../constants';

const logger = getLogger('projectlistview');

export async function init(listFilter?: ProjectFilterParam): Promise<void> {
	const root = document.getElementById('id_lb-main');
	if (root) {
		const mod = await import('./view');
		const obj = new mod.ProjectListView({
			listFilter,
			root,
		});
		obj.show();
	} else {
		logger.error('Root element was not found');
	}
}

export async function initArchiveView(): Promise<void> {
	await init(ProjectFilterParam.Archived);
}
