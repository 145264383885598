import {getLogger} from '../../../logging';
import {OBJ} from '../../../obj';
import {ElObj} from '../../../elobj';
import {clamp} from '../../../util';
import {Cell} from './cell';
import {list, Point} from '../../../tools';

const logger = getLogger('ui.datatable.row');

@OBJ
export class Row extends ElObj {
	static tagName: TagName = 'tr';

	cellAt(pos: Point): Cell | null {
		for (const cell of this.children()) {
			if (cell.rr().contains(pos)) {
				return cell;
			}
		}
		return null;
	}

	children(): list<Cell> {
		return <list<Cell>>super.children();
	}

	index(cell: Cell): [number, number] {
		const column = this.children().indexOf(cell);
		let row: number = -1;
		const par = this.parentBody();
		if (par) {
			row = par.children().indexOf(this);
		}
		return [row, column];
	}

	insertCell(index: number, cell: Cell): void {
		if (cell.parentRow()) {
			logger.error('insertCell: Cell is assigned to another Row.');
			return;
		}
		index = clamp(0, index, this.children().size());
		cell.setParent(this, index);
		cell.show();
	}

	isCheckable(): boolean {
		const par = this.parentHead() || this.parentBody();
		const t = par ?
			par.parentTable() :
			null;
		const dt = t ?
			t.datatable() :
			null;
		if (dt) {
			return dt.rowsCheckable();
		}
		return false;
	}

	parentBody(): Body | null {
		const par = this.parentEl();
		return (par && (par instanceof Body)) ?
			par :
			null;
	}

	parentHead(): Head | null {
		const par = this.parentEl();
		return (par && (par instanceof Head)) ?
			par :
			null;
	}

	removeCell(index: number): void {
		if ((index >= 0) && (index < this.children().size())) {
			this.children().takeAt(index).destroy();
		}
	}

	selectedCssClassName(): string {
		return 'mdc-data-table__row--selected';
	}
}

// NB: DO NOT MOVE
import {Body} from './body';
import {Head} from './head';
