import {AbstractButton, AbstractButtonOpts, AbstractButtonPrivate} from './abstractbutton';
import {Obj, OBJ, SLOT} from '../obj';
import {ElObj} from '../elobj';
import {Icon} from './icon';
import {Point} from '../tools';
import {Dialog} from './dialog';
import {Menu} from './menu';
import {Evt, FocusEvt, KeyEvt, ShowEvt} from '../evt';
import {Key} from '../constants';

enum AutoDefaultValue {
	Off = 0,
	On = 1,
	Auto = 2,
}

export class PushButtonPrivate extends AbstractButtonPrivate {
	autoDefault: AutoDefaultValue;
	defaultButton: boolean;
	flat: boolean;
	hovering: boolean;
	lastAutoDefault: boolean;
	menu: Menu | null;
	menuOpen: boolean;

	constructor() {
		super();
		this.autoDefault = AutoDefaultValue.Auto;
		this.defaultButton = false;
		this.flat = false;
		this.hovering = false;
		this.lastAutoDefault = false;
		this.menu = null;
		this.menuOpen = false;
	}

	init(opts: Partial<PushButtonOpts>): void {
		super.init(opts);
		const q = this.q;
		if (opts.text) {
			q.setText(opts.text);
		}
		if (opts.icon) {
			q.setIcon(opts.icon);
		}
		if (q.element().tagName.toUpperCase() === 'BUTTON') {
			const type = q.attribute('type');
			if (!type) {
				q.setAttribute('type', 'button');
			}
		}
		q.addEventListener('mousedown');
		q.addEventListener('mouseup');
		q.addEventListener('keydown');
		q.addEventListener('keyup');
	}

	adjustedMenuPosition(): Point {
		return new Point();
	}

	dialogParent(): Dialog | null {
		let p: ElObj | null = this.q;
		while (p && p.dd.parent) {
			p = p.parentEl();
			if (p && (p instanceof Dialog)) {
				return p;
			}
		}
		return null;
	}

	get q(): PushButton {
		return <PushButton>super.q;
	}
}

export interface PushButtonOpts extends AbstractButtonOpts {
	dd: PushButtonPrivate;
	icon: Icon;
	text: string;
}

@OBJ
export class PushButton extends AbstractButton {
	static tagName: TagName = 'button';

	constructor(opts: Partial<PushButtonOpts> = {}) {
		opts.classNames = AbstractButton.mergeClassNames(
			opts.classNames,
			'lb-push-button',
		);
		opts.dd = opts.dd || new PushButtonPrivate();
		super(opts);
	}

	autoDefault(): boolean {
		const d = this.d;
		if (d.autoDefault === AutoDefaultValue.Auto) {
			return Boolean(d.dialogParent());
		}
		return Boolean(d.autoDefault);
	}

	get d(): PushButtonPrivate {
		return <PushButtonPrivate>super.d;
	}

	element(): HTMLElement {
		return <HTMLElement>super.element();
	}

	event(event: Evt): boolean {
		const d = this.d;
		if (event.type() === Evt.ParentChange) {
			const dia = d.dialogParent();
			if (dia) {
				if (d.defaultButton) {
					dia.d.setMainDefault(this);
				}
			}
		}
		return super.event(event);
	}

	protected focusInEvent(event: FocusEvt): void {
		const d = this.d;
		if (this.autoDefault() && !d.defaultButton) {
			d.defaultButton = true;
			const dia = d.dialogParent();
			if (dia) {
				dia.d.setDefault(this);
			}
		}
		super.focusInEvent(event);
	}

	protected focusOutEvent(event: FocusEvt): void {
		const d = this.d;
		if (this.autoDefault() && d.defaultButton) {
			const dia = d.dialogParent();
			if (dia) {
				dia.d.setDefault(null);
			} else {
				d.defaultButton = false;
			}
		}
		super.focusOutEvent(event);
		if (d.menu && d.menu.isVisible()) {
			this.setDown(true);
		}
	}

	isDefault(): boolean {
		return this.d.defaultButton;
	}

	isFlat(): boolean {
		return this.d.flat;
	}

	protected keyPressEvent(event: KeyEvt): void {
		const d = this.d;
		if ((event.key() === Key.Enter) && (this.autoDefault() || d.defaultButton)) {
			this.click();
		} else {
			super.keyPressEvent(event);
		}
	}

	menu(): Menu | null {
		return this.d.menu;
	}

	@SLOT
	protected _popupPressed(): void {
		const d = this.d;
		if (!d.down || !d.menu) {
			return;
		}
		const menuPos = d.adjustedMenuPosition();
		d.menuOpen = true;
		// d.menu.exec(menuPos);
		//     if (guard) {
		//         menuOpen = false;
		//         q->setDown(false);
		//     }
	}

	setAutoDefault(enable: boolean): void {
		const d = this.d;
		const state = enable ?
			AutoDefaultValue.On :
			AutoDefaultValue.Off;
		if ((d.autoDefault !== AutoDefaultValue.Auto) && (d.autoDefault === state)) {
			return;
		}
		d.autoDefault = state;
	}

	setDefault(enable: boolean): void {
		const d = this.d;
		if (enable === d.defaultButton) {
			return;
		}
		d.defaultButton = enable;
		if (d.defaultButton) {
			const dia = d.dialogParent();
			if (dia) {
				dia.d.setMainDefault(this);
			}
		}
	}

	setFlat(flat: boolean): void {
		const d = this.d;
		if (flat === d.flat) {
			return;
		}
		d.flat = flat;
	}

	setMenu(menu: Menu | null): void {
		const d = this.d;
		if (menu === d.menu) {
			return;
		}
		if (menu && !d.menu) {
			Obj.connect(
				this, 'pressed',
				this, '_popupPressed',
			);
		}
		if (d.menu) {
			// this.removeAction(d.menu.menuAction());
		}
		d.menu = menu;
		if (d.menu) {
			// this.addAction(d.menu.menuAction());
		}
	}

	showMenu(): void {
		const d = this.d;
		if (!d.menu) {
			return;
		}
		this.setDown(true);
		this._popupPressed();
	}
}

export class FancyPushButtonPrivate extends PushButtonPrivate {
	labelEl: ElObj;

	constructor() {
		super();
		this.labelEl = new ElObj({
			classNames: 'mdc-button__label',
			tagName: 'span',
		});
		this.labelEl.show();
	}

	init(opts: Partial<FancyPushButtonOpts>): void {
		super.init(opts);
		const q = this.q;
		new ElObj({
			classNames: 'mdc-button__ripple',
			parent: q,
			tagName: 'span',
		});
		this.labelEl.setParent(q);
		this.labelEl.show();
	}

	get q(): FancyPushButton {
		return <FancyPushButton>super.q;
	}
}

export interface FancyPushButtonOpts extends PushButtonOpts {
	filled: boolean;
	dd: FancyPushButtonPrivate;
	outlined: boolean;
	raised: boolean;
}

@OBJ
export class FancyPushButton extends PushButton {
	constructor(opts: Partial<FancyPushButtonOpts> = {}) {
		const classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-button',
		);
		if (opts.raised) {
			classNames.unshift('mdc-button--raised');
		} else if (opts.filled) {
			classNames.unshift('mdc-button--unelevated');
		} else if (opts.outlined) {
			classNames.unshift('mdc-button--outlined');
		}

		opts.classNames = classNames;
		opts.dd = opts.dd || new FancyPushButtonPrivate();
		super(opts);
	}

	get d(): FancyPushButtonPrivate {
		return <FancyPushButtonPrivate>super.d;
	}

	setIcon(icon: Icon): void {
		const d = this.d;
		if (icon.eq(d.icon)) {
			return;
		}
		d.icon.hide();
		d.icon.removeAttribute('aria-hidden');
		d.icon.removeClass('mdc-button__icon');
		d.icon.setParent(null);
		super.setIcon(icon);
		d.icon.setAttribute('aria-hidden', 'true');
		d.icon.addClass('mdc-button__icon');
		d.icon.setParent(this, 0);
		d.icon.show();
	}

	setText(text?: string | null): void {
		this.d.labelEl.setText(text);
	}

	text(): string {
		return this.d.labelEl.text();
	}
}
