import {DATA_VIEW_ROOT_ELEMENT_ID} from '../../constants';

export async function init(): Promise<void> {
	const root = document.getElementById(DATA_VIEW_ROOT_ELEMENT_ID);
	if (root) {
		const mod = await import('./view');
		const obj = new mod.DataView({root});
		obj.show();
	}
}
