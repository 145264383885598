import {getLogger} from '../../../logging';
import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {ElObj, ElObjOpts} from '../../../elobj';
import {Checkbox} from '../../checkbox';
import {TextInput, TextInputIconPosition, TextInputOpts} from '../../textinput';
import {AlignmentFlag, CheckState, ItemDataRole} from '../../../constants';
import {Variant} from '../../../variant';
import {ToolButton} from '../../toolbutton';
import {Row} from './row';
import {MouseEvt} from '../../../evt';
import {Icon} from '../../icon';

const logger = getLogger('ui.datatable');

@OBJ
export class Cell extends ElObj {
	protected static checkboxCellClassName: string = '';
	protected static checkboxClassName: string = '';
	protected static mousePressedOn: Cell | null = null;
	protected static rightAlignClassName: string = '';

	protected checkboxEl: Checkbox | null;
	protected headerCell: boolean;
	protected inputEl: TextInput | null;
	protected inputElName: string;
	protected textEl: ElObj;
	protected wrapEl: HeaderCellWrap | null;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-data-table-cell',
		);
		super(opts);
		this.checkboxEl = null;
		this.headerCell = false;
		this.inputEl = null;
		this.inputElName = '';
		this.textEl = new ElObj({
			classNames: [
				'lb-data-table-cell-text',
			],
			parent: this,
			tagName: 'span',
		});
		this.textEl.show();
		this.wrapEl = null;
	}

	private checkboxCellClassName(): string {
		return (<typeof Cell>this.constructor).checkboxCellClassName;
	}

	private checkboxClassName(): string {
		return (<typeof Cell>this.constructor).checkboxClassName;
	}

	@SIGNAL
	protected checkStateChanged(row: number, column: number, state: number): void {
	}

	@SLOT
	protected _checkStateChanged(state: number): void {
		this.checkStateChanged(...this._idx(), state);
	}

	checkState(): CheckState {
		return this.checkboxEl ?
			this.checkboxEl.checkState() :
			CheckState.Unchecked;
	}

	clear(): void {
		this.textEl.clear();
	}

	destroy(): void {
		const cls = <typeof Cell>this.constructor;
		if (cls.mousePressedOn === this) {
			cls.mousePressedOn = null;
		}
		if (this.checkboxEl) {
			this.checkboxEl.destroy();
		}
		this.checkboxEl = null;
		if (this.wrapEl) {
			this.wrapEl.destroy();
		}
		this.wrapEl = null;
		if (this.inputEl) {
			this.inputEl.destroy();
		}
		this.inputEl = null;
		this.textEl.destroy();
		super.destroy();
	}

	focusInput(): void {
		if (this.inputEl) {
			this.inputEl.focus();
		}
	}

	protected mousePressEvent(event: MouseEvt): void {
		(<typeof Cell>this.constructor).mousePressedOn = this;
	}

	_idx(): [number, number] {
		let row: number = -1;
		let column: number = -1;
		const par = this.parentRow();
		if (par) {
			[row, column] = par.index(this);
			if (column < 0) {
				logger.error('Invalid cell column index');
			}
			if (!this.headerCell && (row < 0)) {
				logger.error('Invalid cell row index');
			}
			if (par.isCheckable()) {
				--column;
			}
		}
		return [row, column];
	}

	@SIGNAL
	protected inputIconActivated(row: number, column: number, text: string, position: TextInputIconPosition): void {
	}

	@SLOT
	protected _inputIconActivated(position: TextInputIconPosition): void {
	}

	inputName(): string {
		if (this.inputEl) {
			return this.inputEl.name();
		}
		return this.inputElName;
	}

	@SIGNAL
	protected inputReturnPressed(row: number, column: number, text: string): void {
	}

	@SLOT
	protected _inputReturnPressed(): void {
	}

	inputText(): string {
		return this.inputEl ?
			this.inputEl.text().trim() :
			'';
	}

	@SIGNAL
	protected inputTextChanged(row: number, column: number, text: string): void {
	}

	@SLOT
	protected _inputTextChanged(text: string): void {
		this.inputTextChanged(
			...this._idx(),
			text,
		);
	}

	protected insertCheckbox(checkbox: Checkbox): void {
		checkbox.setParent(this, 0);
		checkbox.show();
	}

	protected isHeaderCell(): this is HeaderCell {
		return this.headerCell;
	}

	isInputEnabled(): boolean {
		return Boolean(this.inputEl);
	}

	parentRow(): Row | null {
		return <Row | null>this.parentEl();
	}

	private rightAlignClassName(): string {
		return (<typeof Cell>this.constructor).rightAlignClassName;
	}

	setCheckboxShown(shown: boolean): void {
		if (shown === Boolean(this.checkboxEl)) {
			return;
		}
		const cellClassName = this.checkboxCellClassName();
		if (shown) {
			this.checkboxEl = new Checkbox();
			if (cellClassName.length > 0) {
				this.addClass(cellClassName);
			}
			const checkboxClassName = this.checkboxClassName();
			if (checkboxClassName.length > 0) {
				this.checkboxEl.addClass(checkboxClassName);
			}
			Obj.connect(
				this.checkboxEl,
				'stateChanged',
				this,
				'_checkStateChanged',
			);
			this.insertCheckbox(this.checkboxEl);
		} else {
			if (this.checkboxEl) {
				this.checkboxEl.destroy();
			}
			this.checkboxEl = null;
			if (cellClassName.length > 0) {
				this.removeClass(cellClassName);
			}
		}
	}

	setCheckState(state: CheckState): void {
		if (this.checkboxEl) {
			this.checkboxEl.setCheckState(state);
		}
	}

	setData(data: Variant, role: ItemDataRole): boolean {
		if (!data.isValid()) {
			return false;
		}
		switch (role) {
			case ItemDataRole.DisplayRole:
			case ItemDataRole.EditRole: {
				this.setText(data.toString());
				break;
			}
			case ItemDataRole.ForegroundRole: {
				const val = data.toString();
				if (val.length > 0) {
					this.setStyleProperty('color', val);
				} else {
					this.removeStyleProperty('color');
				}
				break;
			}
			case ItemDataRole.BackgroundRole: {
				const val = data.toString();
				if (val.length > 0) {
					this.setStyleProperty('background-color', val);
				} else {
					this.removeStyleProperty('background-color');
				}
				break;
			}
			case ItemDataRole.TextAlignmentRole: {
				this.setTextAlignment(data.toNumber());
				break;
			}
			case ItemDataRole.IdentifierRole: {
				this.setInputName(data.toString());
				break;
			}
			case ItemDataRole.DisplayPropertyRole: {
				this.setInputName(data.toString());
				break;
			}
			default: {
				break;
			}
		}
		return true;
	}

	setInputEnabled(enabled: boolean): void {
		if (enabled === this.isInputEnabled()) {
			return;
		}
		this.setClass(enabled, 'lb-data-table-input-cell');
	}

	setInputName(name?: string | null): void {
		const text = name || '';
		if (text === this.inputElName) {
			return;
		}
		this.inputElName = text;
		if (this.inputEl) {
			this.inputEl.setName(this.inputElName);
		}
	}

	setInputText(text: string): void {
		if (this.inputEl) {
			this.inputEl.setText(text);
		}
	}

	setSortIndicatorShown(shown: boolean): void {
		if (!this.headerCell || (shown === Boolean(this.wrapEl))) {
			return;
		}
		this.setClass(
			shown,
			'mdc-data-table__header-cell--with-sort',
		);
		if (shown) {
			this.setAttribute('aria-sort', 'none');
			this.wrapEl = new HeaderCellWrap();
			Obj.connect(
				this.wrapEl.toolButton,
				'clicked',
				this,
				'_sortIndicatorClicked',
			);
			this.wrapEl.show();
			this.textEl.setParent(this.wrapEl.label);
			this.wrapEl.setParent(this);
		} else {
			this.textEl.setParent(this);
			if (this.wrapEl) {
				this.wrapEl.destroy();
			}
			this.wrapEl = null;
			this.removeAttribute('aria-sort');
		}
	}

	setText(text?: string | null): void {
		this.textEl.setText(text);
	}

	setTextAlignment(alignment: AlignmentFlag): void {
		const className = this.rightAlignClassName();
		if (className) {
			this.setClass(
				alignment === AlignmentFlag.AlignRight,
				className);
		}
	}

	@SIGNAL
	protected sortIndicatorClicked(row: number, column: number): void {
	}

	@SLOT
	protected _sortIndicatorClicked(): void {
		this.sortIndicatorClicked(...this._idx());
	}

	text(): string {
		return this.textEl.text();
	}

	textAlignment(): AlignmentFlag {
		const className = this.rightAlignClassName();
		if (className) {
			return this.hasClass(className) ? AlignmentFlag.AlignRight : AlignmentFlag.AlignLeft;
		}
		return AlignmentFlag.AlignLeft;
	}
}

@OBJ
export class HeaderCell extends Cell {
	protected static checkboxCellClassName: string = 'mdc-data-table__header-cell--checkbox';
	protected static checkboxClassName: string = 'mdc-data-table__header-row-checkbox';
	protected static rightAlignClassName: string = 'mdc-data-table__header-cell--numeric';
	static tagName: TagName = 'th';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['role', 'columnheader'],
			['scope', 'col'],
		);
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-data-table-header-cell',
			'mdc-data-table__header-cell',
		);
		super(opts);
		this.headerCell = true;
	}

	@SLOT
	protected _inputIconActivated(position: TextInputIconPosition): void {
		if (this.inputEl && (position === TextInputIconPosition.Trailing)) {
			this.inputIconActivated(
				...this._idx(),
				this.inputEl.text(),
				position,
			);
		}
	}

	@SLOT
	protected _inputReturnPressed(): void {
		if (this.inputEl) {
			this.inputReturnPressed(
				...this._idx(),
				this.inputEl.text(),
			);
		}
	}

	setInputEnabled(enabled: boolean): void {
		if (enabled === this.isInputEnabled()) {
			return;
		}
		super.setInputEnabled(enabled);
		if (enabled) {
			this.inputEl = new HeaderCellTextInput({
				name: this.inputElName || undefined,
				parent: this,
			});
			Obj.connect(
				this.inputEl, 'textChanged',
				this, '_inputTextChanged');
			Obj.connect(
				this.inputEl, 'iconActivated',
				this, '_inputIconActivated');
			Obj.connect(
				this.inputEl, 'returnPressed',
				this, '_inputReturnPressed');
			this.inputEl.show();
		} else {
			if (this.inputEl) {
				this.inputEl.destroy();
			}
			this.inputEl = null;
		}
	}
}

@OBJ
export class DataCell extends Cell {
	protected static checkboxCellClassName: string = 'mdc-data-table__cell--checkbox';
	protected static checkboxClassName: string = 'mdc-data-table__row-checkbox';
	protected static rightAlignClassName: string = 'mdc-data-table__cell--numeric';
	static tagName: TagName = 'td';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__cell',
		);
		super(opts);
	}
}

class HeaderCellTextInput extends TextInput {
	constructor(opts: Partial<TextInputOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-text-field--not-so-tall',
			'lb-text-field--filled-dense',
			'lb-text-field--not-so-tall',
			'lb-text-field--smaller-icon',
		);
		if (opts.noLabel === undefined) {
			opts.noLabel = true;
		}
		if (opts.placeholder === undefined) {
			opts.placeholder = 'Search...';
		}
		if (opts.trailingIcon === undefined) {
			opts.trailingIcon = {
				icon: 'search',
				interactive: true,
				title: 'Search this field',
			};
		}
		if (opts.underlinedLessDense === undefined) {
			opts.underlinedLessDense = true;
		}
		super(opts);
	}
}

@OBJ
class HeaderCellWrap extends ElObj {
	label: ElObj;
	toolButton: ToolButton;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__header-cell-wrapper',
		);
		super(opts);
		this.label = new ElObj({
			classNames: [
				'mdc-data-table__header-cell-label',
			],
			parent: this,
			tagName: 'div',
		});
		this.label.show();
		this.toolButton = new ToolButton({
			classNames: [
				'mdc-data-table__sort-icon-button',
			],
			parent: this,
		});
		this.toolButton.setIcon(
			new Icon({
				name: 'arrow_upward',
			}),
		);
		this.toolButton.show();
		const obj = new ElObj({
			attributes: [
				['aria-hidden', 'true'],
			],
			classNames: [
				'mdc-data-table__sort-status-label',
			],
			parent: this,
			tagName: 'div',
		});
		obj.show();
	}

	destroy(): void {
		this.toolButton.destroy();
		this.label.destroy();
		super.destroy();
	}
}
