import {Obj, OBJ, ObjOpts, ObjPrivate, PROP, SIGNAL, SLOT} from './obj';
import {Icon} from './ui/icon';
import {list} from './tools';
import {Variant} from './variant';
import {ActionEvent, ElAttr} from './constants';
import {ActionEvt, Evt} from './evt';
import {App} from './app';
import {ElObj} from './elobj';
import {ActionGroup, ExclusionPolicy} from './actiongroup';

export class ActionPrivate extends ObjPrivate {
	associatedObjs: list<Obj>;
	checkable: boolean;
	checked: boolean;
	enabled: boolean;
	explicitEnabled: boolean;
	explicitEnabledValue: boolean;
	forceInvisible: boolean;
	group: ActionGroup | null;
	icon: Icon;
	separator: boolean;
	text: string;
	toolTip: string;
	userData: Variant;
	visible: boolean;

	constructor() {
		super();
		this.associatedObjs = new list();
		this.checkable = false;
		this.checked = false;
		this.enabled = true;
		this.explicitEnabled = false;
		this.explicitEnabledValue = true;
		this.forceInvisible = false;
		this.group = null;
		this.icon = new Icon();
		this.separator = false;
		this.text = '';
		this.toolTip = '';
		this.userData = new Variant();
		this.visible = true;
	}

	get q(): Action {
		return <Action>super.q;
	}

	sendDataChanged(): void {
		const q = this.q;
		App.sendEvent(
			q,
			new ActionEvt(ActionEvt.ActionChanged, q),
		);
		q.changed();
	}

	setEnabled(enabled: boolean, byGroup: boolean): boolean {
		if (enabled && !this.visible) {
			enabled = false;
		}
		if (enabled && !byGroup && (this.group && !this.group.isEnabled())) {
			enabled = false;
		}
		if (enabled && byGroup && this.explicitEnabled) {
			enabled = this.explicitEnabledValue;
		}
		if (enabled === this.enabled) {
			return false;
		}
		this.enabled = enabled;
		this.sendDataChanged();
		this.q.enabledChanged(enabled);
		return true;
	}

	setVisible(visible: boolean): void {
		if (visible === this.visible) {
			return;
		}
		this.visible = visible;
		let enable = this.visible;
		if (enable && this.explicitEnabled) {
			enable = this.explicitEnabledValue;
		}
		if (!this.setEnabled(enable, false)) {
			this.sendDataChanged();
		}
		this.q.visibleChanged();
	}
}

export interface ActionOpts extends ObjOpts {
	dd: ActionPrivate;
}

@OBJ
export class Action extends Obj {

	constructor(opts?: Partial<ActionOpts>);
	constructor(parent?: Obj | null);
	constructor(text: string, parent?: Obj | null);
	constructor(icon: Icon, parent?: Obj | null);
	constructor(icon: Icon, text: string, parent?: Obj | null);
	constructor(a?: Obj | Icon | string | Partial<ActionOpts> | null, b?: Obj | string | null, c?: Obj | null) {
		let icon: Icon | null = null;
		let opts: Partial<ActionOpts> = {};
		let parent: Obj | null = null;
		let text: string = '';
		if (a) {
			if (a instanceof Icon) {
				if (typeof b === 'string') {
					// SIG: constructor(icon: Icon, text: string, parent: Obj | null = null)
					icon = a;
					text = b || text;
					parent = c || null;
				} else {
					icon = a;
					parent = b || null;
				}
			} else if (typeof a === 'string') {
				// SIG: constructor(text: string, parent: Obj | null = null)
				text = a;
				if (!(typeof b === 'string')) {
					parent = b || null;
				}
			} else if (a instanceof Obj) {
				// SIG: constructor(parent: Obj | null = null)
				parent = a;
			} else {
				opts = a;
			}
		}
		opts.dd = opts.dd || new ActionPrivate();
		if (parent) {
			opts.parent = parent;
		}
		super(opts);
		if (icon) {
			opts.dd.icon = icon;
		}
		if (text) {
			opts.dd.text = text;
		}
		const axnParent = this.parent();
		if (axnParent && (axnParent instanceof ActionGroup)) {
			opts.dd.group = axnParent;
			axnParent.addAction(this);
		}
	}

	actionGroup(): ActionGroup | null {
		return this.d.group;
	}

	activate(event: ActionEvent): void {
		const d = this.d;
		if (event === ActionEvent.Trigger) {
			if ((d.explicitEnabled && !d.explicitEnabledValue) || (d.group && !d.group.isEnabled())) {
				// Ignore explicit triggers when explicitly disabled
				return;
			}
			if (d.checkable) {
				// Checked action of an exclusive group may not be unchecked
				if (d.checked && (d.group && (d.group.exclusionPolicy() === ExclusionPolicy.Exclusive) && (d.group.checkedAction() === this))) {
					this.triggered(true);
					return;
				}
				this.setChecked(!d.checked);
			}
			this.triggered(d.checked);
		} else if (event === ActionEvent.Hover) {
			this.hovered();
		}
	}

	associatedObjects(): list<Obj> {
		return this.d.associatedObjs;
	}

	@SIGNAL
	changed(): void {
	}

	@SIGNAL
	protected checkableChanged(checkable: boolean): void {
	}

	get d(): ActionPrivate {
		return <ActionPrivate>super.d;
	}

	data(): Variant {
		return this.d.userData;
	}

	destroy(): void {
		const d = this.d;
		if (d.group) {
			d.group.removeAction(this);
		}
		super.destroy();
	}

	@SIGNAL
	enabledChanged(enabled: boolean): void {
	}

	event(event: Evt): boolean {
		if (event.type() === Evt.ActionChanged) {
			for (const obj of this.d.associatedObjs) {
				App.sendEvent(obj, event);
			}
		}
		return super.event(event);
	}

	@SLOT
	hover(): void {
		this.activate(ActionEvent.Hover);
	}

	@SIGNAL
	protected hovered(): void {
	}

	@PROP({NOTIFY: 'changed', WRITE: 'setIcon'})
	icon(): Icon {
		return this.d.icon;
	}

	@PROP({NOTIFY: 'checkableChanged', WRITE: 'setCheckable'})
	isCheckable(): boolean {
		return this.d.checkable;
	}

	@PROP({NOTIFY: 'toggled', WRITE: 'setChecked'})
	isChecked(): boolean {
		const d = this.d;
		return d.checked && d.checkable;
	}

	@PROP({NOTIFY: 'enabledChanged', WRITE: 'setEnabled'})
	isEnabled(): boolean {
		return this.d.enabled;
	}

	isSeparator(): boolean {
		return this.d.separator;
	}

	@PROP({NOTIFY: 'visibleChanged', WRITE: 'setVisible'})
	isVisible(): boolean {
		return this.d.visible;
	}

	@SLOT
	resetEnabled(): void {
		const d = this.d;
		if (!d.explicitEnabled) {
			return;
		}
		d.explicitEnabled = false;
		d.setEnabled(true, false);
	}

	setActionGroup(group: ActionGroup | null): void {
		const d = this.d;
		if (group === d.group) {
			return;
		}
		if (d.group) {
			d.group.removeAction(this);
		}
		d.group = group;
		if (group) {
			group.addAction(this);
		}
		d.sendDataChanged();
	}

	setCheckable(checkable: boolean): void {
		const d = this.d;
		if (checkable === d.checkable) {
			return;
		}
		d.checkable = checkable;
		d.sendDataChanged();
		this.checkableChanged(d.checkable);
		if (d.checked) {
			this.toggled(checkable);
		}
	}

	@SLOT
	setChecked(checked: boolean): void {
		const d = this.d;
		if (checked === d.checked) {
			return;
		}
		d.checked = checked;
		if (!d.checkable) {
			return;
		}
		d.sendDataChanged();
		this.toggled(d.checked);
	}

	setData(value: Variant): void {
		const d = this.d;
		if ((value === d.userData) || (d.userData.eq(value))) {
			return;
		}
		d.userData = value;
		d.sendDataChanged();
	}

	@SLOT
	setDisabled(disabled: boolean): void {
		this.setEnabled(!disabled);
	}

	@SLOT
	setEnabled(enabled: boolean): void {
		const d = this.d;
		if ((d.explicitEnabledValue === enabled) && d.explicitEnabled) {
			return;
		}
		d.explicitEnabledValue = enabled;
		d.explicitEnabled = true;
		d.setEnabled(enabled, false);
	}

	setIcon(icon: Icon): void {
		const d = this.d;
		d.icon = icon;
		d.sendDataChanged();
	}

	setSeparator(sep: boolean): void {
		const d = this.d;
		if (sep === d.separator) {
			return;
		}
		d.separator = sep;
		d.sendDataChanged();
	}

	setText(text: string): void {
		const d = this.d;
		if (text === d.text) {
			return;
		}
		d.text = text;
		d.sendDataChanged();
	}

	setToolTip(toolTip: string): void {
		const d = this.d;
		if (toolTip === d.toolTip) {
			return;
		}
		d.toolTip = toolTip;
		d.sendDataChanged();
	}

	@SLOT
	setVisible(visible: boolean): void {
		const d = this.d;
		if (visible !== d.forceInvisible) {
			return;
		}
		d.forceInvisible = !visible;
		if (visible && d.group && !d.group.isVisible()) {
			return;
		}
		d.setVisible(visible);
	}

	@PROP({NOTIFY: 'changed', WRITE: 'setText'})
	text(): string {
		return this.d.text;
	}

	@SLOT
	toggle(): void {
		this.setChecked(!this.d.checked);
	}

	@SIGNAL
	protected toggled(checked: boolean): void {
	}

	@PROP({NOTIFY: 'changed', WRITE: 'setToolTip'})
	toolTip(): string {
		const d = this.d;
		return (d.toolTip.length > 0) ?
			d.toolTip :
			d.text;
	}

	@SLOT
	trigger(): void {
		this.activate(ActionEvent.Trigger);
	}

	@SIGNAL
	protected triggered(checked: boolean = false): void {
	}

	@SIGNAL
	visibleChanged(): void {
	}
}

export class ElObjActionPrivate extends ActionPrivate {
	autoCreated: boolean;
	createdObjs: list<ElObj>;
	defaultObj: ElObj | null;

	constructor() {
		super();
		this.autoCreated = false;
		this.createdObjs = new list();
		this.defaultObj = null;
	}

	get q(): ElObjAction {
		return <ElObjAction>super.q;
	}
}

export interface ElObjActionOpts extends ActionOpts {
	dd: ElObjActionPrivate;
}

@OBJ
export class ElObjAction extends Action {
	constructor(opts: Partial<ElObjActionOpts> = {}) {
		opts.dd = opts.dd || new ElObjActionPrivate();
		super(opts);
	}

	createdWidgets(): list<ElObj> {
		/**
		 * Returns list of objects created via createElObj() and are currently
		 * in-use by objects to which the action has been added.
		 */
		return this.d.createdObjs;
	}

	createElObj(parent: ElObj): ElObj | null {
		/**
		 * Called whenever the action is added to a container object that
		 * supports custom objects. If you don't want a custom object to be
		 * used as representation of the action in the specified `parent`
		 * object then null should be returned.
		 */
		return null;
	}

	get d(): ElObjActionPrivate {
		return <ElObjActionPrivate>super.d;
	}

	defaultElObj(): ElObj | null {
		return this.d.defaultObj;
	}

	deleteElObj(obj: ElObj): void {
		/**
		 * Called whenever the action is removed from a container object that
		 * displays the action using the custom `obj` previously created using
		 * createElObj(). The default implementation hides the `obj` and
		 * schedules it for deletion using Obj.deleteLater().
		 */
		obj.hide();
		obj.deleteLater();
	}

	destroy(): void {
		const d = this.d;
		for (const obj of d.createdObjs) {
			Obj.disconnect(
				obj, 'destroyed',
				this, '_objWasDestroyed',
			);
		}
		const toDelete = new list(d.createdObjs);
		d.createdObjs.clear();
		for (const obj of toDelete) {
			obj.destroy();
		}
		toDelete.clear();
		if (d.defaultObj) {
			d.defaultObj.destroy();
		}
		d.defaultObj = null;
		super.destroy();
	}

	event(event: Evt): boolean {
		if (event.type() === Evt.ActionChanged) {
			const d = this.d;
			const enabled = this.isEnabled();
			if (d.defaultObj) {
				d.defaultObj.setEnabled(enabled);
			}
			for (const obj of d.createdObjs) {
				obj.setEnabled(enabled);
			}
		}
		return super.event(event);
	}

	@SLOT
	protected _objWasDestroyed(obj: ElObj | null): void {
	}

	releaseElObj(obj: ElObj | null): void {
		if (!obj) {
			return;
		}
		const d = this.d;
		if (obj === d.defaultObj) {
			d.defaultObj.hide();
			d.defaultObj.setParent(null);
			return;
		}
		if (!d.createdObjs.contains(obj)) {
			return;
		}
		Obj.disconnect(
			obj, 'destroyed',
			this, '_objWasDestroyed',
		);
		d.createdObjs.removeAll(obj);
		this.deleteElObj(obj);
	}

	requestElObj(parent: ElObj): ElObj | null {
		const d = this.d;
		const el = this.createElObj(parent);
		if (!el) {
			if (!d.defaultObj) {
				return null;
			}
			d.defaultObj.setParent(parent);
			return d.defaultObj;
		}
		Obj.connect(
			el, 'destroyed',
			this, '_objWasDestroyed',
		);
		d.createdObjs.append(el);
		return el;
	}

	setDefaultElObj(obj: ElObj | null): void {
		const d = this.d;
		if (obj === d.defaultObj) {
			return;
		}
		if (d.defaultObj) {
			d.defaultObj.destroy();
		}
		d.defaultObj = obj;
		if (!d.defaultObj) {
			return;
		}
		this.setVisible(
			!(d.defaultObj.isHidden() && d.defaultObj.testAttribute(ElAttr.ExplicitShowHide)),
		);
		d.defaultObj.hide();
		d.defaultObj.setParent(null);
		if (!this.isEnabled()) {
			d.defaultObj.setEnabled(false);
		}
	}
}
