import {euclideanDistance, stringRepeat} from './util';
import {TAP_DISTANCE_TOLERANCE, TAP_INTERVAL} from '../constants';

export function closestMatchingElement<E extends Element = Element>(element: Element | null, selector: string): E | null {
	if (!element) {
		return null;
	}
	if (element.closest) {
		return element.closest(selector);
	}
	let curr: Element | null = element;
	while (curr) {
		if (elementMatchesSelector(curr, selector)) {
			return <E>curr;
		}
		curr = curr.parentElement;
	}
	return null;
}

export function extractCookie(name: string): string | null {
	if (!document.cookie) {
		return null;
	}
	const cookies = document.cookie.split(';');
	const def = `${name}=`;
	for (let i = 0; i < cookies.length; ++i) {
		const cookie = cookies[i].trim();
		if (cookie.slice(0, name.length + 1) === def) {
			return decodeURIComponent(cookie.slice(name.length + 1));
		}
	}
	return null;
}

export function elementMatchesSelector(element: Element, selector: string): boolean {
	const nativeMatches = element.matches
		|| element.webkitMatchesSelector
		// @ts-ignore
		|| element.msMatchesSelector;
	return nativeMatches.call(element, selector);
}

export function elementString(elem: Element): string {
	return `<${nodeStrings(elem).join(' ')}/>`;
}

export function isBackspace(event: KeyboardEvent): boolean {
	return (event.key === 'Backspace') || (event.keyCode === 8);
}

export function isDelete(event: KeyboardEvent): boolean {
	return (event.key === 'Delete') || (event.keyCode === 46);
}

export function isDisableable(el: Element | null): el is Element & {disabled: boolean;} {
	return !!el && ((el instanceof HTMLInputElement)
		|| (el instanceof HTMLButtonElement)
		|| (el instanceof HTMLSelectElement)
		|| (el instanceof HTMLTextAreaElement)
		|| (el instanceof HTMLOptionElement)
		|| (el instanceof HTMLOptGroupElement)
		|| (el instanceof HTMLFieldSetElement)
		|| (el instanceof HTMLLinkElement)
		|| (el instanceof SVGStyleElement));
}

export function isEnter(event: KeyboardEvent): boolean {
	return (event.key === 'Enter') || (event.keyCode === 13);
}

export function isEscape(event: KeyboardEvent): boolean {
	return (event.key === 'Escape') || (event.keyCode === 27);
}

export function isRequireable(el: Element | null): el is Element & {required: boolean;} {
	return !!el
		&& ((el instanceof HTMLInputElement)
			|| (el instanceof HTMLSelectElement)
			|| (el instanceof HTMLTextAreaElement));
}

export function isTap(startInfo: IPointerEventInfo, endInfo: IPointerEventInfo): boolean {
	return ((euclideanDistance(startInfo.point, endInfo.point) < TAP_DISTANCE_TOLERANCE) && ((endInfo.time - startInfo.time) < TAP_INTERVAL))
		|| ((startInfo.point.x === endInfo.point.x) && (startInfo.point.y === endInfo.point.y));
}

export function isValidable(el: Element | null): el is Element & {reportValidity(): boolean;} {
	return !!el && ((el instanceof HTMLFormElement)
		|| (el instanceof HTMLInputElement)
		|| (el instanceof HTMLTextAreaElement)
		|| (el instanceof HTMLSelectElement)
		|| (el instanceof HTMLButtonElement)
		|| (el instanceof HTMLFieldSetElement)
		|| (el instanceof HTMLObjectElement)
		|| (el instanceof HTMLOutputElement));
}

function nodeStrings(node: Node): Array<string> {
	const rv: Array<string> = [];
	if (node.nodeType === Node.ELEMENT_NODE) {
		const elem = <Element>node;
		rv.push(node.nodeName);
		for (const obj of elem.attributes) {
			rv.push(`${obj.name}="${obj.value}"`);
		}
	}
	return rv;
}

export function pixelNumber(val: string): number {
	// NB: Will return NaN
	if (val.toLowerCase().endsWith('px')) {
		val = val.slice(0, val.length - 2);
	}
	if (val.indexOf('.') >= 0) {
		return Number.parseFloat(val);
	}
	return Number.parseInt(val);
}

export function pixelString(value: number | string, zeroIsEmptyString: boolean = false): string {
	if (typeof value === 'number') {
		return (value === 0) ?
			zeroIsEmptyString ?
				'' :
				'0' :
			`${value}px`;
	}
	if (value.indexOf('px') >= 0) {
		return value;
	}
	return (value === '0') ?
		value :
		`${value}px`;
}

export function printTree(node: Node): void {
	console.log(treeString(node));
}

export function probablyRightClick(event: MouseEvent): boolean {
	// So far only tested on my Mac laptop for contextmenu events. Do better.
	//
	// Pay attention to `button` and `buttons`
	//
	// - If ctrlKey is pressed, all bets are off, don't mess with it.
	// - If it's a normal right-click (again, no ctrlKey), buttons === 2,
	//   probably a right click
	// - If buttons === 0 and button === 2 and, again, no pressed ctrlKey,
	//   probably a right click while the context menu was already open.
	//
	// If ctrlKey is NOT pressed
	//     If buttons === 2 OR (buttons === 0 AND button === 2)
	//         Probably a right click
	// Anything else, it's probably not a right click.
	return !event.ctrlKey && ((event.buttons === 2) || ((event.buttons === 0) && (event.button === 2)));
}

function treeParts(node: Node, level: number = 0): Array<string> {
	const parts: Array<string> = [];
	const name = node.nodeName;
	const pad = stringRepeat(' ', level / 2 * 8);
	const children = node.childNodes;
	if (node.nodeType === Node.TEXT_NODE) {
		const t = (node.textContent || '').trim();
		if (t) {
			parts.push(`${pad}${t}`);
		}
	} else {
		const s = nodeStrings(node).join(' ');
		if (children.length > 0) {
			parts.push(`${pad}<${s}>`);
			for (let i = 0; i < children.length; ++i) {
				const child = children[i];
				parts.push(...treeParts(child, level + 1));
			}
			parts.push(`${pad}</${name}>`);
		} else {
			parts.push(`${pad}<${s}></${name}>`);
		}
	}
	return parts;
}

export function treeString(node: Node): string {
	return treeParts(node).join('\n');
}
