import {clientMightBeMac} from './util';
import {ElAttr, KeyboardModifier, MouseButton, Platform} from './constants';
import {Evt, HoverEvt, InputEvt, KeyEvt, MouseEvt} from './evt';
import type {ElObj} from './elobj';
import {Point} from './tools';
import {AbstractObj, OBJ, Obj, ObjOpts, ObjPrivate} from './obj';

class AppPrivate extends ObjPrivate {
	static lastCursorPos: Point = new Point();
	static modifiers: number = KeyboardModifier.NoModifier;
	static mouseButtons: number = MouseButton.NoButton;

	static captureGlobalModifierState(event: Evt): void {
		switch (event.type()) {
			// NB: Do not move. Positioned here for performance purposes.
			case Evt.MouseMove:
			case Evt.TouchUpdate: {
				this.modifiers = (<InputEvt>event).modifiers();
				break;
			}
			case Evt.MouseButtonPress:
			case Evt.MouseButtonDblClick: {
				const evt = <MouseEvt>event;
				this.modifiers = evt.modifiers();
				if (event.type() === Evt.MouseButtonPress) {
					this.mouseButtons |= evt.button();
				}
				break;
			}
			case Evt.MouseButtonRelease: {
				const evt = <MouseEvt>event;
				this.modifiers = evt.modifiers();
				this.mouseButtons &= ~evt.button();
				break;
			}
			case Evt.KeyPress:
			case Evt.KeyRelease:
			case Evt.TouchBegin:
			case Evt.TouchEnd: {
				this.modifiers = (<InputEvt>event).modifiers();
				break;
			}
			default:
				break;
		}
	}

	static notifyHelper(receiver: AbstractObj, event: Evt): boolean {
		if (App.self && App.self.d.sendThroughApplicationEventFilters(receiver, event)) {
			return true;
		}
		if (this.sendThroughObjectEventFilters(receiver, event)) {
			return true;
		}
		return receiver.event(event);
	}

	static sendThroughObjectEventFilters(receiver: AbstractObj, event: Evt): boolean {
		if ((receiver !== App.self) && receiver.dd.extraData) {
			for (const obj of receiver.dd.extraData.eventFilters) {
				if (obj.eventFilter(receiver, event)) {
					return true;
				}
			}
		}
		return false;
	}

	hoverPos: Point;

	constructor() {
		super();
		this.hoverPos = new Point();
	}

	init(): void {
		App.self = this.q;
	}

	notifyHelper(receiver: AbstractObj, event: Evt): boolean {
		if (this.sendThroughApplicationEventFilters(receiver, event)) {
			return true;
		}
		if (AppPrivate.sendThroughObjectEventFilters(receiver, event)) {
			return true;
		}
		return receiver.event(event);
	}

	get q(): App {
		return <App>super.q;
	}

	sendThroughApplicationEventFilters(receiver: AbstractObj, event: Evt): boolean {
		if (this.extraData) {
			for (const obj of this.extraData.eventFilters) {
				if (obj.eventFilter(receiver, event)) {
					return true;
				}
			}
		}
		return false;
	}
}

export interface AppOpts extends ObjOpts {
	dd: AppPrivate;
}

@OBJ
export class App extends Obj {
	static self: App | null = null;

	static keyboardModifiers(): KeyboardModifier {
		return AppPrivate.modifiers;
	}

	static lastCursorPos(): Point {
		return AppPrivate.lastCursorPos;
	}

	static mouseButtons(): MouseButton {
		return AppPrivate.mouseButtons;
	}

	static platformMaybe(): Platform {
		return clientMightBeMac() ?
			Platform.Mac :
			Platform.Other;
	}

	static sendEvent(receiver: AbstractObj, event: Evt): boolean {
		if (this.self) {
			return this.self.notify(
				receiver,
				event,
			);
		}
		if (receiver.isElType()) {
			return false;
		}
		return AppPrivate.notifyHelper(
			receiver,
			event,
		);
	}

	static setLastCursorPos(pos: Point): void {
		AppPrivate.lastCursorPos = pos;
	}

	constructor(opts: Partial<AppOpts> = {}) {
		opts.dd = opts.dd || new AppPrivate();
		super(opts);
		opts.dd.init();
	}

	get d(): AppPrivate {
		return <AppPrivate>super.d;
	}

	destroy(): void {
		App.self = null;
		super.destroy();
	}

	notify(receiver: AbstractObj | null, event: Evt): boolean {
		if (!receiver) {
			return true;
		}
		AppPrivate.captureGlobalModifierState(event);
		const d = this.d;
		const isEl = receiver.isElType();
		let rv: boolean = false;
		if (receiver.isElType()) {
			switch (event.type()) {
				case Evt.ShortcutOverride:
				case Evt.KeyPress:
				case Evt.KeyRelease: {
					const keyEvt = <KeyEvt>event;
					const accepted = keyEvt.isAccepted();
					while (receiver) {
						if (accepted) {
							keyEvt.accept();
						} else {
							keyEvt.ignore();
						}
						const el: ElObj | null = (isEl && receiver.isElType()) ?
							receiver :
							null;
						rv = d.notifyHelper(receiver, keyEvt);
						if ((rv && keyEvt.isAccepted()) || !el || (isEl && !el.parentEl())) {
							break;
						}
						receiver = el.parentEl();
					}
					break;
				}
				case Evt.MouseMove:
				case Evt.MouseButtonPress:
				case Evt.MouseButtonRelease:
				case Evt.MouseButtonDblClick: {
					let el: ElObj | null = receiver;
					const mouseEvt = <MouseEvt>event;
					let pos = mouseEvt.pos();
					let accepted = mouseEvt.isAccepted();
					while (el) {
						const evt = new MouseEvt(
							mouseEvt.type(),
							pos,
							mouseEvt.button(),
							mouseEvt.buttons(),
							mouseEvt.modifiers(),
						);
						evt.setTimestamp(mouseEvt.timestamp());
						if (!el.hasMouseTracking() && (mouseEvt.type() === Evt.MouseMove) && (mouseEvt.buttons() === 0)) {
							// Throw away any mouse-tracking-only mouse events
							// but still send them through all application
							// event filters (normally done by notifyHelper).
							d.sendThroughApplicationEventFilters(
								el,
								(el === receiver) ?
									mouseEvt :
									evt,
							);
						} else {
							rv = d.notifyHelper(
								el,
								(el === receiver) ?
									mouseEvt :
									evt,
							);
						}
						accepted = ((el === receiver) ?
							mouseEvt :
							evt).isAccepted();
						if (rv && accepted) {
							break;
						}
						if (el.testAttribute(ElAttr.NoMousePropagation)) {
							break;
						}
						el = el.parentEl();
					}
					mouseEvt.setAccepted(accepted);
					if (event.type() === Evt.MouseMove) {
						el = receiver;
						while (el) {
							if (el.testAttribute(ElAttr.Hover)) {
								d.notifyHelper(
									el,
									new HoverEvt(
										Evt.HoverMove,
										pos,
										pos,
										mouseEvt.modifiers(),
									),
								);
							}
							if (el.testAttribute(ElAttr.NoMousePropagation)) {
								break;
							}
							el = el.parentEl();
						}
					}
					d.hoverPos = mouseEvt.pos();
					break;
				}
				case Evt.Enter:
				case Evt.Leave:
				default: {
					rv = d.notifyHelper(receiver, event);
					break;
				}
			}
		} else {
			rv = d.notifyHelper(receiver, event);
		}
		return rv;
	}
}
