import {OverscaledTileID, Tile, Transform} from '../../util';
import {set} from '../../tools';
import {DEFAULT_MAP_TILE_SIZE, PARCEL_LAYER_MIN_ZOOM} from '../../constants';

type PkFetcher = (z: number, x: number, y: number) => Promise<Iterable<AreaPk>>;
type PkSetter = (pks: Iterable<AreaPk>, selected: boolean) => any;

export class Pk {
	private diff: boolean;
	enabled: boolean;
	pkFetcher: PkFetcher | null;
	pkSetter: PkSetter | null;
	private tiles: Map<number, Tile>;
	private transform: Transform;

	constructor(pkFetcher: PkFetcher | null = null, pkSetter: PkSetter | null = null) {
		this.diff = false;
		this.enabled = true;
		this.pkFetcher = pkFetcher;
		this.pkSetter = pkSetter;
		this.tiles = new Map<number, Tile>();
		this.transform = new Transform();
	}

	private clearTiles(): void {
		const func = this._pkSetter();
		for (const tile of this.tiles.values()) {
			func(
				tile.data,
				false,
			);
			tile.unloadData();
		}
		this.tiles.clear();
	}

	destroy(): void {
		this.clearTiles();
		this.tiles.clear();
	}

	private findTopMostAncestor(tileId: OverscaledTileID): OverscaledTileID | null {
		let curr = tileId;
		let rv: OverscaledTileID | null = null;
		while (curr.overscaledZ > 0) {
			curr = curr.scaledTo(curr.overscaledZ - 1);
			if (this.tiles.has(curr.key)) {
				rv = curr;
			}
		}
		return rv;
	}

	invalidate(): void {
		this.diff = true;
	}

	private _pkFetcher(): PkFetcher {
		if (this.pkFetcher) {
			return this.pkFetcher;
		}
		return () => Promise.resolve([]);
	}

	private _pkSetter(): PkSetter {
		return this.pkSetter || (() => undefined);
	}

	unload(): void {
		if (!this.enabled) {
			return;
		}
		const allPks = new set<string>();
		const setter = this._pkSetter();
		for (const tile of this.tiles.values()) {
			allPks.update(tile.data);
			tile.unloadData();
		}
		setter(
			allPks,
			false,
		);
		this.tiles.clear();
	}

	async update(transform: Transform, tileSize?: number): Promise<void> {
		if (!this.enabled) {
			return;
		}
		this.transform = transform;
		if (!this.diff && (this.transform.zoom < PARCEL_LAYER_MIN_ZOOM)) {
			// Outside of range for rendering parcels.
			return;
		}
		const tileIds = transform.coveringTiles({
			tileSize: (tileSize === undefined) ?
				DEFAULT_MAP_TILE_SIZE :
				tileSize,
		});
		const keep = new Map<number, Tile>();
		for (const tileId of tileIds) {
			let tile = this.tiles.get(tileId.key);
			if (!tile) {
				tile = new Tile(
					tileId,
					DEFAULT_MAP_TILE_SIZE * tileId.overscaleFactor(),
					this.transform.tileZoom,
				);
				this.tiles.set(tileId.key, tile);
			}
			keep.set(tileId.key, tile);
			for (const child of tileId.children(22)) {
				const childTile = this.tiles.get(child.key);
				if (childTile) {
					keep.set(childTile.tileID.key, childTile);
				}
			}
			const ancestor = this.findTopMostAncestor(tileId);
			if (ancestor) {
				// tile is covered
				const ancestorTile = <Tile>this.tiles.get(ancestor.key);
				keep.set(
					ancestorTile.tileID.key,
					ancestorTile,
				);
			}
		}
		const allPks = new set<string>();
		const fetcher = this._pkFetcher();
		const setter = this._pkSetter();
		for (const tile of keep.values()) {
			const tileId = tile.tileID;
			const pks = new set(
				await fetcher(
					tileId.canonical.z,
					tileId.canonical.x,
					tileId.canonical.y,
				),
			);
			setter(
				tile.diffData(pks),
				false,
			);
			tile.loadData(pks);
			setter(
				pks,
				true,
			);
			allPks.update(tile.data);
		}
		const tileIdKeys = new set(this.tiles.keys());
		for (const removeKey of tileIdKeys.difference(new set(keep.keys()))) {
			const tile = this.tiles.get(removeKey);
			if (tile) {
				setter(
					tile.data.difference(allPks),
					false,
				);
				tile.unloadData();
			}
		}
		this.diff = false;
	}
}
