import {Layer} from './map';
import {Icon} from '../icon';
import {
	MapLayerColor,
	MapLayerId,
	MapSourceId, MapStyleTheme,
	PARCEL_LAYER_MIN_ZOOM,
	PARCEL_LINE_LAYER_MIN_ZOOM,
} from '../../constants';

export const layerThemes: Record<MapStyleTheme, Array<LayerPaint>> = {
	[MapStyleTheme.Dark]: [
		{
			layerId: MapLayerId.ParcelFill,
			paintPropertyName: 'fill-color',
			value: MapLayerColor.ParcelFillDark,
		},
		{
			layerId: MapLayerId.FilterFill,
			paintPropertyName: 'fill-color',
			value: MapLayerColor.FilterFillDark,
		},
		{
			layerId: MapLayerId.FilterStroke,
			paintPropertyName: 'line-color',
			value: MapLayerColor.FilterStrokeDark,
		},
	],
	[MapStyleTheme.Light]: [
		{
			layerId: MapLayerId.ParcelFill,
			paintPropertyName: 'fill-color',
			value: MapLayerColor.ParcelFillLight,
		},
		{
			layerId: MapLayerId.FilterFill,
			paintPropertyName: 'fill-color',
			value: MapLayerColor.FilterFillLight,
		},
		{
			layerId: MapLayerId.FilterStroke,
			paintPropertyName: 'line-color',
			value: MapLayerColor.FilterStrokeLight,
		},
	],
};

export const mapLayers: Array<Layer> = [
	{
		id: MapLayerId.FilterFill,
		type: 'fill',
		paint: {
			'fill-color': MapLayerColor.FilterFillDark,
			'fill-opacity': 0.17,
		},
		source: MapSourceId.FilterPolygon,
		ui: {
			icon: new Icon({
				name: 'filter_alt',
			}),
			label: 'Filters',
		},
	},
	// {
	// 	id: MapLayerId.FilterPoint,
	// 	type: 'circle',
	// 	paint: {
	// 		'circle-color': MapLayerColor.FilterFillDark,
	// 	},
	// 	source: MapSourceId.FilterPoint,
	// 	ui: {
	// 		icon: new Icon({
	// 			name: 'filter_alt',
	// 		}),
	// 		label: 'Filters',
	// 	},
	// },
	{
		id: MapLayerId.FilterStroke,
		type: 'line',
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-color': MapLayerColor.FilterStrokeDark,
			'line-width': 2,
		},
		source: MapSourceId.FilterPolygon,
	},
	{
		id: MapLayerId.FilterSymbol,
		type: 'symbol',
		layout: {
			'text-field': ['get', 'label'],
			'text-padding': 3,
			'text-size': 18,
		},
		paint: {
			'text-color': MapLayerColor.FilterSymbolDark,
			'text-halo-blur': 0.5,
			'text-halo-color': MapLayerColor.FilterSymbolHaloLight,
			'text-halo-width': 1,
		},
		source: MapSourceId.FilterPolygon,
	},
	{
		id: MapLayerId.ParcelFill,
		minzoom: PARCEL_LAYER_MIN_ZOOM,
		paint: {
			'fill-color': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'highlighted',
					],
					false,
				],
				MapLayerColor.ParcelFillHighlighted,
				MapLayerColor.ParcelFillDark,
			],
			'fill-opacity': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'highlighted',
					],
					false,
				],
				0.65,
				[
					'boolean',
					[
						'feature-state',
						'selected',
					],
					false,
				],
				[
					'case',
					[
						'boolean',
						[
							'feature-state',
							'hover',
						],
						false,
					],
					0.65,
					0.25,
				],
				0,
			],
		},
		source: MapSourceId.Parcel,
		'source-layer': MapLayerId.ParcelSourceArea,
		type: 'fill',
		ui: {
			icon: new Icon({
				name: 'home',
			}),
			label: 'Parcels',
		},
	},
	{
		id: MapLayerId.ParcelLine,
		minzoom: PARCEL_LINE_LAYER_MIN_ZOOM,
		paint: {
			'line-color': MapLayerColor.ParcelLineLight,
			'line-opacity': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'selected',
					],
					false,
				],
				1,
				0.75,
			],
			'line-width': 0.4,
		},
		source: MapSourceId.Parcel,
		'source-layer': MapLayerId.ParcelSourceArea,
		type: 'line',
	},
	{
		id: MapLayerId.DoNotMailParcelCentroid,
		maxzoom: PARCEL_LAYER_MIN_ZOOM,
		paint: {
			'circle-color': MapLayerColor.DoNotMailCentroidDark,
			'circle-opacity': 1,
		},
		source: MapSourceId.DoNotMailParcelCentroid,
		type: 'circle',
	},
	{
		id: MapLayerId.DoNotMailParcelFill,
		minzoom: PARCEL_LAYER_MIN_ZOOM,
		paint: {
			'fill-color': MapLayerColor.DoNotMailFillDark,
			'fill-opacity': [
				'case',
				[
					'boolean',
					[
						'feature-state',
						'hover',
					],
					false,
				],
				0.65,
				0.45,
			],
		},
		source: MapSourceId.DoNotMailParcel,
		type: 'fill',
		ui: {
			icon: new Icon({
				name: 'do_not_disturb_on',
			}),
			label: 'Ignored',
		},
	},
	{
		id: MapLayerId.DebugFill,
		type: 'fill',
		paint: {
			'fill-color': [
				'case',
				[
					'get',
					'removed',
				],
				'#FF0000',
				[
					'case',
					[
						'get',
						'odd',
					],
					'#FFFFFF',
					'#000000',
				],
			],
			'fill-opacity': 0.5,
		},
		source: MapSourceId.Debug,
	},
	{
		id: MapLayerId.DebugTileSymbol,
		type: 'symbol',
		layout: {
			'text-field': ['get', 'label'],
		},
		paint: {
			'text-color': MapLayerColor.FilterSymbolDark,
			'text-halo-blur': 0.5,
			'text-halo-color': MapLayerColor.FilterSymbolHaloLight,
			'text-halo-width': 1,
		},
		source: MapSourceId.Debug,
	},
];
