import type {Obj} from './obj';
import type {Action} from './action';
import {Point, Size} from './tools';
import {
	Key,
	FocusReason,
	MouseButton,
	KeyboardModifier,
} from './constants';
import {clientMightBeMac} from './util';

export enum EvtType {
	None = 0,
	ActionAdded = 114,
	ActionChanged = 113,
	ActionRemoved = 115,
	Timer = 1,
	MouseButtonPress = 2,
	MouseButtonRelease = 3,
	MouseButtonDblClick = 4,
	MouseMove = 5,
	KeyPress = 6,
	KeyRelease = 7,
	FocusIn = 8,
	FocusOut = 9,
	FocusAboutToChange = 23,
	Enter = 10,
	Leave = 11,
	Move = 13,
	Resize = 14,
	Create = 15,
	Destroy = 16,
	Show = 17,
	Hide = 18,
	Close = 19,
	Quit = 20,
	ParentChange = 21,
	ParentAboutToChange = 131,
	ShowToParent = 26,
	HideToParent = 27,
	Wheel = 31,
	WindowTitleChange = 33,
	Clipboard = 40,
	MetaCall = 43,
	DeferredDelete = 52,
	DragEnter = 60,
	DragMove = 61,
	DragLeave = 62,
	Drop = 63,
	DragResponse = 64,
	ChildAdded = 68,
	ChildRemoved = 71,
	ShowWindowRequest = 73,
	UpdateRequest = 77,
	ContextMenu = 82,
	IconDrag = 96,
	FontChange = 97,
	EnabledChange = 98,
	ActivationChange = 99,
	StyleChange = 100,
	IconTextChange = 101,
	ModifiedChange = 102,
	MouseTrackingChange = 109,
	WindowBlocked = 103,
	WindowUnblocked = 104,
	WindowStateChange = 105,
	ReadOnlyChange = 106,
	ToolTip = 110,
	Shortcut = 117,
	ShortcutOverride = 51,
	ZOrderChange = 126,
	HoverEnter = 127,
	HoverLeave = 128,
	HoverMove = 129,
	EnterEditFocus = 150,
	LeaveEditFocus = 151,
	AcceptDropsChange = 152,
	DynamicPropertyChange = 170,
	CursorChange = 183,
	ToolTipChange = 184,
	GrabMouse = 186,
	UngrabMouse = 187,
	GrabKeyboard = 188,
	UngrabKeyboard = 189,
	TouchBegin = 194,
	TouchUpdate = 195,
	TouchEnd = 196,
	Scroll = 205,
	Expose = 206,
	OrientationChange = 208,
	TouchCancel = 209,
	ThemeChange = 210,
	SockClose = 211,
	StyleAnimationUpdate = 213,
	ApplicationStateChange = 214,
	Pointer = 218,
	User = 1000,
}

export class Evt {
	static None: EvtType.None = EvtType.None;
	static ActionAdded: EvtType.ActionAdded = EvtType.ActionAdded;
	static ActionChanged: EvtType.ActionChanged = EvtType.ActionChanged;
	static ActionRemoved: EvtType.ActionRemoved = EvtType.ActionRemoved;
	static Timer: EvtType.Timer = EvtType.Timer;
	static MouseButtonPress: EvtType.MouseButtonPress = EvtType.MouseButtonPress;
	static MouseButtonRelease: EvtType.MouseButtonRelease = EvtType.MouseButtonRelease;
	static MouseButtonDblClick: EvtType.MouseButtonDblClick = EvtType.MouseButtonDblClick;
	static MouseMove: EvtType.MouseMove = EvtType.MouseMove;
	static KeyPress: EvtType.KeyPress = EvtType.KeyPress;
	static KeyRelease: EvtType.KeyRelease = EvtType.KeyRelease;
	static FocusIn: EvtType.FocusIn = EvtType.FocusIn;
	static FocusOut: EvtType.FocusOut = EvtType.FocusOut;
	static FocusAboutToChange: EvtType.FocusAboutToChange = EvtType.FocusAboutToChange;
	static Enter: EvtType.Enter = EvtType.Enter;
	static Leave: EvtType.Leave = EvtType.Leave;
	static Move: EvtType.Move = EvtType.Move;
	static Resize: EvtType.Resize = EvtType.Resize;
	static Create: EvtType.Create = EvtType.Create;
	static Destroy: EvtType.Destroy = EvtType.Destroy;
	static Show: EvtType.Show = EvtType.Show;
	static Hide: EvtType.Hide = EvtType.Hide;
	static Close: EvtType.Close = EvtType.Close;
	static Quit: EvtType.Quit = EvtType.Quit;
	static ParentChange: EvtType.ParentChange = EvtType.ParentChange;
	static ParentAboutToChange: EvtType.ParentAboutToChange = EvtType.ParentAboutToChange;
	static ShowToParent: EvtType.ShowToParent = EvtType.ShowToParent;
	static HideToParent: EvtType.HideToParent = EvtType.HideToParent;
	static Wheel: EvtType.Wheel = EvtType.Wheel;
	static WindowTitleChange: EvtType.WindowTitleChange = EvtType.WindowTitleChange;
	static Clipboard: EvtType.Clipboard = EvtType.Clipboard;
	static MetaCall: EvtType.MetaCall = EvtType.MetaCall;
	static DeferredDelete: EvtType.DeferredDelete = EvtType.DeferredDelete;
	static DragEnter: EvtType.DragEnter = EvtType.DragEnter;
	static DragMove: EvtType.DragMove = EvtType.DragMove;
	static DragLeave: EvtType.DragLeave = EvtType.DragLeave;
	static Drop: EvtType.Drop = EvtType.Drop;
	static DragResponse: EvtType.DragResponse = EvtType.DragResponse;
	static ChildAdded: EvtType.ChildAdded = EvtType.ChildAdded;
	static ChildRemoved: EvtType.ChildRemoved = EvtType.ChildRemoved;
	static ShowWindowRequest: EvtType.ShowWindowRequest = EvtType.ShowWindowRequest;
	static UpdateRequest: EvtType.UpdateRequest = EvtType.UpdateRequest;
	static ContextMenu: EvtType.ContextMenu = EvtType.ContextMenu;
	static IconDrag: EvtType.IconDrag = EvtType.IconDrag;
	static FontChange: EvtType.FontChange = EvtType.FontChange;
	static EnabledChange: EvtType.EnabledChange = EvtType.EnabledChange;
	static ActivationChange: EvtType.ActivationChange = EvtType.ActivationChange;
	static StyleChange: EvtType.StyleChange = EvtType.StyleChange;
	static IconTextChange: EvtType.IconTextChange = EvtType.IconTextChange;
	static ModifiedChange: EvtType.ModifiedChange = EvtType.ModifiedChange;
	static MouseTrackingChange: EvtType.MouseTrackingChange = EvtType.MouseTrackingChange;
	static WindowBlocked: EvtType.WindowBlocked = EvtType.WindowBlocked;
	static WindowUnblocked: EvtType.WindowUnblocked = EvtType.WindowUnblocked;
	static WindowStateChange: EvtType.WindowStateChange = EvtType.WindowStateChange;
	static ReadOnlyChange: EvtType.ReadOnlyChange = EvtType.ReadOnlyChange;
	static ToolTip: EvtType.ToolTip = EvtType.ToolTip;
	static Shortcut: EvtType.Shortcut = EvtType.Shortcut;
	static ShortcutOverride: EvtType.ShortcutOverride = EvtType.ShortcutOverride;
	static ZOrderChange: EvtType.ZOrderChange = EvtType.ZOrderChange;
	static HoverEnter: EvtType.HoverEnter = EvtType.HoverEnter;
	static HoverLeave: EvtType.HoverLeave = EvtType.HoverLeave;
	static HoverMove: EvtType.HoverMove = EvtType.HoverMove;
	static EnterEditFocus: EvtType.EnterEditFocus = EvtType.EnterEditFocus;
	static LeaveEditFocus: EvtType.LeaveEditFocus = EvtType.LeaveEditFocus;
	static AcceptDropsChange: EvtType.AcceptDropsChange = EvtType.AcceptDropsChange;
	static DynamicPropertyChange: EvtType.DynamicPropertyChange = EvtType.DynamicPropertyChange;
	static CursorChange: EvtType.CursorChange = EvtType.CursorChange;
	static ToolTipChange: EvtType.ToolTipChange = EvtType.ToolTipChange;
	static GrabMouse: EvtType.GrabMouse = EvtType.GrabMouse;
	static UngrabMouse: EvtType.UngrabMouse = EvtType.UngrabMouse;
	static GrabKeyboard: EvtType.GrabKeyboard = EvtType.GrabKeyboard;
	static UngrabKeyboard: EvtType.UngrabKeyboard = EvtType.UngrabKeyboard;
	static TouchBegin: EvtType.TouchBegin = EvtType.TouchBegin;
	static TouchUpdate: EvtType.TouchUpdate = EvtType.TouchUpdate;
	static TouchEnd: EvtType.TouchEnd = EvtType.TouchEnd;
	static Scroll: EvtType.Scroll = EvtType.Scroll;
	static Expose: EvtType.Expose = EvtType.Expose;
	static OrientationChange: EvtType.OrientationChange = EvtType.OrientationChange;
	static TouchCancel: EvtType.TouchCancel = EvtType.TouchCancel;
	static ThemeChange: EvtType.ThemeChange = EvtType.ThemeChange;
	static SockClose: EvtType.SockClose = EvtType.SockClose;
	static StyleAnimationUpdate: EvtType.StyleAnimationUpdate = EvtType.StyleAnimationUpdate;
	static ApplicationStateChange: EvtType.ApplicationStateChange = EvtType.ApplicationStateChange;
	static Pointer: EvtType.Pointer = EvtType.Pointer;
	static User: EvtType.User = EvtType.User;

	static fromAnother(other: Evt): Evt {
		const rv = new this(other.typ);
		rv.m_accept = other.m_accept;
		return rv;
	}

	private m_accept: boolean;
	protected m_inputEvt: boolean;
	private typ: number;

	constructor(type: number) {
		this.m_accept = true;
		this.m_inputEvt = false;
		this.typ = type;
	}

	accept(): void {
		this.m_accept = true;
	}

	destroy(): void {
	}

	ignore(): void {
		this.m_accept = false;
	}

	isAccepted(): boolean {
		return this.m_accept;
	}

	isInputEvent(): boolean {
		return this.m_inputEvt;
	}

	setAccepted(accepted: boolean): void {
		this.m_accept = accepted;
	}

	toString(): string {
		return `${this.constructor.name}(${EvtType[this.typ]})`;
	}

	type(): EvtType {
		return this.typ;
	}
}

export class CloseEvt extends Evt {
	constructor() {
		super(EvtType.Close);
	}
}

export class ActionEvt extends Evt {
	private a: Action;
	private b: Action | null;

	constructor(type: EvtType.ActionAdded | EvtType.ActionChanged | EvtType.ActionRemoved, action: Action, before: Action | null = null) {
		super(type);
		this.a = action;
		this.b = before;
	}

	action(): Action {
		return this.a;
	}

	before(): Action | null {
		return this.b;
	}
}

export class ChildEvt extends Evt {
	private c: Obj;

	constructor(type: number, child: Obj) {
		super(type);
		this.c = child;
	}

	added(): boolean {
		return this.type() === Evt.ChildAdded;
	}

	child(): Obj {
		return this.c;
	}

	removed(): boolean {
		return this.type() === Evt.ChildRemoved;
	}
}

export class DynamicPropertyChangeEvt extends Evt {
	private n: string;

	constructor(name: string) {
		super(Evt.DynamicPropertyChange);
		this.n = name;
	}

	propertyName(): string {
		return this.n;
	}
}

export class EnterEvt extends Evt {
	private c: Point;
	private l: Point;

	constructor(localPos: Point, clientPos: Point) {
		super(Evt.Enter);
		this.c = clientPos;
		this.l = localPos;
	}

	clientPos(): Point {
		return this.c;
	}

	localPos(): Point {
		return this.l;
	}

	pos(): Point {
		return this.l;
	}

	x(): number {
		return this.l.x();
	}

	y(): number {
		return this.l.y();
	}
}

export class FocusEvt extends Evt {
	private m_reason: FocusReason;

	constructor(type: number, reason: FocusReason = FocusReason.OtherFocusReason) {
		super(type);
		this.m_reason = reason;
	}

	gotFocus(): boolean {
		return this.type() === Evt.FocusIn;
	}

	lostFocus(): boolean {
		return this.type() === Evt.FocusOut;
	}

	reason(): FocusReason {
		return this.m_reason;
	}

	toString(): string {
		return `${this.constructor.name}(${EvtType[this.type()]}, ${FocusReason[this.m_reason]})`;
	}
}

export class InputEvt extends Evt {
	private modState: KeyboardModifier;
	private ts: number;

	constructor(type: number, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type);
		this.m_inputEvt = true;
		this.modState = modifiers;
		this.ts = 0;
	}

	modifiers(): KeyboardModifier {
		return this.modState;
	}

	setModifiers(modifiers: KeyboardModifier): void {
		this.modState = modifiers;
	}

	setTimestamp(ts: number): void {
		this.ts = ts;
	}

	timestamp(): number {
		return this.ts;
	}
}

export class HoverEvt extends InputEvt {
	private p: Point;
	private op: Point;

	constructor(type: number, pos: Point, oldPos: Point, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type, modifiers);
		this.p = pos;
		this.op = oldPos;
	}

	oldPos(): Point {
		return this.op;
	}

	pos(): Point {
		return this.p;
	}
}

export class MouseEvt extends InputEvt {
	private b: MouseButton;
	private bs: number;
	private c: Point;

	constructor(type: number, clientPos: Point, button: MouseButton, buttons: number, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type, modifiers);
		this.b = button;
		this.bs = buttons;
		this.c = clientPos;
	}

	button(): MouseButton {
		return this.b;
	}

	buttons(): number {
		// Returns the button state when the event was generated. The button
		// state is a combination of Type::LeftButton, Type::RightButton,
		// Type::MidButton using the OR operator. For mouse move events,
		// this is all buttons that are pressed down. For mouse press and
		// double click events this includes the button that caused the
		// event. For mouse release events this excludes the button that
		// caused the event.
		return this.bs;
	}

	pos(): Point {
		return this.c;
	}

	toString(): string {
		return `MouseEvt(${EvtType[this.type()]}, ${MouseButton[this.button()]}, ${this.pos()}`;
	}

	x(): number {
		return this.c.x();
	}

	y(): number {
		return this.c.y();
	}
}

export class KeyEvt extends InputEvt {
	private k: string;

	constructor(type: number, key: string, modifiers: KeyboardModifier = KeyboardModifier.NoModifier) {
		super(type, modifiers);
		this.k = key;
	}

	key(): string {
		return this.k;
	}

	modifiers(): KeyboardModifier {
		const rv = super.modifiers();
		switch (this.k) {
			case Key.Shift:
				return rv ^ KeyboardModifier.ShiftModifier;
			case Key.Control:
				return rv ^ KeyboardModifier.ControlModifier;
			case Key.Alt:
				return rv ^ KeyboardModifier.AltModifier;
			case Key.Meta:
				return rv ^ KeyboardModifier.MetaModifier;
			default:
				return rv;
		}
	}

	toString(): string {
		return `${this.constructor.name}(${EvtType[this.type()]}, ${this.key()}, accepted=${this.isAccepted()})`;
	}
}

export class ResizeEvt extends Evt {
	olds: Size;
	s: Size;

	constructor(size: Size, oldSize: Size) {
		super(EvtType.Resize);
		this.olds = oldSize;
		this.s = size;
	}

	oldSize(): Size {
		return this.olds;
	}

	size(): Size {
		return this.s;
	}
}

export class ShowEvt extends Evt {
	constructor() {
		super(Evt.Show);
	}
}

export class HideEvt extends Evt {
	constructor() {
		super(Evt.Hide);
	}
}

function _domEventKeyModifiers(event: KeyboardEvent | MouseEvent): number {
	let rv: KeyboardModifier = KeyboardModifier.NoModifier;
	if (event.altKey) {
		rv |= KeyboardModifier.AltModifier;
	}
	if (event.ctrlKey || (event.metaKey && clientMightBeMac())) {
		rv |= KeyboardModifier.ControlModifier;
	}
	if (event.metaKey) {
		rv |= KeyboardModifier.MetaModifier;
	}
	if (event.shiftKey) {
		rv |= KeyboardModifier.ShiftModifier;
	}
	return rv;
}

function _dom_EventTypeToEvtType(domEventType: string): EvtType {
	switch (domEventType) {
		case 'mousemove': {
			return EvtType.MouseMove;
		}
		case 'keydown': {
			return EvtType.KeyPress;
		}
		case 'keyup': {
			return EvtType.KeyRelease;
		}
		case 'mouseenter': {
			return EvtType.Enter;
		}
		case 'mouseleave':
		case 'mouseout':
		case 'pointerleave': {
			return EvtType.Leave;
		}
		case 'mousedown': {
			return EvtType.MouseButtonPress;
		}
		case 'mouseup': {
			return EvtType.MouseButtonRelease;
		}
		case 'dblclick': {
			return EvtType.MouseButtonDblClick;
		}
		case 'resize': {
			return EvtType.Resize;
		}
		case 'contextmenu': {
			return EvtType.ContextMenu;
		}
		case 'mouseover': {
			return EvtType.HoverMove;
		}
		default: {
			return EvtType.None;
		}
	}
}

function _domEventMouseButton(button: number): MouseButton {
	switch (button) {
		case 0: {
			return MouseButton.LeftButton;
		}
		case 1: {
			return MouseButton.MiddleButton;
		}
		case 2: {
			return MouseButton.RightButton;
		}
		case 3: {
			return MouseButton.BackButton;
		}
		case 4: {
			return MouseButton.ForwardButton;
		}
		default: {
			return MouseButton.NoButton;
		}
	}
}

export function keyEvtFromDomKeyboardEvent(event: KeyboardEvent): KeyEvt {
	return new KeyEvt(
		_dom_EventTypeToEvtType(event.type),
		event.key,
		_domEventKeyModifiers(event),
	);
}

export function mouseEvtFromDomMouseEvent(event: MouseEvent): MouseEvt {
	return new MouseEvt(
		_dom_EventTypeToEvtType(event.type),
		new Point(
			event.clientX,
			event.clientY,
		),
		_domEventMouseButton(event.button),
		event.buttons,
		_domEventKeyModifiers(event),
	);
}
