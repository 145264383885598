export const CSRF_COOKIE_NAME = 'csrftoken';
export const CSRF_INPUT_NAME = 'csrfmiddlewaretoken';
export const CSS_CLASS_FULL_WIDTH = 'width--100-percent';
export const DEFAULT_MAP_ZOOM_LEVEL = 11;
export const DEFAULT_MAP_PITCH = 0;
export const DEFAULT_MAP_BEARING = 0;
export const DEFAULT_MAP_LONGITUDE = -77.887306;
export const INTERACTIVE_MAP_PUBLIC_TOKEN = 'pk.eyJ1Ijoibmlja3NpbCIsImEiOiJja2xscnRrbXEwMjc3Mm5tdzk4eXR3eWtrIn0.zV0FxNlDS30nsDuxipF_Cg';
export const DEFAULT_MAP_LATITUDE = 34.210938;
export const DEFAULT_MAP_TILE_SIZE = 512;
export const EMPTY_FILTER_LABEL_TEXT = 'Unlabeled Filter';
export const EMPTY_PROJECT_TITLE_TEXT = 'Untitled Project';
export const GEO_AREA_TILES_URI_PREFIX = `${window.location.origin}/api/geo/area/tiles`;
export const GEO_NODE_TILES_URI_PREFIX = `${window.location.origin}/api/geo/node/tiles`;
export const INFINITY_CHAR_CODE = 8734;
export const NBSP_CHAR_CODE = 160;
export const WGS84_RADIUS = 6378137;
export const WGS84_FLATTENING = 1 / 298.257223563;
export const WGS84_POLAR_RADIUS = 6356752.3142;
export const PARCEL_LAYER_MIN_ZOOM = 13.0;
export const DATA_PARCEL_LAYER_MIN_ZOOM = 10.0;
export const PARCEL_LINE_LAYER_MIN_ZOOM = 14.5;
export const PROJECT_DETAIL_BODY_ELEMENT_ID = 'id_lb-project-detail-body';
export const DATA_VIEW_ROOT_ELEMENT_ID = 'id_lb-data-view';
export const INTERACTIVE_MAP_ELEMENT_ID = 'id_lb-interactive-map';
export const PROJECT_DETAIL_PRIMARY_INPUT_ELEMENT_ID = 'id_lb-project-detail-primary-input';
export const REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY = '_LB_REQUEST_CONFIG_WELL_KNOWN_REGISTRY_KEY';
export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_QUARTER_HOUR = 900;
export const TAP_DISTANCE_TOLERANCE = 25;
export const TAP_INTERVAL = 250;

export enum ActionEvent {
	Trigger,
	Hover,
}

export enum AlignmentFlag {
	AlignLeft = 0x0001,
	AlignLeading = AlignLeft,
	AlignRight = 0x0002,
	AlignTrailing = AlignRight,
	AlignHCenter = 0x0004,
	AlignJustify = 0x0008,
	AlignAbsolute = 0x0010,
	AlignHorizontal_Mask = AlignLeft | AlignRight | AlignHCenter | AlignJustify | AlignAbsolute,
	AlignTop = 0x0020,
	AlignBottom = 0x0040,
	AlignVCenter = 0x0080,
	AlignBaseline = 0x0100,
	AlignVertical_Mask = AlignTop | AlignBottom | AlignVCenter | AlignBaseline,
	AlignCenter = AlignVCenter | AlignHCenter,
}

export enum AspectRatioMode {
	IgnoreAspectRatio,
	KeepAspectRatio,
	KeepAspectRatioByExpanding,
}

export enum ButtonLayout {
	Unknown,
	WinLayout,
	MacLayout,
	KdeLayout,
	GnomeLayout,
	AndroidLayout
}

export enum CloseMode {
	CloseNoEvent,
	CloseWithEvent,
}

export enum CssClassName {
	ActiveButton = 'active',
	Attribution = 'mapboxgl-ctrl-attrib',
	BoxSelect = 'mapbox-gl-draw_boxselect',
	ControlBase = 'mapboxgl-ctrl',
	ControlButton = 'mapbox-gl-draw_ctrl-draw-btn',
	MapControl = 'lb-map-control',
	ControlButtonCircle = 'mapbox-gl-draw_circle',
	ControlButtonCombineFeatures = 'mapbox-gl-draw_combine',
	ControlButtonLineString = 'mapbox-gl-draw_line',
	ControlButtonPoint = 'mapbox-gl-draw_point',
	ControlButtonPolygon = 'mapbox-gl-draw_polygon',
	ControlButtonTrash = 'mapbox-gl-draw_trash',
	ControlButtonUncombineFeatures = 'mapbox-gl-draw_uncombine',
	ControlGroup = 'mapboxgl-ctrl-group',
}

export enum Cursor {
	Default = '',
	Add = 'add',
	Crosshair = 'crosshair',
	Drag = 'drag',
	Move = 'move',
	None = 'none',
	Pointer = 'pointer',
}

// Keep in sync w/backend core.types.DataType
export enum DataType {
	Bool = 'bool',
	Bytes = 'bytes',
	Date = 'date',
	Datetime = 'datetime',
	DatetimeTz = 'datetime_tz',
	Decimal = 'decimal',
	Float = 'float',
	Geometry = 'geometry',
	Int = 'int',
	Str = 'str',
	Time = 'time',
	TimeDelta = 'timedelta',
	Uuid4 = 'uuid4',
}

// Keep in sync w/backend odata.constants.EdmAbstractType
export enum EdmAbstractType {
	AnnotationPath = 'Edm.AnnotationPath',
	AnnotationPathCollection = 'Collection(Edm.AnnotationPath)',
	AnyPropertyPath = 'Edm.AnyPropertyPath',
	AnyPropertyPathCollection = 'Collection(Edm.AnyPropertyPath)',
	ComplexType = 'Edm.ComplexType',
	ComplexTypeCollection = 'Collection(Edm.ComplexType)',
	EntityType = 'Edm.EntityType',
	EntityTypeCollection = 'Collection(Edm.EntityType)',
	Geography = 'Edm.Geography',
	GeographyCollection = 'Collection(Edm.Geography)',
	Geometry = 'Edm.Geometry',
	GeometryCollection = 'Collection(Edm.Geometry)',
	ModelElementPath = 'Edm.ModelElementPath',
	ModelElementPathCollection = 'Collection(Edm.ModelElementPath)',
	NavigationPropertyPath = 'Edm.NavigationPropertyPath',
	NavigationPropertyPathCollection = 'Collection(Edm.NavigationPropertyPath)',
	PrimitiveType = 'Edm.PrimitiveType',
	PrimitiveTypeCollection = 'Collection(Edm.PrimitiveType)',
	PropertyPath = 'Edm.PropertyPath',
	PropertyPathCollection = 'Collection(Edm.PropertyPath)',
	Untyped = 'Edm.Untyped',
	UntypedCollection = 'Collection(Edm.Untyped)',
}

// Keep in sync w/backend odata.constants.EdmPrimitiveType
export enum EdmPrimitiveType {
	Binary = 'Edm.Binary',
	BinaryCollection = 'Collection(Edm.Binary)',
	Boolean = 'Edm.Boolean',
	BooleanCollection = 'Collection(Edm.Boolean)',
	Byte = 'Edm.Byte',
	ByteCollection = 'Collection(Edm.Byte)',
	Date = 'Edm.Date',
	DateCollection = 'Collection(Edm.Date)',
	DateTimeOffset = 'Edm.DateTimeOffset',
	DateTimeOffsetCollection = 'Collection(Edm.DateTimeOffset)',
	Decimal = 'Edm.Decimal',
	DecimalCollection = 'Collection(Edm.Decimal)',
	Double = 'Edm.Double',
	DoubleCollection = 'Collection(Edm.Double)',
	Duration = 'Edm.Duration',
	DurationCollection = 'Collection(Edm.Duration)',
	GeographyCollection = 'Edm.GeographyCollection',
	GeographyCollectionCollection = 'Collection(Edm.GeographyCollection)',
	GeographyLineString = 'Edm.GeographyLineString',
	GeographyLineStringCollection = 'Collection(Edm.GeographyLineString)',
	GeographyMultiLineString = 'Edm.GeographyMultiLineString',
	GeographyMultiLineStringCollection = 'Collection(Edm.GeographyMultiLineString)',
	GeographyMultiPoint = 'Edm.GeographyMultiPoint',
	GeographyMultiPointCollection = 'Collection(Edm.GeographyMultiPoint)',
	GeographyMultiPolygon = 'Edm.GeographyMultiPolygon',
	GeographyMultiPolygonCollection = 'Collection(Edm.GeographyMultiPolygon)',
	GeographyPoint = 'Edm.GeographyPoint',
	GeographyPointCollection = 'Collection(Edm.GeographyPoint)',
	GeographyPolygon = 'Edm.GeographyPolygon',
	GeographyPolygonCollection = 'Collection(Edm.GeographyPolygon)',
	GeometryCollection = 'Edm.GeometryCollection',
	GeometryCollectionCollection = 'Collection(Edm.GeometryCollection)',
	GeometryLineString = 'Edm.GeometryLineString',
	GeometryLineStringCollection = 'Collection(Edm.GeometryLineString)',
	GeometryMultiLineString = 'Edm.GeometryMultiLineString',
	GeometryMultiLineStringCollection = 'Collection(Edm.GeometryMultiLineString)',
	GeometryMultiPoint = 'Edm.GeometryMultiPoint',
	GeometryMultiPointCollection = 'Collection(Edm.GeometryMultiPoint)',
	GeometryMultiPolygon = 'Edm.GeometryMultiPolygon',
	GeometryMultiPolygonCollection = 'Collection(Edm.GeometryMultiPolygon)',
	GeometryPoint = 'Edm.GeometryPoint',
	GeometryPointCollection = 'Collection(Edm.GeometryPoint)',
	GeometryPolygon = 'Edm.GeometryPolygon',
	GeometryPolygonCollection = 'Collection(Edm.GeometryPolygon)',
	Guid = 'Edm.Guid',
	GuidCollection = 'Collection(Edm.Guid)',
	Int16 = 'Edm.Int16',
	Int16Collection = 'Collection(Edm.Int16)',
	Int32 = 'Edm.Int32',
	Int32Collection = 'Collection(Edm.Int32)',
	Int64 = 'Edm.Int64',
	Int64Collection = 'Collection(Edm.Int64)',
	SByte = 'Edm.SByte',
	SByteCollection = 'Collection(Edm.SByte)',
	Single = 'Edm.Single',
	SingleCollection = 'Collection(Edm.Single)',
	String = 'Edm.String',
	StringCollection = 'Collection(Edm.String)',
	TimeOfDay = 'Edm.TimeOfDay',
	TimeOfDayCollection = 'Collection(Edm.TimeOfDay)',
}

export enum FociisODataVocabTerm {
	TagSupport = 'TagSupport',
	RelatedRecordLookup = 'RelatedRecordLookup',
	GoToArea = 'GoToArea',
}

export enum ODataOnDeleteAction {
	Cascade = 'Cascade',
	None = 'None',
	SetNull = 'SetNull',
	SetDefault = 'SetDefault',
}

export enum ODataUnboundFunction {
	AreaPkTile = 'area_pk_tile',
	Geom = 'area_geom',
	LookupAssoc = 'assoc',
	RecordSkip = 'record_skip',
}

export enum ODataVocabCoreTerm {
	Permissions = 'Org.OData.Core.V1.Permission',
}

export enum ODataCorePermissions {
	None = 'None',
	Read = 'Read',
	Write = 'Write',
	ReadWrite = 'ReadWrite',
	Invoke = 'Invoke',

}

export enum MapControlType {
	NoType = 0x0000,
	Zoom = 0x0001,
	Draw = 0x0002,
	Info = 0x0004,
	Layer = 0x0008,
}

export enum MapStyle {
	Street = 'mapbox://styles/mapbox/streets-v11',
	SatelliteStreet = 'mapbox://styles/mapbox/satellite-streets-v11',
}

export const DEFAULT_MAP_STYLE_IDENT: MapStyle = MapStyle.Street;

export enum MapStyleTheme {
	Dark = 'dark',
	Light = 'light',
}

export const DEFAULT_MAP_STYLE_THEME: MapStyleTheme = MapStyleTheme.Dark;

export enum InteractiveMapControlPosition {
	TopRight = 'top-right',
	TopLeft = 'top-left',
	BottomRight = 'bottom-right',
	BottomLeft = 'bottom-left',
}

export enum InteractiveMapFlag {
	NoMapFlags = 0x0000,
	MapNavControlIsEnabled = 0x0001,
	MapInfoControlIsEnabled = 0x0002,
	MapLayerControlIsEnabled = 0x0004,
	MapDrawingIsEnabled = 0x0008,
}

export enum ArrowType {
	NoArrow,
	UpArrow,
	DownArrow,
	LeftArrow,
	RightArrow,
}

export enum ButtonRole {
	InvalidRole = -1,
	AcceptRole,
	RejectRole,
	DestructiveRole,
	ActionRole,
	HelpRole,
	YesRole,
	NoRole,
	ResetRole,
	ApplyRole,
	NRoles,
	RoleMask = 0x0FFFFFFF,
	AlternateRole = 0x10000000,
	Stretch = 0x20000000,
	Reverse = 0x40000000,
	EOL = InvalidRole,
}

export const ButtonRoleOrder: ButtonRole[] = [
	ButtonRole.RejectRole,
	ButtonRole.NoRole,
	ButtonRole.DestructiveRole,
	ButtonRole.ResetRole,
	ButtonRole.HelpRole,
	ButtonRole.ActionRole,
	ButtonRole.ApplyRole,
	ButtonRole.AcceptRole,
	ButtonRole.YesRole,
];

export enum CaseSensitivity {
	CaseInsensitive,
	CaseSensitive,
}

export enum CheckIndexOption {
	NoOption = 0x0000,
	IndexIsValid = 0x0001,
	DoNotUseParent = 0x0002,
	ParentIsInvalid = 0x0004,
}

export enum CheckState {
	Unchecked = 0,
	PartiallyChecked = 1,
	Checked = 2,
}

export enum CoordinateType {
	InvalidCoordinate = 0,
	Coordinate2D = 1,
}

export enum DialogCode {
	Rejected,
	Accepted,
}

export enum FocusReason {
	MouseFocusReason,
	TabFocusReason,
	BacktabFocusReason,
	ActiveWindowFocusReason,
	PopupFocusReason,
	ShortcutFocusReason,
	MenuBarFocusReason,
	OtherFocusReason,
	NoFocusReason,
}

// Keep in sync w/backend core.types.ControlType
export enum ControlType {
	NoType = '',
	LineEdit = 'line_edit',
	ComboBox = 'combo_box',
	Tree = 'tree',
}

export enum ElAttr {
	Disabled = 0,
	UnderMouse = 1,
	MouseTracking = 2,
	UpdatesDisabled = 10,
	Visible = 15,
	Hidden = 16,
	ForceDisabled = 32,
	PendingMoveEvent = 34,
	PendingResizeEvent = 35,
	SetFont = 37,
	SetCursor = 38,
	NoChildEventsFromChildren = 39,
	Resized = 42,
	Moved = 43,
	PendingUpdate = 44,
	TransparentForMouseEvents = 51,
	DeleteOnClose = 55,
	RightToLeft = 56,
	NoChildEventsForParent = 58,
	ForceUpdatesDisabled = 59,
	Reparented = 63,
	ConfigPending = 64,
	ExplicitShowHide = 69,
	NoMousePropagation = 73,
	Hover = 74,
	QuitOnClose = 76,
	AcceptDrops = 78,
	AlwaysShowToolTips = 84,
	DontShowOnScreen = 103,
	TranslucentBackground = 120,
	AcceptTouchEvents = 121,
	AlwaysStackOnTop = 128,
	AttributeCount,
}

export enum ExpressionSegment {
	LeftHandSide,
	Operator,
	RightHandSide,
}

export enum InteractiveMapControlMode {
	NoMode,
	DrawShape,
	InfoMode,
	LayerPickerMode,
	ShapeSelect,
}

export enum ItemDataRole {
	DisplayRole = 0,
	DecorationRole = 1,
	EditRole = 2,
	ToolTipRole = 3,
	StatusTipRole = 4,
	WhatsThisRole = 5,
	FontRole = 6,
	TextAlignmentRole = 7,
	BackgroundRole = 8,
	ForegroundRole = 9,
	CheckStateRole = 10,
	AccessibleTextRole = 11,
	AccessibleDescriptionRole = 12,
	SizeHintRole = 13,
	InitialSortOrderRole = 14,
	DisplayPropertyRole = 27,
	DecorationPropertyRole = 28,
	ToolTipPropertyRole = 29,
	StatusTipPropertyRole = 30,
	WhatsThisPropertyRole = 31,
	IdentifierRole = 32,
	UserRole = 0x0100,
}

export enum ItemFlag {
	NoItemFlags = 0,
	ItemIsSelectable = 1,
	ItemIsEditable = 2,
	ItemIsDragEnabled = 4,
	ItemIsDropEnabled = 8,
	ItemIsUserCheckable = 16,
	ItemIsEnabled = 32,
	ItemIsAutoTristate = 64,
	ItemNeverHasChildren = 128,
	ItemIsUserTristate = 256,
}

export enum Key {
	Backspace = 'Backspace',
	Delete = 'Delete',
	Enter = 'Enter',
	Escape = 'Escape',
	Tab = 'Tab',
	ArrowUp = 'ArrowUp',
	ArrowRight = 'ArrowRight',
	ArrowDown = 'ArrowDown',
	ArrowLeft = 'ArrowLeft',
	Shift = 'Shift',
	Space = ' ',
	Meta = 'Meta',
	Alt = 'Alt',
	Control = 'Control',
	Home = 'Home',
	End = 'End',
	PageUp = 'PageUp',
	PageDown = 'PageDown',
}

export enum KeyboardModifier {
	NoModifier = 0x00000000,
	ShiftModifier = 0x02000000,
	ControlModifier = 0x04000000,
	AltModifier = 0x08000000,
	MetaModifier = 0x10000000,
	KeypadModifier = 0x20000000,
	GroupSwitchModifier = 0x40000000,
	// Do not extend the mask to include 0x01000000
	KeyboardModifierMask = 0xfe000000
}

export enum LayoutChangeHint {
	NoLayoutChangeHint,
	VerticalSortHint,
	HorizontalSortHint,
}

export enum MapLayer {
	NoLayer = 0x0000,
	Filters = 0x0002,
	Parcels = 0x0004,
	DoNotMail = 0x0008,
}

export enum MatchFlag {
	MatchExactly = 0,
	MatchContains = 1,
	MatchStartsWith = 2,
	MatchEndsWith = 3,
	MatchWildcard = 5,
	MatchFixedString = 8,
	MatchRegularExpression = 9,
	MatchCaseSensitive = 16,
	MatchWrap = 32,
	MatchRecursive = 64,
}

export enum MouseButton {
	NoButton = 0x00000000,
	LeftButton = 0x00000001,
	RightButton = 0x00000002,
	MiddleButton = 0x00000004,
	BackButton = 0x00000008,
	XButton1 = BackButton,
	ExtraButton1 = XButton1,
	ForwardButton = 0x00000010,
	XButton2 = ForwardButton,
	ExtraButton2 = ForwardButton,
	TaskButton = 0x00000020,
	ExtraButton3 = TaskButton,
	ExtraButton4 = 0x00000040,
	ExtraButton5 = 0x00000080,
	ExtraButton6 = 0x00000100,
	ExtraButton7 = 0x00000200,
	ExtraButton8 = 0x00000400,
	ExtraButton9 = 0x00000800,
	ExtraButton10 = 0x00001000,
	ExtraButton11 = 0x00002000,
	ExtraButton12 = 0x00004000,
	ExtraButton13 = 0x00008000,
	ExtraButton14 = 0x00010000,
	ExtraButton15 = 0x00020000,
	ExtraButton16 = 0x00040000,
	ExtraButton17 = 0x00080000,
	ExtraButton18 = 0x00100000,
	ExtraButton19 = 0x00200000,
	ExtraButton20 = 0x00400000,
	ExtraButton21 = 0x00800000,
	ExtraButton22 = 0x01000000,
	ExtraButton23 = 0x02000000,
	ExtraButton24 = 0x04000000,
	AllButtons = 0x07ffffff,
	MaxMouseButton = ExtraButton24,
	// 4 high-order bits remain available for future use (0x08000000 through 0x40000000).
	MouseButtonMask = 0xffffffff
}

// Keep in sync with Invoice.State
export enum InvoiceState {
	Draft = 'draft',
	Settled = 'settled',
	Void = 'void',
}

export enum MapDrawMode {
	DirectSelect = 'directselect',
	DrawCircle = 'drawcircle',
	DrawLineString = 'drawlinestring',
	DrawPoint = 'drawpoint',
	DrawPolygon = 'drawpolygon',
	NoMode = '',
	SimpleSelect = 'simpleselect',
}

export enum MapFeatureRole {
	Feature = 'feature',
	Midpoint = 'midpoint',
	Vertex = 'vertex',
}

export enum MapLayerColor {
	DoNotMailCentroidDark = '#FF0000',
	DoNotMailFillDark = '#FF0000',
	FilterFillDark = '#404040',
	FilterFillLight = '#FFFFFF',
	FilterFillOutlineDark = '#404040',
	FilterStrokeDark = '#404040',
	FilterStrokeLight = '#FFFFFF',
	FilterSymbolDark = 'hsl(230, 29%, 35%)',
	FilterSymbolHaloLight = 'hsl(0, 0%, 100%)',
	ParcelFillDark = '#6200EE',
	ParcelFillHighlighted = '#00CC75',
	ParcelFillLight = '#2D65FF',
	ParcelLineLight = '#FFFFFF',
}

export enum MapLayerId {
	DebugFill = 'id_lb-debug-fill-layer',
	DebugTileSymbol = 'id_lb-debug-tile-symbol-layer',
	DoNotMailParcelCentroid = 'id_lb-do-not-mail-parcel-centroid-layer',
	DoNotMailParcelFill = 'id_lb-do-not-mail-parcel-fill-layer',
	DrawLineActive = 'gl-draw-line-active',
	DrawLineInactive = 'gl-draw-line-inactive',
	DrawLineStatic = 'gl-draw-line-static',
	DrawPointActive = 'gl-draw-point-active',
	DrawPointInactive = 'gl-draw-point-inactive',
	DrawPointPointStrokeInactive = 'gl-draw-point-point-stroke-inactive',
	DrawPointStatic = 'gl-draw-point-static',
	DrawPointStrokeActive = 'gl-draw-point-stroke-active',
	DrawPolygonAndLineVertexInactive = 'gl-draw-polygon-and-line-vertex-inactive',
	DrawPolygonAndLineVertexStrokeInactive = 'gl-draw-polygon-and-line-vertex-stroke-inactive',
	DrawPolygonFillActive = 'gl-draw-polygon-fill-active',
	DrawPolygonFillInactive = 'gl-draw-polygon-fill-inactive',
	DrawPolygonFillStatic = 'gl-draw-polygon-fill-static',
	DrawPolygonMidpoint = 'gl-draw-polygon-midpoint',
	DrawPolygonStrokeActive = 'gl-draw-polygon-stroke-active',
	DrawPolygonStrokeInactive = 'gl-draw-polygon-stroke-inactive',
	DrawPolygonStrokeStatic = 'gl-draw-polygon-stroke-static',
	FilterFill = 'id_lb-filter-fill-layer',
	FilterPoint = 'id_lb-filter-point-layer',
	FilterStroke = 'id_lb-filter-stroke-layer',
	FilterSymbol = 'id_lb-filter-symbol-layer',
	ParcelFill = 'id_lb-parcel-fill-layer',
	ParcelLine = 'id_lb-parcel-line-layer',
	ParcelSourceArea = 'lb-area-tiles',
	ParcelSourceNode = 'lb-node-tiles',
}

export enum MapSourceId {
	Debug = 'id_lb-debug-source',
	DoNotMailParcel = 'id_lb-do-not-mail-parcel-source',
	DoNotMailParcelCentroid = 'id_lb-do-not-mail-parcel-centroid-source',
	DrawCold = 'mapbox-gl-draw-cold',
	DrawHot = 'mapbox-gl-draw-hot',
	FilterPoint = 'id_lb-filter-point-source',
	FilterPolygon = 'id_lb-filter-polygon-source',
	Parcel = 'id_lb-parcel-source',
}

export enum Orientation {
	Horizontal = 0x1,
	Vertical = 0x2
}

// NB: Keep in-sync w/backend data.models::Place.Type
export enum PlaceType {
	State = 'state',
	County = 'county',
	City = 'city',
	Township = 'township',
	PostalCode = 'postal_code',
	Subdivision = 'subdivision',
}

export enum Platform {
	Mac = 1,
	Other,
}

export enum ProjectFilterParam {
	// NB: Keep in sync with back-end api.views.project::FilterParam
	Archived = 'archived',
	NotArchived = 'not_archived',
	All = 'all',
}

export enum ProjectOption {
	Archive = 'Archive',
	Restore = 'Restore',
	Copy = 'Copy',
	Download = 'Download',
	Pay = 'Pay',
}

export enum SelectionBehavior {
	SelectItems,
	SelectRows,
	SelectColumns,
}

export enum SelectionFlag {
	NoUpdate = 0x0000, // No selection will be made.
	Clear = 0x0001,    // The complete selection will be cleared.
	Select = 0x0002,   // All specified will be selected.
	Deselect = 0x0004, // All specified will be deselected.
	Toggle = 0x0008,   // All specified will be selected or deselected depending on their current state.
	Current = 0x0010,  // The current selection will be updated.
	Rows = 0x0020,
	Columns = 0x0040,
	SelectCurrent = Select | Current, // A combination of Select and Current, provided for convenience.
	ToggleCurrent = Toggle | Current, // A combination of Toggle and Current, provided for convenience.
	ClearAndSelect = Clear | Select,  // A combination of Clear and Select, provided for convenience.
}

/**
	SingleSelection: When the user selects an item, any already-selected item
					 becomes unselected. It is possible for the user to deselect
					 the selected item by pressing the Ctrl key when clicking the
					 selected item.
	ContiguousSelection: When the user selects an item in the usual way, the
						 selection is cleared and the new item selected. However,
						 if the user presses the Shift key while clicking on an
						 item, all items between the current item and the clicked
						 item are selected or unselected, depending on the state
						 of the clicked item.
	ExtendedSelection: When the user selects an item in the usual way, the
					   selection is cleared and the new item selected. However, if
					   the user presses the Ctrl key when clicking on an item, the
					   clicked item gets toggled and all other items are left
					   untouched. If the user presses the Shift key while clicking
					   on an item, all items between the current item and the
					   clicked item are selected or unselected, depending on the
					   state of the clicked item. Multiple items can be selected
					   by dragging the mouse over them.
	MultiSelection: When the user selects an item in the usual way, the selection
					status of that item is toggled and the other items are left
					alone. Multiple items can be toggled by dragging the mouse
					over them.
	NoSelection: Items cannot be selected.
 */
export enum SelectionMode {
	NoSelection,
	SingleSelection,
	MultiSelection,
	ExtendedSelection,
	ContiguousSelection,
}

export enum SortOrder {
	NoOrder,
	AscendingOrder,
	DescendingOrder,
}

export enum StandardButton {
	NoButton = 0x00000000,
	Ok = 0x00000400,
	Save = 0x00000800,
	SaveAll = 0x00001000,
	Open = 0x00002000,
	Yes = 0x00004000,
	YesToAll = 0x00008000,
	No = 0x00010000,
	NoToAll = 0x00020000,
	Abort = 0x00040000,
	Retry = 0x00080000,
	Ignore = 0x00100000,
	Close = 0x00200000,
	Cancel = 0x00400000,
	Discard = 0x00800000,
	Help = 0x01000000,
	Apply = 0x02000000,
	Reset = 0x04000000,
	RestoreDefaults = 0x08000000,
	FirstButton = Ok,
	LastButton = RestoreDefaults,
}

export enum TextFormat {
	PlainText,
	RichText,
	AutoText,
	MarkdownText,
}

export enum UIComponentType {
	Column = 'COLUMN',
	DataTable = 'TABLE',
	Filter = 'FILTER',
	// Floater = 'FLOATER',
	Window = 'WINDOW',
}

export enum UnitOfMeasurement {
	Percent = 'PERCENT',
	Pixel = 'PIXEL',
}

export enum WhichPage {
	FirstPage,
	PrevPage,
	NextPage,
	LastPage,
}

export enum WindowState {
	WindowNoState = 0x00000000,
	WindowMinimized = 0x00000001,
	WindowMaximized = 0x00000002,
	WindowFullScreen = 0x00000004,
	WindowActive = 0x00000008,
}
