import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {OBJ} from '../obj';
import {isNumber} from '../util';

@OBJ
class GridInner extends ElObj {
	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-layout-grid__inner',
			'lb-grid-layout-inner',
		);
		super(opts);
	}
}

export class GridLayoutPrivate extends ElObjPrivate {
	inner: GridInner | null;

	constructor() {
		super();
		this.inner = null;
	}

	init(opts: Partial<GridLayoutOpts>): void {
		super.init(opts);
		this.inner = new GridInner({
			parent: this.q,
		});
	}

	get q(): GridLayout {
		return <GridLayout>super.q;
	}
}

export interface GridLayoutOpts extends ElObjOpts {
	dd: GridLayoutPrivate;
}

@OBJ
export class GridLayout extends ElObj {
	constructor(opts: Partial<GridLayoutOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-layout-grid',
			'lb-grid-layout',
		);
		opts.dd = opts.dd || new GridLayoutPrivate();
		super(opts);
	}

	addEl(el: ElObj, span?: CellSpan): void {
		const d = this.d;
		if (!d.inner) {
			return;
		}
		const cell = new GridCell({
			parent: d.inner,
			span,
		});
		el.setParent(cell);
		cell.show();
	}

	count(): number {
		const d = this.d;
		return d.inner ?
			d.inner.children().size() :
			0;
	}

	get d(): GridLayoutPrivate {
		return <GridLayoutPrivate>super.d;
	}

	indexOf(el: ElObj | null): number {
		const d = this.d;
		if (!el || !d.inner) {
			return -1;
		}
		for (let i = 0; i < d.inner.children().size(); ++i) {
			const child = d.inner.children().at(i);
			const grandChildren = child.children();
			if ((grandChildren.size() === 1) && (grandChildren.first() === el)) {
				return i;
			}
		}
		return -1;
	}

	takeEl(index: number): ElObj | null {
		const d = this.d;
		if (!d.inner) {
			return null;
		}
		if ((index < 0) || (index >= d.inner.children().size())) {
			return null;
		}
		const cell = d.inner.children().at(index);
		const el = <ElObj>cell.children().first();
		cell.setParent(null);
		el.setParent(null);
		return el;
	}
}

type CellSpan = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

class GridCellPrivate extends ElObjPrivate {
	span: number;

	constructor() {
		super();
		this.span = -1;
	}

	init(opts: Partial<GridCellOpts>): void {
		super.init(opts);
		if (opts.span) {
			this.q.setSpan(opts.span);
		}
	}

	get q(): GridCell {
		return <GridCell>super.q;
	}

	setSpan(span?: CellSpan): void {
		if (((span === undefined) && (this.span < 1)) || ((span !== undefined) && (this.span > 0) && (span === this.span)) || (isNumber(span) && ((span < 1) || (span > 12)))) {
			// Nothing to do.
			return;
		}
		if (span === undefined) {
			if (this.span > 0) {
				this.q.removeClass(
					`mdc-layout-grid__cell--span-${this.span}`,
				);
			}
			this.span = -1;
			return;
		}
		this.span = span;
		this.q.addClass(
			`mdc-layout-grid__cell--span-${this.span}`,
		);
	}
}

interface GridCellOpts extends ElObjOpts {
	dd: GridCellPrivate;
	span: CellSpan;
}

@OBJ
class GridCell extends ElObj {
	constructor(opts: Partial<GridCellOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-layout-grid__cell',
		);
		opts.dd = opts.dd || new GridCellPrivate();
		super(opts);
	}

	get d(): GridCellPrivate {
		return <GridCellPrivate>super.d;
	}

	setSpan(span?: CellSpan): void {
		this.d.setSpan(span);
	}
}
