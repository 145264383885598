import {FancyList, FancyListItem, FancyListItemOpts} from '../../ui/list';
import {AbstractObj, Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {EMPTY_FILTER_LABEL_TEXT} from '../../constants';
import {getLogger} from '../../logging';
import {list, Point} from '../../tools';
import {Evt, MouseEvt} from '../../evt';
import {EnabledToggle} from '../../ui/enabletoggle';
import {ParcelView} from '../parcel';
import {FilterMdl} from '../../models';

const logger = getLogger('projectdetailview.filterlist');

@OBJ
export class FilterList extends FancyList {
	addItem(item: Partial<FilterListItemOpts> | FilterListItem): void {
		super.addItem(item);
	}

	@SLOT
	addFilter(filter: FilterMdl): void {
		this.insertFilter(this.count(), filter);
	}

	children(): list<FilterListItem> {
		return <list<FilterListItem>>super.children();
	}

	eventFilter(watched: AbstractObj, event: Evt): boolean {
		switch (event.type()) {
			case Evt.MouseButtonRelease: {
				if ((watched instanceof FilterListItem) && watched.model) {
					this.filterClicked(
						watched.model,
						(<MouseEvt>event).pos(),
					);
					return true;
				}
			}
		}
		return super.eventFilter(watched, event);
	}

	@SIGNAL
	filterClicked(filter: FilterMdl, point: Point): void {
	}

	private indexForFilterId(filterId: number): number {
		for (let i = 0; i < this.children().size(); ++i) {
			const item = this.children().at(i);
			if (item.model && (item.model.id() === filterId)) {
				return i;
			}
		}
		return -1;
	}

	insertFilter(index: number, filter: FilterMdl): void {
		if (filter.parentId() || (filter.parentId() === 0)) {
			// Only top-level filters rendered in this list
			return;
		}
		const pk = filter.id();
		if (this.indexForFilterId(pk) >= 0) {
			logger.info('addFilter: Filter already added.');
			return;
		}
		this.insertItem(
			index,
			new FilterListItem({model: filter}),
		);
	}

	insertItem(index: number, item: Partial<FilterListItemOpts> | FilterListItem): void {
		super.insertItem(index, item);
	}

	@SLOT
	removeFilter(filter: FilterMdl | null): void {
		if (!filter) {
			return;
		}
		if (filter.parentId() || (filter.parentId() === 0)) {
			// Only top-level filters rendered in this list
			return;
		}
		const index = this.indexForFilterId(filter.id());
		if (index >= 0) {
			this.removeItem(index);
		} else {
			logger.error('removeFilter: Invalid index for object ID %s', filter.id());
		}
	}

	parentView(): ParcelView | null {
		let curr = this.parentEl();
		while (curr) {
			if (curr instanceof ParcelView) {
				return curr;
			}
			curr = curr.parentEl();
		}
		return null;
	}
}

interface FilterListItemOpts extends FancyListItemOpts {
	model: FilterMdl | null;
}

@OBJ
class FilterListItem extends FancyListItem {
	private enabledToggle: EnabledToggle;
	model: FilterMdl | null;

	constructor(opts: Partial<FilterListItemOpts> = {}) {
		super(opts);
		this.model = null;
		this.enabledToggle = new EnabledToggle();
		this.enabledToggle.show();
		this.setLeadingObj(this.enabledToggle);
		Obj.connect(
			this.enabledToggle, 'clicked',
			this, 'enabledToggleClicked',
		);
		if (opts.model) {
			this.setFilter(opts.model);
		} else {
			this.setText();
		}
	}

	destroy(): void {
		this.setFilter(null);
		this.enabledToggle.destroy();
		super.destroy();
	}

	@SLOT
	private enabledToggleClicked(enabled: boolean): void {
		const par = this.parentFilterList();
		if (par && this.model) {
			const view = par.parentView();
			if (view) {
				view.updateFilter(this.model, {enabled});
			}
		}
	}

	parentFilterList(): FilterList | null {
		const par = this.parentEl();
		return (par && (par instanceof FilterList)) ?
			par :
			null;
	}

	setFilter(filter: FilterMdl | null): void {
		if (filter === this.model) {
			return;
		}
		if (filter && this.model && (filter.id() === this.model.id())) {
			return;
		}
		const par = this.parentFilterList();
		if (this.model) {
			if (par) {
				Obj.disconnect(
					this.model, 'destroyed',
					par, 'removeFilter',
				);
			}
			Obj.disconnect(
				this.model, 'labelChanged',
				this, 'setText',
			);
			Obj.disconnect(
				this.model, 'enabledChanged',
				this.enabledToggle, 'setChecked',
			);
		}
		this.model = filter;
		if (this.model) {
			if (par) {
				Obj.connect(
					this.model, 'destroyed',
					par, 'removeFilter',
				);
			}
			Obj.connect(
				this.model, 'labelChanged',
				this, 'setText',
			);
			Obj.connect(
				this.model, 'enabledChanged',
				this.enabledToggle, 'setChecked',
			);
		}
		this.setText(
			this.model ?
				this.model.label() :
				'',
		);
		this.enabledToggle.setChecked(
			this.model ?
				this.model.isEnabled() :
				true,
		);
	}

	@SLOT
	setText(text?: string | null): void {
		const s = (typeof text === 'string') ?
			text.trim() :
			'';
		super.setText(
			(s.length > 0) ?
				s :
				EMPTY_FILTER_LABEL_TEXT,
		);
	}
}
