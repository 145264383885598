import {getResolver, ResolverMatch, URLPatterns} from './resolvers';

export interface RequestLike {
	pathname: string;
	urlPatterns?: URLPatterns;
}

export class RequestHandler {
	resolveRequest(request: RequestLike): ResolverMatch {
		const resolver = getResolver(request.urlPatterns);
		return resolver.resolve(request.pathname);
	}
}
