import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {EnabledToggle} from '../../ui/enabletoggle';
import {ToolButton} from '../../ui/toolbutton';
import {FilterMdl} from '../../models';
import {
	FancyList,
	FancyListItem,
	FancyListOpts,
	FancyListPrivate,
	FancyListItemOpts,
	FancyListItemPrivate,
} from '../../ui/list';

export class FilterViewPrivate extends FancyListPrivate {
	constructor() {
		super();
		this.itemProto = FilterViewItem;
	}

	init(opts: Partial<FilterViewOpts>): void {
		super.init(opts);
	}

	get q(): FilterView {
		return <FilterView>super.q;
	}
}

export interface FilterViewOpts extends FancyListOpts {
	dd: FilterViewPrivate;
}

@OBJ
export class FilterView extends FancyList {
	constructor(opts: Partial<FilterViewOpts> = {}) {
		opts.dd = opts.dd || new FilterViewPrivate();
		super(opts);
	}

	@SLOT
	addFilter(filter: FilterMdl): void {
		this.addItem(
			new FilterViewItem({
				filter,
			}),
		);
	}

	get d(): FilterViewPrivate {
		return <FilterViewPrivate>super.d;
	}

	@SIGNAL
	optionButtonTriggered(index: number): void {
	}
}

export class FilterViewItemPrivate extends FancyListItemPrivate {
	init(opts: Partial<FilterViewItemOpts>): void {
		if (opts.filter) {
			opts.text = opts.filter.display();
		}
		super.init(opts);
		const q = this.q;
		const toggl = new EnabledToggle();
		toggl.setCheckable(true);
		q.setLeadingObj(toggl);
		if (opts.filter) {
			toggl.setChecked(
				opts.filter.isEnabled(),
			);
			Obj.connect(
				opts.filter, 'destroyed',
				q, 'filterDestroyed',
			);
			Obj.connect(
				opts.filter, 'displayChanged',
				q, 'setText',
			);
			Obj.connect(
				opts.filter, 'enabledChanged',
				toggl, 'setChecked',
			);
			Obj.connect(
				toggl, 'toggled',
				opts.filter, 'setEnabled',
			);
		}
		const optBtn = new ToolButton();
		optBtn.icon().setName(
			'more_vert',
		);
		q.setTrailingObj(optBtn);
		Obj.connect(
			optBtn, 'clicked',
			q, 'optionButtonClicked',
		);
	}

	get q(): FilterViewItem {
		return <FilterViewItem>super.q;
	}
}

export interface FilterViewItemOpts extends FancyListItemOpts {
	dd: FilterViewItemPrivate;
	filter: FilterMdl;
}

@OBJ
export class FilterViewItem extends FancyListItem {
	constructor(opts: Partial<FilterViewItemOpts> = {}) {
		opts.dd = opts.dd || new FilterViewItemPrivate();
		super(opts);
	}

	get d(): FilterViewItemPrivate {
		return <FilterViewItemPrivate>super.d;
	}

	@SLOT
	private filterDestroyed(): void {
		const par = this.parentList();
		if (!par) {
			return;
		}
		const idx = par.index(this);
		if (idx >= 0) {
			par.removeItem(idx);
		}
	}

	@SLOT
	private optionButtonClicked(): void {
		const par = this.parentList();
		if (!par) {
			return;
		}
		const idx = par.index(this);
		if (idx >= 0) {
			par.optionButtonTriggered(idx);
		}
	}

	parentList(): FilterView | null {
		const par = super.parentList();
		return (par && (par instanceof FilterView)) ?
			par :
			null;
	}

	@SLOT
	setText(text?: string | null): void {
		super.setText(text);
	}
}
