import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {AbstractItemModel, AbstractItemModelPrivate, ModelIndex, PersistentModelIndex} from '../../abstractitemmodel';
import {testFlag} from '../../util';
import {list, Point, Rect, Size} from '../../tools';
import {ItemFlag, Key, KeyboardModifier, LayoutChangeHint, MouseButton, SelectionBehavior, SelectionFlag, SelectionMode} from '../../constants';
import {ItemSelection, ItemSelectionModel, ItemSelectionRange} from '../../itemselectionmodel';
import {getLogger} from '../../logging';
import {Evt, InputEvt, KeyEvt, MouseEvt} from '../../evt';

const logger = getLogger('ui.views.abstractitemview');

export enum State {
	NoState = 0, // The is the default state.
	DraggingState = 1, // The user is dragging items.
	DragSelectingState = 2, // The user is selecting items.
	EditingState = 3, // The user is editing an item in a widget editor.
	ExpandingState = 4, // The user is opening a branch of items.
	CollapsingState = 5, // The user is closing a branch of items.
	AnimatingState = 6, // The item view is performing an animation.
}

export class AbstractItemViewPrivate extends ElObjPrivate {
	static State: typeof State = State;

	ctrlDragSelectionFlag: SelectionFlag;
	currentIndex: ModelIndex;
	currentIndexSet: boolean;
	currentlyCommittingEditor: ElObj | null;
	currentSelectionStartIndex: PersistentModelIndex;
	enteredIndex: PersistentModelIndex;
	hover: PersistentModelIndex;
	model: AbstractItemModel;
	noSelectionOnMousePress: boolean;
	pressedAlreadySelected: boolean;
	pressedIndex: PersistentModelIndex;
	pressedModifiers: KeyboardModifier;
	pressedPosition: Point;
	releaseFromDoubleClick: boolean;
	root: PersistentModelIndex;
	selectionBehavior: SelectionBehavior;
	selectionMode: SelectionMode;
	selectionModel: ItemSelectionModel | null;
	state: State;

	constructor() {
		super();
		this.ctrlDragSelectionFlag = SelectionFlag.NoUpdate;
		this.currentIndex = new ModelIndex();
		this.currentIndexSet = false;
		this.currentlyCommittingEditor = null;
		this.currentSelectionStartIndex = new PersistentModelIndex();
		this.enteredIndex = new PersistentModelIndex();
		this.hover = new PersistentModelIndex();
		this.model = AbstractItemModelPrivate.staticEmptyModel();
		this.noSelectionOnMousePress = false;
		this.pressedAlreadySelected = false;
		this.pressedIndex = new PersistentModelIndex();
		this.pressedModifiers = KeyboardModifier.NoModifier;
		this.pressedPosition = new Point();
		this.releaseFromDoubleClick = false;
		this.root = new PersistentModelIndex();
		this.selectionBehavior = SelectionBehavior.SelectItems;
		this.selectionMode = SelectionMode.ExtendedSelection;
		this.selectionModel = null;
		this.state = State.NoState;
	}

	contiguousSelectionCommand(index: ModelIndex, event: Evt | null): SelectionFlag {
		const flags = this.extendedSelectionCommand(index, event);
		const mask = SelectionFlag.Clear | SelectionFlag.Select | SelectionFlag.Deselect | SelectionFlag.Toggle | SelectionFlag.Current;
		switch (flags & mask) {
			case SelectionFlag.Clear:
			case SelectionFlag.ClearAndSelect:
			case SelectionFlag.SelectCurrent: {
				return flags;
			}
			case SelectionFlag.NoUpdate: {
				if (event && ((event.type() === Evt.MouseButtonPress) || (event.type() === Evt.MouseButtonRelease))) {
					return flags;
				}
				return SelectionFlag.ClearAndSelect | this.selectionBehaviorFlags();
			}
			default: {
				return SelectionFlag.SelectCurrent | this.selectionBehaviorFlags();
			}
		}
	}

	extendedSelectionCommand(index: ModelIndex, event: Evt | null): SelectionFlag {
		const modifiers = (event && event.isInputEvent()) ?
			(<InputEvt>event).modifiers() :
			KeyboardModifier.NoModifier;
		if (event) {
			switch (event.type()) {
				case Evt.MouseMove: {
					if (modifiers & KeyboardModifier.ControlModifier) {
						return SelectionFlag.ToggleCurrent | this.selectionBehaviorFlags();
					}
					break;
				}
				case Evt.MouseButtonPress: {
					const button = (<MouseEvt>event).button();
					const rightButtonPressed = Boolean(button & MouseButton.RightButton);
					const shiftKeyPressed = Boolean(modifiers & KeyboardModifier.ShiftModifier);
					const controlKeyPressed = Boolean(modifiers & KeyboardModifier.ControlModifier);
					const indexIsSelected = this.selectionModel ?
						this.selectionModel.isSelected(index) :
						false;
					if ((shiftKeyPressed || controlKeyPressed) && rightButtonPressed) {
						return SelectionFlag.NoUpdate;
					}
					if (!shiftKeyPressed && !controlKeyPressed && indexIsSelected) {
						return SelectionFlag.NoUpdate;
					}
					if (!index.isValid() && !rightButtonPressed && !shiftKeyPressed && !controlKeyPressed) {
						return SelectionFlag.Clear;
					}
					if (!index.isValid()) {
						return SelectionFlag.NoUpdate;
					}
					if (controlKeyPressed && !rightButtonPressed && this.pressedAlreadySelected) {
						return SelectionFlag.NoUpdate;
					}
					break;
				}
				case Evt.MouseButtonRelease: {
					const button = (<MouseEvt>event).button();
					const rightButtonPressed = Boolean(button & MouseButton.RightButton);
					const shiftKeyPressed = Boolean(modifiers & KeyboardModifier.ShiftModifier);
					const controlKeyPressed = Boolean(modifiers & KeyboardModifier.ControlModifier);
					if (((index.eq(this.pressedIndex) && this.selectionModel && this.selectionModel.isSelected(index)) || !index.isValid()) && (this.state !== State.DragSelectingState) && !shiftKeyPressed && !controlKeyPressed && (!rightButtonPressed || !index.isValid())) {
						return SelectionFlag.ClearAndSelect | this.selectionBehaviorFlags();
					}
					if (index.eq(this.pressedIndex) && controlKeyPressed && !rightButtonPressed) {
						break;
					}
					return SelectionFlag.NoUpdate;
				}
				case Evt.KeyPress: {
					switch ((<KeyEvt>event).key()) {
						case Key.ArrowDown:
						case Key.ArrowUp:
						case Key.ArrowLeft:
						case Key.ArrowRight:
						case Key.Home:
						case Key.End:
						case Key.PageUp:
						case Key.PageDown:
						case Key.Tab: {
							if ((modifiers & KeyboardModifier.ControlModifier)) {
								return SelectionFlag.NoUpdate;
							}
							break;
						}
						case Key.Space: {
							if (modifiers & KeyboardModifier.ControlModifier) {
								return SelectionFlag.Toggle | this.selectionBehaviorFlags();
							}
							return SelectionFlag.Select | this.selectionBehaviorFlags();
						}
						default: {
							break;
						}
					}
				}
			}
		}
		if (modifiers & KeyboardModifier.ShiftModifier) {
			return SelectionFlag.SelectCurrent | this.selectionBehaviorFlags();
		}
		if (modifiers & KeyboardModifier.ControlModifier) {
			return SelectionFlag.Toggle | this.selectionBehaviorFlags();
		}
		if (this.state === State.DragSelectingState) {
			return SelectionFlag.Clear | SelectionFlag.SelectCurrent | this.selectionBehaviorFlags();
		}
		return SelectionFlag.ClearAndSelect | this.selectionBehaviorFlags();
	}

	init(opts: Partial<AbstractItemViewOpts>): void {
		super.init(opts);
		const q = this.q;
		q.addEventListener('mousedown');
		q.addEventListener('mouseup');
		q.addEventListener('keydown');
		q.addEventListener('keyup');
	}

	isIndexEnabled(index: ModelIndex): boolean {
		return testFlag(
			this.model.flags(index),
			ItemFlag.ItemIsEnabled,
		);
	}

	isIndexSelectable(index: ModelIndex): boolean {
		return Boolean(this.model.flags(index) & ItemFlag.ItemIsSelectable);
	}

	isIndexValid(index: ModelIndex): boolean {
		return (index.row() >= 0)
			&& (index.column() >= 0)
			&& (index.model() === this.model);
	}

	multiSelectionCommand(index: ModelIndex, event: Evt | null): SelectionFlag {
		if (!event) {
			return SelectionFlag.Toggle | this.selectionBehaviorFlags();
		}
		switch (event.type()) {
			case Evt.KeyPress: {
				const e = <KeyEvt>event;
				if (e.key() === Key.Space) {
					return SelectionFlag.Toggle | this.selectionBehaviorFlags();
				}
				break;
			}
			case Evt.MouseButtonPress: {
				if ((<MouseEvt>event).button() === MouseButton.LeftButton) {
					// Since the press might start a drag, deselect only on
					// release.
					if (!this.pressedAlreadySelected) {
						return SelectionFlag.Toggle | this.selectionBehaviorFlags();
					}
				}
				break;
			}
			case Evt.MouseButtonRelease: {
				if ((<MouseEvt>event).button() === MouseButton.LeftButton) {
					if (this.pressedAlreadySelected) {
						return SelectionFlag.Toggle | this.selectionBehaviorFlags();
					}
					return SelectionFlag.NoUpdate | this.selectionBehaviorFlags();
				}
				break;
			}
			case Evt.MouseMove: {
				if ((<MouseEvt>event).buttons() & MouseButton.LeftButton) {
					return SelectionFlag.ToggleCurrent | this.selectionBehaviorFlags();
				}
				break;
			}
			default: {
				break;
			}
		}
		return SelectionFlag.NoUpdate;
	}

	get q(): AbstractItemView {
		return <AbstractItemView>super.q;
	}

	selectAll(command: SelectionFlag): void {
		if (!this.selectionModel) {
			return;
		}
		const selection = new ItemSelection();
		const tl = this.model.index(0, 0, this.root);
		const br = this.model.index(this.model.rowCount(this.root) - 1, this.model.columnCount(this.root) - 1, this.root);
		selection.append(new ItemSelectionRange(tl, br));
		this.selectionModel.select(selection, command);
	}

	selectionAllowed(index: ModelIndex): boolean {
		return this.isIndexValid(index) && this.isIndexSelectable(index);
	}

	selectionBehaviorFlags(): SelectionFlag {
		switch (this.selectionBehavior) {
			case SelectionBehavior.SelectRows: {
				return SelectionFlag.Rows;
			}
			case SelectionBehavior.SelectColumns: {
				return SelectionFlag.Columns;
			}
			case SelectionBehavior.SelectItems: {
				return SelectionFlag.NoUpdate;
			}
		}
	}
}

export interface AbstractItemViewOpts extends ElObjOpts {
	dd: AbstractItemViewPrivate;
}

@OBJ
export abstract class AbstractItemView extends ElObj {
	constructor(opts: Partial<AbstractItemViewOpts> = {}) {
		opts.dd = opts.dd || new AbstractItemViewPrivate();
		super(opts);
	}

	protected activated(index: ModelIndex): void {
		/**
		 * This signal is emitted when the item specified by index is
		 * activated by the user. How to activate items depends on the
		 * platform; e.g., by single- or double-clicking the item, or by
		 * pressing the Return or Enter key when the item is current.
		 */
	}

	@SLOT
	clearSelection(): void {
		/**
		 * Deselects all selected items. The current index will not be
		 * changed.
		 */
		const d = this.d;
		if (d.selectionModel) {
			d.selectionModel.clearSelection();
		}
	}

	@SIGNAL
	protected clicked(index: ModelIndex): void {
		/**
		 * This signal is emitted when a mouse button is left-clicked. The
		 * item the mouse was clicked on is specified by index. The signal is
		 * only emitted when the index is valid.
		 */
	}

	@SLOT
	protected closeEditor(editor: ElObj): void {
		/**
		 * Closes the given editor, and releases it.
		 */
	}

	@SLOT
	protected columnsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	@SLOT
	protected _p_columnsAboutToBeRemoved(parent: ModelIndex, first: number, last: number): void {
		this.setState(State.CollapsingState);
	}

	@SLOT
	protected columnsInserted(parent: ModelIndex, first: number, last: number): void {
	}

	@SLOT
	protected columnsRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	@SLOT
	protected _p_columnsRemoved(parent: ModelIndex, first: number, last: number): void {
		this.setState(State.NoState);
	}

	@SLOT
	protected commitData(editor: ElObj): void {
		/**
		 * Commit the data in the editor to the model.
		 */
	}

	@SLOT
	protected currentChanged(current: ModelIndex, previous: ModelIndex): void {
		/**
		 * This slot is called when a new item becomes the current item. The
		 * previous current item is specified by the previous index, and the
		 * new item by the current index. If you want to know about changes to
		 * items see the dataChanged() signal.
		 */
	}

	currentIndex(): ModelIndex {
		/**
		 * Returns the model index of the current item.
		 */
		const d = this.d;
		return d.selectionModel ?
			d.selectionModel.currentIndex() :
			new ModelIndex();
	}

	get d(): AbstractItemViewPrivate {
		return <AbstractItemViewPrivate>super.d;
	}

	@SLOT
	protected dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles?: Array<number>): void {
		/**
		 * This slot is called when items with the given roles are changed in
		 * the model. The changed items are those from topLeft to bottomRight
		 * inclusive. If just one item is changed topLeft == bottomRight.
		 *
		 * The roles which have been changed can either be an empty container
		 * (meaning everything has changed), or a non-empty container with the
		 * subset of roles which have changed.
		 */
	}

	@SIGNAL
	protected doubleClicked(index: ModelIndex): void {
		/**
		 * This signal is emitted when a mouse button is double-clicked. The
		 * item the mouse was double-clicked on is specified by index. The
		 * signal is only emitted when the index is valid.
		 */
	}

	@SLOT
	edit(index: ModelIndex): void {
		/**
		 * Starts editing the item corresponding to the given index if it is
		 * editable.
		 */
	}

	@SLOT
	protected editorDestroyed(editor: ElObj): void {
		/**
		 * This function is called when the given editor has been destroyed.
		 */
	}

	indexAt(pos: Point): ModelIndex {
		/**
		 * Returns the model index of the item at the viewport coordinates
		 * point.
		 *
		 * In the base class this function returns an invalid index.
		 */
		return new ModelIndex();
	}

	indexEl(index: ModelIndex): ElObj | null {
		/**
		 * Returns the ElObj for the item at the given index.
		 */
		return null;
	}

	protected isIndexHidden(index: ModelIndex): boolean {
		/**
		 * Returns true if the item referred to by the given index is hidden
		 * in the view, otherwise returns false.
		 */
		return false;
	}

	@SLOT
	protected layoutChanged(parents: Array<PersistentModelIndex> = [], hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
	}

	model(): AbstractItemModel | null {
		/**
		 * Returns the model that this view is presenting.
		 */
		const d = this.d;
		return (d.model === AbstractItemModelPrivate.staticEmptyModel()) ?
			null :
			d.model;
	}

	@SLOT
	protected _p_modelDestroyed(): void {
		this.d.model = AbstractItemModelPrivate.staticEmptyModel();
	}

	protected mouseDoubleClickEvent(event: MouseEvt): void {
		const d = this.d;
		const index = this.indexAt(event.pos());
		if (!index.isValid() || !d.isIndexEnabled(index) || d.pressedIndex.ne(index)) {
			this.mousePressEvent(new MouseEvt(MouseEvt.MouseButtonPress, event.pos(), event.button(), event.buttons(), event.modifiers()));
			return;
		}
		const persistent = new PersistentModelIndex(index);
		this.doubleClicked(persistent.cast());
		d.releaseFromDoubleClick = true;
	}

	protected mouseMoveEvent(event: MouseEvt): void {
		if ((this.state() === State.ExpandingState) || (this.state() === State.CollapsingState)) {
			return;
		}
		let topLeft: Point;
		const bottomRight = event.pos();
		const index = this.indexAt(bottomRight);
		const d = this.d;
		if (d.selectionMode !== SelectionMode.SingleSelection) {
			topLeft = d.currentSelectionStartIndex.isValid() ?
				this.visualRect(d.currentSelectionStartIndex.cast()).center() :
				d.pressedPosition;
		} else {
			topLeft = bottomRight;
		}
		// d.checkMouseMove(index);
		if ((event.buttons() & MouseButton.LeftButton) && d.selectionAllowed(index) && d.selectionModel) {
			this.setState(State.DragSelectingState);
			let command = this.selectionCommand(index, event);
			if ((d.ctrlDragSelectionFlag !== SelectionFlag.NoUpdate) && testFlag(command, SelectionFlag.Toggle)) {
				command &= ~SelectionFlag.Toggle;
				command |= d.ctrlDragSelectionFlag;
			}
			const selectionRect = new Rect(topLeft, bottomRight);
			this.setSelection(selectionRect, command);
			if (index.isValid() && index.ne(d.selectionModel.currentIndex()) && d.isIndexEnabled(index)) {
				d.selectionModel.setCurrentIndex(index, SelectionFlag.NoUpdate);
			}
		}
	}

	protected mousePressEvent(event: MouseEvt): void {
		const d = this.d;
		if (!d.selectionModel) {
			return;
		}
		const pos = event.pos();
		const index = this.indexAt(pos);
		d.pressedAlreadySelected = d.selectionModel.isSelected(index);
		d.pressedIndex = index.cast();
		d.pressedModifiers = event.modifiers();
		let command = this.selectionCommand(index, event);
		d.noSelectionOnMousePress = (command === SelectionFlag.NoUpdate) || !index.isValid();
		d.pressedPosition = pos;
		if ((command & SelectionFlag.Current) === 0) {
			d.currentSelectionStartIndex = index.cast();
		} else if (!d.currentSelectionStartIndex.isValid()) {
			d.currentSelectionStartIndex = this.currentIndex().cast();
		}
		if (index.isValid() && d.isIndexEnabled(index)) {
			if (testFlag(command, SelectionFlag.Toggle)) {
				command &= ~SelectionFlag.Toggle;
				d.ctrlDragSelectionFlag = d.selectionModel.isSelected(index) ?
					SelectionFlag.Deselect :
					SelectionFlag.Select;
				command |= d.ctrlDragSelectionFlag;
			}
			if ((command & SelectionFlag.Current) === 0) {
				this.setSelection(new Rect(pos, new Size(1, 1)), command);
			} else {
				const rect = new Rect(this.visualRect(d.currentSelectionStartIndex.cast()).center(), pos);
				this.setSelection(rect, command);
			}
			this.pressed(index);
		} else {
			d.selectionModel.select(new ModelIndex(), SelectionFlag.Select);
		}
	}

	protected mouseReleaseEvent(event: MouseEvt): void {
		const d = this.d;
		const releaseFromDoubleClick = d.releaseFromDoubleClick;
		d.releaseFromDoubleClick = false;
		const pos = event.pos();
		const index = this.indexAt(pos);
		const click = (index.eq(d.pressedIndex) && index.isValid() && !releaseFromDoubleClick);
		const selectedClicked = click && (event.button() === MouseButton.LeftButton) && d.pressedAlreadySelected;
		d.ctrlDragSelectionFlag = SelectionFlag.NoUpdate;
		if (d.selectionModel && d.noSelectionOnMousePress) {
			d.noSelectionOnMousePress = false;
			d.selectionModel.select(index, this.selectionCommand(index, event));
		}
		super.mouseReleaseEvent(event);
		this.setState(State.NoState);
		if (click) {
			if (event.button() === MouseButton.LeftButton) {
				this.clicked(index);
			}
			if (d.model.flags(index) & ItemFlag.ItemIsEnabled) {
				this.activated(index);
			}
		}
	}

	@SIGNAL
	protected pressed(index: ModelIndex): void {
		/**
		 * This signal is emitted when a mouse button is pressed. The item the
		 * mouse was pressed on is specified by index. The signal is only
		 * emitted when the index is valid.
		 */
	}

	@SLOT
	reset(): void {
		/**
		 * Reset the internal state of the view.
		 *
		 * Warning: This function will reset open editors, selections, etc.
		 *          Existing changes will not be committed. If you would like
		 *          to save your changes when resetting the view, you can
		 *          reimplement this function, commit your changes, and then
		 *          call the superclass' implementation.
		 */
		const d = this.d;
		d.currentIndexSet = false;
		this.setState(State.NoState);
		this.setRootIndex(new ModelIndex());
		if (d.selectionModel) {
			d.selectionModel.reset();
		}
	}

	@SLOT
	protected rowsAboutToBeRemoved(parent: ModelIndex, start: number, end: number): void {
		/**
		 * This slot is called when rows are about to be removed. The deleted
		 * rows are those under the given parent from start to end inclusive.
		 */
	}

	@SLOT
	protected rowsInserted(parent: ModelIndex, start: number, end: number): void {
		/**
		 * This slot is called when rows are inserted. The new rows are those
		 * under the given parent from start to end inclusive.
		 */
	}

	@SLOT
	protected rowsRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	@SLOT
	protected _p_rowsRemoved(parent: ModelIndex, first: number, last: number): void {
		this.setState(State.NoState);
	}

	scrollTo(index: ModelIndex): void {
		/**
		 * Scrolls the view if necessary to ensure that the item at index is
		 * visible.
		 */
	}

	@SLOT
	selectAll(): void {
		/**
		 * Selects all items in the view.
		 */
		const d = this.d;
		const mode = d.selectionMode;
		if ((mode === SelectionMode.MultiSelection) || (mode === SelectionMode.ExtendedSelection)) {
			d.selectAll(
				SelectionFlag.ClearAndSelect | d.selectionBehaviorFlags(),
			);
		} else if (mode !== SelectionMode.SingleSelection) {
			d.selectAll(
				this.selectionCommand(
					d.model.index(0, 0, d.root),
				),
			);
		}
	}

	protected selectedIndexes(): list<ModelIndex> {
		/**
		 * This convenience function returns a list of all selected and
		 * non-hidden item indexes in the view. The list contains no
		 * duplicates, and is not sorted.
		 */
		return new list<ModelIndex>();
	}

	@SLOT
	protected selectionChanged(selected: ItemSelection, deselected: ItemSelection): void {
		/**
		 * This slot is called when the selection is changed. The previous
		 * selection (which may be empty), is specified by deselected, and the
		 * new selection by selected.
		 */
	}

	protected selectionCommand(index: ModelIndex, event: Evt | null = null): SelectionFlag {
		const d = this.d;
		const modifiers = event && event.isInputEvent() ?
			(<InputEvt>event).modifiers() :
			KeyboardModifier.NoModifier;
		switch (d.selectionMode) {
			case SelectionMode.NoSelection: {
				return SelectionFlag.NoUpdate;
			}
			case SelectionMode.SingleSelection: {
				if (event && (event.type() === Evt.MouseButtonRelease)) {
					return SelectionFlag.NoUpdate;
				}
				if ((modifiers & KeyboardModifier.ControlModifier) && d.selectionModel && d.selectionModel.isSelected(index) && event && (event.type() !== Evt.MouseMove)) {
					return SelectionFlag.Deselect | d.selectionBehaviorFlags();
				} else {
					return SelectionFlag.ClearAndSelect | d.selectionBehaviorFlags();
				}
			}
			case SelectionMode.MultiSelection: {
				return d.multiSelectionCommand(index, event);
			}
			case SelectionMode.ExtendedSelection: {
				return d.extendedSelectionCommand(index, event);
			}
			case SelectionMode.ContiguousSelection: {
				return d.contiguousSelectionCommand(index, event);
			}
		}
		return SelectionFlag.NoUpdate;
	}

	selectionModel(): ItemSelectionModel | null {
		return this.d.selectionModel;
	}

	@SLOT
	setCurrentIndex(index: ModelIndex): void {
		/**
		 * Sets the current item to be the item at index.
		 *
		 * Unless the current selection mode is NoSelection, the item is also
		 * selected. Note that this function also updates the starting
		 * position for any new selections the user performs.
		 */
		const d = this.d;
		if (d.selectionModel && (!index.isValid() || d.isIndexEnabled(index))) {
			const command = this.selectionCommand(index, null);
			d.selectionModel.setCurrentIndex(index, command);
			d.currentIndexSet = true;
			if ((command & SelectionFlag.Current) === 0) {
				d.currentSelectionStartIndex = index.cast();
			}
		}
		if (!index.isValid() || d.isIndexEnabled(index)) {
			d.currentIndex = index;
			d.currentSelectionStartIndex = new PersistentModelIndex(index);
		}
	}

	setIndexEl(index: ModelIndex, el: ElObj | null): void {
		/**
		 * Sets the given ElObj on the item at the given index, passing the
		 * ownership of the ElObj to the view.
		 */
	}

	setModel(model: AbstractItemModel | null): void {
		/**
		 * Sets the model for the view to present.
		 *
		 * The view does not take ownership of the model.
		 */
		const d = this.d;
		if (model === d.model) {
			return;
		}
		if (d.model && (d.model !== AbstractItemModelPrivate.staticEmptyModel())) {
			Obj.disconnect(
				d.model, 'destroyed',
				this, '_p_modelDestroyed',
			);
			Obj.disconnect(
				d.model, 'dataChanged',
				this, 'dataChanged',
			);
			Obj.disconnect(
				d.model, 'rowsInserted',
				this, 'rowsInserted',
			);
			Obj.disconnect(
				d.model, 'rowsAboutToBeRemoved',
				this, 'rowsAboutToBeRemoved',
			);
			Obj.disconnect(
				d.model, 'rowsRemoved',
				this, 'rowsRemoved',
			);
			Obj.disconnect(
				d.model, 'rowsRemoved',
				this, '_p_rowsRemoved',
			);
			Obj.disconnect(
				d.model, 'columnsAboutToBeRemoved',
				this, 'columnsAboutToBeRemoved',
			);
			Obj.disconnect(
				d.model, 'columnsAboutToBeRemoved',
				this, '_p_columnsAboutToBeRemoved',
			);
			Obj.disconnect(
				d.model, 'columnsRemoved',
				this, 'columnsRemoved',
			);
			Obj.disconnect(
				d.model, 'columnsRemoved',
				this, '_p_columnsRemoved',
			);
			Obj.disconnect(
				d.model, 'columnsInserted',
				this, 'columnsInserted',
			);
			Obj.disconnect(
				d.model, 'modelReset',
				this, 'reset',
			);
			Obj.disconnect(
				d.model, 'layoutChanged',
				this, 'layoutChanged',
			);
		}
		d.model = model ?
			model :
			AbstractItemModelPrivate.staticEmptyModel();
		if (d.model && (d.model !== AbstractItemModelPrivate.staticEmptyModel())) {
			Obj.connect(
				d.model, 'destroyed',
				this, '_p_modelDestroyed',
			);
			Obj.connect(
				d.model, 'dataChanged',
				this, 'dataChanged',
			);
			Obj.connect(
				d.model, 'rowsInserted',
				this, 'rowsInserted',
			);
			Obj.connect(
				d.model, 'rowsAboutToBeRemoved',
				this, 'rowsAboutToBeRemoved',
			);
			Obj.connect(
				d.model, 'rowsRemoved',
				this, 'rowsRemoved',
			);
			Obj.connect(
				d.model, 'rowsRemoved',
				this, '_p_rowsRemoved',
			);
			Obj.connect(
				d.model, 'columnsAboutToBeRemoved',
				this, 'columnsAboutToBeRemoved',
			);
			Obj.connect(
				d.model, 'columnsAboutToBeRemoved',
				this, '_p_columnsAboutToBeRemoved',
			);
			Obj.connect(
				d.model, 'columnsRemoved',
				this, 'columnsRemoved',
			);
			Obj.connect(
				d.model, 'columnsRemoved',
				this, '_p_columnsRemoved',
			);
			Obj.connect(
				d.model, 'columnsInserted',
				this, 'columnsInserted',
			);
			Obj.connect(
				d.model, 'modelReset',
				this, 'reset',
			);
			Obj.connect(
				d.model, 'layoutChanged',
				this, 'layoutChanged',
			);
		}
		const selectionModel = new ItemSelectionModel(this.model(), this);
		Obj.connect(
			d.model, 'destroyed',
			selectionModel, 'deleteLater',
		);
		this.setSelectionModel(selectionModel);
		this.reset();
	}

	protected setRootIndex(index: ModelIndex): void {
		const d = this.d;
		if (index.isValid() && (index.model() && !index.model()!.eq(d.model))) {
			logger.warning('setRootIndex: index must be from the currently set model.');
			return;
		}
		d.root = index.cast();
	}

	setSelection(rect: Rect, flags: SelectionFlag): void {
		/**
		 * Applies the selection flags to the items in or touched by the rect.
		 *
		 * When implementing your own ItemView, setSelection should call
		 * selectionModel().select(selection, flags) where selection is either
		 * an empty ModelIndex or a ItemSelection that contains all items that
		 * are contained in rect.
		 */
	}

	setSelectionModel(selectionModel: ItemSelectionModel): void {
		/**
		 * Sets the current selection model to the given \a selectionModel.
		 *
		 * NB: If you call setModel() after this function, the given
		 *     selectionModel will be replaced by one created by the view.
		 */
		const d = this.d;
		if ((selectionModel.model() !== AbstractItemModelPrivate.staticEmptyModel()) && !selectionModel.model().eq(d.model)) {
			logger.warning('setSelectionModel: Trying to set a selection model, which works on a different model than the view.');
			return;
		}
		let oldSelection = new ItemSelection();
		let oldCurrentIndex = new ModelIndex();
		if (d.selectionModel) {
			if (d.selectionModel.model().eq(selectionModel.model())) {
				oldSelection = d.selectionModel.selection();
				oldCurrentIndex = d.selectionModel.currentIndex();
			}
			Obj.disconnect(
				d.selectionModel, 'selectionChanged',
				this, 'selectionChanged',
			);
			Obj.disconnect(
				d.selectionModel, 'currentChanged',
				this, 'currentChanged',
			);
		}
		d.selectionModel = selectionModel;
		if (d.selectionModel) {
			Obj.connect(
				d.selectionModel, 'selectionChanged',
				this, 'selectionChanged',
			);
			Obj.connect(
				d.selectionModel, 'currentChanged',
				this, 'currentChanged',
			);
			this.selectionChanged(
				d.selectionModel.selection(),
				oldSelection,
			);
			this.currentChanged(
				d.selectionModel.currentIndex(),
				oldCurrentIndex,
			);
		}
	}

	protected setState(state: State): void {
		/**
		 * Sets the item view's state to the given state.
		 */
		const d = this.d;
		if (state === d.state) {
			return;
		}
		d.state = state;
	}

	protected state(): State {
		/**
		 * Returns the item view's state.
		 */
		return this.d.state;
	}

	visualRect(index: ModelIndex): Rect {
		/**
		 * Returns the rectangle on the viewport occupied by the item a index.
		 *
		 * If your item is displayed in several areas then visualRect should
		 * return the primary area that contains index and not the complete
		 * area that index might encompasses, touch or cause drawing.
		 *
		 * In the base class this function returns an invalid Rect.
		 */
		return new Rect();
	}
}
