import {RegexPattern, URLPattern} from './resolvers';

type View = (...args: any[]) => any;

export function path(route: RegExp | string, view: View, name?: string): URLPattern {
	const regex = (route instanceof RegExp) ? route : (new RegExp(route));
	return new URLPattern(
		new RegexPattern(regex, name),
		view,
		name);
}
