import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {TextInput} from '../../ui/textinput';
import {GridLayout} from '../../ui/gridlayout';
import {FancyList, FancyListItem, FancyListItemOpts, FancyListItemPrivate, FancyListOpts, FancyListPrivate} from '../../ui/list';
import {FancyPushButton} from '../../ui/pushbutton';
import {pixelString} from '../../util';
import {Checkbox} from '../../ui/checkbox';
import {ToolButton} from '../../ui/toolbutton';
import {Icon} from '../../ui/icon';
import {EnabledToggle} from '../../ui/enabletoggle';
import {Tag} from '../../models';
import {MouseEvt} from '../../evt';

export class TagViewPrivate extends ElObjPrivate {
	createView: CreateTagView | null;
	listView: ListView | null;
	searchInput: TextInput | null;

	constructor() {
		super();
		this.createView = null;
		this.listView = null;
		this.searchInput = null;
	}

	init(opts: Partial<TagViewOpts>): void {
		super.init(opts);
		const q = this.q;
		const grid = new GridLayout({
			parent: q,
		});
		this.listView = new ListView();
		grid.addEl(this.listView, 12);
		this.createView = new CreateTagView();
		grid.addEl(this.createView, 12);
	}

	get q(): TagView {
		return <TagView>super.q;
	}
}

export interface TagViewOpts extends ElObjOpts {
	dd: TagViewPrivate;
}

@OBJ
export class TagView extends ElObj {
	constructor(opts: Partial<TagViewOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-tag-view',
		);
		opts.dd = opts.dd || new TagViewPrivate();
		super(opts);
	}

	@SLOT
	addTag(tag: Tag): void {
		const d = this.d;
		if (!d.listView) {
			return;
		}
		d.listView.addItem({
			text: tag.toString(),
		});
		const blocked = d.listView.blockSignals(
			true,
		);
		d.listView.toggleEnabled(
			d.listView.count() - 1,
			tag.isEnabled(),
		);
		d.listView.blockSignals(
			blocked,
		);
	}

	createView(): CreateTagView | null {
		return this.d.createView;
	}

	get d(): TagViewPrivate {
		return <TagViewPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.createView = null;
		d.searchInput = null;
		d.listView = null;
		super.destroy();
	}

	listView(): ListView | null {
		return this.d.listView;
	}

	@SLOT
	removeTag(index: number): void {
		const d = this.d;
		if (d.listView) {
			d.listView.removeItem(index);
		}
	}

	@SLOT
	resetCreateInput(): void {
		const d = this.d;
		if (d.createView) {
			d.createView.setTag('');
		}
	}
}

@OBJ
class CreateTagButton extends FancyPushButton {
	protected mousePressEvent(event: MouseEvt): void {
		super.mousePressEvent(event);
		event.ignore();
	}

	protected mouseReleaseEvent(event: MouseEvt): void {
		super.mouseReleaseEvent(event);
		event.ignore();
	}
}

class CreateTagViewPrivate extends ElObjPrivate {
	btn: CreateTagButton | null;
	check: Checkbox | null;
	input: TextInput | null;

	constructor() {
		super();
		this.btn = null;
		this.check = null;
		this.input = null;
	}

	init(opts: Partial<CreateTagViewOpts>): void {
		super.init(opts);
		const q = this.q;
		this.input = new TextInput({
			parent: q,
			labelText: 'New tag',
		});
		Obj.connect(
			this.input, 'returnPressed',
			q, 'submitted',
		);
		const checkLabel = new ElObj({
			classNames: [
				'display--flex',
				'align-items--center',
			],
			parent: q,
			tagName: 'label',
		});
		checkLabel.setText('Enabled');
		this.check = new Checkbox({
			parent: checkLabel,
		});
		this.check.setChecked(true);
		this.btn = new CreateTagButton({
			attributes: [
				['type', 'submit'],
			],
			parent: q,
			text: 'Create',
		});
		this.btn.setDefault(true);
		Obj.connect(
			this.btn, 'clicked',
			q, 'submitted',
		);
		q.addEventListener('submit');
	}

	get q(): CreateTagView {
		return <CreateTagView>super.q;
	}
}

interface CreateTagViewOpts extends ElObjOpts {
	dd: CreateTagViewPrivate;
}

@OBJ
class CreateTagView extends ElObj {
	static tagName: TagName = 'form';

	constructor(opts: Partial<CreateTagViewOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-form-container',
		);
		opts.dd = opts.dd || new CreateTagViewPrivate();
		super(opts);
	}

	get d(): CreateTagViewPrivate {
		return <CreateTagViewPrivate>super.d;
	}

	isEnabledChecked(): boolean {
		const d = this.d;
		return d.check ?
			d.check.isChecked() :
			false;
	}

	reset(): void {
		const d = this.d;
		if (d.input) {
			d.input.setText('');
		}
		if (d.check) {
			d.check.setChecked(true);
		}
	}

	setEnabledChecked(checked: boolean): void {
		const d = this.d;
		if (d.check) {
			d.check.setChecked(checked);
		}
	}

	setTag(tag: string): void {
		const d = this.d;
		if (d.input) {
			d.input.setText(tag);
		}
	}

	@SIGNAL
	protected submitted(): void {
	}

	tag(): string {
		const d = this.d;
		return d.input ?
			d.input.text().trim() :
			'';
	}
}

class ListViewPrivate extends FancyListPrivate {
	get q(): ListView {
		return <ListView>super.q;
	}
}

interface ListViewOpts extends FancyListOpts {
	dd: ListViewPrivate;
}

@OBJ
class ListView extends FancyList {
	constructor(opts: Partial<ListViewOpts> = {}) {
		opts.dd = opts.dd || new ListViewPrivate();
		opts.itemPrototype = ListViewItem;
		opts.styles = ElObj.mergeStyles(
			opts.styles,
			['max-height', pixelString(336)],
			['overflow', 'auto'],
		);
		super(opts);
	}

	get d(): ListViewPrivate {
		return <ListViewPrivate>super.d;
	}

	@SIGNAL
	deleteClicked(index: number): void {
	}

	@SIGNAL
	enabledToggled(index: number, enabled: boolean): void {
	}

	toggleEnabled(index: number, enabled: boolean): void {
		const item = <ListViewItem | null>this.item(index);
		if (item) {
			item.toggleEnabled(enabled);
		}
	}
}

class ListViewItemPrivate extends FancyListItemPrivate {
	delBtn: ToolButton | null;
	enabledBtn: EnabledToggle | null;

	constructor() {
		super();
		this.delBtn = null;
		this.enabledBtn = null;
	}

	init(opts: Partial<ListViewItemOpts>): void {
		super.init(opts);
		const q = this.q;
		this.enabledBtn = new EnabledToggle();
		Obj.connect(
			this.enabledBtn, 'toggled',
			q, '_enabledBtnToggled',
		);
		q.setLeadingObj(this.enabledBtn);
		this.delBtn = new ToolButton({
			icon: new Icon({
				name: 'delete',
				outlined: true,
			}),
		});
		Obj.connect(
			this.delBtn, 'clicked',
			q, '_deleteBtnClicked',
		);
		q.setTrailingObj(this.delBtn);
	}

	get q(): ListViewItem {
		return <ListViewItem>super.q;
	}
}

interface ListViewItemOpts extends FancyListItemOpts {
	dd: ListViewItemPrivate;
}

@OBJ
class ListViewItem extends FancyListItem {
	constructor(opts: Partial<ListViewItemOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-tag-list-item',
		);
		opts.dd = opts.dd || new ListViewItemPrivate();
		super(opts);
	}

	get d(): ListViewItemPrivate {
		return <ListViewItemPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.delBtn = null;
		d.enabledBtn = null;
		super.destroy();
	}

	@SLOT
	private _deleteBtnClicked(): void {
		const par = this.parentList();
		if (!par) {
			return;
		}
		const idx = par.index(this);
		if (idx >= 0) {
			par.deleteClicked(idx);
		}
	}

	@SLOT
	private _enabledBtnToggled(checked: boolean): void {
		const par = this.parentList();
		if (!par) {
			return;
		}
		const idx = par.index(this);
		if (idx >= 0) {
			par.enabledToggled(idx, checked);
		}
	}

	parentList(): ListView | null {
		const par = this.parentEl();
		return (par && (par instanceof ListView)) ?
			par :
			null;
	}

	toggleEnabled(enabled: boolean): void {
		const d = this.d;
		if (d.enabledBtn) {
			d.enabledBtn.setChecked(enabled);
		}
	}
}
