export function init(): void {
	const root = document.getElementById('id_lb-account-do-not-mail');
	if (root) {
		import('./view')
			.then(mod => {
				const obj = new mod.DoNotMailView({root});
				obj.show();
			});
	}
}
