import {ItemData, TreeView, TreeViewOpts, TreeViewPrivate} from '../itemviews';
import {Obj, OBJ, PROP, SIGNAL, SLOT} from '../../obj';
import {list, stack} from '../../tools';
import {Variant} from '../../variant';
import {assert, clamp, isNumber} from '../../util';
import {CheckIndexOption, CheckState, ItemDataRole, ItemFlag, MatchFlag, Orientation} from '../../constants';
import {AbstractItemModel, AbstractItemModelOpts, AbstractItemModelPrivate, ModelIndex, PersistentModelIndex} from '../../abstractitemmodel';
import {ElObj} from '../../elobj';
import {List, ListItem} from './el';
import {getLogger} from '../../logging';

const logger = getLogger('ui.tree');

export class TreeItemPrivate {
	disabled: boolean;
	display: list<Variant>;
	hidden: boolean;
	q: TreeItem;
	rowGuess: number;
	selected: boolean;

	constructor(item: TreeItem) {
		this.disabled = false;
		this.display = new list();
		this.hidden = false;
		this.q = item;
		this.rowGuess = -1;
		this.selected = false;
	}

	destroy(): void {
		this.display.clear();
	}

	propagateDisabled(item: TreeItem | null): void {
		if (!item) {
			return;
		}
		const enable = item.par ?
			Boolean(item.par.itemFlags & ItemFlag.ItemIsEnabled) :
			true;
		const parents = new stack<TreeItem>();
		parents.push(item);
		while (!parents.isEmpty()) {
			const parent = parents.pop();
			if (!parent.d.disabled) {
				// Not explicitly disabled
				const oldFlags = parent.itemFlags;
				if (enable) {
					parent.itemFlags = parent.itemFlags | ItemFlag.ItemIsEnabled;
				} else {
					parent.itemFlags = parent.itemFlags & ~ItemFlag.ItemIsEnabled;
				}
				if (parent.itemFlags !== oldFlags) {
					parent.itemChanged();
				}
			}
			for (let i = 0; i < parent.children.size(); ++i) {
				parents.push(parent.children.at(i));
			}
		}
	}
}

export class TreeItem {
	children: list<TreeItem>;
	d: TreeItemPrivate;
	itemFlags: ItemFlag;
	par: TreeItem | null;
	t: number;
	// An item has a list of column entries. Each column has a list of
	// (role, value) pairs.
	values: list<list<ItemData>>;
	view: Tree | null;

	constructor(other: TreeItem | null, tag: 'other');
	constructor(parent: TreeItem, type?: number);
	constructor(parent: TreeView, type?: number);
	constructor(parent: TreeItem, preceding: TreeItem, type?: number);
	constructor(parent: TreeView, preceding: TreeItem, type?: number);
	constructor(type?: number);
	constructor(a?: TreeItem | TreeView | number | null, b?: TreeItem | number | 'other', c?: number) {
		this.d = new TreeItemPrivate(this);
		let doTheModelThing = false;
		let flags: ItemFlag | undefined = undefined;
		let type: number = 0;
		let parent: TreeItem | null = null;
		let view: TreeView | null = null;
		let preceding: TreeItem | null = null;
		let values: list<list<ItemData>> | undefined = undefined;
		if ((a instanceof TreeItem) && (b === 'other') && (c === undefined)) {
			// SIG: constructor(other: TreeItem | null, tag: 'other');
			flags = a.itemFlags;
			parent = null;
			type = 0;
			values = new list(a.values);
			view = null;
			this.d.display = new list(a.d.display);
		} else if (isNumber(a) && (b === undefined) && (c === undefined)) {
			// SIG: constructor(type?: number);
			type = a;
		} else if ((a instanceof TreeView) && (isNumber(b) || (b === undefined)) && (c === undefined)) {
			// SIG: constructor(parent: TreeView, type?: number);
			view = a;
			type = isNumber(b) ? b : 0;
			doTheModelThing = true;
		} else if ((a instanceof TreeView) && (b instanceof TreeItem) && (isNumber(c) || (c === undefined))) {
			// SIG: constructor(parent: TreeView, preceding: TreeItem, type?: number);
			view = a;
			preceding = b;
			type = isNumber(c) ? c : 0;
			doTheModelThing = true;
		} else if ((a instanceof TreeItem) && (isNumber(b) || (b === undefined)) && (c === undefined)) {
			// SIG: constructor(parent: TreeItem, type?: number);
			parent = a;
			type = isNumber(b) ? b : 0;
		} else if ((a instanceof TreeItem) && (b instanceof TreeItem) && (isNumber(c) || (c === undefined))) {
			// SIG: constructor(parent: TreeItem, preceding: TreeItem, type?: number);
			parent = a;
			preceding = b;
			type = isNumber(c) ? c : 0;
		}
		if (flags === undefined) {
			flags = ItemFlag.ItemIsSelectable
				| ItemFlag.ItemIsUserCheckable
				| ItemFlag.ItemIsEnabled
				| ItemFlag.ItemIsDragEnabled
				| ItemFlag.ItemIsAutoTristate
				| ItemFlag.ItemIsDropEnabled;
		}
		if (values === undefined) {
			values = new list();
		}
		this.children = new list();
		this.itemFlags = flags;
		this.par = null;
		this.t = type;
		this.values = values;
		this.view = null;
		if (doTheModelThing) {
			// Do not set this.view here otherwise insertChild() will fail.
			const model = this.treeModel(view);
			if (model) {
				if (preceding) {
					const i = model.rootItem.children.indexOf(preceding) + 1;
					model.rootItem.insertChild(i, this);
				} else {
					model.rootItem.addChild(this);
				}
			}
		} else {
			if (parent) {
				if (preceding) {
					const i = parent.children.indexOf(preceding) + 1;
					parent.insertChild(i, this);
				} else {
					parent.addChild(this);
				}
			}
		}
	}

	addChild(child: TreeItem | null): void {
		if (child) {
			this.insertChild(this.children.size(), child);
			child.d.rowGuess = this.children.size() - 1;
		}
	}

	addChildren(children: list<TreeItem>): void {
		this.insertChildren(this.children.size(), children);
	}

	assign(other: TreeItem): TreeItem {
		this.values = new list(other.values);
		this.d.display = new list(other.d.display);
		this.itemFlags = other.itemFlags;
		return this;
	}

	checkState(column: number): CheckState {
		return this.data(
			column,
			ItemDataRole.CheckStateRole,
		).toNumber();
	}

	child(index: number): TreeItem | null {
		if ((index >= 0) && (index < this.children.size())) {
			return this.children.at(index);
		}
		return null;
	}

	childCount(): number {
		return this.children.size();
	}

	private childrenCheckState(column: number): Variant {
		if (column < 0) {
			return new Variant();
		}
		let checkedChildren = false;
		let uncheckedChildren = false;
		for (const child of this.children) {
			const value = child.data(
				column,
				ItemDataRole.CheckStateRole,
			);
			if (value.isValid()) {
				switch (value.toNumber()) {
					case CheckState.Unchecked: {
						uncheckedChildren = true;
						break;
					}
					case CheckState.Checked: {
						checkedChildren = true;
						break;
					}
					case CheckState.PartiallyChecked:
					default: {
						return new Variant(CheckState.PartiallyChecked);
					}
				}
			} else {
				uncheckedChildren = true;
			}
			if (uncheckedChildren && checkedChildren) {
				return new Variant(CheckState.PartiallyChecked);
			}
		}
		if (uncheckedChildren) {
			return new Variant(CheckState.Unchecked);
		}
		if (checkedChildren) {
			return new Variant(CheckState.Checked);
		}
		// Value was not defined
		return new Variant();
	}

	clone(): TreeItem {
		const stk = new stack<TreeItem | null>();
		const parentStk = new stack<TreeItem | null>();
		stk.push(this);
		parentStk.push(null);
		let copy: TreeItem | null = null;
		let root: TreeItem | null = null;
		let item: TreeItem | null = null;
		let parent: TreeItem | null = null;
		while (!stk.isEmpty()) {
			item = stk.pop();
			parent = parentStk.pop();
			copy = new TreeItem(item, 'other');
			if (!root) {
				root = copy;
			}
			if (parent) {
				copy.par = parent;
				parent.children.insert(0, copy);
			}
			for (let i = 0; i < (item ? item.childCount() : 0); ++i) {
				stk.push(item ? item.child(i) : null);
				parentStk.push(copy);
			}
		}
		assert(root);
		return root;
	}

	columnCount(): number {
		return this.values.size();
	}

	data(column: number, role: ItemDataRole): Variant {
		switch (role) {
			case ItemDataRole.DisplayRole: {
				if ((column >= 0) && (column < this.d.display.size())) {
					return this.d.display.at(column);
				}
				break;
			}
			default: {
				if (role === ItemDataRole.CheckStateRole) {
					// Special case for check state in tristate
					if ((this.children.size() > 0) && Boolean(this.itemFlags & ItemFlag.ItemIsAutoTristate)) {
						return this.childrenCheckState(column);
					}
				}
				if ((column >= 0) && (column < this.values.size())) {
					for (const columnValue of this.values.at(column)) {
						if (columnValue.role === role) {
							return columnValue.value;
						}
					}
				}
				break;
			}
		}
		return new Variant();
	}

	destroy(): void {
		const model = this.treeModel();
		if (this.par) {
			const i = this.par.children.indexOf(this);
			if (i >= 0) {
				if (model) {
					model.beginRemoveItems(this.par, i, 1);
				}
				if (!this.par.children.isEmpty() && (this.par.children.at(i) === this)) {
					this.par.children.takeAt(i);
				}
				if (model) {
					model.endRemoveItems();
				}
			}
		} else if (model) {
			if (this === model.headerItem) {
				model.headerItem = null;
			} else {
				const i = model.rootItem.children.indexOf(this);
				if (i >= 0) {
					model.beginRemoveItems(null, i, 1);
					if (!model.rootItem.children.isEmpty() && model.rootItem.children.at(i) === this) {
						model.rootItem.children.takeAt(i);
					}
					model.endRemoveItems();
				}
			}
		}
		for (let i = 0; i < this.children.size(); ++i) {
			const child = this.children.at(i);
			// Make sure the child does not try to remove itself from our
			// children list.
			child.par = null;
			// Make sure the child does not try to remove itself from the top
			// level list.
			child.view = null;
			child.destroy();
		}
		this.children.clear();
		this.d.destroy();
	}

	protected emitDataChanged(): void {
		this.itemChanged();
	}

	flags(): ItemFlag {
		return this.itemFlags;
	}

	indexOfChild(child: TreeItem): number {
		return this.children.indexOf(child);
	}

	insertChild(index: number, child: TreeItem | null): void {
		if ((index < 0) || (index > this.children.size()) || !child || child.view || child.par) {
			return;
		}
		const model = this.treeModel();
		if (model) {
			if (model.rootItem === this) {
				child.par = null;
			} else {
				child.par = this;
			}
			model.beginInsertItems(this, index, 1);
			const stk = new stack<TreeItem>();
			stk.push(child);
			while (!stk.isEmpty()) {
				const i = stk.pop();
				i.view = this.view;
				for (let c = 0; c < i.children.size(); ++c) {
					stk.push(i.children.at(c));
				}
			}
			this.children.insert(index, child);
			model.endInsertItems();
		} else {
			child.par = this;
			this.children.insert(index, child);
		}
		if (child.par) {
			this.d.propagateDisabled(child);
		}
	}

	insertChildren(index: number, children: list<TreeItem>): void {
		if ((index < 0) || (index > this.children.size()) || children.isEmpty()) {
			return;
		}
		const model = this.treeModel();
		const stk = new stack<TreeItem>();
		const itemsToInsert = new list<TreeItem>();
		for (let n = 0; n < children.size(); ++n) {
			const child = children.at(n);
			if (child.view || child.par) {
				continue;
			}
			itemsToInsert.append(child);
			if (this.view && model) {
				if (child.childCount() === 0) {
					child.view = this.view;
				} else {
					stk.push(child);
				}
			}
			if (model && (model.rootItem === this)) {
				child.par = null;
			} else {
				child.par = this;
			}
		}
		if (!itemsToInsert.isEmpty()) {
			while (!stk.isEmpty()) {
				const i = stk.pop();
				i.view = this.view;
				for (let c = 0; c < i.children.size(); ++c) {
					stk.push(i.children.at(c));
				}
			}
			if (model) {
				model.beginInsertItems(this, index, itemsToInsert.size());
			}
			for (let n = 0; n < itemsToInsert.size(); ++n) {
				const child = itemsToInsert.at(n);
				this.children.insert(index + n, child);
				if (child.par) {
					this.d.propagateDisabled(child);
				}
			}
			if (model) {
				model.endInsertItems();
			}
		}
	}

	isDisabled(): boolean {
		return !Boolean(this.itemFlags & ItemFlag.ItemIsEnabled);
	}

	isSelected(): boolean {
		return this.d.selected;
	}

	itemChanged(): void {
		const model = this.treeModel();
		if (model) {
			model.itemChanged(this);
		}
	}

	lt(other: TreeItem): boolean {
		return AbstractItemModelPrivate.variantLessThan(
			this.data(0, ItemDataRole.DisplayRole),
			other.data(0, ItemDataRole.DisplayRole),
		);
	}

	parent(): TreeItem | null {
		return this.par;
	}

	removeChild(child: TreeItem): void {
		this.takeChild(this.children.indexOf(child));
	}

	setCheckState(column: number, state: CheckState): void {
		this.setData(
			column,
			ItemDataRole.CheckStateRole,
			new Variant(state),
		);
	}

	setData(column: number, role: ItemDataRole, value: Variant): void {
		if (column < 0) {
			return;
		}
		const model = this.treeModel();
		switch (role) {
			case ItemDataRole.DisplayRole: {
				if (this.values.size() <= column) {
					if (model && (this === model.headerItem)) {
						model.setColumnCount(column + 1);
					}
				} else {
					for (let i = 0; i < ((column + 1) - this.values.size()); ++i) {
						this.values.append(new list<ItemData>());
					}
				}
				if (this.d.display.size() <= column) {
					for (let i = (this.d.display.size() - 1); i < (column - 1); ++i) {
						this.d.display.append(new Variant());
					}
					this.d.display.append(value);
				} else if (this.d.display.at(column).ne(value)) {
					this.d.display.replace(column, value);
				} else {
					// Value is unchanged
					return;
				}
				break;
			}
			default: {
				if (role === ItemDataRole.CheckStateRole) {
					if ((this.itemFlags & ItemFlag.ItemIsAutoTristate) && (value.toNumber() !== CheckState.PartiallyChecked)) {
						for (let i = 0; i < this.children.size(); ++i) {
							const child = this.children.at(i);
							const f = this.itemFlags; // Little hack to avoid multiple dataChanged signals
							this.itemFlags &= ~ItemFlag.ItemIsAutoTristate;
							child.setData(column, role, value);
							this.itemFlags = f;
						}
					}
				}
				if (column < this.values.size()) {
					let found = false;
					const columnValues = this.values.at(column);
					for (let i = 0; i < columnValues.size(); ++i) {
						if (columnValues.at(i).role === role) {
							if (columnValues.at(i).value.eq(value)) {
								// Value is unchanged
								return;
							}
							this.values.at(column).at(i).value = value;
							found = true;
							break;
						}
					}
					if (!found) {
						this.values.at(column).append(new ItemData(role, value));
					}
				} else {
					if (model && (this === model.headerItem)) {
						model.setColumnCount(column + 1);
					} else {
						for (let i = 0; i < ((column + 1) - this.values.size()); ++i) {
							this.values.append(new list<ItemData>());
						}
					}
					this.values.at(column).append(new ItemData(role, value));
				}
				break;
			}
		}
		if (model) {
			const roles = ((role === ItemDataRole.DisplayRole) || (role === ItemDataRole.EditRole)) ?
				[
					ItemDataRole.DisplayRole,
					ItemDataRole.EditRole,
				] :
				[role];
			model.emitDataChanged(this, column, roles);
			if (role === ItemDataRole.CheckStateRole) {
				for (let p = this.par; p && (p.itemFlags & ItemFlag.ItemIsAutoTristate); p = p.par) {
					model.emitDataChanged(p, column, roles);
				}
			}
		}
	}

	setDisabled(disabled: boolean): void {
		const f = disabled ?
			(this.itemFlags & ~ItemFlag.ItemIsEnabled) :
			(this.itemFlags | ItemFlag.ItemIsEnabled);
		this.setFlags(f);
	}

	setExpanded(expanded: boolean): void {
		if (this.view) {
			this.view.setExpanded(this, expanded);
		}
	}

	setFlags(flags: ItemFlag): void {
		const enable = Boolean(flags & ItemFlag.ItemIsEnabled);
		const changedState = Boolean(this.itemFlags & ItemFlag.ItemIsEnabled) !== enable;
		const changedExplicit = this.d.disabled !== !enable;
		this.d.disabled = !enable;
		if (enable && this.par && !Boolean(this.par.itemFlags & ItemFlag.ItemIsEnabled)) {
			// Inherit from parent
			this.itemFlags = flags & ~ItemFlag.ItemIsEnabled;
		} else {
			// Item was explicitly disabled or has no parent
			this.itemFlags = flags;
		}
		if (changedState && changedExplicit) {
			// Propagate the change to the children
			const parents = new stack<TreeItem>();
			parents.push(this);
			while (!parents.isEmpty()) {
				const parent = parents.pop();
				for (let i = 0; i < parent.children.size(); ++i) {
					const child = parent.children.at(i);
					if (!child.d.disabled) {
						// Not explicitly disabled
						parents.push(child);
						if (enable) {
							child.itemFlags = child.itemFlags | ItemFlag.ItemIsEnabled;
						} else {
							child.itemFlags = child.itemFlags & ~ItemFlag.ItemIsEnabled;
						}
						child.itemChanged();
					}
				}
			}
		}
		this.itemChanged();
	}

	setSelected(selected: boolean): void {
		this.d.selected = selected;
	}

	setText(column: number, text: string): void {
		this.setData(
			column,
			ItemDataRole.DisplayRole,
			new Variant(text),
		);
	}

	takeChild(index: number): TreeItem | null {
		const model = this.treeModel();
		if ((index >= 0) && (index < this.children.size())) {
			if (model) {
				model.beginRemoveItems(this, index, 1);
			}
			const item = this.children.takeAt(index);
			item.par = null;
			const stk = new stack<TreeItem>();
			stk.push(item);
			while (!stk.isEmpty()) {
				const i = stk.pop();
				i.view = null;
				for (let c = 0; c < i.children.size(); ++c) {
					stk.push(i.children.at(c));
				}
			}
			this.d.propagateDisabled(item);
			if (model) {
				model.endRemoveRows();
			}
			return item;
		}
		return null;
	}

	takeChildren(): list<TreeItem> {
		let removed = new list<TreeItem>();
		if (this.children.size() > 0) {
			const model = this.treeModel();
			if (model) {
				model.beginRemoveItems(this, 0, this.children.size());
			}
			for (let n = 0; n < this.children.size(); ++n) {
				const item = this.children.at(n);
				item.par = null;
				const stk = new stack<TreeItem>();
				stk.push(item);
				while (!stk.isEmpty()) {
					const i = stk.pop();
					i.view = null;
					for (let c = 0; c < i.children.size(); ++c) {
						stk.push(i.children.at(c));
					}
				}
				this.d.propagateDisabled(item);
			}
			removed = new list(this.children);
			this.children.clear();
			if (model) {
				model.endRemoveItems();
			}
		}
		return removed;
	}

	text(column: number): string {
		return this.data(
			column,
			ItemDataRole.DisplayRole,
		).toString();
	}

	treeModel(view: TreeView | null = null): TreeModel | null {
		if (!view) {
			view = this.view;
		}
		return view ?
			<TreeModel>view.model() :
			null;
	}

	treeView(): Tree | null {
		return this.view;
	}

	type(): number {
		return this.t;
	}
}

export class TreeModelPrivate extends AbstractItemModelPrivate {
	get q(): TreeModel {
		return <TreeModel>super.q;
	}
}

export interface TreeModelOpts extends AbstractItemModelOpts {
	columns: number;
	dd: TreeModelPrivate;
	parent: Tree;
}

export class TreeModel extends AbstractItemModel {
	static itemGreaterThan(left: Pair<TreeItem, number>, right: Pair<TreeItem, number>): boolean {
		return right[0].lt(left[0]);
	}

	static itemLessThan(left: Pair<TreeItem, number>, right: Pair<TreeItem, number>): boolean {
		return left[0].lt(right[0]);
	}

	headerItem: TreeItem | null;
	rootItem: TreeItem;

	constructor(opts: Partial<TreeModelOpts> = {}) {
		super(opts);
		this.headerItem = new TreeItem();
		this.rootItem = new TreeItem();
		this.rootItem.view = opts.parent || null;
		this.rootItem.itemFlags = ItemFlag.ItemIsDropEnabled;
		this.headerItem.view = opts.parent || null;
		if (isNumber(opts.columns)) {
			this.setColumnCount(opts.columns);
		}
	}

	beginInsertItems(parent: TreeItem, row: number, count: number): void {
		const par = this.index(parent, 0);
		this.beginInsertRows(
			par,
			row,
			row + count - 1,
		);
	}

	beginRemoveItems(parent: TreeItem | null, row: number, count: number): void {
		assert(row >= 0);
		assert(count > 0);
		this.beginRemoveRows(
			this.index(parent, 0),
			row,
			row + count - 1,
		);
	}

	clear(): void {
		this.beginResetModel();
		for (let i = 0; i < this.rootItem.childCount(); ++i) {
			const item = this.rootItem.children.at(i);
			item.par = null;
			item.view = null;
			item.destroy();
		}
		this.rootItem.children.clear();
		this.endResetModel();
	}

	clearItemData(index: ModelIndex): boolean {
		if (!this.checkIndex(index, CheckIndexOption.IndexIsValid)) {
			return false;
		}
		const item = this.item(index);
		if (!item) {
			return false;
		}
		let allInvalid: boolean = true;
		const iter = item.values.at(index.column());
		for (const obj of iter) {
			if (obj.value.isValid()) {
				allInvalid = false;
				break;
			}
		}
		if (allInvalid && !item.d.display.at(index.column()).isValid()) {
			// Already cleared
			return true;
		}
		item.d.display.replace(
			index.column(),
			new Variant(),
		);
		item.values.at(index.column()).clear();
		this.dataChanged(index, index);
		return true;
	}

	columnCount(parent: ModelIndex | PersistentModelIndex = new ModelIndex()): number {
		if (!this.headerItem) {
			return 0;
		}
		return this.headerItem.columnCount();
	}

	createIndexFromItem(row: number, column: number, item: TreeItem): ModelIndex {
		return this.createIndex(row, column, item);
	}

	get d(): TreeModelPrivate {
		return <TreeModelPrivate>super.d;
	}

	data(index: ModelIndex, role: number): Variant {
		if (index.isValid()) {
			const item = this.item(index);
			if (item) {
				return item.data(index.column(), role);
			}
		}
		return new Variant();
	}

	destroy(): void {
		this.clear();
		if (this.headerItem) {
			this.headerItem.view = null;
			this.headerItem.destroy();
		}
		this.rootItem.view = null;
		this.rootItem.destroy();
		super.destroy();
	}

	emitDataChanged(item: TreeItem, column: number, roles: Array<number>): void {
		if (this.signalsBlocked()) {
			return;
		}
		if ((this.headerItem === item) && (column < item.columnCount())) {
			if (column === -1) {
				this.headerDataChanged(
					Orientation.Horizontal,
					0,
					this.columnCount() - 1,
				);
			} else {
				this.headerDataChanged(
					Orientation.Horizontal,
					column,
					column,
				);
			}
			return;
		}
		let bottomRight: ModelIndex;
		let topLeft: ModelIndex;
		if (column === -1) {
			topLeft = this.index(item, 0);
			bottomRight = this.createIndex(
				topLeft.row(),
				this.columnCount() - 1,
				item,
			);
		} else {
			topLeft = this.index(item, column);
			bottomRight = topLeft;
		}
		this.dataChanged(topLeft, bottomRight, roles);
	}

	endInsertItems(): void {
		this.endInsertRows();
	}

	endRemoveItems(): void {
		this.endRemoveRows();
	}

	flags(index: ModelIndex): ItemFlag {
		if (!index.isValid()) {
			return this.rootItem.flags();
		}
		const item = this.item(index);
		assert(item);
		return item.flags();
	}

	hasChildren(parent: ModelIndex = new ModelIndex()): boolean {
		if (!parent.isValid()) {
			return this.rootItem.childCount() > 0;
		}
		const item = this.item(parent);
		if (item) {
			return item.childCount() > 0;
		}
		return false;
	}

	headerData(section: number, orientation: Orientation, role: number): Variant {
		if (orientation !== Orientation.Horizontal) {
			return new Variant();
		}
		if (this.headerItem) {
			return this.headerItem.data(section, role);
		}
		if (role === ItemDataRole.DisplayRole) {
			return new Variant(String(section + 1));
		}
		return new Variant();
	}

	index(item: TreeItem | null, column: number): ModelIndex;
	index(row: number, column: number, parent?: ModelIndex | PersistentModelIndex): ModelIndex;
	index(a: TreeItem | number | null, column: number, parent?: ModelIndex | PersistentModelIndex): ModelIndex {
		if (isNumber(a)) {
			const row: number = a;
			const c = this.columnCount(parent);
			if ((row < 0) || (column < 0) || (column >= c)) {
				return new ModelIndex();
			}
			if (parent === undefined) {
				parent = new ModelIndex();
			}
			const parentItem = parent.isValid() ?
				this.item(parent) :
				this.rootItem;
			if (parentItem && (row < parentItem.childCount())) {
				const item = parentItem.child(row);
				if (item) {
					return this.createIndex(row, column, item);
				}
				return new ModelIndex();
			}
			return new ModelIndex();
		} else {
			const item: TreeItem | null = a;
			if (!item || (item === this.rootItem)) {
				return new ModelIndex();
			}
			let par = item.parent();
			if (!par) {
				par = this.rootItem;
			}
			let row: number;
			const guess = item.d.rowGuess;
			if ((guess >= 0) && (par.children.size() > guess) && (par.children.at(guess) === item)) {
				row = guess;
			} else {
				row = par.children.lastIndexOf(item);
				item.d.rowGuess = row;
			}
			return this.createIndex(row, column, item);
		}
	}

	insertColumns(column: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		if ((count < 1) || (column < 0) || (column > this.columnCount(parent)) || (parent.column() > 0) || !this.headerItem) {
			return false;
		}
		this.beginInsertColumns(
			parent,
			column,
			column + count - 1,
		);
		const oldCount = this.columnCount(parent);
		column = clamp(0, column, oldCount);
		for (let i = 0; i < (count - oldCount); ++i) {
			this.headerItem.values.append(new list<ItemData>());
		}
		for (let i = oldCount; i < (oldCount + count); ++i) {
			this.headerItem.values.at(
				i,
			).append(
				new ItemData(
					ItemDataRole.DisplayRole,
					new Variant(String(i + 1)),
				),
			);
			this.headerItem.d.display.append(
				new Variant(String(i + 1)),
			);
		}
		const itemStack = new stack<TreeItem | null>();
		itemStack.push(null);
		while (!itemStack.isEmpty()) {
			const par = itemStack.pop();
			const children = par ?
				par.children :
				this.rootItem.children;
			for (let row = 0; row < children.size(); ++row) {
				const child = children.at(row);
				if (child.children.size() > 0) {
					itemStack.push(child);
				}
				for (let i = 0; i < count; ++i) {
					child.values.insert(column + 1, new list());
				}
			}
		}
		this.endInsertColumns();
		return true;
	}

	insertRows(row: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		if ((count < 1) || (row < 0) || (row > this.rowCount(parent)) || (parent.column() > 0)) {
			return false;
		}
		this.beginInsertRows(
			parent,
			row,
			row + count - 1,
		);
		const par = this.item(parent);
		while (count > 0) {
			const item = new TreeItem();
			item.view = this.view();
			item.par = par;
			if (par) {
				par.children.insert(row, item);
			} else {
				this.rootItem.children.insert(row, item);
			}
			++row;
			--count;
		}
		this.endInsertRows();
		return true;
	}

	item(index: ModelIndex | PersistentModelIndex): TreeItem | null {
		if (index.isValid()) {
			return <TreeItem | null>index.internalPointer();
		}
		return null;
	}

	itemChanged(item: TreeItem): void {
		this.dataChanged(
			this.index(item, 0),
			this.index(item, item.columnCount() - 1),
		);
	}

	itemData(index: ModelIndex): Map<number, Variant> {
		const roles = new Map<number, Variant>();
		const item = this.item(index);
		if (item) {
			const column = index.column();
			if (column < item.values.size()) {
				for (let i = 0; i < item.values.at(column).size(); ++i) {
					roles.set(
						item.values.at(column).at(i).role,
						item.values.at(column).at(i).value,
					);
				}
			}
			// Special cases
			const displayValue = item.data(
				column,
				ItemDataRole.DisplayRole,
			);
			if (displayValue.isValid()) {
				roles.set(
					ItemDataRole.DisplayRole,
					displayValue,
				);
			}
			const checkValue = item.data(
				column,
				ItemDataRole.CheckStateRole,
			);
			if (checkValue.isValid()) {
				roles.set(
					ItemDataRole.CheckStateRole,
					checkValue,
				);
			}
		}
		return roles;
	}

	parentIndex(child: ModelIndex): ModelIndex {
		if (!child.isValid()) {
			return new ModelIndex();
		}
		const item = <TreeItem | null>(child.internalPointer() || null);
		if (!item || (item === this.rootItem)) {
			return new ModelIndex();
		}
		return this.index(
			item.parent(),
			0,
		);
	}

	removeRows(row: number, count: number, parent: ModelIndex = new ModelIndex()): boolean {
		if ((count < 1) || (row < 0) || ((row + count) > this.rowCount(parent))) {
			return false;
		}
		const parentItem = this.item(parent);
		// NB: If parentItem is valid, begin/end RemoveRows is handled by
		//     takeChild below
		if (!parentItem) {
			this.beginRemoveRows(
				parent,
				row,
				row + count - 1,
			);
		}
		for (let i = row + count - 1; i >= row; --i) {
			const child = parentItem ?
				parentItem.takeChild(i) :
				this.rootItem.children.takeAt(i);
			assert(child);
			child.view = null;
			child.destroy();
		}
		if (!parentItem) {
			this.endRemoveRows();
		}
		return true;
	}

	rowCount(parent: ModelIndex | PersistentModelIndex = new ModelIndex()): number {
		if (!parent.isValid()) {
			return this.rootItem.childCount();
		}
		const parentItem = this.item(parent);
		if (parentItem) {
			return parentItem.childCount();
		}
		return 0;
	}

	setColumnCount(columns: number): void {
		if (columns < 0) {
			return;
		}
		const count = this.columnCount();
		if (count === columns) {
			return;
		}
		if (columns < count) {
			this.beginRemoveColumns(
				new ModelIndex(),
				columns,
				count - 1,
			);
			if (this.headerItem) {
				this.headerItem.values.remove(
					columns,
					count - columns,
				);
			}
			this.endRemoveColumns();
		} else {
			this.beginInsertColumns(
				new ModelIndex(),
				count,
				columns - 1,
			);
			if (this.headerItem) {
				for (let i = 0; i < (columns - count); ++i) {
					this.headerItem.values.append(new list<ItemData>());
				}
				for (let i = count; i < columns; ++i) {
					// Insert data without emitting the dataChanged signal
					this.headerItem.values.at(
						i,
					).append(
						new ItemData(
							ItemDataRole.DisplayRole,
							new Variant(String(i + 1)),
						),
					);
					this.headerItem.d.display.append(
						new Variant(String(i + 1)),
					);
				}
			}
			this.endInsertColumns();
		}
	}

	setData(index: ModelIndex, value: Variant, role: number): boolean {
		if (index.isValid()) {
			const item = this.item(index);
			if (item) {
				item.setData(index.column(), role, value);
				return true;
			}
		}
		return false;
	}

	setHeaderData(section: number, orientation: Orientation, value: Variant, role: number): boolean {
		if ((section < 0) || (orientation !== Orientation.Horizontal) || !this.headerItem || (section >= this.columnCount())) {
			return false;
		}
		this.headerItem.setData(section, role, value);
		return true;
	}

	view(): Tree {
		return <Tree>this.parent();
	}
}

export class TreePrivate extends TreeViewPrivate {
	itemElMap: Map<TreeItem, ListItem>;
	rootEl: List;

	constructor() {
		super();
		this.itemElMap = new Map();
		this.rootEl = new List();
		this.rootEl.setIndentation(0);
	}

	index(item: TreeItem, column: number = 0): ModelIndex {
		return this.treeModel().index(item, column);
	}

	init(opts: Partial<TreeOpts>): void {
		super.init(opts);
		this.rootEl.setParent(this.q);
		this.rootEl.show();
		this.rootEl.setCheckable(true);
	}

	isIndexValid(index: ModelIndex): boolean {
		return (index.row() >= 0) && (index.column() >= 0) && (index.model() === this.model);
	}

	item(index: ModelIndex): TreeItem | null {
		return this.treeModel().item(index);
	}

	get q(): Tree {
		return <Tree>super.q;
	}

	treeItemForListItem(listItem: ListItem): TreeItem | null {
		for (const [k, v] of this.itemElMap) {
			if (listItem === v) {
				return k;
			}
		}
		return null;
	}

	treeModel(): TreeModel {
		return <TreeModel>this.model;
	}
}

export interface TreeOpts extends TreeViewOpts {
	dd: TreePrivate;
}

@OBJ
export class Tree extends TreeView {
	constructor(opts: Partial<TreeOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames, 'lb-tree',
		);
		opts.dd = opts.dd || new TreePrivate();
		super(opts);
		const model = new TreeModel({
			parent: this,
			columns: 1,
		});
		super.setModel(model);
		Obj.connect(
			this, 'pressed',
			this, '_p_emitItemPressed');
		Obj.connect(
			this, 'clicked',
			this, '_p_emitItemClicked');
		Obj.connect(
			this, 'doubleClicked',
			this, '_p_emitItemDoubleClicked');
		Obj.connect(
			model, 'dataChanged',
			this, '_p_emitItemChanged');
	}

	addTopLevelItem(item: TreeItem): void {
		this.insertTopLevelItem(this.topLevelItemCount(), item);
	}

	addTopLevelItems(items: list<TreeItem>): void {
		this.insertTopLevelItems(this.topLevelItemCount(), items);
	}

	checkStateItems(state: CheckState): list<TreeItem> {
		const rv = new list<TreeItem>();
		const it = new TreeItemIterator(this);
		let curr = it.current;
		while (curr) {
			const v = curr.data(0, ItemDataRole.CheckStateRole);
			if (v.isValid() && !v.isNull() && (v.toNumber() === state)) {
				rv.append(curr);
			}
			it.forward();
			curr = it.current;
		}
		return rv;
	}

	@SLOT
	clear(): void {
		this.d.treeModel().clear();
	}

	@PROP({WRITE: 'setColumnCount'})
	columnCount(): number {
		return this.d.model.columnCount();
	}

	get d(): TreePrivate {
		return <TreePrivate>super.d;
	}

	@SLOT
	private _p_emitItemChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles?: Array<number>): void {
		const d = this.d;
		const item = d.item(topLeft);
		if (item) {
			const el = d.itemElMap.get(item);
			if (el) {
				el.setText(item.text(0));
			} else {
				logger.error('_p_emitItemChanged: No El for matching item at %s', topLeft);
			}
			this.itemChanged(item, topLeft.column());
		}
	}

	@SLOT
	private _p_emitItemClicked(index: ModelIndex): void {
		const d = this.d;
		const item = d.item(index);
		if (item) {
			this.itemClicked(item, index.column());
		}
	}

	@SLOT
	private _p_emitItemDoubleClicked(index: ModelIndex): void {
		const d = this.d;
		const item = d.item(index);
		if (item) {
			this.itemDoubleClicked(item, index.column());
		}
	}

	@SLOT
	private _p_emitItemPressed(index: ModelIndex): void {
		const d = this.d;
		const item = d.item(index);
		if (item) {
			this.itemPressed(item, index.column());
		}
	}

	findItems(text: string, flags: MatchFlag, column: number = 0): list<TreeItem> {
		const d = this.d;
		const indexes = d.model.match(
			this.model()!.index(
				0,
				column,
				new ModelIndex(),
			),
			ItemDataRole.DisplayRole,
			new Variant(text),
			-1,
			flags,
		);
		const items = new list<TreeItem>();
		for (let i = 0; i < indexes.size(); ++i) {
			const item = d.item(indexes.at(i));
			if (item) {
				items.append(item);
			}
		}
		return items;
	}

	indexFromItem(item: TreeItem, column: number = 0): ModelIndex {
		return this.d.index(item, column);
	}

	indexOfTopLevelItem(item: TreeItem): number {
		return this.d.treeModel().rootItem.children.indexOf(item);
	}

	insertTopLevelItem(index: number, item: TreeItem): void {
		this.d.treeModel().rootItem.insertChild(index, item);
	}

	insertTopLevelItems(index: number, items: list<TreeItem>): void {
		this.d.treeModel().rootItem.insertChildren(index, items);
	}

	@SIGNAL
	private itemChanged(item: TreeItem, column: number): void {
	}

	@SIGNAL
	private itemClicked(item: TreeItem, column: number): void {
	}

	@SIGNAL
	private itemDoubleClicked(item: TreeItem, column: number): void {
	}

	itemEl(item: TreeItem, column: number): ElObj | null {
		return super.indexEl(this.d.index(item, column));
	}

	itemFromIndex(index: ModelIndex): TreeItem | null {
		return this.d.item(index);
	}

	@SIGNAL
	private itemPressed(item: TreeItem, column: number): void {
	}

	@SLOT
	private listItemCheckStateChanged(listItem: ListItem): void {
	}

	removeItemEl(item: TreeItem, column: number): void {
		this.setItemEl(item, column, null);
	}

	@SLOT
	protected rowsInserted(parent: ModelIndex, start: number, end: number): void {
		// Added second item to root level:
		//     rowsInserted(ModelIndex(-1, -1, null), 1, 1)
		// - Note invalid index means no parent means root level
		// - 1 is start index, other 1 is end index. Just 1 row inserted at
		//   index 1.
		//
		// ---
		//
		// Added first child to 3rd (index 2) root item:
		//     rowsInserted(ModelIndex(2, 0, AbstractItemModel), 0, 0)
		// - Note valid parent index with row index 2, column index 0
		// - 0 is start index, other 0 is end index. Just 1 row inserted at
		//   index 0 (first child).
		const d = this.d;
		const isTopLevel = !parent.isValid();
		for (let i = 0; i < (end - start + 1); ++i) {
			const row = start + i;
			const itemIndex = d.treeModel().index(
				row,
				0,
				parent,
			);
			const item = this.itemFromIndex(itemIndex);
			if (!item) {
				logger.error(
					'rowsInserted: Item was not found at (%s, %s, %s)',
					row,
					0,
					parent,
				);
				continue;
			}
			if (isTopLevel) {
				const listItem = new ListItem();
				listItem.show();
				d.itemElMap.set(item, listItem);
				d.rootEl.insertItem(
					row,
					listItem,
				);
				Obj.connect(
					listItem, 'checkStateChanged',
					this, 'listItemCheckStateChanged',
				);
			} else {
				const par = item.parent();
				if (!par) {
					logger.error(
						'rowsInserted: No parent item returned for non-top-level item at (%s, %s, %s)',
						row,
						0,
						parent,
					);
					continue;
				}
				const parentListItem = d.itemElMap.get(par);
				if (!parentListItem) {
					logger.error(
						'rowsInserted: No parent el returned for non-top-level item at (%s, %s, %s)',
						row,
						0,
						parent,
					);
					continue;
				}
				const listItem = new ListItem();
				listItem.show();
				d.itemElMap.set(item, listItem);
				parentListItem.insertItem(
					row,
					listItem,
				);
				Obj.connect(
					listItem, 'checkStateChanged',
					this, 'listItemCheckStateChanged',
				);
			}
		}
	}

	@SLOT
	scrollToItem(item: TreeItem): void {
		super.scrollTo(this.d.index(item));
	}

	setExpanded(item: TreeItem, expanded: boolean): void {
		const d = this.d;
		const listItem = d.itemElMap.get(item);
		if (listItem) {
			listItem.setExpanded(expanded);
		}
	}

	setItemEl(item: TreeItem, column: number, el: ElObj | null): void {
		super.setIndexEl(this.d.index(item, column), el);
	}

	takeTopLevelItem(index: number): TreeItem | null {
		return this.d.treeModel().rootItem.takeChild(index);
	}

	topLevelItem(index: number): TreeItem | null {
		return this.d.treeModel().rootItem.child(index);
	}

	@PROP()
	topLevelItemCount(): number {
		return this.d.treeModel().rootItem.childCount();
	}
}

enum IteratorFlag {
	All = 0x00000000,
	Hidden = 0x00000001,
	NotHidden = 0x00000002,
	Selected = 0x00000004,
	Unselected = 0x00000008,
	Selectable = 0x00000010,
	NotSelectable = 0x00000020,
	DragEnabled = 0x00000040,
	DragDisabled = 0x00000080,
	DropEnabled = 0x00000100,
	DropDisabled = 0x00000200,
	HasChildren = 0x00000400,
	NoChildren = 0x00000800,
	Checked = 0x00001000,
	NotChecked = 0x00002000,
	Enabled = 0x00004000,
	Disabled = 0x00008000,
	Editable = 0x00010000,
	NotEditable = 0x00020000,
	UserFlag = 0x01000000,
}

export class TreeItemIterator {
	current: TreeItem | null;
	private currentIdx: number;
	private flags: IteratorFlag;
	private model: TreeModel | null;
	private parentIdxStack: stack<number>;

	constructor(iter: TreeItemIterator);
	constructor(item: TreeItem, flags?: IteratorFlag);
	constructor(item: Tree, flags?: IteratorFlag);
	constructor(obj: TreeItemIterator | TreeItem | Tree, flags: IteratorFlag = IteratorFlag.All) {
		this.currentIdx = 0;
		if (obj instanceof TreeItemIterator) {
			this.current = obj.current;
			this.currentIdx = obj.currentIdx;
			this.flags = obj.flags;
			this.model = obj.model;
			this.parentIdxStack = new stack(...obj.parentIdxStack);
		} else if (obj instanceof TreeItem) {
			const mdl = obj.view ?
				obj.view.model() :
				null;
			this.flags = flags;
			this.model = (mdl && (mdl instanceof TreeModel)) ?
				mdl :
				null;
			this.current = obj;
			this.parentIdxStack = new stack<number>();
			// Initialize currentIdx and parentIdxStack as if we had
			// traversed from the beginning.
			let par: TreeItem | null = obj;
			par = par.parent();
			const root = this.model ?
				this.model.rootItem :
				null;
			this.currentIdx = par ?
				par.indexOfChild(obj) :
				root ?
					root.indexOfChild(obj) :
					0;
			while (root && par) {
				const itm = par;
				par = par.parent();
				const idx = (par ? par : root).indexOfChild(itm);
				this.parentIdxStack.prepend(idx);
			}
			if (this.current && !this.matchesFlags(this.current)) {
				this.forward();
			}
		} else {
			assert(obj instanceof Tree);
			this.current = null;
			this.flags = flags;
			this.parentIdxStack = new stack<number>();
			const mdl = obj.model();
			this.model = (mdl && (mdl instanceof TreeModel)) ?
				mdl :
				null;
			if (this.model && !this.model.rootItem.children.isEmpty()) {
				this.current = this.model.rootItem.child(0);
			}
			if (this.current && !this.matchesFlags(this.current)) {
				this.forward();
			}
		}
	}

	backward(): void {
		if (this.current) {
			do {
				this.current = this.previous(this.current);
			} while (this.current && !this.matchesFlags(this.current));
		}
	}

	destroy(): void {
		this.current = null;
		this.model = null;
		this.parentIdxStack.clear();
	}

	forward(): void {
		if (this.current) {
			do {
				this.current = this.next(this.current);
			} while (this.current && !this.matchesFlags(this.current));
		}
	}

	private matchesFlags(item: TreeItem | null): boolean {
		if (!item) {
			return false;
		}
		if (this.flags === IteratorFlag.All) {
			return true;
		}
		const itemFlags = item.flags();
		if ((this.flags & IteratorFlag.Selectable) && !(itemFlags & ItemFlag.ItemIsSelectable)) {
			return false;
		}
		if ((this.flags & IteratorFlag.NotSelectable) && (itemFlags & ItemFlag.ItemIsSelectable)) {
			return false;
		}
		if ((this.flags & IteratorFlag.DragEnabled) && !(itemFlags & ItemFlag.ItemIsDragEnabled)) {
			return false;
		}
		if ((this.flags & IteratorFlag.DragDisabled) && (itemFlags & ItemFlag.ItemIsDragEnabled)) {
			return false;
		}
		if ((this.flags & IteratorFlag.DropEnabled) && !(itemFlags & ItemFlag.ItemIsDropEnabled)) {
			return false;
		}
		if ((this.flags & IteratorFlag.DropDisabled) && (itemFlags & ItemFlag.ItemIsDropEnabled)) {
			return false;
		}
		if ((this.flags & IteratorFlag.Enabled) && !(itemFlags & ItemFlag.ItemIsEnabled)) {
			return false;
		}
		if ((this.flags & IteratorFlag.Disabled) && (itemFlags & ItemFlag.ItemIsEnabled)) {
			return false;
		}
		if ((this.flags & IteratorFlag.Editable) && !(itemFlags & ItemFlag.ItemIsEditable)) {
			return false;
		}
		if ((this.flags & IteratorFlag.NotEditable) && (itemFlags & ItemFlag.ItemIsEditable)) {
			return false;
		}
		if (this.flags & (IteratorFlag.Checked | IteratorFlag.NotChecked)) {
			// Only test check state for column 0
			const check = item.checkState(0);
			// PartiallyChecked matches as Checked.
			if ((this.flags & IteratorFlag.Checked) && (check === CheckState.Unchecked)) {
				return false;
			}
			if ((this.flags & IteratorFlag.NotChecked) && (check !== CheckState.Unchecked)) {
				return false;
			}
		}
		if ((this.flags & IteratorFlag.HasChildren) && (item.childCount() < 1)) {
			return false;
		}
		if ((this.flags & IteratorFlag.NoChildren) && (item.childCount() > 0)) {
			return false;
		}
		if ((this.flags & IteratorFlag.Selected) && !item.isSelected()) {
			return false;
		}
		if ((this.flags & IteratorFlag.Unselected) && item.isSelected()) {
			return false;
		}
		return true;
	}

	private next(current: TreeItem | null): TreeItem | null {
		if (!current) {
			return null;
		}
		let rv: TreeItem | null;
		if (current.childCount() > 0) {
			// Walk the child
			this.parentIdxStack.push(this.currentIdx);
			this.currentIdx = 0;
			rv = current.child(0);
		} else {
			// Walk the sibling
			let par = current.parent();
			rv = par ?
				par.child(this.currentIdx + 1) :
				this.model ?
					this.model.rootItem.child(this.currentIdx + 1) :
					null;
			while (!rv && par) {
				// No sibling. Walk up to parent, try its sibling.
				par = par.parent();
				this.currentIdx = this.parentIdxStack.pop();
				rv = par ?
					par.child(this.currentIdx + 1) :
					this.model ?
						this.model.rootItem.child(this.currentIdx + 1) :
						null;
			}
			if (rv) {
				++this.currentIdx;
			}
		}
		return rv;
	}

	private nextSibling(item: TreeItem): TreeItem | null {
		let rv: TreeItem | null = null;
		const par = item.parent();
		if (par) {
			rv = par.child(
				par.indexOfChild(item) + 1,
			);
		} else {
			const view = item.treeView();
			if (view) {
				rv = view.topLevelItem(
					view.indexOfTopLevelItem(item) + 1,
				);
			}
		}
		return rv;
	}

	private previous(current: TreeItem | null): TreeItem | null {
		if (!current) {
			return null;
		}
		// Walk the previous sibling
		const par = current.parent();
		let rv = par ?
			par.child(this.currentIdx - 1) :
			this.model ?
				this.model.rootItem.child(this.currentIdx - 1) :
				null;
		if (rv) {
			// Have previous sibling but we need go down to the last leaf
			// node.
			--this.currentIdx;
			while (rv && (rv.childCount() > 0)) {
				this.parentIdxStack.push(this.currentIdx);
				this.currentIdx = rv.childCount() - 1;
				rv = rv.child(this.currentIdx);
			}
		} else if (par) {
			this.currentIdx = this.parentIdxStack.pop();
			rv = par;
		}
		return rv;
	}
}
