import {ElObj, ElObjOpts} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {clamp, numberFormat} from '../../util';
import {PushButton, PushButtonOpts} from '../../ui/pushbutton';
import {getLogger} from '../../logging';
import {list} from '../../tools';
import {Parcel} from '../../models';
import {Icon} from '../../ui/icon';

const logger = getLogger('views.parcelinfo');

export enum ParcelAction {
	IgnoreParcel,
}

@OBJ
class ParcelInfoButton extends PushButton {
	private ic: ElObj | null;

	constructor(opts: Partial<PushButtonOpts> = {}) {
		opts.styles = ElObj.mergeStyles(
			opts.styles,
			['display', 'flex'],
			['align-items', 'center'],
			['justify-content', 'center'],
			['margin-top', '12px'],
		);
		super(opts);
		this.ic = null;
	}
}

@OBJ
export class ParcelInfo extends ElObj {
	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.styles = ElObj.mergeStyles(
			opts.styles,
			['max-height', '150px'],
			['overflow', 'auto'],
			['width', '185px'],
		);
		super(opts);
		this.setText('No info available');
	}

	@SIGNAL
	private actionActivated(sectionIndex: number, action: ParcelAction): void {
	}

	async addInfo(info: Parcel, showParcelIgnoreBtn: boolean = true): Promise<ParcelSection> {
		return await this.insertInfo(
			this.children().size(),
			info,
			showParcelIgnoreBtn,
		);
	}

	children(): list<ParcelSection> {
		return <list<ParcelSection>>super.children();
	}

	info(sectionIndex: number): Parcel | null {
		const section = this.section(sectionIndex);
		return section ?
			section.parcel() :
			null;
	}

	private async insertInfo(index: number, info: Parcel, showIgnoreParcelBtn: boolean): Promise<ParcelSection> {
		this.removeText();
		index = clamp(0, index, this.children().size());
		const section = new ParcelSection({
			showIgnoreParcelBtn,
		});
		Obj.connect(
			section, 'actionActivated',
			this, 'sectionActionActivated',
		);
		section.setParent(this, index);
		section.show();
		await section.setInfo(info);
		return section;
	}

	private section(sectionIndex: number): ParcelSection | null {
		if ((sectionIndex >= 0) && (sectionIndex < this.children().size())) {
			return this.children().at(sectionIndex);
		}
		logger.warning('section: Got invalid section index %s', sectionIndex);
		return null;
	}

	private sectionIndex(section: ElObj): number {
		for (let i = 0; i < this.children().size(); ++i) {
			if (this.children().at(i) === section) {
				return i;
			}
		}
		return -1;
	}

	@SLOT
	private sectionActionActivated(action: ParcelAction): void {
		const obj = this.sender();
		if (obj) {
			const index = this.sectionIndex(<ElObj>obj);
			if (index >= 0) {
				this.actionActivated(index, action);
			}
		}
	}

	async setInfo(sectionIndex: number, info: Parcel): Promise<void> {
		const section = this.section(sectionIndex);
		if (section) {
			await section.setInfo(info);
		}
	}
}

interface ParcelSectionOpts extends ElObjOpts {
	showIgnoreParcelBtn: boolean;
}

@OBJ
class ParcelSection extends ElObj {
	private btn: ParcelInfoButton | null;
	private parc: Parcel | null;
	private showBtn: boolean;

	constructor(opts: Partial<ParcelSectionOpts> = {}) {
		super(opts);
		this.btn = null;
		this.parc = null;
		this.showBtn = Boolean(opts.showIgnoreParcelBtn);
	}

	@SIGNAL
	actionActivated(action: ParcelAction): void {
	}

	destroy(): void {
		this.parc = null;
		super.destroy();
	}

	@SLOT
	private ignoreButtonClicked(): void {
		this.actionActivated(ParcelAction.IgnoreParcel);
	}

	parcel(): Parcel | null {
		return this.parc;
	}

	async setInfo(parcel: Parcel): Promise<void> {
		this.parc = parcel;
		if (!this.btn && this.showBtn) {
			this.btn = new ParcelInfoButton({
				parent: this,
			});
			Obj.connect(
				this.btn, 'clicked',
				this, 'ignoreButtonClicked');
			this.btn.show();
		}
		let topLevelEl = new ElObj({
			parent: this,
			tagName: 'div',
		});
		topLevelEl.setText('Owners:');
		topLevelEl.show();
		for (const owner of await parcel.owners()) {
			const ownerName = await owner.name();
			const el = new ElObj({
				attributes: [
					['title', ownerName],
				],
				parent: topLevelEl,
				styles: [
					['padding-left', '8px'],
					['overflow', 'hidden'],
					['text-overflow', 'ellipsis'],
					['white-space', 'nowrap'],
				],
			});
			el.setText(ownerName);
			el.show();
		}
		topLevelEl = new ElObj({
			parent: this,
		});
		topLevelEl.setText('Addresses:');
		topLevelEl.show();
		const street = await parcel.street();
		const el = new ElObj({
			attributes: [
				['title', street],
			],
			parent: topLevelEl,
			styles: [
				['padding-left', '8px'],
				['overflow', 'hidden'],
				['text-overflow', 'ellipsis'],
				['white-space', 'nowrap'],
			],
		});
		el.setText(street);
		el.show();
		topLevelEl = new ElObj({
			parent: this,
		});
		topLevelEl.setText(`Zoning: ${await parcel.zoningName()}`);
		topLevelEl.show();
		topLevelEl = new ElObj({
			parent: this,
		});
		topLevelEl.setText(`Total value: $${numberFormat(await parcel.totalValue())}`);
		topLevelEl.show();
		topLevelEl = new ElObj({
			parent: this,
		});
		topLevelEl.setText(`Land value: $${numberFormat(await parcel.landValue())}`);
		topLevelEl.show();
		topLevelEl = new ElObj({
			parent: this,
		});
		topLevelEl.setText(`Structures value: $${numberFormat(await parcel.structuresValue())}`);
		topLevelEl.show();
		topLevelEl = new ElObj({
			parent: this,
		});
		topLevelEl.setText(`Other value: $${numberFormat(await parcel.otherValue())}`);
		topLevelEl.show();
		if (this.btn) {
			let icon = this.btn.icon();
			if (!icon) {
				icon = new Icon();
				this.btn.setIcon(icon);
			}
			if (await this.parc.isIgnored()) {
				icon.setName('check');
				this.btn.setText('un-ignore parcel');
			} else {
				icon.setName('not_interested');
				this.btn.setText('ignore parcel');
			}
		}
	}
}
