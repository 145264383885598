import {CloseMode, ElAttr} from './constants';
import {list, Point, Rect, set} from './tools';
import {getLogger} from './logging';
import {OBJ, Obj, ObjOpts, ObjPrivate, SLOT} from './obj';
import {ActionEvt, ChildEvt, CloseEvt, Evt, FocusEvt, HideEvt, KeyEvt, keyEvtFromDomKeyboardEvent, MouseEvt, mouseEvtFromDomMouseEvent, ResizeEvt, ShowEvt} from './evt';
import {assert, bind, elementString, isDisableable, isNumber, iterableToArray, pixelString, printTree, stringIterableToStringArray} from './util';
// NB: DO NOT MOVE THIS.
import {App} from './app';
// NB: DO NOT MOVE THIS (this should be the last item).
import {Tooltip} from './ui/tooltip';
import {Action} from './action';

const logger = getLogger('elobj');
const displayNoneCssClassName = 'display--none';
const magic_number = 4;
const mdcEventTypePatt = /^mdc\w+:/i;
export const _staticNode: Element = document.createElement('div');
export const staticRect: DOMRect = Object.freeze({
	bottom: 0,
	height: 0,
	left: 0,
	right: 0,
	top: 0,
	width: 0,
	x: 0,
	y: 0,
	toJSON: () => '',
});

export interface EventListenerInfoOpts {
	eventType: string;
	isDocumentEvent?: boolean;
	isWindowEvent?: boolean;
}

export class EventListenerInfo {
	eventType: string;
	documentEvent: boolean;
	windowEvent: boolean;

	constructor(opts: EventListenerInfoOpts) {
		this.eventType = opts.eventType;
		this.documentEvent = Boolean(opts.isDocumentEvent);
		this.windowEvent = Boolean(opts.isWindowEvent);
	}

	eq(other: EventListenerInfo): boolean {
		return (this.windowEvent === other.windowEvent)
			&& (this.documentEvent === other.documentEvent)
			&& (this.eventType === other.eventType);
	}
}

export class ElObjPrivate extends ObjPrivate {
	static elObjs: set<ElObj> = new set();
	static elTooltips: Map<ElObj, Tooltip> = new Map();

	actions: list<Action>;
	elAttrs: number;
	elem: Element;
	highAttrs: Array<number>;
	inDestructor: boolean;
	isClosing: boolean;
	isWin: boolean;
	listenerInfo: list<EventListenerInfo>;
	temporarilyAppendToDocumentBody: boolean;
	temporarilyDoNotApplyTheHiddenCssClass: boolean;
	temporarilyDoNotStopDomEventPropagation: boolean;
	textNode: Text | null;
	tooltip: string;

	constructor() {
		super();
		this.actions = new list();
		this.inDestructor = false;
		this.isEl = true;
		this.isWin = false;
		this.elAttrs = 0;
		this.elem = _staticNode;
		this.highAttrs = [];
		this.isClosing = false;
		this.listenerInfo = new list();
		this.textNode = null;
		this.tooltip = '';
		this.temporarilyAppendToDocumentBody = false;
		this.temporarilyDoNotApplyTheHiddenCssClass = false;
		this.temporarilyDoNotStopDomEventPropagation = false;
	}

	closeHelper(mode: CloseMode): boolean {
		if (this.isClosing) {
			return true;
		}
		this.isClosing = true;
		const q = this.q;
		const p = q.parentEl();
		const par = (!!p && !p.dd.wasDeleted) ?
			p :
			null;
		const quitOnClose = q.testAttribute(ElAttr.QuitOnClose);
		if (mode !== CloseMode.CloseNoEvent) {
			const evt = new CloseEvt();
			App.sendEvent(
				q,
				evt,
			);
			if (!evt.isAccepted()) {
				this.isClosing = false;
				return false;
			}
		}
		if (!q.isHidden()) {
			q.hide();
		}
		this.isClosing = false;
		if (q.testAttribute(ElAttr.DeleteOnClose)) {
			q.setAttr(ElAttr.DeleteOnClose, false);
			q.deleteLater();
		}
		return true;
	}

	dumpTree(): void {
		printTree(this.elem);
	}

	ensureTextNode(): Text {
		this.textNode = findTextNode(this.elem);
		// Unsure if the spec means that there will ALWAYS be a Text node
		// or only if there was text content present within the node at
		// some point in its lifetime. Since I don't know and currently
		// lack the willpower to dive deep into this mystery, we'll settle
		// for a check and create a new instance if necessary.
		if (!this.textNode) {
			this.textNode = document.createTextNode('');
			this.elem.appendChild(this.textNode);
		}
		return this.textNode;
	}

	hasListener(opts: {eventType: string; isWindowEvent: boolean; isDocumentEvent: boolean}): boolean {
		const cmp = new EventListenerInfo({
			isDocumentEvent: opts.isDocumentEvent,
			isWindowEvent: opts.isWindowEvent,
			eventType: opts.eventType,
		});
		for (const obj of this.listenerInfo) {
			if (obj.eq(cmp)) {
				return true;
			}
		}
		return false;
	}

	hideChildren(): void {
		for (const child of this.children) {
			if (!child.isElType() || child.testAttribute(ElAttr.Hidden)) {
				continue;
			}
			child.d.hideChildren();
			App.sendEvent(
				child,
				new HideEvt(),
			);
		}
	}

	hideHelper(): void {
		const q = this.q;
		if (q.testAttribute(ElAttr.Visible)) {
			q.setAttr(ElAttr.Visible, false);
		}
		App.sendEvent(
			q,
			new HideEvt(),
		);
		this.hideChildren();
	}

	init(opts: Partial<ElObjOpts>): void {
		const q = this.q;
		assert(q !== opts.parent, 'Cannot parent an ElObj to itself\'');
		if (!App.self) {
			logger.critical('ElObj::init: Cannot create an ElObj without App');
		}
		ElObjPrivate.elObjs.add(q);
		this.elAttrs = 0;
		if (opts.tmpAppendToDocBody !== undefined) {
			this.temporarilyAppendToDocumentBody = opts.tmpAppendToDocBody;
		}
		if (opts.tagName === undefined) {
			opts.tagName = (<typeof ElObj>q.constructor).tagName;
		}
		let elem: Element;
		if (opts.root) {
			if (opts.root instanceof ElObj) {
				const rd = opts.root.d;
				this.elAttrs = rd.elAttrs;
				this.highAttrs = rd.highAttrs;
				this.tooltip = rd.tooltip;
				elem = rd.elem;
				this.listenerInfo = new list(rd.listenerInfo);
				this.textNode = rd.textNode;
				rd.elAttrs = 0;
				rd.highAttrs = [];
				rd.tooltip = '';
				rd.elem = _staticNode;
				rd.listenerInfo = new list();
				rd.textNode = null;
				opts.root.destroy();
			} else {
				elem = opts.root;
			}
		} else {
			if (opts.namespace) {
				elem = document.createElementNS(
					opts.namespace,
					opts.tagName,
				);
			} else {
				elem = document.createElement(
					opts.tagName,
				);
			}
		}
		this.elem = elem;
		q.setAttr(ElAttr.Hidden, true);
		if (opts.attributes) {
			q.setAttribute(opts.attributes);
		}
		if (opts.classNames) {
			if (typeof opts.classNames === 'string') {
				q.addClass(opts.classNames);
			} else {
				q.addClass(...opts.classNames);
			}
		}
		if (opts.styles) {
			for (const [name, value] of opts.styles) {
				q.setStyleProperty(name, value);
			}
		}
		if (opts.parent) {
			q.setParent(opts.parent);
		} else if (this.temporarilyAppendToDocumentBody) {
			document.body.append(
				this.elem,
			);
		}
		App.sendEvent(
			q,
			new Evt(Evt.Create),
		);
	}

	get q(): ElObj {
		return <ElObj>super.q;
	}

	setEnabledHelper(enable: boolean): void {
		const q = this.q;
		q.setAttr(ElAttr.Disabled, !enable);
		if (isDisableable(this.elem)) {
			this.elem.disabled = q.testAttribute(ElAttr.Disabled);
		}
		const attribute = enable ?
			ElAttr.ForceDisabled :
			ElAttr.Disabled;
		for (const child of this.children) {
			if (child.isElType() && !child.testAttribute(attribute)) {
				child.d.setEnabledHelper(enable);
			}
		}
		App.sendEvent(
			q,
			new Evt(Evt.EnabledChange),
		);
	}

	setVisible(visible: boolean): void {
		const q = this.q;
		if (visible) {
			// Show
			q.setAttr(ElAttr.Hidden, false);
			const par = q.parentEl();
			if (!par || par.isVisible()) {
				this.showHelper();
			}
			App.sendEvent(
				q,
				new Evt(Evt.ShowToParent),
			);
		} else {
			// Hide
			if (!q.testAttribute(ElAttr.Hidden)) {
				q.setAttr(ElAttr.Hidden);
				this.hideHelper();
			}
			App.sendEvent(
				q,
				new Evt(Evt.HideToParent),
			);
		}
	}

	showChildren(): void {
		for (const child of this.children) {
			if (!child.isElType()) {
				continue;
			}
			if (!child.testAttribute(ElAttr.ExplicitShowHide)) {
				child.setAttr(ElAttr.Hidden, false);
			}
			if (!child.dd.parent || child.testAttribute(ElAttr.Hidden)) {
				continue;
			}
			if (child.testAttribute(ElAttr.ExplicitShowHide)) {
				child.d.showRecursive();
			} else {
				child.show();
			}
		}
	}

	showHelper(): void {
		const q = this.q;
		q.setAttr(ElAttr.Visible);
		this.showChildren();
		App.sendEvent(
			q,
			new ShowEvt(),
		);
	}

	showRecursive(): void {
		this.showHelper();
	}
}

export interface ElObjOpts extends ObjOpts {
	attributes: Iterable<[string, string]>;
	classNames: Iterable<string>;
	dd: ElObjPrivate;
	namespace: string | null;
	parent: ElObj | null;
	root: ElObj | Element | null;
	styles: Iterable<[string, string]>;
	tagName: TagName;
	tmpAppendToDocBody: boolean;
}

@OBJ
export class ElObj extends Obj {
	static tagName: TagName = 'div';

	static mergeAttributes(existing?: Iterable<[string, string]>, ...other: Array<[string, string]>): Array<[string, string]> {
		return this.mergeStringPairs(
			existing,
			...other,
		);
	}

	static mergeClassNames(existing?: Iterable<string>, ...other: Array<string>): Array<string> {
		const rv: Array<string> = [...other];
		if (existing) {
			rv.push(
				...stringIterableToStringArray(
					existing,
				).map(
					x => x.trim(),
				),
			);
		}
		return rv.filter(
			x => (x.length > 0),
		);
	}

	static mergeStringPairs(existing?: Iterable<[string, string]>, ...other: Array<[string, string]>): Array<[string, string]> {
		const rv: Array<[string, string]> = [];
		if (existing) {
			rv.push(
				...iterableToArray(existing),
			);
		}
		rv.push(...other);
		return rv;
	}

	static mergeStyles(existing?: Iterable<[string, string]>, ...other: Array<[string, string]>): Array<[string, string]> {
		return this.mergeStringPairs(
			existing,
			...other,
		);
	}

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.dd = opts.dd || new ElObjPrivate();
		super(opts);
		opts.dd.init(opts);
	}

	protected actionEvent(event: ActionEvt): void {
	}

	actions(): list<Action> {
		return this.d.actions;
	}

	addClass(...name: Array<string>): void {
		const d = this.d;
		if (name.length > 0) {
			d.elem.classList.add(...name);
		}
	}

	addAction(action: Action): void {
		this.insertAction(null, action);
	}

	addActions(actions: Iterable<Action>): void {
		for (const axn of actions) {
			this.insertAction(null, axn);
		}
	}

	protected addDocumentEventListener<K extends keyof DocumentEvent>(type: K): void;
	protected addDocumentEventListener(type: string): void;
	protected addDocumentEventListener(type: string) {
		const d = this.d;
		if (d.hasListener({eventType: type, isDocumentEvent: true, isWindowEvent: false})) {
			return;
		}
		document.addEventListener(
			type,
			this._documentEvent,
		);
		d.listenerInfo.append(
			new EventListenerInfo({
				eventType: type,
				isDocumentEvent: true,
				isWindowEvent: false,
			}),
		);
	}

	addEventListener<K extends keyof HTMLElementEventMap>(type: K): void;
	addEventListener(type: string): void;
	addEventListener(type: string) {
		const d = this.d;
		if (d.hasListener({eventType: type, isDocumentEvent: false, isWindowEvent: false})) {
			return;
		}
		d.elem.addEventListener(
			type,
			this._domEvent,
		);
		d.listenerInfo.append(
			new EventListenerInfo({
				eventType: type,
				isWindowEvent: false,
			}),
		);
	}

	protected addWindowEventListener<K extends keyof WindowEventMap>(type: K): void;
	protected addWindowEventListener(type: string): void;
	protected addWindowEventListener(type: string) {
		const d = this.d;
		if (d.hasListener({eventType: type, isDocumentEvent: false, isWindowEvent: true})) {
			return;
		}
		window.addEventListener(
			type,
			this._windowEvent,
		);
		d.listenerInfo.append(
			new EventListenerInfo({
				eventType: type,
				isWindowEvent: true,
			}),
		);
	}

	attribute(name: string): string | null {
		return this.d.elem.getAttribute(name);
	}

	blur(): void {
		const d = this.d;
		if (d.elem instanceof HTMLElement) {
			d.elem.blur();
		}
	}

	protected changeEvent(event: Evt): void {
	}

	clear(): void {
		const d = this.d;
		if (d.textNode) {
			d.textNode.data = '';
			d.textNode.remove();
			d.textNode = null;
		}
		let node: Node | null = d.elem.firstChild;
		while (node) {
			d.elem.removeChild(node);
			node = d.elem.firstChild;
		}
	}

	protected closeEvent(event: CloseEvt): void {
	}

	computedStyle(): CSSStyleDeclaration {
		return window.getComputedStyle(this.d.elem);
	}

	get d(): ElObjPrivate {
		return <ElObjPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.elem.remove();
		d.inDestructor = true;
		for (const axn of d.actions) {
			axn.d.associatedObjs.removeAll(this);
		}
		d.actions.clear();
		// NB: The next handful of lines are duplicated from Obj, but required
		//     here since ElObj deletes is children itself.
		const blocked = d.blockSig;
		d.blockSig = false;
		this.destroyed(this);
		d.blockSig = blocked;
		for (const info of d.listenerInfo) {
			if (info.windowEvent) {
				window.removeEventListener(
					info.eventType,
					this._windowEvent,
				);
			} else if (info.documentEvent) {
				document.removeEventListener(
					info.eventType,
					this._documentEvent,
				);
			} else {
				d.elem.removeEventListener(
					info.eventType,
					this._domEvent,
				);
			}
		}
		d.listenerInfo.clear();
		if (d.textNode) {
			d.textNode.remove();
			d.textNode.data = '';
			d.textNode = null;
		}
		d.elem = _staticNode;
		d.elAttrs = 0;
		d.highAttrs = [];
		if (d.children.size() > 0) {
			d.deleteChildren();
		}
		if (ElObjPrivate.elObjs.size > 0) {
			ElObjPrivate.elObjs.discard(this);
		}
		App.sendEvent(
			this,
			new Evt(Evt.Destroy),
		);
		super.destroy();
	}

	@bind
	private _documentEvent(event: Event): void {
		switch (event.type) {
			case 'mouseup': {
				this._documentMouseUpEvent(<MouseEvent>event);
				break;
			}
			case 'keydown': {
				this._documentKeyDownEvent(<KeyboardEvent>event);
				break;
			}
		}
	}

	protected _documentKeyDownEvent(event: KeyboardEvent): void {
	}

	protected _documentMouseUpEvent(event: MouseEvent): void {
	}

	protected _domAnimationEndEvent(event: AnimationEvent): void {
	}

	protected _domChangeEvent(event: Event): void {
	}

	protected _domErrorEvent(event: Event): void {
	}

	@bind
	private _domEvent(event: Event): void {
		switch (event.type) {
			case 'mousemove':
			case 'mouseenter':
			case 'mouseleave':
			case 'pointerleave':
			case 'mouseout':
			case 'mouseover':
			case 'mousedown':
			case 'mouseup':
			case 'contextmenu':
			case 'dblclick': {
				const evt = <MouseEvent>event;
				if (!this.d.temporarilyDoNotStopDomEventPropagation) {
					evt.stopImmediatePropagation();
				}
				App.setLastCursorPos(
					new Point(
						evt.clientX,
						evt.clientY,
					),
				);
				App.sendEvent(
					this,
					mouseEvtFromDomMouseEvent(evt),
				);
				break;
			}
			case 'input': {
				this._domInputEvent(<InputEvent>event);
				break;
			}
			case 'keydown':
			case 'keyup': {
				App.sendEvent(
					this,
					keyEvtFromDomKeyboardEvent(<KeyboardEvent>event),
				);
				break;
			}
			case 'animationend': {
				this._domAnimationEndEvent(<AnimationEvent>event);
				break;
			}
			case 'change': {
				this._domChangeEvent(<Event>event);
				break;
			}
			case 'error': {
				this._domErrorEvent(<Event>event);
				break;
			}
			case 'load': {
				this._domLoadEvent(<Event>event);
				break;
			}
			case 'pageshow': {
				this._domPageShowEvent(<PageTransitionEvent>event);
				break;
			}
			case 'resize': {
				App.sendEvent(
					this,
					new Evt(Evt.Resize),
				);
				break;
			}
			case 'submit': {
				event.preventDefault();
				this._domSubmitEvent(<Event>event);
				break;
			}
		}
		if (mdcEventTypePatt.test(event.type)) {
			this._domMdcEvent(event);
		}
	}

	protected _domInputEvent(event: InputEvent): void {
	}

	protected _domLoadEvent(event: Event): void {
	}

	protected _domMdcEvent(event: Event): void {
	}

	protected _domPageShowEvent(event: PageTransitionEvent): void {
	}

	protected _domSubmitEvent(event: Event): void {
	}

	element(): Element {
		return this.d.elem;
	}

	protected enterEvent(event: Evt): void {
	}

	event(event: Evt): boolean {
		if (!this.isEnabled()) {
			switch (event.type()) {
				case Evt.MouseMove:
				case Evt.Wheel:
				case Evt.KeyPress:
				case Evt.KeyRelease:
				case Evt.MouseButtonPress:
				case Evt.MouseButtonRelease:
				case Evt.MouseButtonDblClick:
				case Evt.TouchBegin:
				case Evt.TouchUpdate:
				case Evt.TouchEnd:
				case Evt.TouchCancel:
				case Evt.ContextMenu:
					return false;
				default:
					break;
			}
		}
		switch (event.type()) {
			case Evt.MouseMove:
				this.mouseMoveEvent(<MouseEvt>event);
				break;
			case Evt.MouseButtonPress:
				this.mousePressEvent(<MouseEvt>event);
				break;
			case Evt.MouseButtonRelease:
				this.mouseReleaseEvent(<MouseEvt>event);
				break;
			case Evt.MouseButtonDblClick:
				this.mouseDoubleClickEvent(<MouseEvt>event);
				break;
			case Evt.KeyPress:
				this.keyPressEvent(<KeyEvt>event);
				break;
			case Evt.KeyRelease:
				this.keyReleaseEvent(<KeyEvt>event);
				break;
			case Evt.ShortcutOverride:
				break;
			case Evt.FocusIn:
				this.focusInEvent(<FocusEvt>event);
				break;
			case Evt.FocusOut:
				this.focusOutEvent(<FocusEvt>event);
				break;
			case Evt.Enter:
				this.enterEvent(event);
				break;
			case Evt.Leave:
				this.leaveEvent(event);
				break;
			case Evt.Resize:
				this.resizeEvent(<ResizeEvt>event);
				break;
			case Evt.Close:
				this.closeEvent(<CloseEvt>event);
				break;
			case Evt.Show:
				this.showEvent(<ShowEvt>event);
				break;
			case Evt.Hide:
				this.hideEvent(<HideEvt>event);
				break;
			case Evt.ActivationChange:
			case Evt.EnabledChange:
			case Evt.FontChange:
			case Evt.StyleChange:
			case Evt.WindowTitleChange:
			case Evt.IconTextChange:
			case Evt.ModifiedChange:
			case Evt.MouseTrackingChange:
			case Evt.ParentChange:
			case Evt.ThemeChange:
			case Evt.ReadOnlyChange:
				this.changeEvent(event);
				break;
			case Evt.TouchBegin:
			case Evt.TouchUpdate:
			case Evt.TouchEnd:
			case Evt.TouchCancel:
				event.ignore();
				break;
			case Evt.ActionAdded:
			case Evt.ActionChanged:
			case Evt.ActionRemoved:
				this.actionEvent(<ActionEvt>event);
				break;
			default:
				return super.event(event);
		}
		return true;
	}

	focus(): void {
		const d = this.d;
		if (d.elem instanceof HTMLElement) {
			d.elem.focus();
		}
	}

	protected focusInEvent(event: FocusEvt): void {
	}

	protected focusOutEvent(event: FocusEvt): void {
	}

	hasClass(name: string): boolean {
		return this.d.elem.classList.contains(name);
	}

	hasFocus(): boolean {
		const active = document.activeElement;
		return (active !== null) && (active === this.d.elem);
	}

	hasMouseTracking(): boolean {
		return this.testAttribute(ElAttr.MouseTracking);
	}

	@SLOT
	hide(): void {
		this.setVisible(false);
	}

	protected hideEvent(event: HideEvt): void {
	}

	insertAction(before: Action | null, action: Action): void {
		const d = this.d;
		if (d.actions.contains(action)) {
			this.removeAction(action);
		}
		let idx = before ?
			d.actions.indexOf(before) :
			-1;
		if (idx < 0) {
			before = null;
			idx = d.actions.size();
		}
		d.actions.insert(idx, action);
		action.d.associatedObjs.append(this);
		App.sendEvent(
			this,
			new ActionEvt(
				Evt.ActionAdded,
				action,
				before,
			),
		);
	}

	insertActions(before: Action | null, actions: Iterable<Action>): void {
		for (const axn of actions) {
			this.insertAction(before, axn);
		}
	}

	private insertChildEl(index: number, child: ElObj | Element): void {
		const d = this.d;
		const count = d.elem.children.length;
		let ref: Element | null = null;
		if ((index >= 0) && (index < count) && (count > 0)) {
			ref = d.elem.children[index];
		}
		const del = this.parentElDelegate();
		const parElem = del ?
			del.element() :
			d.elem;
		parElem.insertBefore(
			(child instanceof ElObj) ?
				child.element() :
				child,
			ref,
		);
	}

	isEnabled(): boolean {
		return !this.testAttribute(ElAttr.Disabled);
	}

	isHidden(): boolean {
		return this.testAttribute(ElAttr.Hidden);
	}

	isVisible(): boolean {
		return this.testAttribute(ElAttr.Visible);
	}

	isWindow(): boolean {
		return this.d.isWin;
	}

	protected keyPressEvent(event: KeyEvt): void {
		event.ignore();
	}

	protected keyReleaseEvent(event: KeyEvt): void {
		event.ignore();
	}

	protected leaveEvent(event: Evt): void {
	}

	protected mouseDoubleClickEvent(event: MouseEvt): void {
		event.ignore();
	}

	protected mouseMoveEvent(event: MouseEvt): void {
		event.ignore();
	}

	protected mousePressEvent(event: MouseEvt): void {
		event.ignore();
	}

	protected mouseReleaseEvent(event: MouseEvt): void {
		event.ignore();
	}

	parentEl(): ElObj | null {
		const d = this.d;
		return (d.parent && d.parent.isElType()) ?
			d.parent :
			null;
	}

	protected parentElDelegate(): ElObj | null {
		return null;
	}

	rect(): DOMRect {
		return this.d.elem.getBoundingClientRect();
	}

	remove(): void {
		this.d.elem.remove();
	}

	removeAction(action: Action | null): void {
		if (!action) {
			return;
		}
		action.d.associatedObjs.removeAll(this);
		const d = this.d;
		if (d.actions.removeAll(action) > 0) {
			App.sendEvent(
				this,
				new ActionEvt(
					Evt.ActionRemoved,
					action,
				),
			);
		}
	}

	removeAttribute(...name: Array<string>): void {
		const d = this.d;
		name.forEach(n => d.elem.removeAttribute(n));
	}

	removeClass(...name: Array<string>): void {
		this.d.elem.classList.remove(...name);
	}

	protected removeDocumentEventListener<K extends keyof DocumentEventMap>(type: K): void;
	protected removeDocumentEventListener(type: string): void;
	protected removeDocumentEventListener(type: string): void {
		const d = this.d;
		document.removeEventListener(
			type,
			this._documentEvent,
		);
		const cmp = new EventListenerInfo({
			eventType: type,
			isDocumentEvent: true,
			isWindowEvent: false,
		});
		let i = d.listenerInfo.size() - 1;
		for (; i >= 0; --i) {
			const info = d.listenerInfo.at(i);
			if (info.eq(cmp)) {
				d.listenerInfo.removeAt(i);
			}
		}
	}

	removeEventListener<K extends keyof HTMLElementEventMap>(type: K): void;
	removeEventListener(type: string): void;
	removeEventListener(type: string): void {
		const d = this.d;
		d.elem.removeEventListener(
			type,
			this._domEvent,
		);
		const cmp = new EventListenerInfo({
			eventType: type,
			isWindowEvent: false,
		});
		let i = d.listenerInfo.size() - 1;
		for (; i >= 0; --i) {
			const info = d.listenerInfo.at(i);
			if (info.eq(cmp)) {
				d.listenerInfo.removeAt(i);
			}
		}
	}

	protected removeWindowEventListener<K extends keyof WindowEventMap>(type: K): void;
	protected removeWindowEventListener(type: string): void;
	protected removeWindowEventListener(type: string): void {
		const d = this.d;
		window.removeEventListener(
			type,
			this._windowEvent,
		);
		const cmp = new EventListenerInfo({
			eventType: type,
			isWindowEvent: true,
		});
		let i = d.listenerInfo.size() - 1;
		for (; i >= 0; --i) {
			const info = d.listenerInfo.at(i);
			if (info.eq(cmp)) {
				d.listenerInfo.removeAt(i);
			}
		}
	}

	removeStyleProperty(name: string): void {
		const d = this.d;
		if ((d.elem instanceof HTMLElement)) {
			d.elem.style.removeProperty(name);
		}
	}

	removeText(): void {
		const d = this.d;
		if (d.textNode) {
			d.textNode.data = '';
			d.textNode.remove();
			d.textNode = null;
		}
		const nodes = d.elem.childNodes;
		for (let i = 0; i < nodes.length; ++i) {
			const node = nodes[i];
			if (node.nodeType === Node.TEXT_NODE) {
				d.elem.removeChild(node);
			}
		}
	}

	protected resizeEvent(event: ResizeEvt): void {
	}

	rr(): Rect {
		const r = this.rect();
		return new Rect(r.x, r.y, r.width, r.height);
	}

	scrollBy(x: number, y: number): void;
	scrollBy(options?: ScrollToOptions): void;
	scrollBy(a?: number | ScrollToOptions, b?: number): void {
		const d = this.d;
		if (isNumber(a) && isNumber(b)) {
			d.elem.scrollBy(a, b);
		} else {
			d.elem.scrollBy(<ScrollToOptions | undefined>a);
		}
	}

	setAttr(attr: ElAttr, on: boolean = true): void {
		if (this.testAttribute(attr) === on) {
			return;
		}
		const d = this.d;
		if (attr < (8 * magic_number)) {
			if (on) {
				d.elAttrs |= (1 << attr);
			} else {
				d.elAttrs &= ~(1 << attr);
			}
		} else {
			const x = attr - 8 * magic_number;
			const intOff = Math.floor(x / (8 * magic_number));
			const n = (1 << (x - (intOff * 8 * magic_number)));
			const f = (n === -2147483648) ?
				2147483648 :
				n;
			if (on) {
				d.highAttrs[intOff] |= f;
			} else {
				d.highAttrs[intOff] &= ~f;
			}
		}
		switch (attr) {
			case ElAttr.Hidden: {
				if (!d.temporarilyDoNotApplyTheHiddenCssClass) {
					this.setClass(on, displayNoneCssClassName);
				}
				break;
			}
			case ElAttr.NoChildEventsForParent: {
				d.sendChildEvents = !on;
				break;
			}
			case ElAttr.NoChildEventsFromChildren: {
				d.receiveChildEvents = !on;
				break;
			}
			case ElAttr.MouseTracking: {
				App.sendEvent(
					this,
					new Evt(Evt.MouseTrackingChange),
				);
				break;
			}
			default:
				break;
		}
	}

	setAttribute(name: string, value: string): void;
	setAttribute(attrs: Iterable<[string, string]>): void;
	setAttribute(a: Iterable<[string, string]> | string, b?: string): void {
		const d = this.d;
		if (typeof a === 'string') {
			d.elem.setAttribute(a, <string>b);
		} else {
			for (const [k, v] of a) {
				this.setAttribute(k, v);
			}
		}
	}

	setClass(add: boolean, ...name: Array<string>): void {
		if (add) {
			this.addClass(...name);
		} else {
			this.removeClass(...name);
		}
	}

	@SLOT
	setDisabled(disabled: boolean): void {
		this.setEnabled(!disabled);
	}

	@SLOT
	setEnabled(enable: boolean): void {
		this.setAttr(
			ElAttr.ForceDisabled,
			!enable,
		);
		this.d.setEnabledHelper(enable);
	}

	setMouseTracking(enable: boolean): void {
		this.setAttr(ElAttr.MouseTracking, enable);
	}

	setParent(parent?: ElObj | null, index: number = -1): void {
		if (parent === this.parentEl()) {
			return;
		}
		assert(this !== parent, 'Cannot parent an ElObj to itself.');
		const d = this.d;
		App.sendEvent(
			this,
			new Evt(Evt.ParentAboutToChange),
		);
		const newParent = (parent !== this.parentEl());
		d.setParentHelper(parent, index);
		const explicitlyHidden = this.testAttribute(ElAttr.Hidden) && this.testAttribute(ElAttr.ExplicitShowHide);
		this.setAttr(ElAttr.Visible, false);
		this.setAttr(ElAttr.Hidden, false);
		if ((!parent || parent.isVisible()) || explicitlyHidden) {
			this.setAttr(ElAttr.Hidden, true);
		}
		this.setAttr(ElAttr.ExplicitShowHide, explicitlyHidden);
		if (newParent) {
			if (!this.parentEl()) {
				if (!this.testAttribute(ElAttr.ForceDisabled)) {
					d.setEnabledHelper(
						parent ?
							parent.isEnabled() :
							true,
					);
				}
			}
			if (parent && d.sendChildEvents) {
				App.sendEvent(
					parent,
					new ChildEvt(Evt.ChildAdded, this),
				);
			}
			App.sendEvent(
				this,
				new Evt(Evt.ParentChange),
			);
		}
		const par = this.parentEl();
		if (d.temporarilyAppendToDocumentBody) {
			document.body.append(
				this.element(),
			);
		} else {
			if (par) {
				if (d.temporarilyAppendToDocumentBody) {
					document.body.append(
						this.element(),
					);
				} else {
					par.insertChildEl(index, this);
				}
			} else {
				this.remove();
			}
		}
		if (!par || par.isVisible()) {
			this.setAttr(ElAttr.Hidden, true);
		} else if (!this.testAttribute(ElAttr.ExplicitShowHide)) {
			this.setAttr(ElAttr.Hidden, false);
		}
	}

	setStyleProperty(name: string, value: string | number | null): void {
		// NB: A value of type number is assumed to be a pixel value and will
		//     be convert as such.
		const d = this.d;
		if (d.elem instanceof HTMLElement) {
			d.elem.style.setProperty(
				name,
				isNumber(value) ?
					pixelString(value) :
					value,
			);
		}
	}

	setTabIndex(index: number): void {
		const d = this.d;
		if (d.elem instanceof HTMLElement) {
			d.elem.tabIndex = index;
		}
	}

	setText(text?: string | null, insertAtIndex: number = -1): void {
		this._setText(text, insertAtIndex);
	}

	protected _setText(text?: string | null, insertAtIndex: number = -1): void {
		const d = this.d;
		const node = d.ensureTextNode();
		// ensureTextNode() appends the Text node (if created) and an
		// index less than 0 is treated as an append.
		if (insertAtIndex >= 0) {
			const childNodes = d.elem.childNodes;
			if ((childNodes.length > 0) && (insertAtIndex < childNodes.length)) {
				const sib = childNodes[insertAtIndex];
				// Sanity check
				assert(sib);
				d.elem.insertBefore(node, sib);
			}
		}
		node.data = text || '';
	}

	// setToolTip(toolTip: string): void {
	// 	// FIXME: This is why it's not working:
	// 	// if (toolTip.trim().length > 0) {
	// 	// 	return;
	// 	// }
	// 	const d = this.d;
	// 	if (toolTip === d.tooltip) {
	// 		return;
	// 	}
	// 	d.tooltip = toolTip;
	// 	const tt = (<typeof ElObjPrivate>d.constructor).elTooltips.get(this);
	// 	if (tt) {
	// 		tt.setText(d.tooltip);
	// 	} else {
	// 		const obj = new Tooltip();
	// 		(<typeof ElObjPrivate>d.constructor).elTooltips.set(this, obj);
	// 		const ttId = `id_lb-elobj-${d.createdNumber}-tooltip`;
	// 		this.setAttribute('aria-describedby', ttId);
	// 		setTimeout(() => {
	// 			obj.initialize(ttId);
	// 			obj.setText(d.tooltip);
	// 			obj.show();
	// 		}, 3000);
	// 	}
	// }

	setToolTip(toolTip?: string): void {
		if (toolTip) {
			this.setAttribute('title', toolTip);
		} else {
			this.removeAttribute('title');
		}
	}

	setTransparent(invisible: boolean): void {
		this.setClass(
			invisible,
			'visibility--hidden',
		);
	}

	@SLOT
	setVisible(visible: boolean): void {
		if (this.testAttribute(ElAttr.ExplicitShowHide) && (this.testAttribute(ElAttr.Hidden) === !visible)) {
			return;
		}
		// Remember that setVisible was called explicitly
		this.setAttr(ElAttr.ExplicitShowHide);
		this.d.setVisible(visible);
	}

	@SLOT
	show(): void {
		this.setVisible(true);
	}

	protected showEvent(event: ShowEvt): void {
	}

	stackUnder(el: ElObj | null): void {
		/**
		 * Places this obj under `el` in the parent obj's stack.
		 *
		 * To make this work, this obj and `el`` must be siblings.
		 */
		if (!el) {
			return;
		}
		const par = this.parentEl();
		if (!par || (par !== el.parentEl()) || (this === el)) {
			return;
		}
		if (par) {
			const from = par.d.children.indexOf(this);
			let to = par.d.children.indexOf(el);
			assert(from >= 0);
			assert(to >= 0);
			if (from < to) {
				--to;
			}
			if (from === to) {
				// Already in correct stacking order
				return;
			}
			par.d.children.move(from, to);
			par.insertChildEl(to, this);
			App.sendEvent(
				this,
				new Evt(Evt.ZOrderChange),
			);
		}
	}

	testAttribute(attr: ElAttr): boolean {
		const d = this.d;
		if (attr < (8 * magic_number)) {
			return Boolean(d.elAttrs & (1 << attr));
		}
		const x = attr - 8 * magic_number;
		const intOff = Math.floor(x / (8 * magic_number));
		const n = (1 << (x - (intOff * 8 * magic_number)));
		const f = (n === -2147483648) ?
			2147483648 :
			n;
		return Boolean(d.highAttrs[intOff] & f);
	}

	text(): string {
		return this.d.ensureTextNode().data;
	}

	toolTip(): string {
		return this.d.tooltip;
	}

	toString(): string {
		const d = this.d;
		const parts = [
			elementString(d.elem),
			this.objectName().trim(),
		];
		const s = parts
			.filter(p => (p.length > 0))
			.join(', ');
		const n = this.constructor.name;
		return `${n}(${s})`;
	}

	window(): ElObj {
		let e: ElObj = this;
		let p = this.parentEl();
		while (!e.isWindow() && p) {
			e = p;
			p = p.parentEl();
		}
		return e;
	}

	protected _windowBlurEvent(event: Event): void {
	}

	@bind
	private _windowEvent(event: Event): void {
		switch (event.type) {
			case 'mousemove': {
				this._windowMouseMoveEvent(<MouseEvent>event);
				break;
			}
			case 'mouseup': {
				this._windowMouseUpEvent(<MouseEvent>event);
				break;
			}
			case 'keydown': {
				this._windowKeyDownEvent(<KeyboardEvent>event);
				break;
			}
			case 'click': {
				this._windowMouseClickEvent(<MouseEvent>event);
				break;
			}
			case 'blur': {
				this._windowBlurEvent(event);
				break;
			}
			case 'focus': {
				this._windowFocusEvent(event);
				break;
			}
			case 'resize': {
				this._windowResizeEvent(event);
				break;
			}
		}
	}

	protected _windowFocusEvent(event: Event): void {
	}

	protected _windowKeyDownEvent(event: KeyboardEvent): void {
	}

	protected _windowMouseClickEvent(event: MouseEvent): void {
	}

	protected _windowMouseMoveEvent(event: MouseEvent): void {
	}

	protected _windowMouseUpEvent(event: MouseEvent): void {
	}

	protected _windowResizeEvent(event: Event): void {
	}
}

function findTextNode(elem: Element): Text | null {
	// WARNING: This routine will normalize this element's entire subtree.
	//
	// The Node.normalize() method puts the specified node and all of
	// its sub-tree into a "normalized" form. In a normalized sub-tree,
	// no text nodes in the sub-tree are empty and there are no
	// adjacent text nodes.
	elem.normalize();
	for (const node of elem.childNodes) {
		if (node.nodeType === Node.TEXT_NODE) {
			return <Text>node;
		}
	}
	return null;
}

