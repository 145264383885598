function nullFactory(): null {
	return null;
}

type Factory<T> = () => T;

export class defaultdict<K, V> {
	private data: Map<K, V>;
	private factory: Factory<V>;

	constructor(defaultFactory?: Factory<V>) {
		this.data = new Map();
		this.factory = <Factory<V>>(defaultFactory || nullFactory);
	}

	get size(): number {
		return this.data.size;
	}

	clear(): void {
		this.data.clear();
	}

	delete(key: K): boolean {
		return this.data.delete(key);
	}

	entries(): IterableIterator<[K, V]> {
		return this.data.entries();
	}

	forEach(fn: (value: V, key: K, map: Map<K, V>) => void, thisArg?: any): void {
		this.data.forEach(fn, thisArg);
	}

	get(key: K): V {
		let rv = this.data.get(key);
		if (rv === undefined) {
			rv = this.factory();
			this.data.set(key, rv);
		}
		return rv;
	}

	has(key: K): boolean {
		return this.data.has(key);
	}

	keys(): IterableIterator<K> {
		return this.data.keys();
	}

	set(key: K, value: V): void {
		this.data.set(key, value);
	}

	toArray(): Array<[K, V]> {
		return Array.from(this);
	}

	values(): IterableIterator<V> {
		return this.data.values();
	}

	get [Symbol.toStringTag](): string {
		return 'defaultdict';
	}

	*[Symbol.iterator](): IterableIterator<[K, V]> {
		yield *this.data[Symbol.iterator]();
	}
}
