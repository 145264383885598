import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {Orientation, SortOrder} from '../../../constants';
import {ElObj, ElObjOpts, ElObjPrivate} from '../../../elobj';
import {HeaderCell} from './cell';
import {Row} from './row';
import type {Table} from './table';
import {list} from '../../../tools';

export class HeadPrivate extends ElObjPrivate {
	orientation: Orientation;
	sortIndicatorOrder: SortOrder;
	sortIndicatorSection: number;
	sortIndicatorShown: boolean;

	constructor() {
		super();
		this.orientation = Orientation.Horizontal;
		this.sortIndicatorOrder = SortOrder.NoOrder;
		this.sortIndicatorSection = -1;
		this.sortIndicatorShown = false;
	}

	init(opts: Partial<ElObjOpts>): void {
		super.init(opts);
		const obj = new HeaderRow({
			parent: this.q,
		});
		obj.show();
	}

	get q(): Head {
		return <Head>super.q;
	}
}

export interface HeadOpts extends ElObjOpts {
	dd: HeadPrivate;
}

@OBJ
export class Head extends ElObj {
	static tagName: TagName = 'thead';

	constructor(opts: Partial<HeadOpts> = {}) {
		opts.dd = opts.dd || new HeadPrivate();
		super(opts);
	}

	get d(): HeadPrivate {
		return <HeadPrivate>super.d;
	}

	insertCell(index: number, cell: HeaderCell): void {
		const row = this.row();
		if (!row) {
			return;
		}
		Obj.connect(
			cell,
			'sortIndicatorClicked',
			this,
			'_sortIndicatorClicked',
		);
		row.insertCell(index, cell);
	}

	isSortIndicatorShown(): boolean {
		return this.d.sortIndicatorShown;
	}

	parentTable(): Table | null {
		return <Table | null>this.parentEl();
	}

	removeCell(index: number): void {
		const row = this.row();
		if (!row) {
			return;
		}
		if ((index >= 0) && (index < row.children().size())) {
			const cell = row.children().at(index);
			Obj.disconnect(
				cell,
				'sortIndicatorClicked',
				this,
				'_sortIndicatorClicked',
			);
			row.removeCell(index);
		}
	}

	row(): HeaderRow | null {
		if ((this.children().size() === 1) && (this.children().first() instanceof HeaderRow)) {
			return <HeaderRow>this.children().first();
		}
		return null;
	}

	setSortIndicator(section: number, order: SortOrder): void {
		/**
		 * Sets the sort indicator for the section in the direction given as
		 * order, and removes the sort indicator from any other section that
		 * was showing it.
		 *
		 * index may be -1, in which case no sort indicator will be shown.
		 */
		const d = this.d;
		if ((section === d.sortIndicatorSection) && (order === d.sortIndicatorOrder)) {
			return;
		}
		d.sortIndicatorSection = section;
		d.sortIndicatorOrder = order;
		this.sortIndicatorChanged(
			d.sortIndicatorSection,
			d.sortIndicatorOrder,
		);
	}

	setSortIndicatorShown(show: boolean): void {
		const d = this.d;
		if (show === d.sortIndicatorShown) {
			return;
		}
		d.sortIndicatorShown = show;
		const row = this.row();
		if (!row) {
			return;
		}
		for (const cell of row.children()) {
			cell.setSortIndicatorShown(d.sortIndicatorShown);
		}
	}

	@SIGNAL
	protected sortIndicatorChanged(index: number, order: SortOrder): void {
	}

	@SLOT
	protected _sortIndicatorClicked(row: number, column: number): void {
		const r = this.row();
		if (!r || !((column >= 0) && (column < r.children().size()))) {
			return;
		}
		const d = this.d;
		let nextSortOrder: SortOrder;
		switch (d.sortIndicatorOrder) {
			case SortOrder.AscendingOrder: {
				nextSortOrder = SortOrder.DescendingOrder;
				break;
			}
			case SortOrder.DescendingOrder: {
				nextSortOrder = SortOrder.NoOrder;
				break;
			}
			case SortOrder.NoOrder: {
				nextSortOrder = SortOrder.AscendingOrder;
				break;
			}
		}
		d.sortIndicatorSection = column;
		d.sortIndicatorOrder = nextSortOrder;
		this.sortIndicatorChanged(column, nextSortOrder);
	}

	sortIndicatorOrder(): SortOrder {
		return this.d.sortIndicatorOrder;
	}

	sortIndicatorSection(): number {
		return this.d.sortIndicatorSection;
	}

	height(): number {
		return this.rect().height;
	}
}

@OBJ
export class HeaderRow extends Row {
	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__header-row',
		);
		super(opts);
	}

	children(): list<HeaderCell> {
		return <list<HeaderCell>>super.children();
	}

	insertCell(index: number, cell: HeaderCell): void {
		super.insertCell(index, cell);
	}
}
