import {Mode} from './mode';
import {MapEventType} from '../../map';
import {Point} from '../features/point';
import {Cursor, MapDrawMode} from '../../../../constants';
import {
	KeyboardEvt,
	MapMouseFeatureEvt,
	MapTouchFeatureEvt,
} from '../events';

export class DrawPoint extends Mode {
	name = MapDrawMode.DrawPoint;
	state!: Point;

	protected keyPressEvent(evt: KeyboardEvt) {
		if ((evt.event.key === 'Escape') || (evt.event.key === 'Enter')) {
			this.stopDrawingAndRemove();
		}
	}

	protected mouseClickEvent(evt: MapMouseFeatureEvt) {
		this.touchTapMouseClickEvent(evt.event.lngLat);
	}

	setup(options?: any): void {
		super.setup(options);
		this.state = this.newFeature({
			geometry: {
				coordinates: [],
				type: 'Point',
			},
			properties: {},
			type: 'Feature',
		});
		this.addFeature(this.state);
		this.clearSelectedFeatures();
		this.ctx.api.setMapClasses({mouse: Cursor.Add});
	}

	stop(): void {
		super.stop();
		if (this.state.getCoordinate().length < 1) {
			this.deleteFeature([this.state.id], {silent: true});
		}
	}

	stopDrawingAndRemove(): void {
		this.deleteFeature([this.state.id], {silent: true});
		this.setMode(MapDrawMode.NoMode);
	}

	toDisplayFeatures(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		// Never render the point we're drawing
		const isActivePoint = feature.properties.id === this.state.id;
		feature.properties.active = isActivePoint ?
			'true' :
			'false';
		if (!isActivePoint) {
			display(feature);
		}
	}

	protected touchTapEvent(evt: MapTouchFeatureEvt): void {
		this.touchTapMouseClickEvent(evt.event.lngLat);
	}

	private touchTapMouseClickEvent(lngLat: {lng: number; lat: number;}): void {
		this.ctx.api.setMapClasses({mouse: Cursor.Move});
		this.state.updateCoordinate('', lngLat.lng, lngLat.lat);
		const trans = this.isTransient();
		this.fireEvent(
			trans ?
				MapEventType.DrawTransientFeatureCreate :
				MapEventType.DrawFeatureCreate,
			{
				features: [
					this.state.toGeoJSON(),
				],
			},
		);
		if (trans) {
			this.deleteFeature(
				[
					this.state.id,
				],
				{
					silent: true,
				},
			);
		}
	}

	trash(): void {
		this.stopDrawingAndRemove();
		super.trash();
	}
}
