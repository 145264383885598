import {ElWin, ElWinOpts, ElWinPrivate} from '../../ui/window';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ToolBar} from '../../ui/toolbar';
import {ActionGroup} from '../../actiongroup';
import {Action} from '../../action';
import {Icon} from '../../ui/icon';

export enum AxnTyp {
	NoAxn,
	Tag,
	Lookup,
	Pin,
	Fly,
}

export class WinPrivate extends ElWinPrivate {
	axnGroup: ActionGroup | null;
	axnMap: Map<Action, AxnTyp>;
	toolBar: ToolBar | null;

	constructor() {
		super();
		this.axnGroup = null;
		this.axnMap = new Map();
		this.toolBar = null;
	}

	init(opts: Partial<WinOpts>): void {
		super.init(opts);
		const q = this.q;
		this.toolBar = new ToolBar({
			parent: q,
		});
		this.axnGroup = new ActionGroup({
			parent: q,
		});
		this.axnGroup.setEnabled(false);
		this.axnGroup.setExclusive(false);
		const tagAxn = this.axnGroup.addAction(
			new Icon({name: 'label'}),
		);
		this.axnMap.set(tagAxn, AxnTyp.Tag);
		tagAxn.setToolTip('Tags');
		this.toolBar.addAction(tagAxn);
		const lookupAxn = this.axnGroup.addAction(
			new Icon({name: 'gps_fixed'}),
		);
		this.axnMap.set(lookupAxn, AxnTyp.Lookup);
		lookupAxn.setToolTip('Find related');
		this.toolBar.addAction(lookupAxn);
		// const pinAxn = this.axnGroup.addAction(
		// 	new Icon({name: 'place'}),
		// );
		// this.axnMap.set(pinAxn, AxnTyp.Pin);
		// pinAxn.setToolTip('Highlight parcel');
		// this.toolBar.addAction(pinAxn);
		const flyAxn = this.axnGroup.addAction(
			new Icon({name: 'flight'}),
		);
		this.axnMap.set(flyAxn, AxnTyp.Fly);
		flyAxn.setToolTip('Fly to parcel');
		this.toolBar.addAction(flyAxn);
		Obj.connect(
			this.axnGroup, 'triggered',
			q, 'actionGroupTriggered',
		);
	}

	get q(): Win {
		return <Win>super.q;
	}
}

export interface WinOpts extends ElWinOpts {
	dd: WinPrivate;
	id: string;
}

@OBJ
export class Win extends ElWin {
	id: string;

	constructor(opts: Partial<WinOpts> = {}) {
		opts.dd = opts.dd || new WinPrivate();
		super(opts);
		this.id = (opts.id === undefined) ?
			'' :
			opts.id;
	}

	action(axnTyp: AxnTyp): Action | null {
		for (const [obj, typ] of this.d.axnMap) {
			if (typ === axnTyp) {
				return obj;
			}
		}
		return null;
	}

	actionGroup(): ActionGroup | null {
		return this.d.axnGroup;
	}

	@SLOT
	protected actionGroupTriggered(action: Action): void {
		for (const [axn, typ] of this.d.axnMap) {
			if (axn === action) {
				this.actionTriggered(this.id, typ);
				break;
			}
		}
	}

	@SIGNAL
	protected actionTriggered(id: string, axn: AxnTyp): void {
	}

	axnTyp(action: Action): AxnTyp {
		const rv = this.d.axnMap.get(action);
		return (rv === undefined) ?
			AxnTyp.NoAxn :
			rv;
	}

	get d(): WinPrivate {
		return <WinPrivate>super.d;
	}

	isActionGroupEnabled(): boolean {
		const d = this.d;
		return d.axnGroup ?
			d.axnGroup.isEnabled() :
			false;
	}

	setActionGroupEnabled(enabled: boolean): void {
		const d = this.d;
		if (d.axnGroup) {
			d.axnGroup.setEnabled(enabled);
		}
	}

	toolBar(): ToolBar | null {
		return this.d.toolBar;
	}
}
