import {Feature} from './feature';
import {Point} from './point';
import {LineString} from './linestring';
import {Polygon} from './polygon';
import {IMapboxDrawContext} from '../mapboxdraw';
import {hat} from '../../../../util';

const models = {
	MultiPoint: Point,
	MultiLineString: LineString,
	MultiPolygon: Polygon,
};

export class MultiFeature extends Feature {
	features: Array<Point> | Array<LineString> | Array<Polygon>;
	model: typeof Point | typeof LineString | typeof Polygon;

	constructor(ctx: IMapboxDrawContext, feature: GeoJsonFeature<GeoJsonMultiPoint | GeoJsonMultiLineString | GeoJsonMultiPolygon>) {
		super(ctx, feature);
		this.model = models[feature.geometry.type];
		this.features = this.coordinatesToFeatures(feature.geometry.coordinates);
	}

	addCoordinate(path: string, longitude: number, latitude: number): void {
		const parts: Array<string> = path.split('.');
		const idx: number = parseInt(parts[0], 10);
		const tail: string = (!parts[1]) ?
			'' :
			parts.slice(1).join('.');
		this.features[idx].addCoordinate(tail, longitude, latitude);
		this.changed();
	}

	private coordinatesToFeatures(coords: GeoJsonPosition[] | GeoJsonPosition[][] | GeoJsonPosition[][][]): Array<Point> | Array<LineString> | Array<Polygon> {
		const Model = this.model.bind(this);
		return coords.map((cc: GeoJsonPosition | GeoJsonPosition[] | GeoJsonPosition[][]) => new Model(this.ctx, {
			id: hat(),
			type: 'Feature',
			properties: {},
			geometry: <GeoJsonPoint | GeoJsonLineString | GeoJsonPolygon>{
				coordinates: cc,
				type: this.type.replace('Multi', ''),
			},
		}));
	}

	getCoordinate(path: string): GeoJsonPosition {
		const parts: Array<string> = path.split('.');
		const idx: number = parseInt(parts[0], 10);
		const tail: string = (!parts[1]) ?
			'' :
			parts.slice(1).join('.');
		return this.features[idx].getCoordinate(tail);
	}

	getCoordinates(): GeoJsonPosition[] | GeoJsonPosition[][] | GeoJsonPosition[][][] {
		return <GeoJsonPosition[] | GeoJsonPosition[][] | GeoJsonPosition[][][]>this.features.map((feat: Point | LineString | Polygon) => {
			if (feat.type === 'Polygon') {
				return feat.getCoordinates();
			}
			return feat.coordinates;
		});
	}

	getFeatures(): Array<Point> | Array<LineString> | Array<Polygon> {
		return this.features;
	}

	isValid(): boolean {
		return this.features.every(f => f.isValid());
	}

	removeCoordinate(path: string): void {
		const parts: Array<string> = path.split('.');
		const idx: number = parseInt(parts[0], 10);
		const tail: string = (!parts[1]) ?
			'' :
			parts.slice(1).join('.');
		this.features[idx].removeCoordinate(tail);
		this.changed();
	}

	setCoordinates(coords: GeoJsonPosition[] | GeoJsonPosition[][] | GeoJsonPosition[][][]): void {
		this.features = this.coordinatesToFeatures(coords);
		this.changed();
	}

	updateCoordinate(path: string, longitude: number, latitude: number): void {
		const parts: Array<string> = path.split('.');
		const idx: number = parseInt(parts[0], 10);
		const tail: string = (!parts[1]) ?
			'' :
			parts.slice(1).join('.');
		this.features[idx].updateCoordinate(tail, longitude, latitude);
		this.changed();
	}
}
