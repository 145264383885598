import {ElObj} from '../../elobj';
import {OBJ} from '../../obj';
import {ControlItem, ControlView, ControlViewOpts, ControlViewPrivate} from '../itemviews/controlview';
import {getLogger} from '../../logging';
import {Tree, TreeItem} from '../tree';
import {ControlType, ExpressionSegment, ItemDataRole} from '../../constants';
import {Variant} from '../../variant';
import {ComboBox} from '../combobox';

const logger = getLogger('filterbox.expression');

const segmentSection: Record<ExpressionSegment, number> = {
	[ExpressionSegment.LeftHandSide]: 0,
	[ExpressionSegment.Operator]: 1,
	[ExpressionSegment.RightHandSide]: 2,
};

class ExpressionPrivate extends ControlViewPrivate {
	get q(): Expression {
		return <Expression>super.q;
	}
}

interface ExpressionOpts extends ControlViewOpts {
	dd: ExpressionPrivate;
}

@OBJ
export class Expression extends ControlView {
	constructor(opts: Partial<ExpressionOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-filter-expr',
		);
		opts.dd = opts.dd || new ExpressionPrivate();
		super(opts);
	}

	get d(): ExpressionPrivate {
		return <ExpressionPrivate>super.d;
	}

	isSegmentDisabled(segment: ExpressionSegment): boolean {
		return !this.isSegmentEnabled(segment);
	}

	isSegmentEnabled(segment: ExpressionSegment): boolean {
		const idx = this.segmentSection(segment);
		if (!((idx >= 0) && (idx < this.sectionCount()))) {
			return true;
		}
		const item = this.item(idx);
		if (!item) {
			logger.error('isSegmentEnabled: No object for index %s', idx);
			return true;
		}
		return item.isEnabled();
	}

	itemSection(item: ControlItem | null): number {
		if (item) {
			return this.indexFromItem(item).column();
		}
		return -1;
	}

	itemSegment(item: ControlItem | null): number {
		const sec = this.itemSection(item);
		switch (sec) {
			case this.segmentSection(ExpressionSegment.LeftHandSide): {
				return ExpressionSegment.LeftHandSide;
			}
			case this.segmentSection(ExpressionSegment.Operator): {
				return ExpressionSegment.Operator;
			}
			case this.segmentSection(ExpressionSegment.RightHandSide): {
				return ExpressionSegment.RightHandSide;
			}
		}
		return -1;
	}

	segmentControlType(segment: ExpressionSegment): ControlType {
		const item = this.segmentItem(segment);
		if (item) {
			return item.type();
		}
		return ControlType.NoType;
	}

	segmentData(segment: ExpressionSegment): Variant {
		const idx = this.segmentSection(segment);
		const item = this.item(idx);
		if (!item) {
			// logger.error('segmentData: No object for index %s', idx);
			return new Variant();
		}
		return item.data(ItemDataRole.EditRole);
	}

	segmentItem(segment: ExpressionSegment): ControlItem | null {
		return this.item(
			this.segmentSection(segment),
		);
	}

	segmentSection(segment: ExpressionSegment): number {
		return segmentSection[segment];
	}

	private setControlOptions(index: number, opts: Iterable<ILabeledValue>): void {
		const item = this.item(index);
		if (!item) {
			logger.error('setControlOptions: No object for index %s', index);
			return;
		}
		const ctrl = item.control();
		if (!ctrl) {
			logger.error('setControlOptions: No object for index %s', index);
			return;
		}
		if (!((ctrl instanceof ComboBox) || (ctrl instanceof Tree))) {
			logger.warning('setControlOptions: The control for this segment does not accept options.', index);
			return;
		}
		const blocked = ctrl.blockSignals(true);
		const objs = Array.from(opts);
		if (ctrl instanceof ComboBox) {
			// NB: Dumpster fire: We check if the current combobox options
			//     match exactly the options we've just been given. This is
			//     because the current selected index (if there is one) is
			//     reset when the combobox is clear and the combobox must be
			//     cleared before setting new options. If there is currently a
			//     valid selected index and we re-set the same options, the
			//     index invalidates and the combobox will render its null
			//     option (index === -1).
			const curr = ctrl.items();
			if ((curr.size() === objs.length) && (curr.size() !== 0)) {
				let diff = false;
				for (let i = 0; i < curr.size(); ++i) {
					const a = curr.at(i);
					const b = objs[i];
					if (a.text !== b.label) {
						diff = true;
						break;
					}
					if (a.value !== b.value) {
						diff = true;
						break;
					}
				}
				if (!diff) {
					// We have a combobox with 1 or more options matching
					// exactly the options we've just been given. Nothing left
					// to do.
					ctrl.blockSignals(blocked);
					return;
				}
			}
		}
		ctrl.clear();
		if (ctrl instanceof ComboBox) {
			for (const obj of objs) {
				ctrl.addItem(obj.label, obj.value);
			}
		} else {
			this.setTreeControlOptions(ctrl, objs);
		}
		ctrl.blockSignals(blocked);
	}

	setSegmentData(segment: ExpressionSegment, data: Variant): void {
		const idx = this.segmentSection(segment);
		if (!((idx >= 0) && (idx < this.sectionCount()))) {
			return;
		}
		const item = this.item(idx);
		if (!item) {
			logger.error('setSegmentCurrentValue: No object for index %s', idx);
			return;
		}
		item.setData(ItemDataRole.EditRole, data);
	}

	setSegmentDisabled(segment: ExpressionSegment, disabled: boolean): void {
		this.setSegmentEnabled(segment, !disabled);
	}

	setSegmentEnabled(segment: ExpressionSegment, enabled: boolean): void {
		const idx = this.segmentSection(segment);
		if (!((idx >= 0) && (idx < this.sectionCount()))) {
			return;
		}
		const item = this.item(idx);
		if (!item) {
			logger.error('setSegmentEnabled: No object for index %s', idx);
			return;
		}
		item.setEnabled(enabled);
	}

	setSegmentOptions(segment: ExpressionSegment, opts: Iterable<ILabeledValue>): void {
		this.setControlOptions(
			this.segmentSection(segment),
			opts,
		);
	}

	setSegmentVisible(segment: ExpressionSegment, visible: boolean, controlType?: ControlType): void {
		// controlType may be included if enabling a segment. If an item
		// already exists and the types do not match, a new item will be
		// created with the given controlType. Otherwise a new item is
		// created with the given controlType. Did I already mention that?
		// Yes, yes I did.
		const section = this.segmentSection(segment);
		if (visible) {
			const count = section + 1;
			if (this.sectionCount() < count) {
				this.setSectionCount(count);
			}
			let item: ControlItem | null = this.item(section);
			if ((controlType !== undefined) && item && (item.type() !== controlType)) {
				item = null;
			}
			if (!item) {
				item = new ControlItem(controlType);
				// NB: Signals blocked because itemChanged() is emitted when
				//     a control is instantiated which is not what we want
				//     right now.
				const blocked = this.blockSignals(true);
				this.setItem(section, item);
				this.blockSignals(blocked);
			}
		} else {
			switch (section) {
				case this.segmentSection(ExpressionSegment.LeftHandSide): {
					this.setSegmentVisible(
						ExpressionSegment.Operator,
						false,
					);
					break;
				}
				case this.segmentSection(ExpressionSegment.Operator): {
					this.setSegmentVisible(
						ExpressionSegment.RightHandSide,
						false,
					);
					break;
				}
			}
			this.removeSection(section);
		}
	}

	private setTreeControlOptions(tree: Tree, opts: Iterable<ILabeledValue>): void {
		doit(tree, Array.from(opts));
	}
}

function exprAttrChildren(parentId: string, other: Array<ILabeledValue>): Array<ILabeledValue> {
	if (parentId.length < 1) {
		return [];
	}
	const rv: Array<ILabeledValue> = [];
	for (const obj of other) {
		if ((obj.parentId === parentId) && (obj.id !== parentId)) {
			rv.push(obj);
		}
	}
	return rv;
}

function makeStuff(parent: TreeItem, parentId: string, objs: Array<ILabeledValue>): void {
	const children = exprAttrChildren(parentId, objs);
	for (const child of children) {
		const item = new TreeItem(parent);
		item.setData(0, ItemDataRole.EditRole, new Variant(child.id));
		item.setText(0, child.label);
		makeStuff(item, child.id, objs);
	}
}

function doit(tree: Tree, objs: Array<ILabeledValue>): void {
	for (const obj of objs) {
		if (obj.parentId.length > 0) {
			// Top-level only here
			continue;
		}
		const item = new TreeItem(tree);
		item.setData(0, ItemDataRole.EditRole, new Variant(obj.id));
		item.setText(0, obj.label);
		makeStuff(item, obj.id, objs);
	}
}
