export class Margins {
	static add(lhs: Margins, rhs: Margins): Margins;
	static add(lhs: Margins, rhs: number): Margins;
	static add(lhs: number, rhs: Margins): Margins;
	static add(lhs: Margins | number, rhs: Margins | number): Margins {
		let m: Margins;
		let left: number;
		let top: number;
		let right: number;
		let bottom: number;
		if ((lhs instanceof Margins) && (rhs instanceof Margins)) {
			m = lhs;
			left = rhs.left();
			top = rhs.top();
			right = rhs.right();
			bottom = rhs.bottom();
		} else {
			if (lhs instanceof Margins) {
				m = lhs;
				left = <number>rhs;
				top = <number>rhs;
				right = <number>rhs;
				bottom = <number>rhs;
			} else {
				m = <Margins>rhs;
				left = <number>lhs;
				top = <number>lhs;
				right = <number>lhs;
				bottom = <number>lhs;
			}
		}
		return new this(
			m.left() + left,
			m.top() + top,
			m.right() + right,
			m.bottom() + bottom);
	}

	static div(margins: Margins, divisor: number): Margins {
		return new this(
			margins.left() / divisor,
			margins.top() / divisor,
			margins.right() / divisor,
			margins.bottom() / divisor);
	}

	static eq(a: Margins, b: Margins): boolean {
		return (a.m_left === b.m_left)
			&& (a.m_top === b.m_top)
			&& (a.m_right === b.m_right)
			&& (a.m_bottom === b.m_bottom);
	}

	static mul(lhs: Margins, rhs: number): Margins;
	static mul(lhs: number, rhs: Margins): Margins;
	static mul(lhs: Margins | number, rhs: Margins | number): Margins {
		let m: Margins;
		let f: number;
		if (lhs instanceof Margins) {
			m = lhs;
			f = <number>rhs;
		} else {
			m = <Margins>rhs;
			f = lhs;
		}
		return new this(
			m.left() * f,
			m.top() * f,
			m.right() * f,
			m.bottom() * f);
	}

	static ne(a: Margins, b: Margins): boolean {
		return (a.m_left !== b.m_left)
			|| (a.m_top !== b.m_top)
			|| (a.m_right !== b.m_right)
			|| (a.m_bottom !== b.m_bottom);
	}

	static neg(margins: Margins): Margins {
		return new this(
			-margins.left(),
			-margins.top(),
			-margins.right(),
			-margins.bottom());
	}

	static sub(lhs: Margins, rhs: Margins): Margins;
	static sub(lhs: Margins, rhs: number): Margins;
	static sub(lhs: Margins, rhs: Margins | number): Margins {
		let left: number;
		let top: number;
		let right: number;
		let bottom: number;
		if (rhs instanceof Margins) {
			left = rhs.left();
			top = rhs.top();
			right = rhs.right();
			bottom = rhs.bottom();
		} else {
			left = rhs;
			top = rhs;
			right = rhs;
			bottom = rhs;
		}
		return new this(
			lhs.left() - left,
			lhs.top() - top,
			lhs.right() - right,
			lhs.bottom() - bottom);
	}

	m_left: number;
	m_top: number;
	m_right: number;
	m_bottom: number;

	constructor(left: number = 0, top: number = 0, right: number = 0, bottom: number = 0) {
		this.m_left = Math.round(left);
		this.m_top = Math.round(top);
		this.m_right = Math.round(right);
		this.m_bottom = Math.round(bottom);
	}

	bottom(): number {
		return this.m_bottom;
	}

	iadd(other: Margins | number): this {
		// @ts-ignore
		const t = Margins.add(this, other);
		this.m_left = t.m_left;
		this.m_top = t.m_top;
		this.m_right = t.m_right;
		this.m_bottom = t.m_bottom;
		return this;
	}

	idiv(divisor: number): this {
		const t = Margins.div(this, divisor);
		this.m_left = t.m_left;
		this.m_top = t.m_top;
		this.m_right = t.m_right;
		this.m_bottom = t.m_bottom;
		return this;
	}

	imul(factor: number): this {
		const t = Margins.mul(this, factor);
		this.m_left = t.m_left;
		this.m_top = t.m_top;
		this.m_right = t.m_right;
		this.m_bottom = t.m_bottom;
		return this;
	}

	isNull(): boolean {
		const {m_left, m_top, m_right, m_bottom} = this;
		return (m_left === 0)
			&& (m_top === 0)
			&& (m_right === 0)
			&& (m_bottom === 0);
	}

	isub(other: Margins | number): this {
		// @ts-ignore
		const t = Margins.sub(this, other);
		this.m_left = t.m_left;
		this.m_top = t.m_top;
		this.m_right = t.m_right;
		this.m_bottom = t.m_bottom;
		return this;
	}

	left(): number {
		return this.m_left;
	}

	right(): number {
		return this.m_right;
	}

	setBottom(bottom: number): void {
		this.m_bottom = Math.round(bottom);
	}

	setLeft(left: number): void {
		this.m_left = Math.round(left);
	}

	setRight(right: number): void {
		this.m_right = Math.round(right);
	}

	setTop(top: number): void {
		this.m_top = Math.round(top);
	}

	top(): number {
		return this.m_top;
	}

	toString(): string {
		return `Margins(left: ${this.m_left}, top: ${this.m_top}, right: ${this.m_right}, bottom: ${this.m_bottom})`;
	}
}
