import {Variant} from '../../variant';

export class ItemData {
	role: number;
	value: Variant;

	constructor(role: number, value: Variant);
	constructor();
	constructor(role?: number, value?: Variant) {
		if ((role === undefined) || (value === undefined)) {
			this.role = -1;
			this.value = new Variant();
		} else {
			this.role = role;
			this.value = value;
		}
	}

	eq(other: ItemData): boolean {
		return (this.role === other.role) && this.value.eq(other.value);
	}
}
