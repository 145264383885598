import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../obj';
import {ButtonLayout, ButtonRole, Orientation, StandardButton} from '../constants';
import {AbstractButton} from './abstractbutton';
import {PushButton} from './pushbutton';
import {list} from '../tools';
import {GridLayout} from './gridlayout';
import {getLogger} from '../logging';
import {assert, isNumber} from '../util';
import {Evt} from '../evt';
import {Dialog} from './dialog';

const logger = getLogger('ui.dialoagbuttonbox');

export class DialogButtonBoxPrivate extends ElObjPrivate {
	buttonLayout: GridLayout | null;
	buttonLists: list<list<AbstractButton>>;
	center: boolean;
	internalRemove: boolean;
	layoutPolicy: ButtonLayout;
	orientation: Orientation;
	standardButtonHash: Map<PushButton, StandardButton>;

	constructor() {
		super();
		this.buttonLayout = null;
		this.buttonLists = new list();
		this.center = false;
		this.internalRemove = false;
		this.layoutPolicy = ButtonLayout.Unknown;
		this.orientation = Orientation.Horizontal;
		this.standardButtonHash = new Map();
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			this.buttonLists.append(
				new list(),
			);
		}
	}

	addButton(button: PushButton, role: ButtonRole, doLayout: boolean = true): void {
		const q = this.q;
		Obj.connect(
			button, 'clicked',
			q, '_handleButtonClicked',
		);
		Obj.connect(
			button, 'destroyed',
			q, '_handleButtonDestroyed',
		);
		this.buttonLists.at(role).append(button);
		if (doLayout) {
			this.layoutButtons();
		}
	}

	addButtonsToLayout(buttonList: list<AbstractButton>, reverse: boolean): void {
		const start = reverse ?
			buttonList.size() - 1 :
			0;
		const end = reverse ?
			-1 :
			buttonList.size();
		const step = reverse ?
			-1 :
			1;
		for (let i = start; i !== end; i += step) {
			const button = buttonList.at(i);
			if (this.buttonLayout) {
				this.buttonLayout.addEl(button);
			}
			button.show();
		}
	}

	createButton(sBtn: StandardButton, doLayout: boolean = true): PushButton {
		const btn = new PushButton({
			parent: this.q,
			text: defaultStandardButtonText(sBtn),
		});
		this.standardButtonHash.set(btn, sBtn);
		const role = defaultStandardButtonRole(sBtn);
		if (role === ButtonRole.InvalidRole) {
			logger.warning('createButton: Invalid ButtonRole, button not added.');
		} else {
			this.addButton(btn, role, doLayout);
		}
		return btn;
	}

	createStandardButtons(buttons: StandardButton): void {
		let i = StandardButton.FirstButton;
		while (i <= StandardButton.LastButton) {
			if (i & buttons) {
				this.createButton(i, false);
			}
			i = i << 1;
		}
		this.layoutButtons();
	}

	init(opts: Partial<DialogButtonBoxOpts>): void {
		super.init(opts);
		if (opts.orientation !== undefined) {
			this.orientation = opts.orientation;
		}
	}

	initLayout(): void {
		if (this.buttonLayout) {
			return;
		}
		this.buttonLayout = new GridLayout({
			parent: this.q,
		});
	}

	layoutButtons(): void {
		if (!this.buttonLayout) {
			return;
		}
		for (let i = this.buttonLayout.count() - 1; i >= 0; --i) {
			const el = this.buttonLayout.takeEl(i);
			if (el) {
				el.hide();
				el.destroy();
			}
		}
		const currentLayout = buttonLayouts[0][0]; // Windows, horizontal
		const acceptRoleList = this.buttonLists.at(ButtonRole.AcceptRole);
		let layoutIdx = 0;
		while (currentLayout[layoutIdx] !== ButtonRole.EOL) {
			const role = (currentLayout[layoutIdx] & ~ButtonRole.Reverse);
			const reverse = Boolean(currentLayout[layoutIdx] & ButtonRole.Reverse);
			switch (role) {
				case ButtonRole.Stretch: {
					break;
				}
				case ButtonRole.AcceptRole: {
					if (acceptRoleList.isEmpty()) {
						break;
					}
					// Only the first one
					const btn = acceptRoleList.first();
					this.buttonLayout.addEl(btn);
					btn.show();
					break;
				}
				case ButtonRole.AlternateRole: {
					if (acceptRoleList.size() > 1) {
						this.addButtonsToLayout(
							acceptRoleList.mid(1),
							reverse,
						);
					}
					break;
				}
				case ButtonRole.DestructiveRole: {
					this.addButtonsToLayout(
						this.buttonLists.at(role),
						reverse,
					);
					break;
				}
				case ButtonRole.RejectRole:
				case ButtonRole.ActionRole:
				case ButtonRole.HelpRole:
				case ButtonRole.YesRole:
				case ButtonRole.NoRole:
				case ButtonRole.ApplyRole:
				case ButtonRole.ResetRole: {
					this.addButtonsToLayout(
						this.buttonLists.at(role),
						reverse,
					);
					break;
				}
			}
			++layoutIdx;
		}
	}

	get q(): DialogButtonBox {
		return <DialogButtonBox>super.q;
	}

	resetLayout(): void {
		this.initLayout();
		this.layoutButtons();
	}
}

export interface DialogButtonBoxOpts extends ElObjOpts {
	buttons: StandardButton;
	dd: DialogButtonBoxPrivate;
	orientation: Orientation;
}

@OBJ
export class DialogButtonBox extends ElObj {
	constructor(opts: Partial<DialogButtonBoxOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-dialog-button-box',
		);
		opts.dd = opts.dd || new DialogButtonBoxPrivate();
		opts.orientation = (opts.orientation === undefined) ?
			Orientation.Horizontal :
			opts.orientation;
		super(opts);
		if (opts.buttons !== undefined) {
			opts.dd.createStandardButtons(opts.buttons);
		}
		opts.dd.initLayout();
	}

	@SIGNAL
	protected accepted(): void {
	}

	addButton(text: string, role: ButtonRole): PushButton | null;
	addButton(button: StandardButton): PushButton | null;
	addButton(button: AbstractButton, role: ButtonRole): void;
	addButton(a: AbstractButton | StandardButton | string, role?: ButtonRole): PushButton | null | void {
		const d = this.d;
		if ((a instanceof AbstractButton) && isNumber(role)) {
			// SIG: addButton(button: AbstractButton, role: ButtonRole): void
			if ((role <= ButtonRole.InvalidRole) || (role >= ButtonRole.NRoles)) {
				logger.warning('addButton: Invalid ButtonRole, button not added.');
				return;
			}
			this.removeButton(a);
			a.setParent(this);
			d.addButton(<PushButton>a, role);
		} else if ((typeof a === 'string') && isNumber(role)) {
			// SIG: addButton(text: string, role: ButtonRole): PushButton | null
			if ((role <= ButtonRole.InvalidRole) || (role >= ButtonRole.NRoles)) {
				logger.warning('addButton: Invalid ButtonRole, button not added.');
				return null;
			}
			const button = new PushButton({
				parent: this,
				text: a,
			});
			d.addButton(button, role);
			return button;
		} else {
			// SIG: addButton(button: StandardButton): PushButton | null
			assert(isNumber(a));
			return d.createButton(a);
		}
	}

	button(which: StandardButton): PushButton | null {
		for (const [btn, sBtn] of this.d.standardButtonHash) {
			if (sBtn === which) {
				return btn;
			}
		}
		return null;
	}

	buttonRole(button: AbstractButton): ButtonRole {
		const d = this.d;
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			const lst = d.buttonLists.at(i);
			for (let k = 0; k < lst.size(); ++k) {
				if (lst.at(k) === button) {
					return i;
				}
			}
		}
		return ButtonRole.InvalidRole;
	}

	buttons(): list<AbstractButton> {
		const d = this.d;
		const rv = new list<AbstractButton>();
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			const lst = d.buttonLists.at(i);
			for (let k = 0; k < lst.size(); ++k) {
				rv.append(lst.at(k));
			}
		}
		return rv;
	}

	centerButtons(): boolean {
		return this.d.center;
	}

	clear(): void {
		const d = this.d;
		d.standardButtonHash.clear();
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			const lst = d.buttonLists.at(i);
			while (lst.size() > 0) {
				const btn = lst.takeAt(0);
				Obj.disconnect(
					btn, 'destroyed',
					this, '_handleButtonDestroyed',
				);
				btn.destroy();
			}
		}
	}

	@SIGNAL
	protected clicked(button: AbstractButton): void {
	}

	get d(): DialogButtonBoxPrivate {
		return <DialogButtonBoxPrivate>super.d;
	}

	event(event: Evt): boolean {
		if (event.type() === Evt.Show) {
			const d = this.d;
			const acceptRoleList = d.buttonLists.at(ButtonRole.AcceptRole);
			const firstAcceptButton = (!!acceptRoleList && acceptRoleList.isEmpty()) ?
				null :
				acceptRoleList ?
					acceptRoleList.at(0) :
					null;
			let hasDefault = false;
			let dia: Dialog | null = null;
			let p: ElObj | null = this;
			while (p && p.parentEl()) {
				p = p.parentEl();
				if (p && (p instanceof Dialog)) {
					break;
				}
			}
			const ch = (dia ? dia : this).children();
			const pbs = new list<PushButton>();
			for (const c of ch) {
				if (c instanceof PushButton) {
					pbs.append(c);
				}
			}
			for (const pb of pbs) {
				if (pb.isDefault() && (pb !== firstAcceptButton)) {
					hasDefault = true;
					break;
				}
			}
			if (!hasDefault && firstAcceptButton) {
				(<PushButton>firstAcceptButton).setDefault(true);
			}
		}
		return super.event(event);
	}

	@SLOT
	protected _handleButtonClicked(): void {
		const btn = <AbstractButton | null>this.sender();
		if (!btn) {
			return;
		}
		const role = this.buttonRole(btn);
		this.clicked(btn);
		switch (role) {
			case ButtonRole.AcceptRole:
			case ButtonRole.YesRole: {
				this.accepted();
				break;
			}
			case ButtonRole.RejectRole:
			case ButtonRole.NoRole: {
				this.rejected();
				break;
			}
			case ButtonRole.HelpRole: {
				this.helpRequested();
				break;
			}
		}
	}

	@SLOT
	protected _handleButtonDestroyed(): void {
		const btn = <AbstractButton | null>this.sender();
		if (!btn) {
			return;
		}
		const d = this.d;
		const rem = d.internalRemove;
		d.internalRemove = true;
		this.removeButton(btn);
		d.internalRemove = rem;
	}

	@SIGNAL
	protected helpRequested(): void {
	}

	orientation(): Orientation {
		return this.d.orientation;
	}

	@SIGNAL
	protected rejected(): void {
	}

	removeButton(button: AbstractButton | null): void {
		if (!button) {
			return;
		}
		const d = this.d;
		d.standardButtonHash.delete(<PushButton>button);
		for (let i = 0; i < ButtonRole.NRoles; ++i) {
			const lst = d.buttonLists.at(i);
			for (let k = 0; k < lst.size(); ++k) {
				if (lst.at(k) === button) {
					lst.takeAt(k);
					if (!d.internalRemove) {
						Obj.disconnect(
							button, 'clicked',
							this, '_handleButtonClicked',
						);
						Obj.disconnect(
							button, 'destroyed',
							this, '_handleButtonDestroyed',
						);
					}
					break;
				}
			}
		}
		if (!d.internalRemove) {
			button.setParent(null);
		}
	}

	setCenterButtons(center: boolean): void {
		const d = this.d;
		if (center === d.center) {
			return;
		}
		d.center = center;
		d.resetLayout();
	}

	setOrientation(orient: Orientation): void {
		const d = this.d;
		if (orient === d.orientation) {
			return;
		}
		d.orientation = orient;
		d.resetLayout();
	}

	setStandardButtons(buttons: StandardButton): void {
		const d = this.d;
		for (const obj of d.standardButtonHash.keys()) {
			obj.destroy();
		}
		d.standardButtonHash.clear();
		d.createStandardButtons(buttons);
	}

	standardButton(button: AbstractButton): StandardButton {
		const rv = this.d.standardButtonHash.get(<PushButton>button);
		return (rv === undefined) ?
			StandardButton.NoButton :
			rv;
	}

	standardButtons(): StandardButton {
		const d = this.d;
		let rv = StandardButton.NoButton;
		for (const s of d.standardButtonHash.values()) {
			rv |= s;
		}
		return rv;
	}
}

function defaultStandardButtonRole(sBtn: StandardButton): ButtonRole {
	switch (sBtn) {
		case StandardButton.Ok:
		case StandardButton.Save:
		case StandardButton.Open:
		case StandardButton.SaveAll:
		case StandardButton.Retry:
		case StandardButton.Ignore: {
			return ButtonRole.AcceptRole;
		}
		case StandardButton.Cancel:
		case StandardButton.Close:
		case StandardButton.Abort: {
			return ButtonRole.RejectRole;
		}
		case StandardButton.Discard: {
			return ButtonRole.DestructiveRole;
		}
		case StandardButton.Help: {
			return ButtonRole.HelpRole;
		}
		case StandardButton.Apply: {
			return ButtonRole.ApplyRole;
		}
		case StandardButton.Yes:
		case StandardButton.YesToAll: {
			return ButtonRole.YesRole;
		}
		case StandardButton.No:
		case StandardButton.NoToAll: {
			return ButtonRole.NoRole;
		}
		case StandardButton.RestoreDefaults:
		case StandardButton.Reset: {
			return ButtonRole.ResetRole;
		}
		default:
			return ButtonRole.InvalidRole;
	}
}

function defaultStandardButtonText(button: number): string {
	switch (button) {
		case StandardButton.Ok: {
			return 'OK';
		}
		case StandardButton.Save: {
			return 'Save';
		}
		case StandardButton.SaveAll: {
			return 'Save All';
		}
		case StandardButton.Open: {
			return 'Open';
		}
		case StandardButton.Yes: {
			return 'Yes';
		}
		case StandardButton.YesToAll: {
			return 'Yes to All';
		}
		case StandardButton.No: {
			return 'No';
		}
		case StandardButton.NoToAll: {
			return 'No to All';
		}
		case StandardButton.Abort: {
			return 'Abort';
		}
		case StandardButton.Retry: {
			return 'Retry';
		}
		case StandardButton.Ignore: {
			return 'Ignore';
		}
		case StandardButton.Close: {
			return 'Close';
		}
		case StandardButton.Cancel: {
			return 'Cancel';
		}
		case StandardButton.Discard: {
			return 'Discard';
		}
		case StandardButton.Help: {
			return 'Help';
		}
		case StandardButton.Apply: {
			return 'Apply';
		}
		case StandardButton.Reset: {
			return 'Reset';
		}
		case StandardButton.RestoreDefaults: {
			return 'Restore Defaults';
		}
		default:
			return '';
	}
}

const buttonLayouts = [
	// Horizontal
	[
		[
			// Windows
			ButtonRole.ResetRole,
			ButtonRole.Stretch,
			ButtonRole.YesRole,
			ButtonRole.AcceptRole,
			ButtonRole.AlternateRole,
			ButtonRole.DestructiveRole,
			ButtonRole.NoRole,
			ButtonRole.ActionRole,
			ButtonRole.RejectRole,
			ButtonRole.ApplyRole,
			ButtonRole.HelpRole,
			ButtonRole.EOL,
			ButtonRole.EOL,
			ButtonRole.EOL,
		],
		[
			// Mac
			ButtonRole.HelpRole,
			ButtonRole.ResetRole,
			ButtonRole.ApplyRole,
			ButtonRole.ActionRole,
			ButtonRole.Stretch,
			ButtonRole.DestructiveRole | ButtonRole.Reverse,
			ButtonRole.AlternateRole | ButtonRole.Reverse,
			ButtonRole.RejectRole | ButtonRole.Reverse,
			ButtonRole.AcceptRole | ButtonRole.Reverse,
			ButtonRole.NoRole | ButtonRole.Reverse,
			ButtonRole.YesRole | ButtonRole.Reverse,
			ButtonRole.EOL,
			ButtonRole.EOL,
		],
	],
	// Vertical
	[
		[
			// Windows
			ButtonRole.ActionRole,
			ButtonRole.YesRole,
			ButtonRole.AcceptRole,
			ButtonRole.AlternateRole,
			ButtonRole.DestructiveRole,
			ButtonRole.NoRole,
			ButtonRole.RejectRole,
			ButtonRole.ApplyRole,
			ButtonRole.ResetRole,
			ButtonRole.HelpRole,
			ButtonRole.Stretch,
			ButtonRole.EOL,
			ButtonRole.EOL,
			ButtonRole.EOL,
		],
		[
			// Mac
			ButtonRole.YesRole,
			ButtonRole.NoRole,
			ButtonRole.AcceptRole,
			ButtonRole.RejectRole,
			ButtonRole.AlternateRole,
			ButtonRole.DestructiveRole,
			ButtonRole.Stretch,
			ButtonRole.ActionRole,
			ButtonRole.ApplyRole,
			ButtonRole.ResetRole,
			ButtonRole.HelpRole,
			ButtonRole.EOL,
			ButtonRole.EOL,
		],
	],
];
