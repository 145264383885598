import mapboxgl from 'mapbox-gl';

import {EventThing} from './events';
import {Store} from './store';
import {featuresAtClick} from './featuresat';
import {Mode} from './modes/mode';
import {Feature} from './features/feature';
import {Polygon} from './features/polygon';
import {DrawControl} from '../control';
import {LineString} from './features/linestring';
import {Point as PointFeature} from './features/point';
import {MultiFeature} from './features/multi';
import {SimpleSelect} from './modes/simpleselect';
import {DirectSelect} from './modes/directselect';
import {DrawCircle} from './modes/drawcircle';
import {DrawPoint} from './modes/drawpoint';
import {DrawPolygon} from './modes/drawpolygon';
import {DrawLineString} from './modes/drawlinestring';
import {OBJ, Obj, ObjOpts} from '../../../obj';
import {getLogger} from '../../../logging';
import {Point, set} from '../../../tools';
import {
	MapLayerId,
	MapSourceId,
	MapDrawMode,
} from '../../../constants';
import {
	featureIdString,
	featureLikeToFeatureCollection,
	geoJsonFeatureIdToArray,
	hat,
	isGeoJsonFeatureId,
	isNumber,
} from '../../../util';

interface IMapClass {
	feature: string;
	mode: string;
	mouse: string;
}

interface ModeMaker {
	new(ctx: IMapboxDrawContext): Mode;
}

export interface IMapboxDrawOptions {
	boxSelect: boolean;
	clickBuffer: number;
	controls: IMapboxDrawControls;
	defaultMode: string;
	displayControlsDefault: boolean;
	keybindings: boolean;
	layers: Array<mapboxgl.AnyLayer>;
	modes: {[modeKey: string]: ModeMaker};
	touchBuffer: number;
	touchEnabled: boolean;
	userProperties: boolean;
}

export interface IMapboxDrawContext {
	api: MapboxDraw;
	container: HTMLElement | null;
	events: EventThing;
	map: mapboxgl.Map | null;
	options: IMapboxDrawOptions;
	store: Store;
}

const logger = getLogger('ui.map.draw');

export interface MapboxDrawOpts extends ObjOpts, IMapboxDrawOptions {
}

@OBJ
export class MapboxDraw extends Obj {
	readonly ctx: IMapboxDrawContext;

	constructor(opts: Partial<MapboxDrawOpts> = {}) {
		super(opts);
		const o = setupOptions(opts);
		o.modes = {
			[MapDrawMode.DirectSelect]: DirectSelect,
			[MapDrawMode.DrawCircle]: DrawCircle,
			[MapDrawMode.DrawLineString]: DrawLineString,
			[MapDrawMode.DrawPoint]: DrawPoint,
			[MapDrawMode.DrawPolygon]: DrawPolygon,
			[MapDrawMode.SimpleSelect]: SimpleSelect,
		};
		const events = new EventThing();
		const store = new Store();
		this.ctx = {
			api: this,
			container: null,
			events,
			map: null,
			options: o,
			store,
		};
		store.ctx = this.ctx;
		events.ctx = this.ctx;
	}

	addFeatures<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(features: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | GeoJsonFeature<G, P> | G): Array<string> {
		const rv: Array<string> = [];
		for (const feature of featureLikeToFeatureCollection(features).features) {
			if (!feature.geometry) {
				logger.error('addFeatures: Feature has no geometry.');
				continue;
			}
			if (feature.geometry.type === 'GeometryCollection') {
				logger.error('addFeatures: Feature geometry is GeometryCollection.');
				continue;
			}
			if (isGeoJsonFeatureId(feature.id)) {
				if (isNumber(feature.id)) {
					feature.id = String(feature.id);
				}
			} else {
				feature.id = hat();
			}
			const existing = this.ctx.store.get(feature.id);
			if (existing && (existing.type === feature.geometry.type)) {
				// If a feature of that id has already been created, and we are swapping it out ...
				if (!coordsIsEqual(existing.getCoordinates(), feature.geometry.coordinates)) {
					existing.incomingCoords(feature.geometry.coordinates);
				}
			} else {
				// If the feature has not yet been created ...
				// let Model: FeatureMaker;
				let Model: {new(...args: any): Feature};
				switch (feature.geometry.type) {
					case 'LineString': {
						Model = LineString;
						break;
					}
					case 'MultiLineString':
					case 'MultiPoint':
					case 'MultiPolygon': {
						Model = MultiFeature;
						break;
					}
					case 'Point': {
						Model = PointFeature;
						break;
					}
					case 'Polygon': {
						Model = Polygon;
						break;
					}
				}
				this.ctx.store.add(
					new Model(
						this.ctx,
						<GeoJsonFeature<Exclude<GeoJsonGeometry, GeoJsonGeometryCollection>>>feature,
					),
				);
			}
			rv.push(feature.id);
		}
		this.ctx.store.render();
		return rv;
	}

	addLayers(): void {
		// drawn features style
		const map = this.ctx.map;
		if (map) {
			map.addSource(MapSourceId.DrawCold, {
				data: {
					type: 'FeatureCollection',
					features: [],
				},
				type: 'geojson',
			});
			// hot features style
			map.addSource(MapSourceId.DrawHot, {
				data: {
					type: 'FeatureCollection',
					features: [],
				},
				type: 'geojson',
			});
			this.ctx.options.layers.forEach(style => map.addLayer(style));
		}
		this.ctx.store.setDirty(true);
		this.ctx.store.render();
	}

	allFeatures<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
		return {
			features: this.ctx.store.getAll()
				.map(
					feature => feature.toGeoJSON<G, P>(),
				),
			type: 'FeatureCollection',
		};
	}

	clearMapClasses(): void {
		if (!this.ctx.container) {
			return;
		}
		for (const type of <['mode', 'feature', 'mouse']>['mode', 'feature', 'mouse']) {
			for (const className of this.ctx.container.classList) {
				if (className.startsWith(`${type}-`)) {
					this.ctx.container.classList.remove(
						className,
					);
				}
			}
		}
	}

	deleteAllFeatures(): void {
		this.ctx.store.delete(
			this.ctx.store.getAllIds(),
			{
				silent: true,
			},
		);
	}

	deleteFeatures(featureIds: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): void {
		this.ctx.store.delete(
			Array.from(
				isGeoJsonFeatureId(featureIds) ?
					[
						featureIds,
					] :
					featureIds,
			).map(
				x => featureIdString(x),
			),
			{
				silent: true,
			},
		);
	}

	destroy(): void {
		this.clearMapClasses();
		if (this.ctx.map) {
			this.restoreMapConfig(this.ctx.map);
		}
		// Stop connect attempt in the event that control is removed before
		// map is loaded
		this.removeLayers();
		this.ctx.map = null;
		this.ctx.container = null;
		this.ctx.store.destroy();
	}

	feature<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(featureId: GeoJsonFeatureId): GeoJsonFeature<G, P> | null {
		const feature = this.ctx.store.get(
			featureIdString(featureId),
		);
		return feature && feature.toGeoJSON<G, P>();
	}

	features<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(featureIds: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): GeoJsonFeatureCollection<G, P> {
		const ids = new set(isGeoJsonFeatureId(featureIds) ?
			[featureIds] :
			Array.from(featureIds));
		const features: Array<GeoJsonFeature<G, P>> = [];
		for (const feat of this.ctx.store.getAll()) {
			if (ids.has(feat.id)) {
				features.push(feat.toGeoJSON<G, P>());
			}
		}
		return {
			features,
			type: 'FeatureCollection',
		};
	}

	featuresAt<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(point: Point): GeoJsonFeatureCollection<G, P> {
		const features: Array<GeoJsonFeature<G, P>> = [];
		const featMap = new Map<string, Feature>(
			this.ctx.store.getAll()
				.map(x => ([x.id, x])),
		);
		for (const feat of featuresAtClick(point, null, this.ctx)) {
			// NB: Are you having problems?
			//     You just guessed that feat.properties.id is the ID found in
			//     the store. So the statement
			//     `featMap.has(feat.properties.id)` may be the source of your
			//     problems if your guess was incorrect.
			if (feat.properties && (feat.properties.id !== undefined)) {
				const f = featMap.get(feat.properties.id);
				if (f) {
					features.push(f.toGeoJSON<G, P>());
				}
			}
		}
		return {
			features,
			type: 'FeatureCollection',
		};
	}

	mode(): MapDrawMode {
		return this.ctx.events.currentModeName();
	}

	private removeLayers() {
		const map = this.ctx.map;
		if (map) {
			const styles = this.ctx.options.layers;
			for (let i = 0; i < styles.length; ++i) {
				const style = styles[i];
				if (map.getLayer(style.id)) {
					map.removeLayer(style.id);
				}
			}
			if (map.getSource(MapSourceId.DrawCold)) {
				map.removeSource(MapSourceId.DrawCold);
			}
			if (map.getSource(MapSourceId.DrawHot)) {
				map.removeSource(MapSourceId.DrawHot);
			}
		}
	}

	private restoreMapConfig(map: mapboxgl.Map): void {
		this.ctx.store.restoreMapConfig(map);
	}

	selectedFeatures<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
		return {
			features: this.ctx.store.getSelected()
				.map(feat => feat.toGeoJSON<G, P>()),
			type: 'FeatureCollection',
		};
	}

	setFeatures<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(features: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | GeoJsonFeature<G, P>): Array<string> {
		const batch = this.ctx.store.createRenderBatch();
		const toDelete = this.ctx.store.getAllIds().slice();
		const newIds = this.addFeatures(features);
		const newIdsLookup = new Set(newIds);
		this.deleteFeatures(
			toDelete.filter(
				id => !newIdsLookup.has(id),
			),
		);
		batch();
		return newIds;
	}

	setMapClasses(options: Partial<IMapClass>): void {
		if (!this.ctx.container) {
			return;
		}
		for (const type of <['mode', 'feature', 'mouse']>['mode', 'feature', 'mouse']) {
			if (!options[type]) {
				continue;
			}
			this.ctx.container.classList.add(
				`${type}-${options[type]}`,
			);
		}
	}

	setMode(mode: MapDrawMode, cfg: Partial<DrawModeCfg> = {}): void {
		const par = this.parent();
		if (par && (par instanceof DrawControl)) {
			par.setMode(mode, cfg);
		}
	}

	setSelectedFeatures(featureIds: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): void {
		// If we are changing the selection within simple select mode,
		// just change the selection instead of stopping and re-starting
		// the mode.
		//
		// Otherwise just call to change the mode with the given feature
		// IDs where they will be selected upon mode activation.
		const stringIds = geoJsonFeatureIdToArray(featureIds)
			.map(x => featureIdString(x));
		if (this.mode() === MapDrawMode.SimpleSelect) {
			const s1 = new set(stringIds);
			const s2 = new set(this.ctx.store.getSelectedIds());
			if (s1.eq(s2)) {
				return;
			}
			this.ctx.store.setSelected(
				stringIds,
				{silent: false},
			);
			this.ctx.store.render();
		}
	}
}

const staticDefaultControls: IMapboxDrawControls = Object.freeze({
	circle: true,
	combine_features: true,
	line_string: true,
	point: true,
	polygon: true,
	trash: true,
	uncombine_features: true,
});

function addSources(layers: Array<mapboxgl.AnyLayer>, sourceBucket: string): Array<mapboxgl.AnyLayer> {
	return layers.map(lyr => {
		if ((lyr.type === 'custom') || !!lyr.source) {
			return lyr;
		}
		return {
			...lyr,
			id: `${lyr.id}.${sourceBucket}`,
			source: (sourceBucket === 'hot') ?
				MapSourceId.DrawHot :
				MapSourceId.DrawCold,
		};
	});
}

function setupOptions(options?: Partial<IMapboxDrawOptions>): IMapboxDrawOptions {
	options = {...(options || {})};
	const controls: IMapboxDrawControls = options.controls || {...staticDefaultControls};
	if (((typeof options.displayControlsDefault === 'boolean') && !options.displayControlsDefault)) {
		controls.combine_features = false;
		controls.line_string = false;
		controls.point = false;
		controls.polygon = false;
		controls.trash = false;
		controls.uncombine_features = false;
	}
	const rv: IMapboxDrawOptions = {
		...staticDefaultOptions,
		controls,
		...options,
	};
	rv.layers = addSources(
		rv.layers,
		'cold',
	).concat(
		addSources(
			rv.layers,
			'hot',
		),
	);
	return rv;
}

const layers: Array<mapboxgl.AnyLayer> = [
	{
		'id': MapLayerId.DrawPolygonFillInactive,
		'type': 'fill',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Polygon'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'fill-color': '#3bb2d0',
			'fill-outline-color': '#3bb2d0',
			'fill-opacity': 0.1,
		},
	},
	{
		'id': MapLayerId.DrawPolygonFillActive,
		'type': 'fill',
		'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
		'paint': {
			'fill-color': '#fbb03b',
			'fill-outline-color': '#fbb03b',
			'fill-opacity': 0.1,
		},
	},
	{
		'id': MapLayerId.DrawPolygonMidpoint,
		'type': 'circle',
		'filter': [
			'all',
			['==', '$type', 'Point'],
			['==', 'meta', 'midpoint'],
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#fbb03b',
		},
	},
	{
		'id': MapLayerId.DrawPolygonStrokeInactive,
		'type': 'line',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Polygon'],
			['!=', 'mode', 'static'],
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#3bb2d0',
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.DrawPolygonStrokeActive,
		'type': 'line',
		'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#fbb03b',
			'line-dasharray': [0.2, 2],
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.DrawLineInactive,
		'type': 'line',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'LineString'],
			['!=', 'mode', 'static'],
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#3bb2d0',
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.DrawLineActive,
		'type': 'line',
		'filter': [
			'all',
			['==', '$type', 'LineString'],
			['==', 'active', 'true'],
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#fbb03b',
			'line-dasharray': [0.2, 2],
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.DrawPolygonAndLineVertexStrokeInactive,
		'type': 'circle',
		'filter': [
			'all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#fff',
		},
	},
	{
		'id': MapLayerId.DrawPolygonAndLineVertexInactive,
		'type': 'circle',
		'filter': [
			'all',
			['==', 'meta', 'vertex'],
			['==', '$type', 'Point'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#fbb03b',
		},
	},
	{
		'id': MapLayerId.DrawPointPointStrokeInactive,
		'type': 'circle',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'circle-radius': 5,
			'circle-opacity': 1,
			'circle-color': '#fff',
		},
	},
	{
		'id': MapLayerId.DrawPointInactive,
		'type': 'circle',
		'filter': [
			'all',
			['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', 'meta', 'feature'],
			['!=', 'mode', 'static'],
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#3bb2d0',
		},
	},
	{
		'id': MapLayerId.DrawPointStrokeActive,
		'type': 'circle',
		'filter': [
			'all',
			['==', '$type', 'Point'],
			['==', 'active', 'true'],
			['!=', 'meta', 'midpoint'],
		],
		'paint': {
			'circle-radius': 7,
			'circle-color': '#fff',
		},
	},
	{
		'id': MapLayerId.DrawPointActive,
		'type': 'circle',
		'filter': [
			'all',
			['==', '$type', 'Point'],
			['!=', 'meta', 'midpoint'],
			['==', 'active', 'true'],
		],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#fbb03b',
		},
	},
	{
		'id': MapLayerId.DrawPolygonFillStatic,
		'type': 'fill',
		'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
		'paint': {
			'fill-color': '#404040',
			'fill-outline-color': '#404040',
			'fill-opacity': 0.1,
		},
	},
	{
		'id': MapLayerId.DrawPolygonStrokeStatic,
		'type': 'line',
		'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#404040',
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.DrawLineStatic,
		'type': 'line',
		'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round',
		},
		'paint': {
			'line-color': '#404040',
			'line-width': 2,
		},
	},
	{
		'id': MapLayerId.DrawPointStatic,
		'type': 'circle',
		'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
		'paint': {
			'circle-radius': 5,
			'circle-color': '#404040',
		},
	},
];

const staticDefaultOptions: IMapboxDrawOptions = Object.freeze({
	boxSelect: true,
	clickBuffer: 2,
	controls: staticDefaultControls,
	defaultMode: MapDrawMode.SimpleSelect,
	displayControlsDefault: true,
	keybindings: true,
	modes: {},
	layers: layers,
	touchBuffer: 25,
	touchEnabled: true,
	userProperties: false,
});

type _Coords = GeoJsonPosition | GeoJsonPosition[] | GeoJsonPosition[][] | GeoJsonPosition[][][];

function coordsIsEqual(a: _Coords, b: _Coords): boolean {
	if (a.length !== b.length) {
		return false;
	}
	if (a.length === 0) {
		return true;
	}
	// a, b have length >= 1
	if (Array.isArray(a[0])) {
		if (!Array.isArray(b[0])) {
			return false;
		}
		// ring/line
		return coordsIsEqual(a[0], b[0]);
	} else {
		// coords
		for (let i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) {
				return false;
			}
		}
	}
	return true;
}
