import {MDCSelect, MDCSelectEvent} from '@material/select';

import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ToolButton} from '../toolbutton';
import {Icon} from '../icon';
import {WhichPage} from '../../constants';
import {Action} from '../../action';
import {getLogger} from '../../logging';
import {list} from '../../tools';
import {isNumber} from '../../util';
import {FancyList, FancyListItem} from '../list';

const logger = getLogger('ui.datatable.pagination');
const ctrlEventType = 'MDCSelect:change';

export class PaginationPrivate extends ElObjPrivate {
	static defaultLabel: string = 'Rows per page';

	axnMap: Map<Action, WhichPage>;
	ctrl: MDCSelect | null;
	label: ElObj | null;
	select: Select | null;
	total: ElObj | null;

	constructor() {
		super();
		this.axnMap = new Map();
		this.ctrl = null;
		this.label = null;
		this.select = null;
		this.total = null;
	}

	init(opts: Partial<PaginationOpts>): void {
		super.init(opts);
		const q = this.q;
		const trailing = new ElObj({
			classNames: [
				'mdc-data-table__pagination-trailing',
			],
			parent: q,
		});
		const perPage = new ElObj({
			classNames: [
				'mdc-data-table__pagination-rows-per-page',
			],
			parent: trailing,
		});
		this.label = new ElObj({
			classNames: [
				'mdc-data-table__pagination-rows-per-page-label',
			],
			parent: perPage,
		});
		this.label.setText(
			(<typeof PaginationPrivate>this.constructor).defaultLabel,
		);
		this.select = new Select({
			parent: perPage,
		});
		const nav = new ElObj({
			classNames: [
				'mdc-data-table__pagination-navigation',
			],
			parent: trailing,
		});
		this.total = new ElObj({
			classNames: [
				'mdc-data-table__pagination-total',
			],
			parent: nav,
		});
		const firstPageBtn = new ToolButton({
			attributes: [
				['data-first-page', 'true'],
			],
			classNames: [
				'mdc-data-table__pagination-button',
			],
			parent: nav,
		});
		const firstPageAxn = new Action(
			new Icon({
				name: 'first_page',
			}),
		);
		this.axnMap.set(
			firstPageAxn,
			WhichPage.FirstPage,
		);
		firstPageBtn.setDefaultAction(firstPageAxn);
		Obj.connect(
			firstPageBtn, 'triggered',
			q, '_navTriggered',
		);
		const prevPageBtn = new ToolButton({
			attributes: [
				['data-prev-page', 'true'],
			],
			classNames: [
				'mdc-data-table__pagination-button',
			],
			parent: nav,
		});
		const prevPageAxn = new Action(
			new Icon({
				name: 'chevron_left',
			}),
		);
		this.axnMap.set(
			prevPageAxn,
			WhichPage.PrevPage,
		);
		prevPageBtn.setDefaultAction(prevPageAxn);
		Obj.connect(
			prevPageBtn, 'triggered',
			q, '_navTriggered',
		);
		const nextPageBtn = new ToolButton({
			attributes: [
				['data-next-page', 'true'],
			],
			classNames: [
				'mdc-data-table__pagination-button',
			],
			parent: nav,
		});
		const nextPageAxn = new Action(
			new Icon({
				name: 'chevron_right',
			}),
		);
		this.axnMap.set(
			nextPageAxn,
			WhichPage.NextPage,
		);
		nextPageBtn.setDefaultAction(nextPageAxn);
		Obj.connect(
			nextPageBtn, 'triggered',
			q, '_navTriggered',
		);
		const lastPageBtn = new ToolButton({
			attributes: [
				['data-last-page', 'true'],
			],
			classNames: [
				'mdc-data-table__pagination-button',
			],
			parent: nav,
		});
		const lastPageAxn = new Action(
			new Icon({
				name: 'last_page',
			}),
		);
		this.axnMap.set(
			lastPageAxn,
			WhichPage.LastPage,
		);
		lastPageBtn.setDefaultAction(lastPageAxn);
		Obj.connect(
			lastPageBtn, 'triggered',
			q, '_navTriggered',
		);
		q.addEventListener(ctrlEventType);
		this.ctrl = new MDCSelect(q.element());
	}

	get q(): Pagination {
		return <Pagination>super.q;
	}
}

export interface PaginationOpts extends ElObjOpts {
	dd: PaginationPrivate;
}

@OBJ
export class Pagination extends ElObj {
	constructor(opts: Partial<PaginationOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__pagination',
		);
		opts.dd = opts.dd || new PaginationPrivate();
		super(opts);
	}

	addPerPageOption(opt: number | string): void {
		const d = this.d;
		if (d.select) {
			d.select.addOption(opt);
		}
	}

	addPerPageOptions(opts: Iterable<number | string>): void {
		const d = this.d;
		if (!d.select) {
			return;
		}
		const o = (typeof opts === 'string') ?
			[opts] :
			opts;
		for (const opt of o) {
			d.select.addOption(opt);
		}
	}

	get d(): PaginationPrivate {
		return <PaginationPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		this.removeEventListener(ctrlEventType);
		if (d.ctrl) {
			d.ctrl.destroy();
		}
		d.ctrl = null;
		d.axnMap.clear();
		d.label = null;
		d.select = null;
		d.total = null;
		super.destroy();
	}

	protected _domMdcEvent(event: Event): void {
		const d = this.d;
		if (!d.select) {
			return;
		}
		if ((event.eventPhase === Event.AT_TARGET) && (event.type === ctrlEventType)) {
			const dt = (<MDCSelectEvent>event).detail;
			if (!isNumber(dt.index)) {
				return;
			}
			this.perPageChanged(
				dt.index,
			);
		}
	}

	protected _emitClicked(which: WhichPage): void {
		this.navigationClicked(which);
		switch (which) {
			case WhichPage.FirstPage: {
				this.firstPageClicked();
				break;
			}
			case WhichPage.PrevPage: {
				this.previousPageClicked();
				break;
			}
			case WhichPage.NextPage: {
				this.nextPageClicked();
				break;
			}
			case WhichPage.LastPage: {
				this.lastPageClicked();
				break;
			}
		}
	}

	@SIGNAL
	protected firstPageClicked(): void {
	}

	hasNextPage(): boolean {
		const d = this.d;
		for (const [axn, which] of d.axnMap) {
			if (which === WhichPage.NextPage) {
				return axn.isEnabled();
			}
		}
		return false;
	}

	hasPreviousPage(): boolean {
		const d = this.d;
		for (const [axn, which] of d.axnMap) {
			if (which === WhichPage.PrevPage) {
				return axn.isEnabled();
			}
		}
		return false;
	}

	label(): string {
		const d = this.d;
		return d.label ?
			d.label.text() :
			'';
	}

	@SIGNAL
	protected lastPageClicked(): void {
	}

	@SIGNAL
	protected navigationClicked(which: WhichPage): void {
	}

	@SLOT
	protected _navTriggered(action: Action): void {
		for (const [axn, which] of this.d.axnMap) {
			if (axn === action) {
				this._emitClicked(which);
				return;
			}
		}
		logger.warning(
			action ?
				'_navTriggered: action not recognized.' :
				'_navTriggered: action is null.',
		);
	}

	@SIGNAL
	protected nextPageClicked(): void {
	}

	@SIGNAL
	protected perPageChanged(index: number): void {
	}

	perPageOption(index: number): string {
		const opts = this.perPageOptions();
		if ((index >= 0) && (index < opts.size())) {
			return opts.at(index);
		}
		return '';
	}

	perPageOptionCount(): number {
		const d = this.d;
		return d.select ?
			d.select.count() :
			0;
	}

	perPageOptionIndex(opt: number | string): number {
		const s = String(opt);
		const opts = this.perPageOptions();
		for (let i = 0; i < opts.size(); ++i) {
			if (opts.at(i) === s) {
				return i;
			}
		}
		return -1;
	}

	perPageOptions(): list<string> {
		const d = this.d;
		return d.select ?
			d.select.options() :
			new list();
	}

	@SIGNAL
	protected previousPageClicked(): void {
	}

	removePerPageOption(opt: number | string): void {
		const d = this.d;
		if (d.select) {
			d.select.removeOption(opt);
		}
	}

	selectedPerPageOptionIndex(): number {
		const d = this.d;
		return d.select ?
			d.select.selectedIndex() :
			-1;
	}

	setHasNextPage(has: boolean): void {
		const d = this.d;
		for (const [axn, which] of d.axnMap) {
			if ((which === WhichPage.NextPage) || (which === WhichPage.LastPage)) {
				axn.setEnabled(has);
			}
		}
	}

	setHasPreviousPage(has: boolean): void {
		const d = this.d;
		for (const [axn, which] of d.axnMap) {
			if ((which === WhichPage.PrevPage) || (which === WhichPage.FirstPage)) {
				axn.setEnabled(has);
			}
		}
	}

	setLabel(label: string): void {
		const d = this.d;
		if (d.label) {
			d.label.setText(label);
		}
	}

	setPerPageSelectText(text: string): void {
		const d = this.d;
		if (d.label) {
			d.label.setText(text);
		}
	}

	setSelectedPerPageOption(index: number): void {
		const d = this.d;
		if (d.select) {
			d.select.setSelectedIndex(index);
		}
	}

	setTotalText(text: string): void {
		const d = this.d;
		if (d.total) {
			d.total.setText(text);
		}
	}

	totalText(): string {
		const d = this.d;
		return d.total ?
			d.total.text() :
			'';
	}
}

class SelectPrivate extends ElObjPrivate {
	countList: FancyList | null;
	ctrl: MDCSelect | null;

	constructor() {
		super();
		this.countList = null;
		this.ctrl = null;
	}

	addListOption(value: number | string, opt: FancyListItem): void {
		if (!this.countList) {
			return;
		}
		const s = String(value);
		opt.setAttribute([
			['role', 'option'],
			['data-value', s],
		]);
		opt.setText(s);
		this.countList.addItem(opt);
	}

	init(opts: Partial<SelectOpts>): void {
		super.init(opts);
		const q = this.q;
		const anchor = new ElObj({
			attributes: [
				['role', 'button'],
				['aria-haspopup', 'listbox'],
				['aria-labelledby', 'id_lb-pagination-selected-text'],
				['tabindex', '0'],
			],
			classNames: [
				'mdc-select__anchor',
			],
			parent: q,
		});
		const selectedTextCont = new ElObj({
			classNames: [
				'mdc-select__selected-text-container',
			],
			parent: anchor,
		});
		const selectedText = new ElObj({
			attributes: [
				['id', 'id_lb-pagination-selected-text'],
			],
			classNames: [
				'mdc-select__selected-text',
			],
			parent: selectedTextCont,
			tagName: 'span',
		});
		const dropDownIcon = new ElObj({
			classNames: [
				'mdc-select__dropdown-icon',
			],
			parent: anchor,
		});
		const iconGraphic = new ElObj({
			attributes: [
				['viewBox', '7 10 10 5'],
			],
			classNames: [
				'mdc-select__dropdown-icon-graphic',
			],
			namespace: 'http://www.w3.org/2000/svg',
			parent: dropDownIcon,
			tagName: 'svg',
		});
		new ElObj({
			attributes: [
				['stroke', 'none'],
				['fill-rule', 'evenodd'],
				['points', '7 10 12 15 17 10'],
			],
			classNames: [
				'mdc-select__dropdown-icon-inactive',
			],
			namespace: 'http://www.w3.org/2000/svg',
			parent: iconGraphic,
			tagName: 'polygon',
		});
		new ElObj({
			attributes: [
				['stroke', 'none'],
				['fill-rule', 'evenodd'],
				['points', '7 15 12 10 17 15'],
			],
			classNames: [
				'mdc-select__dropdown-icon-active',
			],
			namespace: 'http://www.w3.org/2000/svg',
			parent: iconGraphic,
			tagName: 'polygon',
		});
		const notch = new ElObj({
			classNames: [
				'mdc-notched-outline',
				'mdc-notched-outline--notched',
			],
			parent: anchor,
		});
		new ElObj({
			classNames: [
				'mdc-notched-outline__leading',
			],
			parent: notch,
			tagName: 'span',
		});
		new ElObj({
			classNames: [
				'mdc-notched-outline__trailing',
			],
			parent: notch,
			tagName: 'span',
		});
		const menu = new ElObj({
			attributes: [
				['role', 'listbox'],
			],
			classNames: [
				'mdc-select__menu',
				'mdc-menu',
				'mdc-menu-surface',
				'mdc-menu-surface--fullwidth',
			],
			parent: q,
		});
		// NB: ListItems should have `role="option"` and `data-value="x"`
		//     where `x` is per page count option
		this.countList = new FancyList({
			parent: menu,
		});
		this.ctrl = new MDCSelect(q.element());
	}

	get q(): Select {
		return <Select>super.q;
	}

	selectedIndex(): number {
		return this.ctrl ?
			this.ctrl.selectedIndex :
			-1;
	}

	setSelectedIndex(index: number): void {
		if ((index < 0) || (index >= this.q.options().size()) || !this.ctrl || (this.ctrl.selectedIndex === index)) {
			return;
		}
		this.ctrl.selectedIndex = index;
	}
}

interface SelectOpts extends ElObjOpts {
	dd: SelectPrivate;
}

@OBJ
class Select extends ElObj {
	constructor(opts: Partial<SelectOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-select',
			'mdc-select--outlined',
			'mdc-select--no-label',
			'mdc-data-table__pagination-rows-per-page-select',
		);
		opts.dd = opts.dd || new SelectPrivate();
		super(opts);
	}

	addOption(value: number | string): void {
		this.d.addListOption(
			value,
			new FancyListItem(),
		);
	}

	count(): number {
		const d = this.d;
		return d.countList ?
			d.countList.count() :
			0;
	}

	get d(): SelectPrivate {
		return <SelectPrivate>super.d;
	}

	option(index: number): string {
		const d = this.d;
		if (d.countList) {
			const item = d.countList.item(index);
			if (item) {
				return item.attribute('data-value') || '';
			}
		}
		return '';
	}

	options(): list<string> {
		const d = this.d;
		const rv = new list<string>();
		if (d.countList) {
			for (let i = 0; i < d.countList.count(); ++i) {
				const item = d.countList.item(i);
				if (item) {
					rv.append(item.text());
				}
			}
		}
		return rv;
	}

	removeOption(value: number | string): void {
		const d = this.d;
		if (!d.countList) {
			return;
		}
		const s = String(value);
		for (const child of d.countList.children()) {
			if (child.attribute('data-value') === s) {
				child.destroy();
				return;
			}
		}
	}

	selectedIndex(): number {
		return this.d.selectedIndex();
	}

	setSelectedIndex(index: number): void {
		this.d.setSelectedIndex(index);
	}
}
