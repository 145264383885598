import {svc} from '../request';
import {getLogger} from '../logging';
import {isGeoJsonGeometry, isNumber} from '../util';
import {ODataUnboundFunction} from '../constants';
import {Edmx} from './csdl';

const logger = getLogger('odata.service');

const entKeyIntPatt = /([\w.]+)\((\d+)\)/i;
const entKeyStrPatt = /([\w.]+)\('([\w-]+)'\)/i;
type ParsedEntityPath = {name: string; qualifiedName: string; key: number | string};

export async function doAreaPkStuff(z: number, x: number, y: number): Promise<Array<string>> {
	const sig = `Fociis.OData.Model.public.area_pk_tile(z=${z}, x=${x}, y=${y})`;
	const resp = await svc.od.invokeFunction(sig);
	return <Array<string>>resp.data;
}

export async function invokeUnboundFunction(od: Edmx, functionName: ODataUnboundFunction.Geom, qualifiedEntityTypeName: string, entityKey: number | string): Promise<GeoJsonMultiPolygon | null>;
export async function invokeUnboundFunction(od: Edmx, functionName: ODataUnboundFunction.LookupAssoc, qualifiedEntityTypeName: string, entityKey: number | string): Promise<string | null>;
export async function invokeUnboundFunction(od: Edmx, functionName: ODataUnboundFunction.RecordSkip, qualifiedEntityTypeName: string, entityKey: number | string, top: number): Promise<number | null>;
export async function invokeUnboundFunction(od: Edmx, functionName: ODataUnboundFunction, qualifiedEntityTypeName: string, entityKey: number | string, top?: number): Promise<GeoJsonMultiPolygon | number | string | null> {
	const fi = od.functionImport(functionName);
	if (!fi) {
		logger.warning('invokeUnboundFunction: FunctionImport "%s" was not found', functionName);
		return null;
	}
	const f = od.function(fi.function);
	if (!f) {
		logger.warning('invokeUnboundFunction: Function "%s" was not found', fi.function);
		return null;
	}
	if (f.parameterList.size() !== 1) {
		logger.warning('invokeUnboundFunction: Function "%s" has %s parameters. Expected exactly 1 parameter.', fi.function, f.parameterList.size());
		return null;
	}
	const param = f.parameterList.first();
	const arg = isNumber(entityKey) ?
		entityKey :
		`'${entityKey}'`;
	const path = `${qualifiedEntityTypeName}(${arg})`;
	const sig = `${fi.function}(${param.name}=${path})`;
	let qry: Partial<IODataSystemQuery> | undefined = undefined;
	if (functionName === ODataUnboundFunction.RecordSkip) {
		if (!isNumber(top)) {
			logger.error('invokeUnboundFunction: Argument `top` must be supplied to invoke Function "%s"', fi.function);
			return null;
		}
		qry = {
			top,
		};
	}
	const resp = await svc.od.invokeFunction(
		sig,
		qry,
	);
	const data = resp.data;
	let expectTyp: string;
	let got: any = data;
	switch (functionName) {
		case ODataUnboundFunction.AreaPkTile: {
			expectTyp = '';
			return null;
		}
		case ODataUnboundFunction.LookupAssoc: {
			if (typeof data === 'string') {
				return data;
			}
			expectTyp = 'string';
			break;
		}
		case ODataUnboundFunction.RecordSkip: {
			if (isNumber(data)) {
				return data;
			}
			expectTyp = 'number';
			break;
		}
		case ODataUnboundFunction.Geom: {
			if (data === null) {
				return null;
			}
			if (data && isGeoJsonGeometry(data)) {
				if (data.type === 'MultiPolygon') {
					return data;
				}
				got = data.type;
			}
			expectTyp = 'MultiPolygon';
			break;
		}
	}
	logger.error(
		`invokeUnboundFunction: Invalid data type returned. Expected ${expectTyp}, got %s`,
		got,
	);
	return null;
}

export function parseEntitySetPath(path: string): ParsedEntityPath | null {
	let res = entKeyIntPatt.exec(path);
	if (res) {
		const num = Number.parseInt(res[2]);
		if (isNumber(num)) {
			const qn = res[1];
			const parts = qn.split('.');
			const name = parts[parts.length - 1];
			return {
				name,
				qualifiedName: qn,
				key: num,
			};
		}
		return null;
	}
	res = entKeyStrPatt.exec(path);
	if (res) {
		const qn = res[1];
		const parts = qn.split('.');
		const name = parts[parts.length - 1];
		return {
			name,
			qualifiedName: qn,
			key: res[2],
		};
	}
	return null;
}
