import {MDCCircularProgress} from '@material/circular-progress';

import {OBJ} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import {pixelString} from '../../util';

interface SVGElObjOpts extends Partial<ElObjOpts> {
	viewBox?: string;
}

@OBJ
class SVGElObj extends ElObj {
	static tagName: TagName = 'svg';

	constructor(opts: SVGElObjOpts) {
		const attributes = ElObj.mergeAttributes(
			opts.attributes,
		);
		if (opts.viewBox) {
			attributes.push(['viewBox', opts.viewBox]);
		}
		opts.attributes = attributes;
		opts.namespace = 'http://www.w3.org/2000/svg';
		super(opts);
	}
}

interface SVGCircleElObjMinAttrs {
	cx: string;
	cy: string;
	r: string;
	strokeWidth: string;
}

interface SVGCircleElObjAttrs extends SVGCircleElObjMinAttrs {
	strokeDashArray: string;
	strokeDashOffset: string;
}

interface SVGCircleElObjOpts extends Partial<ElObjOpts>, Partial<SVGCircleElObjAttrs> {
}

@OBJ
class SVGCircleElObj extends SVGElObj {
	static tagName: TagName = 'circle';

	constructor(opts: SVGCircleElObjOpts) {
		const attributes = ElObj.mergeAttributes(
			opts.attributes,
		);
		if (opts.cx) {
			attributes.push(['cx', opts.cx]);
		}
		if (opts.cy) {
			attributes.push(['cy', opts.cy]);
		}
		if (opts.r) {
			attributes.push(['r', opts.r]);
		}
		if (opts.strokeDashArray) {
			attributes.push(['stroke-dasharray', opts.strokeDashArray]);
		}
		if (opts.strokeDashOffset) {
			attributes.push(['stroke-dashoffset', opts.strokeDashOffset]);
		}
		if (opts.strokeWidth) {
			attributes.push(['stroke-width', opts.strokeWidth]);
		}
		opts.attributes = attributes;
		super(opts);
	}
}

interface DeterminateCircleGraphicOpts extends SVGElObjOpts {
	circle: SVGCircleElObjOpts;
	track: SVGCircleElObjOpts;
	viewBox: string;
}

@OBJ
class DeterminateCircleGraphic extends SVGElObj {
	constructor(opts: DeterminateCircleGraphicOpts) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-circular-progress__determinate-circle-graphic',
		);
		super(opts);
		let obj = new SVGCircleElObj({
			classNames: [
				'mdc-circular-progress__determinate-track',
			],
			parent: this,
			...(opts.track || {}),
		});
		obj.show();
		obj = new SVGCircleElObj({
			classNames: [
				'mdc-circular-progress__determinate-circle',
			],
			parent: this,
			...(opts.circle || {}),
		});
		obj.show();
	}
}

interface DeterminateContainerOpts extends Partial<ElObjOpts> {
	circleGraphic: {
		circle: SVGCircleElObjOpts;
		track: SVGCircleElObjOpts;
		viewBox: string;
	}
}

@OBJ
class DeterminateContainer extends ElObj {
	constructor(opts: DeterminateContainerOpts) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-circular-progress__determinate-container',
		);
		super(opts);
		const obj = new DeterminateCircleGraphic({
			...(opts.circleGraphic || {}),
			parent: this,
		});
		obj.show();
	}
}

interface IndeterminateCircleGraphicOpts extends SVGElObjOpts {
	circle: SVGCircleElObjOpts;
	viewBox: string;
}

@OBJ
class IndeterminateCircleGraphic extends SVGElObj {
	constructor(opts: IndeterminateCircleGraphicOpts) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-circular-progress__indeterminate-circle-graphic',
		);
		super(opts);
		const obj = new SVGCircleElObj({
			parent: this,
			...(opts.circle || {}),
		});
		obj.show();
	}
}

interface IndeterminateContainerOpts extends Partial<ElObjOpts> {
	circleLeft: IndeterminateCircleGraphicOpts;
	gapPatch: IndeterminateCircleGraphicOpts;
	circleRight: IndeterminateCircleGraphicOpts;
}

@OBJ
class IndeterminateContainer extends ElObj {
	constructor(opts: IndeterminateContainerOpts) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-circular-progress__indeterminate-container',
		);
		opts.tagName = 'div';
		super(opts);
		const spinnerLayer = new ElObj({
			classNames: [
				'mdc-circular-progress__spinner-layer',
			],
			parent: this,
			tagName: 'div',
		});
		spinnerLayer.show();
		const circleLeft = new ElObj({
			classNames: [
				'mdc-circular-progress__circle-clipper',
				'mdc-circular-progress__circle-left',
			],
			parent: spinnerLayer,
			tagName: 'div',
		});
		circleLeft.show();
		let obj = new IndeterminateCircleGraphic({
			...(opts.circleLeft),
			parent: circleLeft,
		});
		obj.show();
		const gapPatch = new ElObj({
			classNames: [
				'mdc-circular-progress__gap-patch',
			],
			parent: spinnerLayer,
			tagName: 'div',
		});
		gapPatch.show();
		obj = new IndeterminateCircleGraphic({
			...(opts.gapPatch),
			parent: gapPatch,
		});
		obj.show();
		const circleRight = new ElObj({
			classNames: [
				'mdc-circular-progress__circle-clipper',
				'mdc-circular-progress__circle-right',
			],
			parent: spinnerLayer,
			tagName: 'div',
		});
		circleRight.show();
		obj = new IndeterminateCircleGraphic({
			...(opts.circleRight),
			parent: circleRight,
		});
		obj.show();
	}
}

type CircularProgressSize = 'small' | 'medium' | 'large';

interface CircularProgressOpts extends ElObjOpts {
	indeterminate: boolean;
	progress: number;
	size: CircularProgressSize;
}

@OBJ
export class CircularProgress extends ElObj {
	private ctrl: MDCCircularProgress;

	constructor(opts: Partial<CircularProgressOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['aria-label', 'progress bar'],
			['aria-valuemin', '0'],
			['aria-valuemax', '1'],
			['role', 'progressbar'],
		);
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-circular-progress',
		);
		const size: CircularProgressSize = opts.size || 'small';
		let cx: string;
		let cy: string;
		let determinateStrokeDashOffset: string;
		let gapPatchStrokeWidth: string;
		let height: number;
		let r: string;
		let strokeDashArray: string;
		let strokeDashOffset: string;
		let strokeWidth: string;
		let viewBox: string;
		let width: number;
		switch (size) {
			case 'small':
				cx = '12';
				cy = '12';
				determinateStrokeDashOffset = '54.978';
				gapPatchStrokeWidth = '2';
				height = 24;
				r = '8.75';
				strokeDashArray = '54.978';
				strokeDashOffset = '27.489';
				strokeWidth = '2.5';
				viewBox = '0 0 24 24';
				width = 24;
				break;
			case 'medium':
				cx = '16';
				cy = '16';
				determinateStrokeDashOffset = '78.54';
				gapPatchStrokeWidth = '2.4';
				height = 36;
				r = '12.5';
				strokeDashArray = '78.54';
				strokeDashOffset = '39.27';
				strokeWidth = '3';
				viewBox = '0 0 32 32';
				width = 36;
				break;
			case 'large':
				cx = '24';
				cy = '24';
				determinateStrokeDashOffset = '113.097';
				gapPatchStrokeWidth = '3.2';
				height = 48;
				r = '18';
				strokeDashArray = '113.097';
				strokeDashOffset = '56.549';
				strokeWidth = '4';
				viewBox = '0 0 48 48';
				width = 48;
				break;
		}
		opts.styles = ElObj.mergeStyles(
			opts.styles,
			['height', pixelString(height)],
			['width', pixelString(width)],
		);
		super(opts);
		let obj = new DeterminateContainer({
			circleGraphic: {
				circle: {
					cx,
					cy,
					r,
					strokeDashArray,
					strokeDashOffset: determinateStrokeDashOffset,
					strokeWidth,
				},
				track: {
					cx,
					cy,
					r,
					strokeWidth,
				},
				viewBox,
			},
			parent: this,
		});
		obj.show();
		obj = new IndeterminateContainer({
			circleLeft: {
				circle: {
					cx,
					cy,
					r,
					strokeDashArray,
					strokeDashOffset,
					strokeWidth,
				},
				viewBox,
			},
			gapPatch: {
				circle: {
					cx,
					cy,
					r,
					strokeDashArray,
					strokeDashOffset,
					strokeWidth: gapPatchStrokeWidth,
				},
				viewBox,
			},
			circleRight: {
				circle: {
					cx,
					cy,
					r,
					strokeDashArray,
					strokeDashOffset,
					strokeWidth,
				},
				viewBox,
			},
			parent: this,
		});
		obj.show();
		this.ctrl = new MDCCircularProgress(this.element());
		if (opts.indeterminate !== undefined) {
			this.setIndeterminate(opts.indeterminate);
		}
		if (opts.progress !== undefined) {
			this.setProgress(opts.progress);
		}
	}

	close(): void {
		this.setOpen(false);
	}

	destroy(): void {
		this.close();
		this.ctrl.destroy();
		super.destroy();
	}

	setIndeterminate(indeterminate: boolean): void {
		this.ctrl.determinate = !indeterminate;
	}

	setOpen(open: boolean): void {
		if (open === this.isVisible()) {
			return;
		}
		if (open) {
			this.ctrl.open();
		} else {
			this.ctrl.close();
		}
	}

	setProgress(aNumberBetweenZeroAndOne: number): void {
		this.ctrl.progress = aNumberBetweenZeroAndOne;
	}
}
