import {Source} from './map';
import {
	GEO_AREA_TILES_URI_PREFIX,
	MapSourceId,
	PARCEL_LAYER_MIN_ZOOM,
} from '../../constants';

export interface ISource {
	id: string;
	source: Source;
}

export const mapSources: Array<ISource> = [
	// NB: DO NOT RE-ORDER
	{
		id: MapSourceId.Parcel,
		source: {
			minzoom: PARCEL_LAYER_MIN_ZOOM,
			promoteId: 'area_pk',
			tiles: [
				`${GEO_AREA_TILES_URI_PREFIX}/{z}/{x}/{y}/`,
			],
			type: 'vector',
		},
	},
	{
		id: MapSourceId.FilterPolygon,
		source: {
			data: {
				features: [],
				type: 'FeatureCollection',
			},
			generateId: true,
			type: 'geojson',
		},
	},
	// {
	// 	id: MapSourceId.FilterPoint,
	// 	source: {
	// 		data: {
	// 			features: [],
	// 			type: 'FeatureCollection',
	// 		},
	// 		generateId: true,
	// 		type: 'geojson',
	// 	},
	// },
	{
		id: MapSourceId.DoNotMailParcel,
		source: {
			data: {
				features: [],
				type: 'FeatureCollection',
			},
			generateId: true,
			type: 'geojson',
		},
	},
	{
		id: MapSourceId.DoNotMailParcelCentroid,
		source: {
			cluster: true,
			clusterMaxZoom: 13,
			clusterRadius: 50,
			data: {
				features: [],
				type: 'FeatureCollection',
			},
			generateId: true,
			type: 'geojson',
		},
	},
	{
		id: MapSourceId.Debug,
		source: {
			data: {
				features: [],
				type: 'FeatureCollection',
			},
			generateId: true,
			type: 'geojson',
		},
	},
];
