import {Size} from './size';
import {Point} from './point';
import type {Margins} from './margins';

export class Rect implements Ieq {
	static eq(a: Rect, b: Rect): boolean {
		return (a.x1 === b.x1) && (a.y1 === b.y1) && (a.x2 === b.x2) && (a.y2 === b.y2);
	}

	static ne(a: Rect, b: Rect): boolean {
		return (a.x1 !== b.x1) || (a.y1 !== b.y1) || (a.x2 !== b.x2) || (a.y2 !== b.y2);
	}

	x1: number;
	y1: number;
	x2: number;
	y2: number;

	constructor(left: number, top: number, width: number, height: number);
	constructor(topLeft: Point, bottomRight: Point);
	constructor(topLeft: Point, size: Size);
	constructor(other: Rect);
	constructor();
	constructor(...args: [number, number, number, number] | [Point, Point] | [Point, Size] | [Rect] | []) {
		let x1: number = 0;
		let y1: number = 0;
		let x2: number = -1;
		let y2: number = -1;
		if (args.length === 4) {
			const [left, top, width, height] = args;
			x1 = left;
			y1 = top;
			x2 = left + width - 1;
			y2 = top + height - 1;
		} else if (args.length === 2) {
			if (args[1] instanceof Point) {
				const [topLeft, bottomRight] = args;
				x1 = topLeft.x();
				y1 = topLeft.y();
				x2 = bottomRight.x();
				y2 = bottomRight.y();
			} else {
				const [topLeft, size] = args;
				x1 = topLeft.x();
				y1 = topLeft.y();
				x2 = topLeft.x() + size.width() - 1;
				y2 = topLeft.y() + size.height() - 1;
			}
		} else if (args.length == 1) {
			const other = args[0];
			x1 = other.x1;
			y1 = other.y1;
			x2 = other.x2;
			y2 = other.y2;
		}
		this.x1 = Math.round(x1);
		this.y1 = Math.round(y1);
		this.x2 = Math.round(x2);
		this.y2 = Math.round(y2);
	}

	adjust(dx1: number, dy1: number, dx2: number, dy2: number): void {
		this.x1 += dx1;
		this.y1 += dy1;
		this.x2 += dx2;
		this.y2 += dy2;
	}

	adjusted(xp1: number, yp1: number, xp2: number, yp2: number): Rect {
		const {x1, y1, x2, y2} = this;
		return new Rect(
			new Point(x1 + xp1, y1 + yp1),
			new Point(x2 + xp2, y2 + yp2));
	}

	and(r: Rect): Rect {
		if (this.isNull() || r.isNull()) {
			return new Rect();
		}
		const {x1, y1, x2, y2} = this;
		let l1 = x1;
		let r1 = x1;
		if ((x2 - x1 + 1) < 0) {
			l1 = x2;
		} else {
			r1 = x2;
		}
		let l2 = r.x1;
		let r2 = r.x1;
		if ((r.x2 - r.x1 + 1) < 0) {
			l2 = r.x2;
		} else {
			r2 = r.x2;
		}
		if ((l1 > r2) || (l2 > r1)) {
			return new Rect();
		}
		let t1 = y1;
		let b1 = y1;
		if ((y2 - y1 + 1) < 0) {
			t1 = y2;
		} else {
			b1 = y2;
		}
		let t2 = r.y1;
		let b2 = r.y1;
		if ((r.y2 - r.y1 + 1) < 0) {
			t2 = r.y2;
		} else {
			b2 = r.y2;
		}
		if ((t1 > b2) || (t2 > b1)) {
			return new Rect();
		}
		const tmp = new Rect();
		tmp.x1 = Math.max(l1, l2);
		tmp.x2 = Math.min(r1, r2);
		tmp.y1 = Math.max(t1, t2);
		tmp.y2 = Math.min(b1, b2);
		return tmp;
	}

	bottom(): number {
		return this.y2;
	}

	bottomLeft(): Point {
		return new Point(this.x1, this.y2);
	}

	bottomRight(): Point {
		return new Point(this.x2, this.y2);
	}

	center(): Point {
		return new Point(Math.round((this.x1 + this.x2) / 2), Math.round((this.y1 + this.y2) / 2));
	}

	contains(other: Rect | Point, proper: boolean = false): boolean {
		return (other instanceof Rect) ?
			this._containsRect(other, proper) :
			this._containsPoint(other, proper);
	}

	eq(other: Rect): boolean {
		return Rect.eq(this, other);
	}

	height(): number {
		return this.y2 - this.y1 + 1;
	}

	iadd(margins: Margins): this {
		const t = this.marginsAdded(margins);
		this.x1 = t.x1;
		this.y1 = t.y1;
		this.x2 = t.x2;
		this.y2 = t.y2;
		return this;
	}

	iand(r: Rect): this {
		const t = this.and(r);
		this.x1 = t.x1;
		this.y1 = t.y1;
		this.x2 = t.x2;
		this.y2 = t.y2;
		return this;
	}

	intersected(r: Rect): Rect {
		return this.and(r);
	}

	intersects(r: Rect): boolean {
		if (this.isNull() || r.isNull()) {
			return false;
		}
		const {x1, y1, x2, y2} = this;
		let l1 = x1;
		let r1 = x1;
		if ((x2 - x1 + 1) < 0) {
			l1 = x2;
		} else {
			r1 = x2;
		}
		let l2 = r.x1;
		let r2 = r.x1;
		if ((r.x2 - r.x1 + 1) < 0) {
			l2 = r.x2;
		} else {
			r2 = r.x2;
		}
		if ((l1 > r2) || (l2 > r1)) {
			return false;
		}
		let t1 = y1;
		let b1 = y1;
		if ((y2 - y1 + 1) < 0) {
			t1 = y2;
		} else {
			b1 = y2;
		}
		let t2 = r.y1;
		let b2 = r.y1;
		if ((r.y2 - r.y1 + 1) < 0) {
			t2 = r.y2;
		} else {
			b2 = r.y2;
		}
		if ((t1 > b2) || (t2 > b1)) {
			return false;
		}
		return true;
	}

	ior(r: Rect): this {
		const t = this.or(r);
		this.x1 = t.x1;
		this.y1 = t.y1;
		this.x2 = t.x2;
		this.y2 = t.y2;
		return this;
	}

	isEmpty(): boolean {
		return (this.x1 > this.x2) || (this.y1 > this.y2);
	}

	isNull(): boolean {
		return (this.x2 === (this.x1 - 1)) && (this.y2 === (this.y1 - 1));
	}

	isub(margins: Margins): this {
		const t = this.marginsRemoved(margins);
		this.x1 = t.x1;
		this.y1 = t.y1;
		this.x2 = t.x2;
		this.y2 = t.y2;
		return this;
	}

	isValid(): boolean {
		return (this.x1 <= this.x2) && (this.y1 <= this.y2);
	}

	left(): number {
		return this.x1;
	}

	marginsAdded(margins: Margins): Rect {
		const {x1, y1, x2, y2} = this;
		return new Rect(
			new Point(x1 - margins.left(), y1 - margins.top()),
			new Point(x2 + margins.right(), y2 + margins.bottom()));
	}

	marginsRemoved(margins: Margins): Rect {
		const {x1, y1, x2, y2} = this;
		return new Rect(
			new Point(x1 + margins.left(), y1 + margins.top()),
			new Point(x2 - margins.right(), y2 - margins.bottom()));
	}

	moveBottom(pos: number): void {
		this.y1 += (pos - this.y2);
		this.y2 = pos;
	}

	moveBottomLeft(p: Point): void {
		this.moveLeft(p.x());
		this.moveBottom(p.y());
	}

	moveBottomRight(p: Point): void {
		this.moveRight(p.x());
		this.moveBottom(p.y());
	}

	moveCenter(p: Point): void {
		const w = this.x2 - this.x1;
		const h = this.y2 - this.y1;
		this.x1 = Math.round(p.x() - w / 2);
		this.y1 = Math.round(p.y() - h / 2);
		this.x2 = this.x1 + w;
		this.y2 = this.y1 + h;
	}

	moveLeft(pos: number): void {
		this.x2 += (pos - this.x1);
		this.x1 = pos;
	}

	moveRight(pos: number): void {
		this.x1 += (pos - this.x2);
		this.x2 = pos;
	}

	moveTo(x: number, y: number): void;
	moveTo(p: Point): void;
	moveTo(...args: [number, number] | [Point]): void {
		let ax: number;
		let ay: number;
		if (args.length === 2) {
			[ax, ay] = args;
		} else {
			const p = args[0];
			ax = p.x();
			ay = p.y();
		}
		this.x2 += (ax - this.x1);
		this.y2 += (ay - this.y1);
		this.x1 = ax;
		this.y1 = ay;
	}

	moveTop(pos: number): void {
		this.y2 += (pos - this.y1);
		this.y1 = pos;
	}

	moveTopLeft(p: Point): void {
		this.moveLeft(p.x());
		this.moveTop(p.y());
	}

	moveTopRight(p: Point): void {
		this.moveRight(p.x());
		this.moveTop(p.y());
	}

	ne(other: Rect): boolean {
		return Rect.ne(this, other);
	}

	normalized(): Rect {
		const {x1, y1, x2, y2} = this;
		const r = new Rect();
		if (x2 < (x1 - 1)) {
			r.x1 = x2;
			r.x2 = x1;
		} else {
			r.x1 = x1;
			r.x2 = x2;
		}
		if (y2 < (y1 - 1)) {
			r.y1 = y2;
			r.y2 = y1;
		} else {
			r.y1 = y1;
			r.y2 = y2;
		}
		return r;
	}

	or(r: Rect): Rect {
		if (this.isNull()) {
			return r;
		}
		if (r.isNull()) {
			return this;
		}
		const {x1, y1, x2, y2} = this;
		let l1 = x1;
		let r1 = x1;
		if ((x2 - x1 + 1) < 0) {
			l1 = x2;
		} else {
			r1 = x2;
		}
		let l2 = r.x1;
		let r2 = r.x1;
		if ((r.x2 - r.x1 + 1) < 0) {
			l2 = r.x2;
		} else {
			r2 = r.x2;
		}
		let t1 = y1;
		let b1 = y1;
		if ((y2 - y1 + 1) < 0) {
			t1 = y2;
		} else {
			b1 = y2;
		}
		let t2 = r.y1;
		let b2 = r.y1;
		if ((r.y2 - r.y1 + 1) < 0) {
			t2 = r.y2;
		} else {
			b2 = r.y2;
		}
		const tmp = new Rect();
		tmp.x1 = Math.min(l1, l2);
		tmp.x2 = Math.max(r1, r2);
		tmp.y1 = Math.min(t1, t2);
		tmp.y2 = Math.max(b1, b2);
		return tmp;
	}

	right(): number {
		return this.x2;
	}

	setBottom(value: number): void {
		this.y2 = Math.round(value);
	}

	setBottomLeft(point: Point): void {
		this.x1 = point.x();
		this.y2 = point.y();
	}

	setBottomRight(point: Point): void {
		this.x2 = point.x();
		this.y2 = point.y();
	}

	setCoords(x1: number, y1: number, x2: number, y2: number): void {
		this.x1 = x1;
		this.y1 = y1;
		this.x2 = x2;
		this.y2 = y2;
	}

	setHeight(value: number): void {
		this.y2 = Math.round(this.y1 + value - 1);
	}

	setLeft(value: number): void {
		this.x1 = Math.round(value);
	}

	setRight(value: number): void {
		this.x2 = Math.round(value);
	}

	setSize(size: Size): void {
		this.x2 = Math.round(size.width() + this.x1 - 1);
		this.y2 = Math.round(size.height() + this.y1 - 1);
	}

	setTop(value: number): void {
		this.y1 = Math.round(value);
	}

	setTopLeft(point: Point): void {
		this.x1 = point.x();
		this.y1 = point.y();
	}

	setTopRight(point: Point): void {
		this.x2 = point.x();
		this.y1 = point.y();
	}

	setWidth(value: number): void {
		this.x2 = Math.round(this.x1 + value - 1);
	}

	setX(value: number): void {
		this.x1 = Math.round(value);
	}

	setY(value: number): void {
		this.y1 = Math.round(value);
	}

	size(): Size {
		return new Size(this.width(), this.height());
	}

	top(): number {
		return this.y1;
	}

	topLeft(): Point {
		return new Point(this.x1, this.y1);
	}

	topRight(): Point {
		return new Point(this.x2, this.y1);
	}

	toString(): string {
		return `Rect(x: ${this.x()}, y: ${this.y()}, width: ${this.width()}, height: ${this.height()})`;
	}

	translate(dx: number, dy: number): void;
	translate(p: Point): void;
	translate(...args: [number, number] | [Point]): void {
		let dx: number;
		let dy: number;
		if (args.length === 2) {
			[dx, dy] = args;
		} else {
			const p = args[0];
			dx = p.x();
			dy = p.y();
		}
		this.x1 += dx;
		this.y1 += dy;
		this.x2 += dx;
		this.y2 += dy;
	}

	translated(dx: number, dy: number): Rect;
	translated(p: Point): Rect;
	translated(...args: [number, number] | [Point]): Rect {
		let dx: number;
		let dy: number;
		if (args.length === 2) {
			[dx, dy] = args;
		} else {
			const p = args[0];
			dx = p.x();
			dy = p.y();
		}
		return new Rect(
			new Point(this.x1 + dx, this.y1 + dy),
			new Point(this.x2 + dx, this.y2 + dy));
	}

	transposed(): Rect {
		return new Rect(this.topLeft(), this.size().transposed());
	}

	united(r: Rect): Rect {
		return this.or(r);
	}

	width(): number {
		return this.x2 - this.x1 + 1;
	}

	x(): number {
		return this.x1;
	}

	y(): number {
		return this.y1;
	}

	private _containsPoint(p: Point, proper: boolean): boolean {
		const {x1, y1, x2, y2} = this;
		let l: number;
		let r: number;
		if (x2 < (x1 - 1)) {
			l = x2;
			r = x1;
		} else {
			l = x1;
			r = x2;
		}
		if (proper) {
			if ((p.x() <= l) || (p.x() >= r)) {
				return false;
			}
		} else {
			if ((p.x() < l) || (p.x() > r)) {
				return false;
			}
		}
		let t: number;
		let b: number;
		if (y2 < (y1 - 1)) {
			t = y2;
			b = y1;
		} else {
			t = y1;
			b = y2;
		}
		if (proper) {
			if ((p.y() <= t) || (p.y() >= b)) {
				return false;
			}
		} else {
			if ((p.y() < t) || (p.y() > b)) {
				return false;
			}
		}
		return true;
	}

	private _containsRect(r: Rect, proper: boolean): boolean {
		if (this.isNull() || r.isNull()) {
			return false;
		}
		const {x1, y1, x2, y2} = this;
		let l1 = x1;
		let r1 = x1;
		if ((x2 - x1 + 1) < 0) {
			l1 = x2;
		} else {
			r1 = x2;
		}
		let l2 = r.x1;
		let r2 = r.x1;
		if ((r.x2 - r.x1 + 1) < 0) {
			l2 = r.x2;
		} else {
			r2 = r.x2;
		}
		if (proper) {
			if ((l2 <= l1) || (r2 >= r1)) {
				return false;
			}
		} else {
			if ((l2 < l1) || (r2 > r1)) {
				return false;
			}
		}
		let t1 = y1;
		let b1 = y1;
		if ((y2 - y1 + 1) < 0) {
			t1 = y2;
		} else {
			b1 = y2;
		}
		let t2 = r.y1;
		let b2 = r.y1;
		if ((r.y2 - r.y1 + 1) < 0) {
			t2 = r.y2;
		} else {
			b2 = r.y2;
		}
		if (proper) {
			if ((t2 <= t1) || (b2 >= b1)) {
				return false;
			}
		} else {
			if ((t2 < t1) || (b2 > b1)) {
				return false;
			}
		}
		return true;
	}
}
