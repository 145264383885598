import geometryCenter from '@turf/center';
import difference from '@turf/difference';
import intersects from '@turf/boolean-intersects';

import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ParcelMap} from './map';
import {DATA_PARCEL_LAYER_MIN_ZOOM, Key, MapControlType, MapLayerId, MapSourceId} from '../../constants';
import {Pk} from './pk';
import {UndoStack} from '../../ui/undostack';
import {svc} from '../../request';
import {uiRepo} from '../../state';
import {geoCoordinateToLngLat, staticInitMapCfg} from '../../ui/map';
import {debounce, featureIdNumber, isNumber, LbFeature, Transform} from '../../util';
import {GeoCoordinate, list, Point, set} from '../../tools';
import {App} from '../../app';
import {UndoView} from './undoview';
import {GeoRectangle} from '../../tools/georectangle';
import {SearchInput} from './searchinput';
import {TextInputIconPosition} from '../../ui/textinput';
import {Drawer} from '../../ui/drawer';
import {FilterBox} from '../../ui/filterbox';
import {getLogger} from '../../logging';
import {ExprTok, FilterMdl, GeoRef, Parcel} from '../../models';
import {Dialog} from '../../ui/dialog';
import {ParcelAction, ParcelInfo} from './parcelinfo';

const logger = getLogger('views.parcel');

export class ParcelViewPrivate extends ElObjPrivate {
	dialog: Dialog | null;
	drawer: Drawer | null;
	editFilter: FilterMdl | null;
	filterBox: FilterBox | null;
	filters: list<FilterMdl>;
	map: ParcelMap | null;
	movingToFilter: FilterMdl | null;
	parcelInfo: ParcelInfo | null;
	pkBs: Pk;
	textInput: SearchInput | null;
	tok: ExprTok;
	undoStack: UndoStack;
	undoView: UndoView | null;

	constructor() {
		super();
		this.dialog = null;
		this.drawer = null;
		this.editFilter = null;
		this.filterBox = null;
		this.filters = new list();
		this.map = null;
		this.movingToFilter = null;
		this.parcelInfo = null;
		this.pkBs = new Pk();
		this.textInput = null;
		this.tok = new ExprTok();
		this.undoStack = new UndoStack();
		this.undoView = null;
	}

	adjustFilterBoxPosition(): void {
		if (!this.filterBox) {
			return;
		}
		const rect = this.filterBox.rect();
		const right = rect.x + rect.width;
		const bottom = rect.y + rect.height;
		let newX: number = rect.x;
		let newY: number = rect.y;
		if (right > window.innerWidth) {
			newX = rect.x - (right - window.innerWidth);
		}
		if ((bottom > window.innerHeight) || (rect.height > window.innerHeight)) {
			newY = rect.y - (bottom - window.innerHeight);
		}
		if ((newX >= 0) && (newY >= 0)) {
			this.q.setFilterBoxPosition(newX, newY);
		}
	}

	centerPoint(geom: GeoJsonGeometry | null): Point | null {
		if (!this.map || !geom || (geom.type === 'GeometryCollection')) {
			return null;
		}
		const p = this.map.project(
			GeoCoordinate.fromPoint(
				geometryCenter(geom).geometry,
			),
		);
		if (!p || p.isNull()) {
			logger.warning('centerPoint: Null coordinate.');
			return null;
		}
		return p;
	}

	async fetchFilters(): Promise<list<FilterMdl>> {
		return new list();
	}

	filterBoxOpenPosition(pos: Point, filterBoxRect: DOMRect): Point {
		let x: number = pos.x();
		let y: number = pos.y();
		if (this.drawer && this.q.isDrawerOpen()) {
			x += this.drawer.rect().width;
		}
		x = Math.max(0, x - Math.floor(filterBoxRect.width / 2)); // Horizontal center
		const windowInnerWidth = window.innerWidth;
		const rightSide = x + filterBoxRect.width;
		if (rightSide > windowInnerWidth) {
			x = windowInnerWidth - filterBoxRect.width;
		}
		y = Math.max(0, y - Math.floor(filterBoxRect.height / 2)); // Vertical center
		const windowInnerHeight = window.innerHeight;
		const bottomSide = y + filterBoxRect.height;
		if (bottomSide > windowInnerHeight) {
			y = windowInnerHeight - filterBoxRect.height;
		}
		return new Point(x, y);
	}

	focusCanvas(): void {
		// FIXME: For reasons I don't yet understand, when the draw control is
		//        activated and is rendered geometry, the document's
		//        focus/active element becomes the <body>. There is likely a
		//        half-decent explanation for this behavior, but I haven't the
		//        time to look into it.
		//        So, for the time-being, we're just
		//        going to manually focus the canvas element after a little
		//        time goes by.
		setTimeout(() => {
			const el = document.querySelector<HTMLCanvasElement>('canvas.mapboxgl-canvas');
			if (el) {
				el.focus();
			}
		}, 33);
	}

	newFilterBoxInstance(): FilterBox {
		return new FilterBox({
			parent: this.q,
			tmpAppendToDocBody: true,
			tok: this.tok,
		});
	}

	newFilterData(data: Partial<INewFilter>): Partial<INewFilter> {
		return data;
	}

	init(opts: Partial<ParcelViewOpts>): void {
		super.init(opts);
		svc.ui.get().then(
			x => this.tok.setTok(
				x.expressions,
			),
		);
		const q = this.q;
		this.pkBs.pkSetter = (pks: Iterable<AreaPk>, selected: boolean) => {
			if (this.map) {
				this.map.setFeatureState(
					pks,
					{selected},
					MapSourceId.Parcel,
					MapLayerId.ParcelSourceArea,
				);
			}
		};
		Obj.connect(
			q, 'filterCreated',
			q, 'addFilter',
		);
		Obj.connect(
			q, 'filterCreated',
			q, 'filterChanged',
		);
		Obj.connect(
			q, 'filterCreated',
			q, 'flyToFilter',
		);
		Obj.connect(
			q, 'filterDeleted',
			q, 'removeFilter',
		);
		Obj.connect(
			q, 'filterRemoved',
			q, 'setCurrentFiltersMapFeatures',
		);
		Obj.connect(
			q, 'queryUpdated',
			q, 'updateMap',
		);
	}

	get q(): ParcelView {
		return <ParcelView>super.q;
	}

	setMapFilterSourceData(filters: Iterable<FilterMdl> | FilterMdl): void {
		if (this.map && this.map.isLoaded()) {
			this.map.setFilterSourceData(filters);
		}
	}
}

export interface ParcelViewOpts extends ElObjOpts {
	dd: ParcelViewPrivate;
}

@OBJ
export class ParcelView extends ElObj {
	private _debouncedUpdatePkBs: GenericDebounced;

	constructor(opts: Partial<ParcelViewOpts> = {}) {
		opts.dd = opts.dd || new ParcelViewPrivate();
		super(opts);
		this._debouncedUpdatePkBs = debounce(
			this._updatePkBs,
			200,
		);
		this.addDocumentEventListener('keydown');
		this.addWindowEventListener('resize');
	}

	@SLOT
	addFilter(filter: FilterMdl): void {
		const d = this.d;
		if (d.filters.contains(filter)) {
			logger.warning('addFilter: Filter (%s) already added', filter.id());
			return;
		}
		d.filters.append(filter);
		Obj.connect(
			filter, 'geometryChanged',
			this, 'setCurrentFiltersMapFeatures',
		);
		Obj.connect(
			filter, 'labelChanged',
			this, 'setCurrentFiltersMapFeatures',
		);
		Obj.connect(
			filter, 'enabledChanged',
			this, 'filterChanged',
		);
		Obj.connect(
			filter, 'expressionChanged',
			this, 'filterChanged',
		);
		Obj.connect(
			filter, 'geometryChanged',
			this, 'filterChanged',
		);
		d.setMapFilterSourceData(
			d.filters.toArray(),
		);
		this.filterAdded(filter);
		for (const obj of filter.childFilters()) {
			this.addFilter(obj);
		}
	}

	@SLOT
	closeCompleter(): void {
		const d = this.d;
		const compl = d.textInput && d.textInput.completer();
		if (compl) {
			compl.hide();
		}
	}

	@SLOT
	closeDrawer(): void {
		this.setDrawerOpen(false);
	}

	@SLOT
	closeFilterBox(): void {
		const d = this.d;
		const box = d.filterBox;
		d.filterBox = null;
		if (box) {
			box.destroy();
		}
	}

	@SLOT
	closePopups(): void {
		const d = this.d;
		if (d.map) {
			d.map.closePopups();
		}
	}

	async createFilter(data: Partial<INewFilter> = {}): Promise<FilterMdl> {
		const fil = await FilterMdl.create(
			this.d.newFilterData(data),
		);
		this.filterCreated(
			fil,
		);
		return fil;
	}

	@SLOT
	async createFiltersFromFeatures<G extends GeoJsonGeometry, P extends GeoJsonProperties>(feats: Array<GeoJsonFeature<G, P>>): Promise<void> {
		const tempIds: Array<string> = [];
		for (const feat of feats) {
			if (typeof feat.id === 'string') {
				tempIds.push(feat.id);
			}
		}
		for (const feat of feats) {
			const f = LbFeature.from(feat);
			await this.createFilter({
				dataFilter: true,
				geometry: f.polygonOrMultiPolygonGeometry,
				geometryIsCircle: f.geometryIsCircle,
				point: f.pointGeometry,
			});
		}
		const d = this.d;
		if (d.map) {
			d.map.deleteFeatures(tempIds);
		}
	}

	@SLOT
	async createFiltersFromPlaces(places: PlacePk | Iterable<PlacePk>): Promise<void> {
		const ids = (typeof places === 'string') ?
			[places] :
			places;
		for (const placePk of ids) {
			await this.createFilter({placePk});
		}
	}

	get d(): ParcelViewPrivate {
		return <ParcelViewPrivate>super.d;
	}

	async deleteFilter(filter: FilterMdl | null): Promise<void> {
		if (filter) {
			await filter.delete();
			this.filterDeleted(filter);
			filter.destroy();
		}
	}

	@SLOT
	async deleteFiltersFromFeatures<G extends GeoJsonGeometry, P extends GeoJsonProperties>(feats: Array<GeoJsonFeature<G, P>>): Promise<void> {
		const d = this.d;
		for (const feat of feats) {
			if (feat.id === undefined) {
				continue;
			}
			const featId = featureIdNumber(feat.id);
			if (!isNumber(featId)) {
				continue;
			}
			let fil: FilterMdl | null = null;
			for (const obj of d.filters) {
				if (obj.id() === featId) {
					fil = obj;
					break;
				}
			}
			await this.deleteFilter(fil);
		}
	}

	@SLOT
	async differenceFiltersFromFeatures<G extends GeoJsonGeometry, P extends GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): Promise<void> {
		// Transient features remove parts of existing geometry (difference)
		//
		// - For each existing filter geometry
		//     - Check if filter geometry intersects with transient geometry
		//         - If so, calculate the geometric difference
		//             - If the difference is nil, this transient geometry has completely wiped-out this filter, so deleted the whole filter.
		//             - Otherwise the difference geometry will be the filters new geometry
		//
		// - If the transient geometry is type POINT
		//     - Find an Area intersecting with the point, get its geometry
		//         - If geometry, use that as the thing to create the difference thing
		const d = this.d;
		for (const feat of features) {
			if (!feat.geometry || (feat.geometry.type === 'GeometryCollection')) {
				continue;
			}
			const transientGeoms: Array<GeoJsonPolygon | GeoJsonMultiPolygon> = [];
			switch (feat.geometry.type) {
				case 'Polygon':
				case 'MultiPolygon': {
					transientGeoms.push(feat.geometry);
					break;
				}
				case 'Point': {
					const parcels = await Parcel.list(
						GeoCoordinate.fromPoint(feat.geometry),
					);
					const areaPks = parcels.map(x => x.areaId);
					const areas = await svc.area.list(areaPks.toArray());
					transientGeoms.push(
						...areas.map(x => x.geometry),
					);
					break;
				}
				default: {
					continue;
				}
			}
			for (const fil of d.filters) {
				const geom = fil.geometry();
				if (!geom) {
					continue;
				}
				for (const transGeom of transientGeoms) {
					if (!intersects(transGeom, geom)) {
						// No touching
						continue;
					}
					if (geom.type === 'Point') {
						// Intersecting with a point. Straight to deleted.
						await this.deleteFilter(fil);
					} else {
						const diff = difference(geom, transGeom);
						if (diff) {
							await this.updateFilter(
								fil,
								{
									geometry: diff.geometry,
									geometryIsCircle: false,
								},
							);
						} else {
							await this.deleteFilter(fil);
						}
					}
				}
			}
		}
	}

	protected _documentKeyDownEvent(event: KeyboardEvent): void {
		switch (event.key) {
			case Key.Escape: {
				// Close order:
				//     1) Active map controls
				//     2) Open completer
				//     3) Open filter box
				//     4) Map popups
				//     5) Current geometry editing
				//     6) Open drawer
				const d = this.d;
				if (d.map && d.map.hasActiveMapControl()) {
					if (d.map.isMapControlActive(MapControlType.Draw)) {
						// Allow draw control to handle the escape event so it
						// can perform necessary cleanup.
						return;
					}
					d.map.setMapControlActive(
						d.map.activeMapControlType(),
						false,
					);
				} else if (this.isCompleterOpen()) {
					this.closeCompleter();
				} else if (this.isFilterBoxOpen()) {
					this.closeFilterBox();
				} else if (d.map && d.map.hasPopups()) {
					this.closePopups();
				} else if (this.isEditingFilterGeometry()) {
					if (d.map && d.map.hasFocus()) {
						// Escape pressed while editing geometry. Assume user
						// wants to exit editing.
						this.stopEditingFilterGeometry();
					}
				} else if (this.isDrawerOpen()) {
					this.closeDrawer();
				}
				break;
			}
			case 'Enter': {
				if (this.isEditingFilterGeometry()) {
					this.stopEditingFilterGeometry();
				}
				break;
			}
		}
	}

	drawer(): Drawer | null {
		return this.d.drawer;
	}

	@SIGNAL
	protected drawerClosed(): void {
	}

	@SIGNAL
	protected drawerOpened(): void {
	}

	editingFilter(): FilterMdl | null {
		return this.d.editFilter;
	}

	filterAt(pos: GeoCoordinate | GeoJsonPoint): FilterMdl | null {
		const fils = this.filtersAt(pos);
		return fils.isEmpty() ?
			null :
			fils.first();
	}

	@SIGNAL
	protected filterBoxClosed(): void {
	}

	@SIGNAL
	protected filterBoxOpened(): void {
	}

	@SIGNAL
	protected filterAdded(filter: FilterMdl): void {
	}

	@SLOT
	protected filterChanged(filter: FilterMdl): void {
		this.updateQuery();
		this.updateMap();
	}

	@SIGNAL
	protected filterCreated(filter: FilterMdl): void {
	}

	@SIGNAL
	protected filterDeleted(filter: FilterMdl): void {
	}

	@SIGNAL
	protected filterRemoved(filter: FilterMdl): void {
	}

	filters(): list<FilterMdl> {
		return this.d.filters;
	}

	filtersAt(pos: GeoCoordinate | GeoJsonPoint): list<FilterMdl> {
		const d = this.d;
		const p: GeoJsonPoint = (pos instanceof GeoCoordinate) ?
			pos.asPoint() :
			pos;
		const rv = new list<FilterMdl>();
		for (const obj of d.filters) {
			const geom = obj.geometry();
			if (geom && intersects(p, geom)) {
				rv.append(obj);
			}
		}
		return rv;
	}

	flyTo(bbox: [number, number, number, number]): void {
		//  long                lat                long                 lat
		// [-77.82740230327359, 34.223342940030975, -77.78976892279576, 34.25637110856619]
		const d = this.d;
		if (!d.map) {
			return;
		}
		const [long0, lat0, long1, lat1] = bbox;
		const coord0 = new GeoCoordinate(lat0, long0);
		const coord1 = new GeoCoordinate(lat1, long1);
		d.map.fitViewToBounds(
			new GeoRectangle(coord1, coord0),
			{padding: 64},
		);
	}

	@SLOT
	flyToFilter(filter: FilterMdl): void {
		const d = this.d;
		const geom = filter.geometry();
		if (geom && geom.bbox) {
			if (this.isFilterBoxOpen()) {
				if (d.filterBox && (d.filterBox.parentFilter() !== filter)) {
					// Filter box is open but has a different parent filter.
					this.closeFilterBox();
				}
			}
			d.movingToFilter = filter;
			this.flyTo(<[number, number, number, number]>geom.bbox);
		} else {
			d.movingToFilter = null;
			const parId = filter.parentId();
			const box = !!parId ? d.filterBox : null;
			const boxPar = !!box && box.parentFilter();
			const isChild = !!boxPar && (boxPar.id() === parId);
			if (!isChild && !filter.isDataFilter()) {
				logger.warning('flyToFilter: Filter has no geometry and/or bbox.');
			}
		}
	}

	@SLOT
	async ignoreParcel(parcel: Parcel | null): Promise<boolean> {
		return false;
	}

	isCompleterOpen(): boolean {
		const d = this.d;
		if (!d.textInput) {
			return false;
		}
		const compl = d.textInput.completer();
		return compl ?
			compl.isVisible() :
			false;
	}

	isDrawerOpen(): boolean {
		const d = this.d;
		return d.drawer ?
			d.drawer.isVisible() :
			false;
	}

	isEditingFilterGeometry(): boolean {
		return Boolean(this.d.editFilter);
	}

	isFilterBoxOpen(): boolean {
		const d = this.d;
		return !!d.filterBox && d.filterBox.isVisible();
	}

	map(): ParcelMap | null {
		return this.d.map;
	}

	@SLOT
	protected mapClicked(point: Point, coord: GeoCoordinate): void {
		const d = this.d;
		if (this.isCompleterOpen()) {
			this.closeCompleter();
		}
		if (d.map && d.map.isMapControlActive(MapControlType.Draw)) {
			// User is drawing. Leave them alone.
			return;
		}
		const clickedFilter = this.filterAt(
			coord,
		);
		if (clickedFilter) {
			const editingFilter = this.editingFilter();
			const editingClickedFilter = editingFilter === clickedFilter;
			if (editingClickedFilter) {
				// Filter in "edit" mode. Close the filter popup to
				// allow for further unobstructed editing.
				this.closeFilterBox();
				// Nothing more to do.
				return;
			}
			if (this.isEditingFilterGeometry()) {
				// Assume map is in draw shape mode and user clicked
				// shape (perhaps trying to click on node). Don't
				// throw the filter box back in their face.
				//
				// Nothing more to do.
				return;
			}
			// Either reposition an already-open filter popup or open
			// a new one.
			if (this.isFilterBoxOpen() && !editingClickedFilter) {
				// Filter box open for some other filter.
				//
				// Close it before setting new filter data to avoid
				// potentially displaying new filter data in box
				// currently positioned at the other filter.
				this.closeFilterBox();
			}
			this.openFilterBox(
				clickedFilter,
				App.lastCursorPos(),
			);
		} else {
			// No filters under the mouse.
			// if (d.map && d.map.isMapControlActive(MapControlType.Draw) && this.isEditingFilterGeometry() && (d.map.selectedFeatures().features.length < 1)) {
			if (this.isEditingFilterGeometry() && (d.map && (d.map.selectedFeatures().features.length < 1))) {
				// We're editing a shape and the user just clicked
				// outside of any shape we have. We'll stop editing.
				this.stopEditingFilterGeometry();
			} else {
				// Close any open filter popups.
				this.closeFilterBox();
			}
		}
	}

	mapConfig(): IInteractiveMapConfiguration {
		let cfg = uiRepo.mapCfg();
		if (cfg) {
			return cfg;
		}
		cfg = {
			...staticInitMapCfg,
		};
		uiRepo.saveMapCfg(cfg);
		return cfg;
	}

	@SLOT
	private async mapMoveEnded(): Promise<void> {
		const d = this.d;
		if (d.movingToFilter) {
			this.openFilterBox(d.movingToFilter);
		}
		d.movingToFilter = null;
	}

	@SLOT
	openDrawer(): void {
		this.setDrawerOpen(true);
	}

	openFilterBox(filter: FilterMdl, pos?: Point): void {
		const d = this.d;
		let p: Point;
		if (pos) {
			p = pos;
		} else {
			const c = d.centerPoint(filter.geometry());
			if (c) {
				p = c;
			} else {
				logger.error('openFilterBox: Unable to ascertain point location.');
				return;
			}
		}
		if (!d.filterBox) {
			d.filterBox = d.newFilterBoxInstance();
			d.filterBox.setWidth(500);
			Obj.connect(
				this, 'filterAdded',
				d.filterBox, 'addFilter',
			);
			Obj.connect(
				this, 'filterRemoved',
				d.filterBox, 'removeFilter',
			);
			Obj.connect(
				d.filterBox, 'destroyed',
				this, 'closeFilterBox',
			);
			Obj.connect(
				d.filterBox, 'closed',
				this, 'closeFilterBox',
			);
		}
		d.filterBox.addFilter(filter);
		for (const child of filter.childFilters()) {
			d.filterBox.addFilter(child);
		}
		d.filterBox.setTransparent(false);
		d.filterBox.show();
		this.setFilterBoxPosition(
			d.filterBoxOpenPosition(
				p,
				d.filterBox.rect(),
			),
		);
	}

	protected async openParcelPopup(coord: GeoCoordinate, parcels: Iterable<Parcel>, showIgnoreButton: boolean = true): Promise<void> {
		this.closePopups();
		this.closeFilterBox();
		const d = this.d;
		if (!d.map) {
			return;
		}
		d.parcelInfo = new ParcelInfo();
		Obj.connect(
			d.parcelInfo, 'actionActivated',
			this, 'parcelInfoActionActivated',
		);
		for (const parcel of parcels) {
			await d.parcelInfo.addInfo(
				parcel,
				showIgnoreButton,
			);
		}
		d.map.openPopup(
			coord,
			{
				el: d.parcelInfo,
				closeButton: true,
				closeOnClick: false,
			},
		);
	}

	protected async openParcelPopupAtCoord(coord: GeoCoordinate, showIgnoreButton: boolean = true): Promise<void> {
		const infos: Array<Parcel> = [];
		const dnm = new set<boolean>();
		const refs = await GeoRef.list({
			coord: {
				longitude: coord.longitude(),
				latitude: coord.latitude(),
			},
		});
		for (const ref of refs) {
			dnm.add(ref.doNotMail());
		}
		let ignored: boolean;
		if (dnm.size > 1) {
			logger.critical('openParcelPopupAtCoord: Multiple DNM values at %s', coord);
			ignored = false;
		} else {
			ignored = (dnm.size === 1) ?
				dnm.toArray()[0] :
				false;
		}
		for (const parcel of await Parcel.list(coord)) {
			await parcel.setIgnored(ignored);
			infos.push(parcel);
		}
		await this.openParcelPopup(coord, infos, showIgnoreButton);
	}

	@SLOT
	protected async parcelInfoActionActivated(sectionIndex: number, action: ParcelAction): Promise<void> {
		const d = this.d;
		if (!d.parcelInfo) {
			return;
		}
		switch (action) {
			case ParcelAction.IgnoreParcel: {
				const parcel = d.parcelInfo.info(sectionIndex);
				if (await this.ignoreParcel(d.parcelInfo.info(sectionIndex))) {
					if (parcel) {
						await d.parcelInfo.setInfo(sectionIndex, parcel);
					}
					await this.updateMap();
				}
				break;
			}
		}
	}

	@SIGNAL
	protected queryUpdated(): void {
	}

	@SLOT
	removeFilter(filter: FilterMdl): void {
		Obj.disconnect(
			filter, 'enabledChanged',
			this, 'filterChanged',
		);
		Obj.disconnect(
			filter, 'expressionChanged',
			this, 'filterChanged',
		);
		Obj.disconnect(
			filter, 'geometryChanged',
			this, 'filterChanged',
		);
		this.filterChanged(filter);
		const d = this.d;
		const idx = d.filters.indexOf(filter);
		if (idx >= 0) {
			d.filters.remove(idx);
		} else {
			logger.error('removeFilter: Object %s was not found within current object collection.', filter.id());
		}
		this.filterRemoved(filter);
	}

	@SLOT
	protected saveMapConfig(): void {
		const d = this.d;
		if (!d.map) {
			return;
		}
		const cam = d.map.camera();
		let sty: string = d.map.style();
		if (sty.length < 1) {
			const curr = uiRepo.mapCfg();
			if (curr) {
				sty = curr.styleIdentifier;
			}
		}
		uiRepo.saveMapCfg({
			...cam,
			styleIdentifier: sty,
		});
	}

	@SLOT
	setCurrentFiltersMapFeatures(): void {
		if (this.isEditingFilterGeometry()) {
			// If geometry was just updated b/c user modified geometry, we
			// don't want to effectively stop the editing process. This isn't
			// a great routine and logic.
			return;
		}
		const d = this.d;
		d.setMapFilterSourceData(
			d.filters.toArray(),
		);
	}

	@SLOT
	setDocumentTitle(title: string): void {
		if (title.trim().length > 0) {
			document.title = `${title} - Listblox`;
		} else {
			document.title = 'Listblox';
		}
	}

	setDrawer(drawer: Drawer | null): void {
		const d = this.d;
		if (drawer === d.drawer) {
			return;
		}
		if (d.drawer) {
			Obj.disconnect(
				d.drawer, 'opened',
				this, 'drawerOpened',
			);
			Obj.disconnect(
				d.drawer, 'opened',
				this, 'updateCompleterPosition',
			);
			Obj.disconnect(
				d.drawer, 'closed',
				this, 'drawerClosed',
			);
			Obj.disconnect(
				d.drawer, 'closed',
				this, 'updateCompleterPosition',
			);
		}
		d.drawer = drawer;
		if (d.drawer) {
			Obj.connect(
				d.drawer, 'opened',
				this, 'drawerOpened',
			);
			Obj.connect(
				d.drawer, 'opened',
				this, 'updateCompleterPosition',
			);
			Obj.connect(
				d.drawer, 'closed',
				this, 'drawerClosed',
			);
			Obj.connect(
				d.drawer, 'closed',
				this, 'updateCompleterPosition',
			);
		}
	}

	setDrawerOpen(open: boolean): void {
		const d = this.d;
		if (d.drawer) {
			d.drawer.setVisible(open);
		}
	}

	setFilterBoxPosition(x: number, y: number): void;
	setFilterBoxPosition(point: Point): void;
	setFilterBoxPosition(a: Point | number, b?: number): void {
		const d = this.d;
		if (!d.filterBox) {
			return;
		}
		let x: number;
		let y: number;
		if (isNumber(a) && isNumber(b)) {
			x = a;
			y = b;
		} else {
			x = (<Point>a).x();
			y = (<Point>a).y();
		}
		d.filterBox.setPosition(x, y);
	}

	@SLOT
	async setFilters(): Promise<void> {
		for (const fil of await this.d.fetchFilters()) {
			this.addFilter(fil);
		}
	}

	setMap(map: ParcelMap | null): void {
		const d = this.d;
		if (map === d.map) {
			return;
		}
		if (d.map) {
			Obj.disconnect(
				d.map, 'cameraChanged',
				this, 'saveMapConfig',
			);
			Obj.disconnect(
				d.map, 'cameraChanged',
				this, 'updateMap',
			);
			Obj.disconnect(
				d.map, 'clicked',
				this, 'mapClicked',
			);
			Obj.disconnect(
				d.map, 'infoRequestedAt',
				this, 'showInfoAt',
			);
			Obj.disconnect(
				d.map, 'loaded',
				this, 'updateMap',
			);
			Obj.disconnect(
				d.map, 'styleChanged',
				this, 'saveMapConfig',
			);
			Obj.disconnect(
				d.map, 'styleChanged',
				this, 'updateMap',
			);
			Obj.disconnect(
				d.map, 'featuresCreated',
				this, 'createFiltersFromFeatures',
			);
			Obj.disconnect(
				d.map, 'featuresDeleted',
				this, 'deleteFiltersFromFeatures',
			);
			Obj.disconnect(
				d.map, 'featuresModified',
				this, 'updateFiltersFromFeatures',
			);
			Obj.disconnect(
				d.map, 'loaded',
				this, 'setCurrentFiltersMapFeatures',
			);
			Obj.disconnect(
				d.map, 'stationary',
				this, 'mapMoveEnded',
			);
			Obj.disconnect(
				d.map, 'transientFeaturesCreated',
				this, 'differenceFiltersFromFeatures',
			);
		}
		d.map = map;
		if (d.map) {
			Obj.connect(
				d.map, 'cameraChanged',
				this, 'saveMapConfig',
			);
			Obj.connect(
				d.map, 'cameraChanged',
				this, 'updateMap',
			);
			Obj.connect(
				d.map, 'clicked',
				this, 'mapClicked',
			);
			Obj.connect(
				d.map, 'infoRequestedAt',
				this, 'showInfoAt',
			);
			Obj.connect(
				d.map, 'loaded',
				this, 'updateMap',
			);
			Obj.connect(
				d.map, 'styleChanged',
				this, 'saveMapConfig',
			);
			Obj.connect(
				d.map, 'styleChanged',
				this, 'updateMap',
			);
			Obj.connect(
				d.map, 'featuresCreated',
				this, 'createFiltersFromFeatures',
			);
			Obj.connect(
				d.map, 'featuresDeleted',
				this, 'deleteFiltersFromFeatures',
			);
			Obj.connect(
				d.map, 'featuresModified',
				this, 'updateFiltersFromFeatures',
			);
			Obj.connect(
				d.map, 'loaded',
				this, 'setCurrentFiltersMapFeatures',
			);
			Obj.connect(
				d.map, 'stationary',
				this, 'mapMoveEnded',
			);
			Obj.connect(
				d.map, 'transientFeaturesCreated',
				this, 'differenceFiltersFromFeatures',
			);
		}
	}

	setTextInput(textInput: SearchInput | null): void {
		const d = this.d;
		if (textInput === d.textInput) {
			return;
		}
		if (d.textInput) {
			Obj.disconnect(
				d.textInput, 'textChanged',
				this, 'textInputTextChanged',
			);
			Obj.disconnect(
				d.textInput, 'iconActivated',
				this, 'textInputIconActivated',
			);
		}
		d.textInput = textInput;
		if (d.textInput) {
			Obj.connect(
				d.textInput, 'textChanged',
				this, 'textInputTextChanged',
			);
			Obj.connect(
				d.textInput, 'iconActivated',
				this, 'textInputIconActivated',
			);
		}
	}

	setUndoView(undoView: UndoView | null): void {
		const d = this.d;
		if (undoView === d.undoView) {
			return;
		}
		if (d.undoView) {
			Obj.disconnect(
				d.undoStack, 'canRedoChanged',
				d.undoView, 'canRedoChanged',
			);
			Obj.disconnect(
				d.undoStack, 'canUndoChanged',
				d.undoView, 'canUndoChanged',
			);
			Obj.disconnect(
				d.undoStack, 'redoTextChanged',
				d.undoView, 'redoTextChanged',
			);
			Obj.disconnect(
				d.undoStack, 'undoTextChanged',
				d.undoView, 'undoTextChanged',
			);
			Obj.disconnect(
				d.undoView, 'actionActivated',
				this, 'undoActionActivated',
			);
		}
		d.undoView = undoView;
		if (d.undoView) {
			Obj.connect(
				d.undoStack, 'canRedoChanged',
				d.undoView, 'canRedoChanged',
			);
			Obj.connect(
				d.undoStack, 'canUndoChanged',
				d.undoView, 'canUndoChanged',
			);
			Obj.connect(
				d.undoStack, 'redoTextChanged',
				d.undoView, 'redoTextChanged',
			);
			Obj.connect(
				d.undoStack, 'undoTextChanged',
				d.undoView, 'undoTextChanged',
			);
			Obj.connect(
				d.undoView, 'actionActivated',
				this, 'undoActionActivated',
			);
		}
	}

	@SLOT
	showInfoAt(coord: GeoCoordinate, showIgnoreButton: boolean = true): void {
		this.openParcelPopupAtCoord(coord, showIgnoreButton);
	}

	startEditingFilterGeometry(filter: FilterMdl | null): void {
		if (!filter) {
			return;
		}
		const d = this.d;
		if (this.isEditingFilterGeometry()) {
			return;
		}
		this.closeFilterBox();
		d.setMapFilterSourceData(
			d.filters.filter(
				x => (x !== filter),
			),
		);
		if (d.map) {
			d.map.addFilterAsFeature(
				filter,
			);
			d.map.selectFilterFeature(
				filter,
			);
		}
		d.editFilter = filter;
		d.focusCanvas();
	}

	stopEditingFilterGeometry(): void {
		const d = this.d;
		if (!d.editFilter) {
			return;
		}
		const wasEditing = d.editFilter;
		d.editFilter = null;
		if (d.map) {
			d.map.deleteFeatures(wasEditing.id());
		}
		d.setMapFilterSourceData(
			d.filters.toArray(),
		);
	}

	textInput(): SearchInput | null {
		return this.d.textInput;
	}

	@SLOT
	protected textInputIconActivated(pos: TextInputIconPosition): void {
		switch (pos) {
			case TextInputIconPosition.Leading:
				this.setDrawerOpen(
					!this.isDrawerOpen(),
				);
				break;
		}
	}

	@SLOT
	protected textInputTextChanged(text: string): void {
	}

	@SLOT
	protected undoActionActivated(action: 'undo' | 'redo'): void {
		const d = this.d;
		switch (action) {
			case 'undo': {
				d.undoStack.undo();
				break;
			}
			case 'redo': {
				d.undoStack.redo();
				break;
			}
		}
	}

	undoStack(): UndoStack {
		return this.d.undoStack;
	}

	undoView(): UndoView | null {
		return this.d.undoView;
	}

	@SLOT
	updateCompleterPosition(): void {
		const d = this.d;
		const compl = d.textInput && d.textInput.completer();
		if (compl) {
			compl.updatePosition();
		}
	}

	async updateFilter(filter: FilterMdl | null, data: Partial<IUpdateFilter>): Promise<void> {
		if (filter) {
			await filter.update(data);
		}
	}

	@SLOT
	async updateFiltersFromFeatures<G extends GeoJsonGeometry, P extends GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): Promise<void> {
		const d = this.d;
		for (const feat of features) {
			const f = LbFeature.from(feat);
			const featId = featureIdNumber(feat.id);
			if (!isNumber(featId)) {
				logger.warning('mapFeaturesUpdated: Got invalid filter identifier %s', feat.id);
				continue;
			}
			let fil: FilterMdl | null = null;
			for (const obj of d.filters) {
				if (obj.id() === featId) {
					fil = obj;
					break;
				}
			}
			if (!fil) {
				logger.error('updateFiltersFromFeatures: No object with ID %s', featId);
				continue;
			}
			await this.updateFilter(
				fil,
				{
					geometry: f.polygonOrMultiPolygonGeometry,
					point: f.pointGeometry,
					geometryIsCircle: f.geometryIsCircle,
				},
			);
		}
	}

	@SLOT
	updatePkBs(): void {
		this._debouncedUpdatePkBs();
	}

	private async _updatePkBs(): Promise<void> {
		const d = this.d;
		if (!d.map) {
			return;
		}
		const t = new Transform();
		const c = d.map.camera();
		t.zoom = c.zoom;
		t.center = geoCoordinateToLngLat(
			GeoCoordinate.fromGeoCoordinateLike({
				latitude: c.latitude,
				longitude: c.longitude,
			}),
		);
		t.bearing = c.bearing;
		t.pitch = c.pitch;
		const rect = d.map.rect();
		t.resize(rect.width, rect.height);
		d.pkBs.invalidate();
		await d.pkBs.update(t);
	}

	@SLOT
	async updateMap(): Promise<void> {
		const d = this.d;
		if (!d.map) {
			return;
		}
		if (d.map.camera().zoom < DATA_PARCEL_LAYER_MIN_ZOOM) {
			return;
		}
		await this.updatePkBs();
	}

	@SLOT
	async updateQuery(): Promise<void> {
		this.queryUpdated();
	}

	protected _windowResizeEvent(event: Event): void {
		const d = this.d;
		d.adjustFilterBoxPosition();
		const compl = d.textInput && d.textInput.completer();
		if (compl) {
			compl.updatePosition();
		}
	}
}
