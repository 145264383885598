import {OBJ} from '../../../obj';
import {ElObj, ElObjOpts} from '../../../elobj';
import {list, Point} from '../../../tools';
import {clamp} from '../../../util';
import {Row} from './row';
import {Table} from './table';
import {Cell} from './cell';

@OBJ
export class Body extends ElObj {
	static tagName: TagName = 'tbody';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__content',
		);
		super(opts);
	}

	cellAt(pos: Point): Cell | null {
		for (const row of this.children()) {
			const cell = row.cellAt(pos);
			if (cell) {
				return cell;
			}
		}
		return null;
	}

	children(): list<BodyRow> {
		return <list<BodyRow>>super.children();
	}

	insertRow(index: number, row: BodyRow): void {
		index = clamp(0, index, this.children().size());
		row.setParent(this, index);
		row.show();
	}

	parentTable(): Table | null {
		return <Table | null>this.parentEl();
	}

	removeRow(index: number): void {
		if ((index >= 0) && (index < this.children().size())) {
			this.children().takeAt(index).destroy();
		}
	}
}

@OBJ
export class BodyRow extends Row {
	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__row',
		);
		super(opts);
	}
}
