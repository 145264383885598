import {ActionBox, Card, CardOpts, CardPrivate, Media, MediaAspectRatio, MediaContent, PrimaryAction} from '../../ui/card';
import {Obj, OBJ, SLOT} from '../../obj';
import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {FancyPushButton} from '../../ui/pushbutton';
import {IViewItem} from './item';
import {CheckState} from '../../constants';
import {LinearProgress} from '../../ui/progress';
import {ToolButton} from '../../ui/toolbutton';
import {Icon} from '../../ui/icon';
import {Layout} from './layout';

export class CardItemPrivate extends CardPrivate {
	primaryBtn: FancyPushButton | null;
	prog: LinearProgress | null;

	constructor() {
		super();
		this.primaryBtn = null;
		this.prog = null;
	}

	init(opts: Partial<CardItemOpts>): void {
		if (opts.outlined === undefined) {
			opts.outlined = true;
		}
		super.init(opts);
		const q = this.q;
		this.primaryAction = new PrimaryAction({
			parent: q,
		});
		this.media = new Media({
			parent: this.primaryAction,
		});
		this.media.setContent(
			new CardItemMediaContent(),
		);
		this.media.setAspectRatio(
			MediaAspectRatio.SixteenByNine,
		);
		this.prog = new LinearProgress({
			indeterminate: true,
			parent: q,
		});
		this.prog.hide();
		this.actionBox = new ActionBox({
			parent: q,
		});
		this.primaryBtn = new FancyPushButton({
			text: '...',
		});
		this.actionBox.addPushButton(this.primaryBtn);
		const icon = new Icon({name: 'more_vert'});
		const btn = new ToolButton();
		Obj.connect(
			btn, 'clicked',
			q, 'openMenu',
		);
		btn.setIcon(icon);
		this.actionBox.addToolButton(btn);
	}

	mediaContent(): CardItemMediaContent | null {
		const obj = this.media && this.media.content();
		return (obj && (obj instanceof CardItemMediaContent)) ?
			obj :
			null;
	}

	get q(): CardItem {
		return <CardItem>super.q;
	}
}

export interface CardItemOpts extends CardOpts {
	dd: CardItemPrivate;
}

@OBJ
export class CardItem extends Card implements IViewItem {
	layout: Layout | null;

	constructor(opts: Partial<CardItemOpts> = {}) {
		opts.classNames = Card.mergeClassNames(
			opts.classNames,
			'lb-project-list-item',
		);
		opts.dd = opts.dd || new CardItemPrivate();
		super(opts);
		this.layout = null;
	}

	checkState(): CheckState {
		return CheckState.Unchecked;
	}

	get d(): CardItemPrivate {
		return <CardItemPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		if (this.layout) {
			this.layout.d.items.removeAll(this);
		}
		this.layout = null;
		d.primaryBtn = null;
		d.prog = null;
		super.destroy();
	}

	image(): string {
		const d = this.d;
		if (d.media) {
			return d.media.mediaUrl();
		}
		return '';
	}

	isBusy(): boolean {
		const d = this.d;
		return !!d.prog && d.prog.isVisible();
	}

	@SLOT
	protected openMenu(): void {
		if (this.layout && this.layout.view) {
			this.layout.view.openProjectOptionMenu(
				this.layout.index(this),
			);
		}
	}

	primaryButtonText(): string {
		const d = this.d;
		return d.primaryBtn ?
			d.primaryBtn.text() :
			'';
	}

	setBusy(busy: boolean): void {
		const d = this.d;
		if (d.prog) {
			d.prog.setVisible(busy);
		}
	}

	setCheckState(state: CheckState): any {
		// noop
	}

	setImage(url: string): any {
		const d = this.d;
		if (d.media) {
			d.media.setMediaUrl(url);
		}
	}

	setPrimaryButtonText(text: string): any {
		const d = this.d;
		if (d.primaryBtn) {
			d.primaryBtn.setText(text);
		}
	}

	setSubTitle(subTitle: string): any {
		const obj = this.d.mediaContent();
		if (obj) {
			obj.setSubTitle(subTitle);
		}
	}

	setTitle(title: string): any {
		const obj = this.d.mediaContent();
		if (obj) {
			obj.setTitle(title);
		}
	}

	subTitle(): string {
		const obj = this.d.mediaContent();
		return obj ?
			obj.subTitle() :
			'';
	}

	setUrl(url: string): any {
		this.setActionHref(url);
	}

	title(): string {
		const obj = this.d.mediaContent();
		return obj ?
			obj.title() :
			'';
	}

	url(): string {
		return this.actionHref();
	}
}

class CardItemMediaContentPrivate extends ElObjPrivate {
	body: CardItemMediaContentBody | null;
	header: CardItemMediaContentHeader | null;

	constructor() {
		super();
		this.body = null;
		this.header = null;
	}

	init(opts: Partial<ElObjOpts>): void {
		super.init(opts);
		const q = this.q;
		const box = new ElObj({
			classNames: 'lb-project-list-card-content',
			parent: q,
		});
		this.header = new CardItemMediaContentHeader({
			parent: box,
		});
		this.body = new CardItemMediaContentBody({
			parent: box,
		});
	}

	get q(): CardItemMediaContent {
		return <CardItemMediaContent>super.q;
	}
}

interface CardItemMediaContentOpts extends ElObjOpts {
	dd: CardItemMediaContentPrivate;
}

@OBJ
class CardItemMediaContent extends MediaContent {
	constructor(opts: Partial<CardItemMediaContentOpts> = {}) {
		opts.dd = opts.dd || new CardItemMediaContentPrivate();
		super(opts);
	}

	get d(): CardItemMediaContentPrivate {
		return <CardItemMediaContentPrivate>super.d;
	}

	setSubTitle(subTitle: string): void {
		const d = this.d;
		if (d.header) {
			d.header.setSubTitle(subTitle);
		}
	}

	setTitle(title: string): void {
		const d = this.d;
		if (d.header) {
			d.header.setTitle(title);
		}
	}

	subTitle(): string {
		const d = this.d;
		return d.header ?
			d.header.subTitle() :
			'';
	}

	title(): string {
		const d = this.d;
		return d.header ?
			d.header.title() :
			'';
	}
}

class CardItemMediaContentHeaderPrivate extends ElObjPrivate {
	subTitle: ElObj | null;
	title: ElObj | null;

	constructor() {
		super();
		this.subTitle = null;
		this.title = null;
	}

	init(opts: Partial<CardItemMediaContentHeaderOpts>): void {
		super.init(opts);
		const q = this.q;
		this.title = new ElObj({
			classNames: [
				'mdc-typography',
				'mdc-typography--headline6',
				'truncate',
			],
			parent: q,
			tagName: 'h2',
		});
		this.title.show();
		this.subTitle = new ElObj({
			classNames: 'lb-project-addr-count',
			parent: q,
		});
		this.subTitle.show();
	}

	get q(): CardItemMediaContentHeader {
		return <CardItemMediaContentHeader>super.q;
	}
}

interface CardItemMediaContentHeaderOpts extends ElObjOpts {
	dd: CardItemMediaContentHeaderPrivate;
}

@OBJ
class CardItemMediaContentHeader extends ElObj {
	constructor(opts: Partial<CardItemMediaContentHeaderOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'content-header',
		);
		opts.dd = opts.dd || new CardItemMediaContentHeaderPrivate();
		super(opts);
	}

	get d(): CardItemMediaContentHeaderPrivate {
		return <CardItemMediaContentHeaderPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.title = null;
		d.subTitle = null;
		super.destroy();
	}

	setSubTitle(subTitle: string): void {
		const d = this.d;
		if (d.subTitle) {
			subTitle = subTitle.trim();
			d.subTitle.setText(subTitle);
		}
	}

	setTitle(title: string): void {
		const d = this.d;
		if (d.title) {
			title = title.trim();
			d.title.setText(title);
			if (title.length > 0) {
				d.title.setAttribute('title', title);
			} else {
				d.title.removeAttribute('title');
			}
		}
	}

	subTitle(): string {
		const d = this.d;
		return d.subTitle ?
			d.subTitle.text().trim() :
			'';
	}

	title(): string {
		const d = this.d;
		return d.title ?
			d.title.text().trim() :
			'';
	}
}

@OBJ
class CardItemMediaContentBody extends ElObj {
	button: FancyPushButton | null;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'content-body',
		);
		super(opts);
		this.button = new FancyPushButton({
			filled: true,
			parent: this,
			text: 'OPEN',
		});
	}
}
