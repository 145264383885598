import {TextInput, TextInputOpts, TextInputPrivate} from '../../ui/textinput';
import {PROJECT_DETAIL_PRIMARY_INPUT_ELEMENT_ID} from '../../constants';
import {OBJ} from '../../obj';

export class SearchInputPrivate extends TextInputPrivate {
	get q(): SearchInput {
		return <SearchInput>super.q;
	}
}

export interface SearchInputOpts extends TextInputOpts {
	dd: SearchInputPrivate;
}

@OBJ
export class SearchInput extends TextInput {
	constructor(opts: Partial<SearchInputOpts> = {}) {
		opts.attributes = TextInput.mergeAttributes(
			opts.attributes,
			['id', PROJECT_DETAIL_PRIMARY_INPUT_ELEMENT_ID],
		);
		opts.dd = opts.dd || new SearchInputPrivate();
		if (opts.leadingIcon === undefined) {
			opts.leadingIcon = {
				interactive: true,
				outlined: false,
				title: 'Info panel',
				icon: 'menu',
			};
		}
		if (opts.noLabel === undefined) {
			opts.noLabel = true;
		}
		super(opts);
		this.addWindowEventListener('resize');
	}

	get d(): SearchInputPrivate {
		return <SearchInputPrivate>super.d;
	}
}
