import {Obj, OBJ, ObjOpts, ObjPrivate, SIGNAL} from './obj';

class StorePrivate extends ObjPrivate {
	get q(): Store {
		return <Store>super.q;
	}
}

interface StoreOpts extends ObjOpts {
	dd: StorePrivate;
}

@OBJ
class Store extends Obj {
	constructor(opts: Partial<StoreOpts> = {}) {
		opts.dd = opts.dd || new StorePrivate();
		super(opts);
	}

	count(): number {
		return window.localStorage.length;
	}

	get d(): StorePrivate {
		return <StorePrivate>super.d;
	}

	deleteEverything(): void {
		window.localStorage.clear();
		this.everythingDeleted();
	}

	deleteItem(key: string): void {
		window.localStorage.removeItem(key);
		this.itemDeleted(key);
	}

	@SIGNAL
	protected everythingDeleted(): void {
	}

	getItem(key: string): string | null {
		return window.localStorage.getItem(key);
	}

	@SIGNAL
	protected itemChanged(key: string): void {
	}

	@SIGNAL
	protected itemDeleted(key: string): void {
	}

	key(index: number): string | null {
		return window.localStorage.key(index);
	}

	*keys(): IterableIterator<string> {
		for (let i = 0; i < this.count(); ++i) {
			const k = this.key(i);
			if (k) {
				yield k;
			}
		}
	}

	setItem(key: string, item: string): void {
		window.localStorage.setItem(key, item);
		this.itemChanged(key);
	}
}

export const leStore: Store = new Store();
