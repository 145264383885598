import {Dialog, DialogOpts, DialogPrivate} from './dialog';
import {AbstractObj, Obj, OBJ, SIGNAL, SLOT} from '../obj';
import {AbstractButton} from './abstractbutton';
import {ButtonRole, DialogCode, Key, StandardButton, TextFormat} from '../constants';
import {PushButton} from './pushbutton';
import {list} from '../tools';
import {Icon} from './icon';
import {Checkbox} from './checkbox';
import {DialogButtonBox} from './dialogbuttonbox';
import {assert, isNumber} from '../util';
import {MessageDialogOptions} from './dialog/helper';
import {CloseEvt, KeyEvt, ShowEvt} from '../evt';
import {ElObj} from '../elobj';

export class MessageBoxPrivate extends DialogPrivate {
	autoAddOkButton: boolean;
	buttonBox: DialogButtonBox | null;
	checkbox: Checkbox | null;
	clickedButton: AbstractButton | null;
	customButtonList: list<AbstractButton>;
	defaultButton: PushButton | null;
	detailsButton: AbstractButton | null;
	detectedEscapeButton: AbstractButton | null;
	escapeButton: AbstractButton | null;
	icon: Icon;
	label: ElObj | null;
	options: MessageDialogOptions;
	memberToDisconnectOnClose: string;
	receiverToDisconnectOnClose: AbstractObj | null;
	signalToDisconnectOnClose: string;

	constructor() {
		super();
		this.autoAddOkButton = true;
		this.buttonBox = null;
		this.checkbox = null;
		this.clickedButton = null;
		this.customButtonList = new list();
		this.defaultButton = null;
		this.detailsButton = null;
		this.detectedEscapeButton = null;
		this.escapeButton = null;
		this.icon = new Icon();
		this.label = null;
		this.memberToDisconnectOnClose = '';
		this.options = MessageDialogOptions.create();
		this.receiverToDisconnectOnClose = null;
		this.signalToDisconnectOnClose = '';
	}

	detectEscapeButton(): void {
		if (this.escapeButton) {
			this.detectedEscapeButton = this.escapeButton;
			return;
		}
		this.detectedEscapeButton = this.buttonBox && this.buttonBox.button(StandardButton.Cancel);
		if (this.detectedEscapeButton) {
			return;
		}
		const btns = this.buttonBox ?
			this.buttonBox.buttons() :
			new list<AbstractButton>();
		if (btns.size() === 1) {
			this.detectedEscapeButton = btns.first();
			return;
		}
		if ((btns.size() === 2) && this.detailsButton) {
			const idx = btns.indexOf(this.detailsButton);
			if (idx >= 0) {
				this.detectedEscapeButton = btns.at(1 - idx);
				return;
			}
		}
		if (this.buttonBox) {
			for (const btn of btns) {
				if (this.buttonBox.buttonRole(btn) === ButtonRole.RejectRole) {
					if (this.detectedEscapeButton) {
						this.detectedEscapeButton = null;
						break;
					}
					this.detectedEscapeButton = btn;
				}
			}
		}
		if (this.detectedEscapeButton) {
			return;
		}
		if (this.buttonBox) {
			for (const btn of btns) {
				if (this.buttonBox.buttonRole(btn) === ButtonRole.NoRole) {
					if (this.detectedEscapeButton) {
						this.detectedEscapeButton = null;
						break;
					}
					this.detectedEscapeButton = btn;
				}
			}
		}
	}

	dialogCodeForButton(button: AbstractButton): number {
		const q = this.q;
		switch (q.buttonRole(button)) {
			case ButtonRole.AcceptRole:
			case ButtonRole.YesRole: {
				return DialogCode.Accepted;
			}
			case ButtonRole.RejectRole:
			case ButtonRole.NoRole: {
				return DialogCode.Rejected;
			}
			default: {
				return -1;
			}
		}
	}

	execReturnCode(button: AbstractButton): number {
		if (!this.buttonBox) {
			return StandardButton.NoButton;
		}
		let rv = this.buttonBox.standardButton(button);
		if (rv === StandardButton.NoButton) {
			// If button is 0, correctly sets rv to -1.
			rv = this.customButtonList.indexOf(button);
		}
		return rv;
	}

	init(opts: Partial<MessageBoxOpts>): void {
		super.init(opts);
		const q = this.q;
		this.label = new ElObj({
			parent: this.content,
		});
		this.buttonBox = new DialogButtonBox({
			classNames: [
				'mdc-dialog__actions',
			],
			parent: this.surface,
		});
		this.buttonBox.setObjectName(
			'lb_msgbox_buttonbox',
		);
		Obj.connect(
			this.buttonBox, 'clicked',
			q, '_buttonClicked',
		);
		this.setupLayout();
		if (opts.title) {
			q.setWindowTitle(opts.title);
		}
		if (opts.text) {
			q.setText(opts.text);
		}
		if (opts.icon) {
			q.setIcon(opts.icon);
		}
		if (opts.buttons) {
			q.setStandardButtons(opts.buttons);
		}
	}

	get q(): MessageBox {
		return <MessageBox>super.q;
	}

	setClickedButton(button: AbstractButton): void {
		const q = this.q;
		this.clickedButton = button;
		q.buttonClicked(
			this.clickedButton,
		);
		const res = this.execReturnCode(
			this.clickedButton,
		);
		this.hide(res);
		this.finalize(
			res,
			this.dialogCodeForButton(
				this.clickedButton,
			),
		);
	}

	setupLayout(): void {
	}
}

export interface MessageBoxOpts extends DialogOpts {
	buttons: StandardButton;
	dd: MessageBoxPrivate;
	icon: Icon;
	text: string;
	title: string;
}

@OBJ
export class MessageBox extends Dialog {
	constructor(opts: Partial<MessageBoxOpts> = {}) {
		opts.dd = opts.dd || new MessageBoxPrivate();
		super(opts);
	}

	addButton(button: AbstractButton, role: ButtonRole): void;
	addButton(text: string, role: ButtonRole): PushButton | null;
	addButton(button: StandardButton): PushButton | null;
	addButton(a: AbstractButton | StandardButton | string, role?: ButtonRole): PushButton | null | void {
		const d = this.d;
		if ((a instanceof AbstractButton) && isNumber(role)) {
			// SIG: addButton(button: AbstractButton, role: ButtonRole): void
			this.removeButton(a);
			d.options.addButton(a.text(), role, a);
			if (d.buttonBox) {
				d.buttonBox.addButton(a, role);
			}
			d.customButtonList.append(a);
			d.autoAddOkButton = false;
		} else if ((typeof a === 'string') && isNumber(role)) {
			// SIG: addButton(text: string, role: ButtonRole): PushButton | null
			const rv = new PushButton({
				text: a,
			});
			this.addButton(rv, role);
			return rv;
		} else {
			// SIG: addButton(button: StandardButton): PushButton | null
			assert(isNumber(a));
			const pb = d.buttonBox && d.buttonBox.addButton(a);
			if (pb) {
				d.autoAddOkButton = false;
			}
			return pb;
		}
	}

	button(which: StandardButton): AbstractButton | null {
		const d = this.d;
		return d.buttonBox ?
			d.buttonBox.button(which) :
			null;
	}

	@SIGNAL
	buttonClicked(button: AbstractButton): void {
	}

	@SLOT
	protected _buttonClicked(button: AbstractButton): void {
		const d = this.d;
		d.setClickedButton(button);
		if (d.receiverToDisconnectOnClose) {
			Obj.disconnect(
				this, d.signalToDisconnectOnClose,
				d.receiverToDisconnectOnClose, d.memberToDisconnectOnClose,
			);
			d.receiverToDisconnectOnClose = null;
			d.signalToDisconnectOnClose = '';
			d.memberToDisconnectOnClose = '';
		}
	}

	buttonRole(button: AbstractButton): ButtonRole {
		const d = this.d;
		return d.buttonBox ?
			d.buttonBox.buttonRole(button) :
			ButtonRole.NoRole;
	}

	buttons(): list<AbstractButton> {
		const d = this.d;
		return d.buttonBox ?
			d.buttonBox.buttons() :
			new list();
	}

	checkBox(): Checkbox | null {
		return this.d.checkbox;
	}

	@SLOT
	protected _clicked(button: StandardButton, role: ButtonRole): void {
		const d = this.d;
		if (button > StandardButton.LastButton) {
			const cstm = d.options.customButton(button);
			const btn = cstm && cstm.button;
			assert(btn);
			d.clickedButton = btn;
			d.clickedButton.click();
			this.done(role);
		} else {
			this.done(button);
		}
	}

	clickedButton(): AbstractButton | null {
		return this.d.clickedButton;
	}

	protected closeEvent(event: CloseEvt): void {
		const d = this.d;
		if (!d.detectedEscapeButton) {
			event.ignore();
			return;
		}
		super.closeEvent(event);
		d.clickedButton = d.detectedEscapeButton;
		this.setResult(
			d.execReturnCode(
				d.detectedEscapeButton,
			),
		);
	}

	get d(): MessageBoxPrivate {
		return <MessageBoxPrivate>super.d;
	}

	defaultButton(): PushButton | null {
		return this.d.defaultButton;
	}

	detailedText(): string {
		// FIXME: NOT IMPLEMENTED
		return '';
	}

	escapeButton(): AbstractButton | null {
		return this.d.escapeButton;
	}

	protected keyPressEvent(event: KeyEvt): void {
		const d = this.d;
		if (event.key() === Key.Escape) {
			if (d.detectedEscapeButton) {
				d.detectedEscapeButton.click();
			}
			return;
		}
		super.keyPressEvent(event);
	}

	icon(): Icon {
		return this.d.icon;
	}

	informativeText(): string {
		// FIXME: NOT IMPLEMENTED
		return '';
	}

	open(receiver: AbstractObj, member: string): void;
	open(): void;
	open(receiver?: AbstractObj, member?: string): void {
		const d = this.d;
		if (receiver && member) {
			const signal = 'buttonClicked';
			Obj.connect(
				this, signal,
				receiver, member,
			);
			d.signalToDisconnectOnClose = signal;
			d.receiverToDisconnectOnClose = receiver;
			d.memberToDisconnectOnClose = member;
		}
		super.open();
	}

	removeButton(button: AbstractButton): void {
		const d = this.d;
		d.customButtonList.removeAll(button);
		if (d.escapeButton === button) {
			d.escapeButton = null;
		}
		if (d.defaultButton === button) {
			d.defaultButton = null;
		}
		if (d.buttonBox) {
			d.buttonBox.removeButton(button);
		}
	}

	setCheckBox(checkbox: Checkbox | null): void {
		const d = this.d;
		if (checkbox === d.checkbox) {
			return;
		}
		if (d.checkbox) {
			d.checkbox.hide();
			if (d.checkbox.parentEl() === this) {
				d.checkbox.setParent(null);
				d.checkbox.deleteLater();
			}
		}
		d.checkbox = checkbox;
		d.setupLayout();
	}

	setDefaultButton(button: PushButton | null | StandardButton): void {
		const d = this.d;
		if (isNumber(button)) {
			if (d.buttonBox) {
				this.setDefaultButton(
					d.buttonBox.button(button),
				);
			}
		} else {
			if (button && d.buttonBox && !d.buttonBox.buttons().contains(button)) {
				return;
			}
			d.defaultButton = button;
			if (d.defaultButton) {
				d.defaultButton.setDefault(true);
				d.defaultButton.focus();
			}
		}
	}

	setDetailedText(text: string): void {
		// FIXME: NOT IMPLEMENTED
	}

	setEscapeButton(button: AbstractButton | null | StandardButton): void {
		const d = this.d;
		if (!d.buttonBox) {
			return;
		}
		if (isNumber(button)) {
			this.setEscapeButton(
				d.buttonBox.button(button),
			);
		} else if (button) {
			if (d.buttonBox.buttons().contains(button)) {
				d.escapeButton = button;
			}
		}
	}

	setIcon(icon: Icon): void {
		this.d.icon = icon;
	}

	setInformativeText(text: string): void {
		// FIXME: NOT IMPLEMENTED
	}

	setStandardButtons(buttons: StandardButton): void {
		const d = this.d;
		if (d.buttonBox) {
			d.buttonBox.setStandardButtons(buttons);
			const btns = d.buttonBox.buttons();
			if (d.escapeButton && !btns.contains(d.escapeButton)) {
				d.escapeButton = null;
			}
			if (d.defaultButton && !btns.contains(d.defaultButton)) {
				d.defaultButton = null;
			}
			d.autoAddOkButton = false;
		}
	}

	setText(text: string): void {
		const d = this.d;
		if (d.label) {
			d.label.setText(text);
		}
	}

	setTextFormat(format: TextFormat): void {
	}

	setWindowTitle(title: string): void {
		const d = this.d;
		if (d.title) {
			d.title.setText(title);
		}
	}

	protected showEvent(event: ShowEvt): void {
		const d = this.d;
		if (d.autoAddOkButton) {
			this.addButton(StandardButton.Ok);
		}
		if (d.detailsButton) {
			this.addButton(
				d.detailsButton,
				ButtonRole.ActionRole,
			);
		}
		d.clickedButton = null;
		d.detectEscapeButton();
		super.showEvent(event);
	}

	standardButton(button: AbstractButton): StandardButton {
		const d = this.d;
		return d.buttonBox ?
			d.buttonBox.standardButton(button) :
			StandardButton.NoButton;
	}

	standardButtons(): StandardButton {
		const d = this.d;
		return d.buttonBox ?
			d.buttonBox.standardButtons() :
			StandardButton.NoButton;
	}

	text(): string {
		const d = this.d;
		return d.label ?
			d.label.text() :
			'';
	}

	textFormat(): TextFormat {
		return TextFormat.PlainText;
	}
}
