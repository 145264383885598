import {ElObj, ElObjOpts} from '../elobj';
import {OBJ, SIGNAL} from '../obj';
import {clamp} from '../util';
import {list} from '../tools';
import {MouseEvt} from '../evt';

interface ComboBoxOpts extends ElObjOpts {
	multiple: boolean;
	size: number; // When multiple is true
}

@OBJ
export class ComboBox extends ElObj {
	static tagName: TagName = 'select';

	private multi: boolean;
	private multiSize: number;

	constructor(opts: Partial<ComboBoxOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(opts.classNames, 'lb-combo-box');
		super(opts);
		this.multi = false;
		this.multiSize = 0;
		if (opts.multiple !== undefined) {
			this.setMultiSelect(opts.multiple, opts.size);
		}
		this.addEventListener('change');
	}

	addItem(text: string, value?: string): void {
		this.insertItem(this.count(), text, value);
	}

	children(): list<OptionElObj> {
		return <list<OptionElObj>>super.children();
	}

	count(): number {
		return this.element().options.length;
	}

	currentIndex(): number {
		return this.selectedIndex();
	}

	@SIGNAL
	private currentIndexChanged(index: number): void {
	}

	currentText(): string {
		const idx = this.selectedIndex();
		if ((idx >= 0) && (idx < this.children().size())) {
			return this.children().at(idx).text();
		}
		return '';
	}

	@SIGNAL
	private currentTextChanged(text: string): void {
	}

	currentValue(): string {
		const idx = this.selectedIndex();
		if ((idx >= 0) && (idx < this.children().size())) {
			return this.children().at(idx).value();
		}
		return '';
	}

	@SIGNAL
	private currentValueChanged(value: string): void {
	}

	protected _domChangeEvent(event: Event): void {
		super._domChangeEvent(event);
		this.currentIndexChanged(this.currentIndex());
		this.currentValueChanged(this.currentValue());
		this.currentTextChanged(this.currentText());
	}

	element(): HTMLSelectElement {
		return <HTMLSelectElement>super.element();
	}

	hasAcceptableInput(): boolean {
		return true;
	}

	hasFocus(): boolean {
		return super.hasFocus() || this.d.elem.contains(document.activeElement);
	}

	index(value: string): number {
		for (let i = 0; i < this.children().size(); ++i) {
			if (this.children().at(i).value() === value) {
				return i;
			}
		}
		return -1;
	}

	insertItem(index: number, text: string, value?: string): void {
		index = clamp(0, index, this.children().size());
		const obj = new OptionElObj({text, value});
		obj.setParent(this, index);
		obj.show();
	}

	isMultiSelect(): boolean {
		return this.multi;
	}

	items(): list<{text: string; value: string;}> {
		const rv = new list<{text: string; value: string;}>();
		for (const opt of this.children()) {
			rv.append({
				text: opt.text(),
				value: opt.value(),
			});
		}
		return rv;
	}

	private selectedIndex(): number {
		return this.element().selectedIndex;
	}

	setCurrentIndex(index: number): void {
		if (index === this.currentIndex()) {
			return;
		}
		this.setSelectedIndex(
			clamp(0, index, this.count() - 1),
		);
		this.currentIndexChanged(this.currentIndex());
		this.currentValueChanged(this.currentValue());
		this.currentTextChanged(this.currentText());
	}

	setMultiSelect(multiSelect: boolean, size?: number): void {
		if (multiSelect === this.multi) {
			return;
		}
		this.multi = multiSelect;
		this.multiSize = (size === undefined) ?
			this.multiSize :
			size;
		const el = this.element();
		el.multiple = this.multi;
		if (this.multi) {
			el.size = this.multiSize;
		}
	}

	private setSelectedIndex(index: number): void {
		this.element().selectedIndex = index;
	}
}

interface OptionElObjOpts extends ElObjOpts {
	text: string;
	value: string;
}

@OBJ
class OptionElObj extends ElObj {
	static tagName: TagName = 'option';

	constructor(opts: Partial<OptionElObjOpts> = {}) {
		super(opts);
		if (opts.text !== undefined) {
			this.setText(opts.text);
		}
		if (opts.value !== undefined) {
			this.setValue(opts.value);
		}
	}

	element(): HTMLOptionElement {
		return <HTMLOptionElement>super.element();
	}

	setValue(value: string): void {
		this.element().value = value;
	}

	text(): string {
		return super.text().trim();
	}

	value(): string {
		return this.element().value;
	}
}
