import {Obj, OBJ, SIGNAL} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import type {TextInput} from './textinput';
import {Evt} from '../../evt';

export enum TextInputIconPosition {
	Leading = 'leading',
	Trailing = 'trailing',
}

export interface TextInputIconOpts extends ElObjOpts {
	icon: string;
	interactive: boolean;
	outlined: boolean;
	position: TextInputIconPosition;
	title: string;
}

@OBJ
export class TextInputIcon extends ElObj {
	static tagName: TagName = 'i';

	private connected: boolean;
	private disabled: boolean;
	private input: TextInput | null;
	private inter: boolean;
	private pos: TextInputIconPosition;

	constructor(opts: Partial<TextInputIconOpts> = {}) {
		const pos = opts.position || TextInputIconPosition.Trailing;
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			opts.outlined ?
				'material-icons-outlined' :
				'material-icons',
			'mdc-text-field__icon',
			`mdc-text-field__icon--${pos}`,
		);
		super(opts);
		this.connected = false;
		this.disabled = false;
		this.input = null;
		this.inter = false;
		this.pos = pos;
		if (opts.icon) {
			this.setIcon(opts.icon);
		}
		if (opts.interactive !== undefined) {
			this.setInteractive(opts.interactive);
		}
		if (opts.title) {
			this.setTitle(opts.title);
		}
	}

	@SIGNAL
	private activated(position: TextInputIconPosition): void {
	}

	protected changeEvent(event: Evt): void {
		if (event.type() === Evt.EnabledChange) {
			const enabled = this.isEnabled();
			this.setClass(!enabled, 'lb-text-input-icon--disabled');
			if (this.inter) {
				this._setInteractive(enabled);
			}
		}
		super.changeEvent(event);
	}

	private connectToTextInput(): void {
		if (this.input && !this.connected) {
			Obj.connect(
				this, 'activated',
				this.input, 'iconActivated',
			);
			this.connected = true;
		}
	}

	private ctrlIconEvent(event: Event): void {
		this.activated(this.pos);
	}

	destroy(): void {
		this.disconnectFromTextInput();
		this.connected = false;
		this.disabled = false;
		this.input = null;
		this.inter = false;
		super.destroy();
	}

	private disconnectFromTextInput(): void {
		if (this.input && this.connected) {
			Obj.disconnect(
				this, 'activated',
				this.input, 'iconActivated');
			this.connected = false;
		}
	}

	icon(): string {
		return this.text().trim();
	}

	isInteractive(): boolean {
		return this.inter;
	}

	protected _domMdcEvent(event: Event): void {
		super._domMdcEvent(event);
		switch (event.type) {
			case 'MDCTextField:icon':
				this.ctrlIconEvent(event);
				break;
		}
	}

	setIcon(icon: string, outlined?: boolean): void {
		if (outlined !== undefined) {
			if (outlined) {
				this.addClass('material-icons-outlined');
				this.removeClass('material-icons');
			} else {
				this.addClass('material-icons');
				this.removeClass('material-icons-outlined');
			}
		}
		this.setText(icon);
	}

	setInteractive(interactive: boolean): void {
		if (interactive === this.inter) {
			return;
		}
		this.inter = interactive;
		this._setInteractive(this.inter);
	}

	private _setInteractive(interactive: boolean): void {
		if (interactive) {
			this.connectToTextInput();
			this.setAttribute('role', 'button');
			this.setTabIndex(0);
			this.addEventListener('MDCTextField:icon');
		} else {
			this.removeEventListener('MDCTextField:icon');
			this.removeAttribute('tabindex');
			this.removeAttribute('role');
			this.disconnectFromTextInput();
		}
	}

	setTextInput(textInput: TextInput | null): void {
		if (textInput === this.input) {
			return;
		}
		const textInputCls = `mdc-text-field--with-${this.pos}-icon`;
		if (this.input) {
			this.input.removeClass(textInputCls);
		}
		this.disconnectFromTextInput();
		this.connected = false;
		this.input = textInput;
		if (this.input) {
			this.input.addClass(textInputCls);
			this.connectToTextInput();
		} else {
			this.remove();
		}
	}

	setTitle(title: string | null): void {
		if (typeof title === 'string') {
			this.setAttribute('title', title);
		} else {
			this.removeAttribute('title');
		}
	}

	title(): string {
		return this.attribute('title') || '';
	}
}
