import {getLogger} from '../../logging';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import {INFINITY_CHAR_CODE, ItemDataRole} from '../../constants';
import {Variant} from '../../variant';
import {isNumber} from '../../util';
import {ToolButton} from '../../ui/toolbutton';
import {DataTableView, DataTableViewOpts, DataTableViewPrivate} from '../../ui/datatable';
import {TableItem} from '../../ui/itemviews';
import {TextInput} from '../../ui/textinput';
import {Point} from '../../tools';
import {DataCell, DataTable} from '../../ui/datatable/el';
import {Evt} from '../../evt';
import {Icon} from '../../ui/icon';

const logger = getLogger('views.tiertable');

class TierTablePrivate extends DataTableViewPrivate {
	get q(): TierTable {
		return <TierTable>super.q;
	}

	init(opts: Partial<TierTableOpts>): void {
		super.init(opts);
		const q = this.q;
		q.setColumnCount(4);
		q.setHorizontalHeaderLabels([
			'First unit',
			'Last unit',
			'Price per unit',
			'',
		]);
	}
}

interface TierTableOpts extends DataTableViewOpts {
	dd: TierTablePrivate
}

@OBJ
export class TierTable extends DataTableView {
	constructor(opts: Partial<TierTableOpts> = {}) {
		opts.dd = opts.dd || new TierTablePrivate();
		if (opts.primaryTable === undefined) {
			opts.primaryTable = new DataTable(
				{
					classNames: [
						'lb-price-data-table',
					],
					dataCellPrototype: TierTableDataCell,
				},
			);
		}
		super(opts);
	}

	@SLOT
	addTier(data?: Partial<{firstUnit: number; lastUnit: number | null; unitPrice: number | string;}>): void {
		const opt = data || {};
		let firstUnit: number | undefined = undefined;
		const insertIdx = this.isLastRowDefined() ?
			this.rowCount() - 1 :
			this.rowCount();
		if (opt.firstUnit === undefined) {
			const num = this.lastUnit(insertIdx - 1);
			if (isNumber(num)) {
				firstUnit = num + 1;
			}
		} else {
			firstUnit = opt.firstUnit;
		}
		if (firstUnit === undefined) {
			firstUnit = 1;
		}
		const lastUnit = (opt.lastUnit === undefined) ?
			firstUnit + 1 :
			opt.lastUnit;
		const unitPrice = (opt.unitPrice === undefined) ?
			'0.00' :
			opt.unitPrice;
		this.d.tableModel().insertRows(insertIdx, 1);
		this.setTier(insertIdx, firstUnit, lastUnit, unitPrice);
		this.updateInputNames();
	}

	private cellInputName(row: number, column: number): string {
		if (!((column === 1) || (column === 2))) {
			logger.error('cellInputName: Got invalid column: %s', column);
			return '';
		}
		const s = (column === 1) ?
			'up_to_including' :
			'unit_amount';
		return `tiers[${row}][${s}]`;
	}

	@SLOT
	protected _p_cellInputTextChanged(row: number, column: number, text: string): void {
		const idx = this.d.tableModel().index(row, column);
		if (idx.isValid()) {
			let num = Number(text.replace(/[^\d.]/g, ''));
			if (!isNumber(num)) {
				num = 0;
			}
			const item = this.item(idx.row(), idx.column());
			if (item) {
				item.setData(
					ItemDataRole.EditRole,
					new Variant(num),
				);
			}
			const nextIdx = this.d.tableModel().index(
				idx.row() + 1,
				idx.column() - 1,
				idx.parent(),
			);
			if (nextIdx.isValid()) {
				const nextItem = this.item(
					nextIdx.row(),
					nextIdx.column(),
				);
				if (nextItem) {
					nextItem.setData(
						ItemDataRole.EditRole,
						new Variant(num + 1),
					);
				}
			}
		}
		super._p_cellInputTextChanged(row, column, text);
	}

	@SLOT
	private cellToolButtonClicked(row: number, column: number): void {
		if (row >= 0) {
			this.removeInBetweenRow(row);
		} else {
			logger.error('cellToolButtonClicked: Got invalid row index (%s, %s).', row, column);
		}
	}

	private isLastRowDefined(): boolean {
		return this.lastUnit(
			this.rowCount() - 1,
		) === Number.POSITIVE_INFINITY;
	}

	private lastUnit(row: number): number {
		if ((row >= 0) && (row < this.rowCount())) {
			const idx = this.d.tableModel().index(row, 1);
			if (idx.isValid()) {
				const data = this.d.tableModel().data(
					idx,
					ItemDataRole.EditRole,
				);
				if (data.isValid() && !data.isNull()) {
					const text = data.toString().trim();
					if (text === String.fromCharCode(INFINITY_CHAR_CODE)) {
						return Number.POSITIVE_INFINITY;
					}
					return Number.parseInt(
						text.replace(/[^\d.]/g, ''),
					);
				}
			}
		}
		return Number.NaN;
	}

	private removeInBetweenRow(rowIdx: number): void {
		if (this.validInBetweenRowIdx(rowIdx)) {
			this.removeRow(rowIdx);
			const prevLastUnitIdx = this.d.tableModel().index(rowIdx - 1, 1);
			if (prevLastUnitIdx.isValid()) {
				const data = this.d.tableModel().data(
					prevLastUnitIdx,
					ItemDataRole.EditRole,
				);
				if (data.isValid() && !data.isNull()) {
					const text = data.toString().trim().replace(/[^\d.]/g, '');
					const idx = this.d.tableModel().index(rowIdx, 0);
					if (idx.isValid()) {
						const item = this.item(idx.row(), idx.column());
						if (item) {
							let prevLastUnit = Number.parseInt(text);
							let firstUnit: number;
							if (isNumber(prevLastUnit)) {
								firstUnit = prevLastUnit + 1;
							} else {
								firstUnit = 1;
							}
							item.setData(ItemDataRole.EditRole, new Variant(firstUnit));
						}
					}
				}
			}
			let curr = rowIdx;
			while (curr < this.rowCount()) {
				this.setRowInputName(curr);
				++curr;
			}
		}
	}

	private setRowInputName(row: number): void {
		let idx = this.d.tableModel().index(row, 1);
		if (idx.isValid()) {
			this.d.tableModel().setData(
				idx,
				new Variant(
					this.cellInputName(
						idx.row(),
						idx.column(),
					),
				),
				ItemDataRole.IdentifierRole,
			);
		}
		idx = this.d.tableModel().index(row, 2);
		if (idx.isValid()) {
			this.d.tableModel().setData(
				idx,
				new Variant(
					this.cellInputName(
						idx.row(),
						idx.column(),
					),
				),
				ItemDataRole.IdentifierRole,
			);
		}
	}

	private setTier(index: number, firstUnit: number, lastUnit: number | null, unitPrice: number | string): void {
		if ((index < 0) || (index >= this.rowCount())) {
			return;
		}
		// FIRST UNIT
		let item = this.item(index, 0);
		if (!item) {
			item = new TableItem();
			this.setItem(index, 0, item);
		}
		let cell = this.indexEl(
			this.d.tableModel().index(index, 0),
		);
		if (cell && (cell instanceof TierTableDataCell)) {
			cell.setInputEnabled(true);
			cell.setDisabled(true);
		}
		item.setData(
			ItemDataRole.EditRole,
			new Variant(firstUnit),
		);
		// LAST UNIT
		lastUnit = (lastUnit === null) ?
			Number.POSITIVE_INFINITY :
			lastUnit;
		item = this.item(index, 1);
		if (!item) {
			item = new TableItem();
			this.setItem(index, 1, item);
		}
		cell = this.indexEl(
			this.d.tableModel().index(index, 1),
		);
		if (cell && (cell instanceof TierTableDataCell)) {
			const inpWasEnabled = cell.isInputEnabled();
			cell.setInputEnabled(true);
			cell.setDisabled(lastUnit === Number.POSITIVE_INFINITY);
			if (!inpWasEnabled) {
				Obj.connect(
					cell, 'inputTextChanged',
					this, '_p_cellInputTextChanged',
				);
			}
		}
		item.setData(
			ItemDataRole.EditRole,
			new Variant(lastUnit),
		);
		// UNIT PRICE
		item = this.item(index, 2);
		if (!item) {
			item = new TableItem();
			this.setItem(index, 2, item);
		}
		cell = this.indexEl(
			this.d.tableModel().index(index, 2),
		);
		if (cell && (cell instanceof TierTableDataCell)) {
			cell.setInputEnabled(true);
		}
		item.setData(
			ItemDataRole.EditRole,
			new Variant(unitPrice),
		);
		// DELETE BUTTON
		if ((index > 0) && Number.isFinite(lastUnit)) {
			item = this.item(index, 3);
			if (!item) {
				item = new TableItem();
				this.setItem(index, 3, item);
			}
			cell = this.indexEl(
				this.d.tableModel().index(index, 3),
			);
			if (cell && (cell instanceof TierTableDataCell)) {
				Obj.connect(
					cell, 'toolButtonClicked',
					this, 'cellToolButtonClicked');
			}
			item.setData(
				ItemDataRole.DecorationRole,
				new Variant('clear'),
			);
		}
		// Is this second to last row
		if ((isNumber(lastUnit) && Number.isFinite(lastUnit)) && (index === (this.rowCount() - 2))) {
			// Next row's first unit (next row is last row)
			const lastRowIdx = this.d.tableModel().index(index + 1, 0);
			if (lastRowIdx.isValid()) {
				const lastRowFirstUnitItem = this.item(lastRowIdx.row(), lastRowIdx.column());
				if (lastRowFirstUnitItem) {
					lastRowFirstUnitItem.setData(ItemDataRole.EditRole, new Variant(lastUnit + 1))
				}
			}
		}
	}

	private updateInputNames(): void {
		for (let row = 0; row < this.rowCount(); ++row) {
			this.setRowInputName(row);
		}
	}

	private validInBetweenRowIdx(rowIdx: number): boolean {
		if (rowIdx <= 0) {
			// First row is sticky; can't delete that one.
			logger.warning('validRowIdx: Invalid row index: %s', rowIdx);
			return false;
		}
		if (rowIdx >= (this.rowCount() - 1)) {
			// Last row is sticky; cant delete that one.
			logger.warning('validRowIdx: Invalid row index: %s', rowIdx);
			return false;
		}
		return true;
	}
}

@OBJ
class TierTableDataCell extends DataCell {
	private toolButton: ToolButton | null;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-price-tier-table-cell',
		);
		super(opts);
		this.toolButton = null;
	}

	protected changeEvent(event: Evt): void {
		if (event.type() === Evt.EnabledChange) {
			if (this.toolButton) {
				this.toolButton.setDisabled(!this.isEnabled());
			}
		}
		super.changeEvent(event);
	}

	destroy(): void {
		if (this.toolButton) {
			this.toolButton.destroy();
		}
		this.toolButton = null;
		super.destroy();
	}

	setData(data: Variant, role: ItemDataRole): boolean {
		if (!data.isValid()) {
			return super.setData(data, role);
		}
		switch (role) {
			case ItemDataRole.DisplayRole:
			case ItemDataRole.EditRole: {
				this.setInputText(data.toString());
				return true;
			}
			case ItemDataRole.DecorationRole: {
				if (!data.isNull()) {
					if (!this.toolButton) {
						this.toolButton = new ToolButton({
							classNames: [
								'lb-price-create-table-row-tool-button',
							],
							parent: this,
						});
						this.toolButton.show();
						Obj.connect(
							this.toolButton, 'clicked',
							this, '_toolButtonClicked');
					}
					let icon = this.toolButton.icon();
					if (!icon) {
						icon = new Icon();
						this.toolButton.setIcon(icon);
					}
					icon.setName(data.toString());
				} else {
					if (this.toolButton) {
						this.toolButton.destroy();
					}
					this.toolButton = null;
				}
				return true;
			}
			default: {
				return super.setData(data, role);
			}
		}
	}

	setInputEnabled(enabled: boolean): void {
		if (enabled === Boolean(this.inputEl)) {
			return;
		}
		super.setInputEnabled(enabled);
		if (enabled) {
			this.inputEl = new TextInput({
				name: this.inputElName || undefined,
				noLabel: true,
				parent: this,
				underlinedLessDenseNoPadding: false,
				underlinedLessDenseNoPaddingWhiteBackground: false,
				underlinedLessDenseWhiteBackground: false,
			});
			this.inputEl.show();
			Obj.connect(
				this.inputEl, 'textChanged',
				this, '_inputTextChanged');
		} else {
			if (this.inputEl) {
				this.inputEl.destroy();
			}
			this.inputEl = null;
		}
	}

	@SIGNAL
	private toolButtonClicked(row: number, column: number, checked: boolean, point: Point): void {
	}

	@SLOT
	private _toolButtonClicked(checked: boolean, point: Point): void {
		this.toolButtonClicked(...this._idx(), checked, point);
	}
}
