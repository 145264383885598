import mapboxgl from 'mapbox-gl';

import {MapboxDraw} from '../draw';
import {MapEventType} from '../map';
import {Obj, OBJ, SLOT} from '../../../obj';
import {Point, PointLike, set} from '../../../tools';
import {IMapboxDrawOptions} from '../draw/mapboxdraw';
import {assert, emptyGeoJsonFeatureCollection, featureIdString, isGeoJsonFeatureId, isIterable} from '../../../util';
import {CssClassName, MapControlType, MapDrawMode} from '../../../constants';
import {InteractiveMapControl, InteractiveMapControlOpts, InteractiveMapControlPrivate} from './control';
import {InteractiveMapControlButton, InteractiveMapControlButtonOpts, InteractiveMapControlButtonPrivate} from './button';
import {getLogger} from '../../../logging';

const logger = getLogger('ui.map.drawcontrol');

enum ButtonAction {
	NoAction,
	DrawCircle,
	CombineFeatures,
	DrawLineString,
	DrawPoint,
	DrawPolygon,
	Trash,
	UncombineFeatures,
}

const ephemeralAxns = new set([
	ButtonAction.Trash,
	ButtonAction.NoAction,
	ButtonAction.CombineFeatures,
	ButtonAction.UncombineFeatures,
]);

class ButtonPrivate extends InteractiveMapControlButtonPrivate {
	action: ButtonAction;

	constructor() {
		super();
		this.action = ButtonAction.NoAction;
	}

	init(opts: Partial<ButtonOpts>): void {
		super.init(opts);
		if (opts.action !== undefined) {
			this.action = opts.action;
		}
		const q = this.q;
		Obj.connect(
			q, 'clicked',
			q, '_clicked',
		);
	}

	get q(): Button {
		return <Button>super.q;
	}
}

interface ButtonOpts extends InteractiveMapControlButtonOpts {
	action: ButtonAction;
	dd: ButtonPrivate;
}

@OBJ
class Button extends InteractiveMapControlButton {
	constructor(opts: Partial<ButtonOpts> = {}) {
		opts.classNames = InteractiveMapControlButton.mergeClassNames(
			opts.classNames,
			CssClassName.ControlButton,
		);
		opts.dd = opts.dd || new ButtonPrivate();
		super(opts);
	}

	activate(): void {
		const par = this.parentControl();
		if (!par) {
			return;
		}
		const pd = par.d;
		if (pd.activeBtn === this) {
			// Nothing to do.
			return;
		}
		const axn = this.d.action;
		if (ephemeralAxns.has(axn)) {
			switch (axn) {
				case ButtonAction.Trash: {
					par.trash();
					break;
				}
				case ButtonAction.CombineFeatures: {
					par.combineFeatures();
					break;
				}
				case ButtonAction.UncombineFeatures: {
					par.uncombineFeatures();
					break;
				}
			}
		} else {
			// Deactivate currently active button
			if (pd.activeBtn) {
				pd.activeBtn.deactivate();
			}
			this.addClass(
				CssClassName.ActiveButton,
			);
			// Set as currently active button
			pd.activeBtn = this;
			// Ensure parent is active
			if (!par.isActive()) {
				par.activate();
			}
			par.setMode(
				pd.buttonActionDrawMode(axn),
			);
		}
	}

	@SLOT
	_clicked(): void {
		// If currently active, this click is to toggle activation, thus
		// deactivation. This also means that, once we deactivate, there will
		// not follow an immediate activation of another button, so we should
		// ensure parent ctrl is also deactivated.
		const wasActive = this.isActive();
		this.setActive(!wasActive);
		if (wasActive) {
			const par = this.parentControl();
			if (par) {
				par.deactivate();
			}
		}
	}

	get d(): ButtonPrivate {
		return <ButtonPrivate>super.d;
	}

	deactivate(): void {
		this.removeClass(
			CssClassName.ActiveButton,
		);
		const par = this.parentControl();
		if (!par) {
			return;
		}
		const pd = par.d;
		if (pd.activeBtn === this) {
			pd.activeBtn = null;
		}
	}

	destroy(): void {
		const p = this.parentControl();
		if (p) {
			if (p.d.activeBtn === this) {
				p.d.activeBtn = null;
			}
		}
		super.destroy();
	}

	isActive(): boolean {
		const p = this.parentControl();
		return !!p && (p.d.activeBtn === this);
	}

	parentControl(): DrawControl | null {
		let curr = this.parentEl();
		while (curr) {
			if (curr instanceof DrawControl) {
				return curr;
			}
			curr = curr.parentEl();
		}
		return null;
	}

	setActive(active: boolean): void {
		if (active) {
			this.activate();
		} else {
			this.deactivate();
		}
	}
}

interface BtnCfg {
	action: ButtonAction;
	className: string;
	enabled: boolean;
	toolTip: string;
}

const mapInteractionNames: Array<MapInteractionType> = [
	'boxZoom',
	'doubleClickZoom',
	'dragPan',
	'dragRotate',
	'keyboard',
	'scrollZoom',
	'touchZoomRotate',
];

export class DrawControlPrivate extends InteractiveMapControlPrivate {
	activeBtn: Button | null;
	ctrl: MapboxDraw | null;
	ctrlOpts: Partial<IMapboxDrawOptions>;
	mapInteractionCfg: Map<MapInteractionType, boolean>;

	constructor() {
		super();
		this.activeBtn = null;
		this.ctrl = null;
		this.ctrlOpts = {};
		this.mapInteractionCfg = new Map();
	}

	buttonActionDrawMode(axn: ButtonAction): MapDrawMode {
		switch (axn) {
			case ButtonAction.DrawPolygon: {
				return MapDrawMode.DrawPolygon;
			}
			case ButtonAction.DrawCircle: {
				return MapDrawMode.DrawCircle;
			}
			case ButtonAction.DrawPoint: {
				return MapDrawMode.DrawPoint;
			}
			case ButtonAction.DrawLineString: {
				return MapDrawMode.DrawLineString;
			}
			default: {
				return MapDrawMode.NoMode;
			}
		}
	}

	captureMapInteractionState(map: mapboxgl.Map): void {
		for (const name of mapInteractionNames) {
			this.setMapInteractionState(
				name,
				map[name].isEnabled(),
			);
		}
	}

	*childButtons(): IterableIterator<Button> {
		for (const child of this.children) {
			if (child instanceof Button) {
				yield child;
			}
		}
	}

	ctrlInstance(): MapboxDraw {
		if (!this.ctrl) {
			this.ctrl = new MapboxDraw({
				...this.ctrlOpts,
				parent: this.q,
			});
		}
		return this.ctrl;
	}

	init(opts: Partial<DrawControlOpts>): void {
		super.init(opts);
		if (opts.boxSelect !== undefined) {
			this.ctrlOpts.boxSelect = opts.boxSelect;
		}
		if (opts.clickBuffer !== undefined) {
			this.ctrlOpts.clickBuffer = opts.clickBuffer;
		}
		if (opts.controls !== undefined) {
			this.ctrlOpts.controls = opts.controls;
		}
		if (opts.defaultMode !== undefined) {
			this.ctrlOpts.defaultMode = opts.defaultMode;
		}
		if (opts.displayControlsDefault !== undefined) {
			this.ctrlOpts.displayControlsDefault = opts.displayControlsDefault;
		}
		if (opts.keybindings !== undefined) {
			this.ctrlOpts.keybindings = opts.keybindings;
		}
		if (opts.layers !== undefined) {
			this.ctrlOpts.layers = opts.layers;
		}
		if (opts.modes !== undefined) {
			this.ctrlOpts.modes = opts.modes;
		}
		if (opts.touchBuffer !== undefined) {
			this.ctrlOpts.touchBuffer = opts.touchBuffer;
		}
		if (opts.touchEnabled !== undefined) {
			this.ctrlOpts.touchEnabled = opts.touchEnabled;
		}
		if (opts.userProperties !== undefined) {
			this.ctrlOpts.userProperties = opts.userProperties;
		}
		const q = this.q;
		Obj.connect(
			q, 'activationChanged',
			q, 'setCtrlActive',
		);
	}

	get q(): DrawControl {
		return <DrawControl>super.q;
	}

	restoreMapInteractionState(map: mapboxgl.Map): void {
		for (const [name, enabled] of this.mapInteractionCfg) {
			if (enabled) {
				map[name].enable();
			} else {
				map[name].disable();
			}
		}
	}

	setBoxSelectEnabled(enable: boolean): void {
		if (!this.map) {
			return;
		}
		if (enable) {
			this.setMapInteractionState(
				'boxZoom',
				this.map.boxZoom.isEnabled(),
			);
			this.map.boxZoom.disable();
			// Need to toggle dragPan on and off or else first  dragPan
			// disable attempt in simple select doesn't work.
			this.map.dragPan.disable();
			this.map.dragPan.enable();
		} else {
			const boxZoomIsEnabled = this.mapInteractionCfg.get(
				'boxZoom',
			);
			if ((typeof boxZoomIsEnabled === 'boolean') && (this.map.boxZoom.isEnabled() !== boxZoomIsEnabled)) {
				if (boxZoomIsEnabled) {
					this.map.boxZoom.enable();
				} else {
					this.map.boxZoom.disable();
				}
			}
		}
	}

	setMapInteractionState(name: MapInteractionType, enabled: boolean): void {
		this.mapInteractionCfg.set(name, enabled);
	}
}

export interface DrawControlOpts extends InteractiveMapControlOpts, IMapboxDrawOptions {
	dd: DrawControlPrivate;
}

@OBJ
export class DrawControl extends InteractiveMapControl {
	constructor(opts: Partial<DrawControlOpts> = {}) {
		opts.dd = opts.dd || new DrawControlPrivate();
		super(opts);
	}

	get d(): DrawControlPrivate {
		return <DrawControlPrivate>super.d;
	}

	addFeatures<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(features: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | GeoJsonFeature<G, P> | G): Array<string> {
		const d = this.d;
		return d.ctrl ?
			d.ctrl.addFeatures<G, P>(features) :
			[];
	}

	allFeatures<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
		const d = this.d;
		return d.ctrl ?
			d.ctrl.allFeatures<G, P>() :
			emptyGeoJsonFeatureCollection<G, P>();
	}

	combineFeatures(): void {
		const d = this.d;
		if (d.ctrl && d.ctrl.ctx.events.currMode) {
			d.ctrl.ctx.events.currMode.combineFeatures();
		}
	}

	deleteAllFeatures(): void {
		const d = this.d;
		if (!d.ctrl) {
			return;
		}
		d.ctrl.deleteAllFeatures();
		// If we were in direct select mode, now our selected feature no
		// longer exists, so escape that mode.
		if (d.ctrl.mode() === MapDrawMode.DirectSelect) {
			this.setMode(
				MapDrawMode.SimpleSelect,
			);
		} else {
			d.ctrl.ctx.store.render();
		}
	}

	deleteFeatures(featureIds: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): void {
		const d = this.d;
		if (!d.ctrl) {
			return;
		}
		d.ctrl.deleteFeatures(featureIds);
		// If we were in direct select mode and our selected feature no longer
		// exists (because it was deleted), we need to get out of that mode.
		if ((d.ctrl.mode() === MapDrawMode.DirectSelect) && (d.ctrl.ctx.store.getSelectedIds().length === 0)) {
			this.setMode(
				MapDrawMode.SimpleSelect,
			);
		} else {
			d.ctrl.ctx.store.render();
		}
	}

	destroy(): void {
		const d = this.d;
		d.activeBtn = null;
		// NB: Signature is dumb for a bad reason. Read the routine's docs.
		this.setCtrlActive(
			MapControlType.NoType,
			false,
		);
		if (d.ctrl) {
			d.ctrl.destroy();
		}
		d.ctrl = null;
		d.mapInteractionCfg.clear();
		super.destroy();
	}

	feature<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(featureId: GeoJsonFeatureId): GeoJsonFeature<G, P> | null {
		const d = this.d;
		return d.ctrl ?
			d.ctrl.feature<G, P>(featureId) :
			null;
	}

	features<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(featureIds: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): GeoJsonFeatureCollection<G, P> {
		const d = this.d;
		if (d.ctrl) {
			return d.ctrl.features<G, P>(featureIds);
		}
		return emptyGeoJsonFeatureCollection<G, P>();
	}

	featuresAt<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(pointLike: PointLike): GeoJsonFeatureCollection<G, P> {
		const d = this.d;
		if (d.ctrl) {
			return d.ctrl.featuresAt<G, P>(
				(pointLike instanceof Point) ?
					pointLike :
					Point.fromPointLike(pointLike),
			);
		}
		return emptyGeoJsonFeatureCollection<G, P>();
	}

	protected mapDataEvent(event: mapboxgl.MapDataEvent): void {
		if (!event.target.isStyleLoaded()) {
			return;
		}
		event.target.off(
			MapEventType.Data,
			this.mapEvent,
		);
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.addLayers();
		}
	}

	protected mapLoadEvent(event: mapboxgl.MapboxEvent): void {
		super.mapLoadEvent(event);
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.addLayers();
		}
	}

	protected mapStyleDataLoadingEvent(event: mapboxgl.MapDataEvent): void {
		event.target.on(
			MapEventType.Data,
			this.mapEvent,
		);
	}

	mode(): MapDrawMode {
		const d = this.d;
		if (d.ctrl) {
			return d.ctrl.mode();
		}
		return MapDrawMode.NoMode;
	}

	onAdd(map: mapboxgl.Map): HTMLElement {
		const d = this.d;
		if (d.map) {
			// Already ran
			return this.element();
		}
		super.onAdd(map);
		const ctrl = d.ctrlInstance();
		ctrl.ctx.map = map;
		ctrl.ctx.container = map.getContainer();
		const ctrls = ctrl.ctx.options.controls;
		const keys = ctrl.ctx.options.keybindings;
		const btnCfgs: Array<BtnCfg> = [
			{
				action: ButtonAction.DrawLineString,
				className: CssClassName.ControlButtonLineString,
				enabled: ctrls.line_string,
				toolTip: `Draw a LineString${keys ? ' (l)' : ''}`,
			},
			{
				action: ButtonAction.DrawPolygon,
				className: CssClassName.ControlButtonPolygon,
				enabled: ctrls.polygon,
				toolTip: `Draw a Polygon${keys ? ' (p)' : ''}`,
			},
			{
				action: ButtonAction.DrawCircle,
				className: CssClassName.ControlButtonCircle,
				enabled: ctrls.circle,
				toolTip: `Draw a Circle${keys ? ' (c)' : ''}`,
			},
			{
				action: ButtonAction.DrawPoint,
				className: CssClassName.ControlButtonPoint,
				enabled: ctrls.point,
				toolTip: `Draw a Point${keys ? ' (m)' : ''}`,
			},
			{
				action: ButtonAction.Trash,
				className: CssClassName.ControlButtonTrash,
				enabled: ctrls.trash,
				toolTip: 'Delete Shape',
			},
			{
				action: ButtonAction.CombineFeatures,
				className: CssClassName.ControlButtonCombineFeatures,
				enabled: ctrls.combine_features,
				toolTip: 'Combine Selected Shapes',
			},
			{
				action: ButtonAction.UncombineFeatures,
				className: CssClassName.ControlButtonUncombineFeatures,
				enabled: ctrls.uncombine_features,
				toolTip: 'Un-combine Selected Shapes',
			},
		];
		for (const cfg of btnCfgs) {
			if (!cfg.enabled) {
				continue;
			}
			const btn = new Button({
				action: cfg.action,
				classNames: cfg.className,
				parent: this,
			});
			btn.show();
			btn.setToolTip(cfg.toolTip);
		}
		if (map.loaded()) {
			ctrl.addLayers();
		} else {
			map.once(
				MapEventType.Load,
				this.mapEvent,
			);
		}
		map.on(
			MapEventType.StyleDataLoading,
			this.mapEvent,
		);
		return this.element();
	}

	onRemove(map: mapboxgl.Map): void {
		map.off(
			MapEventType.StyleDataLoading,
			this.mapEvent,
		);
		const d = this.d;
		if (d.ctrl) {
			d.ctrl.destroy();
		}
		d.ctrl = null;
		super.onRemove(map);
		this.destroy();
	}

	selectedFeatures<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
		const d = this.d;
		return d.ctrl ?
			d.ctrl.selectedFeatures<G, P>() :
			emptyGeoJsonFeatureCollection<G, P>();
	}

	@SLOT
	setActive(active: boolean): void {
		if (active === this.isActive()) {
			return;
		}
		if (!active) {
			this.setMode(MapDrawMode.NoMode);
		}
		// If this was called by a child button, that button will have already
		// set `d.activeBtn` null, this the following check will fail and the
		// cyclic event is avoided.
		//
		// However, if this was called by anything else, and if a button was
		// active at the time of call, that button will still be set on
		// `d.activeBtn` and needs to be deactivated.
		super.setActive(active);
		const d = this.d;
		if (!this.isActive()) {
			if (d.activeBtn) {
				d.activeBtn.setActive(false);
			}
		}
	}

	@SLOT
	private setCtrlActive(type: MapControlType, active: boolean): void {
		// NB: Signature is to adhere to the signal and I'm too tired to think
		//     more. Fix this soonish.
		const d = this.d;
		if (active) {
			if (d.map) {
				d.captureMapInteractionState(d.map);
			}
			const ctrl = d.ctrlInstance();
			if (ctrl.ctx.options.boxSelect) {
				d.setBoxSelectEnabled(true);
			}
			ctrl.ctx.events.addEventListeners();
		} else {
			if (d.map) {
				d.restoreMapInteractionState(d.map);
			}
			if (d.ctrl) {
				d.ctrl.clearMapClasses();
				if (d.ctrl.ctx.options.boxSelect) {
					d.setBoxSelectEnabled(true);
				}
				d.ctrl.ctx.events.removeEventListeners();
			}
		}
	}

	setFeatures<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(features: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | GeoJsonFeature<G, P>): Array<string> {
		const d = this.d;
		return d.ctrl ?
			d.ctrl.setFeatures<G, P>(features) :
			[];
	}

	setMode(mode: MapDrawMode, cfg: Partial<DrawModeCfg> = {}): void {
		if (!this.isActive()) {
			this.activate();
		}
		assert(this.isActive(), 'something bad happened');
		if (!this.isEnabled()) {
			logger.warning('setMode: not enabled but being asked to set mode "%s" and will continue.', mode);
		}
		const d = this.d;
		if (!d.ctrl) {
			return;
		}
		if (mode === d.ctrl.mode()) {
			return;
		}
		const curr = d.ctrl.ctx.events.currMode;
		d.ctrl.ctx.events.currMode = null;
		if (curr) {
			curr.stop();
		}
		let deactivate = false;
		if (mode === MapDrawMode.NoMode) {
			deactivate = true;
		} else {
			if (d.ctrl.ctx.options.modes.hasOwnProperty(mode)) {
				for (const btn of d.childButtons()) {
					if ((d.buttonActionDrawMode(btn.d.action) === mode) && !btn.isActive()) {
						btn.activate();
					}
					break;
				}
				d.ctrl.ctx.events.currMode = new d.ctrl.ctx.options.modes[mode](d.ctrl.ctx);
				d.ctrl.ctx.events.currMode.start();
				d.ctrl.ctx.events.currMode.setup(
					modeCfgToOpt(cfg),
				);
			} else {
				logger.warning('setMode: Got invalid mode %s', mode);
			}
		}
		if (d.ctrl.ctx.map) {
			const modeName: MapDrawMode = d.ctrl.ctx.events.currMode ?
				mode :
				MapDrawMode.NoMode;
			d.ctrl.ctx.map.fire(
				MapEventType.DrawModeChange,
				{mode: modeName},
			);
		}
		d.ctrl.ctx.store.setDirty(true);
		d.ctrl.ctx.store.render();
		if (deactivate) {
			this.deactivate();
		}
	}

	setSelectedFeatures(featureIds: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): void {
		const d = this.d;
		if (!d.ctrl) {
			return;
		}
		d.ctrl.setSelectedFeatures(featureIds);
		if (d.ctrl.mode() !== MapDrawMode.SimpleSelect) {
			this.setMode(
				MapDrawMode.SimpleSelect,
				{
					selectedFeatureIds: featureIds,
				},
			);
		}
	}

	trash(): void {
		const d = this.d;
		if (d.ctrl && d.ctrl.ctx.events.currMode) {
			d.ctrl.ctx.events.currMode.trash();
		}
	}

	uncombineFeatures(): void {
		const d = this.d;
		if (d.ctrl && d.ctrl.ctx.events.currMode) {
			d.ctrl.ctx.events.currMode.uncombineFeatures();
		}
	}
}

function modeCfgToOpt(cfg: Partial<DrawModeCfg>): ModeOpt | null {
	if (cfg.drawFrom && cfg.directSelectedFeatureId) {
		// DrawLineString
		let fr: GeoJsonFeature<GeoJsonPoint> | GeoJsonPoint | Array<number>;
		if (isIterable(cfg.drawFrom)) {
			fr = Array.isArray(cfg.drawFrom) ?
				cfg.drawFrom :
				Array.from(cfg.drawFrom);
		} else {
			fr = cfg.drawFrom;
		}
		return {
			featureId: featureIdString(cfg.directSelectedFeatureId),
			from: fr,
		};
	}
	if (cfg.directSelectedFeatureId) {
		// DirectSelect
		return {
			featureId: featureIdString(cfg.directSelectedFeatureId),
		};
	}
	if (cfg.selectedFeatureIds) {
		// SimpleSelect
		const ids = isGeoJsonFeatureId(cfg.selectedFeatureIds) ?
			[cfg.selectedFeatureIds] :
			Array.isArray(cfg.selectedFeatureIds) ?
				cfg.selectedFeatureIds :
				Array.from(cfg.selectedFeatureIds);
		return {
			featureIds: ids.map(x => featureIdString(x)),
		};
	}
	return null;
}
