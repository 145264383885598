import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {FancyPushButton} from '../../ui/pushbutton';
import {CircularProgress} from '../../ui/progress';
import {getLogger} from '../../logging';

const logger = getLogger('projectdetailview.downloadpay');
const flashingCssClassName = 'lb-glow-button';

export enum ActiveDownloadPayBtn {
	None,
	Download,
	Pay,
}

class DownloadPayPrivate extends ElObjPrivate {
	active: ActiveDownloadPayBtn;
	btns: Map<ActiveDownloadPayBtn, FancyPushButton>;
	prog: CircularProgress | null;

	constructor() {
		super();
		this.active = ActiveDownloadPayBtn.None;
		this.btns = new Map();
		this.prog = null;
	}

	activeButton(): FancyPushButton | null {
		return this.btns.get(this.active) || null;
	}

	button(type: ActiveDownloadPayBtn.Download | ActiveDownloadPayBtn.Pay): FancyPushButton {
		const q = this.q;
		let btn = this.btns.get(type);
		if (!btn) {
			const dl = type === ActiveDownloadPayBtn.Download;
			btn = new FancyPushButton({
				attributes: dl ?
					[
						['download', ''],
					] :
					undefined,
				classNames: [
					'green-lol',
				],
				filled: true,
				parent: q,
				tagName: dl ?
					'a' :
					undefined,
				text: dl ?
					'Download' :
					'Pay & Download',
			});
			Obj.connect(
				btn, 'clicked',
				q, 'buttonClicked',
			);
			this.btns.set(type, btn);
		}
		return btn;
	}

	progress(): CircularProgress {
		if (!this.prog) {
			this.prog = new CircularProgress({
				indeterminate: true,
				parent: this.q,
				size: 'small',
			});
		}
		return this.prog;
	}

	get q(): DownloadPay {
		return <DownloadPay>super.q;
	}
}

interface DownloadPayOpts extends ElObjOpts {
	dd: DownloadPayPrivate;
}

@OBJ
export class DownloadPay extends ElObj {
	constructor(opts: Partial<DownloadPayOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['id', 'lb-project-detail-download-pay'],
		);
		opts.dd = opts.dd || new DownloadPayPrivate();
		super(opts);
	}

	@SIGNAL
	private buttonClicked(): void {
	}

	currentButton(): ActiveDownloadPayBtn {
		return this.d.active;
	}

	@SIGNAL
	private currentButtonChanged(which: ActiveDownloadPayBtn): void {
	}

	get d(): DownloadPayPrivate {
		return <DownloadPayPrivate>super.d;
	}

	isButtonFlashing(): boolean {
		const btn = this.d.activeButton();
		if (btn) {
			return btn.hasClass(flashingCssClassName);
		}
		return false;
	}

	// private makeDownloadButton(url: string): FancyPushButton {
	// 	return new FancyPushButton({
	// 		attributes: [
	// 			['download', ''],
	// 			['href', url],
	// 		],
	// 		classNames: [
	// 			'green-lol',
	// 		],
	// 		filled: true,
	// 		text: 'Download',
	// 		tagName: 'a',
	// 	});
	// }
	//
	// private makePayButton(): FancyPushButton {
	// 	return new FancyPushButton({
	// 		classNames: [
	// 			'green-lol',
	// 		],
	// 		filled: true,
	// 		text: 'Pay & Download',
	// 	});
	// }

	setButtonFlashing(flashing: boolean): void {
		const btn = this.d.activeButton();
		if (btn) {
			btn.setClass(
				flashing,
				flashingCssClassName,
			);
		}
	}

	showButton(which: ActiveDownloadPayBtn, url?: string): void {
		this.setProgressVisible(false);
		const d = this.d;
		if (d.active === which) {
			const b = d.activeButton();
			if (b && b.isHidden()) {
				b.show();
			}
			return;
		}
		if ((which === ActiveDownloadPayBtn.Download) && (url === undefined)) {
			logger.error('showButton: Must specify a URL for download button.');
			return;
		}
		const curr = d.btns.get(d.active);
		if (curr) {
			curr.hide();
		}
		d.active = which;
		if (d.active !== ActiveDownloadPayBtn.None) {
			const btn = d.button(d.active);
			if (d.active === ActiveDownloadPayBtn.Download) {
				// The following assertion holds from the previous check at
				// beginning of routine.
				btn.setAttribute('href', <string>url);
			}
			btn.show();
		}
		this.currentButtonChanged(
			d.active,
		);
	}

	@SLOT
	showDownloadButton(url: string): void {
		this.showButton(
			ActiveDownloadPayBtn.Download,
			url,
		);
	}

	@SLOT
	showPayButton(): void {
		this.showButton(
			ActiveDownloadPayBtn.Pay,
		);
	}

	@SLOT
	showProgress(): void {
		const d = this.d;
		const btn = d.activeButton();
		if (btn) {
			btn.hide();
		}
		const prog = d.progress();
		prog.show();
	}

	setButtonEnabled(enabled: boolean): void {
		const btn = this.d.activeButton();
		if (btn) {
			btn.setEnabled(enabled);
		}
	}

	setProgressVisible(visible: boolean): void {
		const d = this.d;
		if (d.prog) {
			d.prog.setVisible(visible);
		}
	}
}
