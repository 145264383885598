import mapboxgl from 'mapbox-gl';

import {Mode} from './modes/mode';
import {Point} from '../../../tools';
import {IMapboxDrawContext} from './mapboxdraw';
import {Cursor, MapDrawMode} from '../../../constants';
import {featuresAtClick, featuresAtTouch} from './featuresat';
import {bind, closestMatchingElement, euclideanDistance, isTap} from '../../../util';

export enum EvtType {
	None = 0,
	EnabledChange = 98,
	Enter = 10,
	KeyPress = 6,
	KeyRelease = 7,
	Leave = 11,
	MouseButtonClick = 1,
	MouseButtonPress = 2,
	MouseButtonRelease = 3,
	MouseDrag = 599,
	MouseMove = 5,
	TouchBegin = 194,
	TouchDrag = 377,
	TouchEnd = 196,
	TouchTap = 454,
	TouchUpdate = 195,
}

export class Evt {
	needsRender: boolean;
	type: EvtType;

	constructor(type: EvtType) {
		this.needsRender = true;
		this.type = type;
	}
}

export class FeatureEvt extends Evt {
	featureTarget: mapboxgl.MapboxGeoJSONFeature | null;

	constructor(type: EvtType, featureTarget: mapboxgl.MapboxGeoJSONFeature | null) {
		super(type);
		this.featureTarget = featureTarget;
	}
}

export class KeyboardEvt extends Evt {
	event: KeyboardEvent;

	constructor(type: EvtType, event: KeyboardEvent) {
		super(type);
		this.event = event;
	}
}

export class MapMouseEvt extends Evt {
	event: mapboxgl.MapMouseEvent;

	constructor(type: EvtType, event: mapboxgl.MapMouseEvent) {
		super(type);
		this.event = event;
	}
}

export class MapTouchEvt extends Evt {
	event: mapboxgl.MapTouchEvent;

	constructor(type: EvtType, event: mapboxgl.MapTouchEvent) {
		super(type);
		this.event = event;
	}
}

export class MapMouseFeatureEvt extends MapMouseEvt implements FeatureEvt {
	featureTarget: mapboxgl.MapboxGeoJSONFeature | null;

	constructor(type: EvtType, event: mapboxgl.MapMouseEvent, featureTarget: mapboxgl.MapboxGeoJSONFeature | null) {
		super(type, event);
		this.featureTarget = featureTarget;
	}
}

export class MapTouchFeatureEvt extends MapTouchEvt implements FeatureEvt {
	featureTarget: mapboxgl.MapboxGeoJSONFeature | null;

	constructor(type: EvtType, event: mapboxgl.MapTouchEvent, featureTarget: mapboxgl.MapboxGeoJSONFeature | null) {
		super(type, event);
		this.featureTarget = featureTarget;
	}
}

export class MouseEvt extends Evt {
	event: MouseEvent;

	constructor(type: EvtType, event: MouseEvent) {
		super(type);
		this.event = event;
	}
}

const domEvtTypes = [
	'mouseout',
	'keydown',
	'keyup',
];
const mapEvtTypes = [
	'mousemove',
	'mousedown',
	'mouseup',
	'data',
	'touchmove',
	'touchstart',
	'touchend',
];

export class EventThing {
	ctx!: IMapboxDrawContext; // Set where instantiated just after construction
	currMode: Mode | null;
	private mouseDownInfo: IPointerEventInfo;
	private touchStartInfo: IPointerEventInfo;

	constructor() {
		this.currMode = null;
		this.mouseDownInfo = {
			point: {
				x: 0,
				y: 0,
			},
			time: 0,
		};
		this.touchStartInfo = {
			point: {
				x: 0,
				y: 0,
			},
			time: 0,
		};
	}

	addEventListeners(): void {
		const map = this.ctx.map;
		if (map) {
			for (const type of mapEvtTypes) {
				map.on(type, this.event);
			}
		}
		const cont = this.ctx.container;
		if (cont) {
			for (const type of domEvtTypes) {
				cont.addEventListener(type, this.event);
			}
		}
	}

	currentModeName(): MapDrawMode {
		return this.currMode ?
			this.currMode.name :
			MapDrawMode.NoMode;
	}

	currentModeRender(feature: FeatureInternalFeature, display: (feature: FeatureInternalFeature) => any): void {
		this.currMode && this.currMode.render(feature, display);
	}

	private dataEvent(event: mapboxgl.MapDataEvent): void {
		const map: mapboxgl.Map | null = this.ctx.map;
		if ((event.dataType === 'style') && map) {
			const hasLayers: boolean = this.ctx.options.layers.some(style => map.getLayer(style.id));
			if (!hasLayers) {
				this.ctx.api.addLayers();
				this.ctx.store.setDirty(true);
				this.ctx.store.render();
			}
		}
	}

	@bind
	private event(event: {type: string}): void {
		switch (event.type) {
			case 'mousemove':
				this.mouseMoveEvent(<mapboxgl.MapMouseEvent>event);
				break;
			case 'touchmove':
				this.touchMoveEvent(<mapboxgl.MapTouchEvent>event);
				break;
			case 'data':
				this.dataEvent(<mapboxgl.MapDataEvent>event);
				break;
			case 'mousedown':
				this.mouseDownEvent(<mapboxgl.MapMouseEvent>event);
				break;
			case 'mouseup':
				this.mouseUpEvent(<mapboxgl.MapMouseEvent>event);
				break;
			case 'mouseout':
				this.mouseOutEvent(<MouseEvent>event);
				break;
			case 'touchstart':
				this.touchStartEvent(<mapboxgl.MapTouchEvent>event);
				break;
			case 'touchend':
				this.touchEndEvent(<mapboxgl.MapTouchEvent>event);
				break;
			case 'keydown':
				this.keyDownEvent(<KeyboardEvent>event);
				break;
			case 'keyup':
				this.keyUpEvent(<KeyboardEvent>event);
				break;
		}
	}

	private keyDownEvent(event: KeyboardEvent): void {
		const tgt: EventTarget | null = event.target;
		if (tgt && !closestMatchingElement(<Element>tgt, '.mapboxgl-canvas, .lb-map-control')) {
			return;
		} // we only handle events on the map
		const key: string = event.key;
		switch (key) {
			case 'Backspace':
			case 'Delete': {
				// if (this.ctx.options.controls.trash && this.currMode) {
				// 	event.preventDefault();
				// 	this.currMode.trash();
				// }
				if (this.currMode) {
					if (this.ctx.options.controls.trash || (this.currentModeName() === MapDrawMode.DirectSelect)) {
						event.preventDefault();
						this.currMode.trash();
					}
				}
				break;
			}
			case 'Alt': {
				if (this.currMode) {
					this.currMode.setTransient(true);
				}
				break;
			}
		}
		if (isKeyModeValid(key) && this.currMode) {
			this.currMode.event(new KeyboardEvt(EvtType.KeyPress, event));
		}
	}

	private keyUpEvent(event: KeyboardEvent): void {
		if (this.currMode) {
			if (isKeyModeValid(event.key)) {
				this.currMode.event(new KeyboardEvt(EvtType.KeyRelease, event));
			}
			if (event.key === 'Alt') {
				this.currMode.setTransient(false);
			}
		}
	}

	private mouseDownEvent(event: mapboxgl.MapMouseEvent): void {
		this.mouseDownInfo = {
			time: Date.now(),
			point: event.point,
		};
		if (this.currMode) {
			this.currMode.event(
				new MapMouseFeatureEvt(
					EvtType.MouseButtonPress,
					event,
					getFeatureAndSetCursor(event.point, this.ctx)));
		}
	}

	private mouseDragEvent(event: mapboxgl.MapMouseEvent, currMode: Mode): void {
		if (isClick(this.mouseDownInfo, {point: event.point, time: Date.now()})) {
			event.originalEvent.stopPropagation();
		} else {
			this.ctx.api.setMapClasses({mouse: Cursor.Drag});
			currMode.event(new MapMouseEvt(EvtType.MouseDrag, event));
		}
	}

	private mouseMoveEvent(event: mapboxgl.MapMouseEvent): void {
		if (this.currMode) {
			if (event.originalEvent.buttons === 1) {
				this.mouseDragEvent(event, this.currMode);
			} else {
				this.currMode.event(
					new MapMouseFeatureEvt(
						EvtType.MouseMove,
						event,
						getFeatureAndSetCursor(event.point, this.ctx)));
			}
		}
	}

	private mouseOutEvent(event: MouseEvent): void {
		if (this.currMode) {
			this.currMode.event(new MouseEvt(EvtType.Leave, event));
		}
	}

	private mouseUpEvent(event: mapboxgl.MapMouseEvent): void {
		if (this.currMode) {
			const typ = isClick(this.mouseDownInfo, {point: event.point, time: Date.now()}) ?
				EvtType.MouseButtonClick :
				EvtType.MouseButtonRelease;
			this.currMode.event(
				new MapMouseFeatureEvt(
					typ,
					event,
					getFeatureAndSetCursor(event.point, this.ctx)));
		}
	}

	removeEventListeners(): void {
		const map = this.ctx.map;
		if (map) {
			for (const type of mapEvtTypes) {
				map.off(type, this.event);
			}
		}
		const cont = this.ctx.container;
		if (cont) {
			for (const type of domEvtTypes) {
				cont.removeEventListener(type, this.event);
			}
		}
	}

	private touchDragEvent(event: mapboxgl.MapTouchEvent, currMode: Mode): void {
		if (isTap(this.touchStartInfo, {point: event.point, time: Date.now()})) {
			event.originalEvent.stopPropagation();
		} else {
			this.ctx.api.setMapClasses({mouse: Cursor.Drag});
			currMode.event(new MapTouchEvt(EvtType.TouchDrag, event));
		}
	}

	private touchEndEvent(event: mapboxgl.MapTouchEvent): void {
		if (this.ctx.options.touchEnabled && this.currMode) {
			event.originalEvent.preventDefault();
			const tgts: Array<mapboxgl.MapboxGeoJSONFeature> = featuresAtTouch(
				Point.fromPointLike([
					event.point.x,
					event.point.y,
				]),
				null,
				this.ctx);
			const typ = isTap(this.touchStartInfo, {time: Date.now(), point: event.point}) ?
				EvtType.TouchTap :
				EvtType.TouchEnd;
			this.currMode.event(
				new MapTouchFeatureEvt(
					typ,
					event,
					(tgts.length > 0) ?
						tgts[0] :
						null));
		}
	}

	private touchMoveEvent(event: mapboxgl.MapTouchEvent): void {
		if (this.ctx.options.touchEnabled && this.currMode) {
			event.originalEvent.preventDefault();
			this.currMode.event(
				new MapTouchEvt(
					EvtType.TouchUpdate,
					event));
			this.touchDragEvent(event, this.currMode);
		}
	}

	private touchStartEvent(event: mapboxgl.MapTouchEvent): void {
		this.touchStartInfo = {
			time: Date.now(),
			point: event.point,
		};
		if (this.ctx.options.touchEnabled && this.currMode) {
			// Prevent emulated mouse events because we will fully handle the touch here.
			// This does not stop the touch events from propagating to mapbox though.
			event.originalEvent.preventDefault();
			const tgts: Array<mapboxgl.MapboxGeoJSONFeature> = featuresAtTouch(
				Point.fromPointLike([
					event.point.x,
					event.point.y,
				]),
				null,
				this.ctx);
			this.currMode.event(
				new MapTouchFeatureEvt(
					EvtType.TouchBegin,
					event,
					(tgts.length > 0) ?
						tgts[0] :
						null));
		}
	}
}

function isKeyModeValid(key: string): boolean {
	switch (key) {
		case 'Backspace':
		case 'Delete':
		case '0':
		case '1':
		case '2':
		case '3':
		case '4':
		case '5':
		case '6':
		case '7':
		case '8':
		case '9':
			return false;
	}
	return true;
}

function getFeatureAndSetCursor(point: IGenericPoint, ctx: IMapboxDrawContext): mapboxgl.MapboxGeoJSONFeature | null {
	const features = featuresAtClick(
		Point.fromPointLike([
			point.x,
			point.y,
		]),
		null,
		ctx,
	);
	return features[0] || null;
}

function isClick(startInfo: IPointerEventInfo, endInfo: IPointerEventInfo): boolean {
	const FINE_TOLERANCE = 4;
	const GROSS_TOLERANCE = 12;
	const INTERVAL = 500;
	startInfo.point = startInfo.point || endInfo.point;
	startInfo.time = startInfo.time || endInfo.time;
	const moveDistance = euclideanDistance(startInfo.point, endInfo.point);
	return (moveDistance < FINE_TOLERANCE) || ((moveDistance < GROSS_TOLERANCE) && ((endInfo.time - startInfo.time) < INTERVAL));
}

export function isMapMouseEvent(event: any): event is mapboxgl.MapMouseEvent {
	return event.originalEvent instanceof MouseEvent;
}
