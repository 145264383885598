import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {list} from '../../tools';
import {LayoutChangeHint, Orientation, SortOrder} from '../../constants';
import {AbstractItemModel, AbstractItemModelPrivate, ModelIndex, PersistentModelIndex} from '../../abstractitemmodel';
import {assert, clamp, isNumber} from '../../util';
import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';

class SectionItem {
	isHidden: boolean;
	size: number;
	tmpLogIdx: number;

	constructor(size: number = 0, isHidden: boolean = false) {
		this.isHidden = isHidden;
		this.size = size;
		this.tmpLogIdx = -1;
	}
}

class LayoutChangeItem {
	index: PersistentModelIndex;
	section: SectionItem;

	constructor(index: PersistentModelIndex, section: SectionItem) {
		this.index = index;
		this.section = section;
	}
}

enum ViewState {
	NoState,
	ResizeSection,
	MoveSection,
	SelectSections,
	NoClear,
}

export class HeaderViewPrivate extends ElObjPrivate {
	defaultSectionSize: number;
	layoutChangePersistentSections: list<LayoutChangeItem>;
	logicalIndices: list<number>; // logicalIndex = row or column in the model
	model: AbstractItemModel;
	orientation: Orientation;
	root: PersistentModelIndex;
	sectionItems: list<SectionItem>;
	sortIndicatorOrder: SortOrder;
	sortIndicatorSection: number;
	sortIndicatorShown: boolean;
	state: ViewState;
	visualIndices: list<number>; // visualIndex = visualIndices.at(logicalIndex)

	constructor() {
		super();
		this.defaultSectionSize = 100;
		this.layoutChangePersistentSections = new list();
		this.logicalIndices = new list();
		this.model = AbstractItemModelPrivate.staticEmptyModel();
		this.orientation = Orientation.Horizontal;
		this.root = new PersistentModelIndex();
		this.sectionItems = new list<SectionItem>();
		this.sortIndicatorOrder = SortOrder.DescendingOrder;
		this.sortIndicatorSection = -1;
		this.sortIndicatorShown = false;
		this.state = ViewState.NoState;
		this.visualIndices = new list();
	}

	clear(): void {
		if (this.state !== ViewState.NoClear) {
			this.visualIndices.clear();
			this.logicalIndices.clear();
			this.sectionItems.clear();
		}
	}

	createSectionItems(start: number, end: number, size: number): void {
		const sizePerSection = size / (end - start + 1);
		if (end >= this.sectionItems.size()) {
			for (let i = 0; i < (end - this.sectionItems.size()); ++i) {
				this.sectionItems.append(new SectionItem());
			}
		}
		for (let i = start; i <= end; ++i) {
			this.sectionItems.at(i).size = sizePerSection;
		}
	}

	logicalIndex(visualIndex: number): number {
		return this.logicalIndices.isEmpty() ? visualIndex : this.logicalIndices.at(visualIndex);
	}

	modelSectionCount(): number {
		return (this.orientation === Orientation.Horizontal) ? this.model.columnCount(this.root) : this.model.rowCount(this.root);
	}

	removeSectionsFromSectionItems(start: number, end: number): void {
		this.sectionItems.remove(start, end - start + 1);
	}

	sectionCount(): number {
		return this.sectionItems.size();
	}

	_p_sectionsAboutToBeChanged(parents?: Array<PersistentModelIndex>, hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		if (((hint === LayoutChangeHint.VerticalSortHint) && (this.orientation === Orientation.Horizontal)) || ((hint === LayoutChangeHint.HorizontalSortHint) && (this.orientation === Orientation.Vertical))) {
			return;
		}
		// If there is no row/column we can't have mapping for columns because no ModelIndex in the model would be valid. this is far from being bullet-proof and we would need a real system to map columns or rows persistently
		if (((this.orientation === Orientation.Horizontal) && (this.model.rowCount(this.root) === 0)) || (this.model.columnCount(this.root) === 0)) {
			return;
		}
		this.layoutChangePersistentSections.clear();
		// after layoutChanged another section can be last stretched section
		for (let i = 0; i < this.sectionItems.size(); ++i) {
			const s = this.sectionItems.at(i);
			// only add if the section is not default and not visually moved
			if ((s.size === this.defaultSectionSize) && !s.isHidden) {
				continue;
			}
			const logical = this.logicalIndex(i);
			if (s.isHidden) {
				// s.size = this.hiddenSectionSize.value(logical);
			}
			// ### note that we are using column or row 0
			this.layoutChangePersistentSections.append(new LayoutChangeItem(new PersistentModelIndex((this.orientation === Orientation.Horizontal) ? this.model.index(0, logical, this.root) : this.model.index(logical, 0, this.root)), s));
		}
	}

	_p_sectionsAboutToBeMoved(sourceParent: ModelIndex, logicalStart: number, logicalEnd: number, destinationParent: ModelIndex, logicalDestination: number): void {
		if (sourceParent.ne(this.root) || destinationParent.ne(this.root)) {
			// we only handle changes in the root level
			return;
		}
		this._p_sectionsAboutToBeChanged();
	}

	_p_sectionsChanged(parents?: Array<PersistentModelIndex>, hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		if (((hint === LayoutChangeHint.VerticalSortHint) && (this.orientation === Orientation.Horizontal)) || ((hint === LayoutChangeHint.HorizontalSortHint) && (this.orientation === Orientation.Vertical))) {
			return;
		}
		const oldPersistentSections = this.layoutChangePersistentSections;
		this.layoutChangePersistentSections.clear();
		const newCount = this.modelSectionCount();
		const oldCount = this.sectionItems.size();
		const q = <HeaderView>this.q;
		if (newCount === 0) {
			this.clear();
			if (oldCount !== 0) {
				q.sectionCountChanged(oldCount, 0);
			}
			return;
		}
		let hasPersistentIndexes = false;
		for (const item of oldPersistentSections) {
			if (item.index.isValid()) {
				hasPersistentIndexes = true;
				break;
			}
		}
		if (!hasPersistentIndexes) {
			if (oldCount !== newCount) {
				q.initializeSections();
			}
			return;
		}
		// adjust section size
		if (newCount !== oldCount) {
			q.initializeSections(clamp(0, oldCount, newCount - 1), newCount - 1);
		}
		// reset sections
		for (let i = 0; i < newCount; ++i) {
			if (i >= this.sectionItems.size()) {
				this.sectionItems.append(new SectionItem(this.defaultSectionSize));
			} else {
				this.sectionItems.replace(i, new SectionItem(this.defaultSectionSize));
			}
		}
		for (const item of oldPersistentSections) {
			const index = item.index;
			if (!index.isValid()) {
				continue;
			}
			const newLogicalIndex = ((this.orientation === Orientation.Horizontal) ? index.column() : index.row());
			// the new visualIndices are already adjusted / reset by initializeSections()
			const newVisualIndex = this.visualIndex(newLogicalIndex);
			if (newVisualIndex < this.sectionItems.size()) {
				const newSection = item.section;
				this.sectionItems.replace(newVisualIndex, newSection);
				if (newSection.isHidden) {
					// otherwise setSectionHidden will return without doing anything
					newSection.isHidden = false;
				}
			}
		}
	}

	_p_sectionsMoved(sourceParent: ModelIndex, logicalStart: number, logicalEnd: number, destinationParent: ModelIndex, logicalDestination: number): void {
		if (sourceParent.ne(this.root) || destinationParent.ne(this.root)) {
			// we only handle changes in the root level
			return;
		}
		this._p_sectionsChanged();
	}

	_p_sectionsRemoved(parent: ModelIndex, logicalFirst: number, logicalLast: number): void {
		if (parent.ne(this.root)) {
			// we only handle changes in the root level
			return;
		}
		if ((Math.min(logicalFirst, logicalLast) < 0) || (Math.max(logicalLast, logicalFirst) >= this.sectionCount())) {
			return;
		}
		const q = <HeaderView>this.q;
		const oldCount = q.count();
		const changeCount = logicalLast - logicalFirst + 1;
		if (this.visualIndices.isEmpty() && this.logicalIndices.isEmpty()) {
			this.removeSectionsFromSectionItems(logicalFirst, logicalLast);
		} else {
			if (logicalFirst === logicalLast) {
				// Remove just one index.
				const l = logicalFirst;
				const visual = this.visualIndices.at(l);
				assert(this.sectionCount() === this.logicalIndices.size());
				for (let v = 0; v < this.sectionCount(); ++v) {
					if (v > visual) {
						const logical = this.logicalIndices.at(v);
						this.visualIndices.replace(logical, this.visualIndices.at(logical) - 1);
					}
					if (this.logicalIndex(v) > l) {
						// no need to move the positions before l
						this.logicalIndices.replace(v, this.logicalIndices.at(v) - 1);
					}
				}
				this.logicalIndices.remove(visual);
				this.visualIndices.remove(l);
				this.removeSectionsFromSectionItems(visual, visual);
			} else {
				for (let u = 0; u < this.sectionItems.size(); ++u) {
					// Store section info
					this.sectionItems.at(u).tmpLogIdx = this.logicalIndices.at(u);
				}
				for (let v = this.sectionItems.size() - 1; v >= 0; --v) {
					// Remove the sections
					if ((logicalFirst <= this.sectionItems.at(v).tmpLogIdx) && (this.sectionItems.at(v).tmpLogIdx <= logicalLast)) {
						this.removeSectionsFromSectionItems(v, v);
					}
				}
				this.visualIndices.resize(this.sectionItems.size(), -1);
				this.logicalIndices.resize(this.sectionItems.size(), -1);
				for (let w = 0; w < this.sectionItems.size(); ++w) {
					// Restore visual and logical indexes
					let logIdx = this.sectionItems.at(w).tmpLogIdx;
					if (logIdx > logicalFirst) {
						logIdx -= changeCount;
					}
					this.visualIndices.replace(logIdx, w);
					this.logicalIndices.replace(w, logIdx);
				}
			}
		}
		// update sorting column
		if (this.sortIndicatorSection >= logicalFirst) {
			if (this.sortIndicatorSection <= logicalLast) {
				this.sortIndicatorSection = -1;
			} else {
				this.sortIndicatorSection -= changeCount;
			}
		}
		// if we only have the last section (the "end" position) left, the header is empty
		if (this.sectionCount() <= 0) {
			this.clear();
		}
		q.sectionCountChanged(oldCount, q.count());
	}

	visualIndex(logicalIndex: number): number {
		return this.visualIndices.isEmpty() ? logicalIndex : this.visualIndices.at(logicalIndex);
	}
}

export interface HeaderViewOpts extends ElObjOpts {
	dd: HeaderViewPrivate;
}

@OBJ
export class HeaderView extends ElObj {
	constructor(opts: Partial<HeaderViewOpts> = {}) {
		opts.dd = opts.dd || new HeaderViewPrivate();
		super(opts);
	}

	get d(): HeaderViewPrivate {
		return <HeaderViewPrivate>super.d;
	}

	count(): number {
		return this.d.sectionCount();
	}

	@SLOT
	headerDataChanged(orientation: Orientation, logicalFirst: number, logicalLast: number) {
		const d = this.d;
		if (d.orientation !== orientation) {
			return;
		}
		if ((logicalFirst < 0) || (logicalLast < 0) || (logicalFirst >= this.count()) || (logicalLast >= this.count())) {
			return;
		}
	}

	initializeSections(start: number, end: number): void;
	initializeSections(): void;
	initializeSections(a?: number, b?: number): void {
		const d = this.d;
		if (isNumber(a) && isNumber(b)) {
			const start = a;
			const end = b;
			assert(start >= 0);
			assert(end >= 0);
			const oldCount = d.sectionCount();
			if ((end + 1) < d.sectionCount()) {
				d.removeSectionsFromSectionItems(end + 1, d.sectionCount() - 1);
			}
			const newSectionCount = end + 1;
			if (!d.logicalIndices.isEmpty()) {
				if (oldCount <= newSectionCount) {
					d.logicalIndices.resize(newSectionCount, -1);
					d.visualIndices.resize(newSectionCount, -1);
					for (let i = oldCount; i < newSectionCount; ++i) {
						d.logicalIndices.replace(i, i);
						d.visualIndices.replace(i, i);
					}
				} else {
					let j = 0;
					for (let i = 0; i < oldCount; ++i) {
						const v = d.logicalIndices.at(i);
						if (v < newSectionCount) {
							d.logicalIndices.replace(j, v);
							d.visualIndices.replace(v, j);
							j++;
						}
					}
					d.logicalIndices.resize(newSectionCount, -1);
					d.visualIndices.resize(newSectionCount, -1);
				}
			}
			if (newSectionCount > oldCount) {
				d.createSectionItems(start, end, (end - start + 1) * d.defaultSectionSize);
			}
			if (d.sectionCount() !== oldCount) {
				this.sectionCountChanged(oldCount, d.sectionCount());
			}
		} else {
			const oldCount = d.sectionCount();
			const newCount = d.modelSectionCount();
			if (newCount <= 0) {
				d.clear();
				this.sectionCountChanged(oldCount, 0);
			} else if (newCount !== oldCount) {
				const min = clamp(0, oldCount, newCount - 1);
				this.initializeSections(min, newCount - 1);
			}
		}
	}

	isSortIndicatorShown(): boolean {
		return this.d.sortIndicatorShown;
	}

	logicalIndex(visualIndex: number): number {
		// FIXME: NOT IMPLEMENTED
		return -1;
	}

	model(): AbstractItemModel | null {
		return (this.d.model === AbstractItemModelPrivate.staticEmptyModel()) ?
			null :
			this.d.model;
	}

	@SIGNAL
	sectionCountChanged(oldCount: number, newCount: number): void {
	}

	@SLOT
	private _p_sectionsAboutToBeChanged(parents: Array<PersistentModelIndex> = [], hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		this.d._p_sectionsAboutToBeChanged(parents, hint);
	}

	@SLOT
	sectionsAboutToBeRemoved(parent: ModelIndex, logicalFirst: number, logicalLast: number): void {
		// This slot is called when sections are removed from the parent.
		// logicalFirst and logicalLast signify where the sections were
		// removed.
		//
		// If only one section is removed, logicalFirst and logicalLast will
		// be the same.
	}

	@SLOT
	private _p_sectionsAboutToBeMoved(sourceParent: ModelIndex, logicalStart: number, logicalEnd: number, destinationParent: ModelIndex, logicalDestination: number): void {
		this.d._p_sectionsAboutToBeMoved(sourceParent, logicalStart, logicalEnd, destinationParent, logicalDestination);
	}

	@SLOT
	private _p_sectionsChanged(parents: Array<PersistentModelIndex> = [], hint: LayoutChangeHint = LayoutChangeHint.NoLayoutChangeHint): void {
		this.d._p_sectionsChanged(parents, hint);
	}

	@SLOT
	sectionsInserted(parent: ModelIndex, logicalFirst: number, logicalLast: number): void {
		// This slot is called when sections are inserted into the parent.
		// logicalFirst and logicalLast indices signify where the new sections
		// were inserted.
		//
		// If only one section is inserted, logicalFirst and logicalLast will
		// be the same.
		const d = this.d;
		if (parent.ne(d.root)) {
			return; // we only handle changes in the root level
		}
		const oldCount = d.sectionCount();
		const insertAt = logicalFirst;
		const insertCount = logicalLast - logicalFirst + 1;
		const section = new SectionItem(d.defaultSectionSize);
		if (d.sectionItems.isEmpty() || (insertAt >= d.sectionItems.size())) {
			// append
			d.sectionItems.insert(d.sectionItems.size(), insertCount, section);
		} else {
			// separate them out into their own sections
			d.sectionItems.insert(insertAt, insertCount, section);
		}
		// update sorting column
		if (d.sortIndicatorSection >= logicalFirst) {
			d.sortIndicatorSection += insertCount;
		}
		// update mapping
		if (!d.visualIndices.isEmpty() && !d.logicalIndices.isEmpty()) {
			assert(d.visualIndices.size() === d.logicalIndices.size());
			const mappingCount = d.visualIndices.size();
			for (let i = 0; i < mappingCount; ++i) {
				if (d.visualIndices.at(i) >= logicalFirst) {
					d.visualIndices.replace(i, d.visualIndices.at(i) + insertCount);
				}
				if (d.logicalIndices.at(i) >= logicalFirst) {
					d.logicalIndices.replace(i, d.logicalIndices.at(i) + insertCount);
				}
			}
			for (let j = logicalFirst; j <= logicalLast; ++j) {
				d.visualIndices.insert(j, j);
				d.logicalIndices.insert(j, j);
			}
		}
		this.sectionCountChanged(oldCount, this.count());
	}

	@SLOT
	_p_sectionsMoved(sourceParent: ModelIndex, logicalStart: number, logicalEnd: number, destinationParent: ModelIndex, logicalDestination: number): void {
		this.d._p_sectionsMoved(sourceParent, logicalStart, logicalEnd, destinationParent, logicalDestination);
	}

	@SLOT
	private _p_sectionsRemoved(parent: ModelIndex, logicalFirst: number, logicalLast: number): void {
		this.d._p_sectionsRemoved(parent, logicalFirst, logicalLast);
	}

	setModel(model: AbstractItemModel | null): void {
		if (model === this.model()) {
			return;
		}
		const d = this.d;
		d.layoutChangePersistentSections.clear();
		if (d.model && d.model !== AbstractItemModelPrivate.staticEmptyModel()) {
			if (d.orientation === Orientation.Horizontal) {
				Obj.disconnect(
					d.model, 'columnsInserted',
					this, 'sectionsInserted');
				Obj.disconnect(
					d.model, 'columnsAboutToBeRemoved',
					this, 'sectionsAboutToBeRemoved');
				Obj.disconnect(
					d.model, 'columnsRemoved',
					this, '_p_sectionsRemoved');
				Obj.disconnect(
					d.model, 'columnsAboutToBeMoved',
					this, '_p_sectionsAboutToBeMoved');
				Obj.disconnect(
					d.model, 'columnsMoved',
					this, '_p_sectionsMoved');
			} else {
				Obj.disconnect(
					d.model, 'rowsInserted',
					this, 'sectionsInserted');
				Obj.disconnect(
					d.model, 'rowsAboutToBeRemoved',
					this, 'sectionsAboutToBeRemoved');
				Obj.disconnect(
					d.model, 'rowsRemoved',
					this, '_p_sectionsRemoved');
				Obj.disconnect(
					d.model, 'rowsAboutToBeMoved',
					this, '_p_sectionsAboutToBeMoved');
				Obj.disconnect(
					d.model, 'rowsMoved',
					this, '_p_sectionsMoved');
			}
			Obj.disconnect(
				d.model, 'headerDataChanged',
				this, 'headerDataChanged');
			Obj.disconnect(
				d.model, 'layoutAboutToBeChanged',
				this, '_p_sectionsAboutToBeChanged');
			Obj.disconnect(
				d.model, 'layoutChanged',
				this, '_p_sectionsChanged');
		}
		if (model && model !== AbstractItemModelPrivate.staticEmptyModel()) {
			if (d.orientation === Orientation.Horizontal) {
				Obj.connect(
					model, 'columnsInserted',
					this, 'sectionsInserted');
				Obj.connect(
					model, 'columnsAboutToBeRemoved',
					this, 'sectionsAboutToBeRemoved');
				Obj.connect(
					model, 'columnsRemoved',
					this, '_p_sectionsRemoved');
				Obj.connect(
					model, 'columnsAboutToBeMoved',
					this, '_p_sectionsAboutToBeMoved');
				Obj.connect(
					model, 'columnsMoved',
					this, '_p_sectionsMoved');
			} else {
				Obj.connect(
					model, 'rowsInserted',
					this, 'sectionsInserted');
				Obj.connect(
					model, 'rowsAboutToBeRemoved',
					this, 'sectionsAboutToBeRemoved');
				Obj.connect(
					model, 'rowsRemoved',
					this, '_p_sectionsRemoved');
				Obj.connect(
					model, 'rowsAboutToBeMoved',
					this, '_p_sectionsAboutToBeMoved');
				Obj.connect(
					model, 'rowsMoved',
					this, '_p_sectionsMoved');
			}
			Obj.connect(
				model, 'headerDataChanged',
				this, 'headerDataChanged');
			Obj.connect(
				model, 'layoutAboutToBeChanged',
				this, '_p_sectionsAboutToBeChanged');
			Obj.connect(
				model, 'layoutChanged',
				this, '_p_sectionsChanged');
		}
		d.state = ViewState.NoState;
		this.initializeSections();
	}

	setSortIndicator(logicalIndex: number, order: SortOrder): void {
		const d = this.d;
		const old = d.sortIndicatorSection;
		if ((old === logicalIndex) && (order === d.sortIndicatorOrder)) {
			return;
		}
		d.sortIndicatorSection = logicalIndex;
		d.sortIndicatorOrder = order;
		this.sortIndicatorChanged(logicalIndex, order);
	}

	setSortIndicatorShown(shown: boolean): void {
		const d = this.d;
		if (d.sortIndicatorShown === shown) {
			return;
		}
		d.sortIndicatorShown = shown;
		if ((this.sortIndicatorSection() < 0) || (this.sortIndicatorSection() > this.count())) {
			return;
		}
	}

	@SIGNAL
	sortIndicatorChanged(logicalIndex: number, order: SortOrder): void {
	}

	sortIndicatorOrder(): SortOrder {
		return this.d.sortIndicatorOrder;
	}

	sortIndicatorSection(): number {
		return this.d.sortIndicatorSection;
	}

	visualIndex(logicalIndex: number): number {
		// FIXME: NOT IMPLEMENTED
		return -1;
	}
}
