import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import {ItemDataRole} from '../../constants';
import {Variant} from '../../variant';
import {ToolButton} from '../../ui/toolbutton';
import {Dialog} from '../../ui/dialog';
import {FancyPushButton} from '../../ui/pushbutton';
import {getLogger} from '../../logging';
import {list} from '../../tools';
import {DataTableView} from '../../ui/datatable';
import {ModelIndex} from '../../abstractitemmodel';
import {TableItem} from '../../ui/itemviews';
import {DoNotMail, GeoRef} from '../../models';
import {DataCell, DataTable} from '../../ui/datatable/el';
import {Icon} from '../../ui/icon';

const logger = getLogger('views.donotmail');

@OBJ
class DoNotMailTableCellEl extends DataCell {
	private toolButton: ToolButton | null;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-do-not-mail-table-cell',
		);
		super(opts);
		this.toolButton = null;
	}

	private btn(): ToolButton {
		if (!this.toolButton) {
			this.toolButton = new ToolButton({
				classNames: [
					'lb-do-not-mail-table-row-tool-button',
				],
				parent: this,
			});
			this.toolButton.show();
			Obj.connect(
				this.toolButton, 'clicked',
				this, '_toolButtonClicked',
			);
		}
		return this.toolButton;
	}

	destroy(): void {
		this.toolButton = null;
		super.destroy();
	}

	setData(data: Variant, role: ItemDataRole): boolean {
		switch (role) {
			case ItemDataRole.DecorationRole: {
				if (data.isValid()) {
					const iconName = data.toString();
					if (iconName.length > 0) {
						const btn = this.btn();
						let icon = btn.icon();
						if (!icon) {
							icon = new Icon();
							btn.setIcon(icon);
						}
						icon.setName(iconName);
					} else {
						if (!this.toolButton) {
							// Nothing to do.
						} else {
							const icon = this.toolButton.icon();
							if (icon) {
								icon.setName();
							}
						}
					}
				}
				break;
			}
			default: {
				return super.setData(data, role);
			}
		}
		return true;
	}

	@SIGNAL
	private _clicked2(row: number, column: number): void {
	}

	@SLOT
	private _toolButtonClicked(): void {
		this._clicked2(...this._idx());
	}
}

@OBJ
export class DoNotMailView extends ElObj {
	private currentIndex: ModelIndex;
	private doNotMailList: list<DoNotMail>;
	private dialog: Dialog | null;
	private dialogBody: DialogBody | null;
	private tableView: DataTableView;

	constructor(opts: Partial<ElObjOpts> = {}) {
		super(opts);
		this.currentIndex = new ModelIndex();
		this.doNotMailList = new list();
		this.dialog = null;
		this.dialogBody = null;
		const tbl = new DataTable({
			classNames: [
				'width--100-percent',
			],
			dataCellPrototype: DoNotMailTableCellEl,
		});
		tbl.show();
		this.tableView = new DataTableView(
			{
				primaryTable: tbl,
			},
		);
		this.tableView.show();
		this.init();
	}

	@SLOT
	private cellClicked(row: number, column: number): void {
		if (column !== 0) {
			return;
		}
		const idx = this.tableView.d.tableModel().index(row, column);
		if (idx.isValid()) {
			this.currentIndex = idx;
			this.confirmDelete();
		} else {
			logger.error('cellClicked: Invalid index (%s, %s)', row, column);
		}
	}

	private confirmDelete(): void {
		this.destroyDialog();
		this.dialogBody = new DialogBody();
		this.dialogBody.show();
		Obj.connect(
			this.dialogBody, 'canceled',
			this, 'removalCanceled');
		Obj.connect(
			this.dialogBody, 'confirmed',
			this, 'removalConfirmed');
		// this.dialog = new Dialog({title: 'Remove from do not mail list?'});
		// Obj.connect(
		// 	this.dialog, 'closed',
		// 	this, 'dialogClosed');
		// this.dialog.appendElObj(this.dialogBody);
		// this.dialog.show();
	}

	private async deleteGeoRef(pk: GeoRefPk): Promise<void> {
		const ref = await GeoRef.get(pk);
		await ref.delete();
	}

	destroy(): void {
		this.currentIndex = new ModelIndex();
		this.doNotMailList.clear();
		this.destroyDialog();
		this.tableView.destroy();
		super.destroy();
	}

	private destroyDialog(): void {
		if (this.dialogBody) {
			this.dialogBody.destroy();
		}
		this.dialogBody = null;
		if (this.dialog) {
			this.dialog.destroy();
		}
		this.dialog = null;
	}

	@SLOT
	private dialogClosed(): void {
		this.destroyDialog();
	}

	private async init(): Promise<void> {
		Obj.connect(
			this.tableView,
			'cellClicked',
			this,
			'cellClicked',
		);
		this.tableView.setParent(this);
		this.tableView.setColumnCount(4);
		this.tableView.setHorizontalHeaderLabels([
			'',
			'Name',
			'Parcel Address',
			'Mailing Address',
		]);
		this.tableView.showProgressIndicator();
		this.doNotMailList = new list(await DoNotMail.list());
		if (this.doNotMailList.size() > 0) {
			await this.setTableItems(this.doNotMailList);
		} else {
			this.tableView.hide();
			const el = new ElObj({
				classNames: [
					'text-align--center',
				],
				parent: this,
				tagName: 'div',
			});
			el.show();
			el.setText('Nothing here yet');
		}
		this.tableView.hideProgressIndicator();
	}

	@SLOT
	private removalCanceled(): void {
		this.currentIndex = new ModelIndex();
		this.destroyDialog();
	}

	@SLOT
	private async removalConfirmed(): Promise<void> {
		if (this.currentIndex.isValid()) {
			const obj = this.doNotMailList.at(this.currentIndex.row());
			await this.deleteGeoRef(await obj.pk());
			this.destroyDialog();
			window.location.reload();
		} else {
			logger.error('removalConfirmed: Current index is invalid');
		}
	}

	private async setTableItems(objs: list<DoNotMail>): Promise<void> {
		this.tableView.setRowCount(objs.size());
		for (let row = 0; row < objs.size(); ++row) {
			const obj = objs.at(row);
			// ToolButton
			let item = new TableItem();
			this.tableView.setItem(row, 0, item);
			item.setData(
				ItemDataRole.DecorationRole,
				new Variant('clear'),
			);
			// Name
			item = new TableItem();
			this.tableView.setItem(row, 1, item);
			item.setData(ItemDataRole.EditRole, new Variant(await obj.ownerName()));
			// Parcel address
			let street = addrStr(await obj.parcelAddress());
			let nop: boolean = false;
			if (street.length < 1) {
				street = 'No data';
				nop = true;
			}
			item = new TableItem();
			this.tableView.setItem(row, 2, item);
			item.setData(ItemDataRole.EditRole, new Variant(street));
			if (nop) {
				item.setData(
					ItemDataRole.ForegroundRole,
					new Variant('#A3ACB9'));
			}
			// Owner (mailing) address
			item = new TableItem();
			this.tableView.setItem(row, 3, item);
			item.setData(ItemDataRole.EditRole, new Variant(addrStr(await obj.ownerAddress())));
		}
	}
}

function addrStr(addr: IAddress): string {
	const parts = [
		addr.street,
		addr.city,
		addr.state,
	];
	const keep: Array<string> = [];
	for (let i = 0; i < parts.length; ++i) {
		const part = parts[i].trim();
		if (part.length > 0) {
			keep.push(part);
		}
	}
	return keep.join(', ');
}

@OBJ
class DialogBody extends ElObj {
	private buttonBox: ElObj;
	cancelButton: FancyPushButton;
	submitButton: FancyPushButton;

	constructor(opts: Partial<ElObjOpts> = {}) {
		super(opts);
		this.buttonBox = new ElObj({
			classNames: [
				'lb-button-box',
				'display--flex',
				'flex-direction--row',
				'justify-content--flex-end',
			],
			parent: this,
			tagName: 'div',
		});
		this.buttonBox.show();
		this.cancelButton = new FancyPushButton({
			filled: true,
			parent: this.buttonBox,
			text: 'Cancel',
		});
		this.cancelButton.show();
		Obj.connect(
			this.cancelButton, 'clicked',
			this, 'canceled');
		this.submitButton = new FancyPushButton({
			parent: this.buttonBox,
			text: 'Remove Record',
		});
		this.submitButton.show();
		Obj.connect(
			this.submitButton, 'clicked',
			this, 'confirmed');
	}

	@SIGNAL
	private canceled(): void {
	}

	@SIGNAL
	private confirmed(): void {
	}
}
