import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {OBJ} from '../obj';
import {AbstractButton} from './abstractbutton';

const outlinedCssClassName = 'mdc-card--outlined';

export class CardPrivate extends ElObjPrivate {
	actionBox: ActionBox | null;
	media: Media | null;
	outlined: boolean;
	primaryAction: PrimaryAction | null;

	constructor() {
		super();
		this.actionBox = null;
		this.media = null;
		this.outlined = false;
		this.primaryAction = null;
	}

	init(opts: Partial<CardOpts>): void {
		super.init(opts);
		if (typeof opts.outlined === 'boolean') {
			this.q.setOutlined(opts.outlined);
		}
	}

	get q(): Card {
		return <Card>super.q;
	}
}

export interface CardOpts extends ElObjOpts {
	dd: CardPrivate;
	outlined: boolean;
}

@OBJ
export class Card extends ElObj {
	constructor(opts: Partial<CardOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-card',
		);
		opts.dd = opts.dd || new CardPrivate();
		super(opts);
	}

	actionHref(): string {
		const d = this.d;
		return d.primaryAction ?
			d.primaryAction.href() :
			'';
	}

	get d(): CardPrivate {
		return <CardPrivate>super.d;
	}

	isOutlined(): boolean {
		return this.d.outlined;
	}

	mediaUrl(): string {
		const d = this.d;
		return d.media ?
			d.media.mediaUrl() :
			'';
	}

	setActionHref(href: string): void {
		const d = this.d;
		if (!d.primaryAction) {
			d.primaryAction = new PrimaryAction({
				parent: this,
			});
			d.primaryAction.show();
		}
		d.primaryAction.setHref(href);
	}

	setMediaUrl(url: string): void {
		const d = this.d;
		if (!d.media) {
			d.media = new Media({
				parent: d.primaryAction || this,
			});
			d.media.show();
		}
		d.media.setMediaUrl(url);
	}

	setOutlined(outlined: boolean): void {
		const d = this.d;
		if (outlined === d.outlined) {
			return;
		}
		d.outlined = outlined;
		this.setClass(
			d.outlined,
			outlinedCssClassName,
		);
	}
}

export class ActionBoxPrivate extends ElObjPrivate {
	pushButtonBox: ElObj | null;
	toolButtonBox: ElObj | null;

	constructor() {
		super();
		this.pushButtonBox = null;
		this.toolButtonBox = null;
	}

	get q(): ActionBox {
		return <ActionBox>super.q;
	}
}

export interface ActionBoxOpts extends ElObjOpts {
	dd: ActionBoxPrivate;
}

@OBJ
export class ActionBox extends ElObj {
	constructor(opts: Partial<ActionBoxOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-card__actions',
		);
		opts.dd = opts.dd || new ActionBoxPrivate();
		super(opts);
	}

	addPushButton(button: AbstractButton): void {
		const d = this.d;
		if (!d.pushButtonBox) {
			d.pushButtonBox = new ElObj({
				classNames: 'mdc-card__action-buttons',
				parent: this,
			});
			d.pushButtonBox.show();
		}
		if (d.pushButtonBox.children().indexOf(button) >= 0) {
			return;
		}
		button.addClass(
			'mdc-card__action',
			'mdc-card__action--button',
		);
		button.setParent(d.pushButtonBox);
		button.show();
	}

	addToolButton(button: AbstractButton): void {
		const d = this.d;
		if (!d.toolButtonBox) {
			if (!d.pushButtonBox) {
				d.pushButtonBox = new ElObj({
					classNames: 'mdc-card__action-buttons',
					parent: this,
				});
				d.pushButtonBox.show();
			}
			d.toolButtonBox = new ElObj({
				classNames: 'mdc-card__action-icons',
				parent: this,
			});
			d.toolButtonBox.show();
		}
		if (d.toolButtonBox.children().indexOf(button) >= 0) {
			return;
		}
		button.addClass(
			'mdc-card__action',
			'mdc-card__action--icon',
		);
		button.setParent(d.toolButtonBox);
		button.show();
	}

	get d(): ActionBoxPrivate {
		return <ActionBoxPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.pushButtonBox = null;
		d.toolButtonBox = null;
		super.destroy();
	}

	takePushButton(button: AbstractButton): void {
		const d = this.d;
		if (d.pushButtonBox) {
			const idx = d.pushButtonBox.children().indexOf(button);
			if (idx >= 0) {
				button.removeClass('mdc-card__action');
				button.removeClass('mdc-card__action--button');
				if (button.parentEl() === d.pushButtonBox) {
					button.setParent(null);
				}
			}
		}
	}

	takeToolButton(button: AbstractButton): void {
		const d = this.d;
		if (d.toolButtonBox) {
			const idx = d.toolButtonBox.children().indexOf(button);
			if (idx >= 0) {
				button.removeClass('mdc-card__action');
				button.removeClass('mdc-card__action--icon');
				if (button.parentEl() === d.toolButtonBox) {
					button.setParent(null);
				}
			}
		}
	}
}

export enum MediaAspectRatio {
	NotSpecified,
	OneByOne,
	SixteenByNine,
}

const oneByOneCssClassName = 'mdc-card__media--square';
const sixteenByNineCssClassName = 'mdc-card__media--16-9';

export class MediaPrivate extends ElObjPrivate {
	aspectRatio: MediaAspectRatio;
	content: MediaContent | null;
	url: string;

	constructor() {
		super();
		this.aspectRatio = MediaAspectRatio.NotSpecified;
		this.content = null;
		this.url = '';
	}

	init(opts: Partial<MediaOpts>): void {
		super.init(opts);
		if (opts.aspectRatio !== undefined) {
			this.q.setAspectRatio(opts.aspectRatio);
		}
	}

	get q(): Media {
		return <Media>super.q;
	}
}

export interface MediaOpts extends ElObjOpts {
	aspectRatio: MediaAspectRatio;
	dd: MediaPrivate;
}

@OBJ
export class Media extends ElObj {
	static OneByOne: MediaAspectRatio.OneByOne = MediaAspectRatio.OneByOne;
	static SixteenByNine: MediaAspectRatio.SixteenByNine = MediaAspectRatio.SixteenByNine;
	static NotSpecified: MediaAspectRatio.NotSpecified = MediaAspectRatio.NotSpecified;

	constructor(opts: Partial<MediaOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-card__media',
		);
		opts.dd = opts.dd || new MediaPrivate();
		super(opts);
	}

	aspectRatio(): MediaAspectRatio {
		return this.d.aspectRatio;
	}

	content(): MediaContent | null {
		return this.d.content;
	}

	get d(): MediaPrivate {
		return <MediaPrivate>super.d;
	}

	mediaUrl(): string {
		return this.d.url;
	}

	setAspectRatio(aspectRatio: MediaAspectRatio): void {
		const d = this.d;
		if (aspectRatio === d.aspectRatio) {
			return;
		}
		switch (d.aspectRatio) {
			case MediaAspectRatio.SixteenByNine: {
				this.removeClass(sixteenByNineCssClassName);
				break;
			}
			case MediaAspectRatio.OneByOne: {
				this.removeClass(oneByOneCssClassName);
				break;
			}
		}
		d.aspectRatio = aspectRatio;
		switch (d.aspectRatio) {
			case MediaAspectRatio.SixteenByNine: {
				this.addClass(sixteenByNineCssClassName);
				break;
			}
			case MediaAspectRatio.OneByOne: {
				this.addClass(oneByOneCssClassName);
				break;
			}
		}
	}

	setContent(content: MediaContent | null): void {
		const d = this.d;
		if (content === d.content) {
			return;
		}
		if (d.content) {
			d.content.setParent(null);
		}
		d.content = content;
		if (d.content) {
			d.content.setParent(this);
		}
	}

	setMediaUrl(url: string): void {
		url = url.trim();
		const d = this.d;
		if (url === d.url) {
			return;
		}
		d.url = url;
		if (d.url.length > 0) {
			this.setStyleProperty(
				'background-image',
				`url("${d.url}")`,
			);
		} else {
			this.removeStyleProperty('background-image');
		}
	}
}

@OBJ
export class MediaContent extends ElObj {
	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-card__media-content',
		);
		super(opts);
	}
}

@OBJ
export class PrimaryAction extends ElObj {
	static tagName: TagName = 'a';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['tabindex', '0'],
		);
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-card__primary-action',
		);
		super(opts);
	}

	element(): HTMLAnchorElement {
		return <HTMLAnchorElement>super.element();
	}

	href(): string {
		return this.element().href;
	}

	setHref(href: string): void {
		this.element().href = href;
	}
}
