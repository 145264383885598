import {OBJ} from '../../../obj';
import {
	PushButton,
	PushButtonOpts,
	PushButtonPrivate,
} from '../../pushbutton';

export class InteractiveMapControlButtonPrivate extends PushButtonPrivate {
	get q(): InteractiveMapControlButton {
		return <InteractiveMapControlButton>super.q;
	}
}

export interface InteractiveMapControlButtonOpts extends PushButtonOpts {
	dd: InteractiveMapControlButtonPrivate;
}

@OBJ
export class InteractiveMapControlButton extends PushButton {
	constructor(opts: Partial<InteractiveMapControlButtonOpts> = {}) {
		opts.classNames = PushButton.mergeClassNames(
			opts.classNames,
			'lb-map-control-button',
		);
		opts.dd = opts.dd || new InteractiveMapControlButtonPrivate();
		super(opts);
	}

	get d(): InteractiveMapControlButtonPrivate {
		return <InteractiveMapControlButtonPrivate>super.d;
	}
}
