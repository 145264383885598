import {Icon} from '../../icon';
import {ElObj} from '../../../elobj';
import {Cursor} from '../../../constants';
import {Obj, OBJ, SLOT} from '../../../obj';
import {
	InteractiveMapControlButton,
	InteractiveMapControlButtonOpts,
} from './button';
import {
	InteractiveMapControl,
	InteractiveMapControlOpts,
	InteractiveMapControlPrivate,
} from './control';

@OBJ
class InfoButton extends InteractiveMapControlButton {
	constructor(opts: Partial<InteractiveMapControlButtonOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-map-control-info-button',
		);
		super(opts);
		new Icon({
			name: 'info',
			outlined: true,
			parent: this,
		});
		this.setToolTip('Get Info');
	}
}

export class InfoControlPrivate extends InteractiveMapControlPrivate {
	init(opts: Partial<InfoControlOpts>): void {
		super.init(opts);
		const q = this.q;
		const btn = new InfoButton({
			parent: q,
		});
		btn.setCheckable(true);
		Obj.connect(
			btn, 'toggled',
			q, 'setActive',
		);
		Obj.connect(
			q, 'activationChanged',
			q, 'setCursorForCurrentActivation',
		);
	}

	get q(): InfoControl {
		return <InfoControl>super.q;
	}
}

export interface InfoControlOpts extends InteractiveMapControlOpts {
	dd: InfoControlPrivate;
}

@OBJ
export class InfoControl extends InteractiveMapControl {
	constructor(opts: Partial<InfoControlOpts> = {}) {
		opts.dd = opts.dd || new InfoControlPrivate();
		super(opts);
	}

	get d(): InfoControlPrivate {
		return <InfoControlPrivate>super.d;
	}

	@SLOT
	private setCursorForCurrentActivation(): void {
		this.setCursor(
			this.isActive() ?
				Cursor.Crosshair :
				Cursor.Default,
		);
	}
}
