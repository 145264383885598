import {OBJ} from '../../obj';
import {Layout, LayoutOpts, LayoutPrivate} from './layout';
import {CardItem} from './card';

export class CardLayoutPrivate extends LayoutPrivate {
	init(opts: Partial<CardLayoutOpts>): void {
		super.init(opts);
	}

	get q(): CardLayout {
		return <CardLayout>super.q;
	}
}

export interface CardLayoutOpts extends LayoutOpts {
	dd: CardLayoutPrivate;
}

@OBJ
export class CardLayout extends Layout {
	constructor(opts: Partial<CardLayoutOpts> = {}) {
		opts.dd = opts.dd || new CardLayoutPrivate();
		super(opts);
	}

	addItem(item: CardItem): void {
		this.addEl(item);
		super.addItem(item);
	}

	get d(): CardLayoutPrivate {
		return <CardLayoutPrivate>super.d;
	}

	newItem(): CardItem {
		return new CardItem();
	}
}
