import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import {ToolButton} from '../../ui/toolbutton';
import {Icon} from '../../ui/icon';

@OBJ
export class UndoView extends ElObj {
	private buttons: {redo: ToolButton; undo: ToolButton;};
	private disabled: boolean;
	private txt: {redo: ElObj; undo: ElObj;};

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'display--flex',
			'flex-direction--row',
			'align-items--center',
			'padding-top--8',
			'justify-content--center',
		);
		super(opts);
		this.disabled = false;
		const undoContainer = new ElObj({
			classNames: [
				'display--flex',
				'flex-direction--column',
				'align-items--center',
				'margin-right--16',
			],
			parent: this,
			tagName: 'div',
		});
		undoContainer.show();
		const undoLabel = new ElObj({
			classNames: [
				'mdc-typography',
				'mdc-typography--body2',
				'lb-project-detail-undo-redo-label--disabled',
			],
			parent: undoContainer,
			tagName: 'span',
		});
		undoLabel.show();
		undoLabel.setText('undo');
		const redoContainer = new ElObj({
			classNames: [
				'display--flex',
				'flex-direction--column',
				'align-items--center',
				'margin-left--16',
			],
			parent: this,
			tagName: 'div',
		});
		redoContainer.show();
		const redoLabel = new ElObj({
			classNames: [
				'mdc-typography',
				'mdc-typography--body2',
				'lb-project-detail-undo-redo-label--disabled',
			],
			parent: redoContainer,
			tagName: 'span',
		});
		redoLabel.show();
		redoLabel.setText('redo');
		this.buttons = {
			redo: new ToolButton({
				classNames: [
					'lb-project-detail-undo-redo-action',
				],
				parent: redoContainer,
			}),
			undo: new ToolButton({
				classNames: [
					'lb-project-detail-undo-redo-action',
				],
				parent: undoContainer,
			}),
		};
		this.buttons.redo.setIcon(new Icon({name: 'redo'}));
		this.buttons.undo.setIcon(new Icon({name: 'undo'}));
		this.buttons.redo.show();
		this.buttons.undo.show();
		this.buttons.undo.setDisabled(true);
		this.buttons.redo.setDisabled(true);
		this.txt = {redo: redoLabel, undo: undoLabel};
		Obj.connect(
			this.buttons.redo, 'clicked',
			this, 'redoClicked',
		);
		Obj.connect(
			this.buttons.undo, 'clicked',
			this, 'undoClicked',
		);
	}

	@SIGNAL
	private actionActivated(action: 'redo' | 'undo'): void {
	}

	@SLOT
	private canRedoChanged(canRedo: boolean): void {
		this.buttons.redo.setEnabled(canRedo);
		this.txt.redo.setClass(!canRedo, 'lb-project-detail-undo-redo-label--disabled');
	}

	@SLOT
	private canUndoChanged(canUndo: boolean): void {
		this.buttons.undo.setEnabled(canUndo);
		this.txt.undo.setClass(!canUndo, 'lb-project-detail-undo-redo-label--disabled');
	}

	@SLOT
	private redoClicked(): void {
		this.actionActivated('redo');
	}

	@SLOT
	private redoTextChanged(redoText: string): void {
		this.buttons.redo.setAttribute(
			'title',
			(redoText.trim().length > 0) ?
				`redo ${redoText}` :
				'',
		);
	}

	@SLOT
	private undoClicked(): void {
		this.actionActivated('undo');
	}

	@SLOT
	private undoTextChanged(undoText: string): void {
		this.buttons.undo.setAttribute(
			'title',
			(undoText.trim().length > 0) ?
				`undo ${undoText}` :
				'',
		);
	}
}
