import {OBJ, PROP, SIGNAL, SLOT} from '../obj';
import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {Evt, KeyEvt, MouseEvt} from '../evt';
import {Key, MouseButton} from '../constants';
import {Icon} from './icon';

export class AbstractButtonPrivate extends ElObjPrivate {
	checkable: boolean;
	checked: boolean;
	down: boolean;
	icon: Icon;
	pressed: boolean;

	constructor() {
		super();
		this.checkable = false;
		this.checked = false;
		this.down = false;
		this.icon = new Icon();
		this.pressed = false;
	}

	get q(): AbstractButton {
		return <AbstractButton>super.q;
	}
}

export interface AbstractButtonOpts extends ElObjOpts {
	dd: AbstractButtonPrivate;
}

@OBJ
export class AbstractButton extends ElObj {
	constructor(opts: Partial<AbstractButtonOpts> = {}) {
		opts.dd = opts.dd || new AbstractButtonPrivate();
		super(opts);
	}

	protected changeEvent(event: Evt): void {
		const d = this.d;
		if ((event.type() === Evt.EnabledChange) && !this.isEnabled() && d.down) {
			d.down = false;
			this.released();
		}
		super.changeEvent(event);
	}

	checkStateSet(): void {
		/**
		 * Called when setChecked() is used, unless it is called from within
		 * nextCheckState(). It allows subclasses to reset their intermediate
		 * button states.
		 */
	}

	@SLOT
	click(): void {
		if (!this.isEnabled()) {
			return;
		}
		const d = this.d;
		d.down = true;
		this.pressed();
		d.down = false;
		this.nextCheckState();
		this.released();
		this.clicked(
			this.isChecked(),
		);
	}

	@SIGNAL
	protected clicked(checked: boolean = false): void {
	}

	get d(): AbstractButtonPrivate {
		return <AbstractButtonPrivate>super.d;
	}

	event(event: Evt): boolean {
		// Unlike other objects, disabled buttons accept mouse events. This
		// avoids surprising click-through scenarios.
		if (!this.isEnabled()) {
			switch (event.type()) {
				case Evt.MouseMove:
				case Evt.HoverMove:
				case Evt.Wheel:
				case Evt.HoverEnter:
				case Evt.HoverLeave:
				case Evt.MouseButtonPress:
				case Evt.MouseButtonRelease:
				case Evt.MouseButtonDblClick:
				case Evt.ContextMenu: {
					return true;
				}
				default: {
					break;
				}
			}
		}
		return super.event(event);
	}

	icon(): Icon {
		return this.d.icon;
	}

	isCheckable(): boolean {
		return this.d.checkable;
	}

	@PROP({NOTIFY: 'toggled', USER: true, WRITE: 'setChecked'})
	isChecked(): boolean {
		return this.d.checked;
	}

	isDown(): boolean {
		return this.d.down;
	}

	protected keyPressEvent(event: KeyEvt): void {
		const d = this.d;
		switch (event.key()) {
			case Key.Enter: {
				event.ignore();
				break;
			}
			case Key.Space: {
				this.setDown(true);
				this.pressed();
				break;
			}
			case Key.Escape: {
				if (d.down) {
					this.setDown(false);
					this.released();
				}
				break;
			}
		}
	}

	protected keyReleaseEvent(event: KeyEvt): void {
		switch (event.key()) {
			case Key.Space: {
				this.click();
				break;
			}
			default: {
				event.ignore();
				break;
			}
		}
	}

	protected mousePressEvent(event: MouseEvt): void {
		if (event.button() !== MouseButton.LeftButton) {
			event.ignore();
			return;
		}
		const d = this.d;
		this.setDown(true);
		d.pressed = true;
		this.pressed();
		event.accept();
	}

	protected mouseReleaseEvent(event: MouseEvt): void {
		if (event.button() !== MouseButton.LeftButton) {
			event.ignore();
			return;
		}
		const d = this.d;
		d.pressed = false;
		if (!d.down) {
			event.ignore();
			return;
		}
		this.click();
		event.accept();
	}

	nextCheckState(): void {
		if (this.isCheckable()) {
			this.setChecked(
				!this.isChecked(),
			);
		}
	}

	@SIGNAL
	pressed(): void {
	}

	@SIGNAL
	released(): void {
	}

	setCheckable(checkable: boolean): void {
		const d = this.d;
		if (d.checkable === checkable) {
			return;
		}
		d.checkable = checkable;
		d.checked = false;
	}

	@SLOT
	setChecked(checked: boolean): void {
		const d = this.d;
		if (!d.checkable || (checked === d.checked)) {
			this.checkStateSet();
			return;
		}
		d.checked = checked;
		this.checkStateSet();
		this.toggled(d.checked);
	}

	setDown(down: boolean): void {
		const d = this.d;
		if (down === d.down) {
			return;
		}
		d.down = down;
	}

	setIcon(icon: Icon): void {
		const d = this.d;
		if (icon.eq(d.icon)) {
			return;
		}
		d.icon = icon;
	}

	@SLOT
	toggle(): void {
		this.setChecked(
			!this.d.checked,
		);
	}

	@SIGNAL
	toggled(checked: boolean): void {
	}
}
