import {ButtonRole, StandardButton} from '../../constants';
import {list} from '../../tools';
import {AbstractButton} from '../abstractbutton';

enum Icon {
	NoIcon,
	Information,
	Warning,
	Critical,
	Question,
}

class MessageDialogOptionsPrivate {
	buttons: StandardButton;
	customButtons: list<CustomButton>;
	detailedText: string;
	icon: Icon;
	informativeText: string;
	nextCustomButtonId: number;
	text: string;
	windowTitle: string;

	constructor() {
		this.buttons = StandardButton.Ok;
		this.customButtons = new list();
		this.detailedText = '';
		this.icon = Icon.NoIcon;
		this.informativeText = '';
		this.nextCustomButtonId = StandardButton.LastButton + 1;
		this.text = '';
		this.windowTitle = '';
	}
}

export class MessageDialogOptions {
	static Icon: typeof Icon = Icon;

	static create(): MessageDialogOptions {
		return new this();
	}

	d: MessageDialogOptionsPrivate;

	constructor() {
		this.d = new MessageDialogOptionsPrivate();
	}

	addButton(label: string, role: ButtonRole, buttonImpl: AbstractButton | null): number {
		const btn = new CustomButton(
			this.d.nextCustomButtonId++,
			label,
			role,
			buttonImpl,
		);
		this.d.customButtons.append(btn);
		return btn.id;
	}

	customButton(id: number): CustomButton | null {
		const idx = this.d.customButtons.findIndex(
			x => (x.id === id),
		);
		return (idx >= 0) ?
			this.d.customButtons.at(idx) :
			null;
	}

	customButtons(): list<CustomButton> {
		return this.d.customButtons;
	}

	detailedText(): string {
		return this.d.detailedText;
	}

	icon(): Icon {
		return this.d.icon;
	}

	informativeText(): string {
		return this.d.informativeText;
	}

	removeButton(id: number): void {
		const btn = this.customButton(id);
		if (btn) {
			this.d.customButtons.removeOne(btn);
		}
	}

	setDetailedText(text: string): void {
		this.d.detailedText = text;
	}

	setIcon(icon: Icon): void {
		this.d.icon = icon;
	}

	setInformativeText(text: string): void {
		this.d.informativeText = text;
	}

	setStandardButtons(buttons: StandardButton): void {
		this.d.buttons = buttons;
	}

	setText(text: string): void {
		this.d.text = text;
	}

	setWindowTitle(title: string): void {
		this.d.windowTitle = title;
	}

	standardButtons(): StandardButton {
		return this.d.buttons;
	}

	text(): string {
		return this.d.text;
	}

	windowTitle(): string {
		return this.d.windowTitle;
	}
}

class CustomButton {
	static eq(a: CustomButton, b: CustomButton): boolean {
		return a.id === b.id;
	}

	button: AbstractButton | null;
	id: number;
	label: string;
	role: ButtonRole;

	constructor(id: number = -1, label: string = '', role: ButtonRole = ButtonRole.InvalidRole, button: AbstractButton | null = null) {
		this.button = button;
		this.id = id;
		this.label = label;
		this.role = role;
	}
}
