import {Obj, OBJ, ObjOpts, SLOT} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import {Filter} from './filter';
import {FancyPushButton} from '../pushbutton';
import {list} from '../../tools';
import {getLogger} from '../../logging';
import {isNumber} from '../../util';
import {Icon} from '../icon';
import {AbstractButton} from '../abstractbutton';
import {ParcelView} from '../../views/parcel';
import {ExprTok, FilterMdl} from '../../models';
import {ElWin, ElWinOpts, ElWinPrivate} from '../window';

const logger = getLogger('projectdetail.filters.filterbox');
const DEL_BTN_TEXT = 'Delete';
const CONFIRM_DEL_BTN_TEXT = 'Delete this filter?';

enum Btn {
	Add = 1,
	Cancel,
	Del,
	Edit,
}

type Btns = Record<Btn, AbstractButton>;

export class FilterBoxPrivate extends ElWinPrivate {
	addFilterEnabled: boolean;
	btns: Btns;
	childBox: ChildFilterBox;
	confirmDelActive: boolean;
	childFilterItems: list<FilterItem>;
	parentFilterItem: FilterItem | null;
	tok: ExprTok;

	constructor() {
		super();
		this.addFilterEnabled = true;
		this.btns = {
			[Btn.Add]: new FancyPushButton({
				filled: true,
				icon: new Icon({name: 'add'}),
				text: 'Add filter',
			}),
			[Btn.Cancel]: new FancyPushButton({
				filled: true,
				icon: new Icon({name: 'report_gmailerrorred'}),
				styles: [
					['margin-top', '8px'],
				],
				text: 'No! Do not delete!',
			}),
			[Btn.Del]: new FancyPushButton({
				classNames: [
					'color--danger',
				],
				filled: false,
				icon: new Icon({name: 'delete'}),
				text: DEL_BTN_TEXT,
			}),
			[Btn.Edit]: new FancyPushButton({
				filled: false,
				icon: new Icon({name: 'edit'}),
				text: 'Edit shape',
			}),
		};
		this.btns[Btn.Add].setToolTip('Add another filter');
		this.btns[Btn.Cancel].setToolTip('Cancel deleting this filter');
		this.btns[Btn.Del].setToolTip('Delete this filter');
		this.btns[Btn.Edit].setToolTip('Edit shape');
		this.childBox = new ChildFilterBox();
		this.confirmDelActive = false;
		this.childFilterItems = new list();
		this.parentFilterItem = null;
		this.tok = new ExprTok();
	}

	childFilterItemByModelId(id: FilterPk): FilterItem | null {
		for (const obj of this.childFilterItems) {
			if (obj.model && (obj.model.id() === id)) {
				return obj;
			}
		}
		return null;
	}

	createFilterItem(obj: FilterMdl | null): FilterItem {
		return new FilterItem({
			box: this.q,
			el: new Filter({tok: this.tok}),
			model: obj,
		});
	}

	deleteFilter(filter: FilterMdl | null): void {
		const view = this.parentView();
		if (view) {
			view.deleteFilter(filter);
		}
	}

	init(opts: Partial<FilterBoxOpts>): void {
		if (opts.tok) {
			this.tok = opts.tok;
		}
		super.init(opts);
		const q = this.q;
		this.childBox.setParent(q);
		this.childBox.hide();
		const buttonBox = new ElObj({
			classNames: [
				'lb-filterbox-buttonbox',
			],
			parent: q,
		});
		Obj.connect(
			this.btns[Btn.Add], 'clicked',
			q, '_addFilterButtonClicked',
		);
		this.btns[Btn.Add].setParent(buttonBox);
		const buttonRow = new ElObj({
			classNames: [
				'lb-filterbox-buttonbox-row',
			],
			parent: buttonBox,
		});
		Obj.connect(
			this.btns[Btn.Edit], 'clicked',
			q, '_editFilterButtonClicked',
		);
		this.btns[Btn.Edit].setParent(buttonRow);
		this.btns[Btn.Del].setParent(buttonRow);
		Obj.connect(
			this.btns[Btn.Del], 'clicked',
			q, '_deleteButtonClicked',
		);
		this.btns[Btn.Cancel].setParent(buttonBox);
		this.btns[Btn.Cancel].hide();
		Obj.connect(
			this.btns[Btn.Cancel], 'clicked',
			q, '_cancelDeleteButtonClicked',
		);
	}

	get q(): FilterBox {
		return <FilterBox>super.q;
	}

	parentView(): ParcelView | null {
		let curr = this.q.parentEl();
		while (curr) {
			if (curr instanceof ParcelView) {
				return curr;
			}
			curr = curr.parentEl();
		}
		return null;
	}
}

export interface FilterBoxOpts extends ElWinOpts {
	dd: FilterBoxPrivate;
	tok: ExprTok;
}

@OBJ
export class FilterBox extends ElWin {
	constructor(opts: Partial<FilterBoxOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-filterbox',
		);
		opts.dd = opts.dd || new FilterBoxPrivate();
		super(opts);
	}

	private addChildFilter(obj: FilterMdl): void {
		this.insertChildFilter(
			this.d.childFilterItems.size(),
			obj,
		);
	}

	@SLOT
	addFilter(obj: FilterMdl | null): void {
		if (!obj) {
			return;
		}
		if (isNumber(obj.parentId())) {
			this.addChildFilter(obj);
		} else {
			this.setParentFilter(obj);
		}
	}

	@SLOT
	private _addFilterButtonClicked(): void {
		const d = this.d;
		const view = d.parentView();
		if (view) {
			view.createFilter({
				parentId: (d.parentFilterItem && d.parentFilterItem.model) ?
					d.parentFilterItem.model.id() :
					null,
			});
		}
	}

	@SLOT
	private _cancelDeleteButtonClicked(): void {
		const d = this.d;
		d.btns[Btn.Cancel].hide();
		d.btns[Btn.Del].setText(DEL_BTN_TEXT);
		if (d.addFilterEnabled) {
			d.btns[Btn.Add].show();
		}
		d.btns[Btn.Edit].show();
		d.confirmDelActive = false;
	}

	get d(): FilterBoxPrivate {
		return <FilterBoxPrivate>super.d;
	}

	@SLOT
	private _deleteButtonClicked(): void {
		const d = this.d;
		if (d.confirmDelActive) {
			d.confirmDelActive = false;
			d.deleteFilter(
				d.parentFilterItem && d.parentFilterItem.model,
			);
			return;
		}
		d.confirmDelActive = true;
		if (d.addFilterEnabled) {
			d.btns[Btn.Add].hide();
		}
		d.btns[Btn.Edit].hide();
		d.btns[Btn.Del].setText(CONFIRM_DEL_BTN_TEXT);
		d.btns[Btn.Cancel].show();
		d.btns[Btn.Cancel].focus();
	}

	destroy(): void {
		const d = this.d;
		d.btns[Btn.Add].destroy();
		d.btns[Btn.Edit].destroy();
		d.childBox.destroy();
		// d.btns[Btn.Close].destroy();
		super.destroy();
	}

	@SLOT
	private _editFilterButtonClicked(): void {
		const d = this.d;
		const view = d.parentView();
		if (view) {
			if (view.isEditingFilterGeometry()) {
				view.stopEditingFilterGeometry();
			} else {
				view.startEditingFilterGeometry(
					d.parentFilterItem && d.parentFilterItem.model,
				);
			}
		}
	}

	insertChildFilter(index: number, obj: FilterMdl): void {
		const d = this.d;
		if (!d.parentFilterItem) {
			logger.error('insertChildFilter: Parent object is not defined.');
			return;
		}
		if (!d.parentFilterItem.model) {
			logger.error('insertChildFilter: Parent item object is not defined.');
			return;
		}
		if (d.parentFilterItem.model.id() !== obj.parentId()) {
			logger.error('insertChildFilter: Object parent differs from current parent.');
			return;
		}
		if (!((index >= 0) && (index <= d.childFilterItems.size()))) {
			logger.warning('insertChildFilter: Invalid index: %s.', index);
			return;
		}
		if (d.childFilterItemByModelId(obj.id())) {
			logger.warning('insertChildFilter: Child object already added.');
			return;
		}
		const item = d.createFilterItem(obj);
		item.setParent(d.parentFilterItem);
		d.childFilterItems.insert(index, item);
		if (item.el) {
			item.el.d.enabledToggle.setChecked(obj.isEnabled());
			item.el.setExpression(obj.expression());
			d.childBox.insertChildFilter(index, item.el);
			d.childBox.show();
			Obj.connect(
				item.el, 'deleteClicked',
				item, 'deleteFilter',
			);
		}
	}

	parentFilter(): FilterMdl | null {
		const d = this.d;
		return d.parentFilterItem ?
			d.parentFilterItem.model :
			null;
	}

	private removeChildFilter(obj: FilterMdl | null): void {
		if (!obj) {
			return;
		}
		const d = this.d;
		const idx = d.childFilterItems.findIndex(x => (x.model === obj));
		if (idx >= 0) {
			const item = d.childFilterItems.takeAt(idx);
			item.destroy();
			item.box = null;
		}
	}

	@SLOT
	removeFilter(obj: FilterMdl | null): void {
		if (!obj) {
			return;
		}
		const d = this.d;
		if (d.parentFilterItem && (obj === d.parentFilterItem.model)) {
			const view = d.parentView();
			if (view) {
				view.closeFilterBox();
			}
		} else {
			this.removeChildFilter(obj);
		}
	}

	setAddFilterEnabled(enabled: boolean): void {
		const d = this.d;
		if (enabled === d.addFilterEnabled) {
			return;
		}
		d.addFilterEnabled = enabled;
		// Just hides the "Add Filter" button for now.
		d.btns[Btn.Add].setVisible(
			d.addFilterEnabled,
		);
	}

	private setParentFilter(obj: FilterMdl | null): void {
		const d = this.d;
		if (!obj && !d.parentFilterItem) {
			// Both null. null === null.
			return;
		}
		if (obj && d.parentFilterItem && d.parentFilterItem.model && ((obj === d.parentFilterItem.model) || (obj.id() === d.parentFilterItem.model.id()))) {
			// Already set as parent filter.
			return;
		}
		if (obj && d.childFilterItemByModelId(obj.id())) {
			logger.warning('setParentFilter: Given object is a child of current parent object.');
			return;
		}
		if (d.parentFilterItem) {
			d.parentFilterItem.destroy();
			d.parentFilterItem = null;
		}
		if (!obj) {
			// Nothing left to do.
			return;
		}
		d.parentFilterItem = d.createFilterItem(obj);
		if (d.parentFilterItem.el) {
			d.parentFilterItem.el.d.enabledToggle.setChecked(obj.isEnabled());
			d.parentFilterItem.el.addClass('lb-filter-parent');
			if (d.parentFilterItem.model) {
				d.parentFilterItem.el.setLabel(d.parentFilterItem.model.label());
			}
			d.parentFilterItem.el.setParent(
				this,
				// this.children().indexOf(d.childBox),
				1,
			);
			d.parentFilterItem.el.show();
		}
	}
}

@OBJ
class ChildFilterBox extends ElObj {
	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-filterbox-childbox',
		);
		super(opts);
	}

	addChildFilter(filter: Filter | null): void {
		this.insertChildFilter(
			this.children().size(),
			filter,
		);
	}

	children(): list<Filter> {
		return <list<Filter>>super.children();
	}

	insertChildFilter(index: number, filter: Filter | null): void {
		if (!filter) {
			return;
		}
		filter.setParent(this, index);
		filter.show();
		this.scrollBy({
			top: filter.rect().height * this.children().size(),
		});
	}
}

interface FilterItemOpts extends ObjOpts {
	box: FilterBox | null;
	el: Filter | null;
	model: FilterMdl | null;
}

@OBJ
class FilterItem extends Obj {
	box: FilterBox | null;
	el: Filter | null;
	model: FilterMdl | null;

	constructor(opts: Partial<FilterItemOpts> = {}) {
		super(opts);
		this.box = opts.box || null;
		this.el = opts.el || null;
		this.model = null;
		if (opts.model) {
			this.setModel(opts.model);
		}
	}

	@SLOT
	deleteFilter(): void {
		if (this.box) {
			this.box.d.deleteFilter(this.model);
		}
	}

	destroy(): void {
		this.setModel(null);
		this.model = null;
		if (this.el) {
			this.el.destroy();
		}
		this.el = null;
		if (this.box) {
			const boxD = this.box.d;
			if (boxD.parentFilterItem === this) {
				boxD.parentFilterItem = null;
			} else {
				const items = boxD.childFilterItems;
				const idx = items.indexOf(this);
				if (idx >= 0) {
					items.remove(idx);
				}
				if (boxD.childFilterItems.isEmpty()) {
					boxD.childBox.hide();
				}
			}
		}
		this.box = null;
		super.destroy();
	}

	@SLOT
	private enabledChanged(enabled: boolean): void {
		const view = this.box && this.box.d.parentView();
		if (view) {
			view.updateFilter(this.model, {enabled});
		}
	}

	@SLOT
	private expressionChanged(expression: IExpressionBase | null): void {
		const view = this.box && this.box.d.parentView();
		if (view) {
			view.updateFilter(this.model, {expression});
		}
	}

	@SLOT
	private labelChanged(label: string): void {
		const view = this.box && this.box.d.parentView();
		if (view) {
			view.updateFilter(this.model, {label});
		}
	}

	@SLOT
	removeFilter(): void {
		if (this.box) {
			this.box.removeFilter(this.model);
		}
	}

	setModel(model: FilterMdl | null): void {
		if (model === this.model) {
			return;
		}
		if (this.model) {
			Obj.disconnect(
				this.model, 'destroyed',
				this, 'removeFilter',
			);
			if (this.el) {
				Obj.disconnect(
					this.el, 'enabledToggled',
					this, 'enabledChanged',
				);
				Obj.disconnect(
					this.el, 'expressionChanged',
					this, 'expressionChanged',
				);
				Obj.disconnect(
					this.el, 'labelChanged',
					this, 'labelChanged',
				);
				Obj.disconnect(
					this.model, 'enabledChanged',
					this.el.d.enabledToggle, 'setChecked',
				);
			}
		}
		this.model = model;
		if (this.model) {
			Obj.connect(
				this.model, 'destroyed',
				this, 'removeFilter',
			);
			if (this.el) {
				Obj.connect(
					this.el, 'enabledToggled',
					this, 'enabledChanged',
				);
				Obj.connect(
					this.el, 'expressionChanged',
					this, 'expressionChanged',
				);
				Obj.connect(
					this.el, 'labelChanged',
					this, 'labelChanged',
				);
				Obj.connect(
					this.model, 'enabledChanged',
					this.el.d.enabledToggle, 'setChecked',
				);
				Obj.connect(
					this.model, 'labelChanged',
					this.el, 'setLabel',
				);
			}
		}
	}
}
