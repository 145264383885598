import {Feature} from './feature';
import {isNumber} from '../../../../util';

export class Point extends Feature {
	getCoordinate(path?: string): GeoJsonPosition {
		return <GeoJsonPosition>this.getCoordinates();
	}

	isValid(): boolean {
		return isNumber(this.coordinates[0]) && isNumber(this.coordinates[1]);
	}

	updateCoordinate(path: string, longitude: number, latitude: number): void {
		this.coordinates = [longitude, latitude];
		this.changed();
	}
}
