import {isIterable, isNullOrUndef, isNumber} from './util';

export function emptyGeoJsonFeatureCollection<G extends GeoJsonGeometry | null = GeoJsonGeometry, P extends GeoJsonProperties = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
	return {
		features: [],
		type: 'FeatureCollection',
	};
}

export function extractFeatureLikeFeatureIds<G extends GeoJsonGeometry | null = GeoJsonGeometry, P extends GeoJsonProperties = GeoJsonProperties>(objs: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeature<G, P> | GeoJsonFeatureCollection<G, P>): Array<GeoJsonFeatureId> {
	const rv: Array<GeoJsonFeatureId> = [];
	for (const feat of featureLikeToFeatureCollection(objs).features) {
		if ((feat.id !== undefined) && isGeoJsonFeatureId(feat.id)) {
			rv.push(feat.id);
		}
	}
	return rv;
}

export function featureIdNumber(id?: GeoJsonFeatureId): number {
	// NB: MAY RETURN NaN
	if (isNumber(id)) {
		return id;
	}
	if (typeof id === 'string') {
		return Number.parseInt(id);
	}
	return Number.NaN;
}

export function featureIdString(id: GeoJsonFeatureId): string {
	return (typeof id === 'string') ?
		id :
		String(id);
}

export function featureLikeToFeatureCollection<G extends GeoJsonGeometry | null = GeoJsonGeometry, P extends GeoJsonProperties = GeoJsonProperties>(obj: GeoJsonFeature<G, P> | Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | G): GeoJsonFeatureCollection<G, P> {
	if (isIterable(obj)) {
		return {
			features: Array.from(obj),
			type: 'FeatureCollection',
		};
	} else {
		if (!obj) {
			return {
				features: [
					{
						geometry: obj,
						properties: <P>null,
						type: 'Feature',
					},
				],
				type: 'FeatureCollection',
			};
		}
		if (obj.type === 'FeatureCollection') {
			return obj;
		} else if (obj.type === 'Feature') {
			return {
				features: [obj],
				type: 'FeatureCollection',
			};
		}
		return {
			features: [
				{
					geometry: <G>((obj.type === 'GeometryCollection') ?
						null :
						obj),
					properties: <P>null,
					type: 'Feature',
				},
			],
			type: 'FeatureCollection',
		};
	}
}

export function featureOrFeatureIdLikeToFeatureIds<G extends GeoJsonGeometry | null = GeoJsonGeometry, P extends GeoJsonProperties = GeoJsonProperties>(obj?: GeoJsonFeature<G, P> | Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): Array<GeoJsonFeatureId> {
	if (obj === undefined) {
		return [];
	}
	if (isGeoJsonFeatureId(obj)) {
		// GeoJsonFeatureId
		return [
			obj,
		];
	} else if (isIterable(obj)) {
		// Iterable<GeoJsonFeature> | Iterable<GeoJsonFeatureId>
		const objs = Array.from(
			<Iterable<GeoJsonFeature<G, P> | Iterable<GeoJsonFeatureId>>>obj,
		);
		if (objs.length > 0) {
			if (isGeoJsonFeatureId(objs[0])) {
				// Iterable<GeoJsonFeatureId>
				return <Array<GeoJsonFeatureId>>objs;
			} else {
				// Iterable<GeoJsonFeature>
				return extractFeatureLikeFeatureIds(
					<Array<GeoJsonFeature<G, P>>>objs,
				);
			}
		}
	} else {
		// GeoJsonFeature | GeoJsonFeatureCollection
		return extractFeatureLikeFeatureIds(obj);
	}
	return [];
}

export function geoJsonFeatureIdToArray(id: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): Array<GeoJsonFeatureId> {
	if (isGeoJsonFeatureId(id)) {
		return [
			id,
		];
	}
	return Array.from(id);
}

export function isGeoJsonFeatureId(obj: any): obj is GeoJsonFeatureId {
	return (typeof obj === 'string') || isNumber(obj);
}

export function isGeoJsonGeometry(obj: any): obj is Exclude<GeoJsonGeometry, 'GeometryCollection'> {
	if (obj && (typeof obj === 'object')) {
		return ('type' in obj) && ('coordinates' in obj);
	}
	return false;
}

type LbFeatBBox = [number, number, number, number];
type LbFeatGeom = GeoJsonPoint | GeoJsonPolygon | GeoJsonMultiPolygon;
type LbFeatProps = {[name: string]: any} & {geometryIsCircle: boolean; center: [number, number] | null;}
type LbFeat = GeoJsonFeature<LbFeatGeom | null, LbFeatProps> & {bbox?: LbFeatBBox; id?: GeoJsonFeatureId;};

export class LbFeature {
	static from(data: Partial<GeoJsonFeature<GeoJsonGeometry | null>> = {}): LbFeature {
		return new this(data);
	}

	static fromGeometry(geometry?: GeoJsonGeometry | null): LbFeature {
		return this.from({geometry});
	}

	private static initData(data?: Partial<GeoJsonFeature<GeoJsonGeometry | null>> | null): GeoJsonFeature<GeoJsonGeometry | null> {
		const d = isNullOrUndef(data) ?
			{} :
			data;
		return {
			bbox: d.bbox,
			geometry: d.geometry || null,
			id: d.id,
			properties: d.properties || null,
			type: 'Feature',
		};
	}

	private data: GeoJsonFeature<GeoJsonGeometry | null>;

	constructor(data: Partial<GeoJsonFeature<GeoJsonGeometry | null>> = {}) {
		this.data = LbFeature.initData(data);
	}

	get bbox(): [number, number, number, number] | undefined {
		if (this.data.bbox && (this.data.bbox.length === 4)) {
			return this.data.bbox;
		}
		return undefined;
	}

	get centerProperty(): [number, number] | undefined {
		if (!this.data.properties) {
			return undefined;
		}
		if (('center' in this.data.properties) && Array.isArray(this.data.properties.center) && (this.data.properties.center.length === 2) && isNumber(this.data.properties.center[0]) && isNumber(this.data.properties.center[1])) {
			return <[number, number]>this.data.properties.center;
		}
		return undefined;
	}

	get circleProperty(): boolean | undefined {
		if (!this.data.properties) {
			return undefined;
		}
		if (('circle' in this.data.properties) && (typeof this.data.properties.circle === 'boolean')) {
			return this.data.properties.circle;
		}
		if (('geometryIsCircle' in this.data.properties) && (typeof this.data.properties.geometryIsCircle === 'boolean')) {
			return this.data.properties.geometryIsCircle;
		}
		return undefined;
	}

	get forceMultiPolygonGeometry(): GeoJsonMultiPolygon | null {
		const geom = this.polygonOrMultiPolygonGeometry;
		if (geom) {
			if (geom.type === 'MultiPolygon') {
				return geom;
			}
			return {
				...geom,
				coordinates: [
					geom.coordinates,
				],
				type: 'MultiPolygon',
			};
		}
		return null;
	}

	get geometry(): LbFeatGeom | null {
		if (this.data.geometry) {
			switch (this.data.geometry.type) {
				case 'Polygon':
				case 'MultiPolygon':
				case 'Point': {
					return this.data.geometry;
				}
			}
		}
		return null;
	}

	get geometryIsCircle(): boolean {
		return this.properties.geometryIsCircle;
	}

	get id(): GeoJsonFeatureId | undefined {
		return isGeoJsonFeatureId(this.data.id) ?
			this.data.id :
			undefined;
	}

	get multiPolygonGeometry(): GeoJsonMultiPolygon | null {
		const geom = this.polygonOrMultiPolygonGeometry;
		return (geom && (geom.type === 'MultiPolygon')) ?
			geom :
			null;
	}

	get pointGeometry(): GeoJsonPoint | null {
		const geom = this.geometry;
		return (geom && (geom.type === 'Point')) ?
			geom :
			null;
	}

	get polygonGeometry(): GeoJsonPolygon | null {
		const geom = this.polygonOrMultiPolygonGeometry;
		return (geom && (geom.type === 'Polygon')) ?
			geom :
			null;
	}

	get polygonOrMultiPolygonGeometry(): GeoJsonPolygon | GeoJsonMultiPolygon | null {
		const geom = this.geometry;
		return (geom && ((geom.type === 'Polygon') || (geom.type === 'MultiPolygon'))) ?
			geom :
			null;
	}

	get properties(): LbFeatProps {
		const cir = this.circleProperty;
		const cen = this.centerProperty;
		return {
			...(this.data.properties || {}),
			center: (cen === undefined) ?
				null :
				cen,
			geometryIsCircle: (cir === undefined) ?
				false :
				cir,
		};
	}

	setData(data?: Partial<GeoJsonFeature> | null): void {
		this.data = LbFeature.initData(data);
	}

	toObject(): LbFeat {
		return {
			bbox: this.bbox,
			geometry: this.geometry,
			id: this.id,
			properties: this.properties,
			type: 'Feature',
		};
	}
}
