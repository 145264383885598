import {set} from './set';
import {isIterable, isNumber} from '../util';

type ListConstructorArgs<T> = {sized: {size: number; value: T;}; items: Iterable<T>};

export class list<T> {
	static constructorArguments<T>(a?: number | Iterable<T> | Partial<ListConstructorArgs<T>>, b?: T): Partial<ListConstructorArgs<T>> {
		const rv: Partial<ListConstructorArgs<T>> = {};
		if (isNumber(a)) {
			a = Math.max(a, 0);
			rv.sized = {size: Math.max(a, 0), value: <T>b};
		} else if (isIterable(a)) {
			rv.items = a;
		} else if (a) {
			return a;
		}
		return rv;
	}

	protected data: Array<T>;

	constructor(size: number, value: T);
	constructor(items?: Iterable<T>);
	constructor(args?: Partial<ListConstructorArgs<T>>);
	constructor(a?: number | Iterable<T> | Partial<ListConstructorArgs<T>>, b?: T) {
		const args = list.constructorArguments(a, b);
		if (args.sized) {
			this.data = new Array(args.sized.size);
			for (let i = 0; i < args.sized.size; ++i) {
				this.data[i] = args.sized.value;
			}
		} else {
			this.data = [...(args.items || [])];
		}
	}

	append(value: T): void {
		this.data.push(value);
	}

	at(index: number): T {
		// Returns the item at index position `i` in the list. `i` *must* be a
		// valid index position in the list (i.e., 0 <= i < size()).
		return this.data[index];
	}

	clear(): void {
		this.data.length = 0;
		this.data = [];
	}

	contains(value: T): boolean {
		return this.indexOf(value) >= 0;
	}

	destroy(): void {
		this.clear();
		// this.cmp = defaultCmp;
	}

	eq(other: list<T>): boolean {
		if (this.data.length !== other.data.length) {
			return false;
		}
		for (let i = 0; i < this.data.length; ++i) {
			if (this.data[i] !== other.data[i]) {
				return false;
			}
		}
		return true;
	}

	extend(values: Iterable<T>): void {
		this.data.push(...values);
	}

	filter<S extends T>(func: (value: T, index?: number) => value is S): list<S>;
	filter(func: (value: T, index?: number) => unknown): list<T>;
	filter(func: (value: T, index?: number) => boolean): list<T> {
		return new list(<Array<T>>this.data.filter((obj, i) => func(obj, i)));
	}

	findIndex(predicate: (value: T, index: number) => unknown): number {
		return this.data.findIndex(predicate);
	}

	first(): T {
		// Returns the first item in the list. The list must not be empty. If
		// the list can be empty, call isEmpty() before calling this function.
		return this.data[0];
	}

	indexOf(value: T, from: number = 0): number {
		return this.data.indexOf(value, from);
	}

	insert(index: number, count: number, value: T): void;
	insert(index: number, value: T): void;
	insert(index: number, b: number | T, c?: T): void {
		if (index === -1) {
			index = this.data.length;
		}
		if (isNumber(b) && (c !== undefined)) {
			// insert(index: number, count: number, value: T): void;
			for (let i = 0; i < b; ++i) {
				this.insert(index + i, c);
			}
		} else {
			// insert(index: number, value: T): void;
			this.data.splice(
				Math.max(0, index),
				0,
				<T>b,
			);
		}
	}

	isEmpty(): boolean {
		return this.data.length < 1;
	}

	join(separator?: string): string {
		return this.data.join(separator);
	}

	last(): T {
		// Returns the last item in the list. The list must not be empty. If
		// the list can be empty, call isEmpty() before calling this function.
		return this.data[this.data.length - 1];
	}

	lastIndexOf(value: T, from: number = -1): number {
		if (from < 0) {
			from = this.data.length - 1;
		}
		from = Math.max(0, from);
		for (let i = from; i >= 0; --i) {
			if (this.data[i] === value) {
				return i;
			}
		}
		return -1;
	}

	map<U>(func: (value: T, index?: number) => U): list<U> {
		return new list<U>(this.data.map((obj, i) => func(obj, i)));
	}

	mid(index: number, length: number = -1): list<T> {
		// Returns a sub-list which includes elements from this list, starting
		// at `index`. If `length` is -1 (the default), all elements from
		// `index` are included; otherwise `length` elements (or all remaining
		// elements if there are less than length elements) are included.
		// const rv = new list<T>(undefined, this.cmp);
		const rv = new list<T>();
		index = Math.max(0, index);
		if (index >= this.data.length) {
			return rv;
		}
		if (length < 0) {
			length = this.data.length;
		}
		rv.data = this.data.slice(index, index + length);
		return rv;
	}

	move(from: number, to: number): void {
		// Moves the item at index position from to index position to.
		//
		// This is the same as insert(to, takeAt(from)). This function assumes
		// that both from and to are at least 0 but less than size(). To avoid
		// failure, test that both from and to are at least 0 and less than
		// size().
		this.insert(to, this.takeAt(from));
	}

	ne(other: list<T>): boolean {
		if (this.data.length !== other.data.length) {
			return true;
		}
		if ((this.data.length === 0) && (other.data.length === 0)) {
			return false;
		}
		for (let i = 0; i < this.data.length; ++i) {
			if (this.data[i] !== other.data[i]) {
				return true;
			}
		}
		return false;
	}

	plus(other: list<T>): list<T> {
		// Returns a list that contains all the items in this list followed
		// by all the items in the other list.
		const rv = new list<T>();
		rv.data = this.data.concat(other.data);
		return rv;
	}

	plusEq(other: T | list<T>): this {
		// Appends the items of the other list to this list and returns
		// this list.
		this.data.push(...((other instanceof list) ? other.data : [other]));
		return this;
	}

	prepend(value: T): void {
		this.data.unshift(value);
	}

	remove(index: number, count: number = 1): void {
		if (index >= 0) {
			this.data.splice(index, count);
		}
	}

	removeAll(value: T): number {
		// Removes all occurrences of value in the list and returns the
		// number of entries removed.
		let rv: number = 0;
		for (let i = 0; i < this.data.length; ++i) {
			if (this.data[i] === value) {
				this.data.splice(i, 1);
				++rv;
			}
		}
		return rv;
	}

	removeAt(index: number): void {
		// Removes the item at `index`. `index` must be a valid index
		// position in the list (i.e., 0 <= index < size()).
		if (index >= 0) {
			this.data.splice(index, 1);
		}
	}

	removeFirst(): void {
		// Removes the first item in the list. Calling this function is
		// equivalent to calling removeAt(0). The list must not be empty. If
		// the list can be empty, call isEmpty() before calling this function.
		this.data.splice(0, 1);
	}

	removeLast(): void {
		// Removes the last item in the list. Calling this function is
		// equivalent to calling removeAt(size() - 1). The list must not be
		// empty. If the list can be empty, call isEmpty() before calling
		// this function.
		this.data.splice(this.data.length - 1, 1);
	}

	removeOne(value: T): boolean {
		// Removes the first occurrence of value in the list and returns true
		// on success; otherwise returns false.
		for (let i = 0; i < this.data.length; ++i) {
			if (this.data[i] === value) {
				this.data.splice(i, 1);
				return true;
			}
		}
		return false;
	}

	replace(index: number, value: T): void {
		// Replaces the item at `index` with `value`. `index` must be a valid
		// index position in the list (i.e., 0 <= index < size()).
		this.data[index] = value;
	}

	resize(size: number, value: T): void {
		// Sets the size of the list to size. If size is greater than the
		// current size, elements are added to the end; the new elements are
		// populated with value. If size is less than the current size,
		// elements are removed from the end.
		if ((size === this.data.length) || (size < 0)) {
			return;
		}
		if (size > this.data.length) {
			// Add elements
			this.insert(this.data.length, size - this.data.length, value);
		} else {
			// Remove elements
			this.remove(this.data.length - 1, this.data.length - size);
		}
	}

	size(): number {
		return this.data.length;
	}

	sort(cmp?: (a: T, b: T) => number): void {
		this.data.sort(cmp);
	}

	startsWith(value: T): boolean {
		// Returns true if this list is not empty and its first item is equal
		// to value; otherwise returns false.
		// return (this.data.length > 0) && (this.cmp(this.data[0], value) === 0);
		return (this.data.length > 0) && (this.data[0] === value);
	}

	swapItemsAt(indexA: number, indexB: number): void {
		// Exchange the item at `indexA` with the item at `indexB`. This
		// function assumes that both `indexA` and `indexB` are at least 0 but
		// less than size(). To avoid failure, test that both `indexA` and
		// `indexB` are at least 0 and less than size().
		[this.data[indexA], this.data[indexB]] = [this.data[indexB], this.data[indexA]];
	}

	takeAt(index: number): T {
		// Removes the item at `index` and returns it. `index` must be a valid
		// index position in the list (i.e., 0 <= index < size()).
		return this.data.splice(index, 1)[0];
	}

	takeFirst(): T {
		// Removes the first item in the list and returns it. This is the same
		// as takeAt(0). This function assumes the list is not empty. To avoid
		// failure, call isEmpty() before calling this function.
		return this.data.splice(0, 1)[0];
	}

	takeLast(): T {
		// Removes the last item in the list and returns it. This is the same
		// as takeAt(size() - 1). This function assumes the list is not empty.
		// To avoid failure, call isEmpty() before calling this function.
		return this.data.splice(this.data.length - 1, 1)[0];
	}

	toArray(): Array<T> {
		return Array.from(this.data);
	}

	toSet(): set<T> {
		return new set(this.data);
	}

	toString(): string {
		return `${this.constructor.name}(${this.data.toString()})`;
	}

	value<D>(index: number, defaultValue?: D): T | D | undefined {
		if (index >= 0 && index < this.data.length) {
			return this.data[index];
		}
		return defaultValue;
	}

	get [Symbol.toStringTag](): string {
		return 'list';
	}

	*[Symbol.iterator](): IterableIterator<T> {
		yield *this.data[Symbol.iterator]();
	}
}
