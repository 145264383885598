import {MDCDialog} from '@material/dialog';

import {DialogBase, DialogBaseOpts, DialogBasePrivate} from './dialogbase';
import {OBJ} from '../../obj';
import {HideEvt, ShowEvt} from '../../evt';
import {ElObj, ElObjOpts} from '../../elobj';
import {fauxFocusTrapFactory} from '../../global';

enum MDCEventType {
	Closed = 'MDCDialog:closed',
	Opened = 'MDCDialog:opened',
}

export class DialogPrivate extends DialogBasePrivate {
	axnBtns: ElObj | null;
	container: ElObj | null;
	content: ElObj | null;
	ctrl: MDCDialog | null;
	surface: ElObj | null;
	title: ElObj | null;

	constructor() {
		super();
		this.axnBtns = null;
		this.container = null;
		this.content = null;
		this.ctrl = null;
		this.surface = null;
		this.title = null;
	}

	destroyCtrl(): void {
		if (!this.ctrl) {
			return;
		}
		const q = this.q;
		q.removeEventListener(MDCEventType.Opened);
		q.removeEventListener(MDCEventType.Closed);
		this.ctrl.destroy();
		this.ctrl = null;
	}

	init(opts: Partial<DialogOpts>): void {
		super.init(opts);
		const q = this.q;
		this.container = new ElObj({
			classNames: 'mdc-dialog__container',
			parent: q,
		});
		this.surface = new Surface({
			parent: this.container,
		});
		this.title = new ElObj({
			classNames: 'mdc-dialog__title',
			parent: this.surface,
			tagName: 'h2',
		});
		this.content = new ElObj({
			classNames: 'mdc-dialog__content',
			parent: this.surface,
		});
		new ElObj({
			classNames: 'mdc-dialog__scrim',
			parent: q,
		});
	}

	get q(): Dialog {
		return <Dialog>super.q;
	}

	setCtrlOpen(open: boolean): void {
		const q = this.q;
		if (open) {
			if (!this.ctrl) {
				this.ctrl = new MDCDialog(
					q.element(),
					undefined,
					fauxFocusTrapFactory,
				);
				this.ctrl.escapeKeyAction = '';
				this.ctrl.scrimClickAction = '';
				q.addEventListener(MDCEventType.Opened);
				q.addEventListener(MDCEventType.Closed);
			}
			this.ctrl.open();
		} else {
			if (this.ctrl) {
				this.ctrl.close();
			}
		}
	}
}

export interface DialogOpts extends DialogBaseOpts {
	dd: DialogPrivate;
}

@OBJ
export class Dialog extends DialogBase {
	constructor(opts: Partial<DialogOpts> = {}) {
		opts.classNames = DialogBase.mergeClassNames(
			opts.classNames,
			'mdc-dialog',
		);
		opts.dd = opts.dd || new DialogPrivate();
		super(opts);
	}

	get d(): DialogPrivate {
		return <DialogPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.destroyCtrl();
		d.axnBtns = null;
		d.container = null;
		d.content = null;
		d.surface = null;
		d.title = null;
		super.destroy();
	}

	protected _domMdcEvent(event: Event): void {
		if (event.type === MDCEventType.Closed) {
			this.d.destroyCtrl();
		}
	}

	protected hideEvent(event: HideEvt): void {
		this.d.setCtrlOpen(false);
		super.hideEvent(event);
	}

	protected showEvent(event: ShowEvt): void {
		this.d.setCtrlOpen(true);
		super.showEvent(event);
	}
}

@OBJ
class Surface extends ElObj {
	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['role', 'alertdialog'],
			['aria-modal', 'true'],
		);
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-dialog__surface',
		);
		super(opts);
	}
}
