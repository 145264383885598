export class MetaType {
	static cmp(a: MetaType, b: MetaType): number {
		if (a.typeId === b.typeId) {
			return 0;
		}
		if (a.typeId > b.typeId) {
			return 1;
		}
		return -1;
	}

	static type(typeName: string): MetaType.Type {
		if (typeName in MetaType.Type) {
			return MetaType.Type[<keyof typeof MetaType.Type>typeName];
		}
		return MetaType.Type.Invalid;
	}

	static typeName(typeId: number): string {
		if (typeId in MetaType.Type) {
			return MetaType.Type[typeId];
		}
		return '';
	}

	private typeId: number;

	constructor(typeId: number = MetaType.Type.Invalid) {
		this.typeId = typeId;
	}

	id(): number {
		return this.typeId;
	}

	isValid(): boolean {
		return this.typeId !== MetaType.Type.Invalid;
	}

	name(): string {
		return MetaType.typeName(this.typeId);
	}
}

export namespace MetaType {
	export enum Type {
		Invalid = 0,
		Boolean = 1,
		Number = 6,
		Decimal = 7,
		String = 10,
		TimeDelta = 13,
		Date = 14,
		Time = 15,
		DateTime = 16,
		Url = 17,
		Variant = 41,
		Null = 51,
		Icon = 0x1005,
	}

	export type Invalid = Type.Invalid;
	export type Boolean = Type.Boolean;
	export type Number = Type.Number;
	export type Decimal = Type.Decimal;
	export type String = Type.String;
	export type TimeDelta = Type.TimeDelta;
	export type Date = Type.Date;
	export type Time = Type.Time;
	export type DateTime = Type.DateTime;
	export type Url = Type.Url;
	export type Variant = Type.Variant;
	export type Null = Type.Null;
	export type Icon = Type.Icon;

	export const Invalid: Type.Invalid = Type.Invalid;
	export const Boolean: Type.Boolean = Type.Boolean;
	export const Number: Type.Number = Type.Number;
	export const Decimal: Type.Decimal = Type.Decimal;
	export const String: Type.String = Type.String;
	export const TimeDelta: Type.TimeDelta = Type.TimeDelta;
	export const Date: Type.Date = Type.Date;
	export const Time: Type.Time = Type.Time;
	export const DateTime: Type.DateTime = Type.DateTime;
	export const Url: Type.Url = Type.Url;
	export const Variant: Type.Variant = Type.Variant;
	export const Null: Type.Null = Type.Null;
	export const Icon: Type.Icon = Type.Icon;
}
