import mapboxgl from 'mapbox-gl';

import {IMapboxDrawContext} from './mapboxdraw';
import {Point} from '../../../tools';
import {WGS84_RADIUS, MapFeatureRole} from '../../../constants';

const roleSet = new Set<string>([
	MapFeatureRole.Feature,
	MapFeatureRole.Midpoint,
	MapFeatureRole.Vertex,
]);

function featuresAt(point: Point | null, bbox: [[number, number], [number, number]] | null, ctx: IMapboxDrawContext, buffer: number): Array<mapboxgl.MapboxGeoJSONFeature> {
	if (!ctx.map) {
		return [];
	}
	const box: [[number, number], [number, number]] | undefined = point ?
		mapEventToBoundingBox(point, buffer) :
		bbox || undefined;
	const queryParams: {layers?: string[]; filter?: any[]} & mapboxgl.FilterOptions = {};
	if (ctx.options.layers) {
		queryParams.layers = ctx.options.layers.map(s => s.id);
	}
	const feats: Array<mapboxgl.MapboxGeoJSONFeature> = [];
	const mapFeats = ctx.map.queryRenderedFeatures(box, queryParams);
	for (let i = 0; i < mapFeats.length; ++i) {
		const mapFeat = mapFeats[i];
		if (mapFeat.properties && roleSet.has(mapFeat.properties.meta)) {
			feats.push(mapFeat);
		}
	}
	const uniqueFeats: Array<mapboxgl.MapboxGeoJSONFeature> = [];
	const featIds = new Set<string>();
	for (let i = 0; i < feats.length; ++i) {
		const feat = feats[i];
		const featId = (feat.properties && feat.properties.id) || '';
		if (!featIds.has(featId)) {
			featIds.add(featId);
			uniqueFeats.push(feat);
		}
	}
	return sortFeatures(uniqueFeats);
}

export function featuresAtClick(point: Point | null, bbox: [[number, number], [number, number]] | null, ctx: IMapboxDrawContext): Array<mapboxgl.MapboxGeoJSONFeature> {
	return featuresAt(point, bbox, ctx, ctx.options.clickBuffer);
}

export function featuresAtTouch(point: Point | null, bbox: [[number, number], [number, number]] | null, ctx: IMapboxDrawContext): Array<mapboxgl.MapboxGeoJSONFeature> {
	return featuresAt(point, bbox, ctx, ctx.options.touchBuffer);
}

function mapEventToBoundingBox(point: Point, buffer: number = 0): [[number, number], [number, number]] {
	return [
		[point.x() - buffer, point.y() - buffer],
		[point.x() + buffer, point.y() + buffer],
	];
}

interface MapboxGeoJSONFeatureWithArea extends mapboxgl.MapboxGeoJSONFeature {
	area?: number;
}

const FEATURE_SORT_RANKS: {[key: string]: number} = {
	Point: 0,
	LineString: 1,
	Polygon: 2,
};

function comparator(a: MapboxGeoJSONFeatureWithArea, b: MapboxGeoJSONFeatureWithArea): number {
	const score = FEATURE_SORT_RANKS[a.geometry.type] - FEATURE_SORT_RANKS[b.geometry.type];
	if ((score === 0) && (a.geometry.type === 'Polygon')) {
		return <number>a.area - <number>b.area;
	}
	return score;
}

function sortFeatures(features: Array<mapboxgl.MapboxGeoJSONFeature & {area?: number;}>): Array<mapboxgl.MapboxGeoJSONFeature> {
	return features.map(feature => {
		if (feature.geometry.type === 'Polygon') {
			feature.area = geometry({
				coordinates: (<GeoJsonPolygon>feature.geometry).coordinates,
				type: 'Polygon',
			});
		}
		return feature;
	}).sort(comparator).map(feature => {
		delete feature.area;
		return feature;
	});
}

/***********************************************************************************
 ** Copyright 2005-2013 OpenLayers Contributors. All rights reserved. See
 ** authors.txt for full list.
 **
 ** Redistribution and use in source and binary forms, with or without modification,
 ** are permitted provided that the following conditions are met:
 **
 **  1. Redistributions of source code must retain the above copyright notice, this
 **  list of conditions and the following disclaimer.
 **
 **  2. Redistributions in binary form must reproduce the above copyright notice,
 **  this list of conditions and the following disclaimer in the documentation and/or
 **  other materials provided with the distribution.
 **
 ** THIS SOFTWARE IS PROVIDED BY OPENLAYERS CONTRIBUTORS ``AS IS'' AND ANY EXPRESS
 ** OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
 ** MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE DISCLAIMED. IN NO EVENT
 ** SHALL COPYRIGHT HOLDER OR CONTRIBUTORS BE LIABLE FOR ANY DIRECT, INDIRECT,
 ** INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES (INCLUDING, BUT NOT
 ** LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES; LOSS OF USE, DATA, OR
 ** PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF
 ** LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE
 ** OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS SOFTWARE, EVEN IF
 ** ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 **
 ** The views and conclusions contained in the software and documentation are those
 ** of the authors and should not be interpreted as representing official policies,
 ** either expressed or implied, of OpenLayers Contributors.
 */

function geometry(geom: GeoJsonGeometry): number {
	let area: number = 0;
	let i: number;
	switch (geom.type) {
		case 'Polygon':
			return polygonArea(geom.coordinates);
		case 'MultiPolygon':
			for (i = 0; i < geom.coordinates.length; i++) {
				area += polygonArea(geom.coordinates[i]);
			}
			return area;
		case 'Point':
		case 'MultiPoint':
		case 'LineString':
		case 'GeometryCollection':
		case 'MultiLineString':
			return 0;
	}
}

function polygonArea(coords: GeoJsonPosition[][]): number {
	let area: number = 0;
	if (coords.length > 0) {
		area += Math.abs(ringArea(coords[0]));
		for (let i = 1; i < coords.length; i++) {
			area -= Math.abs(ringArea(coords[i]));
		}
	}
	return area;
}

/**
 * Calculate the approximate area of the polygon were it projected onto
 *     the earth.  Note that this area will be positive if ring is oriented
 *     clockwise, otherwise it will be negative.
 *
 * Reference:
 * Robert. G. Chamberlain and William H. Duquette, "Some Algorithms for
 *     Polygons on a Sphere", JPL Publication 07-03, Jet Propulsion
 *     Laboratory, Pasadena, CA, June 2007 http://trs-new.jpl.nasa.gov/dspace/handle/2014/40409
 *
 * Returns:
 * {float} The approximate signed geodesic area of the polygon in square
 *     meters.
 */
function ringArea(coords: GeoJsonPosition[]): number {
	let p1: GeoJsonPosition;
	let p2: GeoJsonPosition;
	let p3: GeoJsonPosition;
	let lowerIndex: number;
	let middleIndex: number;
	let upperIndex: number;
	let i: number;
	let area: number = 0;
	const coordsLength: number = coords.length;
	if (coordsLength > 2) {
		for (i = 0; i < coordsLength; i++) {
			if (i === coordsLength - 2) {// i = N-2
				lowerIndex = coordsLength - 2;
				middleIndex = coordsLength - 1;
				upperIndex = 0;
			} else if (i === coordsLength - 1) {// i = N-1
				lowerIndex = coordsLength - 1;
				middleIndex = 0;
				upperIndex = 1;
			} else { // i = 0 to N-3
				lowerIndex = i;
				middleIndex = i + 1;
				upperIndex = i + 2;
			}
			p1 = coords[lowerIndex];
			p2 = coords[middleIndex];
			p3 = coords[upperIndex];
			area += (rad(p3[0]) - rad(p1[0])) * Math.sin(rad(p2[1]));
		}
		area = area * WGS84_RADIUS * WGS84_RADIUS / 2;
	}
	return area;
}

function rad(n: number): number {
	return n * Math.PI / 180;
}
