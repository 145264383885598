import {Obj, OBJ, ObjOpts, ObjPrivate, SIGNAL, SLOT} from './obj';
import {list} from './tools';
import {Action} from './action';
import {Icon} from './ui/icon';
import {assert} from './util';

export enum ExclusionPolicy {
	None,
	Exclusive,
	ExclusiveOptional,
}

export class ActionGroupPrivate extends ObjPrivate {
	actions: list<Action>;
	current: Action | null;
	enabled: boolean;
	exclusionPolicy: ExclusionPolicy;
	visible: boolean;

	constructor() {
		super();
		this.actions = new list();
		this.current = null;
		this.enabled = true;
		this.exclusionPolicy = ExclusionPolicy.Exclusive;
		this.visible = true;
	}

	get q(): ActionGroup {
		return <ActionGroup>super.q;
	}
}

export interface ActionGroupOpts extends ObjOpts {
	dd: ActionGroupPrivate;
}

@OBJ
export class ActionGroup extends Obj {
	constructor(opts: Partial<ActionGroupOpts> = {}) {
		opts.dd = opts.dd || new ActionGroupPrivate();
		super(opts);
	}

	@SLOT
	private _actionChanged(): void {
		const axn = this.sender();
		assert(
			axn && (axn instanceof Action),
			'_actionChanged: Internal error',
		);
		const d = this.d;
		if (d.exclusionPolicy !== ExclusionPolicy.None) {
			if (axn.isChecked()) {
				if (axn !== d.current) {
					if (d.current) {
						d.current.setChecked(false);
					}
					d.current = axn;
				}
			} else if (axn === d.current) {
				d.current = null;
			}
		}
	}

	@SLOT
	private _actionHovered(): void {
		const axn = this.sender();
		assert(
			axn && (axn instanceof Action),
			'_actionHovered: Internal error',
		);
		this.hovered(axn);
	}

	actions(): list<Action> {
		return this.d.actions;
	}

	@SLOT
	private _actionTriggered(): void {
		const axn = this.sender();
		assert(
			axn && (axn instanceof Action),
			'_actionTriggered: Internal error',
		);
		this.triggered(axn);
	}

	addAction(action: Action): Action;
	addAction(text: string): Action;
	addAction(icon: Icon, text?: string): Action;
	addAction(a: Action | Icon | string, b?: string): Action {
		const d = this.d;
		if (a instanceof Action) {
			// SIG: addAction(action: Action): Action
			if (!d.actions.contains(a)) {
				d.actions.append(a);
				Obj.connect(
					a, 'triggered',
					this, '_actionTriggered',
				);
				Obj.connect(
					a, 'changed',
					this, '_actionChanged',
				);
				Obj.connect(
					a, 'hovered',
					this, '_actionHovered',
				);
			}
			const ad = a.d;
			ad.setEnabled(d.enabled, true);
			if (!ad.forceInvisible) {
				ad.setVisible(d.visible);
			}
			if (a.isChecked()) {
				d.current = a;
			}
			const oldGroup = ad.group;
			if (oldGroup !== this) {
				if (oldGroup) {
					oldGroup.removeAction(a);
				}
				ad.group = this;
				ad.sendDataChanged();
			}
			return a;
		} else if (a instanceof Icon) {
			// SIG: addAction(icon: Icon, text?: string): Action
			return new Action(a, b || '', this);
		} else {
			// SIG: addAction(text: string): Action
			return new Action(a, this);
		}
	}

	checkedAction(): Action | null {
		return this.d.current;
	}

	get d(): ActionGroupPrivate {
		return <ActionGroupPrivate>super.d;
	}

	exclusionPolicy(): ExclusionPolicy {
		return this.d.exclusionPolicy;
	}

	@SIGNAL
	protected hovered(action: Action): void {
	}

	isEnabled(): boolean {
		return this.d.enabled;
	}

	isExclusive(): boolean {
		return this.exclusionPolicy() !== ExclusionPolicy.None;
	}

	isVisible(): boolean {
		return this.d.visible;
	}

	removeAction(action: Action): void {
		const d = this.d;
		if (d.actions.removeAll(action) > 0) {
			if (action === d.current) {
				d.current = null;
			}
			Obj.disconnect(
				action, 'triggered',
				this, '_actionTriggered',
			);
			Obj.disconnect(
				action, 'changed',
				this, '_actionChanged',
			);
			Obj.disconnect(
				action, 'hovered',
				this, '_actionHovered',
			);
			action.d.group = null;
		}
	}

	@SLOT
	setDisabled(disable: boolean): void {
		this.setEnabled(!disable);
	}

	@SLOT
	setEnabled(enable: boolean): void {
		const d = this.d;
		d.enabled = enable;
		for (const axn of d.actions) {
			axn.d.setEnabled(enable, true);
		}
	}

	@SLOT
	setExclusionPolicy(policy: ExclusionPolicy): void {
		this.d.exclusionPolicy = policy;
	}

	@SLOT
	setExclusive(exclusive: boolean): void {
		this.setExclusionPolicy(
			exclusive ?
				ExclusionPolicy.Exclusive :
				ExclusionPolicy.None,
		);
	}

	@SLOT
	setVisible(visible: boolean): void {
		const d = this.d;
		d.visible = visible;
		for (const axn of d.actions) {
			if (!axn.d.forceInvisible) {
				axn.d.setVisible(visible);
			}
		}
	}

	@SIGNAL
	protected triggered(action: Action): void {
	}
}
