import {mat2, mat4, quat, vec3, vec4} from 'gl-matrix';
import mapboxgl from 'mapbox-gl';

import {set} from '../tools';
import {assert, isNumber} from './util';
import {DEFAULT_MAP_TILE_SIZE} from '../constants';

const NUM_WORLD_COPIES = 3;
const DEFAULT_MIN_ZOOM = 0;
const EXTENT = 8192;
type RayIntersectionResult = {p0: vec4, p1: vec4, t: number};
type ElevationReference = 'sea' | 'ground';
type Tup2 = [number, number];
type Tup3 = [number, number, number];
type Tup4 = [number, number, number, number];

export class Transform {
	tileSize: number;
	tileZoom: number;
	lngRange?: Tup2;
	latRange?: Tup2;
	maxValidLatitude: number;
	scale: number;
	width: number;
	height: number;
	angle: number;
	rotationMatrix: mat2;
	zoomFraction: number;
	pixelsToGLUnits: Tup2;
	cameraToCenterDistance: number;
	mercatorMatrix: mat4;
	mercatorFogMatrix: mat4;
	projMatrix: mat4;
	invProjMatrix: mat4;
	alignedProjMatrix: mat4;
	pixelMatrix: mat4;
	pixelMatrixInverse: mat4;
	worldToFogMatrix: mat4;
	skyboxMatrix: mat4;
	glCoordMatrix: mat4;
	labelPlaneMatrix: mat4;
	freezeTileCoverage: boolean;
	cameraElevationReference: ElevationReference;
	fogCullDistSq?: number;
	_averageElevation: number;
	_elevation?: Elevation;
	_fov: number;
	_pitch: number;
	_zoom: number;
	_cameraZoom?: number;
	_unmodified: boolean;
	_renderWorldCopies: boolean;
	_minZoom: number;
	_maxZoom: number;
	_minPitch: number;
	_maxPitch: number;
	_center: LngLat;
	_edgeInsets: EdgeInsets;
	_constraining: boolean;
	_projMatrixCache: {[_: number]: Float32Array};
	_alignedProjMatrixCache: {[_: number]: Float32Array};
	_fogTileMatrixCache: {[_: number]: Float32Array};
	_camera: FreeCamera;
	_centerAltitude: number;
	_horizonShift: number;

	constructor(minZoom?: number, maxZoom?: number, minPitch?: number, maxPitch?: number, renderWorldCopies: boolean = true) {
		this.tileSize = DEFAULT_MAP_TILE_SIZE; // constant
		this.tileZoom = 0;
		this.maxValidLatitude = 85.051129; // constant
		this.cameraToCenterDistance = 0;
		this._renderWorldCopies = renderWorldCopies;
		this._minZoom = minZoom || DEFAULT_MIN_ZOOM;
		this._maxZoom = maxZoom || 22;
		this._minPitch = isNumber(minPitch) ? minPitch : 0;
		this._maxPitch = isNumber(maxPitch) ? maxPitch : 60;
		this.setMaxBounds();
		this.width = 0;
		this.height = 0;
		this._zoom = 0;
		this._center = new LngLat(0, 0);
		this.zoomFraction = 0;
		this.freezeTileCoverage = false;
		this._constraining = false;
		this.projMatrix = mat4.create();
		this.invProjMatrix = mat4.create();
		this.alignedProjMatrix = mat4.create();
		this.pixelMatrix = mat4.create();
		this.pixelMatrixInverse = mat4.create();
		this.worldToFogMatrix = mat4.create();
		this.skyboxMatrix = mat4.create();
		this.glCoordMatrix = mat4.create();
		this.labelPlaneMatrix = mat4.create();
		this.mercatorMatrix = mat4.create();
		this.mercatorFogMatrix = mat4.create();
		this.zoom = 0;
		this.angle = 0;
		this._fov = 0.6435011087932844;
		this._pitch = 0;
		this.pixelsToGLUnits = [0, 0];
		this.scale = 0;
		this._unmodified = true;
		this._edgeInsets = new EdgeInsets();
		this._projMatrixCache = {};
		this._alignedProjMatrixCache = {};
		this._fogTileMatrixCache = {};
		this._camera = new FreeCamera();
		this.rotationMatrix = mat2.create();
		this._centerAltitude = 0;
		this._averageElevation = 0;
		this.cameraElevationReference = 'ground';
		// Move the horizon closer to the center. 0 would not shift the horizon. 1 would put the horizon at the center.
		this._horizonShift = 0.1;
	}

	clone(): Transform {
		const clone = new Transform(this._minZoom, this._maxZoom, this._minPitch, this.maxPitch, this._renderWorldCopies);
		clone._elevation = this._elevation;
		clone._centerAltitude = this._centerAltitude;
		clone.tileSize = this.tileSize;
		clone.latRange = this.latRange;
		clone.width = this.width;
		clone.height = this.height;
		clone.cameraElevationReference = this.cameraElevationReference;
		clone._center = this._center;
		clone._setZoom(this.zoom);
		clone._cameraZoom = this._cameraZoom;
		clone.angle = this.angle;
		clone._fov = this._fov;
		clone._pitch = this._pitch;
		clone._averageElevation = this._averageElevation;
		clone._unmodified = this._unmodified;
		clone._edgeInsets = this._edgeInsets.clone();
		clone._camera = this._camera.clone();
		clone._calcMatrices();
		clone.freezeTileCoverage = this.freezeTileCoverage;
		return clone;
	}

	get elevation(): Elevation | undefined {
		return this._elevation;
	}

	set elevation(elevation: Elevation | undefined) {
		if (this._elevation === elevation) {
			return;
		}
		this._elevation = elevation;
		if (!elevation) {
			this._cameraZoom = undefined;
			this._centerAltitude = 0;
		} else {
			if (this._updateCenterElevation()) {
				this._updateCameraOnTerrain();
			}
		}
		this._calcMatrices();
	}

	updateElevation(constrainCameraOverTerrain: boolean) {
		// On render, no need for higher granularity on update reasons.
		if (this._terrainEnabled() && this._cameraZoom == null) {
			if (this._updateCenterElevation()) {
				this._updateCameraOnTerrain();
			}
		}
		if (constrainCameraOverTerrain) {
			this._constrainCameraAltitude();
		}
		this._calcMatrices();
	}

	get minZoom(): number {
		return this._minZoom;
	}

	set minZoom(zoom: number) {
		if (this._minZoom === zoom) {
			return;
		}
		this._minZoom = zoom;
		this.zoom = Math.max(this.zoom, zoom);
	}

	get maxZoom(): number {
		return this._maxZoom;
	}

	set maxZoom(zoom: number) {
		if (this._maxZoom === zoom) {
			return;
		}
		this._maxZoom = zoom;
		this.zoom = Math.min(this.zoom, zoom);
	}

	get minPitch(): number {
		return this._minPitch;
	}

	set minPitch(pitch: number) {
		if (this._minPitch === pitch) {
			return;
		}
		this._minPitch = pitch;
		this.pitch = Math.max(this.pitch, pitch);
	}

	get maxPitch(): number {
		return this._maxPitch;
	}

	set maxPitch(pitch: number) {
		if (this._maxPitch === pitch) {
			return;
		}
		this._maxPitch = pitch;
		this.pitch = Math.min(this.pitch, pitch);
	}

	get renderWorldCopies(): boolean {
		return this._renderWorldCopies;
	}

	set renderWorldCopies(renderWorldCopies: boolean) {
		if (renderWorldCopies === undefined) {
			renderWorldCopies = true;
		} else if (renderWorldCopies === null) {
			renderWorldCopies = false;
		}
		this._renderWorldCopies = renderWorldCopies;
	}

	get worldSize(): number {
		return this.tileSize * this.scale;
	}

	get cameraWorldSize(): number {
		const distance = Math.max(this._camera.getDistanceToElevation(this._averageElevation), Number.EPSILON);
		return this._worldSizeFromZoom(this._zoomFromMercatorZ(distance));
	}

	get pixelsPerMeter(): number {
		return mercatorZfromAltitude(1, this.center.lat) * this.worldSize;
	}

	get cameraPixelsPerMeter(): number {
		return mercatorZfromAltitude(1, this.center.lat) * this.cameraWorldSize;
	}

	get centerOffset(): Point {
		return this.centerPoint._sub(this.size._div(2));
	}

	get size(): Point {
		return new Point(this.width, this.height);
	}

	get bearing(): number {
		return -this.angle / Math.PI * 180;
	}

	set bearing(bearing: number) {
		const b = -wrap(bearing, -180, 180) * Math.PI / 180;
		if (this.angle === b) {
			return;
		}
		this._unmodified = false;
		this.angle = b;
		this._calcMatrices();
		// 2x2 matrix for rotating points
		this.rotationMatrix = mat2.create();
		mat2.rotate(this.rotationMatrix, this.rotationMatrix, this.angle);
	}

	get pitch(): number {
		return this._pitch / Math.PI * 180;
	}

	set pitch(pitch: number) {
		const p = clamp(pitch, this.minPitch, this.maxPitch) / 180 * Math.PI;
		if (this._pitch === p) {
			return;
		}
		this._unmodified = false;
		this._pitch = p;
		this._calcMatrices();
	}

	get fov(): number {
		return this._fov / Math.PI * 180;
	}

	set fov(fov: number) {
		fov = Math.max(0.01, Math.min(60, fov));
		if (this._fov === fov) {
			return;
		}
		this._unmodified = false;
		this._fov = fov / 180 * Math.PI;
		this._calcMatrices();
	}

	get averageElevation(): number {
		return this._averageElevation;
	}

	set averageElevation(averageElevation: number) {
		this._averageElevation = averageElevation;
		this._calcFogMatrices();
	}

	get zoom(): number {
		return this._zoom;
	}

	set zoom(zoom: number) {
		const z = Math.min(Math.max(zoom, this.minZoom), this.maxZoom);
		if (this._zoom === z) {
			return;
		}
		this._unmodified = false;
		this._setZoom(z);
		if (this._terrainEnabled()) {
			this._updateCameraOnTerrain();
		}
		this._constrain();
		this._calcMatrices();
	}

	_setZoom(z: number) {
		this._zoom = z;
		this.scale = this.zoomScale(z);
		this.tileZoom = Math.floor(z);
		this.zoomFraction = z - this.tileZoom;
	}

	_updateCenterElevation(): boolean {
		if (!this._elevation) {
			return false;
		}
		// Camera zoom describes the distance of the camera to the sea level (altitude). It is used only for manipulating the camera location.
		// The standard zoom (this._zoom) defines the camera distance to the terrain (height). Its behavior and conceptual meaning in determining
		// which tiles to stream is same with or without the terrain.
		const elevationAtCenter = this._elevation.getAtPointOrZero(MercatorCoordinate.fromLngLat(this.center), -1);
		if (elevationAtCenter === -1) {
			// Elevation data not loaded yet
			this._cameraZoom = undefined;
			return false;
		}
		this._centerAltitude = elevationAtCenter;
		return true;
	}

	_updateCameraOnTerrain() {
		const height = this.cameraToCenterDistance / this.worldSize;
		const terrainElevation = mercatorZfromAltitude(this._centerAltitude, this.center.lat);
		this._cameraZoom = this._zoomFromMercatorZ(terrainElevation + height);
	}

	sampleAverageElevation(): number {
		if (!this._elevation) {
			return 0;
		}
		const elevation: Elevation = this._elevation;
		const elevationSamplePoints = [
			[0.5, 0.2],
			[0.3, 0.5],
			[0.5, 0.5],
			[0.7, 0.5],
			[0.5, 0.8],
		];
		const horizon = this.horizonLineFromTop();
		let elevationSum = 0.0;
		let weightSum = 0.0;
		for (let i = 0; i < elevationSamplePoints.length; i++) {
			const pt = new Point(
				elevationSamplePoints[i][0] * this.width,
				horizon + elevationSamplePoints[i][1] * (this.height - horizon),
			);
			const hit = elevation.pointCoordinate(pt);
			if (!hit) {
				continue;
			}
			const distanceToHit = Math.hypot(hit[0] - this._camera.position[0], hit[1] - this._camera.position[1]);
			const weight = 1 / distanceToHit;
			elevationSum += hit[3] * weight;
			weightSum += weight;
		}
		if (weightSum === 0) {
			return NaN;
		}
		return elevationSum / weightSum;
	}

	get center(): LngLat {
		return this._center;
	}

	set center(center: LngLat) {
		if (center.lat === this._center.lat && center.lng === this._center.lng) {
			return;
		}
		this._unmodified = false;
		this._center = center;
		if (this._terrainEnabled()) {
			if (this.cameraElevationReference === 'ground') {
				// Check that the elevation data is available at the new location.
				if (this._updateCenterElevation()) {
					this._updateCameraOnTerrain();
				} else {
					this._cameraZoom = undefined;
				}
			} else {
				this._updateZoomFromElevation();
			}
		}
		this._constrain();
		this._calcMatrices();
	}

	_updateZoomFromElevation() {
		if (this._cameraZoom == null || !this._elevation) {
			return;
		}
		// Compute zoom level from the height of the camera relative to the terrain
		const cameraZoom: number = this._cameraZoom;
		const elevationAtCenter = this._elevation.getAtPointOrZero(MercatorCoordinate.fromLngLat(this.center));
		const mercatorElevation = mercatorZfromAltitude(elevationAtCenter, this.center.lat);
		const altitude = this._mercatorZfromZoom(cameraZoom);
		const minHeight = this._mercatorZfromZoom(this._maxZoom);
		const height = Math.max(altitude - mercatorElevation, minHeight);
		this._setZoom(this._zoomFromMercatorZ(height));
	}

	get padding(): mapboxgl.PaddingOptions {
		return this._edgeInsets.toJSON();
	}

	set padding(padding: mapboxgl.PaddingOptions) {
		if (this._edgeInsets.equals(padding)) {
			return;
		}
		this._unmodified = false;
		//Update edge-insets inplace
		this._edgeInsets.interpolate(this._edgeInsets, padding, 1);
		this._calcMatrices();
	}

	computeZoomRelativeTo(position: MercatorCoordinate): number {
		// Find map center position on the target plane by casting a ray from screen center towards the plane.
		// Direct distance to the target position is used if the target position is above camera position.
		const centerOnTargetAltitude = this.rayIntersectionCoordinate(this.pointRayIntersection(this.centerPoint, position.toAltitude()));
		let targetPosition: vec3 | undefined;
		if (position.z < this._camera.position[2]) {
			targetPosition = [centerOnTargetAltitude.x, centerOnTargetAltitude.y, centerOnTargetAltitude.z];
		} else {
			targetPosition = [position.x, position.y, position.z];
		}
		const distToTarget = vec3.length(vec3.sub(new Float32Array(3), this._camera.position, targetPosition));
		return clamp(this._zoomFromMercatorZ(distToTarget), this._minZoom, this._maxZoom);
	}

	_setCameraOrientation(orientation: quat): boolean {
		// zero-length quaternions are not valid
		if (!quat.length(orientation)) {
			return false;
		}
		quat.normalize(orientation, orientation);
		// The new orientation must be sanitized by making sure it can be represented
		// with a pitch and bearing. Roll-component must be removed and the camera can't be upside down
		const forward = vec3.transformQuat([0, 0, 0], [0, 0, -1], orientation);
		const up = vec3.transformQuat([0, 0, 0], [0, -1, 0], orientation);
		if (up[2] < 0.0) {
			return false;
		}
		const updatedOrientation = orientationFromFrame(forward, up);
		if (!updatedOrientation) {
			return false;
		}
		this._camera.orientation = updatedOrientation;
		return true;
	}

	_setCameraPosition(position: vec3) {
		// Altitude must be clamped to respect min and max zoom
		const minWorldSize = this.zoomScale(this.minZoom) * this.tileSize;
		const maxWorldSize = this.zoomScale(this.maxZoom) * this.tileSize;
		const distToCenter = this.cameraToCenterDistance;
		position[2] = clamp(position[2], distToCenter / maxWorldSize, distToCenter / minWorldSize);
		this._camera.position = position;
	}

	get centerPoint(): Point {
		return this._edgeInsets.getCenter(this.width, this.height);
	}

	get fovAboveCenter(): number {
		return this._fov * (0.5 + this.centerOffset.y / this.height);
	}

	isPaddingEqual(padding: mapboxgl.PaddingOptions): boolean {
		return this._edgeInsets.equals(padding);
	}

	interpolatePadding(start: mapboxgl.PaddingOptions, target: mapboxgl.PaddingOptions, t: number) {
		this._unmodified = false;
		this._edgeInsets.interpolate(start, target, t);
		this._constrain();
		this._calcMatrices();
	}

	coveringZoomLevel(options: {roundZoom?: boolean, tileSize: number}) {
		const z = (options.roundZoom ? Math.round : Math.floor)(
			this.zoom + this.scaleZoom(this.tileSize / options.tileSize),
		);
		// At negative zoom levels load tiles from z0 because negative tile zoom levels don't exist.
		return Math.max(0, z);
	}

	getVisibleUnwrappedCoordinates(tileID: CanonicalTileID) {
		const result = [new UnwrappedTileID(0, tileID)];
		if (this._renderWorldCopies) {
			const utl = this.pointCoordinate(new Point(0, 0));
			const utr = this.pointCoordinate(new Point(this.width, 0));
			const ubl = this.pointCoordinate(new Point(this.width, this.height));
			const ubr = this.pointCoordinate(new Point(0, this.height));
			const w0 = Math.floor(Math.min(utl.x, utr.x, ubl.x, ubr.x));
			const w1 = Math.floor(Math.max(utl.x, utr.x, ubl.x, ubr.x));
			// Add an extra copy of the world on each side to properly render
			// ImageSources and CanvasSources. Both sources draw outside the
			// tile boundaries of the tile that "contains them" so we need to
			// add extra copies on both sides in case offscreen tiles need to
			// draw into on-screen ones.
			const extraWorldCopy = 1;
			for (let w = w0 - extraWorldCopy; w <= w1 + extraWorldCopy; w++) {
				if (w === 0) {
					continue;
				}
				result.push(new UnwrappedTileID(w, tileID));
			}
		}
		return result;
	}

	coveringTiles(
		options: {
			tileSize: number,
			minzoom?: number,
			maxzoom?: number,
			roundZoom?: boolean,
			reparseOverscaled?: boolean,
			renderWorldCopies?: boolean,
			isTerrainDEM?: boolean
		},
	): Array<OverscaledTileID> {
		let z = this.coveringZoomLevel(options);
		const actualZ = z;
		const useElevationData = this.elevation && !options.isTerrainDEM;
		if (options.minzoom !== undefined && z < options.minzoom) {
			return [];
		}
		if (options.maxzoom !== undefined && z > options.maxzoom) {
			z = options.maxzoom;
		}
		const centerCoord = MercatorCoordinate.fromLngLat(this.center);
		const numTiles = 1 << z;
		const centerPoint = [numTiles * centerCoord.x, numTiles * centerCoord.y, 0];
		// @ts-ignore
		const cameraFrustum = Frustum.fromInvProjectionMatrix(this.invProjMatrix, this.worldSize, z);
		const cameraCoord = this.pointCoordinate(this.getCameraPoint());
		const meterToTile = numTiles * mercatorZfromAltitude(1, this.center.lat);
		const cameraAltitude = this._camera.position[2] / mercatorZfromAltitude(1, this.center.lat);
		const cameraPoint = [numTiles * cameraCoord.x, numTiles * cameraCoord.y, cameraAltitude];
		// Let's consider an example for !roundZoom: e.g. tileZoom 16 is used from zoom 16 all the way to zoom 16.99.
		// This would mean that the minimal distance to split would be based on distance from camera to center of 16.99 zoom.
		// The same is already incorporated in logic behind roundZoom for raster (so there is no adjustment needed in following line).
		// 0.02 added to compensate for precision errors, see "coveringTiles for terrain" test in transform.test.js.
		const zoomSplitDistance = this.cameraToCenterDistance / options.tileSize * (options.roundZoom ? 1 : 0.502);
		// No change of LOD behavior for pitch lower than 60 and when there is no top padding: return only tile ids from the requested zoom level
		const minZoom = this.pitch <= 60.0 && this._edgeInsets.top <= this._edgeInsets.bottom && !this._elevation ? z : 0;
		// When calculating tile cover for terrain, create deep AABB for nodes, to ensure they intersect frustum: for sources,
		// other than DEM, use minimum of visible DEM tiles and center altitude as upper bound (pitch is always less than 90°).
		const maxRange = options.isTerrainDEM && this._elevation ? this._elevation.exaggeration() * 10000 : this._centerAltitude;
		const minRange = options.isTerrainDEM ? -maxRange : this._elevation ? this._elevation.getMinElevationBelowMSL() : 0;
		const newRootTile = (wrap: number): RootTile => {
			return {
				// With elevation, this._elevation provides z coordinate values. For 2D:
				// All tiles are on zero elevation plane => z difference is zero
				aabb: new Aabb([wrap * numTiles, 0, minRange], [(wrap + 1) * numTiles, numTiles, maxRange]),
				zoom: 0,
				x: 0,
				y: 0,
				wrap,
				fullyVisible: false,
				shouldSplit: false,
			};
		};
		// Do a depth-first traversal to find visible tiles and proper levels of detail
		const stack: Array<RootTile> = [];
		const result = [];
		const maxZoom = z;
		const overscaledZ = options.reparseOverscaled ? actualZ : z;
		const getAABBFromElevation = (it: RootTile) => {
			assert(this._elevation);
			if (!this._elevation || !it.tileID) {
				return;
			} // To silence flow.
			const minmax = this._elevation.getMinMaxForTile(it.tileID);
			const aabb = it.aabb;
			if (minmax) {
				aabb.min[2] = minmax.min;
				aabb.max[2] = minmax.max;
				aabb.center[2] = (aabb.min[2] + aabb.max[2]) / 2;
			} else {
				it.shouldSplit = shouldSplit(it);
				if (!it.shouldSplit) {
					// At final zoom level, while corresponding DEM tile is not loaded yet,
					// assume center elevation. This covers ground to horizon and prevents
					// loading unnecessary tiles until DEM cover is fully loaded.
					aabb.min[2] = aabb.max[2] = aabb.center[2] = this._centerAltitude;
				}
			}
		};
		const square = (a: number): number => a * a;
		const cameraHeightSqr = square((cameraAltitude - this._centerAltitude) * meterToTile); // in tile coordinates.
		// Scale distance to split for acute angles.
		// dzSqr: z component of camera to tile distance, square.
		// dSqr: 3D distance of camera to tile, square.
		const distToSplitScale = (dzSqr: number, dSqr: number) => {
			// When the angle between camera to tile ray and tile plane is smaller
			// than acuteAngleThreshold, scale the distance to split. Scaling is adaptive: smaller
			// the angle, the scale gets lower value. Although it seems early to start at 45,
			// it is not: scaling kicks in around 60 degrees pitch.
			const acuteAngleThresholdSin = 0.707; // Math.sin(45)
			const stretchTile = 1.1;
			// Distances longer than 'dz / acuteAngleThresholdSin' gets scaled
			// following geometric series sum: every next dz length in distance can be
			// 'stretchTile times' longer. It is further, the angle is sharper. Total,
			// adjusted, distance would then be:
			// = dz / acuteAngleThresholdSin + (dz * stretchTile + dz * stretchTile ^ 2 + ... + dz * stretchTile ^ k),
			// where k = (d - dz / acuteAngleThresholdSin) / dz = d / dz - 1 / acuteAngleThresholdSin;
			// = dz / acuteAngleThresholdSin + dz * ((stretchTile ^ (k + 1) - 1) / (stretchTile - 1) - 1)
			// or put differently, given that k is based on d and dz, tile on distance d could be used on distance scaled by:
			// 1 / acuteAngleThresholdSin + (stretchTile ^ (k + 1) - 1) / (stretchTile - 1) - 1
			if (dSqr * square(acuteAngleThresholdSin) < dzSqr) {
				return 1.0;
			} // Early return, no scale.
			const r = Math.sqrt(dSqr / dzSqr);
			const k = r - 1 / acuteAngleThresholdSin;
			return r / (1 / acuteAngleThresholdSin + (Math.pow(stretchTile, k + 1) - 1) / (stretchTile - 1) - 1);
		};
		const shouldSplit = (it: RootTile) => {
			if (it.zoom < minZoom) {
				return true;
			} else if (it.zoom === maxZoom) {
				return false;
			}
			if (it.shouldSplit != null) {
				return it.shouldSplit;
			}
			const dx = it.aabb.distanceX(cameraPoint);
			const dy = it.aabb.distanceY(cameraPoint);
			let dzSqr = cameraHeightSqr;
			if (useElevationData) {
				dzSqr = square(it.aabb.distanceZ(cameraPoint) * meterToTile);
			}
			const distanceSqr = dx * dx + dy * dy + dzSqr;
			const distToSplit = (1 << maxZoom - it.zoom) * zoomSplitDistance;
			const distToSplitSqr = square(distToSplit * distToSplitScale(Math.max(dzSqr, cameraHeightSqr), distanceSqr));
			return distanceSqr < distToSplitSqr;
		};
		if (this._renderWorldCopies) {
			// Render copy of the globe thrice on both sides
			for (let i = 1; i <= NUM_WORLD_COPIES; i++) {
				stack.push(newRootTile(-i));
				stack.push(newRootTile(i));
			}
		}
		stack.push(newRootTile(0));
		while (stack.length > 0) {
			const it = <RootTile>stack.pop();
			const x = it.x;
			const y = it.y;
			let fullyVisible = it.fullyVisible;
			// Visibility of a tile is not required if any of its ancestor if fully inside the frustum
			if (!fullyVisible) {
				const intersectResult = it.aabb.intersects(cameraFrustum);
				if (intersectResult === 0) {
					// 49@13
					continue;
				}
				fullyVisible = intersectResult === 2;
			}
			// Have we reached the target depth or is the tile too far away to be any split further?
			if ((it.zoom === maxZoom) || !shouldSplit(it)) {
				const tileZoom = (it.zoom === maxZoom) ? overscaledZ : it.zoom;
				if (!!options.minzoom && (options.minzoom > tileZoom)) {
					// Not within source tile range.
					// 0@13
					continue;
				}
				const dx = centerPoint[0] - ((0.5 + x + (it.wrap << it.zoom)) * (1 << (z - it.zoom)));
				const dy = centerPoint[1] - 0.5 - y;
				const id = it.tileID ?
					it.tileID :
					new OverscaledTileID(tileZoom, it.wrap, it.zoom, x, y);
				result.push({
					distanceSq: dx * dx + dy * dy,
					tileID: id,
				});
				// 9@13
				continue;
			}
			for (let i = 0; i < 4; i++) {
				const childX = (x << 1) + (i % 2);
				const childY = (y << 1) + (i >> 1);
				const child: RootTile = {
					aabb: it.aabb.quadrant(i),
					fullyVisible,
					shouldSplit: undefined,
					tileID: undefined,
					wrap: it.wrap,
					x: childX,
					y: childY,
					zoom: it.zoom + 1,
				};
				if (useElevationData) {
					child.tileID = new OverscaledTileID(
						(it.zoom + 1) === maxZoom ? overscaledZ : it.zoom + 1,
						it.wrap,
						it.zoom + 1,
						childX,
						childY);
					getAABBFromElevation(child);
				}
				stack.push(child);
			}
		}
		if (this.fogCullDistSq) {
			const fogCullDistSq = this.fogCullDistSq;
			result.splice(0, result.length, ...result.filter(entry => {
				const min: Tup4 = [0, 0, 0, 1];
				const max: Tup4 = [EXTENT, EXTENT, 0, 1];
				const fogTileMatrix = this.calculateFogTileMatrix(entry.tileID.toUnwrapped());
				vec4.transformMat4(min, min, fogTileMatrix);
				vec4.transformMat4(max, max, fogTileMatrix);
				const sqDist = getAABBPointSquareDist(min, max);
				if (sqDist === 0) {
					return true;
				}
				let overHorizonLine = false;
				const horizonLineFromTop = this.horizonLineFromTop();
				if (sqDist > fogCullDistSq && horizonLineFromTop !== 0) {
					const projMatrix = this.calculateProjMatrix(entry.tileID.toUnwrapped());
					let minmax;
					if (useElevationData && this._elevation) {
						minmax = this._elevation.getMinMaxForTile(entry.tileID);
					}
					if (!minmax) {
						minmax = {min: minRange, max: maxRange};
					}
					const cornerFar = furthestTileCorner(this.bearing);
					const farX = cornerFar[0] * EXTENT;
					const farY = cornerFar[1] * EXTENT;
					const worldFar: Tup3 = [farX, farY, minmax.max];
					// World to NDC
					vec3.transformMat4(worldFar, worldFar, projMatrix);
					// NDC to Screen
					const screenCoordY = (1 - worldFar[1]) * this.height * 0.5;
					// Prevent cutting tiles crossing over the horizon lines to
					// prevent pop-in and out within the fog culling range
					overHorizonLine = screenCoordY < horizonLineFromTop;
				}
				return sqDist < fogCullDistSq || overHorizonLine;
			}));
		}
		const cover = result.sort((a, b) => a.distanceSq - b.distanceSq).map(a => a.tileID);
		// Relax the assertion on terrain, on high zoom we use distance to center of tile
		// while camera might be closer to selected center of map.
		assert(!cover.length || this.elevation || cover[0].overscaledZ === overscaledZ);
		return cover;
	}

	resize(width: number, height: number) {
		// NB: Are you executing resize() last? You might wanna.
		this.width = width;
		this.height = height;
		this.pixelsToGLUnits = [2 / width, -2 / height];
		this._constrain();
		this._calcMatrices();
	}

	get unmodified(): boolean {
		return this._unmodified;
	}

	zoomScale(zoom: number) {
		return Math.pow(2, zoom);
	}

	scaleZoom(scale: number) {
		return Math.log(scale) / Math.LN2;
	}

	project(lnglat: LngLat) {
		const lat = clamp(lnglat.lat, -this.maxValidLatitude, this.maxValidLatitude);
		return new Point(
			mercatorXfromLng(lnglat.lng) * this.worldSize,
			mercatorYfromLat(lat) * this.worldSize);
	}

	unproject(point: Point): LngLat {
		const ws = this.worldSize;
		let x: number = 0;
		let y: number = 0;
		if (ws !== 0) {
			x = point.x / ws;
			y = point.y / ws;
		}
		return new MercatorCoordinate(x, y).toLngLat();
	}

	get point(): Point {
		return this.project(this.center);
	}

	setLocationAtPoint(lnglat: LngLat, point: Point) {
		const a = this.pointCoordinate(point);
		const b = this.pointCoordinate(this.centerPoint);
		const loc = this.locationCoordinate(lnglat);
		const newCenter = new MercatorCoordinate(
			loc.x - (a.x - b.x),
			loc.y - (a.y - b.y));
		this.center = this.coordinateLocation(newCenter);
		if (this._renderWorldCopies) {
			this.center = this.center.wrap();
		}
	}

	setLocation(location: MercatorCoordinate) {
		this.center = this.coordinateLocation(location);
		if (this._renderWorldCopies) {
			this.center = this.center.wrap();
		}
	}

	locationPoint(lnglat: LngLat) {
		return this._coordinatePoint(this.locationCoordinate(lnglat), false);
	}

	locationPoint3D(lnglat: LngLat) {
		return this._coordinatePoint(this.locationCoordinate(lnglat), true);
	}

	pointLocation(p: Point) {
		return this.coordinateLocation(this.pointCoordinate(p));
	}

	pointLocation3D(p: Point) {
		return this.coordinateLocation(this.pointCoordinate3D(p));
	}

	locationCoordinate(lnglat: LngLat) {
		return MercatorCoordinate.fromLngLat(lnglat);
	}

	coordinateLocation(coord: MercatorCoordinate) {
		return coord.toLngLat();
	}

	pointRayIntersection(p: Point, z?: number): RayIntersectionResult {
		const targetZ = (z !== undefined && z !== null) ? z : this._centerAltitude;
		// since we don't know the correct projected z value for the point,
		// unproject two points to get a line and then find the point on that
		// line with z=0
		const p0: Tup4 = [p.x, p.y, 0, 1];
		const p1: Tup4 = [p.x, p.y, 1, 1];
		vec4.transformMat4(p0, p0, this.pixelMatrixInverse);
		vec4.transformMat4(p1, p1, this.pixelMatrixInverse);
		const w0 = p0[3];
		const w1 = p1[3];
		vec4.scale(p0, p0, 1 / w0);
		vec4.scale(p1, p1, 1 / w1);
		const z0 = p0[2];
		const z1 = p1[2];
		const t = z0 === z1 ? 0 : (targetZ - z0) / (z1 - z0);
		return {p0, p1, t};
	}

	screenPointToMercatorRay(p: Point): Ray {
		const p0: Tup4 = [p.x, p.y, 0, 1];
		const p1: Tup4 = [p.x, p.y, 1, 1];
		vec4.transformMat4(p0, p0, this.pixelMatrixInverse);
		vec4.transformMat4(p1, p1, this.pixelMatrixInverse);
		vec4.scale(p0, p0, 1 / p0[3]);
		vec4.scale(p1, p1, 1 / p1[3]);
		// Convert altitude from meters to pixels
		p0[2] = mercatorZfromAltitude(p0[2], this._center.lat) * this.worldSize;
		p1[2] = mercatorZfromAltitude(p1[2], this._center.lat) * this.worldSize;
		vec4.scale(p0, p0, 1 / this.worldSize);
		vec4.scale(p1, p1, 1 / this.worldSize);
		return new Ray([p0[0], p0[1], p0[2]], vec3.normalize([0, 0, 0], vec3.sub([0, 0, 0], [p1[0], p1[1], p1[2]], [p0[0], p0[1], p0[2]])));
	}

	rayIntersectionCoordinate(rayIntersection: RayIntersectionResult): MercatorCoordinate {
		const {p0, p1, t} = rayIntersection;
		const z0 = mercatorZfromAltitude(p0[2], this._center.lat);
		const z1 = mercatorZfromAltitude(p1[2], this._center.lat);
		return new MercatorCoordinate(
			numnum(p0[0], p1[0], t) / this.worldSize,
			numnum(p0[1], p1[1], t) / this.worldSize,
			numnum(z0, z1, t));
	}

	pointCoordinate(p: Point): MercatorCoordinate {
		const horizonOffset = this.horizonLineFromTop(false);
		const clamped = new Point(p.x, Math.max(horizonOffset, p.y));
		return this.rayIntersectionCoordinate(this.pointRayIntersection(clamped));
	}

	pointCoordinate3D(p: Point): MercatorCoordinate {
		if (!this.elevation) {
			return this.pointCoordinate(p);
		}
		const elevation = this.elevation;
		let raycast = this.elevation.pointCoordinate(p);
		if (raycast) {
			return new MercatorCoordinate(raycast[0], raycast[1], raycast[2]);
		}
		let start = 0, end = this.horizonLineFromTop();
		if (p.y > end) {
			return this.pointCoordinate(p);
		} // holes between tiles below horizon line or below bottom.
		const samples = 10;
		const threshold = 0.02 * end;
		const r = p.clone();
		for (let i = 0; i < samples && end - start > threshold; i++) {
			r.y = numnum(start, end, 0.66); // non uniform binary search favoring points closer to horizon.
			const rCast = elevation.pointCoordinate(r);
			if (rCast) {
				end = r.y;
				raycast = rCast;
			} else {
				start = r.y;
			}
		}
		return raycast ? new MercatorCoordinate(raycast[0], raycast[1], raycast[2]) : this.pointCoordinate(p);
	}

	isPointAboveHorizon(p: Point): boolean {
		if (!this.elevation) {
			const horizon = this.horizonLineFromTop();
			return p.y < horizon;
		} else {
			return !this.elevation.pointCoordinate(p);
		}
	}

	_coordinatePoint(coord: MercatorCoordinate, sampleTerrainIn3D: boolean) {
		const elevation = sampleTerrainIn3D && this.elevation ? this.elevation.getAtPointOrZero(coord, this._centerAltitude) : this._centerAltitude;
		const p: Tup4 = [coord.x * this.worldSize, coord.y * this.worldSize, elevation + coord.toAltitude(), 1];
		vec4.transformMat4(p, p, this.pixelMatrix);
		return p[3] > 0 ?
			new Point(p[0] / p[3], p[1] / p[3]) :
			new Point(Number.MAX_VALUE, Number.MAX_VALUE);
	}

	getBounds(): mapboxgl.LngLatBounds {
		if (this._terrainEnabled()) {
			return this._getBounds3D();
		}
		return new mapboxgl.LngLatBounds()
			.extend(this.pointLocation(new Point(this._edgeInsets.left, this._edgeInsets.top)))
			.extend(this.pointLocation(new Point(this.width - this._edgeInsets.right, this._edgeInsets.top)))
			.extend(this.pointLocation(new Point(this.width - this._edgeInsets.right, this.height - this._edgeInsets.bottom)))
			.extend(this.pointLocation(new Point(this._edgeInsets.left, this.height - this._edgeInsets.bottom)));
	}

	_getBounds3D(): mapboxgl.LngLatBounds {
		assert(this.elevation);
		const minmax = this.elevation.visibleDemTiles.reduce((acc, t) => {
			if (t.dem) {
				const tree = t.dem.tree;
				acc.min = Math.min(acc.min, tree.minimums[0]);
				acc.max = Math.max(acc.max, tree.maximums[0]);
			}
			return acc;
		}, {min: Number.MAX_VALUE, max: 0});
		minmax.min *= this.elevation.exaggeration();
		minmax.max *= this.elevation.exaggeration();
		const top = this.horizonLineFromTop();
		return [
			new Point(0, top),
			new Point(this.width, top),
			new Point(this.width, this.height),
			new Point(0, this.height),
		].reduce((acc, p) => {
			return acc
				.extend(this.coordinateLocation(this.rayIntersectionCoordinate(this.pointRayIntersection(p, minmax.min))))
				.extend(this.coordinateLocation(this.rayIntersectionCoordinate(this.pointRayIntersection(p, minmax.max))));
		}, new mapboxgl.LngLatBounds());
	}

	horizonLineFromTop(clampToTop: boolean = true): number {
		// h is height of space above map center to horizon.
		const h = this.height / 2 / Math.tan(this._fov / 2) / Math.tan(Math.max(this._pitch, 0.1)) + this.centerOffset.y;
		// incorporate 3% of the area above center to account for reduced precision.
		const horizonEpsilon = 0.03;
		const offset = this.height / 2 - h * (1 - horizonEpsilon);
		return clampToTop ? Math.max(0, offset) : offset;
	}

	getMaxBounds(): mapboxgl.LngLatBounds | null {
		if (!this.latRange || this.latRange.length !== 2 ||
			!this.lngRange || this.lngRange.length !== 2) {
			return null;
		}
		return new mapboxgl.LngLatBounds([this.lngRange[0], this.latRange[0]], [this.lngRange[1], this.latRange[1]]);
	}

	setMaxBounds(bounds?: mapboxgl.LngLatBounds) {
		if (bounds) {
			this.lngRange = [bounds.getWest(), bounds.getEast()];
			this.latRange = [bounds.getSouth(), bounds.getNorth()];
			this._constrain();
		} else {
			this.lngRange = undefined;
			this.latRange = [-this.maxValidLatitude, this.maxValidLatitude];
		}
	}

	calculatePosMatrix(unwrappedTileID: UnwrappedTileID, worldSize: number): mat4 {
		const canonical = unwrappedTileID.canonical;
		const scale = worldSize / this.zoomScale(canonical.z);
		const unwrappedX = canonical.x + Math.pow(2, canonical.z) * unwrappedTileID.wrap;
		const posMatrix = mat4.identity(mat4.create());
		mat4.translate(posMatrix, posMatrix, [unwrappedX * scale, canonical.y * scale, 0]);
		mat4.scale(posMatrix, posMatrix, [scale / EXTENT, scale / EXTENT, 1]);
		return posMatrix;
	}

	calculateFogTileMatrix(unwrappedTileID: UnwrappedTileID): Float32Array {
		const fogTileMatrixKey = unwrappedTileID.key;
		const cache = this._fogTileMatrixCache;
		if (cache[fogTileMatrixKey]) {
			return cache[fogTileMatrixKey];
		}
		const posMatrix = this.calculatePosMatrix(unwrappedTileID, this.cameraWorldSize);
		mat4.multiply(posMatrix, this.worldToFogMatrix, posMatrix);
		cache[fogTileMatrixKey] = new Float32Array(posMatrix);
		return cache[fogTileMatrixKey];
	}

	calculateProjMatrix(unwrappedTileID: UnwrappedTileID, aligned: boolean = false): Float32Array {
		const projMatrixKey = unwrappedTileID.key;
		const cache = aligned ? this._alignedProjMatrixCache : this._projMatrixCache;
		if (cache[projMatrixKey]) {
			return cache[projMatrixKey];
		}
		const posMatrix = this.calculatePosMatrix(unwrappedTileID, this.worldSize);
		mat4.multiply(posMatrix, aligned ? this.alignedProjMatrix : this.projMatrix, posMatrix);
		cache[projMatrixKey] = new Float32Array(posMatrix);
		return cache[projMatrixKey];
	}

	customLayerMatrix(): mat4 {
		return <mat4>this.mercatorMatrix.slice();
	}

	recenterOnTerrain() {
		if (!this._elevation) {
			return;
		}
		const elevation: Elevation = this._elevation;
		this._updateCameraState();
		// Cast a ray towards the sea level and find the intersection point with the terrain.
		const start = this._camera.position;
		const dir = this._camera.forward();
		if (start[2] <= 0 || dir[2] >= 0) {
			return;
		}
		// The raycast function expects z-component to be in meters
		const metersToMerc = mercatorZfromAltitude(1.0, this._center.lat);
		start[2] /= metersToMerc;
		dir[2] /= metersToMerc;
		vec3.normalize(dir, dir);
		const t = elevation.raycast(start, dir, elevation.exaggeration());
		if (t) {
			const point = vec3.scaleAndAdd([0, 0, 0], start, dir, t);
			const newCenter = new MercatorCoordinate(point[0], point[1], mercatorZfromAltitude(point[2], latFromMercatorY(point[1])));
			const pos = this._camera.position;
			const camToNew: Tup3 = [newCenter.x - pos[0], newCenter.y - pos[1], newCenter.z - pos[2]];
			const maxAltitude = newCenter.z + vec3.length(camToNew);
			// Camera zoom has to be updated as the orbit distance might have changed
			this._cameraZoom = this._zoomFromMercatorZ(maxAltitude);
			this._centerAltitude = newCenter.toAltitude();
			this._center = newCenter.toLngLat();
			this._updateZoomFromElevation();
			this._constrain();
			this._calcMatrices();
		}
	}

	_constrainCameraAltitude() {
		if (!this._elevation) {
			return;
		}
		const elevation: Elevation = this._elevation;
		this._updateCameraState();
		const elevationAtCamera = elevation.getAtPointOrZero(this._camera.mercatorPosition);
		const minHeight = this._minimumHeightOverTerrain() * Math.cos(degToRad(this._maxPitch));
		const terrainElevation = mercatorZfromAltitude(elevationAtCamera, this._center.lat);
		const cameraHeight = this._camera.position[2] - terrainElevation;
		if (cameraHeight < minHeight) {
			const center = MercatorCoordinate.fromLngLat(this._center, this._centerAltitude);
			const cameraPos = this._camera.mercatorPosition;
			const cameraToCenter: Tup3 = [center.x - cameraPos.x, center.y - cameraPos.y, center.z - cameraPos.z];
			const prevDistToCamera = vec3.length(cameraToCenter);
			// Adjust the camera vector so that the camera is placed above the terrain.
			// Distance between the camera and the center point is kept constant.
			cameraToCenter[2] -= minHeight - cameraHeight;
			const newDistToCamera = vec3.length(cameraToCenter);
			if (newDistToCamera === 0) {
				return;
			}
			vec3.scale(cameraToCenter, cameraToCenter, prevDistToCamera / newDistToCamera);
			this._camera.position = [center.x - cameraToCenter[0], center.y - cameraToCenter[1], center.z - cameraToCenter[2]];
			this._camera.orientation = orientationFromFrame(cameraToCenter, this._camera.up()) || this._camera.orientation;
			this._updateStateFromCamera();
		}
	}

	_constrain() {
		if (!this.center || !this.width || !this.height || this._constraining) {
			return;
		}
		this._constraining = true;
		let minY = -90;
		let maxY = 90;
		let minX = -180;
		let maxX = 180;
		let sy, sx, x2, y2;
		const size = this.size,
			unmodified = this._unmodified;
		if (this.latRange) {
			const latRange = this.latRange;
			minY = mercatorYfromLat(latRange[1]) * this.worldSize;
			maxY = mercatorYfromLat(latRange[0]) * this.worldSize;
			sy = maxY - minY < size.y ? size.y / (maxY - minY) : 0;
		}
		if (this.lngRange) {
			const lngRange = this.lngRange;
			minX = mercatorXfromLng(lngRange[0]) * this.worldSize;
			maxX = mercatorXfromLng(lngRange[1]) * this.worldSize;
			sx = maxX - minX < size.x ? size.x / (maxX - minX) : 0;
		}
		const point = this.point;
		// how much the map should scale to fit the screen into given latitude/longitude ranges
		const s = Math.max(sx || 0, sy || 0);
		if (s) {
			this.center = this.unproject(new Point(
				sx ? (maxX + minX) / 2 : point.x,
				sy ? (maxY + minY) / 2 : point.y));
			this.zoom += this.scaleZoom(s);
			this._unmodified = unmodified;
			this._constraining = false;
			return;
		}
		if (this.latRange) {
			const y = point.y,
				h2 = size.y / 2;
			if (y - h2 < minY) {
				y2 = minY + h2;
			}
			if (y + h2 > maxY) {
				y2 = maxY - h2;
			}
		}
		if (this.lngRange) {
			const x = point.x,
				w2 = size.x / 2;
			if (x - w2 < minX) {
				x2 = minX + w2;
			}
			if (x + w2 > maxX) {
				x2 = maxX - w2;
			}
		}
		// pan the map if the screen goes off the range
		if (x2 !== undefined || y2 !== undefined) {
			this.center = this.unproject(new Point(
				x2 !== undefined ? x2 : point.x,
				y2 !== undefined ? y2 : point.y));
		}
		this._constrainCameraAltitude();
		this._unmodified = unmodified;
		this._constraining = false;
	}

	_minZoomForBounds(): number {
		const minZoomForDim = (dim: number, range: Tup2): number => {
			return Math.log2(dim / (this.tileSize * Math.abs(range[1] - range[0])));
		};
		let minLatZoom = DEFAULT_MIN_ZOOM;
		if (this.latRange) {
			const latRange = this.latRange;
			minLatZoom = minZoomForDim(this.height, [mercatorYfromLat(latRange[0]), mercatorYfromLat(latRange[1])]);
		}
		let minLngZoom = DEFAULT_MIN_ZOOM;
		if (this.lngRange) {
			const lngRange = this.lngRange;
			minLngZoom = minZoomForDim(this.width, [mercatorXfromLng(lngRange[0]), mercatorXfromLng(lngRange[1])]);
		}
		return Math.max(minLatZoom, minLngZoom);
	}

	_maxCameraBoundsDistance(): number {
		return this._mercatorZfromZoom(this._minZoomForBounds());
	}

	_calcMatrices() {
		if (!this.height) {
			return;
		}
		const halfFov = this._fov / 2;
		const offset = this.centerOffset;
		this.cameraToCenterDistance = 0.5 / Math.tan(halfFov) * this.height;
		const pixelsPerMeter = this.pixelsPerMeter;
		this._updateCameraState();
		// Find the distance from the center point [width/2 + offset.x, height/2 + offset.y] to the
		// center top point [width/2 + offset.x, 0] in Z units, using the law of sines.
		// 1 Z unit is equivalent to 1 horizontal px at the center of the map
		// (the distance between[width/2, height/2] and [width/2 + 1, height/2])
		const groundAngle = Math.PI / 2 + this._pitch;
		const fovAboveCenter = this.fovAboveCenter;
		// Adjust distance to MSL by the minimum possible elevation visible on screen,
		// this way the far plane is pushed further in the case of negative elevation.
		const minElevationInPixels = this.elevation ?
			this.elevation.getMinElevationBelowMSL() * pixelsPerMeter :
			0;
		const cameraToSeaLevelDistance = ((this._camera.position[2] * this.worldSize) - minElevationInPixels) / Math.cos(this._pitch);
		const topHalfSurfaceDistance = Math.sin(fovAboveCenter) * cameraToSeaLevelDistance / Math.sin(clamp(Math.PI - groundAngle - fovAboveCenter, 0.01, Math.PI - 0.01));
		const point = this.point;
		const x = point.x, y = point.y;
		// Calculate z distance of the farthest fragment that should be rendered.
		const furthestDistance = Math.cos(Math.PI / 2 - this._pitch) * topHalfSurfaceDistance + cameraToSeaLevelDistance;
		// Add a bit extra to avoid precision problems when a fragment's distance is exactly `furthestDistance`
		const horizonDistance = cameraToSeaLevelDistance * (1 / this._horizonShift);
		const farZ = Math.min(furthestDistance * 1.01, horizonDistance);
		// The larger the value of nearZ is
		// - the more depth precision is available for features (good)
		// - clipping starts appearing sooner when the camera is close to 3d features (bad)
		//
		// Smaller values worked well for mapbox-gl-js but deckgl was encountering precision issues
		// when rendering it's layers using custom layers. This value was experimentally chosen and
		// seems to solve z-fighting issues in deckgl while not clipping buildings too close to the camera.
		const nearZ = this.height / 50;
		const worldToCamera = this._camera.getWorldToCamera(this.worldSize, pixelsPerMeter);
		const cameraToClip = this._camera.getCameraToClipPerspective(this._fov, this.width / this.height, nearZ, farZ);
		// Apply center of perspective offset
		cameraToClip[8] = -offset.x * 2 / this.width;
		cameraToClip[9] = offset.y * 2 / this.height;
		let m = mat4.mul(mat4.create(), cameraToClip, worldToCamera);
		// The mercatorMatrix can be used to transform points from mercator coordinates
		// ([0, 0] nw, [1, 1] se) to GL coordinates.
		this.mercatorMatrix = mat4.scale(mat4.create(), m, [this.worldSize, this.worldSize, this.worldSize / pixelsPerMeter]);
		this.projMatrix = m;
		// For tile cover calculation, use inverted of base (non elevated) matrix
		// as tile elevations are in tile coordinates and relative to center elevation.
		this.invProjMatrix = mat4.invert(mat4.create(), this.projMatrix);
		const view = new Float32Array(16);
		mat4.identity(view);
		mat4.scale(view, view, [1, -1, 1]);
		mat4.rotateX(view, view, this._pitch);
		mat4.rotateZ(view, view, this.angle);
		const projection = mat4.perspective(new Float32Array(16), this._fov, this.width / this.height, nearZ, farZ);
		// The distance in pixels the skybox needs to be shifted down by to meet the shifted horizon.
		const skyboxHorizonShift = (Math.PI / 2 - this._pitch) * (this.height / this._fov) * this._horizonShift;
		// Apply center of perspective offset to skybox projection
		projection[8] = -offset.x * 2 / this.width;
		projection[9] = (offset.y + skyboxHorizonShift) * 2 / this.height;
		this.skyboxMatrix = mat4.multiply(view, projection, view);
		// Make a second projection matrix that is aligned to a pixel grid for rendering raster tiles.
		// We're rounding the (floating point) x/y values to achieve to avoid rendering raster images to fractional
		// coordinates. Additionally, we adjust by half a pixel in either direction in case that viewport dimension
		// is an odd integer to preserve rendering to the pixel grid. We're rotating this shift based on the angle
		// of the transformation so that 0°, 90°, 180°, and 270° rasters are crisp, and adjust the shift so that
		// it is always <= 0.5 pixels.
		const xShift = (this.width % 2) / 2, yShift = (this.height % 2) / 2,
			angleCos = Math.cos(this.angle), angleSin = Math.sin(this.angle),
			dx = x - Math.round(x) + angleCos * xShift + angleSin * yShift,
			dy = y - Math.round(y) + angleCos * yShift + angleSin * xShift;
		const alignedM = mat4.create();
		mat4.translate(alignedM, alignedM, [dx > 0.5 ? dx - 1 : dx, dy > 0.5 ? dy - 1 : dy, 0]);
		this.alignedProjMatrix = alignedM;
		m = mat4.create();
		mat4.scale(m, m, [this.width / 2, -this.height / 2, 1]);
		mat4.translate(m, m, [1, -1, 0]);
		this.labelPlaneMatrix = m;
		m = mat4.create();
		mat4.scale(m, m, [1, -1, 1]);
		mat4.translate(m, m, [-1, -1, 0]);
		mat4.scale(m, m, [2 / this.width, 2 / this.height, 1]);
		this.glCoordMatrix = m;
		// matrix for conversion from location to screen coordinates
		this.pixelMatrix = mat4.multiply(mat4.create(), this.labelPlaneMatrix, this.projMatrix);
		this._calcFogMatrices();
		// inverse matrix for conversion from screen coordinates to location
		m = mat4.invert(mat4.create(), this.pixelMatrix);
		if (!m) {
			throw new Error('failed to invert matrix');
		}
		this.pixelMatrixInverse = m;
		this._projMatrixCache = {};
		this._alignedProjMatrixCache = {};
	}

	_calcFogMatrices() {
		this._fogTileMatrixCache = {};
		const cameraWorldSize = this.cameraWorldSize;
		const cameraPixelsPerMeter = this.cameraPixelsPerMeter;
		const cameraPos = this._camera.position;
		// The mercator fog matrix encodes transformation necessary to transform a position to camera fog space (in meters):
		// translates p to camera origin and transforms it from pixels to meters. The windowScaleFactor is used to have a
		// consistent transformation across different window sizes.
		// - p = p - cameraOrigin
		// - p.xy = p.xy * cameraWorldSize * windowScaleFactor
		// - p.z  = p.z  * cameraPixelsPerMeter * windowScaleFactor
		const windowScaleFactor = 1 / this.height;
		const metersToPixel: Tup3 = [cameraWorldSize, cameraWorldSize, cameraPixelsPerMeter];
		vec3.scale(metersToPixel, metersToPixel, windowScaleFactor);
		vec3.scale(cameraPos, cameraPos, -1);
		vec3.multiply(cameraPos, cameraPos, metersToPixel);
		const m = mat4.create();
		mat4.translate(m, m, cameraPos);
		mat4.scale(m, m, metersToPixel);
		this.mercatorFogMatrix = m;
		// The worldToFogMatrix can be used for conversion from world coordinates to relative camera position in
		// units of fractions of the map height. Later composed with tile position to construct the fog tile matrix.
		this.worldToFogMatrix = this._camera.getWorldToCameraPosition(cameraWorldSize, cameraPixelsPerMeter, windowScaleFactor);
	}

	_updateCameraState() {
		if (!this.height) {
			return;
		}
		// Set camera orientation and move it to a proper distance from the map
		this._camera.setPitchBearing(this._pitch, this.angle);
		const dir = this._camera.forward();
		const distance = this.cameraToCenterDistance;
		const center = this.point;
		// Use camera zoom (if terrain is enabled) to maintain constant altitude to sea level
		const zoom = this._cameraZoom ? this._cameraZoom : this._zoom;
		const altitude = this._mercatorZfromZoom(zoom);
		const height = altitude - mercatorZfromAltitude(this._centerAltitude, this.center.lat);
		// simplified version of: this._worldSizeFromZoom(this._zoomFromMercatorZ(height))
		const updatedWorldSize = this.cameraToCenterDistance / height;
		this._camera.position = [
			center.x / this.worldSize - (dir[0] * distance) / updatedWorldSize,
			center.y / this.worldSize - (dir[1] * distance) / updatedWorldSize,
			mercatorZfromAltitude(this._centerAltitude, this._center.lat) + (-dir[2] * distance) / updatedWorldSize,
		];
	}

	_translateCameraConstrained(translation: vec3) {
		const maxDistance = this._maxCameraBoundsDistance();
		// Define a ceiling in mercator Z
		const maxZ = maxDistance * Math.cos(this._pitch);
		const z = this._camera.position[2];
		const deltaZ = translation[2];
		let t = 1;
		// we only need to clamp if the camera is moving upwards
		if (deltaZ > 0) {
			t = Math.min((maxZ - z) / deltaZ, 1);
		}
		this._camera.position = vec3.scaleAndAdd(vec3.create(), this._camera.position, translation, t);
		this._updateStateFromCamera();
	}

	_updateStateFromCamera() {
		const position = this._camera.position;
		const dir = this._camera.forward();
		const {pitch, bearing} = this._camera.getPitchBearing();
		// Compute zoom from the distance between camera and terrain
		const centerAltitude = mercatorZfromAltitude(this._centerAltitude, this.center.lat);
		const minHeight = this._mercatorZfromZoom(this._maxZoom) * Math.cos(degToRad(this._maxPitch));
		const height = Math.max((position[2] - centerAltitude) / Math.cos(pitch), minHeight);
		const zoom = this._zoomFromMercatorZ(height);
		// Cast a ray towards the ground to find the center point
		vec3.scaleAndAdd(position, position, dir, height);
		this._pitch = clamp(pitch, degToRad(this.minPitch), degToRad(this.maxPitch));
		this.angle = wrap(bearing, -Math.PI, Math.PI);
		this._setZoom(clamp(zoom, this._minZoom, this._maxZoom));
		if (this._terrainEnabled()) {
			this._updateCameraOnTerrain();
		}
		this._center = new MercatorCoordinate(position[0], position[1], position[2]).toLngLat();
		this._unmodified = false;
		this._constrain();
		this._calcMatrices();
	}

	_worldSizeFromZoom(zoom: number): number {
		return Math.pow(2.0, zoom) * this.tileSize;
	}

	_mercatorZfromZoom(zoom: number): number {
		return this.cameraToCenterDistance / this._worldSizeFromZoom(zoom);
	}

	_minimumHeightOverTerrain() {
		// Determine minimum height for the camera over the terrain related to current zoom.
		// Values above than 2 allow max-pitch camera closer to e.g. top of the hill, exposing
		// drape raster overscale artifacts or cut terrain (see under it) as it gets clipped on
		// near plane. Returned value is in mercator coordinates.
		const MAX_DRAPE_OVERZOOM = 2;
		const zoom = Math.min((this._cameraZoom != null ? this._cameraZoom : this._zoom) + MAX_DRAPE_OVERZOOM, this._maxZoom);
		return this._mercatorZfromZoom(zoom);
	}

	_zoomFromMercatorZ(z: number): number {
		return this.scaleZoom(this.cameraToCenterDistance / (z * this.tileSize));
	}

	_terrainEnabled(): boolean {
		return !!this._elevation;
	}

	isHorizonVisibleForPoints(p0: Point, p1: Point): boolean {
		const minX = Math.min(p0.x, p1.x);
		const maxX = Math.max(p0.x, p1.x);
		const minY = Math.min(p0.y, p1.y);
		const maxY = Math.max(p0.y, p1.y);
		const min = new Point(minX, minY);
		const max = new Point(maxX, maxY);
		const corners = [
			min, max,
			new Point(minX, maxY),
			new Point(maxX, minY),
		];
		const minWX = (this._renderWorldCopies) ? -NUM_WORLD_COPIES : 0;
		const maxWX = (this._renderWorldCopies) ? 1 + NUM_WORLD_COPIES : 1;
		const minWY = 0;
		const maxWY = 1;
		for (const corner of corners) {
			const rayIntersection = this.pointRayIntersection(corner);
			if (rayIntersection.t < 0) {
				return true;
			}
			const coordinate = this.rayIntersectionCoordinate(rayIntersection);
			if (coordinate.x < minWX || coordinate.y < minWY ||
				coordinate.x > maxWX || coordinate.y > maxWY) {
				return true;
			}
		}
		return false;
	}

	isHorizonVisible(): boolean {
		// we consider the horizon as visible if the angle between
		// a the top plane of the frustum and the map plane is smaller than this threshold.
		const horizonAngleEpsilon = 2;
		if (this.pitch + radToDeg(this.fovAboveCenter) > (90 - horizonAngleEpsilon)) {
			return true;
		}
		return this.isHorizonVisibleForPoints(new Point(0, 0), new Point(this.width, this.height));
	}

	zoomDeltaToMovement(center: vec3, zoomDelta: number): number {
		const distance = vec3.length(vec3.sub(vec3.create(), this._camera.position, center));
		const relativeZoom = this._zoomFromMercatorZ(distance) + zoomDelta;
		return distance - this._mercatorZfromZoom(relativeZoom);
	}

	getCameraPoint() {
		const pitch = this._pitch;
		const yOffset = Math.tan(pitch) * (this.cameraToCenterDistance || 1);
		return this.centerPoint.add(new Point(0, yOffset));
	}
}

class Elevation {
	getAtPointOrZero(point: MercatorCoordinate, defaultIfNotLoaded: number = 0): number {
		return this.getAtPoint(point, defaultIfNotLoaded) || 0;
	}

	getAtPoint(point: MercatorCoordinate, defaultIfNotLoaded?: number, exaggerated: boolean = true): number | null {
		const src = null;
		if (!src) {
			return isNumber(defaultIfNotLoaded) ? defaultIfNotLoaded : null;
		}
		if ((point.y < 0.0) || (point.y > 1.0)) {
			return isNumber(defaultIfNotLoaded) ? defaultIfNotLoaded : null;
		}
		// const z = cache.getSource().maxzoom;
		const z = 22;
		const tiles = 1 << z;
		const wrap = Math.floor(point.x);
		const px = point.x - wrap;
		const tileID = new OverscaledTileID(z, wrap, z, Math.floor(px * tiles), Math.floor(point.y * tiles));
		const demTile = this.findDEMTileFor(tileID);
		if (!(demTile && demTile.dem)) {
			return isNumber(defaultIfNotLoaded) ? defaultIfNotLoaded : null;
		}
		const dem = demTile.dem;
		const tilesAtTileZoom = 1 << demTile.tileID.canonical.z;
		const x = (px * tilesAtTileZoom - demTile.tileID.canonical.x) * dem.dim;
		const y = (point.y * tilesAtTileZoom - demTile.tileID.canonical.y) * dem.dim;
		const i = Math.floor(x);
		const j = Math.floor(y);
		const exaggeration = exaggerated ? this.exaggeration() : 1;
		return exaggeration * numnum(
			numnum(dem.get(i, j), dem.get(i, j + 1), y - j),
			numnum(dem.get(i + 1, j), dem.get(i + 1, j + 1), y - j),
			x - i);
	}

	getAtTileOffset(tileID: OverscaledTileID, x: number, y: number): number {
		const tilesAtTileZoom = 1 << tileID.canonical.z;
		return this.getAtPointOrZero(new MercatorCoordinate(
			tileID.wrap + (tileID.canonical.x + x / EXTENT) / tilesAtTileZoom,
			(tileID.canonical.y + y / EXTENT) / tilesAtTileZoom));
	}

	getMinMaxForTile(tileID: OverscaledTileID): {min: number, max: number} | null {
		const demTile = this.findDEMTileFor(tileID);
		if (!(demTile && demTile.dem)) {
			return null;
		}
		const dem = demTile.dem;
		const tree = dem.tree;
		const demTileID = demTile.tileID;
		const scale = 1 << tileID.canonical.z - demTileID.canonical.z;
		let xOffset = tileID.canonical.x / scale - demTileID.canonical.x;
		let yOffset = tileID.canonical.y / scale - demTileID.canonical.y;
		let index = 0; // Start from DEM tree root.
		for (let i = 0; i < tileID.canonical.z - demTileID.canonical.z; i++) {
			if (tree.leaves[index]) {
				break;
			}
			xOffset *= 2;
			yOffset *= 2;
			const childOffset = 2 * Math.floor(yOffset) + Math.floor(xOffset);
			index = tree.childOffsets[index] + childOffset;
			xOffset = xOffset % 1;
			yOffset = yOffset % 1;
		}
		return {min: this.exaggeration() * tree.minimums[index], max: this.exaggeration() * tree.maximums[index]};
	}

	getMinElevationBelowMSL(): number {
		throw new Error('Pure virtual method called.');
	}

	raycast(position: vec3, dir: vec3, exaggeration: number): number {
		throw new Error('Pure virtual method called.');
	}

	pointCoordinate(screenPoint: Point): vec3 {
		throw new Error('Pure virtual method called.');
	}

	exaggeration(): number {
		throw new Error('Pure virtual method called.');
	}

	findDEMTileFor(_: OverscaledTileID): Tile {
		throw new Error('Pure virtual method called.');
	}

	get visibleDemTiles(): Array<Tile> {
		throw new Error('Getter must be implemented in subclass.');
	}
}

const earthRadius = 6371008.8;

class LngLat {
	lng: number;
	lat: number;

	constructor(lng: number, lat: number) {
		if (!(isNumber(lng) && isNumber(lat))) {
			throw new Error(`Invalid LngLat object: (${lng}, ${lat})`);
		}
		this.lng = lng;
		this.lat = lat;
		if ((this.lat > 90) || (this.lat < -90)) {
			throw new Error('Invalid LngLat latitude value: must be between -90 and 90');
		}
	}

	wrap() {
		return new LngLat(wrap(this.lng, -180, 180), this.lat);
	}

	toArray() {
		return [this.lng, this.lat];
	}

	toString() {
		return `LngLat(${this.lng}, ${this.lat})`;
	}

	distanceTo(lngLat: LngLat) {
		const rad = Math.PI / 180;
		const lat1 = this.lat * rad;
		const lat2 = lngLat.lat * rad;
		const a = Math.sin(lat1) * Math.sin(lat2) + Math.cos(lat1) * Math.cos(lat2) * Math.cos((lngLat.lng - this.lng) * rad);
		return earthRadius * Math.acos(Math.min(a, 1));
	}

	toBounds(radius: number = 0): mapboxgl.LngLatBounds {
		const earthCircumferenceInMetersAtEquator = 40075017;
		const latAccuracy = 360 * radius / earthCircumferenceInMetersAtEquator,
			lngAccuracy = latAccuracy / Math.cos((Math.PI / 180) * this.lat);
		return new mapboxgl.LngLatBounds(new LngLat(this.lng - lngAccuracy, this.lat - latAccuracy),
			new LngLat(this.lng + lngAccuracy, this.lat + latAccuracy));
	}

	static convert(input: mapboxgl.LngLatLike): LngLat {
		if (input instanceof LngLat) {
			return input;
		}
		if (Array.isArray(input) && (input.length === 2 || input.length === 3)) {
			return new LngLat(Number(input[0]), Number(input[1]));
		}
		if (!Array.isArray(input) && typeof input === 'object' && input !== null) {
			return new LngLat(
				// flow can't refine this to have one of lng or lat, so we have to cast to any
				Number('lng' in input ? input.lng : input.lon),
				Number(input.lat),
			);
		}
		throw new Error('`LngLatLike` argument must be specified as a LngLat instance, an object {lng: <lng>, lat: <lat>}, an object {lon: <lng>, lat: <lat>}, or an array of [<lng>, <lat>]');
	}
}

const earthCircumference = 2 * Math.PI * earthRadius; // meters
function circumferenceAtLatitude(latitude: number) {
	return earthCircumference * Math.cos(latitude * Math.PI / 180);
}

function mercatorXfromLng(lng: number) {
	return (180 + lng) / 360;
}

function mercatorYfromLat(lat: number) {
	return (180 - (180 / Math.PI * Math.log(Math.tan(Math.PI / 4 + lat * Math.PI / 360)))) / 360;
}

function mercatorZfromAltitude(altitude: number, lat: number) {
	return altitude / circumferenceAtLatitude(lat);
}

function lngFromMercatorX(x: number) {
	return x * 360 - 180;
}

function latFromMercatorY(y: number) {
	const y2 = 180 - y * 360;
	return 360 / Math.PI * Math.atan(Math.exp(y2 * Math.PI / 180)) - 90;
}

function altitudeFromMercatorZ(z: number, y: number) {
	return z * circumferenceAtLatitude(latFromMercatorY(y));
}

function mercatorScale(lat: number) {
	return 1 / Math.cos(lat * Math.PI / 180);
}

class MercatorCoordinate {
	x: number;
	y: number;
	z: number;

	constructor(x: number, y: number, z: number = 0) {
		this.x = +x;
		this.y = +y;
		this.z = +z;
	}

	static fromLngLat(lngLatLike: mapboxgl.LngLatLike, altitude: number = 0) {
		const lngLat = LngLat.convert(lngLatLike);
		return new MercatorCoordinate(
			mercatorXfromLng(lngLat.lng),
			mercatorYfromLat(lngLat.lat),
			mercatorZfromAltitude(altitude, lngLat.lat));
	}

	toLngLat() {
		return new LngLat(
			lngFromMercatorX(this.x),
			latFromMercatorY(this.y));
	}

	toAltitude() {
		return altitudeFromMercatorZ(this.z, this.y);
	}

	meterInMercatorCoordinateUnits() {
		// 1 meter / circumference at equator in meters * Mercator projection scale factor at this latitude
		return 1 / earthCircumference * mercatorScale(latFromMercatorY(this.y));
	}
}

class Point {
	static convert(a: Array<number> | Point): Point {
		if (a instanceof Point) {
			return a;
		}
		if (Array.isArray(a)) {
			return new Point(a[0], a[1]);
		}
		return a;
	}

	x: number;
	y: number;

	constructor(x: number, y: number) {
		this.x = x;
		this.y = y;
	}

	add(p: Point): Point {
		return this.clone()._add(p);
	}

	clone(): Point {
		return new Point(this.x, this.y);
	}

	div(k: number): Point {
		return this.clone()._div(k);
	}

	divByPoint(p: Point): Point {
		return this.clone()._divByPoint(p);
	}

	equals(other: Point): boolean {
		return this.x === other.x &&
			this.y === other.y;
	}

	mag() {
		return Math.sqrt(this.x * this.x + this.y * this.y);
	}

	matMult(m: Array<number>): Point {
		return this.clone()._matMult(m);
	}

	mult(k: number): Point {
		return this.clone()._mult(k);
	}

	multByPoint(p: Point): Point {
		return this.clone()._multByPoint(p);
	}

	perp() {
		return this.clone()._perp();
	}

	rotate(a: number): Point {
		return this.clone()._rotate(a);
	}

	rotateAround(a: number, p: Point): Point {
		return this.clone()._rotateAround(a, p);
	}

	round() {
		return this.clone()._round();
	}

	sub(p: Point): Point {
		return this.clone()._sub(p);
	}

	dist(p: Point) {
		return Math.sqrt(this.distSqr(p));
	}

	distSqr(p: Point) {
		var dx = p.x - this.x,
			dy = p.y - this.y;
		return dx * dx + dy * dy;
	}

	angle() {
		return Math.atan2(this.y, this.x);
	}

	angleTo(b: Point) {
		return Math.atan2(this.y - b.y, this.x - b.x);
	}

	angleWith(b: Point) {
		return this.angleWithSep(b.x, b.y);
	}

	angleWithSep(x: number, y: number) {
		return Math.atan2(
			this.x * y - this.y * x,
			this.x * x + this.y * y);
	}

	_matMult(m: Array<number>) {
		var x = m[0] * this.x + m[1] * this.y;
		var y = m[2] * this.x + m[3] * this.y;
		this.x = x;
		this.y = y;
		return this;
	}

	_add(p: Point) {
		this.x += p.x;
		this.y += p.y;
		return this;
	}

	_sub(p: Point) {
		this.x -= p.x;
		this.y -= p.y;
		return this;
	}

	_mult(k: number) {
		this.x *= k;
		this.y *= k;
		return this;
	}

	_div(k: number) {
		this.x /= k;
		this.y /= k;
		return this;
	}

	_multByPoint(p: Point) {
		this.x *= p.x;
		this.y *= p.y;
		return this;
	}

	_divByPoint(p: Point) {
		this.x /= p.x;
		this.y /= p.y;
		return this;
	}

	_unit() {
		this._div(this.mag());
		return this;
	}

	_perp() {
		var y = this.y;
		// noinspection JSSuspiciousNameCombination
		this.y = this.x;
		this.x = -y;
		return this;
	}

	_rotate(angle: number) {
		var cos = Math.cos(angle);
		var sin = Math.sin(angle);
		var x = cos * this.x - sin * this.y;
		var y = sin * this.x + cos * this.y;
		this.x = x;
		this.y = y;
		return this;
	}

	_rotateAround(angle: number, p: Point) {
		var cos = Math.cos(angle);
		var sin = Math.sin(angle);
		var x = p.x + cos * (this.x - p.x) - sin * (this.y - p.y);
		var y = p.y + sin * (this.x - p.x) + cos * (this.y - p.y);
		this.x = x;
		this.y = y;
		return this;
	}

	_round() {
		this.x = Math.round(this.x);
		this.y = Math.round(this.y);
		return this;
	}

	unit() {
		return this.clone()._unit();
	}
}

//
const DEG_TO_RAD = Math.PI / 180;
const RAD_TO_DEG = 180 / Math.PI;

//
function radToDeg(a: number): number {
	return a * RAD_TO_DEG;
}

//
function clamp(n: number, min: number, max: number): number {
	return Math.min(max, Math.max(min, n));
}

//
function wrap(n: number, min: number, max: number): number {
	const d = max - min;
	const w = ((n - min) % d + d) % d + min;
	return (w === min) ? max : w;
}

//
function degToRad(a: number): number {
	return a * DEG_TO_RAD;
}

//
// // function getAABBPointSquareDist(min: Point, max: Point, point?: Point): number {
function getAABBPointSquareDist(min: Tup2 | Tup4, max: Tup2 | Tup4, point?: Tup2 | Tup4): number {
	let sqDist = 0.0;
	for (let i = 0; i < 2; ++i) {
		const v = point ? point[i] : 0.0;
		assert(min[i] < max[i], 'Invalid aabb min and max inputs, min[i] must be < max[i].');
		if (min[i] > v) {
			sqDist += (min[i] - v) * (min[i] - v);
		}
		if (max[i] < v) {
			sqDist += (v - max[i]) * (v - max[i]);
		}
	}
	return sqDist;
}

//
const TILE_CORNERS: [[number, number], [number, number], [number, number], [number, number]] = [[0, 0], [1, 0], [1, 1], [0, 1]];

//
function furthestTileCorner(bearing: number): Tup2 {
	const alignedBearing = ((bearing + 45) + 360) % 360;
	const cornerIdx = Math.round(alignedBearing / 90) % 4;
	return TILE_CORNERS[cornerIdx];
}

//
function numnum(a: number, b: number, t: number) {
	return (a * (1 - t)) + (b * t);
}

//
class Ray {
	pos: vec3;
	dir: vec3;

	constructor(pos_: vec3, dir_: vec3) {
		this.pos = pos_;
		this.dir = dir_;
	}

	intersectsPlane(pt: vec3, normal: vec3, out: vec3): boolean {
		const D = vec3.dot(normal, this.dir);
		// ray is parallel to plane, so it misses
		if (Math.abs(D) < 1e-6) {
			return false;
		}
		const t = vec3.dot(vec3.sub(vec3.create(), pt, this.pos), normal) / D;
		const intersection = vec3.scaleAndAdd(vec3.create(), this.pos, this.dir, t);
		vec3.copy(out, intersection);
		return true;
	}
}

//
class Frustum {
	points: Array<Array<number>>;
	planes: Array<Array<number>>;

	constructor(points_: Array<Array<number>>, planes_: Array<Array<number>>) {
		this.points = points_;
		this.planes = planes_;
	}

	// NB: DO NOT REMOVE until you've figured out what's wrong (or have given
	//     up (don't)).
	// static fromInvProjectionMatrix(invProj: mat4, worldSize: number, zoom: number): Frustum {
	// 	const clipSpaceCorners: Array<Tup4> = [
	// 		[-1, 1, -1, 1],
	// 		[1, 1, -1, 1],
	// 		[1, -1, -1, 1],
	// 		[-1, -1, -1, 1],
	// 		[-1, 1, 1, 1],
	// 		[1, 1, 1, 1],
	// 		[1, -1, 1, 1],
	// 		[-1, -1, 1, 1],
	// 	];
	// 	const scale = Math.pow(2, zoom);
	// 	// Transform frustum corner points from clip space to tile space
	// 	const frustumCoords: Array<vec4> = clipSpaceCorners
	// 		.map(v => {
	// 			const s = vec4.transformMat4(vec4.create(), v, invProj);
	// 			const k = 1.0 / s[3] / worldSize * scale;
	// 			// Z scale in meters.
	// 			return vec4.mul(s, s, [k, k, 1.0 / s[3], k]);
	// 		});
	// 	const frustumPlanePointIndices = [
	// 		[0, 1, 2],  // near
	// 		[6, 5, 4],  // far
	// 		[0, 3, 7],  // left
	// 		[2, 1, 5],  // right
	// 		[3, 2, 6],  // bottom
	// 		[0, 4, 5],   // top
	// 	];
	// 	const frustumPlanes = frustumPlanePointIndices.map(p => {
	// 		const a = vec3.sub(vec3.create(), [frustumCoords[p[0]][0], frustumCoords[p[0]][1], frustumCoords[p[0]][2]], [frustumCoords[p[1]][0], frustumCoords[p[1]][1], frustumCoords[p[1]][2]]);
	// 		const b = vec3.sub(vec3.create(), [frustumCoords[p[2]][0], frustumCoords[p[2]][1], frustumCoords[p[2]][2]], [frustumCoords[p[1]][0], frustumCoords[p[1]][1], frustumCoords[p[1]][2]]);
	// 		const n = <Tup3>vec3.normalize([0, 0, 0], vec3.cross(vec3.create(), a, b));
	// 		const d = -vec3.dot(n, [frustumCoords[p[1]][0], frustumCoords[p[1]][1], frustumCoords[p[1]][2]]);
	// 		return n.concat(d);
	// 	});
	// 	return new Frustum(<Array<Array<number>>>frustumCoords, frustumPlanes);
	// }

	static fromInvProjectionMatrix(invProj: Float64Array, worldSize: number, zoom: number): Frustum {
		// With the implementation commented-out above (hopefully not
		// removed), if you set the map zoom to 19.62136338127992, it freezes.
		//
		// Rest of the params:
		// latitude:   34.257767308792225
		// longitude: -77.85358205557083
		// bearing:   -30.025000000000322
		// pitch:       0
		// style: mapbox://styles/mapbox/streets-v11
		const clipSpaceCorners = [
			[-1, 1, -1, 1],
			[1, 1, -1, 1],
			[1, -1, -1, 1],
			[-1, -1, -1, 1],
			[-1, 1, 1, 1],
			[1, 1, 1, 1],
			[1, -1, 1, 1],
			[-1, -1, 1, 1],
		];
		const scale = Math.pow(2, zoom);
		// Transform frustum corner points from clip space to tile space
		const frustumCoords = clipSpaceCorners
			.map(v => {
				// @ts-ignore
				const s = vec4.transformMat4([], v, invProj);
				const k = 1.0 / s[3] / worldSize * scale;
				// Z scale in meters.
				return vec4.mul(s, s, [k, k, 1.0 / s[3], k]);
			});
		const frustumPlanePointIndices = [
			[0, 1, 2],  // near
			[6, 5, 4],  // far
			[0, 3, 7],  // left
			[2, 1, 5],  // right
			[3, 2, 6],  // bottom
			[0, 4, 5],   // top
		];
		const frustumPlanes = frustumPlanePointIndices.map((p: Array<number>) => {
			// @ts-ignore
			const a = vec3.sub([], frustumCoords[p[0]], frustumCoords[p[1]]);
			// @ts-ignore
			const b = vec3.sub([], frustumCoords[p[2]], frustumCoords[p[1]]);
			// @ts-ignore
			const n = vec3.normalize([], vec3.cross([], a, b));
			// @ts-ignore
			const d = -vec3.dot(n, frustumCoords[p[1]]);
			// @ts-ignore
			return n.concat(d);
		});
		// @ts-ignore
		return new Frustum(frustumCoords, frustumPlanes);
	}
}

class Aabb {
	min: vec3;
	max: vec3;
	center: vec3;

	constructor(min_: vec3, max_: vec3) {
		this.min = min_;
		this.max = max_;
		this.center = vec3.scale(vec3.create(), vec3.add(vec3.create(), this.min, this.max), 0.5);
	}

	quadrant(index: number): Aabb {
		const split = [(index % 2) === 0, index < 2];
		const qMin = vec3.clone(this.min);
		const qMax = vec3.clone(this.max);
		for (let axis = 0; axis < split.length; axis++) {
			qMin[axis] = split[axis] ? this.min[axis] : this.center[axis];
			qMax[axis] = split[axis] ? this.center[axis] : this.max[axis];
		}
		// Temporarily, elevation is constant, hence quadrant.max.z = this.max.z
		qMax[2] = this.max[2];
		return new Aabb(qMin, qMax);
	}

	distanceX(point: Array<number>): number {
		const pointOnAabb = Math.max(Math.min(this.max[0], point[0]), this.min[0]);
		return pointOnAabb - point[0];
	}

	distanceY(point: Array<number>): number {
		const pointOnAabb = Math.max(Math.min(this.max[1], point[1]), this.min[1]);
		return pointOnAabb - point[1];
	}

	distanceZ(point: Array<number>): number {
		const pointOnAabb = Math.max(Math.min(this.max[2], point[2]), this.min[2]);
		return pointOnAabb - point[2];
	}

	// Performs a frustum-aabb intersection test. Returns 0 if there's no intersection,
	// 1 if shapes are intersecting and 2 if the aabb if fully inside the frustum.
	intersects(frustum: Frustum): number {
		// Execute separating axis test between two convex objects to find intersections
		// Each frustum plane together with 3 major axes define the separating axes
		const aabbPoints: Array<Tup4> = [
			[this.min[0], this.min[1], this.min[2], 1],
			[this.max[0], this.min[1], this.min[2], 1],
			[this.max[0], this.max[1], this.min[2], 1],
			[this.min[0], this.max[1], this.min[2], 1],
			[this.min[0], this.min[1], this.max[2], 1],
			[this.max[0], this.min[1], this.max[2], 1],
			[this.max[0], this.max[1], this.max[2], 1],
			[this.min[0], this.max[1], this.max[2], 1],
		];
		let fullyInside = true;
		for (let p = 0; p < frustum.planes.length; p++) {
			const plane = <Tup4>frustum.planes[p];
			let pointsInside = 0;
			for (let i = 0; i < aabbPoints.length; i++) {
				pointsInside += (vec4.dot(plane, aabbPoints[i]) >= 0) ? 1 : 0;
			}
			if (pointsInside === 0) {
				return 0;
			}
			if (pointsInside !== aabbPoints.length) {
				fullyInside = false;
			}
		}
		if (fullyInside) {
			return 2;
		}
		for (let axis = 0; axis < 3; axis++) {
			let projMin = Number.MAX_VALUE;
			let projMax = -Number.MAX_VALUE;
			for (let p = 0; p < frustum.points.length; p++) {
				const projectedPoint = frustum.points[p][axis] - this.min[axis];
				projMin = Math.min(projMin, projectedPoint);
				projMax = Math.max(projMax, projectedPoint);
			}
			if (projMax < 0 || projMin > this.max[axis] - this.min[axis]) {
				return 0;
			}
		}
		return 1;
	}
}

class EdgeInsets {
	top: number;
	bottom: number;
	left: number;
	right: number;

	constructor(top: number = 0, bottom: number = 0, left: number = 0, right: number = 0) {
		if (isNaN(top) || top < 0 ||
			isNaN(bottom) || bottom < 0 ||
			isNaN(left) || left < 0 ||
			isNaN(right) || right < 0
		) {
			throw new Error('Invalid value for edge-insets, top, bottom, left and right must all be numbers');
		}
		this.top = top;
		this.bottom = bottom;
		this.left = left;
		this.right = right;
	}

	interpolate(start: mapboxgl.PaddingOptions | EdgeInsets, target: mapboxgl.PaddingOptions, t: number): EdgeInsets {
		if (target.top != null && start.top != null) {
			this.top = numnum(start.top, target.top, t);
		}
		if (target.bottom != null && start.bottom != null) {
			this.bottom = numnum(start.bottom, target.bottom, t);
		}
		if (target.left != null && start.left != null) {
			this.left = numnum(start.left, target.left, t);
		}
		if (target.right != null && start.right != null) {
			this.right = numnum(start.right, target.right, t);
		}
		return this;
	}

	getCenter(width: number, height: number): Point {
		// Clamp insets so they never overflow width/height and always calculate a valid center
		const x = clamp((this.left + width - this.right) / 2, 0, width);
		const y = clamp((this.top + height - this.bottom) / 2, 0, height);
		return new Point(x, y);
	}

	equals(other: mapboxgl.PaddingOptions): boolean {
		return this.top === other.top &&
			this.bottom === other.bottom &&
			this.left === other.left &&
			this.right === other.right;
	}

	clone(): EdgeInsets {
		return new EdgeInsets(this.top, this.bottom, this.left, this.right);
	}

	toJSON(): mapboxgl.PaddingOptions {
		return {
			top: this.top,
			bottom: this.bottom,
			left: this.left,
			right: this.right,
		};
	}
}

function getColumn(matrix: mat4, col: number): vec4 {
	return [matrix[col * 4], matrix[col * 4 + 1], matrix[col * 4 + 2], matrix[col * 4 + 3]];
}

function setColumn(matrix: mat4, col: number, values: vec4) {
	matrix[col * 4] = values[0];
	matrix[col * 4 + 1] = values[1];
	matrix[col * 4 + 2] = values[2];
	matrix[col * 4 + 3] = values[3];
}

function updateTransformOrientation(matrix: mat4, orientation: quat) {
	// Take temporary copy of position to prevent it from being overwritten
	const position: vec4 = getColumn(matrix, 3);
	// Convert quaternion to rotation matrix
	mat4.fromQuat(matrix, orientation);
	setColumn(matrix, 3, position);
}

function updateTransformPosition(matrix: mat4, position: vec3) {
	setColumn(matrix, 3, [position[0], position[1], position[2], 1.0]);
}

function orientationFromPitchBearing(pitch: number, bearing: number): quat {
	// Both angles are considered to define CW rotation around their respective axes.
	// Values have to be negated to achieve the proper quaternion in left handed coordinate space
	const orientation = quat.identity(quat.create());
	quat.rotateZ(orientation, orientation, -bearing);
	quat.rotateX(orientation, orientation, -pitch);
	return orientation;
}

function orientationFromFrame(forward: vec3, up: vec3): quat | null {
	// Find right-vector of the resulting coordinate frame. Up-vector has to be
	// sanitized first in order to remove the roll component from the orientation
	const xyForward = <Tup3>[forward[0], forward[1], 0];
	const xyUp: Tup3 = [up[0], up[1], 0];
	const epsilon = 1e-15;
	if (vec3.length(xyForward) >= epsilon) {
		// Roll rotation can be seen as the right vector not being on the xy-plane, ie. right[2] != 0.0.
		// It can be negated by projecting the up vector on top of the forward vector.
		const xyDir = vec3.normalize(vec3.create(), xyForward);
		vec3.scale(xyUp, xyDir, vec3.dot(xyUp, xyDir));
		up[0] = xyUp[0];
		up[1] = xyUp[1];
	}
	const right = vec3.cross(vec3.create(), up, forward);
	if (vec3.len(right) < epsilon) {
		return null;
	}
	const bearing = Math.atan2(-right[1], right[0]);
	const pitch = Math.atan2(Math.sqrt(forward[0] * forward[0] + forward[1] * forward[1]), -forward[2]);
	return orientationFromPitchBearing(pitch, bearing);
}

class FreeCamera {
	_transform: mat4;
	_orientation: quat;

	constructor(position?: vec3, orientation?: quat) {
		this._transform = mat4.identity(new Float32Array(16));
		this._orientation = quat.identity(new Float32Array(16));
		if (orientation) {
			this._orientation = orientation;
			updateTransformOrientation(this._transform, this._orientation);
		}
		if (position) {
			updateTransformPosition(this._transform, position);
		}
	}

	get mercatorPosition(): MercatorCoordinate {
		const pos = this.position;
		return new MercatorCoordinate(pos[0], pos[1], pos[2]);
	}

	get position(): vec3 {
		const col: vec4 = getColumn(this._transform, 3);
		return [col[0], col[1], col[2]];
	}

	set position(value: vec3) {
		updateTransformPosition(this._transform, value);
	}

	get orientation(): quat {
		return this._orientation;
	}

	set orientation(value: quat) {
		this._orientation = value;
		updateTransformOrientation(this._transform, this._orientation);
	}

	getPitchBearing(): {pitch: number, bearing: number} {
		const f = this.forward();
		const r = this.right();
		return {
			bearing: Math.atan2(-r[1], r[0]),
			pitch: Math.atan2(Math.sqrt(f[0] * f[0] + f[1] * f[1]), -f[2]),
		};
	}

	setPitchBearing(pitch: number, bearing: number) {
		this._orientation = orientationFromPitchBearing(pitch, bearing);
		updateTransformOrientation(this._transform, this._orientation);
	}

	forward(): vec3 {
		const col: vec4 = getColumn(this._transform, 2);
		// Forward direction is towards the negative Z-axis
		return [-col[0], -col[1], -col[2]];
	}

	up(): vec3 {
		const col: vec4 = getColumn(this._transform, 1);
		// Up direction has to be flipped to point towards north
		return [-col[0], -col[1], -col[2]];
	}

	right(): vec3 {
		const col: vec4 = getColumn(this._transform, 0);
		return [col[0], col[1], col[2]];
	}

	getCameraToWorld(worldSize: number, pixelsPerMeter: number): mat4 {
		const cameraToWorld = mat4.create();
		mat4.invert(cameraToWorld, this.getWorldToCamera(worldSize, pixelsPerMeter));
		return cameraToWorld;
	}

	getWorldToCameraPosition(worldSize: number, pixelsPerMeter: number, uniformScale: number): mat4 {
		const invPosition = this.position;
		vec3.scale(invPosition, invPosition, -worldSize);
		const matrix = mat4.create();
		mat4.fromScaling(matrix, [uniformScale, uniformScale, uniformScale]);
		mat4.translate(matrix, matrix, invPosition);
		// Adjust scale on z (3rd column 3rd row)
		matrix[10] *= pixelsPerMeter;
		return matrix;
	}

	getWorldToCamera(worldSize: number, pixelsPerMeter: number): mat4 {
		// transformation chain from world space to camera space:
		// 1. Height value (z) of renderables is in meters. Scale z coordinate by pixelsPerMeter
		// 2. Transform from pixel coordinates to camera space with cameraMatrix^-1
		// 3. flip Y if required
		// worldToCamera: flip * cam^-1 * zScale
		// cameraToWorld: (flip * cam^-1 * zScale)^-1 => (zScale^-1 * cam * flip^-1)
		const matrix = mat4.create();
		// Compute inverse of camera matrix and post-multiply negated translation
		const invOrientation = quat.create();
		const invPosition = this.position;
		quat.conjugate(invOrientation, this._orientation);
		vec3.scale(invPosition, invPosition, -worldSize);
		mat4.fromQuat(matrix, invOrientation);
		mat4.translate(matrix, matrix, invPosition);
		// Pre-multiply y (2nd row)
		matrix[1] *= -1.0;
		matrix[5] *= -1.0;
		matrix[9] *= -1.0;
		matrix[13] *= -1.0;
		// Post-multiply z (3rd column)
		matrix[8] *= pixelsPerMeter;
		matrix[9] *= pixelsPerMeter;
		matrix[10] *= pixelsPerMeter;
		matrix[11] *= pixelsPerMeter;
		return matrix;
	}

	getCameraToClipPerspective(fovy: number, aspectRatio: number, nearZ: number, farZ: number): mat4 {
		const matrix = mat4.create();
		mat4.perspective(matrix, fovy, aspectRatio, nearZ, farZ);
		return matrix;
	}

	getDistanceToElevation(elevationMeters: number): number {
		const z0 = elevationMeters === 0 ? 0 : mercatorZfromAltitude(elevationMeters, this.position[1]);
		const f = this.forward();
		return (z0 - this.position[2]) / f[2];
	}

	clone(): FreeCamera {
		return new FreeCamera(<vec3>[...this.position], <quat>[...this.orientation]);
	}
}

class CanonicalTileID {
	z: number;
	x: number;
	y: number;
	key: number;

	constructor(z: number, x: number, y: number) {
		assert((z >= 0) && (z <= 25));
		assert((x >= 0) && (x < Math.pow(2, z)));
		assert((y >= 0) && (y < Math.pow(2, z)));
		this.z = z;
		this.x = x;
		this.y = y;
		this.key = calculateKey(0, z, z, x, y);
	}

	equals(id: CanonicalTileID) {
		return (this.z === id.z) && (this.x === id.x) && (this.y === id.y);
	}

	getTilePoint(coord: MercatorCoordinate) {
		const tilesAtZoom = Math.pow(2, this.z);
		return new Point(
			(coord.x * tilesAtZoom - this.x) * EXTENT,
			(coord.y * tilesAtZoom - this.y) * EXTENT);
	}

	getTileVec3(coord: MercatorCoordinate): vec3 {
		const tilesAtZoom = Math.pow(2, this.z);
		const x = (coord.x * tilesAtZoom - this.x) * EXTENT;
		const y = (coord.y * tilesAtZoom - this.y) * EXTENT;
		return vec3.fromValues(x, y, altitudeFromMercatorZ(coord.z, coord.y));
	}

	toString() {
		return `${this.z}/${this.x}/${this.y}`;
	}

	url(urls: Array<string>, scheme?: string) {
		const bbox = getTileBBox(this.x, this.y, this.z);
		const quadkey = getQuadkey(this.z, this.x, this.y);
		return urls[(this.x + this.y) % urls.length]
			.replace('{prefix}', (this.x % 16).toString(16) + (this.y % 16).toString(16))
			.replace('{z}', String(this.z))
			.replace('{x}', String(this.x))
			.replace('{y}', String(scheme === 'tms' ? (Math.pow(2, this.z) - this.y - 1) : this.y))
			.replace('{quadkey}', quadkey)
			.replace('{bbox-epsg-3857}', bbox);
	}
}

class UnwrappedTileID {
	wrap: number;
	canonical: CanonicalTileID;
	key: number;

	constructor(wrap: number, canonical: CanonicalTileID) {
		this.wrap = wrap;
		this.canonical = canonical;
		this.key = calculateKey(wrap, canonical.z, canonical.z, canonical.x, canonical.y);
	}
}

export class OverscaledTileID {
	overscaledZ: number;
	wrap: number;
	canonical: CanonicalTileID;
	key: number;

	constructor(overscaledZ: number, wrap: number, z: number, x: number, y: number) {
		assert(overscaledZ >= z);
		this.overscaledZ = overscaledZ;
		this.wrap = wrap;
		this.canonical = new CanonicalTileID(z, x, y);
		this.key = ((wrap === 0) && (overscaledZ === z)) ?
			this.canonical.key :
			calculateKey(wrap, overscaledZ, z, x, y);
	}

	equals(id: OverscaledTileID) {
		return (this.overscaledZ === id.overscaledZ) && (this.wrap === id.wrap) && this.canonical.equals(id.canonical);
	}

	scaledTo(targetZ: number) {
		assert(targetZ <= this.overscaledZ);
		const zDifference = this.canonical.z - targetZ;
		if (targetZ > this.canonical.z) {
			return new OverscaledTileID(targetZ, this.wrap, this.canonical.z, this.canonical.x, this.canonical.y);
		} else {
			return new OverscaledTileID(targetZ, this.wrap, targetZ, this.canonical.x >> zDifference, this.canonical.y >> zDifference);
		}
	}

	/*
	 * calculateScaledKey is an optimization:
	 * when withWrap == true, implements the same as this.scaledTo(z).key,
	 * when withWrap == false, implements the same as this.scaledTo(z).wrapped().key.
	 */
	calculateScaledKey(targetZ: number, withWrap: boolean = true): number {
		if (this.overscaledZ === targetZ && withWrap) {
			return this.key;
		}
		if (targetZ > this.canonical.z) {
			return calculateKey(this.wrap * +withWrap, targetZ, this.canonical.z, this.canonical.x, this.canonical.y);
		} else {
			const zDifference = this.canonical.z - targetZ;
			return calculateKey(this.wrap * +withWrap, targetZ, targetZ, this.canonical.x >> zDifference, this.canonical.y >> zDifference);
		}
	}

	isChildOf(parent: OverscaledTileID) {
		if (parent.wrap !== this.wrap) {
			// We can't be a child if we're in a different world copy
			return false;
		}
		const zDifference = this.canonical.z - parent.canonical.z;
		// We're first testing for z == 0, to avoid a 32 bit shift, which is undefined.
		return (parent.overscaledZ === 0) || ((parent.overscaledZ < this.overscaledZ) && (parent.canonical.x === (this.canonical.x >> zDifference)) && (parent.canonical.y === (this.canonical.y >> zDifference)));
	}

	children(sourceMaxZoom: number) {
		if (this.overscaledZ >= sourceMaxZoom) {
			// return a single tile coord representing a an overscaled tile
			return [new OverscaledTileID(this.overscaledZ + 1, this.wrap, this.canonical.z, this.canonical.x, this.canonical.y)];
		}
		const z = this.canonical.z + 1;
		const x = this.canonical.x * 2;
		const y = this.canonical.y * 2;
		return [
			new OverscaledTileID(z, this.wrap, z, x, y),
			new OverscaledTileID(z, this.wrap, z, x + 1, y),
			new OverscaledTileID(z, this.wrap, z, x, y + 1),
			new OverscaledTileID(z, this.wrap, z, x + 1, y + 1),
		];
	}

	isLessThan(rhs: OverscaledTileID) {
		if (this.wrap < rhs.wrap) {
			return true;
		}
		if (this.wrap > rhs.wrap) {
			return false;
		}
		if (this.overscaledZ < rhs.overscaledZ) {
			return true;
		}
		if (this.overscaledZ > rhs.overscaledZ) {
			return false;
		}
		if (this.canonical.x < rhs.canonical.x) {
			return true;
		}
		if (this.canonical.x > rhs.canonical.x) {
			return false;
		}
		if (this.canonical.y < rhs.canonical.y) {
			return true;
		}
		return false;
	}

	wrapped() {
		return new OverscaledTileID(this.overscaledZ, 0, this.canonical.z, this.canonical.x, this.canonical.y);
	}

	unwrapTo(wrap: number) {
		return new OverscaledTileID(this.overscaledZ, wrap, this.canonical.z, this.canonical.x, this.canonical.y);
	}

	overscaleFactor() {
		return Math.pow(2, this.overscaledZ - this.canonical.z);
	}

	toUnwrapped() {
		return new UnwrappedTileID(this.wrap, this.canonical);
	}

	toString() {
		return `${this.overscaledZ}/${this.canonical.x}/${this.canonical.y}`;
	}

	getTilePoint(coord: MercatorCoordinate) {
		return this.canonical.getTilePoint(new MercatorCoordinate(coord.x - this.wrap, coord.y));
	}

	getTileVec3(coord: MercatorCoordinate) {
		return this.canonical.getTileVec3(new MercatorCoordinate(coord.x - this.wrap, coord.y, coord.z));
	}
}

const MAX_SAFE_INTEGER = Math.pow(2, 53) - 1;

function calculateKey(wrap: number, overscaledZ: number, z: number, x: number, y: number): number {
	// only use 22 bits for x & y so that the key fits into MAX_SAFE_INTEGER
	const dim = 1 << Math.min(z, 22);
	let xy = dim * (y % dim) + (x % dim);
	// zigzag-encode wrap if we have the room for it
	if (wrap && z < 22) {
		const bitsAvailable = 2 * (22 - z);
		xy += dim * dim * ((wrap < 0 ? -2 * wrap - 1 : 2 * wrap) % (1 << bitsAvailable));
	}
	// encode z into 5 bits (24 max) and overscaledZ into 4 bits (10 max)
	const key = ((xy * 32) + z) * 16 + (overscaledZ - z);
	assert(key >= 0 && key <= MAX_SAFE_INTEGER);
	return key;
}

function getQuadkey(z: number, x: number, y: number): string {
	let quadkey = '', mask;
	for (let i = z; i > 0; i--) {
		mask = 1 << (i - 1);
		quadkey += ((x & mask ? 1 : 0) + (y & mask ? 2 : 0));
	}
	return quadkey;
}

type DEMEncoding = 'mapbox' | 'terrarium';

class DEMData {
	uid: number;
	data: Uint32Array;
	stride: number;
	dim: number;
	encoding: DEMEncoding;
	borderReady: boolean;
	_tree?: DemMinMaxQuadTree;
	get tree(): DemMinMaxQuadTree {
		if (!this._tree) {
			this._buildQuadTree();
		}
		return <DemMinMaxQuadTree>this._tree;
	}

	// RGBAImage data has uniform 1px padding on all sides: square tile edge size defines stride
	// and dim is calculated as stride - 2.
	constructor(uid: number, data: RGBAImage, encoding: DEMEncoding, borderReady: boolean = false, buildQuadTree: boolean = false) {
		this.uid = uid;
		if (data.height !== data.width) {
			throw new RangeError('DEM tiles must be square');
		}
		if (encoding && (encoding !== 'mapbox') && (encoding !== 'terrarium')) {
			throw new Error(`"${encoding}" is not a valid encoding type. Valid types include "mapbox" and "terrarium".`);
		}
		this.stride = data.height;
		const dim = this.dim = data.height - 2;
		this.data = new Uint32Array(data.data.buffer);
		this.encoding = encoding || 'mapbox';
		this.borderReady = borderReady;
		if (borderReady) {
			return;
		}
		// in order to avoid flashing seams between tiles, here we are initially populating a 1px border of pixels around the image
		// with the data of the nearest pixel from the image. this data is eventually replaced when the tile's neighboring
		// tiles are loaded and the accurate data can be backfilled using DEMData#backfillBorder
		for (let x = 0; x < dim; x++) {
			// left vertical border
			// noinspection JSSuspiciousNameCombination
			this.data[this._idx(-1, x)] = this.data[this._idx(0, x)];
			// right vertical border
			// noinspection JSSuspiciousNameCombination
			this.data[this._idx(dim, x)] = this.data[this._idx(dim - 1, x)];
			// left horizontal border
			this.data[this._idx(x, -1)] = this.data[this._idx(x, 0)];
			// right horizontal border
			this.data[this._idx(x, dim)] = this.data[this._idx(x, dim - 1)];
		}
		// corners
		this.data[this._idx(-1, -1)] = this.data[this._idx(0, 0)];
		this.data[this._idx(dim, -1)] = this.data[this._idx(dim - 1, 0)];
		this.data[this._idx(-1, dim)] = this.data[this._idx(0, dim - 1)];
		this.data[this._idx(dim, dim)] = this.data[this._idx(dim - 1, dim - 1)];
		if (buildQuadTree) {
			this._buildQuadTree();
		}
	}

	_buildQuadTree() {
		assert(!this._tree);
		// Construct the implicit sparse quad tree by traversing mips from top to down
		this._tree = new DemMinMaxQuadTree(this);
	}

	get(x: number, y: number, clampToEdge: boolean = false) {
		const pixels = new Uint8Array(this.data.buffer);
		if (clampToEdge) {
			x = clamp(x, -1, this.dim);
			y = clamp(y, -1, this.dim);
		}
		const index = this._idx(x, y) * 4;
		const unpack = this.encoding === 'terrarium' ? this._unpackTerrarium : this._unpackMapbox;
		return unpack(pixels[index], pixels[index + 1], pixels[index + 2]);
	}

	_idx(x: number, y: number) {
		if (x < -1 || x >= this.dim + 1 || y < -1 || y >= this.dim + 1) {
			throw new RangeError('out of range source coordinates for DEM data');
		}
		return (y + 1) * this.stride + (x + 1);
	}

	_unpackMapbox(r: number, g: number, b: number) {
		// unpacking formula for mapbox.terrain-rgb:
		// https://www.mapbox.com/help/access-elevation-data/#mapbox-terrain-rgb
		return ((r * 256 * 256 + g * 256.0 + b) / 10.0 - 10000.0);
	}

	_unpackTerrarium(r: number, g: number, b: number) {
		// unpacking formula for mapzen terrarium:
		// https://aws.amazon.com/public-datasets/terrain/
		return ((r * 256 + g + b / 256) - 32768.0);
	}

	getPixels() {
		return new RGBAImage({width: this.stride, height: this.stride}, new Uint8Array(this.data.buffer));
	}

	backfillBorder(borderTile: DEMData, dx: number, dy: number) {
		if (this.dim !== borderTile.dim) {
			throw new Error('dem dimension mismatch');
		}
		let xMin = dx * this.dim,
			xMax = dx * this.dim + this.dim,
			yMin = dy * this.dim,
			yMax = dy * this.dim + this.dim;
		switch (dx) {
			case -1:
				xMin = xMax - 1;
				break;
			case 1:
				xMax = xMin + 1;
				break;
		}
		switch (dy) {
			case -1:
				yMin = yMax - 1;
				break;
			case 1:
				yMax = yMin + 1;
				break;
		}
		const ox = -dx * this.dim;
		const oy = -dy * this.dim;
		for (let y = yMin; y < yMax; y++) {
			for (let x = xMin; x < xMax; x++) {
				this.data[this._idx(x, y)] = borderTile.data[this._idx(x + ox, y + oy)];
			}
		}
	}

	onDeserialize() {
		if (this._tree) {
			this._tree.dem = this;
		}
	}
}

export class Tile {
	aborted?: boolean;
	data: set<AreaPk>;
	dem?: DEMData;
	expirationTime: any;
	expiredRequestCount: number;
	placementSource: any;
	queryPadding: number;
	reloadCallback: any;
	resourceTiming?: Array<PerformanceResourceTiming>;
	state: TileState;
	tileID: OverscaledTileID;
	tileSize: number;
	tileZoom: number;
	timeAdded: any;
	uid: number;
	uses: number;

	constructor(tileID: OverscaledTileID, size: number, tileZoom: number) {
		this.data = new set();
		this.expirationTime = null;
		this.queryPadding = 0;
		this.tileID = tileID;
		this.tileSize = size;
		this.tileZoom = tileZoom;
		this.uid = uniqueId();
		this.uses = 0;
		// Counts the number of times a response was already expired when
		// received. We're using this to add a delay when making a new request
		// so we don't have to keep retrying immediately in case of a server
		// serving expired tiles.
		this.expiredRequestCount = 0;
		this.state = 'loading';
	}

	wasRequested() {
		return (this.state === 'errored') || (this.state === 'loaded') || (this.state === 'reloading');
	}

	hasData() {
		return (this.state === 'loaded') || (this.state === 'reloading') || (this.state === 'expired');
	}

	diffData(pks: Iterable<AreaPk>): set<AreaPk> {
		if (pks instanceof set) {
			return this.data.difference(pks);
		}
		if (typeof pks === 'string') {
			return this.data.difference(new set([pks]));
		}
		return this.data.difference(new set(pks));
	}

	loadData(pks: Iterable<AreaPk>): void {
		if (this.hasData()) {
			this.unloadData();
		}
		this.state = 'loaded';
		if (pks instanceof set) {
			this.data = pks;
		} else if (typeof pks === 'string') {
			this.data.add(pks);
		} else {
			this.data.update(pks);
		}
	}

	unloadData(): void {
		this.data.clear();
		this.state = 'unloaded';
	}
}

function getMercCoords(x: number, y: number, z: number) {
	var resolution = (2 * Math.PI * 6378137 / 256) / Math.pow(2, z);
	return [
		(x * resolution - 2 * Math.PI * 6378137 / 2.0),
		(y * resolution - 2 * Math.PI * 6378137 / 2.0),
	];
}

function getTileBBox(x: number, y: number, z: number) {
	y = (Math.pow(2, z) - y - 1);
	var min = getMercCoords(x * 256, y * 256, z);
	var max = getMercCoords((x + 1) * 256, (y + 1) * 256, z);
	return min[0] + ',' + min[1] + ',' + max[0] + ',' + max[1];
}

class DemMinMaxQuadTree {
	maximums: Array<number>;
	minimums: Array<number>;
	leaves: Array<number>;
	childOffsets: Array<number>;
	nodeCount: number;
	dem: DEMData;
	_siblingOffset: Array<Array<number>>;

	constructor(dem_: DEMData) {
		this.maximums = [];
		this.minimums = [];
		this.leaves = [];
		this.childOffsets = [];
		this.nodeCount = 0;
		this.dem = dem_;
		// Precompute the order of 4 sibling nodes in the memory. Top-left, top-right, bottom-left, bottom-right
		this._siblingOffset = [
			[0, 0],
			[1, 0],
			[0, 1],
			[1, 1],
		];
		if (!this.dem) {
			return;
		}
		const mips = buildDemMipmap(this.dem);
		const maxLvl = mips.length - 1;
		// Create the root node
		const rootMip = mips[maxLvl];
		const min = rootMip.minimums;
		const max = rootMip.maximums;
		const leaves = rootMip.leaves;
		this._addNode(min[0], max[0], leaves[0]);
		// Construct the rest of the tree recursively
		this._construct(mips, 0, 0, maxLvl, 0);
	}

	_addNode(min: number, max: number, leaf: number) {
		this.minimums.push(min);
		this.maximums.push(max);
		this.leaves.push(leaf);
		this.childOffsets.push(0);
		return this.nodeCount++;
	}

	_construct(mips: Array<MipLevel>, x: number, y: number, lvl: number, parentIdx: number) {
		if (mips[lvl].isLeaf(x, y) === 1) {
			return;
		}
		// Update parent offset
		if (!this.childOffsets[parentIdx]) {
			this.childOffsets[parentIdx] = this.nodeCount;
		}
		// Construct all 4 children and place them next to each other in memory
		const childLvl = lvl - 1;
		const childMip = mips[childLvl];
		let leafMask = 0;
		let firstNodeIdx;
		for (let i = 0; i < this._siblingOffset.length; i++) {
			const childX = x * 2 + this._siblingOffset[i][0];
			const childY = y * 2 + this._siblingOffset[i][1];
			const elevation = childMip.getElevation(childX, childY);
			const leaf = childMip.isLeaf(childX, childY);
			const nodeIdx = this._addNode(elevation.min, elevation.max, leaf);
			if (leaf) {
				leafMask |= 1 << i;
			}
			if (!firstNodeIdx) {
				firstNodeIdx = nodeIdx;
			}
		}
		// Continue construction of the tree recursively to non-leaf nodes.
		for (let i = 0; i < this._siblingOffset.length; i++) {
			if (!(leafMask & (1 << i))) {
				this._construct(
					mips,
					x * 2 + this._siblingOffset[i][0],
					y * 2 + this._siblingOffset[i][1],
					childLvl,
					<number>firstNodeIdx + i);
			}
		}
	}
}

//
class RGBAImage {
	width: number;
	height: number;
	// data must be a Uint8Array instead of Uint8ClampedArray because texImage2D does not
	// support Uint8ClampedArray in all browsers
	data: Uint8Array;

	constructor(size: Size, data?: Uint8Array | Uint8ClampedArray) {
		this.height = size.height;
		this.width = size.width;
		if (!data) {
			data = new Uint8Array(this.width * this.height * 4);
		} else if (data instanceof Uint8ClampedArray) {
			data = new Uint8Array(data.buffer);
		} else if (data.length !== this.width * this.height * 4) {
			throw new RangeError('mismatched image size');
		}
		this.data = data;
		createImage(this, size, 4, data);
	}
}

interface RootTile {
	aabb: Aabb;
	zoom: number;
	x: number;
	y: number;
	wrap: number;
	fullyVisible: boolean;
	tileID?: OverscaledTileID;
	shouldSplit?: boolean;
}

let id = 1;

function uniqueId(): number {
	return id++;
}

type TileState =
	| 'loading'   // Tile data is in the process of loading.
	| 'loaded'    // Tile data has been loaded. Tile can be rendered.
	| 'reloading' // Tile data has been loaded and is being updated. Tile can be rendered.
	| 'unloaded'  // Tile data has been deleted.
	| 'errored'   // Tile data was not loaded because of an error.
	| 'expired';

function buildDemMipmap(dem: DEMData): Array<MipLevel> {
	const demSize = dem.dim;
	const elevationDiffThreshold = 5;
	const texelSizeOfMip0 = 8;
	const levelCount = Math.ceil(Math.log2(demSize / texelSizeOfMip0));
	const mips: Array<MipLevel> = [];
	let blockCount = Math.ceil(Math.pow(2, levelCount));
	const blockSize = 1 / blockCount;
	const blockSamples = (x: number, y: number, size: number, exclusive: boolean, outBounds: Tup4) => {
		const padding = exclusive ? 1 : 0;
		const minx = x * size;
		const maxx = (x + 1) * size - padding;
		const miny = y * size;
		const maxy = (y + 1) * size - padding;
		outBounds[0] = minx;
		outBounds[1] = miny;
		outBounds[2] = maxx;
		outBounds[3] = maxy;
	};
	// The first mip (0) is built by sampling 4 corner points of each 8x8 texel block
	let mip = new MipLevel(blockCount);
	const blockBounds: Tup4 = [0, 0, 0, 0];
	for (let idx = 0; idx < blockCount * blockCount; idx++) {
		const y = Math.floor(idx / blockCount);
		const x = idx % blockCount;
		blockSamples(x, y, blockSize, false, blockBounds);
		const e0 = sampleElevation(blockBounds[0], blockBounds[1], dem);    // minx, miny
		const e1 = sampleElevation(blockBounds[2], blockBounds[1], dem);    // maxx, miny
		const e2 = sampleElevation(blockBounds[2], blockBounds[3], dem);    // maxx, maxy
		const e3 = sampleElevation(blockBounds[0], blockBounds[3], dem);    // minx, maxy
		mip.minimums.push(Math.min(e0, e1, e2, e3));
		mip.maximums.push(Math.max(e0, e1, e2, e3));
		mip.leaves.push(1);
	}
	mips.push(mip);
	// Construct the rest of the mip levels from bottom to up
	for (blockCount /= 2; blockCount >= 1; blockCount /= 2) {
		const prevMip = mips[mips.length - 1];
		mip = new MipLevel(blockCount);
		for (let idx = 0; idx < blockCount * blockCount; idx++) {
			const y = Math.floor(idx / blockCount);
			const x = idx % blockCount;
			// Sample elevation of all 4 children mip texels. 4 leaf nodes can be concatenated into a single
			// leaf if the total elevation difference is below the threshold value
			blockSamples(x, y, 2, true, blockBounds);
			const e0 = prevMip.getElevation(blockBounds[0], blockBounds[1]);
			const e1 = prevMip.getElevation(blockBounds[2], blockBounds[1]);
			const e2 = prevMip.getElevation(blockBounds[2], blockBounds[3]);
			const e3 = prevMip.getElevation(blockBounds[0], blockBounds[3]);
			const l0 = prevMip.isLeaf(blockBounds[0], blockBounds[1]);
			const l1 = prevMip.isLeaf(blockBounds[2], blockBounds[1]);
			const l2 = prevMip.isLeaf(blockBounds[2], blockBounds[3]);
			const l3 = prevMip.isLeaf(blockBounds[0], blockBounds[3]);
			const minElevation = Math.min(e0.min, e1.min, e2.min, e3.min);
			const maxElevation = Math.max(e0.max, e1.max, e2.max, e3.max);
			const canConcatenate = l0 && l1 && l2 && l3;
			mip.maximums.push(maxElevation);
			mip.minimums.push(minElevation);
			if (maxElevation - minElevation <= elevationDiffThreshold && canConcatenate) {
				// All samples have uniform elevation. Mark this as a leaf
				mip.leaves.push(1);
			} else {
				mip.leaves.push(0);
			}
		}
		mips.push(mip);
	}
	return mips;
}

class MipLevel {
	size: number;
	minimums: Array<number>;
	maximums: Array<number>;
	leaves: Array<number>;

	constructor(size_: number) {
		this.size = size_;
		this.minimums = [];
		this.maximums = [];
		this.leaves = [];
	}

	getElevation(x: number, y: number): {min: number, max: number} {
		const idx = this.toIdx(x, y);
		return {
			min: this.minimums[idx],
			max: this.maximums[idx],
		};
	}

	isLeaf(x: number, y: number): number {
		return this.leaves[this.toIdx(x, y)];
	}

	toIdx(x: number, y: number): number {
		return y * this.size + x;
	}
}

function createImage(image: RGBAImage, {width, height}: Size, channels: number, data?: Uint8Array | Uint8ClampedArray) {
	if (!data) {
		data = new Uint8Array(width * height * channels);
	} else if (data instanceof Uint8ClampedArray) {
		data = new Uint8Array(data.buffer);
	} else if (data.length !== width * height * channels) {
		throw new RangeError('mismatched image size');
	}
	image.width = width;
	image.height = height;
	image.data = data;
	return image;
}

type Size = {
	width: number,
	height: number
};

function sampleElevation(fx: number, fy: number, dem: DEMData): number {
	// Sample position in texels
	const demSize = dem.dim;
	const x = clamp(fx * demSize - 0.5, 0, demSize - 1);
	const y = clamp(fy * demSize - 0.5, 0, demSize - 1);
	// Compute 4 corner points for bilinear interpolation
	const ixMin = Math.floor(x);
	const iyMin = Math.floor(y);
	const ixMax = Math.min(ixMin + 1, demSize - 1);
	const iyMax = Math.min(iyMin + 1, demSize - 1);
	const e00 = dem.get(ixMin, iyMin);
	const e10 = dem.get(ixMax, iyMin);
	const e01 = dem.get(ixMin, iyMax);
	const e11 = dem.get(ixMax, iyMax);
	return bilinearLerp(e00, e10, e01, e11, x - ixMin, y - iyMin);
}

function bilinearLerp(p00: any, p10: any, p01: any, p11: any, x: number, y: number): any {
	return numnum(
		numnum(p00, p01, y),
		numnum(p10, p11, y),
		x);
}
