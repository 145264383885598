import {IFilterListOpt, IProjectListOpt, svc} from './request';
import {GeoCoordinate, list, set} from './tools';
import {OBJ, Obj, ObjOpts, ObjPrivate, SIGNAL, SLOT} from './obj';
import {assert, deepCopy, isErrorResponse, isNumber, LbFeature, title} from './util';
import {ProjectFilterParam, SortOrder} from './constants';
import geometryCenter from '@turf/center';
import {getLogger} from './logging';

const logger = getLogger('model');
const staticDoNotMail: IDoNotMail = Object.freeze({
	geoRefId: 0,
	ownerAddress: {
		uid: '',
		street: '',
		city: '',
		state: '',
	},
	ownerName: '',
	parcelAddress: {
		uid: '',
		street: '',
		city: '',
		state: '',
	},
});

@OBJ
export class DoNotMail extends Obj {
	static async list(opt?: Partial<IGeoRefListOpt>): Promise<list<DoNotMail>> {
		const resp = await svc.doNotMail.list(opt);
		return new list(resp.data.map(x => new this(x)));
	}

	private data: IDoNotMail;
	private dirty: boolean;

	constructor(dataOrPk?: IDoNotMail | GeoRefPk) {
		super();
		this.data = staticDoNotMail;
		this.dirty = false;
		if (dataOrPk) {
			if (isNumber(dataOrPk)) {
				this.data = {...staticDoNotMail, geoRefId: dataOrPk};
				this.dirty = true;
			} else {
				this.data = {...dataOrPk};
			}
		}
	}

	destroy(): void {
		this.data = staticDoNotMail;
		super.destroy();
	}

	async ownerAddress(): Promise<IAddr> {
		return this.data.ownerAddress;
	}

	async ownerName(): Promise<string> {
		return this.data.ownerName;
	}

	async parcelAddress(): Promise<IAddr> {
		return this.data.parcelAddress;
	}

	async pk(): Promise<GeoRefPk> {
		return this.data.geoRefId;
	}
}

const staticGeoRef: IGeoRef = {
	doNotMail: false,
	id: 0,
	multipolygon: null,
	point: null,
	showOnMap: false,
	userId: 0,
};

interface IGeoRefSetDoNotMailOpt {
	geometry: IGeoRefMultiPolygon | IGeoRefPoint | null;
	parcelPk: ParcelPk;
}

export class GeoRef extends Obj {
	static async create(data: Partial<INewGeoRef>): Promise<GeoRef | null> {
		// FIXME: This sucks. Do something about it.
		const resp = await svc.geoRef.create(data);
		if (resp.data) {
			return new this(resp.data);
		}
		return null;
	}

	static async get(pk: GeoRefPk): Promise<GeoRef> {
		const resp = await svc.geoRef.get(pk);
		return new this(resp.data);
	}

	static async list(opt?: Partial<IGeoRefListOpt>): Promise<list<GeoRef>> {
		const resp = await svc.geoRef.list(opt);
		return new list(resp.data.map(x => new this(x)));
	}

	static async setDoNotMail(doNotMail: boolean, data: Partial<IGeoRefSetDoNotMailOpt>): Promise<GeoRef | null> {
		const payload: Partial<INewGeoRef> = {
			doNotMail,
			point: null,
			multipolygon: null,
			parcelPk: data.parcelPk,
		};
		if (data.geometry) {
			switch (data.geometry.type) {
				case 'MultiPolygon': {
					payload.multipolygon = data.geometry;
					break;
				}
				case 'Point': {
					payload.point = data.geometry;
					break;
				}
			}
		}
		return await this.create(payload);
	}

	private data: IGeoRef;
	private del: boolean;
	private dirty: boolean;
	private etag: string;
	private etagChangedLastReq: boolean;
	private fetching: boolean;
	private toRestore: IGeoRef | null;

	constructor(dataOrPk?: IGeoRef | GeoRefPk) {
		super();
		this.data = staticGeoRef;
		this.del = false;
		this.dirty = false;
		this.etag = '';
		this.etagChangedLastReq = false;
		this.fetching = false;
		this.toRestore = null;
		if (dataOrPk) {
			if (isNumber(dataOrPk)) {
				this.dirty = true;
				this.data = {...staticGeoRef, id: dataOrPk};
			} else {
				this.data = {...dataOrPk};
			}
		}
	}

	@SIGNAL
	private created(geoReg: GeoRef): void {
	}

	async delete(): Promise<void> {
		if (this.del) {
			return;
		}
		if (this.data !== staticGeoRef) {
			this.toRestore = {...this.data};
		}
		this.del = true;
		const pk = this.data.id;
		this.data = Object.freeze({...staticGeoRef, id: pk});
		await svc.geoRef.delete(pk);
		this.deleted(pk);
	}

	@SIGNAL
	private deleted(pk: GeoRefPk): void {
	}

	doNotMail(): boolean {
		return this.data.doNotMail;
	}

	@SIGNAL
	private doNotMailChanged(pk: GeoRefPk, doNotMail: boolean): void {
	}

	private async fetch(pk?: GeoRefPk): Promise<void> {
		this.fetching = true;
		const resp = await svc.geoRef.get((pk === undefined) ? this.data.id : pk);
		this.receiveResponse(resp);
		this.fetching = false;
	}

	private async fetchIfDirty(pk?: GeoRefPk): Promise<void> {
		if (this.dirty) {
			await this.fetch(pk);
		}
	}

	multipolygon(): IGeoRefMultiPolygon | null {
		return this.data.multipolygon;
	}

	@SIGNAL
	private multipolygonChanged(pk: GeoRefPk, multipolygon: IGeoRefMultiPolygon | null): void {
	}

	pk(): GeoRefPk {
		return this.data.id;
	}

	point(): IGeoRefPoint | null {
		return this.data.point;
	}

	@SIGNAL
	private pointChanged(pk: GeoRefPk, point: IGeoRefPoint | null): void {
	}

	private receiveResponse(resp: IResponse<IGeoRef | null> | null): void {
		if (resp) {
			const etag = resp.headers['etag'] || '';
			this.etagChangedLastReq = this.etag !== etag;
			this.etag = etag;
			if (resp.data) {
				this.data = resp.data;
			}
		}
		this.dirty = false;
	}

	async restore(): Promise<void> {
		if (!this.del) {
			return;
		}
		const data = this.toRestore || staticGeoRef;
		const resp = await svc.geoRef.create({
			doNotMail: data.doNotMail,
			multipolygon: data.multipolygon,
			point: data.point,
			showOnMap: data.showOnMap,
		});
		this.toRestore = null;
		this.del = false;
		this.receiveResponse(resp);
		this.created(this);
	}

	async setDoNotMail(doNotMail: boolean, data: Partial<IGeoRefSetDoNotMailOpt>): Promise<void> {
		await this.fetchIfDirty();
		if (doNotMail === this.data.doNotMail) {
			return;
		}
		await this.update({doNotMail});
		this.doNotMailChanged(this.data.id, this.data.doNotMail);
	}

	async setMultipolygon(point: IGeoRefPoint | null): Promise<void> {
		await this.fetchIfDirty();
		if (point === this.data.point) {
			return;
		}
		await this.update({point});
		this.pointChanged(this.data.id, this.data.point);
	}

	async setPoint(multipolygon: IGeoRefMultiPolygon | null): Promise<void> {
		await this.fetchIfDirty();
		if (multipolygon === this.data.multipolygon) {
			return;
		}
		await this.update({multipolygon});
		this.multipolygonChanged(this.data.id, this.data.multipolygon);
	}

	async setShowOnMap(showOnMap: boolean): Promise<void> {
		await this.fetchIfDirty();
		if (showOnMap === this.data.showOnMap) {
			return;
		}
		await this.update({showOnMap});
		this.showOnMapChanged(this.data.id, this.data.showOnMap);
	}

	showOnMap(): boolean {
		return this.data.showOnMap;
	}

	@SIGNAL
	private showOnMapChanged(pk: GeoRefPk, showOnMap: boolean): void {
	}

	private async update(data: Partial<IGeoRef>): Promise<void> {
		const resp = await svc.geoRef.update(this.data.id, data);
		this.receiveResponse(resp);
	}
}

@OBJ
export class Owner extends Obj {
	private data: IOwner;

	constructor(data: IOwner) {
		super();
		this.data = data;
	}

	async name(): Promise<string> {
		return this.data.name;
	}

	async pk(): Promise<OwnerPk> {
		return this.data.uid;
	}
}

@OBJ
export class Parcel extends Obj {
	static async list(coord: GeoCoordinate): Promise<list<Parcel>> {
		return new list(
			(
				await svc.parcel.list(coord)
			).map(
				x => new this(x),
			),
		);
	}

	private ignored: boolean;
	private p: IParcel;

	constructor(data: IParcel, ignored?: boolean) {
		super();
		this.ignored = (ignored === undefined) ?
			false :
			ignored;
		this.p = data;
	}

	get areaId(): AreaPk {
		return this.p.areaId;
	}

	@SIGNAL
	private ignoredChanged(): void {
	}

	async isIgnored(): Promise<boolean> {
		return this.ignored;
	}

	async landValue(): Promise<string> {
		return this.p.landValue;
	}

	async otherValue(): Promise<string> {
		return this.p.otherValue;
	}

	async owners(): Promise<list<Owner>> {
		return new list(this.p.owners.map(x => new Owner(x)));
	}

	async pk(): Promise<ParcelPk> {
		return this.p.uid;
	}

	async setIgnored(ignored: boolean): Promise<void> {
		if (ignored === this.ignored) {
			return;
		}
		this.ignored = ignored;
		this.ignoredChanged();
	}

	async street(): Promise<string> {
		return this.p.street;
	}

	async structuresValue(): Promise<string> {
		return this.p.structuresValue;
	}

	async totalValue(): Promise<string> {
		return this.p.totalValue;
	}

	async zoningId(): Promise<ZoningPk | null> {
		return this.p.zoningId;
	}

	async zoningName(): Promise<string> {
		return this.p.zoningName;
	}
}

@OBJ
export class PaymentIntent extends Obj {
	static async create(slug: string): Promise<PaymentIntent> {
		const resp = await svc.payment.create({slug});
		return new this(resp.data);
	}

	private data: IPaymentIntent;

	constructor(data: IPaymentIntent) {
		super();
		this.data = data;
	}

	async clientSecret(): Promise<string> {
		return this.data.clientSecret;
	}

	async status(): Promise<string> {
		return this.data.status;
	}

	async succeeded(): Promise<boolean> {
		return this.data.succeeded;
	}
}

@OBJ
export class Place extends Obj {
	static async list(name: string): Promise<list<Place>> {
		return new list((await svc.place.list(name)).map(x => new this(x)));
	}

	private p: IPlace;

	constructor(data: IPlace) {
		super();
		this.p = data;
	}

	async label(): Promise<string> {
		const parts: Array<string> = [
			this.p.typeDisplay,
		];
		if (this.p.city.length > 0) {
			parts.push(`in ${title(this.p.city.toLocaleLowerCase())}`);
			const state = this.p.state.toLocaleUpperCase();
			if (state === 'NORTH CAROLINA') {
				parts.push(`, NC`);
			}
		}
		return parts.join(' ');
	}

	async name(): Promise<string> {
		return this.p.name;
	}

	async pk(): Promise<PlacePk> {
		return this.p.uid;
	}
}

export class ProjPrivate extends ObjPrivate {
	static allProjMdls: set<Proj> = new set();

	data: IProject;
	deleted: boolean;

	constructor() {
		super();
		this.data = staticProject;
		this.deleted = false;
	}

	init(opts: Partial<ProjOpts>): void {
		if (opts.data) {
			this.data = opts.data;
		}
		ProjPrivate.allProjMdls.add(this.q);
	}

	get q(): Proj {
		return <Proj>super.q;
	}
}

export interface ProjOpts extends ObjOpts {
	data: IProject;
	dd: ProjPrivate;
}

@OBJ
export class Proj extends Obj {
	static async archived(opts?: Partial<Omit<IProjectListOpt, 'filter'>>): Promise<list<Proj>> {
		let op: Partial<IProjectListOpt> = {};
		op.filter = ProjectFilterParam.Archived;
		if (opts) {
			op = {...op, ...opts};
		}
		return await this.list(op);
	}

	static async clone(slug: string): Promise<Proj> {
		return new this({data: await svc.project.clone(slug)});
	}

	static async get(slug: string): Promise<Proj | null> {
		const resp = await svc.project.get(slug);
		return resp ? new this({data: resp.data}) : null;
	}

	static async list(opts?: Partial<IProjectListOpt>): Promise<list<Proj>> {
		const resp = await svc.project.list(opts);
		return new list(resp.data.map(x => (new this({data: x}))));
	}

	static async merge(slugs: Array<string>): Promise<Proj> {
		return new this({data: await svc.project.merge(slugs)});
	}

	constructor(opts: Partial<ProjOpts> = {}) {
		opts.dd = opts.dd || new ProjPrivate();
		super(opts);
		opts.dd.init(opts);
	}

	absoluteListUrl(): string {
		const d = this.d;
		return d.data.absoluteListUrl || '';
	}

	@SIGNAL
	private absoluteListUrlChanged(newUrl: string | null): void {
	}

	absoluteUrl(): string {
		const d = this.d;
		return d.data.absoluteUrl;
	}

	@SIGNAL
	private archivedChanged(archived: boolean): void {
	}

	async clone(): Promise<Proj> {
		return await Proj.clone(this.d.data.slug);
	}

	get d(): ProjPrivate {
		return <ProjPrivate>super.d;
	}

	destroy(): void {
		ProjPrivate.allProjMdls.discard(this);
		super.destroy();
		this.d.data = staticProject;
	}

	geoMapConfigurationId(): GeoMapConfigurationPk | null {
		return this.d.data.geoMapConfigurationId;
	}

	@SIGNAL
	private geoMapConfigurationIdChanged(newId: GeoMapConfigurationPk | null): void {
	}

	id(): ProjectPk {
		return this.d.data.id;
	}

	invoiceId(): InvoicePk | null {
		return this.d.data.invoiceId;
	}

	isArchived(): boolean {
		return this.d.data.archived;
	}

	isPaymentAllowed(): boolean {
		return this.d.data.paymentAllowed;
	}

	isPaymentRequired(): boolean {
		return this.d.data.paymentRequired;
	}

	isReadOnly(): boolean {
		return this.d.data.readOnly;
	}

	@SIGNAL
	private paymentProcessed(): void {
	}

	async processPayment(): Promise<void> {
		const d = this.d;
		d.data = (await svc.project.processPayment(d.data.slug)).data;
		this.paymentProcessed();
	}

	protected async requestUpdate(data: Partial<IProject>): Promise<IResponse<IProject>> {
		const d = this.d;
		return await svc.project.update(
			d.data.slug,
			{
				...d.data,
				...data,
			},
		);
	}

	async setArchived(archived: boolean): Promise<void> {
		if (archived !== this.d.data.archived) {
			await this.update({archived});
		}
	}

	slug(): string {
		return this.d.data.slug;
	}

	async setTitle(title: string): Promise<void> {
		if (title !== this.d.data.title) {
			await this.update({title});
		}
	}

	title(): string {
		return this.d.data.title;
	}

	@SIGNAL
	private titleChanged(newTitle: string): void {
	}

	toObject(): IProject | null {
		const d = this.d;
		return (d.data === staticProject) ?
			null :
			deepCopy(d.data);
	}

	async update(data: Partial<IProject>): Promise<void> {
		const d = this.d;
		const keys = <Array<keyof IProject>>Object.keys(data);
		const resp = await this.requestUpdate(<Partial<IProject>>{
			...data,
		});
		d.data = resp.data;
		for (const key of keys) {
			switch (key) {
				case 'archived': {
					this.archivedChanged(d.data.archived);
					break;
				}
				case 'geoMapConfigurationId': {
					this.geoMapConfigurationIdChanged(d.data.geoMapConfigurationId);
					break;
				}
				case 'title': {
					this.titleChanged(d.data.title);
					break;
				}
			}
		}
	}
}

const staticProject: IProject = Object.freeze({
	absoluteListUrl: null,
	absoluteUrl: '',
	archived: false,
	geoMapConfigurationId: null,
	id: 0,
	invoiceId: null,
	paymentAllowed: false,
	paymentRequired: false,
	readOnly: false,
	slug: '',
	title: '',
	userId: 0,
});
type NormGeometry = Pick<IFilter, 'geometry' | 'geometryIsCircle' | 'point'>;
type UpdateGeometry = Pick<IUpdateFilter, 'geometry' | 'geometryIsCircle' | 'point'>;

export class FilterMdlPrivate extends ObjPrivate {
	static allFilterMdls: set<FilterMdl> = new set();

	static newFilterObject(): INewFilter {
		return {
			dataFilter: undefined,
			enabled: true,
			expression: null,
			geometry: null,
			geometryIsCircle: false,
			icon: '',
			label: '',
			parentId: null,
			placePk: undefined,
			point: null,
			projectSlug: undefined,
		};
	}

	data: IFilter;
	dataFilter: boolean;
	deleted: boolean;

	constructor() {
		super();
		this.data = staticFilter;
		this.dataFilter = false;
		this.deleted = false;
	}

	async init(opts: Partial<FilterMdlOpts>): Promise<void> {
		if (opts.data) {
			this.data = opts.data;
		}
		if (opts.isDataFilter) {
			this.dataFilter = true;
		}
		const q = this.q;
		FilterMdlPrivate.allFilterMdls.add(q);
		const parId = q.parentId();
		if (isNumber(parId) && (parId > 0)) {
			for (const obj of FilterMdlPrivate.allFilterMdls) {
				if (obj.id() === parId) {
					q.setParent(obj);
					break;
				}
			}
		}
		for (const cd of (await svc.filter.children(q.id(), q.isDataFilter())).data) {
			new FilterMdl({
				data: cd,
				parent: q,
			});
		}
	}

	get q(): FilterMdl {
		return <FilterMdl>super.q;
	}
}

export interface FilterMdlOpts extends ObjOpts {
	data: IFilter;
	dd: FilterMdlPrivate;
	isDataFilter: boolean;
}

@OBJ
export class FilterMdl extends Obj {
	static async create(data: Partial<INewFilter> = {}): Promise<FilterMdl> {
		const resp = await svc.filter.create({
			...FilterMdlPrivate.newFilterObject(),
			...data,
		});
		return new this({
			data: resp.data,
		});
	}

	private static existingFilterObj(data: IFilter): FilterMdl | null {
		if (!isNumber(data.id) || (data.id <= 0)) {
			return null;
		}
		for (const obj of FilterMdlPrivate.allFilterMdls) {
			if (obj.id() === data.id) {
				return obj;
			}
		}
		return null;
	}

	static async list(opt?: Partial<IFilterListOpt>): Promise<list<FilterMdl>> {
		const rv = new list<FilterMdl>();
		for (const filData of (await svc.filter.list(opt))) {
			let filObj = this.existingFilterObj(filData);
			if (!filObj) {
				filObj = new this({data: filData});
			}
			rv.append(filObj);
		}
		return rv;
	}

	constructor(opts: Partial<FilterMdlOpts> = {}) {
		opts.dd = opts.dd || new FilterMdlPrivate();
		super(opts);
		opts.dd.init(opts);
	}

	*childFilters(): IterableIterator<FilterMdl> {
		for (const child of this.children()) {
			if (child instanceof FilterMdl) {
				yield child;
			}
		}
	}

	get d(): FilterMdlPrivate {
		return <FilterMdlPrivate>super.d;
	}

	@SIGNAL
	protected dataFilterChanged(): void {
	}

	async delete(): Promise<void> {
		const d = this.d;
		if (d.deleted) {
			return;
		}
		d.deleted = true;
		try {
			await svc.filter.delete(d.data.id);
		} catch (exc) {
			d.deleted = false;
			throw exc;
		}
	}

	destroy(): void {
		FilterMdlPrivate.allFilterMdls.discard(this);
		// this.d.data = staticFilter;
		super.destroy();
	}

	display(): string {
		return this.d.data.display;
	}

	@SIGNAL
	private displayChanged(newDisplay: string): void {
	}

	@SIGNAL
	private enabledChanged(enabled: boolean): void {
	}

	expression(): IExpression | null {
		return this.d.data.expression;
	}

	@SIGNAL
	private expressionChanged(newExpression: IExpression | null): void {
	}

	geometry(): GeoJsonMultiPolygon | GeoJsonPoint | null {
		const d = this.d;
		return d.data.geometry || d.data.point;
	}

	@SIGNAL
	private geometryChanged(newGeometry: GeoJsonMultiPolygon | GeoJsonPoint | null): void {
	}

	icon(): string {
		return this.d.data.icon;
	}

	@SIGNAL
	private iconChanged(newIcon: string): void {
	}

	id(): FilterPk {
		return this.d.data.id;
	}

	isDataFilter(): boolean {
		return this.d.dataFilter;
	}

	isDeleted(): boolean {
		return this.d.deleted;
	}

	isEnabled(): boolean {
		return this.d.data.enabled;
	}

	label(): string {
		return this.d.data.label;
	}

	@SIGNAL
	private labelChanged(newLabel: string): void {
	}

	parentId(): FilterPk | null {
		return this.d.data.parentId;
	}

	parentFilter(): FilterMdl | null {
		const par = this.parent();
		return (par instanceof FilterMdl) ?
			par :
			null;
	}

	protected async requestUpdate(data: Partial<IFilter>): Promise<IResponse<IFilter>> {
		const d = this.d;
		return await svc.filter.update(
			d.data.id,
			{
				...d.data,
				...data,
			},
			this.isDataFilter(),
		);
	}

	setDataFilter(isDataFilter: boolean): void {
		const d = this.d;
		if (isDataFilter === d.dataFilter) {
			return;
		}
		d.dataFilter = isDataFilter;
		this.dataFilterChanged();
	}

	@SLOT
	async setEnabled(enabled: boolean): Promise<void> {
		if (enabled !== this.d.data.enabled) {
			await this.update({enabled});
		}
	}

	@SLOT
	async setExpression(expression: IExpressionBase | null): Promise<void> {
		const d = this.d;
		let expr: IExpression | null = null;
		if (expression) {
			if (d.data.expression) {
				if ((expression.lhs === d.data.expression.lhs) && (expression.operator === d.data.expression.operator) && (expression.rhs === d.data.expression.rhs) && (expression.negated === d.data.expression.negated)) {
					// Both same. Nothing more to do.
					return;
				}
			}
			if (d.data.expression) {
				expr = {
					...d.data.expression,
					...expression,
				};
			} else {
				expr = {
					...expression,
					id: 0,
					isValid: false,
				};
			}
		} else {
			if (!d.data.expression) {
				// Both same (null). Nothing more to do.
				return;
			}
		}
		await this.update({expression: expr});
	}

	@SLOT
	async setGeometry(data: Partial<UpdateGeometry>): Promise<void> {
		const d = this.d;
		const n: NormGeometry = {
			geometry: d.data.geometry,
			geometryIsCircle: d.data.geometryIsCircle,
			point: d.data.point,
		};
		const f = LbFeature.from({
			geometry: data.geometry || data.point,
			properties: {
				center: data.geometryIsCircle,
			},
		});
		if (data.geometry !== undefined) {
			n.geometry = f.forceMultiPolygonGeometry;
		}
		if (data.geometryIsCircle !== undefined) {
			n.geometryIsCircle = f.geometryIsCircle;
		}
		if (data.point !== undefined) {
			n.point = f.pointGeometry;
		}
		if ((d.data.geometry === n.geometry) && (d.data.geometryIsCircle === n.geometryIsCircle) && (d.data.point === n.point)) {
			// Nothing left to do.
			return;
		}
		await this.update(n);
	}

	@SLOT
	async setLabel(label: string): Promise<void> {
		label = label.trim();
		if (label !== this.d.data.label) {
			await this.update({label});
		}
	}

	toGeoJsonFeature<G extends GeoJsonGeometry | null, P extends GeoJsonFilterFeatureProperties>(): GeoJsonFilterFeature<G, P> {
		let center: Array<number> | undefined = undefined;
		let geom: GeoJsonGeometry | null = null;
		let isCircle: boolean = false;
		const d = this.d;
		if (d.data.geometry) {
			if (d.data.geometryIsCircle) {
				isCircle = true;
				geom = {
					coordinates: (d.data.geometry.coordinates.length > 0) ?
						deepCopy(d.data.geometry.coordinates[0]) :
						[],
					type: 'Polygon',
				};
				center = (geom.coordinates.length > 0) ?
					geometryCenter(geom).geometry.coordinates :
					[];
			} else {
				geom = deepCopy(d.data.geometry);
			}
		} else if (d.data.point) {
			geom = deepCopy(d.data.point);
		}
		return {
			geometry: <G>geom,
			id: d.data.id,
			properties: <P>{
				center,
				circle: isCircle,
				label: d.data.label,
			},
			type: 'Feature',
		};
	}

	toObject(): IFilter | null {
		const d = this.d;
		return (d.data === staticFilter) ?
			null :
			deepCopy(d.data);
	}

	async update(data: Partial<IUpdateFilter>): Promise<void> {
		const d = this.d;
		const prevDisplay = d.data.display;
		const n: NormGeometry = {
			geometry: d.data.geometry,
			geometryIsCircle: d.data.geometryIsCircle,
			point: d.data.point,
		};
		const f = LbFeature.from({
			geometry: data.geometry || data.point,
			properties: {
				circle: data.geometryIsCircle,
			},
		});
		const cir = f.circleProperty;
		if (cir !== undefined) {
			n.geometryIsCircle = cir;
		}
		if (data.geometry !== undefined) {
			n.geometry = f.forceMultiPolygonGeometry;
		}
		if (data.point !== undefined) {
			n.point = f.pointGeometry;
		}
		const keys = <Array<keyof IFilter>>Object.keys(data);
		const resp = await this.requestUpdate(<Partial<IFilter>>{
			...data,
			...n,
		});
		d.data = resp.data;
		// FIXME: Temp. thing to avoid emitting the same signal in the event
		//        we just updated something concerning geometry.
		let emittedGeom = false;
		for (const key of keys) {
			switch (key) {
				case 'label': {
					this.labelChanged(d.data.label);
					break;
				}
				case 'expression': {
					this.expressionChanged(d.data.expression);
					break;
				}
				case 'enabled': {
					this.enabledChanged(d.data.enabled);
					break;
				}
				case 'geometry':
				case 'point':
				case 'geometryIsCircle': {
					if (!emittedGeom) {
						emittedGeom = true;
						this.geometryChanged(this.geometry());
					}
					break;
				}
				case 'icon': {
					this.iconChanged(d.data.icon);
					break;
				}
				default: {
					logger.warning('update: key "%s" not recognized.', key);
					break;
				}
			}
		}
		if (d.data.display !== prevDisplay) {
			this.displayChanged(d.data.display);
		}
	}
}

const staticFilter: IFilter = Object.freeze({
	children: [],
	display: '',
	enabled: false,
	expression: null,
	geometry: null,
	geometryIsCircle: false,
	point: null,
	icon: '',
	id: 0,
	label: '',
	parentId: null,
});

export class ExprTok implements IExprTok {
	private tok: IExpressionToken;

	constructor(tok?: IExpressionToken) {
		this.tok = tok || staticTok;
	}

	attribute(value: string): IExpressionAttribute | null {
		for (const obj of this.tok.attributes) {
			if (obj.value === value) {
				return obj;
			}
		}
		return null;
	}

	attributes(): Array<IExpressionAttribute> {
		return this.tok.attributes;
	}

	choices(attrValue: string): Array<ILabeledValue> {
		for (const obj of this.tok.attributes) {
			if (obj.value === attrValue) {
				return obj.choices;
			}
		}
		return [];
	}

	operators(attrValue: string): Array<ILabeledValue> {
		for (const obj of this.tok.attributes) {
			if (obj.value === attrValue) {
				return obj.operators;
			}
		}
		return [];
	}

	setTok(tok: IExpressionToken | null): void {
		if (tok === this.tok) {
			return;
		}
		this.tok = tok || staticTok;
	}

	sortedAttributes(order: SortOrder = SortOrder.AscendingOrder): Array<IExpressionAttribute> {
		const key = exprAttrSortKey(order);
		const rv = [...this.tok.attributes];
		rv.sort(key);
		return rv;
	}
}

function exprAttrSortKey(order: SortOrder): ((a: IExpressionAttribute, b: IExpressionAttribute) => number) {
	const bit = (order === SortOrder.DescendingOrder) ?
		-1 :
		1;
	return (a: IExpressionAttribute, b: IExpressionAttribute) => {
		let rv: number = 0;
		if (a.label > b.label) {
			rv = 1;
		}
		if (a.label < b.label) {
			rv = -1;
		}
		return rv * bit;
	};
}

const staticTok: IExpressionToken = {
	attributes: [],
	operators: [],
};

export class TagPrivate extends ObjPrivate {
	static allTags: set<Tag> = new set();

	static newTagObject(): ITag {
		return {
			...staticTag,
		};
	}

	data: ITag;
	deleted: boolean;

	constructor() {
		super();
		this.data = staticTag;
		this.deleted = false;
	}

	init(opts: Partial<TagOpts>): void {
		if (opts.data) {
			this.data = opts.data;
		}
		const q = this.q;
		TagPrivate.allTags.add(q);
	}

	get q(): Tag {
		return <Tag>super.q;
	}
}

export interface TagOpts extends ObjOpts {
	data: ITag;
	dd: TagPrivate;
}

@OBJ
export class Tag extends Obj {
	static async create(data: Partial<ITag> = {}): Promise<Tag> {
		let rv: Tag | null = null;
		let err: Error | null = null;
		try {
			const resp = await svc.d.newTag({
				...TagPrivate.newTagObject(),
				...data,
			});
			if (isErrorResponse(resp)) {
				err = new Error(
					resp.data.error.message,
				);
			} else {
				rv = new this({
					data: resp.data,
				});
			}
		} catch (exc) {
			err = exc;
		}
		if (err) {
			throw err;
		}
		// Sanity check
		assert(rv);
		return rv;
	}

	static async list(): Promise<list<Tag>> {
		const rv = new list<Tag>();
		const resp = await svc.d.tags();
		for (const d of resp.data) {
			let found = false;
			for (const obj of TagPrivate.allTags) {
				if ((d.k === obj.k()) && (d.v === obj.v())) {
					rv.append(obj);
					found = true;
					break;
				}
			}
			if (!found) {
				rv.append(
					new this({data: d}),
				);
			}
		}
		return rv;
	}

	constructor(opts: Partial<TagOpts> = {}) {
		opts.dd = opts.dd || new TagPrivate();
		super(opts);
		opts.dd.init(opts);
	}

	get d(): TagPrivate {
		return <TagPrivate>super.d;
	}

	async delete(): Promise<void> {
		const d = this.d;
		if (d.deleted) {
			return;
		}
		let err: Error | null = null;
		try {
			const r = await svc.d.deleteTag(
				d.data.k,
				d.data.v,
			);
			if (isErrorResponse(r)) {
				err = new Error(
					r.data.error.message,
				);
			}
		} catch (exc) {
			err = exc;
		}
		if (err) {
			d.deleted = false;
			throw err;
		} else {
			d.deleted = true;
		}
	}

	destroy(): void {
		TagPrivate.allTags.discard(this);
		super.destroy();
		this.d.data = staticTag;
	}

	k(): string {
		return this.d.data.k;
	}

	@SIGNAL
	private kChanged(k: string): void {
	}

	@SIGNAL
	private enabledChanged(enabled: boolean): void {
	}

	eq(other: Tag): boolean {
		return (this.k() === other.k()) && (this.v() === other.v());
	}

	isDeleted(): boolean {
		return this.d.deleted;
	}

	isEnabled(): boolean {
		return this.d.data.enabled;
	}

	protected async requestUpdate(data: Partial<ITag>): Promise<IResponse<ITag>> {
		const d = this.d;
		return await svc.d.updateTag(
			{
				...d.data,
				...data,
			},
		);
	}

	@SLOT
	async setEnabled(enabled: boolean): Promise<void> {
		if (enabled !== this.d.data.enabled) {
			await this.update({
				enabled,
			});
		}
	}

	toObject(): ITag | null {
		const d = this.d;
		return (d.data === staticTag) ?
			null :
			deepCopy(d.data);
	}

	toString(): string {
		return `${this.d.data.k}=${this.d.data.v}`;
	}

	async update(data: Partial<ITag>): Promise<void> {
		const d = this.d;
		const keys = <Array<keyof ITag>>Object.keys(data);
		const resp = await this.requestUpdate(data);
		d.data = resp.data;
		for (const key of keys) {
			switch (key) {
				case 'k': {
					this.kChanged(d.data.k);
					break;
				}
				case 'v': {
					this.vChanged(d.data.v);
					break;
				}
				case 'enabled': {
					this.enabledChanged(d.data.enabled);
					break;
				}
				default: {
					logger.warning('update: key "%s" not recognized.', key);
					break;
				}
			}
		}
	}

	v(): string {
		return this.d.data.v;
	}

	@SIGNAL
	private vChanged(v: string): void {
	}
}

const staticTag: ITag = Object.freeze({
	k: '',
	v: '',
	enabled: true,
});

export class ParcelTagPrivate extends ObjPrivate {
	static allParcelTags: set<ParcelTag> = new set();

	static newParcelTagObject(): IParcelTag {
		return {
			...staticParcelTag,
		};
	}

	data: IParcelTag;
	deleted: boolean;

	constructor() {
		super();
		this.data = staticParcelTag;
		this.deleted = false;
	}

	init(opts: Partial<ParcelTagOpts>): void {
		if (opts.data) {
			this.data = opts.data;
		}
		const q = this.q;
		ParcelTagPrivate.allParcelTags.add(q);
	}

	get q(): ParcelTag {
		return <ParcelTag>super.q;
	}
}

export interface ParcelTagOpts extends ObjOpts {
	data: IParcelTag;
	dd: ParcelTagPrivate;
}

@OBJ
export class ParcelTag extends Obj {
	static async create(data: IParcelTag): Promise<ParcelTag> {
		const resp = await svc.d.newParcelTag({
			...data,
		});
		return new this({
			data: resp.data,
		});
	}

	static async list(parcelId: ParcelPk): Promise<list<ParcelTag>> {
		const rv = new list<ParcelTag>();
		const resp = await svc.d.parcelTags(parcelId);
		for (const d of resp.data) {
			let found = false;
			for (const obj of ParcelTagPrivate.allParcelTags) {
				if ((d.parcelId === obj.parcelId()) && ((d.tagK === obj.tagK()) && (d.tagV === obj.tagV()))) {
					rv.append(obj);
					found = true;
					break;
				}
			}
			if (!found) {
				rv.append(
					new this({data: d}),
				);
			}
		}
		return rv;
	}

	constructor(opts: Partial<ParcelTagOpts> = {}) {
		opts.dd = opts.dd || new ParcelTagPrivate();
		super(opts);
		opts.dd.init(opts);
	}

	get d(): ParcelTagPrivate {
		return <ParcelTagPrivate>super.d;
	}

	async delete(): Promise<void> {
		const d = this.d;
		if (d.deleted) {
			return;
		}
		d.deleted = true;
		try {
			await svc.d.deleteParcelTag(
				d.data.parcelId,
				d.data.tagK,
				d.data.tagV,
			);
		} catch (exc) {
			d.deleted = false;
			throw exc;
		}
	}

	destroy(): void {
		ParcelTagPrivate.allParcelTags.discard(this);
		super.destroy();
		this.d.data = staticParcelTag;
	}

	@SIGNAL
	private enabledChanged(enabled: boolean): void {
	}

	eq(other: ParcelTag): boolean {
		return (this.parcelId() === other.parcelId()) && (this.tagK() === other.tagK()) && (this.tagV() === other.tagV());
	}

	isDeleted(): boolean {
		return this.d.deleted;
	}

	isEnabled(): boolean {
		return this.d.data.enabled;
	}

	parcelId(): string {
		return this.d.data.parcelId;
	}

	protected async requestUpdate(data: Partial<IParcelTag>): Promise<IResponse<IParcelTag>> {
		const d = this.d;
		return await svc.d.updateParcelTag(
			{
				...d.data,
				...data,
			},
		);
	}

	@SLOT
	async setEnabled(enabled: boolean): Promise<void> {
		if (enabled !== this.d.data.enabled) {
			await this.update({
				enabled,
			});
		}
	}

	tag(): Tag | null {
		const d = this.d;
		if (d.deleted) {
			return null;
		}
		for (const tag of TagPrivate.allTags) {
			if (!tag.isDeleted() && ((tag.k() === d.data.tagK) && (tag.v() === d.data.tagV))) {
				return tag;
			}
		}
		return null;
	}

	tagK(): string {
		return this.d.data.tagK;
	}

	tagV(): string {
		return this.d.data.tagV;
	}

	toObject(): IParcelTag | null {
		const d = this.d;
		return (d.data === staticParcelTag) ?
			null :
			deepCopy(d.data);
	}

	async update(data: Partial<IParcelTag>): Promise<void> {
		const d = this.d;
		const keys = <Array<keyof IParcelTag>>Object.keys(data);
		const resp = await this.requestUpdate(data);
		d.data = resp.data;
		for (const key of keys) {
			switch (key) {
				case 'enabled': {
					this.enabledChanged(d.data.enabled);
					break;
				}
				default: {
					logger.warning('update: key "%s" not recognized.', key);
					break;
				}
			}
		}
	}
}

const staticParcelTag: IParcelTag = Object.freeze({
	parcelId: '',
	tagK: '',
	tagV: '',
	enabled: true,
});
