import mapboxgl from 'mapbox-gl';

import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {GeoCoordinate, GeoCoordinateLike, list, Point, PointLike, set} from '../../tools';
import {GeoRectangle} from '../../tools/georectangle';
import {getLogger} from '../../logging';
import {Evt} from '../../evt';
import {DrawControl, InfoControl, InteractiveMapControl, LayerControl} from './control';
import {assert, bind, emptyGeoJsonFeatureCollection, featureLikeToFeatureCollection, featureOrFeatureIdLikeToFeatureIds, isNumber, pixelString} from '../../util';
import {DEFAULT_MAP_BEARING, DEFAULT_MAP_LATITUDE, DEFAULT_MAP_LONGITUDE, DEFAULT_MAP_PITCH, DEFAULT_MAP_STYLE_IDENT, DEFAULT_MAP_STYLE_THEME, DEFAULT_MAP_ZOOM_LEVEL, INTERACTIVE_MAP_ELEMENT_ID, INTERACTIVE_MAP_PUBLIC_TOKEN, InteractiveMapControlPosition, InteractiveMapFlag, MapControlType, MapDrawMode, MapStyle, MapStyleTheme} from '../../constants';
import {Icon} from '../icon';
import {layerThemes} from './layer';
import {App} from '../../app';

const logger = getLogger('ui.map');

export interface CameraOpts extends ICamera {
	padding: number | {top: number; right: number; bottom: number; left: number;};
}

export interface FitToBoundsOpts extends FlyToOpts {
	maxZoom: number;
}

export interface FlyToOpts extends CameraOpts {
	curve: number;
	maxDuration: number;
	minZoom: number;
	screenSpeed: number;
	speed: number;
}

interface LayerItem {
	layer: Layer;
	layerId: string;
}

interface LayerUi {
	icon: Icon;
	label: string;
}

export enum MapEventType {
	Data = 'data',
	DrawFeatureCombine = 'draw.combine',
	DrawFeatureCreate = 'draw.create',
	DrawFeatureDelete = 'draw.delete',
	DrawFeatureSelectionChange = 'draw.selectionchange',
	DrawFeatureUncombine = 'draw.uncombine',
	DrawFeatureUpdate = 'draw.update',
	DrawModeChange = 'draw.modechange',
	DrawTransientFeatureCreate = 'draw.transient',
	Load = 'load',
	MouseClick = 'click',
	MouseDoubleClick = 'dblclick',
	MouseDown = 'mousedown',
	MouseEnter = 'mouseenter',
	MouseLeave = 'mouseleave',
	MouseMove = 'mousemove',
	MouseOut = 'mouseout',
	MouseOver = 'mouseover',
	MouseUp = 'mouseup',
	MoveEnd = 'moveend',
	PitchEnd = 'pitchend',
	PopupClose = 'close',
	PopupOpen = 'open',
	RotateEnd = 'rotateend',
	SourceData = 'sourcedata',
	StyleData = 'styledata',
	StyleDataLoading = 'styledataloading',
	TouchCancel = 'touchcancel',
	TouchEnd = 'touchend',
	TouchMove = 'touchmove',
	TouchStart = 'touchstart',
	Zoom = 'zoom',
	ZoomEnd = 'zoomend',
}

interface PopupOpts {
	closeButton: boolean;
	closeOnClick: boolean;
	closeOnMove: boolean;
	cssClassName: string;
	el: ElObj | HTMLElement;
	focusAfterOpen: boolean;
	html: string;
	maxWidth: number | string;
	text: string;
}

export type Layer = mapboxgl.AnyLayer & {ui?: LayerUi;}
export type Source = mapboxgl.AnySourceData;
export type MapEvent<T> = mapboxgl.MapboxEvent<T>;
export type MapLayerEvent<T> = mapboxgl.MapboxEvent<T> & {features?: Array<mapboxgl.MapboxGeoJSONFeature>};
export type DrawModeChangeEvent = MapEvent<undefined> & {mode: MapDrawMode;};
export type MapPopupEvent = {target: mapboxgl.Popup; type: string;}
export type FeatureCreatedEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties> = MapEvent<undefined> & {features: Array<GeoJsonFeature<G, P>>;};
export type FeatureDeletedEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties> = MapEvent<undefined> & {features: Array<GeoJsonFeature<G, P>>;};
export type FeatureSelectionChangeEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties> = MapEvent<undefined> & {features: Array<GeoJsonFeature<G, P>>; points: Array<GeoJsonFeature<GeoJsonPoint, {}>>;};
export type FeatureModifiedEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties> = MapEvent<undefined> & {action: string; features: Array<GeoJsonFeature<G, P>>;};
export type TransientFeatureCreatedEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties> = MapEvent<undefined> & {features: Array<GeoJsonFeature<G, P>>;};

export const staticInitMapCfg: IInteractiveMapConfiguration = Object.freeze({
	bearing: DEFAULT_MAP_BEARING,
	latitude: DEFAULT_MAP_LATITUDE,
	longitude: DEFAULT_MAP_LONGITUDE,
	pitch: DEFAULT_MAP_PITCH,
	styleIdentifier: DEFAULT_MAP_STYLE_IDENT,
	zoom: DEFAULT_MAP_ZOOM_LEVEL,
});

export class InteractiveMapPrivate extends ElObjPrivate {
	activeCtrls: number;
	ctrls: Map<MapControlType, InteractiveMapControl>;
	flags: InteractiveMapFlag;
	initCfg: IInteractiveMapConfiguration;
	layers: list<LayerItem>;
	loaded: boolean;
	loading: boolean;
	map: mapboxgl.Map | null;
	popups: set<mapboxgl.Popup>;
	sources: Map<string, Source>;
	theme: MapStyleTheme;

	constructor() {
		super();
		this.activeCtrls = 0;
		this.ctrls = new Map();
		this.flags = InteractiveMapFlag.NoMapFlags;
		this.initCfg = {
			...staticInitMapCfg,
		};
		this.layers = new list();
		this.loaded = false;
		this.loading = false;
		this.map = null;
		this.popups = new set();
		this.sources = new Map();
		this.theme = DEFAULT_MAP_STYLE_THEME;
	}

	addLayer(layerId: string, layer: Layer): void {
		if (this.layer(layerId) === layer) {
			return;
		}
		const idx = this.layers.findIndex(
			x => (x.layerId === layerId),
		);
		if (idx >= 0) {
			logger.warning('addLayer: Layer with id "%s" already added.', layerId);
			return;
		}
		this.layers.append({
			layer,
			layerId,
		});
		if (this.map) {
			this.map.addLayer(layer);
		}
		if (!layer.ui) {
			return;
		}
		const ctrl = <LayerControl | null>this.control(MapControlType.Layer);
		if (ctrl) {
			ctrl.addLayerToggle(
				layerId,
				layer.ui.icon,
				layer.ui.label,
			);
		}
	}

	addSource(sourceId: string, source: Source): void {
		if (this.sources.get(sourceId) === source) {
			return;
		}
		this.sources.set(sourceId, source);
		if (this.map) {
			this.map.addSource(sourceId, source);
		}
	}

	centerCoords(): GeoCoordinate {
		if (this.map) {
			return lngLatToGeoCoordinate(
				this.map.getCenter(),
			);
		}
		return new GeoCoordinate();
	}

	control(type: MapControlType): InteractiveMapControl | null {
		return this.ctrls.get(type) || null;
	}

	featureState(featureId: GeoJsonFeatureId, sourceId: string, sourceLayerId?: string): {[key: string]: any} {
		if (this.map) {
			return this.map.getFeatureState({
				id: featureId,
				source: sourceId,
				sourceLayer: sourceLayerId,
			});
		}
		return {};
	}

	fitViewToBounds(bounds: GeoRectangle, padding?: number | {top: number; right: number; bottom: number; left: number;}) {
		if (this.map) {
			this.map.fitBounds(
				geoRectangleToLngLatBounds(
					bounds,
				),
				{padding},
			);
		}
	}

	protected hasLayer(layerId: string): boolean {
		return Boolean(this.map && this.map.getLayer(layerId));
	}

	protected hasSource(sourceId: string): boolean {
		return Boolean(this.map && this.map.getSource(sourceId));
	}

	init(opts: Partial<InteractiveMapOpts>): void {
		super.init(opts);
		this.loaded = false;
		this.loading = false;
		if (opts.flags !== undefined) {
			this.flags = opts.flags;
		}
		this.setInitCfg(opts);
	}

	layer(layerId: string): Layer | null {
		for (const item of this.layers) {
			if (item.layerId === layerId) {
				return item.layer;
			}
		}
		return null;
	}

	loadTheme(objs: Iterable<LayerPaint>): void {
		const q = this.q;
		for (const obj of objs) {
			q.setLayerPaintProperty(
				obj.layerId,
				obj.paintPropertyName,
				obj.value,
			);
		}
	}

	newPopup(opts: Partial<PopupOpts>): mapboxgl.Popup {
		const obj = new mapboxgl.Popup(
			popupOptsToPopupOptions(opts),
		);
		if (opts.html) {
			obj.setHTML(opts.html);
		}
		if (opts.text) {
			obj.setText(opts.text);
		}
		if (opts.el) {
			let el: HTMLElement;
			if (opts.el instanceof ElObj) {
				el = <HTMLElement>opts.el.element();
				opts.el.show();
			} else {
				el = opts.el;
			}
			obj.setDOMContent(el);
		}
		return obj;
	}

	project(coord: GeoCoordinateLike): Point | null {
		if (this.map) {
			const p = this.map.project(
				geoCoordinateLikeToLngLat(coord),
			);
			// NB: "When the map is pitched and lnglat is completely behind
			//     the camera, there are no pixel coordinates corresponding to
			//     that location. In that case, the x and y components of the
			//     returned Point are set to Number.MAX_VALUE."
			//     https://docs.mapbox.com/mapbox-gl-js/api/map/#map#project
			if ((p.x < Number.MAX_VALUE) && (p.y < Number.MAX_VALUE)) {
				return new Point(p.x, p.y);
			}
		}
		return null;
	}

	get q(): InteractiveMap {
		return <InteractiveMap>super.q;
	}

	removeLayer(layerId: string): void {
		const idx = this.layers.findIndex(
			x => (x.layerId === layerId),
		);
		if (idx >= 0) {
			const item = this.layers.takeAt(idx);
			if (this.map) {
				this.map.removeLayer(item.layerId);
			}
		}
	}

	removeSource(sourceId: string): void {
		if (this.sources.delete(sourceId) && this.map) {
			this.map.removeSource(sourceId);
		}
	}

	setCtrl(type: MapControlType, ctrl: InteractiveMapControl): void {
		const existing = this.ctrls.get(type);
		if (ctrl === existing) {
			// Nothing to do.
			return;
		}
		const q = this.q;
		if (existing) {
			Obj.disconnect(
				existing, 'activationChanged',
				q, 'mapControlActivationChanged',
			);
			if (this.map) {
				this.map.removeControl(existing);
			}
		}
		this.ctrls.set(type, ctrl);
		Obj.connect(
			ctrl, 'activationChanged',
			q, 'mapControlActivationChanged',
		);
		ctrl.show();
		if (this.map) {
			this.map.addControl(ctrl);
		}
	}

	setFeatureState(featureId: GeoJsonFeatureId, state: {[key: string]: any}, sourceId: string, sourceLayerId?: string): void {
		if (!this.map) {
			return;
		}
		this.map.setFeatureState(
			{
				id: featureId,
				source: sourceId,
				sourceLayer: sourceLayerId,
			},
			state,
		);
	}

	setInitCfg(cfg: Partial<IInteractiveMapConfiguration> | null): void {
		if (!cfg) {
			this.initCfg = {
				...staticInitMapCfg,
			};
			return;
		}
		if (cfg.bearing !== undefined) {
			this.initCfg.bearing = cfg.bearing;
		}
		if (cfg.latitude !== undefined) {
			this.initCfg.latitude = cfg.latitude;
		}
		if (cfg.longitude !== undefined) {
			this.initCfg.longitude = cfg.longitude;
		}
		if (cfg.pitch !== undefined) {
			this.initCfg.pitch = cfg.pitch;
		}
		if (cfg.styleIdentifier !== undefined) {
			this.initCfg.styleIdentifier = cfg.styleIdentifier;
		}
		if (cfg.zoom !== undefined) {
			this.initCfg.zoom = cfg.zoom;
		}
	}

	setLayerPaintProperty(layerId: string, propertyName: string, value: any): void {
		if (!this.map) {
			return;
		}
		if (!this.hasLayer(layerId)) {
			logger.warning('setLayerPaintProperty: Layer "%s" is not loaded.', layerId);
			return;
		}
		this.map.setPaintProperty(
			layerId,
			propertyName,
			value,
		);
	}

	setSourceData<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(sourceId: string, data: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeature<G, P> | GeoJsonFeatureCollection<G, P>): void {
		const coll = featureLikeToFeatureCollection(data);
		const src = <mapboxgl.GeoJSONSourceRaw | undefined>this.sources.get(sourceId);
		if (src) {
			src.data = coll;
		}
		if (this.map) {
			const src = <mapboxgl.GeoJSONSource | undefined>this.map.getSource(sourceId);
			if (src) {
				src.setData(coll);
			}
		}
	}

	source(sourceId: string): Source | null {
		return this.sources.get(sourceId) || null;
	}
}

export interface InteractiveMapOpts extends ElObjOpts, IInteractiveMapConfiguration {
	dd: InteractiveMapPrivate;
	flags: InteractiveMapFlag;
}

@OBJ
export class InteractiveMap extends ElObj {
	protected static defaultEventTypes: Iterable<MapEventType> = [
		MapEventType.Load,
		MapEventType.MouseClick,
		MapEventType.MouseDoubleClick,
		MapEventType.MouseDown,
		MapEventType.MouseUp,
		MapEventType.TouchCancel,
		MapEventType.TouchEnd,
		MapEventType.TouchStart,
		MapEventType.MoveEnd,
		MapEventType.PitchEnd,
		MapEventType.RotateEnd,
		MapEventType.ZoomEnd,
	];

	constructor(opts: Partial<InteractiveMapOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['id', INTERACTIVE_MAP_ELEMENT_ID],
		);
		opts.dd = opts.dd || new InteractiveMapPrivate();
		super(opts);
	}

	activeMapControlType(): MapControlType {
		const d = this.d;
		if (d.activeCtrls > 0) {
			if (d.activeCtrls & MapControlType.Draw) {
				return MapControlType.Draw;
			}
			if (d.activeCtrls & MapControlType.Info) {
				return MapControlType.Info;
			}
		}
		return MapControlType.NoType;
	}

	addFeatures<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(features: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | GeoJsonFeature<G, P> | G): void {
		/**
		 * Add a GeoJSON feature to the existing Draw GeoJSON feature set.
		 */
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		if (ctrl) {
			ctrl.addFeatures(features);
		}
	}

	addLayer(layerId: string, layer: Layer): void {
		/**
		 * Add layer to map.
		 */
		this.d.addLayer(layerId, layer);
	}

	addSource(sourceId: string, source: Source): void {
		/**
		 * Add source to map.
		 */
		this.d.addSource(sourceId, source);
	}

	allFeatures<G extends GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		return ctrl ?
			ctrl.allFeatures<G, P>() :
			emptyGeoJsonFeatureCollection<G, P>();
	}

	bearing(): number {
		const d = this.d;
		return d.map ?
			d.map.getBearing() :
			DEFAULT_MAP_BEARING;
	}

	camera(): ICamera {
		const d = this.d;
		if (d.map) {
			const lngLat = d.map.getCenter();
			return {
				bearing: d.map.getBearing(),
				latitude: lngLat.lat,
				longitude: lngLat.lng,
				pitch: d.map.getPitch(),
				zoom: d.map.getZoom(),
			};
		}
		return {
			bearing: DEFAULT_MAP_BEARING,
			latitude: DEFAULT_MAP_LATITUDE,
			longitude: DEFAULT_MAP_LONGITUDE,
			pitch: DEFAULT_MAP_PITCH,
			zoom: DEFAULT_MAP_ZOOM_LEVEL,
		};
	}

	@SIGNAL
	protected cameraChanged(): void {
		/**
		 * Emitted for map camera change.
		 */
	}

	center(): GeoCoordinate {
		/**
		 * Return center coordinates of current map view.
		 */
		return this.d.centerCoords();
	}

	protected changeEvent(event: Evt): void {
		super.changeEvent(event);
		if (event.type() === Evt.MouseTrackingChange) {
			this.setMapEventListener(
				MapEventType.MouseMove,
				this.hasMouseTracking(),
			);
		}
	}

	@SIGNAL
	protected clicked(point: Point, coord: GeoCoordinate): void {
	}

	closePopups(): void {
		for (const obj of this.d.popups) {
			obj.remove();
		}
	}

	get d(): InteractiveMapPrivate {
		return <InteractiveMapPrivate>super.d;
	}

	deleteAllFeatures(): void {
		/**
		 * Remove all Draw GeoJSON features
		 */
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		if (ctrl) {
			ctrl.deleteAllFeatures();
		}
	}

	deleteFeatures<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(featureIds: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): void {
		/**
		 * Remove given Draw GeoJSON feature or, if a GeoJSON feature ID is
		 * given, remove the Draw GeoJSON feature with ID matching the
		 * argument.
		 */
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		if (ctrl) {
			ctrl.deleteFeatures(
				featureOrFeatureIdLikeToFeatureIds(featureIds),
			);
		}
	}

	drawMode(): MapDrawMode {
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		return ctrl ?
			ctrl.mode() :
			MapDrawMode.NoMode;
	}

	@SIGNAL
	protected drawModeChanged(newMode: MapDrawMode): void {
	}

	protected drawModeChangeEvent(event: DrawModeChangeEvent): void {
	}

	element(): HTMLElement {
		return <HTMLElement>super.element();
	}

	feature<G extends GeoJsonGeometry, P = GeoJsonProperties>(featureId: GeoJsonFeatureId): GeoJsonFeature<G, P> | null {
		/**
		 * Return the Draw GeoJSON feature with ID matching argument or null
		 * if no match was found.
		 */
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		return ctrl ?
			ctrl.feature<G, P>(featureId) :
			null;
	}

	protected featureCreatedEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(event: FeatureCreatedEvent<G, P>): void {
		this.featuresCreated<G, P>(event.features);
	}

	protected featureDeletedEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(event: FeatureDeletedEvent<G, P>): void {
		this.featuresDeleted<G, P>(event.features);
	}

	protected featureModifiedEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(event: FeatureModifiedEvent<G, P>): void {
		this.featuresModified<G, P>(event.features);
	}

	features(sourceId: string, sourceLayerId?: string, filter?: Array<any>): Array<mapboxgl.MapboxGeoJSONFeature> {
		/**
		 * Return features within given source. This description sucks.
		 */
		const d = this.d;
		if (d.map) {
			return d.map.querySourceFeatures(
				sourceId,
				{
					filter,
					sourceLayer: sourceLayerId,
				},
			);
		}
		return [];
	}

	@SIGNAL
	protected featuresCreated<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): void {
		/**
		 * Draw GeoJSON feature(s) was just created
		 */
	}

	@SIGNAL
	protected featuresDeleted<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): void {
		/**
		 * Draw GeoJSON feature(s) was just deleted
		 */
	}

	@SIGNAL
	protected featureSelectionChanged<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): void {
		/**
		 * Draw GeoJSON feature(s) was just modified
		 */
	}

	protected featureSelectionChangeEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(event: FeatureSelectionChangeEvent<G, P>): void {
		this.featureSelectionChanged<G, P>(event.features);

	}

	@SIGNAL
	protected featuresModified<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): void {
		/**
		 * Draw GeoJSON feature(s) was just modified
		 */
	}

	featureState(featureId: GeoJsonFeatureId, sourceId: string, sourceLayerId?: string): {[key: string]: any} {
		return this.d.featureState(
			featureId,
			sourceId,
			sourceLayerId,
		);
	}

	fitViewToBounds(bounds: GeoRectangle, opts?: Partial<FitToBoundsOpts>): void;
	fitViewToBounds(topLeft: GeoCoordinate, bottomRight: GeoCoordinate, opts?: Partial<FitToBoundsOpts>): void
	fitViewToBounds(center: GeoCoordinate, degreesWidth: number, degreesHeight: number, opts?: Partial<FitToBoundsOpts>): void;
	fitViewToBounds(a: GeoRectangle | GeoCoordinate, b?: GeoCoordinate | Partial<FitToBoundsOpts> | number, c?: Partial<FitToBoundsOpts> | number, d?: Partial<FitToBoundsOpts>): void {
		/**
		 * Set current map view to given bounds
		 */
		let opts: Partial<FitToBoundsOpts> | undefined;
		let rect: GeoRectangle;
		if (a instanceof GeoRectangle) {
			// SIG: fitViewToBounds(bounds: GeoRectangle, opts?: Partial<FitToBoundsOpts>)
			rect = a;
			opts = <Partial<FitToBoundsOpts> | undefined>b;
		} else if ((a instanceof GeoCoordinate) && (b instanceof GeoCoordinate)) {
			// SIG: fitViewToBounds(topLeft: GeoCoordinate, bottomRight: GeoCoordinate, opts?: Partial<FitToBoundsOpts>)
			rect = new GeoRectangle(a, b);
			opts = <Partial<FitToBoundsOpts> | undefined>c;
		} else {
			// SIG: fitViewToBounds(center: GeoCoordinate, degreesWidth: number, degreesHeight: number, opts?: Partial<FitToBoundsOpts>)
			assert(a instanceof GeoCoordinate);
			assert(isNumber(b));
			assert(isNumber(c));
			rect = new GeoRectangle(a, b, c);
			opts = d;
		}
		this.d.fitViewToBounds(
			rect,
			opts && opts.padding,
		);
	}

	hasActiveMapControl(): boolean {
		return Boolean(this.d.activeCtrls);
	}

	hasFocus(): boolean {
		return super.hasFocus() || this.d.elem.contains(document.activeElement);
	}

	hasPopups(): boolean {
		return this.d.popups.size > 0;
	}

	hasSelectedFeatures(): boolean {
		return this.selectedFeatureCount() > 0;
	}

	@SIGNAL
	protected infoRequestedAt(coords: GeoCoordinate): void {
		/**
		 * Emitted when map is clicked while info map control is active.
		 */
	}

	isLayerVisible(layerId: string): boolean {
		const d = this.d;
		if (!d.map) {
			return false;
		}
		const val = d.map.getLayoutProperty(
			layerId,
			'visibility',
		);
		return (val === undefined) || (val === 'visible');
	}

	isLoaded(): boolean {
		/**
		 * Return true if map resources have been loaded; false otherwise.
		 */
		return this.d.loaded;
	}

	isMapControlActive(type: MapControlType): boolean {
		if (type === MapControlType.NoType) {
			return false;
		}
		return Boolean(this.d.activeCtrls & type);
	}

	layer(layerId: string): Layer | null {
		/**
		 * Returns the layer matching given ID.
		 *
		 * The layers considered are only those added via this component and
		 * are not requested from the vendor map component.
		 */
		return this.d.layer(layerId);
	}

	*layers(): IterableIterator<[string, Layer]> {
		/**
		 * Returns iterator of [<layer ID>, <Layer>]
		 */
		for (const obj of this.d.layers) {
			yield [obj.layerId, obj.layer];
		}
	}

	@SIGNAL
	protected layerVisibilityChanged(layerId: string, visible: boolean): void {
		/**
		 * Emitted after the call to set a layer's visibility.
		 * This might be the better idea mentioned in layerToggled() docs.
		 */
	}

	@SIGNAL
	protected layerToggled(layerId: string, enabled: boolean): void {
		/**
		 * Emits when user toggles a layer button. The name/behavior may be a
		 * little misleading and could perhaps use some more thought:
		 *
		 * Currently, this signal is emitted when the click occurs which is to
		 * say not AFTER the layer has actually been toggled. So, either this
		 * signal should be renamed or the behavior should be adjust such that
		 * this signal is emitted only after the layer visually toggles.
		 */
	}

	load(cfg?: Partial<IInteractiveMapConfiguration>): void {
		const d = this.d;
		if (d.loaded || d.loading) {
			return;
		}
		d.loading = true;
		setTimeout(
			() => this._load(cfg),
			3,
		);
	}

	protected _load(cfg?: Partial<IInteractiveMapConfiguration>): void {
		const d = this.d;
		d.loading = true;
		if (cfg) {
			d.setInitCfg(cfg);
		}
		d.map = new mapboxgl.Map({
			accessToken: INTERACTIVE_MAP_PUBLIC_TOKEN,
			attributionControl: false,
			bearing: d.initCfg.bearing,
			center: {
				lng: d.initCfg.longitude,
				lat: d.initCfg.latitude,
			},
			container: this.element(),
			doubleClickZoom: false,
			pitch: d.initCfg.pitch,
			style: d.initCfg.styleIdentifier,
			zoom: d.initCfg.zoom,
		});
		d.map.addControl(
			new mapboxgl.AttributionControl({
				compact: true,
			}),
			InteractiveMapControlPosition.BottomLeft,
		);
		const evtTypes: set<MapEventType> = new set(
			(<typeof InteractiveMap>this.constructor).defaultEventTypes,
		);
		for (const evtType of evtTypes) {
			this.setMapEventListener(
				evtType,
				true,
			);
		}
	}

	@SIGNAL
	protected loaded(): void {
		/**
		 * Map resources have been loaded
		 */
	}

	mapControl(type: MapControlType): InteractiveMapControl | null {
		return this.d.ctrls.get(type) || null;
	}

	@SLOT
	protected mapControlActivationChanged(type: MapControlType, active: boolean): void {
	}

	mapControlType(control: InteractiveMapControl): MapControlType {
		const d = this.d;
		for (const [type, ctrl] of d.ctrls) {
			if (ctrl === control) {
				return type;
			}
		}
		return MapControlType.NoType;
	}

	protected mapDataEvent(event: mapboxgl.MapDataEvent): void {
	}

	@bind
	protected mapEvent(event: MapEvent<unknown>): void {
		const d = this.d;
		switch (event.type) {
			case MapEventType.MouseMove: {
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapMouseMoveEvent(evt);
				break;
			}
			case MapEventType.TouchMove: {
				const evt = <mapboxgl.MapTouchEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapTouchMoveEvent(evt);
				break;
			}
			case MapEventType.MouseOver: {
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapMouseOverEvent(evt);
				break;
			}
			case MapEventType.MouseOut: {
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapMouseOutEvent(evt);
				break;
			}
			case MapEventType.Zoom: {
				this.mapZoomEvent(<mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>>event);
				break;
			}
			case MapEventType.MouseEnter: {
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapMouseEnterEvent(evt);
				break;
			}
			case MapEventType.MouseLeave: {
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapMouseLeaveEvent(evt);
				break;
			}
			case MapEventType.Data: {
				this.mapDataEvent(<mapboxgl.MapDataEvent>event);
				break;
			}
			case MapEventType.SourceData: {
				this.mapSourceDataEvent(<mapboxgl.MapSourceDataEvent>event);
				break;
			}
			case MapEventType.MoveEnd: {
				this.stationary();
				this.cameraChanged();
				this.mapMoveEndEvent(<mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>>event);
				break;
			}
			case MapEventType.ZoomEnd: {
				this.mapZoomEndEvent(<mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>>event);
				break;
			}
			case MapEventType.MouseDown: {
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapMouseDownEvent(evt);
				break;
			}
			case MapEventType.MouseUp: {
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapMouseUpEvent(evt);
				break;
			}
			case MapEventType.MouseClick: {
				// When info mode is active, the usual "map clicked" event is
				// not emitted. Instead, the "infoRequestedAt" event is
				// emitted; it's semantics.
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				if (this.isMapControlActive(MapControlType.Info)) {
					this.infoRequestedAt(
						lngLatToGeoCoordinate(evt.lngLat),
					);
				} else {
					this.mapMouseClickEvent(evt);
					this.clicked(
						new Point(evt.point.x, evt.point.y),
						lngLatToGeoCoordinate(evt.lngLat),
					);
				}
				break;
			}
			case MapEventType.MouseDoubleClick: {
				const evt = <mapboxgl.MapMouseEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapMouseDoubleClickEvent(evt);
				break;
			}
			case MapEventType.TouchStart: {
				const evt = <mapboxgl.MapTouchEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapTouchStartEvent(evt);
				break;
			}
			case MapEventType.TouchEnd: {
				const evt = <mapboxgl.MapTouchEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapTouchEndEvent(evt);
				break;
			}
			case MapEventType.TouchCancel: {
				const evt = <mapboxgl.MapTouchEvent>event;
				App.setLastCursorPos(
					new Point(
						evt.point.x,
						evt.point.y,
					),
				);
				this.mapTouchCancelEvent(evt);
				break;
			}
			case MapEventType.DrawModeChange: {
				const evt = <DrawModeChangeEvent>event;
				this.drawModeChangeEvent(evt);
				this.drawModeChanged(evt.mode);
				break;
			}
			case MapEventType.DrawFeatureCreate: {
				this.featureCreatedEvent(<FeatureCreatedEvent>event);
				break;
			}
			case MapEventType.DrawFeatureDelete: {
				this.featureDeletedEvent(<FeatureDeletedEvent>event);
				break;
			}
			case MapEventType.DrawFeatureUpdate: {
				this.featureModifiedEvent(<FeatureModifiedEvent>event);
				break;
			}
			case MapEventType.DrawFeatureSelectionChange: {
				this.featureSelectionChangeEvent(<FeatureSelectionChangeEvent>event);
				break;
			}
			case MapEventType.DrawTransientFeatureCreate: {
				this.transientFeatureCreatedEvent(<TransientFeatureCreatedEvent>event);
				break;
			}
			case MapEventType.PitchEnd: {
				this.mapPitchEndEvent(<mapboxgl.MapboxEvent<MouseEvent | TouchEvent | undefined>>event);
				break;
			}
			case MapEventType.RotateEnd: {
				this.mapRotateEndEvent(<mapboxgl.MapboxEvent<MouseEvent | TouchEvent | undefined>>event);
				break;
			}
			case MapEventType.StyleData: {
				this.mapStyleDataEvent(<mapboxgl.MapDataEvent>event);
				break;
			}
			case MapEventType.StyleDataLoading: {
				this.mapStyleDataLoadingEvent(<mapboxgl.MapDataEvent>event);
				break;
			}
			case MapEventType.Load: {
				d.loading = false;
				d.loaded = true;
				for (const [srcId, src] of d.sources) {
					if (!event.target.getSource(srcId)) {
						event.target.addSource(srcId, src);
					}
				}
				for (const item of d.layers) {
					if (!event.target.getLayer(item.layerId)) {
						event.target.addLayer(item.layer);
					}
				}
				if (d.flags & InteractiveMapFlag.MapNavControlIsEnabled) {
					event.target.addControl(
						new mapboxgl.NavigationControl({
							showZoom: true,
							showCompass: false,
						}),
						InteractiveMapControlPosition.TopRight,
					);
				}
				if (d.flags & InteractiveMapFlag.MapDrawingIsEnabled) {
					const ctrl = this.newMapControl(MapControlType.Draw);
					if (ctrl) {
						d.setCtrl(
							MapControlType.Draw,
							ctrl,
						);
					}
				}
				if (d.flags & InteractiveMapFlag.MapInfoControlIsEnabled) {
					const ctrl = this.newMapControl(MapControlType.Info);
					if (ctrl) {
						d.setCtrl(
							MapControlType.Info,
							ctrl,
						);
					}
				}
				if (d.flags & InteractiveMapFlag.MapLayerControlIsEnabled) {
					const ctrl = this.newMapControl(MapControlType.Layer);
					if (ctrl) {
						d.setCtrl(
							MapControlType.Layer,
							ctrl,
						);
					}
				}
				this.mapLoadEvent(<mapboxgl.MapboxEvent>event);
				this.loaded();
				break;
			}
			default: {
				logger.warning('mapEvent: Got invalid event "%s"', event.type);
				break;
			}
		}
	}

	@bind
	protected mapLayerEvent(event: MapLayerEvent<unknown>): void {
		switch (event.type) {
			case MapEventType.MouseMove: {
				this.mapLayerMouseMoveEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.MouseEnter: {
				this.mapLayerMouseEnterEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.MouseLeave: {
				this.mapLayerMouseLeaveEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.MouseOver: {
				this.mapLayerMouseOverEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.MouseOut: {
				this.mapLayerMouseOutEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.MouseDown: {
				this.mapLayerMouseDownEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.MouseUp: {
				this.mapLayerMouseUpEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.MouseClick: {
				this.mapLayerMouseClickEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.MouseDoubleClick: {
				this.mapLayerMouseDoubleClickEvent(<mapboxgl.MapLayerMouseEvent>event);
				break;
			}
			case MapEventType.TouchStart: {
				this.mapLayerTouchStartEvent(<mapboxgl.MapLayerTouchEvent>event);
				break;
			}
			case MapEventType.TouchEnd: {
				this.mapLayerTouchEndEvent(<mapboxgl.MapLayerTouchEvent>event);
				break;
			}
			case MapEventType.TouchCancel: {
				this.mapLayerTouchCancelEvent(<mapboxgl.MapLayerTouchEvent>event);
				break;
			}
			default: {
				logger.warning('mapLayerEvent: Got invalid event "%s"', event.type);
				break;
			}
		}
	}

	protected mapLayerMouseMoveEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerMouseEnterEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerMouseLeaveEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerMouseOverEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerMouseOutEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerMouseDownEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerMouseUpEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerMouseClickEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerMouseDoubleClickEvent(event: mapboxgl.MapLayerMouseEvent): void {
	}

	protected mapLayerTouchStartEvent(event: mapboxgl.MapLayerTouchEvent): void {
	}

	protected mapLayerTouchEndEvent(event: mapboxgl.MapLayerTouchEvent): void {
	}

	protected mapLayerTouchCancelEvent(event: mapboxgl.MapLayerTouchEvent): void {
	}

	protected mapLoadEvent(event: mapboxgl.MapboxEvent): void {
	}

	protected mapMouseClickEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMouseDoubleClickEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMouseDownEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMouseEnterEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMouseLeaveEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMouseMoveEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMouseOutEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMouseOverEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMouseUpEvent(event: mapboxgl.MapMouseEvent): void {
	}

	protected mapMoveEndEvent(event: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>): void {
	}

	protected mapPitchEndEvent(event: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | undefined>): void {
	}

	@bind
	protected mapPopupEvent(event: MapPopupEvent): void {
		const d = this.d;
		switch (event.type) {
			case MapEventType.PopupOpen: {
				d.popups.add(event.target);
				event.target.once(
					MapEventType.PopupClose,
					<mapboxgl.EventedListener>this.mapPopupEvent,
				);
				break;
			}
			case MapEventType.PopupClose: {
				d.popups.discard(event.target);
				break;
			}
		}
	}

	protected mapRotateEndEvent(event: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | undefined>): void {
	}

	protected mapSourceDataEvent(event: mapboxgl.MapSourceDataEvent): void {
	}

	protected mapStyleDataEvent(event: mapboxgl.MapDataEvent): void {
	}

	protected mapStyleDataLoadingEvent(event: mapboxgl.MapDataEvent): void {
	}

	protected mapTouchCancelEvent(event: mapboxgl.MapTouchEvent): void {
	}

	protected mapTouchEndEvent(event: mapboxgl.MapTouchEvent): void {
	}

	protected mapTouchMoveEvent(event: mapboxgl.MapTouchEvent): void {
	}

	protected mapTouchStartEvent(event: mapboxgl.MapTouchEvent): void {
	}

	protected mapZoomEvent(event: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>): void {
	}

	protected mapZoomEndEvent(event: mapboxgl.MapboxEvent<MouseEvent | TouchEvent | WheelEvent | undefined>): void {
	}

	protected newMapControl(type: MapControlType): InteractiveMapControl | null {
		switch (type) {
			case MapControlType.Draw: {
				const ctrl = new DrawControl({
					controls: {
						circle: true,
						combine_features: false,
						line_string: false,
						point: false,
						polygon: true,
						trash: false,
						uncombine_features: false,
					},
					keybindings: false,
					parent: this,
					position: InteractiveMapControlPosition.TopRight,
				});
				const evtTypes = [
					MapEventType.DrawFeatureCreate,
					MapEventType.DrawFeatureDelete,
					MapEventType.DrawModeChange,
					MapEventType.DrawFeatureSelectionChange,
					MapEventType.DrawTransientFeatureCreate,
					MapEventType.DrawFeatureUpdate,
				];
				for (const evtType of evtTypes) {
					this.setMapEventListener(
						evtType,
						true,
					);
				}
				return ctrl;
			}
			case MapControlType.Info: {
				return new InfoControl({
					parent: this,
					position: InteractiveMapControlPosition.TopRight,
				});
			}
			case MapControlType.Layer: {
				const d = this.d;
				const ctrl = new LayerControl({
					parent: this,
					position: InteractiveMapControlPosition.BottomRight,
					style: (d.initCfg.styleIdentifier.trim().length > 0) ?
						<MapStyle>d.initCfg.styleIdentifier.trim() :
						undefined,
				});
				for (const item of this.d.layers) {
					if (item.layer.ui) {
						ctrl.addLayerToggle(
							item.layerId,
							item.layer.ui.icon,
							item.layer.ui.label,
							this.isLayerVisible(item.layerId),
						);
					}
				}
				Obj.connect(
					ctrl, 'styleChanged',
					this, 'reloadMapData',
				);
				Obj.connect(
					ctrl, 'styleChanged',
					this, 'styleChanged',
				);
				Obj.connect(
					ctrl, 'layerToggled',
					this, 'layerToggled',
				);
				return ctrl;
			}
		}
		return null;
	}

	openPopup(coords: GeoCoordinateLike, opts?: Partial<PopupOpts>): void;
	openPopup(latitude: number, longitude: number, opts?: Partial<PopupOpts>): void;
	openPopup(a: GeoCoordinateLike | number, b?: Partial<PopupOpts> | number, c?: Partial<PopupOpts>): void {
		/**
		 * Open a popup on map at given geographic (NOT screen/pixel)
		 * coordinates.
		 */
		const d = this.d;
		if (!d.map) {
			return;
		}
		let coords: GeoCoordinate;
		let opts: Partial<PopupOpts> = {};
		if (isNumber(a) && isNumber(b)) {
			// SIG: openPopup(latitude: number, longitude: number, opts?: Partial<PopupOpts>)
			coords = new GeoCoordinate(a, b);
			if (c !== undefined) {
				opts = c;
			}
		} else {
			// SIG: openPopup(coords: GeoCoordsLike, opts?: Partial<PopupOpts>)
			coords = GeoCoordinate.fromGeoCoordinateLike(<GeoCoordinateLike>a);
			if (b !== undefined) {
				opts = <Partial<PopupOpts>>b;
			}
		}
		const popup = d.newPopup(opts);
		popup.once(
			MapEventType.PopupOpen,
			<mapboxgl.EventedListener>this.mapPopupEvent,
		);
		popup.setLngLat(
			geoCoordinateToLngLat(coords),
		).addTo(
			d.map,
		);
		popup.addClassName('lb-map-popup');
		if (opts.cssClassName) {
			popup.addClassName(opts.cssClassName);
		}
	}

	pitch(): number {
		const d = this.d;
		return d.map ?
			d.map.getPitch() :
			DEFAULT_MAP_PITCH;
	}

	project(coord: GeoCoordinateLike): Point | null {
		return this.d.project(coord);
	}

	@SLOT
	protected reloadMapData(): void {
		const d = this.d;
		if (!d.map) {
			return;
		}
		for (const [sourceId, source] of this.sources()) {
			d.map.addSource(sourceId, source);
		}
		for (const pair of this.layers()) {
			d.map.addLayer(pair[1]);
		}
	}

	removeLayer(layerId: string): void {
		/**
		 * Remove layer matching given layer ID.
		 */
		this.d.removeLayer(layerId);
	}

	removeSource(sourceId: string): void {
		/**
		 * Remove source matching given source ID.
		 */
		this.d.removeSource(sourceId);
	}

	renderedFeatureAt(pointLike: PointLike, layerIds?: string | Iterable<string>, filter?: Array<any>): mapboxgl.MapboxGeoJSONFeature | null {
		/**
		 * Returns the first (top-most) feature returned from
		 * renderedFeaturesAt() or null if no features are returned.
		 */
		const feats = this.renderedFeaturesAt(
			pointLike,
			layerIds,
			filter,
		);
		return (feats.length > 0) ?
			feats[0] :
			null;
	}

	renderedFeaturesAt(pointLike: PointLike, layerIds?: string | Iterable<string>, filter?: Array<any>): Array<mapboxgl.MapboxGeoJSONFeature> {
		/**
		 * Return any rendered features present at given screen coords
		 * (not geographic!).
		 *
		 * Provide one or more layer IDs and the result will be narrowed to
		 * those layers.
		 */
		const d = this.d;
		if (d.map) {
			const ids = (layerIds === undefined) ?
				undefined :
				(typeof layerIds === 'string') ?
					[layerIds] :
					Array.isArray(layerIds) ?
						layerIds :
						Array.from(layerIds);
			return d.map.queryRenderedFeatures(
				Point.fromPointLike(pointLike).toTuple(),
				{
					filter,
					layers: ids,
				},
			);
		}
		return [];
	}

	selectedFeatureCount(): number {
		return this.selectedFeatures().features.length;
	}

	selectedFeatures<G extends GeoJsonGeometry, P = GeoJsonProperties>(): GeoJsonFeatureCollection<G, P> {
		/**
		 * Return all Draw GeoJSON features currently selected.
		 */
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		return ctrl ?
			ctrl.selectedFeatures<G, P>() :
			emptyGeoJsonFeatureCollection<G, P>();
	}

	setFeatures<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(features: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeature<G, P> | GeoJsonFeatureCollection<G, P>): void {
		/**
		 * First clear any existing Draw GeoJSON features, then add those
		 * given as argument.
		 */
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		if (ctrl) {
			ctrl.setFeatures(features);
		}
	}

	setFeatureState<G extends GeoJsonGeometry | null = GeoJsonGeometry, P = GeoJsonProperties>(feature: GeoJsonFeature<G, P> | Iterable<GeoJsonFeature<G, P>> | GeoJsonFeatureCollection<G, P> | Iterable<GeoJsonFeatureId> | GeoJsonFeatureId | undefined, state: {[key: string]: any}, sourceId: string, sourceLayerId?: string): void {
		/**
		 * Set map source feature state.
		 */
		const d = this.d;
		if (!d.map) {
			return;
		}
		const ids = featureOrFeatureIdLikeToFeatureIds(
			feature,
		);
		if (ids.length > 0) {
			for (const id of ids) {
				d.setFeatureState(
					id,
					state,
					sourceId,
					sourceLayerId,
				);
			}
		} else {
			logger.debug(
				'setFeatureState: Invalid argument type (%s) or missing argument ID:',
				typeof feature,
				feature,
			);
		}
	}

	@SLOT
	setLayerVisible(layerId: string, visible: boolean): void {
		if (this.isLayerVisible(layerId) === visible) {
			// Nothing to do.
			return;
		}
		const d = this.d;
		const vis = visible ?
			'visible' :
			'none';
		if (d.map) {
			d.map.setLayoutProperty(
				layerId,
				'visibility',
				vis,
			);
		}
		// Set property on local object to ensure continuity when layers are
		// re-loaded.
		for (const obj of d.layers) {
			if ((obj.layerId !== layerId) || (obj.layer.type === 'custom')) {
				continue;
			}
			if (!obj.layer.layout) {
				obj.layer.layout = {};
			}
			obj.layer.layout.visibility = vis;
			break;
		}
		this.layerVisibilityChanged(layerId, visible);
	}

	setMapControlActive(type: MapControlType, active: boolean): void {
		const d = this.d;
		const ctrl = d.control(type);
		if (!ctrl || (ctrl.isActive() === active)) {
			return;
		}
		ctrl.setActive(active);
	}

	setLayerPaintProperty(layerId: string, propertyName: string, value: any): void {
		this.d.setLayerPaintProperty(
			layerId,
			propertyName,
			value,
		);
	}

	protected setMapEventListener(type: MapEventType, listen: boolean, layerId?: string): void {
		const d = this.d;
		if (!d.map) {
			return;
		}
		if (listen) {
			if (layerId === undefined) {
				d.map.on(
					type,
					this.mapEvent,
				);
			} else {
				d.map.on(
					<keyof mapboxgl.MapLayerEventType>type,
					layerId,
					this.mapLayerEvent,
				);
			}
		} else {
			if (layerId === undefined) {
				d.map.off(
					type,
					this.mapEvent,
				);
			} else {
				d.map.off(
					<keyof mapboxgl.MapLayerEventType>type,
					layerId,
					this.mapLayerEvent,
				);
			}
		}
	}

	setSelectedFeatures(features: Iterable<GeoJsonFeatureId> | GeoJsonFeatureId): void {
		/**
		 * Set Draw GeoJSON features selected.
		 */
		const ctrl = <DrawControl | null>this.d.control(MapControlType.Draw);
		if (ctrl) {
			ctrl.setSelectedFeatures(features);
		}
	}

	setSourceData<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(sourceId: string, data: Iterable<GeoJsonFeature<G, P>> | GeoJsonFeature<G, P> | GeoJsonFeatureCollection<G, P>): void {
		/**
		 * Set data for source matching given sourceId.
		 */
		const d = this.d;
		const src = d.sources.get(sourceId);
		if (!src || (src.type !== 'geojson')) {
			return;
		}
		d.setSourceData(sourceId, data);
	}

	setTheme(theme: MapStyleTheme): void {
		const d = this.d;
		if (theme === d.theme) {
			return;
		}
		d.theme = theme;
		d.loadTheme(layerThemes[d.theme]);
		this.themeChanged(d.theme);
	}

	source(sourceId: string): Source | null {
		/**
		 * Returns the source matching given ID.
		 *
		 * The sources considered are only those added via this component and
		 * are not requested from the vendor map component.
		 */
		return this.d.source(sourceId);
	}

	*sources(): IterableIterator<[string, Source]> {
		/**
		 * Returns iterator of [<source ID>, <Source>]
		 */
		yield *this.d.sources;
	}

	@SIGNAL
	protected stationary(): void {
	}

	style(): MapStyle {
		/**
		 * Return current map style ID.
		 */
		const ctrl = <LayerControl | null>this.d.control(MapControlType.Layer);
		return ctrl ?
			ctrl.style() :
			DEFAULT_MAP_STYLE_IDENT;
	}

	@SIGNAL
	protected styleChanged(newStyle: MapStyle): void {
		/**
		 * Emitted when map finishes loading a new style.
		 */
	}

	theme(): MapStyleTheme {
		return this.d.theme;
	}

	@SIGNAL
	protected themeChanged(newTheme: MapStyleTheme): void {
	}

	protected transientFeatureCreatedEvent<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(event: TransientFeatureCreatedEvent<G, P>): void {
		this.transientFeaturesCreated<G, P>(event.features);
	}

	@SIGNAL
	protected transientFeaturesCreated<G extends GeoJsonGeometry = GeoJsonGeometry, P = GeoJsonProperties>(features: Array<GeoJsonFeature<G, P>>): void {
		/**
		 * Draw GeoJSON transient feature(s) was just created
		 */
	}

	zoom(): number {
		const d = this.d;
		return d.map ?
			d.map.getZoom() :
			DEFAULT_MAP_ZOOM_LEVEL;
	}
}

function fitToBoundsOptsToFitBoundsOptions(opts?: Partial<FitToBoundsOpts>): mapboxgl.FitBoundsOptions | undefined {
	if (!opts) {
		return undefined;
	}
	if (isNumber(opts.latitude) && isNumber(opts.longitude)) {
		return {
			...opts,
			center: {
				lat: opts.latitude,
				lng: opts.longitude,
			},
		};
	}
	return {
		bearing: opts.bearing,
		curve: opts.curve,
		maxDuration: opts.maxDuration,
		maxZoom: opts.maxZoom,
		minZoom: opts.minZoom,
		padding: opts.padding,
		pitch: opts.pitch,
		screenSpeed: opts.screenSpeed,
		speed: opts.speed,
		zoom: opts.zoom,
	};
}

export function geoCoordinateLikeToLngLat(coord: GeoCoordinateLike): mapboxgl.LngLat {
	if (Array.isArray(coord)) {
		// NB: GeoCoordinate coordinate positions are the correct positions.
		//     You must first reverse them -- making them incorrect -- to
		//     create the LngLat object.
		const [lat, lng] = coord;
		return new mapboxgl.LngLat(lng, lat);
	}
	if (coord instanceof GeoCoordinate) {
		return geoCoordinateToLngLat(coord);
	}
	return new mapboxgl.LngLat(coord.longitude, coord.latitude);
}

export function geoCoordinateToLngLat(coord: GeoCoordinate): mapboxgl.LngLat {
	return new mapboxgl.LngLat(
		coord.longitude(),
		coord.latitude(),
	);
}

function geoRectangleToLngLatBounds(rect: GeoRectangle): mapboxgl.LngLatBounds {
	return new mapboxgl.LngLatBounds(
		geoCoordinateToLngLat(rect.bottomLeft()),
		geoCoordinateToLngLat(rect.topRight()));
}

export function lngLatToGeoCoordinate(lngLat: mapboxgl.LngLat): GeoCoordinate {
	return new GeoCoordinate(lngLat.lat, lngLat.lng);
}

function popupOptsToPopupOptions(opts?: Partial<PopupOpts>): mapboxgl.PopupOptions {
	opts = opts || {};
	const rv: mapboxgl.PopupOptions = {
		closeButton: opts.closeButton,
		closeOnClick: opts.closeOnClick,
		closeOnMove: opts.closeOnMove,
		focusAfterOpen: opts.focusAfterOpen,
	};
	if (opts.maxWidth !== undefined) {
		let s: string;
		if (isNumber(opts.maxWidth)) {
			s = pixelString(opts.maxWidth);
		} else {
			s = opts.maxWidth;
		}
		rv.maxWidth = s;
	}
	return rv;
}
