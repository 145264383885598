import {list} from '../tools';
import {OBJ, Obj, ObjOpts, ObjPrivate, SIGNAL, SLOT} from '../obj';
import {getLogger} from '../logging';

const logger = getLogger('ui.undostack');

export class UndoCommandPrivate {
	actionText: string;
	childList: list<UndoCommand>;
	id: number;
	obsolete: boolean;
	text: string;

	constructor() {
		this.actionText = '';
		this.childList = new list<UndoCommand>();
		this.id = -1;
		this.obsolete = false;
		this.text = '';
	}

	destroy(): void {
		this.actionText = '';
		this.childList.clear();
		this.id = -1;
		this.obsolete = false;
		this.text = '';
	}
}

export interface UndoCommandOpts {
	parent: UndoCommand | null;
	text: string;
}

export class UndoCommand {
	d: UndoCommandPrivate;

	constructor(opts: Partial<UndoCommandOpts> = {}) {
		this.d = new UndoCommandPrivate();
		if (opts.parent) {
			opts.parent.d.childList.append(this);
		}
		if (opts.text !== undefined) {
			this.setText(opts.text);
		}
	}

	actionText(): string {
		return this.d.actionText;
	}

	child(index: number): UndoCommand | null {
		if ((index >= 0) && (index < this.d.childList.size())) {
			return this.d.childList.at(index);
		}
		return null;
	}

	childCount(): number {
		return this.d.childList.size();
	}

	destroy(): void {
		for (const obj of this.d.childList) {
			obj.destroy();
		}
		this.d.childList.clear();
		this.d.destroy();
	}

	id(): number {
		return -1;
	}

	isObsolete(): boolean {
		return this.d.obsolete;
	}

	mergeWith(other: UndoCommand): boolean {

		return false;
	}

	redo(): void {
		for (let i = 0; i < this.d.childList.size(); ++i) {
			this.d.childList.at(i).redo();
		}
	}

	setObsolete(obsolete: boolean): void {
		this.d.obsolete = obsolete;
	}

	setText(text: string): void {
		const cdpos = text.indexOf('\n');
		if (cdpos > 0) {
			this.d.text = text.substring(0, cdpos);
			this.d.actionText = text.substring(cdpos + 1);
		} else {
			this.d.text = text;
			this.d.actionText = text;
		}
	}

	text(): string {
		return this.d.text;
	}

	undo(): void {
		for (let i = this.d.childList.size() - 1; i >= 0; --i) {
			this.d.childList.at(i).undo();
		}
	}
}

export class UndoStackPrivate extends ObjPrivate {
	cleanIndex: number = 0;
	commandList: list<UndoCommand> = new list<UndoCommand>();
	group: UndoGroup | null = null;
	index: number = 0;
	macroStack: list<UndoCommand> = new list<UndoCommand>();
	undoLimit: number = 0;

	checkUndoLimit(): boolean {
		if ((this.undoLimit <= 0) || !this.macroStack.isEmpty() || (this.undoLimit >= this.commandList.size())) {
			return false;
		}
		const delCount = this.commandList.size() - this.undoLimit;
		for (let i = 0; i < delCount; ++i) {
			const cmd = this.commandList.takeFirst();
			cmd.destroy();
		}
		this.index -= delCount;
		if (this.cleanIndex !== -1) {
			if (this.cleanIndex < delCount) {
				// We've deleted the clean command
				this.cleanIndex = -1;
			} else {
				this.cleanIndex -= delCount;
			}
		}
		return true;
	}

	get q(): UndoStack {
		return <UndoStack>super.q;
	}

	setIndex(idx: number, clean: boolean): void {
		const q = this.q;
		const wasClean = this.index === this.cleanIndex;
		if (idx !== this.index) {
			this.index = idx;
			q.indexChanged(this.index);
			q.canUndoChanged(q.canUndo());
			q.undoTextChanged(q.undoText());
			q.canRedoChanged(q.canRedo());
			q.redoTextChanged(q.redoText());
		}
		if (clean) {
			this.cleanIndex = this.index;
		}
		const isClean = this.index === this.cleanIndex;
		if (isClean !== wasClean) {
			q.cleanChanged(isClean);
		}
	}
}

export interface UndoStackOpts extends ObjOpts {
	dd: UndoStackPrivate;
}

@OBJ
export class UndoStack extends Obj {
	constructor(opts: Partial<UndoStackOpts> = {}) {
		opts.dd = opts.dd || new UndoStackPrivate();
		super(opts);
		if (opts.parent && (opts.parent instanceof UndoGroup)) {
			opts.parent.addStack(this);
		}
	}

	beginMacro(text: string): void {
		const d = this.d;
		const cmd = new UndoCommand();
		cmd.setText(text);
		if (d.macroStack.isEmpty()) {
			while (d.index < d.commandList.size()) {
				d.commandList.takeLast().destroy();
			}
			if (d.cleanIndex > d.index) {
				// We've deleted the clean state
				d.cleanIndex = -1;
			}
			d.commandList.append(cmd);
		} else {
			d.macroStack.last().d.childList.append(cmd);
		}
		d.macroStack.append(cmd);
		if (d.macroStack.size() === 1) {
			this.canUndoChanged(false);
			this.undoTextChanged('');
			this.canRedoChanged(false);
			this.redoTextChanged('');
		}
	}

	canRedo(): boolean {
		const d = this.d;
		if (!d.macroStack.isEmpty()) {
			return false;
		}
		return d.index < d.commandList.size();
	}

	canUndo(): boolean {
		const d = this.d;
		if (!d.macroStack.isEmpty()) {
			return false;
		}
		return d.index > 0;
	}

	@SIGNAL
	canRedoChanged(canRedo: boolean): void {
	}

	@SIGNAL
	canUndoChanged(canUndo: boolean): void {
	}

	@SIGNAL
	cleanChanged(clean: boolean): void {
	}

	cleanIndex(): number {
		return this.d.cleanIndex;
	}

	clear(): void {
		const d = this.d;
		if (d.commandList.isEmpty()) {
			return;
		}
		const wasClean = this.isClean();
		d.macroStack.clear();
		d.commandList.clear();
		d.index = 0;
		d.cleanIndex = 0;
		this.indexChanged(0);
		this.canUndoChanged(false);
		this.undoTextChanged('');
		this.canRedoChanged(false);
		this.redoTextChanged('');
		if (!wasClean) {
			this.cleanChanged(true);
		}
	}

	command(index: number): UndoCommand | null {
		const d = this.d;
		if ((index >= 0) && (index < d.commandList.size())) {
			return d.commandList.at(index);
		}
		return null;
	}

	count(): number {
		return this.d.commandList.size();
	}

	get d(): UndoStackPrivate {
		return <UndoStackPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		if (d.group) {
			d.group.removeStack(this);
		}
		this.clear();
		super.destroy();
	}

	endMacro(): void {
		const d = this.d;
		if (d.macroStack.isEmpty()) {
			logger.warning('endMacro: No matching beginMacro');
			return;
		}
		d.macroStack.removeLast();
		if (d.macroStack.isEmpty()) {
			d.checkUndoLimit();
			d.setIndex(d.index + 1, false);
		}
	}

	index(): number {
		return this.d.index;
	}

	@SIGNAL
	indexChanged(index: number): void {
	}

	isActive(): boolean {
		const d = this.d;
		return d.group ?
			(d.group.activeStack() === this) :
			true;
	}

	isClean(): boolean {
		const d = this.d;
		if (!d.macroStack.isEmpty()) {
			return false;
		}
		return d.cleanIndex === d.index;
	}

	push(cmd: UndoCommand): void {
		const d = this.d;
		if (!cmd.isObsolete()) {
			cmd.redo();
		}
		const macro = !d.macroStack.isEmpty();
		let cur: UndoCommand | null = null;
		if (macro) {
			const macroCmd = d.macroStack.last();
			if (!macroCmd.d.childList.isEmpty()) {
				cur = macroCmd.d.childList.last();
			}
		} else {
			if (d.index > 0) {
				cur = d.commandList.at(d.index - 1);
			}
			while (d.index < d.commandList.size()) {
				const c = d.commandList.takeLast();
				c.destroy();
			}
			if (d.cleanIndex > d.index) {
				// We've deleted the clean state
				d.cleanIndex = -1;
			}
		}
		const tryMerge = (cur !== null)
			&& (cur.id() !== -1)
			&& (cur.id() === cmd.id())
			&& (macro || (d.index !== d.cleanIndex));
		if (tryMerge && (<UndoCommand>cur).mergeWith(cmd)) {
			cmd.destroy();
			if (macro) {
				if ((<UndoCommand>cur).isObsolete()) {
					d.macroStack.last().d.childList.takeLast().destroy();
				}
			} else {
				if ((<UndoCommand>cur).isObsolete()) {
					d.commandList.takeLast().destroy();
					d.setIndex(d.index - 1, false);
				} else {
					this.indexChanged(d.index);
					this.canUndoChanged(this.canUndo());
					this.undoTextChanged(this.undoText());
					this.canRedoChanged(this.canRedo());
					this.redoTextChanged(this.redoText());
				}
			}
		} else if (cmd.isObsolete()) {
			// Command should be destroyed and NOT added to the stack
			cmd.destroy();
		} else {
			if (macro) {
				d.macroStack.last().d.childList.append(cmd);
			} else {
				d.commandList.append(cmd);
				d.checkUndoLimit();
				d.setIndex(d.index + 1, false);
			}
		}
	}

	@SLOT
	redo(): void {
		const d = this.d;
		if (d.index === d.commandList.size()) {
			return;
		}
		if (!d.macroStack.isEmpty()) {
			logger.warning('redo: Cannot redo in the middle of a macro');
			return;
		}
		const idx = d.index;
		const cmd = d.commandList.at(idx);
		if (!cmd.isObsolete()) {
			cmd.redo();
		}
		if (cmd.isObsolete()) {
			// A separate check; the redo command may set obsolete flag
			d.commandList.takeAt(idx).destroy();
			if (d.cleanIndex > idx) {
				this.resetClean();
			}
		} else {
			d.setIndex(d.index + 1, false);
		}
	}

	redoText(): string {
		const d = this.d;
		if (!d.macroStack.isEmpty()) {
			return '';
		}
		if (d.index < d.commandList.size()) {
			return d.commandList.at(d.index).actionText();
		}
		return '';
	}

	@SIGNAL
	redoTextChanged(redoText: string): void {
	}

	@SLOT
	resetClean(): void {
		const wasClean = this.isClean();
		this.d.cleanIndex = -1;
		if (wasClean) {
			this.cleanChanged(false);
		}
	}

	@SLOT
	setActive(active: boolean = true): void {
		const d = this.d;
		if (d.group) {
			if (active) {
				d.group.setActiveStack(this);
			} else if (d.group.activeStack() === this) {
				d.group.setActiveStack(null);
			}
		}
	}

	@SLOT
	setClean(): void {
		const d = this.d;
		if (!d.macroStack.isEmpty()) {
			logger.warning('setClean: Cannot set clean in the middle of a macro.');
			return;
		}
		d.setIndex(d.index, true);
	}

	@SLOT
	setIndex(idx: number): void {
		const d = this.d;
		if (!d.macroStack.isEmpty()) {
			logger.warning('setIndex: Cannot set index in the middle of a macro');
			return;
		}
		if (idx < 0) {
			idx = 0;
		} else if (idx > d.commandList.size()) {
			idx = d.commandList.size();
		}
		let i: number = d.index;
		while (i < idx) {
			const cmd = d.commandList.at(i);
			if (!cmd.isObsolete()) {
				cmd.redo();
			}
			// A separate check; the redo command may set obsolete flag
			if (cmd.isObsolete()) {
				d.commandList.takeAt(i).destroy();
				if (d.cleanIndex > i) {
					this.resetClean();
				}
				// We removed a command; take one.
				--idx;
			} else {
				++i;
			}
		}
		while (i > idx) {
			const cmd = d.commandList.at(--i);
			cmd.undo();
			if (cmd.isObsolete()) {
				d.commandList.takeAt(i).destroy();
				if (d.cleanIndex > i) {
					this.resetClean();
				}
			}
		}
		d.setIndex(idx, false);
	}

	setUndoLimit(limit: number): void {
		const d = this.d;
		if (!d.commandList.isEmpty()) {
			logger.warning('setUndoLimit: An undo limit can only be set when the stack is empty');
			return;
		}
		if (limit === d.undoLimit) {
			return;
		}
		d.undoLimit = limit;
		d.checkUndoLimit();
	}

	text(index: number): string {
		const d = this.d;
		if ((index >= 0) && (index < d.commandList.size())) {
			return d.commandList.at(index).text();
		}
		return '';
	}

	@SLOT
	undo(): void {
		const d = this.d;
		if (d.index === 0) {
			return;
		}
		if (!d.macroStack.isEmpty()) {
			logger.warning('undo: Cannot undo in the middle of a macro');
			return;
		}
		const idx = d.index - 1;
		const cmd = d.commandList.at(idx);
		if (!cmd.isObsolete()) {
			cmd.undo();
		}
		if (cmd.isObsolete()) {
			// A separate check; the undo command may set obsolete flag
			d.commandList.takeAt(idx).destroy();
			if (d.cleanIndex > idx) {
				this.resetClean();
			}
		}
		d.setIndex(idx, false);
	}

	undoLimit(): number {
		return this.d.undoLimit;
	}

	undoText(): string {
		const d = this.d;
		if (!d.macroStack.isEmpty()) {
			return '';
		}
		if (d.index > 0) {
			return d.commandList.at(d.index - 1).actionText();
		}
		return '';
	}

	@SIGNAL
	undoTextChanged(undoText: string): void {
	}
}

export class UndoGroupPrivate extends ObjPrivate {
	active: UndoStack | null;
	stackList: list<UndoStack>;

	constructor() {
		super();
		this.active = null;
		this.stackList = new list();
	}

	get q(): UndoGroup {
		return <UndoGroup>super.q;
	}
}

export interface UndoGroupOpts extends ObjOpts {
	dd: UndoGroupPrivate;
}

export class UndoGroup extends Obj {
	constructor(opts: Partial<UndoGroupOpts> = {}) {
		opts.dd = opts.dd || new UndoGroupPrivate();
		super(opts);
	}

	activeStack(): UndoStack | null {
		return this.d.active;
	}

	@SIGNAL
	private activeStackChanged(stack: UndoStack | null): void {
	}

	addStack(stack: UndoStack): void {
		const d = this.d;
		if (d.stackList.contains(stack)) {
			return;
		}
		d.stackList.append(stack);
		const other = stack.d.group;
		if (other) {
			other.removeStack(stack);
		}
		stack.d.group = this;
	}

	canRedo(): boolean {
		const d = this.d;
		return d.active ?
			d.active.canRedo() :
			false;
	}

	@SIGNAL
	private canRedoChanged(canRedo: boolean): void {
	}

	canUndo(): boolean {
		const d = this.d;
		return d.active ?
			d.active.canUndo() :
			false;
	}

	@SIGNAL
	private canUndoChanged(canUndo: boolean): void {
	}

	@SIGNAL
	private cleanChanged(clean: boolean): void {
	}

	destroy(): void {
		const d = this.d;
		d.active = null;
		for (const obj of d.stackList) {
			obj.d.group = null;
		}
		d.stackList.clear();
		super.destroy();
	}

	@SIGNAL
	private indexChanged(index: number): void {
	}

	isClean(): boolean {
		const d = this.d;
		return d.active ?
			d.active.isClean() :
			true;
	}

	@SLOT
	redo(): void {
		const d = this.d;
		if (d.active) {
			d.active.redo();
		}
	}

	redoText(): string {
		const d = this.d;
		return d.active ?
			d.active.redoText() :
			'';
	}

	@SIGNAL
	private redoTextChanged(text: string): void {
	}

	removeStack(stack: UndoStack): void {
		const d = this.d;
		if (d.stackList.removeAll(stack) === 0) {
			return;
		}
		if (stack === d.active) {
			this.setActiveStack(null);
		}
		stack.d.group = null;
	}

	@SLOT
	setActiveStack(stack: UndoStack | null): void {
		const d = this.d;
		if (d.active === stack) {
			return;
		}
		if (d.active) {
			Obj.disconnect(
				d.active, 'canUndoChanged',
				this, 'canUndoChanged',
			);
			Obj.disconnect(
				d.active, 'undoTextChanged',
				this, 'undoTextChanged',
			);
			Obj.disconnect(
				d.active, 'canRedoChanged',
				this, 'canRedoChanged',
			);
			Obj.disconnect(
				d.active, 'redoTextChanged',
				this, 'redoTextChanged',
			);
			Obj.disconnect(
				d.active, 'indexChanged',
				this, 'indexChanged',
			);
			Obj.disconnect(
				d.active, 'cleanChanged',
				this, 'cleanChanged',
			);
		}
		d.active = stack;
		if (d.active) {
			Obj.connect(
				d.active, 'canUndoChanged',
				this, 'canUndoChanged',
			);
			Obj.connect(
				d.active, 'undoTextChanged',
				this, 'undoTextChanged',
			);
			Obj.connect(
				d.active, 'canRedoChanged',
				this, 'canRedoChanged',
			);
			Obj.connect(
				d.active, 'redoTextChanged',
				this, 'redoTextChanged',
			);
			Obj.connect(
				d.active, 'indexChanged',
				this, 'indexChanged',
			);
			Obj.connect(
				d.active, 'cleanChanged',
				this, 'cleanChanged',
			);
			this.canUndoChanged(d.active.canUndo());
			this.undoTextChanged(d.active.undoText());
			this.canRedoChanged(d.active.canRedo());
			this.redoTextChanged(d.active.redoText());
			this.cleanChanged(d.active.isClean());
			this.indexChanged(d.active.index());
		} else {
			this.canUndoChanged(false);
			this.undoTextChanged('');
			this.canRedoChanged(false);
			this.redoTextChanged('');
			this.cleanChanged(true);
			this.indexChanged(0);
		}
		this.activeStackChanged(d.active);
	}

	stacks(): list<UndoStack> {
		return this.d.stackList;
	}

	@SLOT
	undo(): void {
		const d = this.d;
		if (d.active) {
			d.active.undo();
		}
	}

	undoText(): string {
		const d = this.d;
		return d.active ?
			d.active.undoText() :
			'';
	}

	@SIGNAL
	private undoTextChanged(text: string): void {
	}

	get d(): UndoGroupPrivate {
		return <UndoGroupPrivate>super.d;
	}
}
