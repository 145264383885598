import mapboxgl from 'mapbox-gl';

import {Icon} from '../../icon';
import {DEFAULT_MAP_STYLE_IDENT, MapStyle} from '../../../constants';
import {ElObj} from '../../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {PushButton, PushButtonOpts, PushButtonPrivate} from '../../pushbutton';
import {InteractiveMapControl, InteractiveMapControlOpts, InteractiveMapControlPrivate} from './control';
import {Evt} from '../../../evt';
import {getLogger} from '../../../logging';
import {MapEventType} from '../map';

const logger = getLogger('ui.layercontrol');

enum LayerButtonRole {
	MapLayerToggle = 0x0001,
	MapStyleChanger,
}

class LayerButtonPrivate extends PushButtonPrivate {
	selected: boolean;
	text: ElObj | null;

	constructor() {
		super();
		this.selected = false;
		this.text = null;
	}

	init(opts: Partial<LayerButtonOpts>): void {
		super.init(opts);
		const q = this.q;
		if (opts.icon) {
			q.setIcon(opts.icon);
		}
		if (!this.text) {
			this.text = new ElObj({
				classNames: 'lb-layer-list-item-label',
				parent: q,
				tagName: 'span',
			});
			this.text.show();
		}
		if (opts.label !== undefined) {
			q.setText(opts.label);
		}
		if (opts.selected !== undefined) {
			q.setSelected(opts.selected);
		}
		Obj.connect(
			q, 'clicked',
			q, '_clicked',
		);
	}

	get q(): LayerButton {
		return <LayerButton>super.q;
	}
}

interface LayerButtonOpts extends PushButtonOpts {
	dd: LayerButtonPrivate;
	label: string;
	selected: boolean;
}

@OBJ
class LayerButton extends PushButton {
	constructor(opts: Partial<LayerButtonOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['type', 'button'],
		);
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-layer-button',
			'go-away-mapbox',
		);
		opts.dd = opts.dd || new LayerButtonPrivate();
		super(opts);
	}

	@SLOT
	private _clicked(): void {
		const par = this.parentLayerControl();
		if (!par) {
			return;
		}
		const pd = par.d;
		const item = pd.lyrBtnItems.get(this);
		if (!item) {
			return;
		}
		switch (item.role) {
			case LayerButtonRole.MapLayerToggle: {
				const prev = par.isLayerEnabled(item.layerId);
				par.setLayerEnabled(
					item.layerId,
					!prev,
				);
				break;
			}
			case LayerButtonRole.MapStyleChanger: {
				const newStyleUrl = pd.nextStyleIdentForStyleIdent(pd.styleIdent);
				par.setStyle(
					newStyleUrl,
				);
				pd.setButtonStyleForCurrentStyleIdent(this);
			}
		}
	}

	get d(): LayerButtonPrivate {
		return <LayerButtonPrivate>super.d;
	}

	destroy(): void {
		const d = this.d;
		d.text = null;
		const ctrl = this.parentLayerControl();
		if (ctrl) {
			ctrl.d.lyrBtnItems.delete(this);
		}
		super.destroy();
	}

	protected enterEvent(event: Evt): void {
		const par = this.parentLayerControl();
		if (!par) {
			return;
		}
		const pd = par.d;
		const item = pd.lyrBtnItems.get(this);
		if (!item) {
			return;
		}
		item.mouseOver = true;
		pd.setButtonStyleForCurrentStyleIdent(this);
	}

	isSelected(): boolean {
		return this.d.selected;
	}

	protected leaveEvent(event: Evt): void {
		const par = this.parentLayerControl();
		if (!par) {
			return;
		}
		const pd = par.d;
		const item = pd.lyrBtnItems.get(this);
		if (!item) {
			return;
		}
		item.mouseOver = false;
		let icon = this.icon();
		if (!icon) {
			icon = new Icon();
			this.setIcon(icon);
		}
		icon.setName('layers');
		this.setText('Layers');
	}

	parentLayerControl(): LayerControl | null {
		let curr: ElObj | null = this.parentEl();
		while (curr) {
			if (curr instanceof LayerControl) {
				return curr;
			}
			curr = curr.parentEl();
		}
		return null;
	}

	setIcon(icon: Icon): void {
		const d = this.d;
		if (icon.eq(d.icon)) {
			return;
		}
		d.icon.hide();
		d.icon.setParent(null);
		super.setIcon(icon);
		d.icon.hide();
		d.icon.setParent(this);
		if (!d.icon.isNull()) {
			d.icon.show();
		}
	}

	setSelected(selected: boolean): void {
		const d = this.d;
		if (selected === d.selected) {
			return;
		}
		d.selected = selected;
		this.setClass(d.selected, 'selected');
	}

	setText(text?: string | null): void {
		const d = this.d;
		if (d.text) {
			d.text.setText(text);
		}
	}

	text(): string {
		const d = this.d;
		return d.text ?
			d.text.text().trim() :
			'';
	}
}

interface BtnMapItem {
	el: ElObj | null;
	layerId: string;
	mouseOver: boolean;
	role: LayerButtonRole;
}

export class LayerControlPrivate extends InteractiveMapControlPrivate {
	lyrBtnItems: Map<LayerButton, BtnMapItem>;
	lyrList: ElObj | null;
	styleIdent: MapStyle;

	constructor() {
		super();
		this.lyrBtnItems = new Map();
		this.lyrList = null;
		this.styleIdent = DEFAULT_MAP_STYLE_IDENT;
	}

	buttonsForRole(role: LayerButtonRole): Array<LayerButton> {
		const rv: Array<LayerButton> = [];
		for (const [btn, obj] of this.lyrBtnItems) {
			if (obj.role === role) {
				rv.push(btn);
			}
		}
		return rv;
	}

	buttonStyleForStyleIdent(ident: MapStyle): {iconName: string; text: string;} {
		// FIXME: This is lazy and dumb but I was really tired
		//        at this point so I gave myself a pass at the
		//        time. No more excuses; fix it!
		switch (ident) {
			case MapStyle.Street: {
				return {
					iconName: 'satellite',
					text: 'Satellite',
				};
			}
			case MapStyle.SatelliteStreet: {
				return {
					iconName: 'map',
					text: 'Default',
				};
			}
		}
	}

	init(opts: Partial<LayerControlOpts>): void {
		super.init(opts);
		const q = this.q;
		if (opts.style) {
			this.styleIdent = opts.style;
		}
		if (!this.lyrList) {
			this.lyrList = new ElObj({
				classNames: [
					'lb-layer-list',
				],
				parent: q,
				tagName: 'ul',
			});
			this.lyrList.hide();
		}
		const lyrBtnCont = new ElObj({
			classNames: [
				'lb-layer-button-container',
				'go-away-mapbox',
			],
			parent: q,
		});
		const lyrBtn = new LayerButton({
			icon: new Icon({
				name: 'layers',
			}),
			label: 'Layers',
			parent: lyrBtnCont,
		});
		this.lyrBtnItems.set(
			lyrBtn,
			{
				el: lyrBtnCont,
				layerId: '',
				mouseOver: false,
				role: LayerButtonRole.MapStyleChanger,
			},
		);
		lyrBtnCont.show();
	}

	layerButtonMapItem(layerId: string): {layerButton: LayerButton; buttonMapItem: BtnMapItem;} | null {
		for (const [btn, item] of this.lyrBtnItems) {
			if (item.layerId === layerId) {
				return {
					buttonMapItem: item,
					layerButton: btn,
				};
			}
		}
		return null;
	}

	nextStyleIdentForStyleIdent(ident: MapStyle): MapStyle {
		// FIXME: This is lazy and dumb but I was really tired
		//        at this point so I gave myself a pass at the
		//        time. No more excuses; fix it!
		switch (ident) {
			case MapStyle.Street: {
				return MapStyle.SatelliteStreet;
			}
			case MapStyle.SatelliteStreet: {
				return MapStyle.Street;
			}
		}
	}

	get q(): LayerControl {
		return <LayerControl>super.q;
	}

	setButtonStyleForCurrentStyleIdent(btn: LayerButton): boolean {
		const s = this.buttonStyleForStyleIdent(this.styleIdent);
		if (s) {
			let obj = btn.icon();
			if (!obj) {
				obj = new Icon();
				btn.setIcon(obj);
			}
			obj.setName(s.iconName);
			btn.setText(s.text);
			return true;
		}
		return false;
	}
}

export interface LayerControlOpts extends InteractiveMapControlOpts {
	dd: LayerControlPrivate;
	style: MapStyle;
}

@OBJ
export class LayerControl extends InteractiveMapControl {
	constructor(opts: Partial<LayerControlOpts> = {}) {
		opts.classNames = InteractiveMapControl.mergeClassNames(
			opts.classNames,
			'lb-layer-control',
			'go-away-mapbox',
			'with-detail',
		);
		opts.dd = opts.dd || new LayerControlPrivate();
		super(opts);
	}

	addLayerToggle(layerId: string, icon: Icon, label: string, enabled: boolean = true): void {
		const d = this.d;
		if (d.layerButtonMapItem(layerId)) {
			logger.warning(
				'addLayerToggle: Layer toggle for layer %s already added.',
				layerId,
			);
			return;
		}
		if (d.lyrList && d.lyrList.isHidden()) {
			d.lyrList.show();
		}
		const item = new ElObj({
			classNames: 'lb-layer-list-item',
			parent: d.lyrList,
			tagName: 'li',
		});
		const btn = new LayerButton({
			parent: item,
			label,
			icon,
			selected: enabled,
		});
		d.lyrBtnItems.set(
			btn,
			{
				el: item,
				layerId: layerId,
				mouseOver: false,
				role: LayerButtonRole.MapLayerToggle,
			},
		);
		item.show();
	}

	get d(): LayerControlPrivate {
		return <LayerControlPrivate>super.d;
	}

	hasLayerToggle(layerId: string): boolean {
		return Boolean(this.d.layerButtonMapItem(layerId));
	}

	isLayerEnabled(layerId: string): boolean {
		const obj = this.d.layerButtonMapItem(layerId);
		return obj ?
			obj.layerButton.isSelected() :
			false;
	}

	@SIGNAL
	private layerToggled(layerId: string, enabled: boolean): void {
	}

	protected mapDataEvent(event: mapboxgl.MapDataEvent): void {
		super.mapDataEvent(event);
		if (!event.target.isStyleLoaded()) {
			return;
		}
		event.target.off(
			MapEventType.Data,
			this.mapEvent,
		);
		this.styleChanged(this.d.styleIdent);
	}

	removeLayerToggle(layerId: string): void {
		const d = this.d;
		const obj = d.layerButtonMapItem(layerId);
		if (!obj) {
			return;
		}
		d.lyrBtnItems.delete(obj.layerButton);
		if (obj.buttonMapItem.el) {
			obj.buttonMapItem.el.hide();
		}
		obj.layerButton.removeEventFilter(this);
		obj.layerButton.destroy();
		if (obj.buttonMapItem.el) {
			obj.buttonMapItem.el.destroy();
		}
		obj.buttonMapItem.el = null;
	}

	setLayerEnabled(layerId: string, enabled: boolean): void {
		const obj = this.d.layerButtonMapItem(layerId);
		const btn = obj && obj.layerButton;
		if (!btn) {
			return;
		}
		if (btn.isSelected() === enabled) {
			return;
		}
		btn.setSelected(enabled);
		this.layerToggled(
			layerId,
			btn.isSelected(),
		);
	}

	setStyle(styleIdent: MapStyle): void {
		const d = this.d;
		if (styleIdent === d.styleIdent) {
			return;
		}
		if (d.map && (!d.map.isStyleLoaded())) {
			return;
		}
		d.styleIdent = styleIdent;
		if (d.map) {
			d.map.on(
				MapEventType.Data,
				this.mapEvent,
			);
			d.map.setStyle(d.styleIdent);
		}
	}

	style(): MapStyle {
		return this.d.styleIdent;
	}

	@SIGNAL
	private styleChanged(newStyleIdent: MapStyle): void {
	}
}
