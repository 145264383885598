import {Obj, OBJ, SIGNAL, SLOT} from '../../../obj';
import {MDCDataTable} from './ctrl';
import {ProgressIndicator} from './progressindicator';
import {list, Point, set} from '../../../tools';
import {ElObj, ElObjOpts, ElObjPrivate, staticRect} from '../../../elobj';
import {assert, range} from '../../../util';
import {Cell, DataCell, HeaderCell} from './cell';
import {Head, HeaderRow} from './head';
import {Body, BodyRow} from './body';
import {Table} from './table';
import {ColumnSelect} from './columnselect';
import {Pagination, Pagination as Pagination2} from '../pagination';
import {getLogger} from '../../../logging';
import type {Variant} from '../../../variant';
import {CheckState, ItemDataRole, SortOrder} from '../../../constants';
import {Row} from './row';
import {MouseEvt} from '../../../evt';
import {TextInputIconPosition} from '../../textinput';

const logger = getLogger('ui.datatable.el');
type PrototypeConstructor<T> = new () => T;

export class DataTablePrivate extends ElObjPrivate {
	columnSelect: ColumnSelect | null;
	container: Container;
	ctrl: MDCDataTable;
	dataCellPrototype: PrototypeConstructor<DataCell>;
	headerCellPrototype: PrototypeConstructor<HeaderCell>;
	headerInput: boolean;
	horizontalHeader: Head;
	pagination: Pagination | null;
	progressIndicator: ProgressIndicator | null;
	rowsCheckable: boolean;

	constructor() {
		super();
		this.columnSelect = null;
		this.container = new Container();
		this.ctrl = new MDCDataTable(document.createElement('div'));
		this.dataCellPrototype = DataCell;
		this.headerCellPrototype = HeaderCell;
		this.headerInput = false;
		this.horizontalHeader = new Head();
		this.pagination = null;
		this.progressIndicator = null;
		this.rowsCheckable = false;
	}

	body(): Body | null {
		return this.container.table().body();
	}

	bodyRows(): list<BodyRow> {
		const obj = this.body();
		return obj ?
			obj.children() :
			new list();
	}

	deselectedBodyRowIndices(): set<number> {
		const q = this.q;
		const all = new set<number>(
			range(q.rowCount()),
		);
		const selected = new set<number>(
			q.selectedRows(),
		);
		return all.difference(selected);
	}

	headerCells(): list<HeaderCell> {
		const row = this.horizontalHeader.row();
		return row ?
			row.children() :
			new list();
	}

	headerRow(): HeaderRow | null {
		return this.horizontalHeader.row();
	}

	init(opts: Partial<DataTableOpts>): void {
		super.init(opts);
		this.container.show();
		this.horizontalHeader.show();
	}

	async isElVisible(el: ElObj | Element): Promise<ElVisObj> {
		return await elVis(
			this.container,
			el,
			0.63,
			{
				top: -56,
				right: 0,
				bottom: 0,
				left: 0,
			},
		);
	}

	isSelectAllChecked(): boolean {
		const cells = this.headerCells();
		return cells.isEmpty() ?
			false :
			(cells.first().checkState() === CheckState.Checked);
	}

	get q(): DataTable {
		return <DataTable>super.q;
	}

	setProgressIndicatorVisible(visible: boolean): void {
		if (!this.progressIndicator) {
			return;
		}
		if (visible) {
			this.ctrl.showProgress();
		} else {
			this.ctrl.hideProgress();
		}
	}

	setRowCheckState(row: number, state: CheckState, silent: boolean): void {
		// Assumes row index is valid (no checks made)
		const el = (row >= 0) ?
			this.bodyRows().at(row) :
			this.headerRow();
		if (!el) {
			return;
		}
		// Relying on the invariant that each row has 1 cell
		const obj = el.children().at(0);
		let blocked = false;
		if (silent) {
			blocked = obj.blockSignals(true);
		}
		obj.setCheckState(state);
		if (silent) {
			obj.blockSignals(blocked);
		}
	}

	setSortEnabled(enabled: boolean): void {
		this.ctrl.destroy();
		this.horizontalHeader.setSortIndicatorShown(enabled);
		this.ctrl = new MDCDataTable(<Element>this.q.element());
	}

	setup(opts: Partial<DataTableOpts>): void {
		const q = this.q;
		this.horizontalHeader.setParent(
			this.container.table(),
			0,
		);
		this.ctrl.destroy();
		this.container.setParent(q);
		this.container.show();
		if (opts.progressIndicator) {
			if (!this.progressIndicator) {
				this.progressIndicator = new ProgressIndicator();
			}
			this.progressIndicator.setParent(q);
		}
		this.ctrl = new MDCDataTable(q.element());
	}

	showRowSelected(row: number, selected: boolean): void {
		let el: Row | null;
		if (row === -1) {
			// Header row
			el = this.headerRow();
		} else {
			// Body row
			const rows = this.bodyRows();
			if (!((row >= 0) && (row < rows.size()))) {
				return;
			}
			el = rows.at(row);
		}
		if (el) {
			el.setClass(
				selected,
				el.selectedCssClassName(),
			);
		}
	}
}

export interface DataTableOpts extends ElObjOpts {
	dataCellPrototype: PrototypeConstructor<DataCell>;
	dd: DataTablePrivate;
	headerCellPrototype: PrototypeConstructor<HeaderCell>;
	headerInput: boolean;
	progressIndicator: boolean;
	stickyHeader: boolean;
}

@OBJ
export class DataTable extends ElObj {
	constructor(opts: Partial<DataTableOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table',
			'lb-data-table',
		);
		opts.dd = opts.dd || new DataTablePrivate();
		if (opts.progressIndicator === undefined) {
			opts.progressIndicator = true;
		}
		super(opts);
		if (opts.headerCellPrototype) {
			opts.dd.headerCellPrototype = opts.headerCellPrototype;
		}
		if (opts.dataCellPrototype) {
			opts.dd.dataCellPrototype = opts.dataCellPrototype;
		}
		if (opts.headerInput) {
			this.setHeaderInputEnabled(true);
		}
		if (opts.stickyHeader) {
			this.setStickyHeader(true);
		}
		opts.dd.setup(opts);
	}

	cell(row: number, column: number): Cell | null {
		return this.dataCell(row, column);
	}

	@SIGNAL
	protected cellCheckStateChanged(row: number, column: number, state: number): void {
	}

	@SLOT
	protected _cellCheckStateChanged(row: number, column: number, state: number): void {
		if (column !== 0) {
			logger.error(
				'_cellCheckStateChanged: Got invalid index (%s, %s) for state change (%s (%s))',
				row,
				column,
				state,
				CheckState[state],
			);
			return;
		}
		const d = this.d;
		// Ensure, for our purposes, check is always checked or unchecked.
		state = (state === CheckState.Checked) ?
			state :
			CheckState.Unchecked;
		const selected = state === CheckState.Checked;
		if (row >= 0) {
			// Body cell
			if ((state === CheckState.Unchecked) && d.isSelectAllChecked()) {
				// Before this activity, all rows had been checked via the
				// select-all toggle. Since we're about to uncheck one of
				// those, all things are no longer selected.
				const hdrow = d.headerRow();
				d.setRowCheckState(
					-1,
					CheckState.Unchecked,
					true,
				);
			}
			this.rowSelectionChanged(
				row,
				selected,
			);
		} else {
			// Header cell (select all toggle)
			const rowsToUpdate = selected ?
				d.deselectedBodyRowIndices() :
				new set(this.selectedRows());
			for (const row of rowsToUpdate) {
				d.setRowCheckState(
					row,
					state,
					true,
				);
			}
			this.selectAllRowsChanged(
				selected,
			);
		}
	}

	@SIGNAL
	protected cellClicked(row: number, column: number, evt: MouseEvt): void {
	}

	@SIGNAL
	protected cellDoubleClicked(row: number, column: number): void {
	}

	@SIGNAL
	protected cellInputIconActivated(row: number, column: number, text: string, position: TextInputIconPosition): void {
	}

	@SIGNAL
	protected cellInputReturnPressed(row: number, column: number, text: string): void {
	}

	@SIGNAL
	protected cellInputTextChanged(row: number, column: number, text: string): void {
	}

	clear(): void {
		for (const cell of this.d.headerCells()) {
			cell.clear();
		}
		this.clearContents();
	}

	clearContents(): void {
		for (const row of this.d.bodyRows()) {
			for (const cell of row.children()) {
				cell.clear();
			}
		}
	}

	columnCount(): number {
		const d = this.d;
		const rv = d.headerCells().size();
		// NB: Account for additional checkbox column at index 0
		return d.rowsCheckable ?
			rv - 1 :
			rv;
	}

	columnSelect(): ColumnSelect | null {
		return this.d.columnSelect;
	}

	protected connectCell(cell: Cell): void {
		Obj.connect(
			cell, 'checkStateChanged',
			this, 'cellCheckStateChanged',
		);
		if (cell.isInputEnabled()) {
			Obj.connect(
				cell, 'inputTextChanged',
				this, 'cellInputTextChanged',
			);
			Obj.connect(
				cell, 'inputReturnPressed',
				this, 'cellInputReturnPressed',
			);
			Obj.connect(
				cell, 'inputIconActivated',
				this, 'cellInputIconActivated',
			);
		}
	}

	get d(): DataTablePrivate {
		return <DataTablePrivate>super.d;
	}

	protected dataCell(row: number, column: number): DataCell | null {
		const d = this.d;
		if ((row >= 0) && (row < this.rowCount()) && (column >= 0) && (column < this.columnCount())) {
			if (d.rowsCheckable) {
				// NB: Account for additional checkbox column at index 0
				++column;
			}
			return d.bodyRows().at(row).children().at(column);
		}
		return null;
	}

	cellAt(pos: Point): Cell | null {
		const d = this.d;
		const body = d.body();
		if (body) {
			return body.cellAt(pos);
		}
		return null;
	}

	destroy(): void {
		const d = this.d;
		if (d.columnSelect) {
			d.columnSelect.destroy();
		}
		d.columnSelect = null;
		d.ctrl.destroy();
		if (d.progressIndicator) {
			d.progressIndicator.destroy();
		}
		d.progressIndicator = null;
		if (d.pagination) {
			d.pagination.destroy();
		}
		d.pagination = null;
		d.container.destroy();
		d.dataCellPrototype = DataCell;
		d.headerCellPrototype = HeaderCell;
		d.rowsCheckable = false;
		super.destroy();
	}

	focusHeaderInput(column: number): void {
		const cell = this.headerCell(column);
		if (cell) {
			cell.focusInput();
		}
	}

	protected headerCell(column: number): HeaderCell | null {
		const d = this.d;
		if ((column >= 0) && (column < this.columnCount())) {
			if (d.rowsCheckable) {
				// NB: Account for additional checkbox column at index 0
				++column;
			}
			return d.headerCells().at(column);
		}
		return null;
	}

	headerInputText(column: number): string {
		const cell = this.headerCell(column);
		if (cell) {
			return cell.inputText();
		}
		return '';
	}

	hideProgressIndicator(): void {
		this.setProgressIndicatorVisible(false);
	}

	horizontalHeader(): Head {
		return this.d.horizontalHeader;
	}

	insertColumns(index: number, count: number = 1): boolean {
		const d = this.d;
		if ((count < 1) || (index < 0) || (index > this.columnCount())) {
			return false;
		}
		if (d.rowsCheckable) {
			// NB: Account for additional checkbox column at index 0
			++index;
		}
		return this._insertColumns(index, count);
	}

	private _insertColumns(index: number, count: number): boolean {
		const d = this.d;
		const header = this.horizontalHeader();
		const checkable = d.rowsCheckable;
		const sortShown = header.isSortIndicatorShown();
		for (let column = index; column < (index + count); ++column) {
			const cell = this.newHeaderCell();
			if (checkable && (column === 0)) {
				cell.setCheckboxShown(true);
			} else {
				if (sortShown) {
					cell.setSortIndicatorShown(true);
				}
				if (d.headerInput) {
					cell.setInputEnabled(true);
				}
			}
			this.connectCell(cell);
			header.insertCell(column, cell);
		}
		for (const row of d.bodyRows()) {
			for (let column = index; column < (index + count); ++column) {
				const cell = this.newDataCell();
				cell.show();
				if (checkable && (column === 0)) {
					cell.setCheckboxShown(true);
				}
				this.connectCell(cell);
				row.insertCell(column, cell);
			}
		}
		return true;
	}

	insertRows(index: number, count: number = 1): boolean {
		const d = this.d;
		if ((count < 1) || (index < 0) || (index > this.rowCount())) {
			return false;
		}
		const body = d.body();
		if (!body) {
			return false;
		}
		// NB: columnCount here DOES NOT take into account whether
		//     rowCheckable is enabled. This is because we're filling in the
		//     entire row which would also include a cell at index 0 when
		//     rowsCheckable is enabled.
		const checkable = d.rowsCheckable;
		const columnCount = (this.columnCount() + (checkable ? 1 : 0));
		for (let row = index; row < (index + count); ++row) {
			const el = new BodyRow();
			for (let column = 0; column < columnCount; ++column) {
				const cell = this.newDataCell();
				cell.show();
				if (checkable && (column === 0)) {
					cell.setCheckboxShown(true);
				}
				this.connectCell(cell);
				el.insertCell(column, cell);
			}
			body.insertRow(row, el);
		}
		return true;
	}

	protected newDataCell(): DataCell {
		return new this.d.dataCellPrototype();
	}

	protected newHeaderCell(): HeaderCell {
		return new this.d.headerCellPrototype();
	}

	pagination(): Pagination | null {
		return this.d.pagination;
	}

	removeColumns(index: number, count: number = 1): boolean {
		const d = this.d;
		if ((count < 1) || (index < 0) || ((index + count) > this.columnCount())) {
			return false;
		}
		if (d.rowsCheckable) {
			// NB: Account for additional checkbox column at index 0
			++index;
		}
		return this._removeColumns(index, count);
	}

	private _removeColumns(index: number, count: number): boolean {
		const d = this.d;
		for (const el of d.bodyRows()) {
			for (let column = (index + count - 1); column >= index; --column) {
				el.removeCell(column);
			}
		}
		const el = d.horizontalHeader;
		for (let column = (index + count - 1); column >= index; --column) {
			el.removeCell(column);
		}
		return true;
	}

	removeRows(index: number, count: number = 1): boolean {
		const d = this.d;
		if ((count < 1) || (index < 0) || ((index + count) > this.rowCount())) {
			return false;
		}
		const body = d.body();
		if (!body) {
			return false;
		}
		for (let row = (index + count - 1); row >= index; --row) {
			body.removeRow(row);
		}
		return true;
	}

	rowCount(): number {
		return this.d.bodyRows().size();
	}

	rowsCheckable(): boolean {
		return this.d.rowsCheckable;
	}

	@SIGNAL
	rowSelectionChanged(row: number, selected: boolean): void {
	}

	async scrollToRow(row: number): Promise<void> {
		const cell = this.dataCell(row, 0);
		if (!cell) {
			return;
		}
		const d = this.d;
		const contEl = d.container.element();
		if (!contEl) {
			logger.error('Container element missing.');
			return;
		}
		const vis = await d.isElVisible(cell);
		if (!vis.valid || vis.visible) {
			return;
		}
		const dy = vis.targetRect.top - vis.rootRect.top;
		contEl.scrollTo({
			top: contEl.scrollTop + dy,
		});
	}

	@SIGNAL
	selectAllRowsChanged(selectAll: boolean): void {
	}

	selectedRows(): list<number> {
		const d = this.d;
		const rv = new list<number>();
		if (!d.rowsCheckable) {
			return rv;
		}
		const rows = d.bodyRows();
		for (let i = 0; i < rows.size(); ++i) {
			const row = rows.at(i);
			if (!row.children().isEmpty() && (row.children().first().checkState() === CheckState.Checked)) {
				rv.append(i);
			}
		}
		return rv;
	}

	setCellData(row: number, column: number, data: Variant, role: ItemDataRole): void {
		// NB: dataCell() accounts for rowsCheckable
		const cell = this.dataCell(row, column);
		if (cell) {
			cell.setData(data, role);
		}
	}

	setColumnCount(count: number): void {
		const columnCount = this.columnCount();
		if ((count < 0) || (columnCount === count)) {
			return;
		}
		if (columnCount < count) {
			// NB: insertColumn() accounts for rowsCheckable
			this.insertColumns(
				Math.max(
					columnCount,
					0,
				),
				count - columnCount,
			);
		} else {
			// NB: removeColumns() accounts for rowsCheckable
			this.removeColumns(
				Math.max(
					count,
					0,
				),
				columnCount - count,
			);
		}
	}

	setColumnSelect(columnSelect: ColumnSelect | null): void {
		const d = this.d;
		if (columnSelect === d.columnSelect) {
			return;
		}
		if (d.columnSelect) {
			d.columnSelect.destroy();
		}
		d.columnSelect = columnSelect;
		if (d.columnSelect) {
			d.columnSelect.setParent(d.container);
		}
	}

	setHeaderCellData(column: number, data: Variant, role: ItemDataRole): void {
		// NB: headerCell() accounts for rowsCheckable
		const cell = this.headerCell(column);
		if (cell) {
			cell.setData(data, role);
		}
	}

	setHeaderInputEnabled(enabled: boolean): void {
		const d = this.d;
		if (enabled === d.headerInput) {
			return;
		}
		d.headerInput = enabled;
		// NB: columnCount() accounts for rowsCheckable
		for (let i = 0; i < this.columnCount(); ++i) {
			// NB: headerCell() accounts for rowsCheckable
			const cell = this.headerCell(i);
			if (cell) {
				cell.setInputEnabled(d.headerInput);
				if (d.headerInput) {
					Obj.connect(
						cell, 'inputTextChanged',
						this, 'cellInputTextChanged',
					);
					Obj.connect(
						cell, 'inputReturnPressed',
						this, 'cellInputReturnPressed',
					);
					Obj.connect(
						cell, 'inputIconActivated',
						this, 'cellInputIconActivated',
					);
				} else {
					Obj.disconnect(
						cell, 'inputTextChanged',
						this, 'cellInputTextChanged',
					);
					Obj.disconnect(
						cell, 'inputReturnPressed',
						this, 'cellInputReturnPressed',
					);
					Obj.disconnect(
						cell, 'inputIconActivated',
						this, 'cellInputIconActivated',
					);
				}
			}
		}
	}

	setHeaderInputText(column: number, text: string): void {
		// NB: headerCell() accounts for rowsCheckable
		const cell = this.headerCell(column);
		if (cell) {
			cell.setInputText(text);
		}
	}

	setPagination(pagination: Pagination | null): void {
		const d = this.d;
		if (pagination === d.pagination) {
			return;
		}
		if (d.pagination) {
			d.pagination.destroy();
		}
		d.pagination = pagination;
		if (d.pagination) {
			if (d.progressIndicator) {
				const progIndIdx = d.children.indexOf(d.progressIndicator);
				d.pagination.setParent(
					this,
					progIndIdx,
				);
			} else {
				d.pagination.setParent(this);
			}
		}
	}

	setPagination2(pagination: Pagination2): void {
		const d = this.d;
		// if (pagination === d.pagination) {
		// 	return;
		// }
		// if (d.pagination) {
		// 	d.pagination.destroy();
		// }
		// d.pagination = pagination;
		if (d.progressIndicator) {
			const progIndIdx = d.children.indexOf(d.progressIndicator);
			pagination.setParent(
				this,
				progIndIdx,
			);
		} else {
			pagination.setParent(this);
		}
	}

	setProgressIndicatorVisible(visible: boolean): void {
		this.d.setProgressIndicatorVisible(visible);
	}

	setRowCount(count: number): void {
		const rowCount = this.rowCount();
		if ((count < 0) || (rowCount === count)) {
			return;
		}
		if (rowCount < count) {
			this.insertRows(
				Math.max(
					rowCount,
					0,
				),
				count - rowCount,
			);
		} else {
			this.removeRows(
				Math.max(
					count,
					0,
				),
				rowCount - count,
			);
		}
	}

	setRowsCheckable(checkable: boolean): void {
		const d = this.d;
		if (checkable === d.rowsCheckable) {
			return;
		}
		const wasCheckable = d.rowsCheckable;
		const wasSelected = wasCheckable ?
			this.selectedRows() :
			[];
		d.rowsCheckable = checkable;
		if (wasCheckable) {
			// Style any selected columns back to their default
			for (const idx of wasSelected) {
				d.showRowSelected(idx, false);
			}
			// Remove existing checkbox column
			Obj.disconnect(
				this,
				'cellCheckStateChanged',
				this,
				'_cellCheckStateChanged',
			);
			Obj.disconnect(
				this,
				'rowSelectionChanged',
				this,
				'showRowSelected',
			);
			Obj.disconnect(
				this,
				'selectAllRowsChanged',
				this,
				'showAllRowsSelected',
			);
			this._removeColumns(0, 1);
		} else {
			// Insert the checkbox column
			Obj.connect(
				this,
				'cellCheckStateChanged',
				this,
				'_cellCheckStateChanged',
			);
			Obj.connect(
				this,
				'rowSelectionChanged',
				this,
				'showRowSelected',
			);
			Obj.connect(
				this,
				'selectAllRowsChanged',
				this,
				'showAllRowsSelected',
			);
			this._insertColumns(0, 1);
		}
	}

	setSortingEnabled(enabled: boolean): void {
		this.d.setSortEnabled(enabled);
	}

	setStickyHeader(sticky: boolean): void {
		this.setClass(
			sticky,
			'mdc-data-table--sticky-header',
		);
	}

	@SLOT
	setVisualSortIndicator(column: number, order: SortOrder): void {
		const d = this.d;
		if (d.rowsCheckable) {
			// NB: Account for additional checkbox column at index 0
			++column;
		}
		d.ctrl.setSort(
			column,
			order,
			null,
			false,
		);
	}

	@SLOT
	showAllRowsSelected(selected: boolean): void {
		const d = this.d;
		for (let i = 0; i < this.rowCount(); ++i) {
			d.showRowSelected(i, selected);
		}
	}

	showProgressIndicator(): void {
		this.setProgressIndicatorVisible(true);
	}

	@SLOT
	showRowSelected(row: number, selected: boolean): void {
		this.d.showRowSelected(row, selected);
	}
}

@OBJ
class Container extends ElObj {
	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__table-container',
		);
		super(opts);
		const obj = new Table({
			parent: this,
		});
		obj.show();
	}

	table(): Table {
		const obj = this.children().isEmpty() ?
			null :
			this.children().first();
		assert(obj && (obj instanceof Table));
		return obj;
	}
}

interface ElVisObj {
	rootRect: DOMRect;
	targetRect: DOMRect;
	valid: boolean;
	visible: boolean;
}

function elVis(rootEl: ElObj | Element, tgtEl: ElObj | Element, threshold: number, margins: {top: number; right: number; bottom: number; left: number;}): Promise<ElVisObj> {
	const rv: ElVisObj = {
		rootRect: staticRect,
		targetRect: staticRect,
		valid: false,
		visible: false,
	};
	const root = (rootEl instanceof ElObj) ?
		rootEl.element() :
		rootEl;
	if (!root) {
		return Promise.resolve(rv);
	}
	const tgt = (tgtEl instanceof ElObj) ?
		tgtEl.element() :
		tgtEl;
	if (!tgt) {
		return Promise.resolve(rv);
	}
	const opts: Partial<IntersectionObserverInit> = {
		root,
		rootMargin: `${margins.top}px ${margins.right}px ${margins.bottom}px ${margins.left}px`,
		threshold,
	};
	return new Promise(resolve => {
		const cb = (entries: Array<IntersectionObserverEntry>) => {
			if (entries.length > 0) {
				const ent = entries[0];
				let valid = true;
				if (ent.rootBounds) {
					rv.rootRect = ent.rootBounds;
				} else {
					valid = false;
				}
				rv.targetRect = ent.boundingClientRect;
				rv.visible = ent.isIntersecting;
				rv.valid = valid;
				resolve(rv);
			} else {
				resolve(rv);
			}
			obs.disconnect();
		};
		const obs = new IntersectionObserver(cb, opts);
		obs.observe(tgt);
	});
}
