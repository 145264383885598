import {list} from './list';

export class set<T> {
	static fromArray<T>(data: Array<T>): set<T> {
		return new this(data);
	}

	static fromList<T>(data: list<T>): set<T> {
		return new this(data);
	}

	protected _data: Set<T>;

	constructor(iterable?: Iterable<T> | null) {
		this._data = new Set<T>(iterable);
	}

	get size(): number {
		return this._data.size;
	}

	add(value: T): void {
		this._data.add(value);
	}

	clear(): void {
		return this._data.clear();
	}

	copy(): set<T> {
		return new set(this);
	}

	destroy(): void {
		this.clear();
	}

	difference(other: SetType<T>): set<T> {
		const me = new set<T>(this);
		for (const item of other) {
			me.discard(item);
		}
		return me;
	}

	discard(value: T): boolean {
		return this._data.delete(value);
	}

	eq(other: SetType<T>): boolean {
		if (this.size !== other.size) {
			return false;
		}
		for (const val of this) {
			if (!other.has(val)) {
				return false;
			}
		}
		return true;
	}

	has(value: T): boolean {
		return this._data.has(value);
	}

	intersection(other: SetType<T>): set<T> {
		const rv = new set<T>();
		for (const item of other) {
			if (this.has(item)) {
				rv.add(item);
			}
		}
		return rv;
	}

	isdisjoint(other: SetType<T>): boolean {
		for (const item of other) {
			if (this.has(item)) {
				return false;
			}
		}
		return true;
	}

	isEmpty(): boolean {
		return this.size < 1;
	}

	issubset(other: SetType<T>): boolean {
		for (const item of this) {
			if (!other.has(item)) {
				return false;
			}
		}
		return true;
	}

	issuperset(other: SetType<T>): boolean {
		for (const item of other) {
			if (!this.has(item)) {
				return false;
			}
		}
		return true;
	}

	symmetricdifference(other: SetType<T>): set<T> {
		const rv = new set<T>(this);
		for (const item of other) {
			if (rv.has(item)) {
				rv.discard(item);
			} else {
				rv.add(item);
			}
		}
		return rv;
	}

	toArray(): T[] {
		return Array.from(this);
	}

	toList(): list<T> {
		return new list(this);
	}

	toString(): string {
		const vals = this.toArray();
		return `{${vals}}`;
	}

	union(other: SetType<T>): set<T> {
		const rv = new set<T>(this);
		for (const item of other) {
			rv.add(item);
		}
		return rv;
	}

	update(iterable: Iterable<T>): void {
		for (const obj of iterable) {
			this.add(obj);
		}
	}

	get [Symbol.toStringTag](): string {
		return 'set';
	}

	*[Symbol.iterator](): IterableIterator<T> {
		yield *this._data[Symbol.iterator]();
	}
}

type SetType<T> = set<T> | Set<T>;
