import {OBJ} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import {numberFormat} from '../../util';
import {FancyList, FancyListItem, FancyListOpts} from '../../ui/list';
import {getLogger} from '../../logging';
import {CircularProgress} from '../../ui/progress';

const logger = getLogger('projectdetailview.stats');

interface ProjectDetailStatsOpts extends ElObjOpts {
	heading: string;
}

@OBJ
export class ProjectDetailStats extends ElObj {
	private header: Header;
	private list: StatsList;

	constructor(opts: Partial<ProjectDetailStatsOpts> = {}) {
		super(opts);
		this.header = new Header({
			parent: this,
		});
		this.header.show();
		this.setHeading(opts.heading || 'Stats');
		this.list = new StatsList({
			parent: this,
		});
		this.list.show();
	}

	beginSync(): void {
		this.list.beginSync();
	}

	endSync(): void {
		this.list.endSync();
	}

	heading(): string {
		return this.header.text();
	}

	setHeading(heading: string): void {
		this.header.setText(heading);
	}

	setAddressCount(count: number): void {
		this.list.setAddressCount(count);
	}

	setPrice(price: number | string): void {
		this.list.setPrice(price);
	}
}

@OBJ
class Header extends ElObj {
	static tagName: TagName = 'h5';

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-list-group__subheader',
		);
		super(opts);
	}
}

@OBJ
class StatsList extends FancyList {
	private addressCountItem: FancyListItem;
	private addressCountItemProgress: CircularProgress | null;
	private priceItem: FancyListItem;
	private priceItemProgress: CircularProgress | null;

	constructor(opts: Partial<FancyListOpts> = {}) {
		opts.twoLine = true;
		super(opts);
		this.addressCountItemProgress = null;
		this.addItem({
			leadingIcon: {name: 'mail'},
			secondaryText: 'unique mailing addresses',
			text: '---',
			twoLine: true,
		});
		let item = this.item(0);
		if (!item) {
			logger.error('StatsList: Unable to add list items');
			item = new FancyListItem();
		}
		this.addressCountItem = item;
		this.priceItemProgress = null;
		this.addItem({
			leadingIcon: {name: 'attach_money'},
			secondaryText: 'total price',
			text: '---',
			twoLine: true,
		});
		item = this.item(1);
		if (!item) {
			logger.error('StatsList: Unable to add list items');
			item = new FancyListItem();
		}
		item.show();
		this.priceItem = item;
	}

	beginSync(): void {
		this.destroyProgress();
		this.addressCountItem.addClass('lb-list-item--sync');
		this.addressCountItem.setPrimaryText('---');
		this.addressCountItemProgress = new CircularProgress({indeterminate: true, size: 'small'});
		this.addressCountItem.setLeadingObj(this.addressCountItemProgress);
		this.addressCountItemProgress.show();
		this.priceItem.addClass('lb-list-item--sync');
		this.priceItem.setPrimaryText('---');
		this.priceItemProgress = new CircularProgress({indeterminate: true, size: 'small'});
		this.priceItem.setLeadingObj(this.priceItemProgress);
		this.priceItemProgress.show();
	}

	destroy(): void {
		this.destroyProgress();
		this.addressCountItem.destroy();
		super.destroy();
	}

	private destroyProgress(): void {
		if (this.addressCountItemProgress) {
			this.addressCountItemProgress.destroy();
		}
		this.addressCountItemProgress = null;
		if (this.priceItemProgress) {
			this.priceItemProgress.destroy();
		}
		this.priceItemProgress = null;
	}

	endSync(): void {
		if (this.addressCountItemProgress) {
			this.addressCountItemProgress.close();
		}
		this.destroyProgress();
		this.addressCountItem.setLeadingIcon('mail');
		this.addressCountItem.removeClass('lb-list-item--sync');
		if (this.priceItemProgress) {
			this.priceItemProgress.close();
		}
		this.destroyProgress();
		this.priceItem.setLeadingIcon('attach_money');
		this.priceItem.removeClass('lb-list-item--sync');
	}

	setAddressCount(count: number): void {
		this.addressCountItem.setPrimaryText(numberFormat(count));
	}

	setPrice(price: number | string): void {
		this.priceItem.setPrimaryText(`$${numberFormat(price)}`);
	}
}
