import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {OBJ, SIGNAL, SLOT} from '../../obj';
import {CloseMode, DialogCode, ElAttr, Key, KeyboardModifier, Orientation} from '../../constants';
import {CloseEvt, KeyEvt} from '../../evt';
import {PushButton} from '../pushbutton';

export const dialogElementId = 'id_lb-dialog';

export class DialogBasePrivate extends ElObjPrivate {
	doShowExtension: boolean;
	extension: ElObj | null;
	mainDef: PushButton | null;
	orientation: Orientation;
	rescode: number;
	resizer: ElObj | null;
	sizeGripEnabled: boolean;

	constructor() {
		super();
		this.doShowExtension = false;
		this.extension = null;
		this.isWin = true;
		this.mainDef = null;
		this.orientation = Orientation.Horizontal;
		this.rescode = -1;
		this.resizer = null;
		this.sizeGripEnabled = false;
	}

	finalize(resultCode: number, dialogCode: number): void {
		const q = this.q;
		if (dialogCode === DialogCode.Accepted) {
			q.accepted();
		} else if (dialogCode === DialogCode.Rejected) {
			q.rejected();
		}
		q.finished(resultCode);
	}

	hide(resultCode: number): void {
		const q = this.q;
		q.setResult(resultCode);
		q.hide();
		this.closeHelper(CloseMode.CloseNoEvent);
	}

	hideDefault(): void {
		const q = this.q;
		for (const child of q.findChildren(PushButton)) {
			(<PushButton>child).setDefault(false);
		}
	}

	init(opts: Partial<DialogBaseOpts>): void {
		if (opts.tmpAppendToDocBody === undefined) {
			opts.tmpAppendToDocBody = true;
		}
		super.init(opts);
		const q = this.q;
		q.addEventListener('keydown');
		q.addEventListener('keyup');
	}

	get q(): DialogBase {
		return <DialogBase>super.q;
	}

	setDefault(def: PushButton | null): void {
		const q = this.q;
		let hasMain = false;
		for (const child of q.findChildren(PushButton)) {
			const pb = <PushButton>child;
			if (pb.window() === q) {
				if (pb === this.mainDef) {
					hasMain = true;
				}
				if (pb !== def) {
					pb.setDefault(false);
				}
			}
		}
		if (!def && hasMain) {
			// assertion: hasMain is only true when mainDef is present.
			(<PushButton>this.mainDef).setDefault(true);
		}
		if (!hasMain) {
			this.mainDef = def;
		}
	}

	setMainDefault(def: PushButton | null): void {
		this.mainDef = null;
		this.setDefault(def);
	}
}

export interface DialogBaseOpts extends ElObjOpts {
	dd: DialogBasePrivate;
}

@OBJ
export class DialogBase extends ElObj {
	constructor(opts: Partial<DialogBaseOpts> = {}) {
		opts.attributes = ElObj.mergeAttributes(
			opts.attributes,
			['id', dialogElementId],
		);
		opts.dd = opts.dd || new DialogBasePrivate();
		super(opts);
	}

	@SLOT
	accept(): void {
		this.done(DialogCode.Accepted);
	}

	@SIGNAL
	accepted(): void {
	}

	protected closeEvent(event: CloseEvt): void {
		if (this.isVisible()) {
			this.reject();
			if (this.isVisible()) {
				event.ignore();
			}
		} else {
			event.accept();
		}
	}

	get d(): DialogBasePrivate {
		return <DialogBasePrivate>super.d;
	}

	destroy(): void {
		try {
			this.hide();
		} catch {
			// noop
		}
		super.destroy();
	}

	@SLOT
	done(result: number): void {
		const d = this.d;
		d.hide(result);
		d.finalize(result, result);
	}

	@SIGNAL
	finished(result: number): void {
	}

	isSizeGripEnabled(): boolean {
		return !!this.d.resizer;
	}

	protected keyPressEvent(event: KeyEvt): void {
		if (event.key() === Key.Escape) {
			this.reject();
		} else if (!event.modifiers() || ((event.modifiers() & KeyboardModifier.KeypadModifier) && (event.key() === Key.Enter))) {
			switch (event.key()) {
				case Key.Enter: {
					for (const child of this.findChildren(PushButton)) {
						const pb = <PushButton>child;
						if (pb.isDefault() && pb.isVisible()) {
							if (pb.isEnabled()) {
								pb.click();
							}
							return;
						}
					}
					break;
				}
				default: {
					event.ignore();
					return;
				}
			}
		} else {
			event.ignore();
		}
	}

	@SLOT
	open(): void {
		this.setResult(0);
		this.show();
	}

	@SLOT
	reject(): void {
		this.done(DialogCode.Rejected);
	}

	@SIGNAL
	rejected(): void {
	}

	result(): number {
		return this.d.rescode;
	}

	setResult(result: number): void {
		this.d.rescode = result;
	}

	setSizeGripEnabled(enable: boolean): void {
		const d = this.d;
		d.sizeGripEnabled = enable;
		if (d.sizeGripEnabled && d.doShowExtension) {
			return;
		}
		if (!d.sizeGripEnabled !== !this.isSizeGripEnabled()) {
			if (d.sizeGripEnabled) {
				// Initialize size grip
			} else {
				d.resizer = null;
			}
		}
	}

	setVisible(visible: boolean): void {
		const d = this.d;
		if (visible) {
			if (this.testAttribute(ElAttr.ExplicitShowHide) && !this.testAttribute(ElAttr.Hidden)) {
				return;
			}
			super.setVisible(visible);
			if (d.mainDef) {
				d.mainDef.focus();
			}
			if (!d.mainDef && this.isWindow()) {
				for (const el of this.children()) {
					if ((el instanceof PushButton) && el.autoDefault()) {
						el.setDefault(true);
						break;
					}
				}
			}

		} else {
			if (this.testAttribute(ElAttr.ExplicitShowHide) && this.testAttribute(ElAttr.Hidden)) {
				return;
			}
			super.setVisible(visible);
		}
	}
}
