import {ElObj, ElObjOpts, ElObjPrivate} from '../../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../../obj';
import {ComboBox} from '../../ui/combobox';
import {TextInput} from '../../ui/textinput';
import {FancyPushButton} from '../../ui/pushbutton';

export class EditViewPrivate extends ElObjPrivate {
	clearBtn: FancyPushButton | null;
	combo: ComboBox | null;
	lineEdit: TextInput | null;
	submitBtn: FancyPushButton | null;

	constructor() {
		super();
		this.clearBtn = null;
		this.combo = null;
		this.lineEdit = null;
		this.submitBtn = null;
	}

	init(opts: Partial<EditViewOpts>): void {
		super.init(opts);
		const q = this.q;
		let row = new ElObj({
			classNames: [
				'lb-edit-view-row',
			],
			parent: q,
		});
		this.combo = new ComboBox({
			classNames: [
				'lb-edit-view-ctrl',
			],
			parent: row,
		});
		this.lineEdit = new TextInput({
			classNames: [
				'lb-edit-view-ctrl',
			],
			parent: row,
			underlinedLessDense: true,
		});
		Obj.connect(
			this.lineEdit, 'returnPressed',
			q, 'submitted',
		);
		row = new ElObj({
			classNames: [
				'lb-edit-view-row',
				'lb-edit-view-btn-row',
			],
			parent: q,
		});

		this.clearBtn = new FancyPushButton({
			filled: true,
			parent: row,
			text: 'Clear',
		});
		this.clearBtn.setDefault(true);
		Obj.connect(
			this.clearBtn, 'clicked',
			q, 'clearContents',
		);
		this.submitBtn = new FancyPushButton({
			parent: row,
			text: 'Submit Changes',
		});
		Obj.connect(
			this.submitBtn, 'clicked',
			q, 'submitted',
		);
	}

	get q(): EditView {
		return <EditView>super.q;
	}
}

export interface EditViewOpts extends ElObjOpts {
	dd: EditViewPrivate;
}

@OBJ
export class EditView extends ElObj {
	constructor(opts: Partial<EditViewOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-edit-view',
		);
		opts.dd = opts.dd || new EditViewPrivate();
		super(opts);
	}

	@SLOT
	clearContents(): void {
		const d = this.d;
		if (d.combo) {
			const idx = d.combo.index('');
			if (idx >= 0) {
				const blocked = d.combo.blockSignals(true);
				d.combo.setCurrentIndex(idx);
				d.combo.blockSignals(blocked);
			}
		}
		if (d.lineEdit) {
			const blocked = d.lineEdit.blockSignals(true);
			d.lineEdit.clear();
			d.lineEdit.blockSignals(blocked);
		}
	}

	get d(): EditViewPrivate {
		return <EditViewPrivate>super.d;
	}

	comboBox(): ComboBox | null {
		return this.d.combo;
	}

	lineEdit(): TextInput | null {
		return this.d.lineEdit;
	}

	@SIGNAL
	protected submitted(): void {
	}
}
