import {ElObj, ElObjOpts, ElObjPrivate} from '../elobj';
import {Obj, OBJ, SIGNAL, SLOT} from '../obj';
import {ToolBar} from './toolbar';
import {Point, Size} from '../tools';
import {AlignmentFlag, CloseMode, ElAttr, Key, MouseButton, WindowState} from '../constants';
import {CloseEvt, MouseEvt, ShowEvt} from '../evt';
import {clamp, isNumber, pixelString} from '../util';
import {Icon} from './icon';
import {Action} from '../action';

interface DragInfo {
	h: number;
	minX: number;
	w: number;
	x: number;
	y: number;
	startX: number;
	startY: number;
}

export class ElWinPrivate extends ElObjPrivate {
	private static maxZ: number = 0;

	private static setMaxZ(z: number): void {
		if (isNumber(z) && (z > this.maxZ)) {
			this.maxZ = z;
		}
	}

	closeAxn: Action | null;
	drag: DragInfo;
	firstShown: boolean;
	heightBeforeMinimized: number;
	maxH: number;
	maxW: number;
	minH: number;
	minMaxAxn: Action | null;
	minW: number;
	opacity: number;
	polished: boolean;
	state: WindowState;
	title: string;
	topBar: ElWinTopBar | null;

	constructor() {
		super();
		this.closeAxn = null;
		this.drag = {
			h: 0,
			minX: 0,
			w: 0,
			x: 0,
			y: 0,
			startX: 0,
			startY: 0,
		};
		this.firstShown = false;
		this.heightBeforeMinimized = -1;
		this.isWin = true;
		this.maxH = Number.MAX_SAFE_INTEGER;
		this.maxW = Number.MAX_SAFE_INTEGER;
		this.minH = 0;
		this.minMaxAxn = null;
		this.minW = 0;
		this.opacity = 1.0;
		this.polished = false;
		this.state = WindowState.WindowNoState;
		this.title = '';
		this.topBar = null;
	}

	init(opts: Partial<ElWinOpts>): void {
		super.init(opts);
		const q = this.q;
		this.topBar = new ElWinTopBar({
			parent: q,
		});
		Obj.connect(
			this.topBar, 'pressed',
			q, 'raise',
		);
		Obj.connect(
			this.topBar, 'pressed',
			q, 'startDragMove',
		);
		Obj.connect(
			this.topBar, 'released',
			q, 'stopDragMove',
		);
		const toolBar = this.topBar.toolBar();
		if (!toolBar) {
			return;
		}
		this.minMaxAxn = new Action(
			new Icon({name: 'minimize'}),
			q,
		);
		this.minMaxAxn.setCheckable(true);
		this.minMaxAxn.setToolTip('Minimize');
		toolBar.addAction(this.minMaxAxn);
		Obj.connect(
			this.minMaxAxn, 'toggled',
			q, 'setMinimized',
		);
		Obj.connect(
			this.topBar, 'doubleClicked',
			this.minMaxAxn, 'toggle',
		);
		this.closeAxn = new Action(
			new Icon({name: 'close'}),
			q,
		);
		this.closeAxn.setToolTip('Close');
		toolBar.addAction(this.closeAxn);
		Obj.connect(
			this.closeAxn, 'triggered',
			q, 'close',
		);
		const topGrip = new SizeGrip({
			alignment: AlignmentFlag.AlignTop,
			parent: q,
		});
		Obj.connect(
			topGrip, 'pressed',
			q, 'sizeGripPressed',
		);
		Obj.connect(
			topGrip, 'moved',
			q, 'sizeGripMoved',
		);
		const rightGrip = new SizeGrip({
			alignment: AlignmentFlag.AlignRight,
			parent: q,
		});
		Obj.connect(
			rightGrip, 'pressed',
			q, 'sizeGripPressed',
		);
		Obj.connect(
			rightGrip, 'moved',
			q, 'sizeGripMoved',
		);
		const bottomGrip = new SizeGrip({
			alignment: AlignmentFlag.AlignBottom,
			parent: q,
		});
		Obj.connect(
			bottomGrip, 'pressed',
			q, 'sizeGripPressed',
		);
		Obj.connect(
			bottomGrip, 'moved',
			q, 'sizeGripMoved',
		);
		const leftGrip = new SizeGrip({
			alignment: AlignmentFlag.AlignLeft,
			parent: q,
		});
		Obj.connect(
			leftGrip, 'pressed',
			q, 'sizeGripPressed',
		);
		Obj.connect(
			leftGrip, 'moved',
			q, 'sizeGripMoved',
		);
		ElWinPrivate.setMaxZ(
			this.z(),
		);
		q.addEventListener('keydown');
		q.addEventListener('keyup');
		q.addEventListener('mousedown');
		q.addEventListener('mouseup');
	}

	lower(): void {
		this.setZ(
			this.z() - 1,
		);
	}

	polish(): void {
		const q = this.q;
		if (!this.polished) {
			this.polished = true;
			const rect = q.rect();
			if (rect.width > this.maxW) {
				this.setWidthStyle(this.maxW);
			}
			if (rect.width < this.minW) {
				this.setWidthStyle(this.minW);
			}
			if (rect.height > this.maxH) {
				this.setHeightStyle(this.maxH);
			}
			if (rect.height < this.minH) {
				this.setHeightStyle(this.minH);
			}
		}
		if (!this.firstShown) {
			this.firstShown = true;
			q.fitToWindow();
		}
	}

	get q(): ElWin {
		return <ElWin>super.q;
	}

	raiseToTop(): void {
		this.setZ(ElWinPrivate.maxZ + 1);
	}

	setHeightStyle(height: number, noClamp: boolean = false): void {
		(<HTMLElement>this.elem).style.setProperty(
			'height',
			pixelString(
				noClamp ?
					height :
					clamp(
						this.minH,
						height,
						this.maxH,
					),
			),
		);
	}

	setHeightStyleAnim(height: number, noClamp: boolean = false): void {
		window.requestAnimationFrame(
			() => this.setHeightStyle(height, noClamp),
		);
	}

	setOpacityStyle(level: number): void {
		(<HTMLElement>this.elem).style.setProperty(
			'opacity',
			String(
				clamp(
					0,
					level,
					1.0,
				),
			),
		);
	}

	setPosStyle(x: number, y: number): void {
		(<HTMLElement>this.elem).style.setProperty(
			'translate',
			`${pixelString(x)} ${pixelString(y)}`,
		);
	}

	setPosStyleAnim(x: number, y: number): void {
		window.requestAnimationFrame(
			() => this.setPosStyle(x, y),
		);
	}

	setWidthStyle(width: number, noClamp: boolean = false): void {
		(<HTMLElement>this.elem).style.setProperty(
			'width',
			pixelString(
				noClamp ?
					width :
					clamp(
						this.minW,
						width,
						this.maxW,
					),
			),
		);
	}

	setWidthStyleAnim(width: number, noClamp: boolean = false): void {
		window.requestAnimationFrame(
			() => this.setWidthStyle(width, noClamp),
		);
	}

	setZ(z: number): void {
		if (!isNumber(z)) {
			return;
		}
		this.q.setStyleProperty(
			'z-index',
			String(z),
		);
		ElWinPrivate.setMaxZ(z);
	}

	z(): number {
		const style = this.q.computedStyle();
		const zs = style.zIndex;
		if (zs === '') {
			return Number.NaN;
		}
		return Number.parseInt(zs);
	}
}

export interface ElWinOpts extends ElObjOpts {
	dd: ElWinPrivate;
}

@OBJ
export class ElWin extends ElObj {
	constructor(opts: Partial<ElWinOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-el-win',
		);
		opts.dd = opts.dd || new ElWinPrivate();
		super(opts);
		opts.dd.raiseToTop();
	}

	@SLOT
	alert(msec: number = 0): void {
		/**
		 * Causes an alert to be shown for msec milliseconds. If msec is 0
		 * (the default), then the alert is shown indefinitely until the
		 * window becomes active again.
		 *
		 * TODO: Implement the timeout/animation duration
		 */
		this.addClass('alert');
		this.addEventListener('animationend');
	}

	@SLOT
	close(): void {
		this.d.closeHelper(
			CloseMode.CloseWithEvent,
		);
	}

	@SIGNAL
	protected closed(): void {
	}

	protected closeEvent(event: CloseEvt): void {
		this.closed();
	}

	get d(): ElWinPrivate {
		return <ElWinPrivate>super.d;
	}

	protected _domAnimationEndEvent(event: AnimationEvent): void {
		this.removeClass('alert');
		this.removeEventListener('animationend');
	}

	fitToWindow(): void {
		const winH = window.innerHeight;
		const winW = window.innerWidth;
		const rect = this.rect();
		let x = rect.x;
		let y = rect.y;
		if ((rect.x + rect.width) > winW) {
			x = winW - rect.width;
		}
		if ((rect.y + rect.height) > winH) {
			y = winH - rect.height;
		}
		if ((rect.x !== x) || (rect.y !== y)) {
			this.move(x, y);
		}
	}

	height(): number {
		return this.rect().height;
	}

	@SIGNAL
	protected heightChanged(arg: number): void {
	}

	isMinimized(): boolean {
		return this.d.state === WindowState.WindowMinimized;
	}

	@SLOT
	lower(): void {
		this.d.lower();
	}

	maximumHeight(): number {
		return this.d.maxH;
	}

	@SIGNAL
	protected maximumHeightChanged(arg: number): void {
	}

	maximumSize(): Size {
		const d = this.d;
		return new Size(d.maxW, d.maxH);
	}

	maximumWidth(): number {
		return this.d.maxW;
	}

	@SIGNAL
	protected maximumWidthChanged(arg: number): void {
	}

	minimumHeight(): number {
		return this.d.minH;
	}

	@SIGNAL
	protected minimumHeightChanged(arg: number): void {
	}

	minimumSize(): Size {
		const d = this.d;
		return new Size(d.minW, d.minH);
	}

	minimumWidth(): number {
		return this.d.minW;
	}

	@SIGNAL
	protected minimumWidthChanged(arg: number): void {
	}

	protected mouseReleaseEvent(event: MouseEvt): void {
		event.accept();
		if (this.isVisible()) {
			this.raise();
		}
	}

	protected mousePressEvent(event: MouseEvt): void {
		this.raise();
		event.accept();
	}

	move(x: number, y: number): void {
		// FIXME: This was a lazy first implementation. More care is
		//        needed here.
		this.d.setPosStyle(x, y);
	}

	opacity(): number {
		return this.d.opacity;
	}

	@SIGNAL
	protected opacityChanged(level: number): void {
	}

	position(): Point {
		const {x, y} = this.rect();
		return new Point(x, y);
	}

	@SIGNAL
	protected positionChanged(pos: Point): void {
	}

	@SLOT
	raise(): void {
		this.d.raiseToTop();
	}

	resize(width: number, height: number): void;
	resize(size: Size): void;
	resize(a: Size | number, b?: number): void {
		let s: Size;
		if (isNumber(a) && isNumber(b)) {
			s = new Size(a, b);
		} else {
			s = <Size>a;
		}
		if (!s.isValid()) {
			return;
		}
		const d = this.d;
		const rect = this.rect();
		if (s.width() !== rect.width) {
			const w = clamp(
				d.minW,
				s.width(),
				d.maxW,
			);
			if (w !== rect.width) {
				d.setWidthStyle(w);
				this.widthChanged(w);
			}
		}
		if (s.height() !== rect.height) {
			const h = clamp(
				d.minH,
				s.height(),
				d.maxH,
			);
			if (h !== rect.height) {
				d.setHeightStyle(h);
				this.heightChanged(h);
			}
		}
	}

	@SLOT
	setHeight(arg: number): void {
		this.resize(
			new Size(
				this.width(),
				arg,
			),
		);
	}

	@SLOT
	setMaximumHeight(arg: number): void {
		const d = this.d;
		if (!isNumber(arg) || (arg === d.maxH) || (arg < 0)) {
			return;
		}
		this.setMaximumSize(
			new Size(
				d.maxW,
				arg,
			),
		);
	}

	setMaximumSize(arg: Size): void {
		if (!arg.isValid()) {
			return;
		}
		const d = this.d;
		const argW = arg.width();
		if ((argW !== d.maxW) && (argW >= d.minW)) {
			const mw = clamp(
				d.minW,
				argW,
				Number.MAX_SAFE_INTEGER,
			);
			if (mw !== d.maxW) {
				d.maxW = mw;
				if (d.polished && (this.width() > d.maxW)) {
					d.setWidthStyle(d.maxW);
				}
				this.maximumWidthChanged(d.maxW);
			}
		}
		const argH = arg.height();
		if ((argH !== d.maxH) && (argH >= d.minH)) {
			const mh = clamp(
				d.minH,
				argH,
				Number.MAX_SAFE_INTEGER,
			);
			if (mh !== d.maxH) {
				d.maxH = mh;
				if (d.polished && (this.height() > d.maxH)) {
					d.setHeightStyle(d.maxW);
				}
				this.maximumHeightChanged(d.maxH);
			}
		}
	}

	@SLOT
	setMaximumWidth(arg: number): void {
		const d = this.d;
		if (!isNumber(arg) || (arg === d.maxW) || (arg < 0)) {
			return;
		}
		this.setMaximumSize(
			new Size(
				arg,
				d.maxH,
			),
		);
	}

	@SLOT
	setMinimized(minimized: boolean): void {
		this.setWindowState(
			minimized ?
				WindowState.WindowMinimized :
				WindowState.WindowMaximized,
		);
	}

	@SLOT
	setMinimumHeight(arg: number): void {
		const d = this.d;
		if (!isNumber(arg) || (arg === d.minH) || (arg < 0)) {
			return;
		}
		this.setMinimumSize(
			new Size(
				d.minW,
				arg,
			),
		);
	}

	setMinimumSize(arg: Size): void {
		if (!arg.isValid()) {
			return;
		}
		const d = this.d;
		const argW = arg.width();
		if ((argW !== d.minW) && (argW <= d.maxW)) {
			const mw = clamp(
				0,
				argW,
				d.maxW,
			);
			if (mw !== d.minW) {
				d.minW = mw;
				if (d.polished && (this.width() < d.minW)) {
					d.setWidthStyle(d.minW);
				}
				this.minimumWidthChanged(d.minW);
			}
		}
		const argH = arg.height();
		if ((argH !== d.minH) && (argH <= d.maxH)) {
			const mh = clamp(
				0,
				argH,
				d.maxH,
			);
			if (mh !== d.minH) {
				d.minH = mh;
				if (d.polished && (this.height() < d.minH)) {
					d.setHeightStyle(d.minH);
				}
				this.minimumHeightChanged(d.minH);
			}
		}
	}

	@SLOT
	setMinimumWidth(arg: number): void {
		const d = this.d;
		if (!isNumber(arg) || (arg === d.minW) || (arg < 0)) {
			return;
		}
		this.setMinimumSize(
			new Size(
				arg,
				d.minH,
			),
		);
	}

	setOpacity(level: number): void {
		/**
		 * A value of 1.0 or above is treated as fully opaque, whereas a value
		 * of 0.0 or below is treated as fully transparent. Values in-between
		 * represent varying levels of translucency between the two extremes.
		 *
		 * The default value is 1.0.
		 */
		const d = this.d;
		if ((level === d.opacity) || (level < 0) || (level > 1)) {
			return;
		}
		d.opacity = level;
		d.setOpacityStyle(
			d.opacity,
		);
		this.opacityChanged(d.opacity);
	}

	setPosition(x: number, y: number): void;
	setPosition(pos: Point): void;
	setPosition(a: Point | number, b?: number): void {
		let x: number;
		let y: number;
		if (isNumber(a) && isNumber(b)) {
			x = a;
			y = b;
		} else {
			x = (<Point>a).x();
			y = (<Point>a).y();
		}
		this.move(x, y);
	}

	@SLOT
	setTitle(title: string): void {
		const d = this.d;
		if (title === d.title) {
			return;
		}
		d.title = title;
		if (d.topBar) {
			d.topBar.setLabel(d.title);
		}
		this.titleChanged(d.title);
	}

	@SLOT
	setWidth(arg: number): void {
		this.resize(
			new Size(
				arg,
				this.height(),
			),
		);
	}

	setWindowState(arg: WindowState): void {
		const d = this.d;
		if (arg === d.state) {
			return;
		}
		d.state = arg;
		if (d.state === WindowState.WindowMinimized) {
			if (d.topBar) {
				d.heightBeforeMinimized = this.height();
				for (const child of this.children()) {
					if (child.isElType() && (child !== d.topBar) && child.isVisible()) {
						child.hide();
						child.setAttr(ElAttr.DontShowOnScreen);
					}
				}
				d.setHeightStyle(
					d.topBar.rect().height,
					true,
				);
				if (d.minMaxAxn) {
					d.minMaxAxn.icon(
					).setName(
						'maximize',
					);
					d.minMaxAxn.setToolTip(
						'Maximize',
					);
				}
			}
		} else {
			if (d.heightBeforeMinimized >= 0) {
				d.setHeightStyle(
					d.heightBeforeMinimized,
					true,
				);
				for (const child of this.children()) {
					if (child.isElType() && (child !== d.topBar) && child.testAttribute(ElAttr.DontShowOnScreen)) {
						child.show();
					}
				}
			}
			d.heightBeforeMinimized = -1;
			if (d.minMaxAxn) {
				d.minMaxAxn.icon(
				).setName(
					'minimize',
				);
				d.minMaxAxn.setToolTip(
					'Minimize',
				);
			}
		}
		this.windowStateChanged(d.state);
	}

	@SLOT
	setX(arg: number): void {
		this.move(
			arg,
			this.y(),
		);
	}

	@SLOT
	setY(arg: number): void {
		this.move(
			this.x(),
			arg,
		);
	}

	protected showEvent(event: ShowEvt): void {
		this.d.polish();
		super.showEvent(event);
	}

	@SLOT
	protected sizeGripMoved(dx: number, dy: number, align: AlignmentFlag): void {
		const d = this.d;
		switch (align) {
			case AlignmentFlag.AlignTop: {
				if (((d.drag.h - dy) > d.maxH) || ((d.drag.h - dy) < d.minH)) {
					return;
				}
				d.drag.y += dy;
				d.drag.h -= dy;
				d.setPosStyleAnim(d.drag.x, d.drag.y);
				d.setHeightStyleAnim(d.drag.h, true);
				break;
			}
			case AlignmentFlag.AlignRight: {
				if (((d.drag.w + dx) > d.maxW) || ((d.drag.w + dx) < d.minW)) {
					return;
				}
				d.drag.w += dx;
				d.setWidthStyleAnim(d.drag.w, true);
				break;
			}
			case AlignmentFlag.AlignBottom: {
				if (((d.drag.h + dy) > d.maxH) || ((d.drag.h + dy) < d.minH)) {
					return;
				}
				d.drag.h += dy;
				d.setHeightStyleAnim(d.drag.h);
				break;
			}
			case AlignmentFlag.AlignLeft: {
				if (((d.drag.w - dx) > d.maxW) || ((d.drag.w - dx) < d.minW)) {
					return;
				}
				d.drag.x += dx;
				d.drag.w -= dx;
				d.setPosStyleAnim(d.drag.x, d.drag.y);
				d.setWidthStyleAnim(d.drag.w, true);
				break;
			}
		}
	}

	@SLOT
	protected sizeGripPressed(): void {
		const {
			height,
			width,
			x,
			y,
		} = this.rect();
		this.d.drag = {
			h: height,
			minX: 0,
			w: width,
			x,
			y,
			startX: x,
			startY: y,
		};
	}

	@SLOT
	protected startDragMove(): void {
		const d = this.d;
		const {
			height,
			width,
			x,
			y,
		} = this.rect();
		d.drag = {
			h: height,
			minX: 0,
			w: width,
			x,
			y,
			startX: x,
			startY: y,
		};
		this.addWindowEventListener('mouseup');
		this.addWindowEventListener('mousemove');
		this.addWindowEventListener('keydown');
		this.addWindowEventListener('blur');
	}

	state(): WindowState {
		return this.d.state;
	}

	@SLOT
	protected stopDragMove(): void {
		this.removeWindowEventListener('mouseup');
		this.removeWindowEventListener('mousemove');
		this.removeWindowEventListener('keydown');
		this.removeWindowEventListener('blur');
		const d = this.d;
		const rect = this.rect();
		const xChanged = d.drag.startX !== rect.x;
		const yChanged = d.drag.startY !== rect.y;
		if (xChanged) {
			this.xChanged(rect.x);
		}
		if (yChanged) {
			this.yChanged(rect.y);
		}
		if (xChanged || yChanged) {
			this.positionChanged(
				new Point(
					rect.x,
					rect.y,
				),
			);
		}
	}

	title(): string {
		return this.d.title;
	}

	@SIGNAL
	protected titleChanged(arg: string): void {
	}

	width(): number {
		return this.rect().width;
	}

	@SIGNAL
	protected widthChanged(arg: number): void {
	}

	protected _windowBlurEvent(event: Event): void {
		this.stopDragMove();
	}

	protected _windowKeyDownEvent(event: KeyboardEvent): void {
		if (event.key === Key.Escape) {
			this.stopDragMove();
		}
	}

	protected _windowMouseMoveEvent(event: MouseEvent): void {
		const d = this.d;
		d.drag.x = Math.max(
			d.drag.minX,
			d.drag.x + event.movementX,
		);
		d.drag.y = Math.max(
			0,
			d.drag.y + event.movementY,
		);
		d.setPosStyleAnim(
			d.drag.x,
			d.drag.y,
		);
	}

	protected _windowMouseUpEvent(event: MouseEvent): void {
		this.stopDragMove();
	}

	@SIGNAL
	protected windowStateChanged(arg: WindowState): void {
	}

	x(): number {
		return this.rect().x;
	}

	@SIGNAL
	protected xChanged(x: number): void {
	}

	y(): number {
		return this.rect().y;
	}

	@SIGNAL
	protected yChanged(y: number): void {
	}
}

export class ElWinTopBarPrivate extends ElObjPrivate {
	elLabel: ElObj | null;
	label: string;
	toolBar: ToolBar | null;

	constructor() {
		super();
		this.elLabel = null;
		this.label = '';
		this.toolBar = null;
	}

	init(opts: Partial<ElWinTopBarOpts>): void {
		super.init(opts);
		const q = this.q;
		this.elLabel = new ElObj({
			classNames: [
				'lb-el-win-top-bar-label',
			],
			parent: q,
		});
		this.elLabel.setText(this.label);
		this.toolBar = new ToolBar({
			parent: q,
		});
		q.addEventListener('dblclick');
		q.addEventListener('keydown');
		q.addEventListener('keyup');
		q.addEventListener('mousedown');
		q.addEventListener('mouseup');
	}

	get q(): ElWinTopBar {
		return <ElWinTopBar>super.q;
	}
}

export interface ElWinTopBarOpts extends ElObjOpts {
	dd: ElWinTopBarPrivate;
}

@OBJ
export class ElWinTopBar extends ElObj {
	constructor(opts: Partial<ElWinTopBarOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-el-win-top-bar',
		);
		opts.dd = opts.dd || new ElWinTopBarPrivate();
		super(opts);
	}

	get d(): ElWinTopBarPrivate {
		return <ElWinTopBarPrivate>super.d;
	}

	@SIGNAL
	protected doubleClicked(): void {
	}

	label(): string {
		return this.d.label;
	}

	@SIGNAL
	protected labelChanged(label: string): void {
	}

	protected mouseDoubleClickEvent(event: MouseEvt): void {
		super.mouseDoubleClickEvent(event);
		this.doubleClicked();
	}

	protected mousePressEvent(event: MouseEvt): void {
		super.mousePressEvent(event);
		this.pressed();
	}

	protected mouseReleaseEvent(event: MouseEvt): void {
		super.mouseReleaseEvent(event);
		this.released();
	}

	@SIGNAL
	protected pressed(): void {
	}

	@SIGNAL
	protected released(): void {
	}

	@SLOT
	setLabel(label: string): void {
		const d = this.d;
		if (label === d.label) {
			return;
		}
		d.label = label;
		if (d.elLabel) {
			d.elLabel.setText(d.label);
		}
		this.labelChanged(d.label);
	}

	toolBar(): ToolBar | null {
		return this.d.toolBar;
	}
}

export class SizeGripPrivate extends ElObjPrivate {
	align: AlignmentFlag;
	moving: boolean;

	constructor() {
		super();
		this.align = 0;
		this.moving = false;
	}

	init(opts: Partial<SizeGripOpts>): void {
		super.init(opts);
		const q = this.q;
		q.setAlignment(
			(opts.alignment === undefined) ?
				AlignmentFlag.AlignAbsolute :
				opts.alignment,
		);
		q.addEventListener('keydown');
		q.addEventListener('keyup');
		q.addEventListener('mousedown');
		q.addEventListener('mouseup');
		q.addEventListener('mouseleave');
		q.addEventListener('pointerleave');
	}

	get q(): SizeGrip {
		return <SizeGrip>super.q;
	}
}

export interface SizeGripOpts extends ElObjOpts {
	alignment: AlignmentFlag;
	dd: SizeGripPrivate;
}

@OBJ
export class SizeGrip extends ElObj {
	constructor(opts: Partial<SizeGripOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'lb-el-win-grip',
		);
		opts.dd = opts.dd || new SizeGripPrivate();
		super(opts);
	}

	alignment(): AlignmentFlag {
		return this.d.align;
	}

	get d(): SizeGripPrivate {
		return <SizeGripPrivate>super.d;
	}

	protected mousePressEvent(event: MouseEvt): void {
		super.mousePressEvent(event);
		if (event.button() !== MouseButton.LeftButton) {
			return;
		}
		this.pressed();
		this.startDragging();
	}

	protected mouseReleaseEvent(event: MouseEvt): void {
		super.mouseReleaseEvent(event);
		if (event.button() !== MouseButton.LeftButton) {
			return;
		}
		this.stopDragging();
	}

	@SIGNAL
	protected moved(dx: number, dy: number, align: AlignmentFlag): void {
	}

	@SIGNAL
	protected pressed(): void {
	}

	setAlignment(align: AlignmentFlag): void {
		const d = this.d;
		if (align === d.align) {
			return;
		}
		d.align = align;
		this.setClass(
			Boolean(d.align & AlignmentFlag.AlignTop),
			'top',
		);
		this.setClass(
			Boolean(d.align & AlignmentFlag.AlignRight),
			'right',
		);
		this.setClass(
			Boolean(d.align & AlignmentFlag.AlignBottom),
			'bottom',
		);
		this.setClass(
			Boolean(d.align & AlignmentFlag.AlignLeft),
			'left',
		);
	}

	protected startDragging(): void {
		this.d.moving = true;
		this.addClass('active');
		this.addWindowEventListener('keydown');
		this.addWindowEventListener('mousemove');
		this.addWindowEventListener('mouseup');
		this.addWindowEventListener('blur');
	}

	protected stopDragging(): void {
		this.d.moving = false;
		this.removeWindowEventListener('keydown');
		this.removeWindowEventListener('mousemove');
		this.removeWindowEventListener('mouseup');
		this.removeWindowEventListener('blur');
		this.removeClass('active');
	}

	protected _windowBlurEvent(event: Event): void {
		this.stopDragging();
	}

	protected _windowKeyDownEvent(event: KeyboardEvent): void {
		if (event.key === Key.Escape) {
			this.stopDragging();
		}
	}

	protected _windowMouseMoveEvent(event: MouseEvent): void {
		this.moved(
			event.movementX,
			event.movementY,
			this.alignment(),
		);
	}

	protected _windowMouseUpEvent(event: MouseEvent): void {
		this.stopDragging();
	}
}
