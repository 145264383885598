import {OBJ} from '../../../obj';
import {ElObj, ElObjOpts} from '../../../elobj';

@OBJ
export class ProgressIndicator extends ElObj {
	constructor(opts: Partial<ElObjOpts> = {}) {
		let rootExisted = Boolean(opts.root);
		if (!(opts.root || opts.tagName)) {
			const root = opts.parent ?
				opts.parent.element().querySelector('.mdc-data-table__progress-indicator') :
				null;
			if (root) {
				opts.root = root;
				rootExisted = true;
			}
		}
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'mdc-data-table__progress-indicator',
		);
		super(opts);
		let obj: ElObj;
		if (!rootExisted) {
			obj = new ElObj({
				classNames: 'mdc-data-table__scrim',
				parent: this,
				tagName: 'div',
			});
			obj.show();
			const bar = new ElObj({
				attributes: [
					['aria-label', 'Loading data'],
					['role', 'progressbar'],
				],
				classNames: [
					'mdc-linear-progress',
					'mdc-linear-progress--indeterminate',
					'mdc-data-table__linear-progress',
				],
				parent: this,
				tagName: 'div',
			});
			bar.show();
			const buffer = new ElObj({
				classNames: 'mdc-linear-progress__buffer',
				parent: bar,
				tagName: 'div',
			});
			buffer.show();
			obj = new ElObj({
				classNames: 'mdc-linear-progress__buffer-bar',
				parent: buffer,
				tagName: 'div',
			});
			obj.show();
			obj = new ElObj({
				classNames: 'mdc-linear-progress__buffer-dots',
				parent: buffer,
				tagName: 'div',
			});
			obj.show();
			const primary = new ElObj({
				classNames: [
					'mdc-linear-progress__bar',
					'mdc-linear-progress__primary-bar',
				],
				parent: bar,
				tagName: 'div',
			});
			primary.show();
			obj = new ElObj({
				classNames: 'mdc-linear-progress__bar-inner',
				parent: primary,
				tagName: 'span',
			});
			obj.show();
			const secondary = new ElObj({
				classNames: [
					'mdc-linear-progress__bar',
					'mdc-linear-progress__secondary-bar',
				],
				parent: bar,
				tagName: 'div',
			});
			secondary.show();
			obj = new ElObj({
				classNames: 'mdc-linear-progress__bar-inner',
				parent: secondary,
				tagName: 'span',
			});
			obj.show();
		}
	}
}
