import {assert} from '../util';
import {AspectRatioMode} from '../constants';

function fuzzyIsNull(d: number): boolean {
	return Math.abs(d) <= 0.000000000001;
}

export class Size implements Ieq {
	static add(a: Size, b: Size): Size {
		return new this(a.wd + b.wd, a.ht + b.ht);
	}

	static div(size: Size, divisor: number): Size {
		assert(!fuzzyIsNull(divisor));
		return new this(Math.round(size.wd / divisor), Math.round(size.ht / divisor));
	}

	static eq(a: Size, b: Size): boolean {
		return (a.ht === b.ht) && (a.wd === b.wd);
	}

	static mul(size: Size, factor: number): Size;
	static mul(factor: number, size: Size): Size;
	static mul(...args: [Size, number] | [number, Size]): Size {
		let factor: number;
		let size: Size;
		if (typeof args[0] === 'number') {
			[factor, size] = <[number, Size]>args;
		} else {
			[size, factor] = <[Size, number]>args;
		}
		return new this(Math.round(size.wd * factor), Math.round(size.ht * factor));
	}

	static ne(a: Size, b: Size): boolean {
		return (a.ht !== b.ht) || (a.wd !== b.wd);
	}

	static sub(a: Size, b: Size): Size {
		return new this(a.wd - b.wd, a.ht - b.ht);
	}

	private ht: number;
	private wd: number;

	constructor(width: number, height: number);
	constructor(other: Size);
	constructor();
	constructor(...args: [number, number] | [Size] | []) {
		let ht: number = -1;
		let wd: number = -1;
		if (args.length === 2) {
			[wd, ht] = args;
		} else if (args.length === 1) {
			const other = args[0];
			ht = other.ht;
			wd = other.wd;
		}
		this.ht = Math.round(ht);
		this.wd = Math.round(wd);
	}

	boundedTo(size: Size): Size {
		return new Size(Math.min(this.wd, size.wd), Math.min(this.ht, size.ht));
	}

	eq(other: Size): boolean {
		// Equality
		return Size.eq(this, other);
	}

	expandedTo(size: Size): Size {
		return new Size(Math.max(this.wd, size.wd), Math.max(this.ht, size.ht));
	}

	height(): number {
		return this.ht;
	}

	iadd(size: Size): this {
		// In-place addition
		this.wd += size.wd;
		this.ht += size.ht;
		return this;
	}

	idiv(divisor: number): Size {
		// In-place division
		assert(!fuzzyIsNull(divisor));
		this.wd = Math.round(this.wd / divisor);
		this.ht = Math.round(this.ht / divisor);
		return this;
	}

	imul(factor: number): this {
		// In-place multiplication
		this.ht = Math.round(this.ht * factor);
		this.wd = Math.round(this.wd * factor);
		return this;
	}

	isEmpty(): boolean {
		return (this.wd < 0) || (this.ht < 0);
	}

	isNull(): boolean {
		return (this.wd === 0) && (this.ht === 0);
	}

	isub(size: Size): this {
		// In-place subtraction
		this.wd -= size.wd;
		this.ht -= size.ht;
		return this;
	}

	isValid(): boolean {
		return (this.wd >= 0) && (this.ht >= 0);
	}

	ne(other: Size): boolean {
		// Equality
		return Size.ne(this, other);
	}

	scale(width: number, height: number, mode: AspectRatioMode): void;
	scale(size: Size, mode: AspectRatioMode): void;
	scale(...args: [number, number, AspectRatioMode] | [Size, AspectRatioMode]): void {
		if (args.length === 3) {
			return this.scale(new Size(args[0], args[1]), args[2]);
		} else {
			const {wd, ht} = this.scaled(...args);
			this.wd = wd;
			this.ht = ht;
		}
	}

	scaled(width: number, height: number, mode: AspectRatioMode): Size;
	scaled(size: Size, mode: AspectRatioMode): Size;
	scaled(...args: [number, number, AspectRatioMode] | [Size, AspectRatioMode]): Size {
		if (args.length === 3) {
			return this.scaled(new Size(args[0], args[1]), args[2]);
		} else {
			const [s, mode] = args;
			if ((mode === AspectRatioMode.IgnoreAspectRatio) || (this.wd === 0) || (this.ht === 0)) {
				return s;
			}
			let useHeight: boolean;
			const rw = Math.round(s.ht * this.wd / this.ht);
			if (mode === AspectRatioMode.KeepAspectRatio) {
				useHeight = (rw <= s.wd);
			} else {
				// mode === AspectRatioMode.KeepAspectRatioByExpanding
				useHeight = (rw >= s.wd);
			}
			if (useHeight) {
				return new Size(rw, s.ht);
			}
			return new Size(s.wd, Math.round(s.wd * this.ht / this.wd));
		}
	}

	setHeight(value: number): void {
		this.ht = Math.round(value);
	}

	setWidth(value: number): void {
		this.wd = Math.round(value);
	}

	toString(): string {
		return `Size(width: ${this.wd}, height: ${this.ht})`;
	}

	transpose(): void {
		const ht = this.ht;
		this.ht = this.wd;
		this.wd = ht;
	}

	transposed(): Size {
		return new Size(this.ht, this.wd);
	}

	width(): number {
		return this.wd;
	}
}
