import {svc} from '../../request';
import {Obj, OBJ, SLOT} from '../../obj';
import {ElObj, ElObjOpts} from '../../elobj';
import {getLogger} from '../../logging';
import {TierTable} from './tiertable';
import {ComboBox} from '../../ui/combobox';
import {FancyPushButton} from '../../ui/pushbutton';
import {TextInput} from '../../ui/textinput';
import {isNumber} from '../../util';
import {Checkbox} from '../../ui/checkbox';
import {Icon} from '../../ui/icon';

const logger = getLogger('views.price');

export enum PricingModel {
	Standard,
	Volume,
}

const pricingModelChoices: Array<[string, string]> = [
	['standard', 'Standard pricing'],
	['volume', 'Volume pricing'],
];

interface PriceViewOpts extends ElObjOpts {
	pk: PricePk;
}

@OBJ
export class PriceView extends ElObj {
	private currentPriceModel: PricingModel;
	protected defaultCheckbox: Checkbox;
	protected labelInput: TextInput;
	protected pk: PricePk | null;
	private priceModelComboBox: ComboBox;
	protected pricingView: PricingView;
	private submitBtn: FancyPushButton;

	constructor(opts: Partial<PriceViewOpts> = {}) {
		if ((opts.root === undefined) && (opts.tagName === undefined)) {
			opts.root = document.getElementById(
				(opts.pk === undefined) ?
					'id_lb-price-create' :
					'id_lb-price-update',
			);
		}
		super(opts);
		this.currentPriceModel = PricingModel.Standard;
		this.pk = null;
		const checkboxContainer = new ElObj({
			classNames: [
				'mdc-form-field',
				'lb-vertical-input-box--field',
			],
			parent: this,
			styles: [
				['margin-left', '-8px'],
			],
			tagName: 'div',
		});
		checkboxContainer.show();
		const defaultCheckboxId = `id_lb-price-default-input`;
		this.defaultCheckbox = new Checkbox({
			inputId: defaultCheckboxId,
			inputName: 'default',
			parent: checkboxContainer,
		});
		this.defaultCheckbox.show();
		const defaultCheckboxLabel = new ElObj({
			attributes: [
				['for', defaultCheckboxId],
			],
			parent: checkboxContainer,
			tagName: 'label',
		});
		defaultCheckboxLabel.show();
		defaultCheckboxLabel.setText('Default price');
		this.labelInput = new TextInput({
			classNames: [
				'lb-vertical-input-box--field',
			],
			fullWidth: true,
			labelText: 'Label (optional, only you will see it)',
			outlined: true,
			name: 'label',
			parent: this,
		});
		this.labelInput.show();
		this.priceModelComboBox = new ComboBox({
			attributes: [
				['name', 'pricing_model'],
			],
			classNames: [
				'lb-vertical-input-box--field',
			],
			parent: this,
		});
		this.priceModelComboBox.show();
		this.pricingView = new PricingView({
			classNames: [
				'lb-vertical-input-box--field',
			],
			parent: this,
		});
		this.pricingView.show();
		const axnBox = new ElObj({
			classNames: [
				'lb-vertical-input-box--actions',
			],
			parent: this,
			tagName: 'div',
		});
		axnBox.show();
		this.submitBtn = new FancyPushButton({
			attributes: [
				['type', 'submit'],
			],
			parent: axnBox,
			raised: true,
			text: 'Save',
		});
		this.submitBtn.show();
		this.init(opts.pk);
	}

	destroy(): void {
		this.defaultCheckbox.destroy();
		this.labelInput.destroy();
		this.priceModelComboBox.destroy();
		this.submitBtn.destroy();
		this.pricingView.destroy();
		this.currentPriceModel = PricingModel.Standard;
		this.pk = null;
		super.destroy();
	}

	protected async fetchPrice(pk: PricePk): Promise<IPrice> {
		return svc.price.get(pk);
	}

	protected async fetchPriceTiers(pricePk: PricePk): Promise<Array<IPriceTier>> {
		return await svc.priceTier.list({pricePk});
	}

	protected async init(pk?: PricePk): Promise<void> {
		this.addEventListener('submit');
		Obj.connect(
			this.priceModelComboBox,
			'currentIndexChanged',
			this,
			'priceModelComboBoxIndexChanged',
		);
		for (const [value, label] of pricingModelChoices) {
			this.priceModelComboBox.addItem(label, value);
		}
		if (pk === undefined) {
			this.pricingView.volPricingView.tierTable.addTier(
				{
					firstUnit: 1,
					lastUnit: 1,
					unitPrice: '0.00',
				},
			);
			this.pricingView.volPricingView.tierTable.addTier(
				{
					firstUnit: 2,
					lastUnit: null,
					unitPrice: '0.00',
				},
			);
		} else {
			if (typeof pk === 'string') {
				pk = Number.parseInt(pk);
			}
			if ((pk === undefined) || !isNumber(pk)) {
				logger.error('init: Invalid PK.');
				return;
			}
			this.pk = pk;
			const obj = await this.fetchPrice(this.pk);
			this.defaultCheckbox.setChecked(obj.default);
			let tiers: Array<IPriceTier> = [];
			if (obj.isVolumePricing) {
				tiers = await this.fetchPriceTiers(this.pk);
			}
			this.setPriceData(obj, tiers);
		}
	}

	@SLOT
	private priceModelComboBoxIndexChanged(): void {
		let newModel: PricingModel;
		switch (this.currentPriceModel) {
			case PricingModel.Standard:
				newModel = PricingModel.Volume;
				break;
			case PricingModel.Volume:
				newModel = PricingModel.Standard;
				break;
		}
		this.setCurrentPriceModel(newModel);
	}

	private setCurrentPriceModel(model: PricingModel): void {
		if (model === this.currentPriceModel) {
			return;
		}
		this.currentPriceModel = model;
		this.pricingView.setCurrentPricingModelView(
			this.currentPriceModel);
	}

	protected setPriceData(data: IPrice, tiers?: Array<IPriceTier>): void {
		this.labelInput.setText(data.label);
		const model = data.isVolumePricing ?
			PricingModel.Volume :
			PricingModel.Standard;
		this.priceModelComboBox.setCurrentIndex(model);
		switch (model) {
			case PricingModel.Volume:
				this.pricingView.setTiers(tiers || []);
				break;
			case PricingModel.Standard:
				this.pricingView.setUnitAmount(data.unitAmount || '');
				break;
		}
	}
}

@OBJ
class PricingView extends ElObj {
	stdPricingView: StandardPricingView;
	volPricingView: VolumePricingView;

	constructor(opts: Partial<ElObjOpts> = {}) {
		super(opts);
		this.stdPricingView = new StandardPricingView({
			parent: this,
		});
		this.stdPricingView.show();
		this.volPricingView = new VolumePricingView({
			parent: this,
		});
		this.volPricingView.hide();
	}

	destroy(): void {
		this.stdPricingView.destroy();
		this.volPricingView.destroy();
		super.destroy();
	}

	setCurrentPricingModelView(model: PricingModel): void {
		switch (model) {
			case PricingModel.Standard:
				this.volPricingView.hide();
				this.stdPricingView.show();
				this.stdPricingView.priceInput.setRequired(true);
				break;
			case PricingModel.Volume:
				this.stdPricingView.hide();
				this.stdPricingView.priceInput.setRequired(false);
				this.volPricingView.show();
				break;
		}
	}

	setTiers(tiers: Array<IPriceTier>): void {
		this.volPricingView.setTiers(tiers);
	}

	setUnitAmount(amount: number | string): void {
		this.stdPricingView.priceInput.setText(String(amount));
	}
}

@OBJ
class StandardPricingView extends ElObj {
	priceInput: TextInput;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'display--flex',
			'flex-direction--column',
		);
		super(opts);
		this.priceInput = new TextInput({
			classNames: [
				'lb-price-create-row',
			],
			labelText: 'Price',
			outlined: true,
			name: 'unit_amount',
			parent: this,
		});
		this.priceInput.show();
	}
}

@OBJ
class VolumePricingView extends ElObj {
	addTierButton: FancyPushButton;
	tierTable: TierTable;

	constructor(opts: Partial<ElObjOpts> = {}) {
		opts.classNames = ElObj.mergeClassNames(
			opts.classNames,
			'display--flex',
			'flex-direction--column',
		);
		super(opts);
		const btnContainer = new ElObj({
			classNames: [
				'display--flex',
				'flex-direction--column',
			],
			parent: this,
			tagName: 'div',
		});
		btnContainer.show();
		this.addTierButton = new FancyPushButton({
			classNames: [
				'align-self--flex-end',
			],
			icon: new Icon({name: 'add'}),
			parent: btnContainer,
			text: 'Add another tier',
		});
		this.addTierButton.show();
		this.tierTable = new TierTable({
			parent: this,
		});
		this.tierTable.show();
		Obj.connect(
			this.addTierButton, 'clicked',
			this.tierTable, 'addTier');
	}

	destroy(): void {
		this.addTierButton.destroy();
		this.tierTable.destroy();
		super.destroy();
	}

	setTiers(tiers: Array<IPriceTier>): void {
		let firstUnit: number = 0;
		for (let i = 0; i < tiers.length; ++i) {
			const obj = tiers[i];
			this.tierTable.addTier({
				firstUnit,
				lastUnit: obj.upToIncluding,
				unitPrice: obj.unitAmount,
			});
			if (isNumber(obj.upToIncluding)) {
				firstUnit = obj.upToIncluding + 1;
			}
		}
	}
}
