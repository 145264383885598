import {AbstractItemView, AbstractItemViewOpts, AbstractItemViewPrivate} from './abstractitemview';
import {AbstractItemModel, AbstractItemModelOpts, ModelIndex, PersistentModelIndex} from '../../abstractitemmodel';
import {Obj, OBJ, PROP, SIGNAL, SLOT} from '../../obj';
import {CheckState, ControlType, ItemDataRole} from '../../constants';
import {Variant} from '../../variant';
import {list} from '../../tools';
import {ItemData} from './itemdata';
import {assert, isNumber} from '../../util';
import {getLogger} from '../../logging';
import {ElObj, ElObjOpts} from '../../elobj';
import {ComboBox} from '../combobox';
import {TextInput} from '../textinput';
import {Tree, TreeItem} from '../tree';

const logger = getLogger('ui.views.controlview');

class ControlItemPrivate {
	el: ControlEl | null;
	enabled: boolean;
	id: number;
	q: ControlItem;

	constructor(item: ControlItem) {
		this.el = null;
		this.enabled = true;
		this.id = -1;
		this.q = item;
	}

	initEl(): void {
		if (this.el) {
			return;
		}
		const view = this.q.controlView();
		if (!view) {
			return;
		}
		this.el = new ControlEl({parent: view});
		this.el.init(this.q);
		this.el.show();
	}
}

export class ControlItem {
	d: ControlItemPrivate;
	t: ControlType;
	values: list<ItemData>;
	view: ControlView | null;

	constructor(type: ControlType = ControlType.NoType) {
		this.d = new ControlItemPrivate(this);
		this.t = type;
		this.values = new list();
		this.view = null;
	}

	checkState(): CheckState {
		return this.data(
			ItemDataRole.CheckStateRole,
		).toNumber();
	}

	comboBoxControl(): ComboBox | null {
		const el = this.control();
		if (el && (el instanceof ComboBox)) {
			return el;
		}
		return null;
	}

	control(): ElObj | null {
		return this.d.el ?
			this.d.el.ctrl :
			null;
	}

	private controlModel(): ControlModel | null {
		return this.view ?
			this.view.model() :
			null;
	}

	controlView(): ControlView | null {
		return this.view;
	}

	data(role: ItemDataRole): Variant {
		for (const value of this.values) {
			if (value.role === role) {
				return value.value;
			}
		}
		return new Variant();
	}

	destroy(): void {
		const model = this.controlModel();
		if (model) {
			model.removeItem(this);
		}
		this.d.id = -1;
		if (this.d.el) {
			this.d.el.destroy();
			this.d.el = null;
		}
	}

	isEnabled(): boolean {
		return this.d.enabled;
	}

	isDisabled(): boolean {
		return !this.isEnabled();
	}

	lineEditControl(): TextInput | null {
		const el = this.control();
		if (el && (el instanceof TextInput)) {
			return el;
		}
		return null;
	}

	section(): number {
		return this.view ?
			this.view.section(this) :
			-1;
	}

	setCheckState(state: CheckState): void {
		this.setData(
			ItemDataRole.CheckStateRole,
			new Variant(state));
	}

	setData(role: ItemDataRole, data: Variant): void {
		let found: boolean = false;
		for (let i = 0; i < this.values.size(); ++i) {
			if (this.values.at(i).role === role) {
				if (this.values.at(i).value.eq(data)) {
					return;
				}
				this.values.at(i).value = data;
				found = true;
				break;
			}
		}
		if (!found) {
			this.values.append(new ItemData(role, data));
		}
		if (this.d.el) {
			this.d.el.setData(role, data);
		}
		const model = this.controlModel();
		if (model) {
			model.itemChanged(this, [role]);
		}
	}

	setEnabled(enabled: boolean): void {
		if (enabled === this.d.enabled) {
			return;
		}
		this.d.enabled = enabled;
		if (this.d.el) {
			this.d.el.setEnabled(this.d.enabled);
		}
	}

	treeControl(): Tree | null {
		const el = this.control();
		if (el && (el instanceof Tree)) {
			return el;
		}
		return null;
	}

	type(): ControlType {
		return this.t;
	}
}

const controlElCssClassName = 'lb-ctrl-view-ctrl-el';

@OBJ
export class ControlEl extends ElObj {
	static CssClassName: string = controlElCssClassName;
	static CssSelector: string = `.${controlElCssClassName}`;

	ctrl: ElObj | null;
	item: ControlItem | null;

	constructor(opts: Partial<ElObjOpts> = {}) {
		super(opts);
		this.ctrl = null;
		this.item = null;
		this.addClass((<typeof ControlEl>this.constructor).CssClassName);
	}

	destroy(): void {
		if (this.ctrl) {
			this.ctrl.destroy();
		}
		this.ctrl = null;
		if (this.item) {
			this.item.d.el = null;
		}
		this.item = null;
		super.destroy();
	}

	init(item: ControlItem): void {
		if (this.ctrl) {
			return;
		}
		assert(!this.item);
		this.item = item;
		let signal: string = '';
		let slot: string = '_stringValueChanged';
		switch (item.type()) {
			case ControlType.ComboBox: {
				this.ctrl = new ComboBox();
				signal = 'currentValueChanged';
				break;
			}
			case ControlType.LineEdit: {
				this.ctrl = new TextInput();
				signal = 'textChanged';
				break;
			}
			case ControlType.Tree: {
				this.ctrl = new Tree();
				signal = 'itemChanged';
				slot = '_treeValueChanged';
				break;
			}
			default: {
				return;
			}
		}
		if (signal.length > 0) {
			Obj.connect(
				this.ctrl, signal,
				this, slot,
			);
		}
		this.ctrl.setParent(this);
		this.ctrl.show();
	}

	setData(role: ItemDataRole, data: Variant): void {
		if (!data.isValid() || data.isNull() || !this.item || !this.ctrl) {
			return;
		}
		const blocked = this.ctrl.blockSignals(true);
		switch (this.item.type()) {
			case ControlType.LineEdit: {
				assert(this.ctrl instanceof TextInput);
				switch (role) {
					case ItemDataRole.DisplayRole:
					case ItemDataRole.EditRole: {
						this.ctrl.setText(data.toString());
					}
				}
				break;
			}
			case ControlType.ComboBox: {
				assert(this.ctrl instanceof ComboBox);
				if (role === ItemDataRole.EditRole) {
					const idx = this.ctrl.index(data.toString());
					this.ctrl.setCurrentIndex(idx);
				}
				break;
			}
		}
		this.ctrl.blockSignals(blocked);
	}

	@SLOT
	private _stringValueChanged(value: string): void {
		if (this.item) {
			this.item.setData(
				ItemDataRole.EditRole,
				new Variant(value),
			);
		}
	}

	@SLOT
	private _treeValueChanged(treeItem: TreeItem, column: number): void {
		if (!this.item) {
			return;
		}
		const treeItemEditData = treeItem.data(column, ItemDataRole.EditRole);
		if (!treeItemEditData.isValid() || treeItemEditData.isNull()) {
			return;
		}
		const treeItemUid = treeItemEditData.toString();
		if (treeItemUid.length < 1) {
			return;
		}
		const checkState = treeItem.checkState(column);
		const treeItemChecked = checkState === CheckState.Checked;
		const itemEditData = this.item.data(ItemDataRole.EditRole);
		const currCheckedUids = itemEditData.toString();
		if (treeItemChecked) {
			// Ensure uid is part of edit data
			if (currCheckedUids.indexOf(treeItemUid) < 0) {
				let newCheckedUids = `${currCheckedUids},${treeItemUid}`;
				newCheckedUids = newCheckedUids.replace(/,+/, ',');
				newCheckedUids = newCheckedUids.replace(/^,+/, '');
				newCheckedUids = newCheckedUids.replace(/,+$/, '');
				this.item.setData(ItemDataRole.EditRole, new Variant(newCheckedUids));
			}
		} else {
			// Ensure uid is not part of item data
			if (currCheckedUids.indexOf(treeItemUid) >= 0) {
				let newCheckedUids = currCheckedUids.replace(treeItemUid, '');
				newCheckedUids = newCheckedUids.replace(/,+/, ',');
				newCheckedUids = newCheckedUids.replace(/^,+/, '');
				newCheckedUids = newCheckedUids.replace(/,+$/, '');
				this.item.setData(ItemDataRole.EditRole, new Variant(newCheckedUids));
			}
		}
	}
}

export interface ControlModelOpts extends AbstractItemModelOpts {
	sectionCount: number;
}

@OBJ
export class ControlModel extends AbstractItemModel {
	private controlItems: list<ControlItem | null>;

	constructor(opts: Partial<ControlModelOpts> = {}) {
		super(opts);
		const count = isNumber(opts.sectionCount) ?
			opts.sectionCount :
			0;
		this.controlItems = new list(count, null);
	}

	clear(): void {
		this.beginResetModel();
		for (let i = 0; i < this.controlItems.size(); ++i) {
			const itm = this.controlItems.at(i);
			if (itm) {
				itm.view = null;
				itm.destroy();
				this.controlItems.replace(i, null);
			}
		}
		this.endResetModel();
	}

	columnCount(parent: ModelIndex | PersistentModelIndex = new ModelIndex()): number {
		return parent.isValid() ?
			0 :
			this.controlItems.size();
	}

	createItem(): ControlItem {
		return new ControlItem();
	}

	data(index: ModelIndex, role: ItemDataRole = ItemDataRole.DisplayRole): Variant {
		const itm = this.item(index);
		if (itm) {
			return itm.data(role);
		}
		return new Variant();
	}

	index(row: number, column: number, parent?: ModelIndex): ModelIndex;
	index(column: number, parent?: ModelIndex): ModelIndex;
	index(item: ControlItem | null): ModelIndex;
	index(a: ControlItem | number | null, b?: ModelIndex | number, parent?: ModelIndex): ModelIndex {
		let item: ControlItem | null = null;
		let column: number = -1;
		if (isNumber(a) && isNumber(b)) {
			// SIG: index(row: number, column: number, parent?: ModelIndex)
			column = b;
		} else if (isNumber(a) && (b instanceof ModelIndex)) {
			// SIG: index(column: number, parent?: ModelIndex)
			column = a;
			parent = b;
		} else {
			assert(a instanceof ControlItem);
			// SIG: index(item: ControlItem | null)
			item = a;
		}
		if (column >= 0) {
			// fall through
		} else {
			if (!item) {
				return new ModelIndex();
			}
			const id = item.d.id;
			if ((id >= 0) && (id < this.controlItems.size()) && (this.controlItems.at(id) === item)) {
				column = id;
			} else {
				// we need to search for the item
				column = this.controlItems.indexOf(item);
				if (column === -1) {
					// not found
					return new ModelIndex();
				}
			}
		}
		return this.hasIndex(0, column, parent) ?
			this.createIndex(0, column) :
			new ModelIndex();
	}

	insertColumns(index: number, count: number = 1, parent?: ModelIndex): boolean {
		if ((count < 1) || (index < 0) || (index > this.controlItems.size())) {
			return false;
		}
		this.beginInsertColumns(
			new ModelIndex(),
			index,
			index + count - 1,
		);
		const rowCount = this.rowCount();
		const columnCount = this.controlItems.size();
		this.controlItems.insert(index, count, null);
		if (columnCount === 0) {
			this.controlItems.resize(rowCount * count, null);
		} else {
			this.controlItems.insert(index, count, null);
		}
		this.endInsertColumns();
		return true;
	}

	isValid(index: ModelIndex): boolean {
		return (index.isValid() && (index.row() < this.rowCount()) && (index.column() < this.controlItems.size()));
	}

	item(row: number, column: number): ControlItem | null;
	item(index: ModelIndex): ControlItem | null;
	item(a: ModelIndex | number, b?: number): ControlItem | null {
		if (isNumber(a) && isNumber(b)) {
			return this.item(this.index(a, b));
		} else {
			const index = <ModelIndex>a;
			if (!this.isValid(index)) {
				return null;
			}
			return this.controlItems.at(index.column());
		}
	}

	itemChanged(item: ControlItem | null, roles?: Array<number>): void {
		if (!item) {
			return;
		}
		const idx = this.index(item);
		if (idx.isValid()) {
			this.dataChanged(idx, idx, roles);
		}
	}

	parentIndex(child: ModelIndex): ModelIndex {
		return new ModelIndex();
	}

	removeColumns(index: number, count: number = 1, parent?: ModelIndex): boolean {
		if ((count < 1) || (index < 0) || ((index + count) > this.controlItems.size())) {
			return false;
		}
		this.beginRemoveColumns(new ModelIndex(), index, index + count - 1);
		for (let i = index; i < (index + count); ++i) {
			const oldItem = this.controlItems.at(i);
			if (oldItem) {
				oldItem.view = null;
				oldItem.destroy();
			}
		}
		this.controlItems.remove(index, count);
		this.endRemoveColumns();
		return true;
	}

	removeItem(item: ControlItem): void {
		let i = this.controlItems.indexOf(item);
		if (i !== -1) {
			const idx = this.index(item);
			this.controlItems.replace(i, null);
			this.dataChanged(idx, idx);
			return;
		}
	}

	rowCount(): number {
		return 1;
	}

	setColumnCount(count: number): void {
		const cc = this.controlItems.size();
		if ((count < 0) || (cc === count)) {
			return;
		}
		if (cc < count) {
			this.insertColumns(Math.max(cc, 0), count - cc);
		} else {
			this.removeColumns(Math.max(count, 0), cc - count);
		}
	}

	setData(index: ModelIndex, value: Variant, role: number): boolean {
		if (!index.isValid()) {
			return false;
		}
		let itm = this.item(index);
		if (itm) {
			itm.setData(role, value);
			return true;
		}
		// don't create dummy table items for empty values
		if (!value.isValid()) {
			return false;
		}
		const view = <ControlView | null>this.parent();
		if (!view) {
			return false;
		}
		itm = this.createItem();
		itm.setData(role, value);
		view.setItem(index.column(), itm);
		return true;
	}

	setItem(column: number, item: ControlItem): void {
		if ((column < 0) || (column >= this.controlItems.size())) {
			return;
		}
		let oldItem: ControlItem | null = this.controlItems.at(column);
		if (item === oldItem) {
			return;
		}
		if (oldItem) {
			oldItem.view = null;
			oldItem.destroy();
		}
		if (item) {
			item.d.id = column;
			item.d.initEl();
		}
		this.controlItems.replace(column, item);
		const idx = this.index(this.rowCount(), column);
		this.dataChanged(idx, idx);
	}

	takeItem(column: number): ControlItem | null {
		const itm = ((column >= 0) && (column < this.controlItems.size())) ? this.controlItems.at(column) : null;
		if (itm) {
			itm.view = null;
			itm.d.id = -1;
			this.controlItems.replace(column, null);
			const ind = this.index(this.rowCount(), column);
			this.dataChanged(ind, ind);
		}
		return itm;
	}
}

export class ControlViewPrivate extends AbstractItemViewPrivate {
	init(opts: Partial<ControlViewOpts>): void {
		super.init(opts);
		const q = this.q;
		q.setModel(new ControlModel({
			parent: q,
			sectionCount: opts.sectionCount || 0,
		}));
		Obj.connect(
			this.model,
			'columnsInserted',
			q,
			'_columnsInserted',
		);
		Obj.connect(
			this.model,
			'columnsRemoved',
			q,
			'_columnsRemoved',
		);
		Obj.connect(
			this.model,
			'dataChanged',
			q,
			'_dataChanged',
		);
		Obj.connect(
			this.model,
			'modelReset',
			q,
			'_modelReset',
		);
	}

	get q(): ControlView {
		return <ControlView>super.q;
	}
}

export interface ControlViewOpts extends AbstractItemViewOpts {
	dd: ControlViewPrivate;
	sectionCount: number;
}

@OBJ
export class ControlView extends AbstractItemView {
	static controlTypeForString(s: string): ControlType {
		let rv: ControlType;
		switch (s) {
			case ControlType.ComboBox:
			case ControlType.LineEdit:
			case ControlType.NoType:
			case ControlType.Tree: {
				rv = s;
				break;
			}
			default: {
				logger.warning('ControlView::controlTypeForString: Invalid control type string: "%s"', s);
				return ControlType.NoType;
			}
		}
		return rv;
	}

	constructor(opts: Partial<ControlViewOpts> = {}) {
		opts.dd = opts.dd || new ControlViewPrivate();
		super(opts);
	}

	@SLOT
	clear(): void {
		this.model().clear();
	}

	@SLOT
	private _columnsInserted(parent: ModelIndex, first: number, last: number): void {
	}

	@SLOT
	private _columnsRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	get d(): ControlViewPrivate {
		return <ControlViewPrivate>super.d;
	}

	@SLOT
	private _dataChanged(topLeft: ModelIndex, bottomRight: ModelIndex, roles?: Array<number>): void {
		const item = this.model().item(topLeft);
		if (item) {
			this.itemChanged(item);
		}
	}

	indexFromItem(item: ControlItem): ModelIndex {
		return this.model().index(item);
	}

	@SLOT
	insertSection(section: number): void {
		// Inserts an empty section at given section index.
		this.model().insertColumns(section);
	}

	item(section: number): ControlItem | null {
		const model = this.model();
		return model.item(model.rowCount(), section);
	}

	@SIGNAL
	private itemChanged(item: ControlItem): void {
	}

	itemFromIndex(index: ModelIndex): ControlItem | null {
		return this.model().item(index);
	}

	model(): ControlModel {
		return <ControlModel>super.model();
	}

	@SLOT
	private _modelReset(): void {
	}

	@SLOT
	removeSection(section: number): void {
		this.model().removeColumns(section);
	}

	@SLOT
	private _rowsInserted(parent: ModelIndex, start: number, end: number): void {
	}

	@SLOT
	private _rowsRemoved(parent: ModelIndex, first: number, last: number): void {
	}

	section(item: ControlItem): number {
		return this.model().index(item).column();
	}

	@PROP({WRITE: 'setSectionCount'})
	sectionCount(): number {
		return this.model().columnCount();
	}

	setItem(section: number, item: ControlItem): void {
		if (item) {
			if (item.view) {
				logger.warning('ControlView: cannot insert an item that is already owned by another ControlView.');
			} else {
				item.view = this;
				this.model().setItem(section, item);
			}
		} else {
			const obj = this.takeItem(section);
			if (obj) {
				obj.destroy();
			}
		}
	}

	setSectionCount(count: number): void {
		this.model().setColumnCount(count);
	}

	takeItem(section: number): ControlItem | null {
		const item = this.model().takeItem(section);
		if (item) {
			item.view = null;
		}
		return item;
	}
}
