import do_a_bbox from '@turf/bbox';

import {ParcelMap, ParcelView, ParcelViewOpts, ParcelViewPrivate} from '../parcel';
import {Obj, OBJ, ObjOpts, SIGNAL, SLOT} from '../../obj';
import {AxnTyp, Win} from './window';
import {TableView} from './tableview';
import {svc} from '../../request';
import {getLogger} from '../../logging';
import {uiRepo} from '../../state';
import {ButtonRole, EdmPrimitiveType, FociisODataVocabTerm, InteractiveMapFlag, ItemDataRole, MapLayerId, MapSourceId, ODataCorePermissions, ODataUnboundFunction, ODataVocabCoreTerm, Orientation, StandardButton, UnitOfMeasurement, WhichPage} from '../../constants';
import {ToolBar} from '../../ui/toolbar';
import {mapLayers, mapSources} from '../../ui/map';
import {GeoCoordinate, list, set} from '../../tools';
import {ActionGroup} from '../../actiongroup';
import {FilterMdl, ParcelTag, Place, Tag} from '../../models';
import {ElObj} from '../../elobj';
import {Icon} from '../../ui/icon';
import {assert, delay, isErrorResponse, isNumber, numberFormat} from '../../util';
import {ItemSelection} from '../../itemselectionmodel';
import {ModelIndex} from '../../abstractitemmodel';
import {Action} from '../../action';
import {TagView} from './tagview';
import {FancyListItem} from '../../ui/list';
import {MessageBox} from '../../ui/messagebox';
import {AbstractButton} from '../../ui/abstractbutton';
import {TextInput} from '../../ui/textinput';
import {Completer} from '../../ui/completer';
import {FilterView} from './filterview';
import {Menu} from '../../ui/menu';
import {App} from '../../app';
import {EditView} from './editview';
import {ParcelTagMenu} from './parceltagmenu';
import type {FilterBox} from '../../ui/filterbox';
import {ElWin} from '../../ui/window';
import {doAreaPkStuff, Edmx, EntitySet, invokeUnboundFunction, parseCSDLDocument, parseEntitySetPath} from '../../odata';

const logger = getLogger('views.data');
const staticObjIdPrefix = 'lb.views.data.';
const filterListId = `${staticObjIdPrefix}filterlist`;
const tagViewId = `${staticObjIdPrefix}tagview`;
const editViewId = `${staticObjIdPrefix}editview`;
const ignoreSourceIds = new set<string>([
	MapSourceId.DoNotMailParcel,
	MapSourceId.DoNotMailParcelCentroid,
]);
const lyrs = mapLayers.filter(
	x => ((x.type !== 'custom') && (typeof x.source === 'string') && !ignoreSourceIds.has(x.source)),
);
const srcs = mapSources.filter(
	x => !ignoreSourceIds.has(x.id),
);

enum FilterListOption {
	Negate,
	Delete,
}

type DataViewItem = {id: string; activatedColumnFilter: FilterMdl | null; table: TableView | null; toolBarAxn: Action | null; win: Win; sysQuery: IODataSystemQuery; lastTotalObjectCount: number;};
type DialogTag = {tagK: string; tagV: string; enabled: boolean;}
type FilterViewMenu = {menu: Menu | null; listItemIndex: number; options: list<FilterListOption>}
type ParcelTagViewMenu = {menu: ParcelTagMenu | null; parcelTags: list<ParcelTag>;}

export class DataViewPrivate extends ParcelViewPrivate {
	dialog: MessageBox | null;
	dialogTag: DialogTag | null;
	editView: EditView | null;
	filterList: FilterView | null;
	filterListMenu: FilterViewMenu;
	items: Map<string, DataViewItem>;
	od: Edmx;
	parcelTagMenu: ParcelTagViewMenu;
	places: list<Place>;
	semaphore: Semaphore;
	tags: list<Tag>;
	tagView: TagView | null;
	toolBar: ToolBar | null;
	winAxnGroup: ActionGroup | null;

	constructor() {
		super();
		this.dialog = null;
		this.dialogTag = null;
		this.editView = null;
		this.filterList = null;
		this.filterListMenu = {
			listItemIndex: -1,
			menu: null,
			options: new list(),
		};
		this.items = new Map();
		this.od = new Edmx();
		this.parcelTagMenu = {
			menu: null,
			parcelTags: new list(),
		};
		this.places = new list();
		this.semaphore = new Semaphore();
		this.tags = new list();
		this.tagView = null;
		this.toolBar = null;
		this.winAxnGroup = null;
	}

	private addEntityTableView(id: string): DataViewItem | null {
		const q = this.q;
		const et = this.od.entityType(id);
		if (!et) {
			logger.warning('addEntityTableView: No EntityType for %s', id);
			return null;
		}
		const propNames = et.propertyList.map(x => x.name);
		return q.addTableView(
			et.qualifiedName(),
			propNames.toArray(),
		);
	}

	addEntityTableViewViaEntityPropertyPath(path: string): DataViewItem | null {
		return this.addEntityTableView(
			this.entityTypeQualifiedNameFromPropertyPath(path),
		);
	}

	addToolBarAction(id: string, iconName: string, toolTip?: string): Action | null {
		if (!this.toolBar || !this.winAxnGroup) {
			return null;
		}
		const axn = this.winAxnGroup.addAction(
			new Icon({
				name: iconName,
			}),
		);
		axn.setToolTip(toolTip || id);
		this.toolBar.addAction(axn);
		return axn;
	}

	destroyFilterListMenu(): void {
		if (this.filterListMenu.menu) {
			this.filterListMenu.menu.hide();
			this.filterListMenu.menu.destroy();
		}
		this.filterListMenu.menu = null;
		this.filterListMenu.listItemIndex = -1;
		this.filterListMenu.options.clear();
	}

	destroyParcelTagMenu(): void {
		if (this.parcelTagMenu.menu) {
			this.parcelTagMenu.menu.hide();
			this.parcelTagMenu.menu.destroy();
		}
		this.parcelTagMenu.menu = null;
	}

	async entityCollection(entitySetName: string, qry?: Partial<IODataSystemQuery>): Promise<IODataEntityCollection> {
		const resp = await svc.od.entityCollection(
			entitySetName,
			qry,
		);
		return resp.data;
	}

	entityKeyValue(id: string, row: number): number | string | null {
		const item = this.item(id);
		if (!item || !item.table) {
			logger.error(
				'entityKeyValue: No view item or data table for "%s".',
				id,
			);
			return null;
		}
		const et = this.od.entityType(id);
		if (!et) {
			logger.error(
				'entityKeyValue: No EntityType for "%s"',
				id,
			);
			return null;
		}
		const keyProp = et.keyProperty();
		if (!keyProp) {
			logger.error(
				'entityKeyValue: Somehow missing entity\'s key property.',
			);
			return null;
		}
		const idx = et.propertyIndex(keyProp.name);
		if (idx < 0) {
			logger.error(
				'entityKeyValue: EntityType "%s" has no key/properties.',
				id,
			);
			return null;
		}
		const tblItem = item.table.item(
			row,
			idx,
		);
		if (!tblItem) {
			logger.error(
				'entityKeyValue: No table item for (%s, %s).',
				row,
				idx,
			);
			return null;
		}
		const data = tblItem.data(ItemDataRole.EditRole);
		if (!data.isValid() || data.isNull()) {
			logger.error(
				'entityKeyValue: Invalid item data for (%s, %s)',
				row,
				idx,
			);
			return null;
		}
		switch (keyProp.type) {
			case EdmPrimitiveType.Int16:
			case EdmPrimitiveType.Int32:
			case EdmPrimitiveType.Int64: {
				return data.toNumber();
			}
			default: {
				return data.toString();
			}
		}
	}

	entityPropertyName(id: string, index: number): string {
		const et = this.od.entityType(id);
		if (!et) {
			logger.error('entityPropertyName: No EntityType for "%s"', id);
			return '';
		}
		const prop = et.property(index);
		return prop ?
			prop.name :
			'';
	}

	entityPropertyNames(id: string): Array<string> {
		const et = this.od.entityType(id);
		if (et) {
			return et.propertyList.map(x => x.name).toArray();
		}
		logger.error('entityPropertyNames: No EntityType for "%s"', id);
		return [];
	}

	async entitySetCount(entitySetName: string): Promise<number> {
		const resp = await svc.od.entitySetCount(entitySetName);
		return resp.data;
	}

	entitySetForEntityType(entityType: string): EntitySet | null {
		const et = this.od.entityType(entityType);
		return et ?
			et.entitySet() :
			null;
	}

	entityTypeQualifiedNameFromPropertyPath(path: string): string {
		const parts = path.split('.');
		return parts.slice(0, parts.length - 1).join('.');
	}

	async fetchFilters(): Promise<list<FilterMdl>> {
		return await FilterMdl.list({
			type: 'data',
		});
	}

	filtersForId(id: string): list<FilterMdl> {
		const rv = new list<FilterMdl>();
		for (const fil of this.filters) {
			const expr = fil.expression();
			if (expr && (expr.lhs === id)) {
				rv.append(fil);
			}
		}
		return rv;
	}

	firstSelectedIndex(id: string): ModelIndex {
		const lst = this.selectedIndexes(id);
		return lst.isEmpty() ?
			new ModelIndex() :
			lst.first();
	}

	async handleFlyAction(entityTypeName: string, entityKey: number | string): Promise<void> {
		const geom = await invokeUnboundFunction(
			this.od,
			ODataUnboundFunction.Geom,
			entityTypeName,
			entityKey,
		);
		const bb = (geom && geom.bbox) ?
			geom.bbox :
			do_a_bbox(geom);
		if (bb && (bb.length === 4)) {
			this.q.flyTo(bb);
		}
	}

	async handleLookupAction(referencingEntityTypeName: string, referencingEntityKey: number | string): Promise<void> {
		const assocEntPath = await invokeUnboundFunction(
			this.od,
			ODataUnboundFunction.LookupAssoc,
			referencingEntityTypeName,
			referencingEntityKey,
		);
		assert(assocEntPath && (assocEntPath.length > 0));
		const parsedEntSetPath = parseEntitySetPath(
			assocEntPath,
		);
		if (!parsedEntSetPath) {
			logger.error('handleLookupAction: Invalid entity path.');
			return;
		}
		const assocEs = this.od.entitySet(
			parsedEntSetPath.qualifiedName,
		);
		if (!assocEs) {
			logger.error('handleLookupAction: No EntitySet for associated EntityType "%s".', parsedEntSetPath.qualifiedName);
			return;
		}
		const assocEt = assocEs.entityTypeObject();
		if (!assocEt) {
			logger.error(
				'handleLookupAction: No EntityType matching "%s"',
				assocEs.entityType,
			);
			return;
		}
		const propNames = assocEt.propertyList.map(x => x.name);
		const assocItem = this.item(assocEs.entityType) || this.q.addTableView(assocEs.entityType, propNames.toArray());
		assert(assocItem.table);
		const win = assocItem.table.window();
		if (win instanceof ElWin) {
			if (!win.isVisible()) {
				win.show();
			}
			win.raise();
		}
		let skip: number | null = null;
		assocItem.table.showProgressIndicator();
		try {
			skip = await invokeUnboundFunction(
				this.od,
				ODataUnboundFunction.RecordSkip,
				assocEs.entityType,
				parsedEntSetPath.key,
				assocItem.sysQuery.top,
			);
		} finally {
			assocItem.table.hideProgressIndicator();
		}
		assert(isNumber(skip) && skip >= 0);
		assocItem.sysQuery.skip = skip;
		await this.q.updateTableView(assocItem);
		const tbl = assocItem.table;
		const sel = tbl && tbl.selectionModel();
		if (!tbl || !sel) {
			return;
		}
		const key = assocEt.keyProperty();
		if (!key) {
			logger.warning('handleLookupAction: EntityType has no key property');
			return;
		}
		const keyIdx = assocEt.propertyIndex(key.name);
		if (keyIdx < 0) {
			logger.warning('handleLookupAction: Unable to identify EntityType Key Property');
			return;
		}
		const column = keyIdx;
		const rc = tbl.rowCount();
		for (let row = 0; row < rc; ++row) {
			const item = tbl.item(row, column);
			if (!item) {
				continue;
			}
			const data = item.data(ItemDataRole.EditRole);
			if (!data.isValid() || data.isNull()) {
				continue;
			}
			const val = isNumber(parsedEntSetPath.key) ? data.toNumber() : data.toString();
			if (val === parsedEntSetPath.key) {
				tbl.setCurrentIndex(tbl.d.tableModel().index(item));
				tbl.scrollTo(item);
				break;
			}
		}
	}

	async handleTagAction(parcelPk: string): Promise<void> {
		this.destroyParcelTagMenu();
		const q = this.q;
		const parcelTags = await ParcelTag.list(parcelPk);
		if (parcelTags.isEmpty()) {
			this.showNoParcelTagsMessage();
			return;
		}
		this.parcelTagMenu.parcelTags = parcelTags;
		this.parcelTagMenu.menu = new ParcelTagMenu();
		for (const parcelTag of this.parcelTagMenu.parcelTags) {
			const relatedTag = parcelTag.tag();
			if (!relatedTag) {
				logger.warning(
					'handleTagAction: Related Tag was not found for (%s, %s, %s)',
					parcelTag.tagK(),
					parcelTag.tagV(),
					parcelTag.parcelId(),
				);
				continue;
			}
			this.parcelTagMenu.menu.addTag(
				parcelTag,
			);
		}
		Obj.connect(
			this.parcelTagMenu.menu, 'closed',
			q, 'parcelTagViewClosed',
		);
		Obj.connect(
			this.parcelTagMenu.menu, 'removeClicked',
			q, 'parcelTagViewRemoveClicked',
		);
		this.parcelTagMenu.menu.setPosition(
			App.lastCursorPos(),
		);
		this.parcelTagMenu.menu.show();
	}

	async init(opts: Partial<DataViewOpts>): Promise<void> {
		// NB: If the page seems not to be rendering the problem may lay here.
		//     It's to do with the asynchronous behaviour of this routine as
		//     opposed to its super's which is synchronous. You didn't have
		//     time to debug it so it's likely the issue will arise again.
		super.init(opts);
		this.pkBs.enabled = false;
		const q = this.q;
		this.semaphore.setParent(q);
		Obj.connect(
			this.semaphore, 'released',
			q, 'queryUpdated',
		);
		delay(async () => {
			const mdResp = await svc.od.metadataDocument();
			const od = parseCSDLDocument(mdResp.data);
			if (od) {
				this.od = od;
			}
			const svcResp = await svc.od.serviceDocument();
			for (const el of svcResp.data.value) {
				if (el.kind && (el.kind !== 'EntitySet')) {
					continue;
				}
				const es = this.od.entitySet(el.name);
				if (!es) {
					logger.error('init: No EntitySet for "%s"', el.name);
					continue;
				}
				q.addTableView(
					es.entityType,
					this.entityPropertyNames(es.entityType),
				);
			}
		});
		Obj.connect(
			q, 'filterRemoved',
			q, 'deactivateColumnFilter',
		);
		this.toolBar = new ToolBar({
			parent: q,
		});
		q.setMap(
			new ParcelMap({
				flags: InteractiveMapFlag.MapNavControlIsEnabled
					| InteractiveMapFlag.MapDrawingIsEnabled
					| InteractiveMapFlag.MapInfoControlIsEnabled
					| InteractiveMapFlag.MapLayerControlIsEnabled,
				layers: lyrs,
				parent: q,
				sources: srcs,
			}),
		);
		if (this.map) {
			this.map.load(
				q.mapConfig(),
			);
		}
		this.winAxnGroup = new ActionGroup({
			parent: q,
		});
		this.winAxnGroup.setExclusive(false);
		Obj.connect(
			this.winAxnGroup, 'triggered',
			q, 'toolBarActionGroupTriggered',
		);
		const filtersItem = this.newViewItem(filterListId);
		let tb = filtersItem.win.toolBar();
		if (tb) {
			tb.hide();
		}
		const filterViewTitle = 'Filters';
		filtersItem.win.setTitle(filterViewTitle);
		this.filterList = new FilterView({
			parent: filtersItem.win,
		});
		filtersItem.win.close();
		filtersItem.win.setHeight(250);
		filtersItem.toolBarAxn = this.addToolBarAction(
			filterListId,
			'filter_list',
			filterViewTitle,
		);
		Obj.connect(
			q, 'filterAdded',
			this.filterList, 'addFilter',
		);
		Obj.connect(
			this.filterList, 'itemActivated',
			q, 'filterListItemActivated',
		);
		Obj.connect(
			this.filterList, 'optionButtonTriggered',
			q, 'filterListOptionButtonTriggered',
		);
		const tagsItem = this.newViewItem(tagViewId);
		tb = tagsItem.win.toolBar();
		if (tb) {
			tb.hide();
		}
		const tagViewTitle = 'Tags';
		tagsItem.win.setTitle(tagViewTitle);
		this.tagView = new TagView({
			parent: tagsItem.win,
		});
		tagsItem.win.close();
		tagsItem.toolBarAxn = this.addToolBarAction(
			tagViewId,
			'label',
			tagViewTitle,
		);
		delay(async () => {
			this.tags = new list(await Tag.list());
			if (this.tags.size() > 3) {
				tagsItem.win.setHeight(430);
			}
			if (this.tagView) {
				for (const tag of this.tags) {
					this.tagView.addTag(tag);
				}
				const createView = this.tagView.createView();
				if (createView) {
					Obj.connect(
						createView, 'submitted',
						q, 'newTagSubmitted',
					);
				}
				const listView = this.tagView.listView();
				if (listView) {
					Obj.connect(
						listView, 'deleteClicked',
						q, 'tagListItemDeleteBtnClicked',
					);
					Obj.connect(
						listView, 'enabledToggled',
						q, 'tagListItemEnabledToggled',
					);
					Obj.connect(
						listView, 'itemActivated',
						q, 'tagListItemClicked',
					);
				}
			}
		});
		const editViewItem = this.newViewItem(editViewId);
		tb = editViewItem.win.toolBar();
		if (tb) {
			tb.hide();
		}
		editViewItem.win.setMinimumWidth(285);
		const editViewTitle = 'Change Form';
		editViewItem.win.setTitle(editViewTitle);
		this.editView = new EditView({
			parent: editViewItem.win,
		});
		editViewItem.win.close();
		editViewItem.toolBarAxn = this.addToolBarAction(
			editViewId,
			'edit',
			editViewTitle,
		);
		Obj.connect(
			this.editView, 'submitted',
			q, 'editViewSubmitted',
		);
		const inp = new TextInput();
		q.setTextInput(inp);
		this.toolBar.addObj(inp);
		const compl = new Completer();
		inp.setCompleter(compl);
		Obj.connect(
			compl, 'activated',
			q, 'completerActivated',
		);
		delay(() => q.setFilters());
	}

	isActivatedColumnFilter(fil: FilterMdl | null): boolean {
		const item = this.item(fil);
		return !!fil && !!item && (item.activatedColumnFilter === fil);
	}

	item(lookup: TableView | Win | FilterMdl | string | null): DataViewItem | null {
		if (!lookup) {
			return null;
		}
		if (typeof lookup === 'string') {
			const rv = this.items.get(lookup);
			if (rv) {
				return rv;
			}
			if (lookup.indexOf('.') >= 0) {
				// Maybe a property path
				const parts = lookup.split('.');
				const key = parts.slice(0, parts.length - 1).join('.');
				return this.items.get(key) || null;
			}
			return null;
		}
		if (lookup instanceof TableView) {
			for (const item of this.items.values()) {
				if (item.table === lookup) {
					return item;
				}
			}
			return null;
		}
		if (lookup instanceof FilterMdl) {
			const expr = lookup.expression();
			if (expr) {
				if (expr.lhs.indexOf('.') >= 0) {
					const et = this.od.entityType(
						this.entityTypeQualifiedNameFromPropertyPath(expr.lhs),
					);
					return (et && this.items.get(et.qualifiedName())) || null;
				}
				return this.items.get(expr.lhs) || null;
			}
			return null;
		}
		return this.items.get(lookup.id) || null;
	}

	itemId(item: DataViewItem | null): string {
		if (!item) {
			return '';
		}
		for (const [id, itm] of this.items) {
			if (item === itm) {
				return id;
			}
		}
		return '';
	}

	newFilterBoxInstance(): FilterBox {
		const rv = super.newFilterBoxInstance();
		rv.setAddFilterEnabled(false);
		return rv;
	}

	newFilterData(data: Partial<INewFilter>): Partial<INewFilter> {
		data.dataFilter = true;
		return super.newFilterData(data);
	}

	newViewItem(id: string): DataViewItem {
		const q = this.q;
		const win = new Win({
			id,
			parent: q,
		});
		const et = this.od.entityType(id);
		if (et) {
			let disableAxns: Array<AxnTyp> = [];
			if (!et.supports(FociisODataVocabTerm.TagSupport)) {
				disableAxns.push(AxnTyp.Tag);
			}
			if (!et.supports(FociisODataVocabTerm.RelatedRecordLookup)) {
				disableAxns.push(AxnTyp.Lookup);
			}
			if (!et.supports(FociisODataVocabTerm.GoToArea)) {
				disableAxns.push(AxnTyp.Fly);
			}
			for (const typ of disableAxns) {
				const axn = win.action(typ);
				if (axn) {
					axn.setEnabled(false);
					axn.setVisible(false);
				}
			}
		}
		const rv: DataViewItem = {
			activatedColumnFilter: null,
			id,
			table: null,
			toolBarAxn: null,
			lastTotalObjectCount: 0,
			win,
			sysQuery: {
				skip: 0,
				top: 10,
			},
		};
		this.items.set(id, rv);
		let comp = uiRepo.windowComponent(
			id,
		);
		if (comp) {
			win.setMinimized(
				comp.minimized,
			);
			win.setPosition(
				Math.max(
					0,
					comp.offset.x * window.innerWidth,
				),
				Math.max(
					0,
					comp.offset.y * window.innerHeight,
				),
			);
		} else {
			const pos = win.position();
			const rect = win.rect();
			comp = uiRepo.emptyNotSavedWindowComponent();
			comp.dimensions.height = rect.height;
			comp.dimensions.width = rect.width;
			comp.id = id;
			comp.offset.x = pos.x() / window.innerWidth;
			comp.offset.y = pos.y() / window.innerHeight;
		}
		comp.title = id;
		win.setTitle(comp.title);
		uiRepo.saveComponent(comp);
		Obj.connect(
			win, 'actionTriggered',
			q, 'winActionTriggered',
		);
		Obj.connect(
			win, 'windowStateChanged',
			q, 'winStateChanged',
		);
		Obj.connect(
			win, 'positionChanged',
			q, 'winStateChanged',
		);
		return rv;
	}

	openMessageBox(text: string, title: string = '', buttons: StandardButton = StandardButton.Ok, default_button: StandardButton = StandardButton.Ok, slot: string | null = 'closeDialog'): void {
		const q = this.q;
		q.closeDialog();
		this.dialog = new MessageBox({
			buttons,
			text,
			title: (title.length > 0) ?
				title :
				undefined,
		});
		if (slot) {
			this.dialog.open(
				q,
				slot,
			);
		} else {
			this.dialog.open();
		}
		this.dialog.setDefaultButton(
			default_button,
		);
	}

	placePagination(win: Win, tbl: TableView): void {
		const pg = tbl.pagination();
		if (pg) {
			const footBs = new ElObj({
				parent: win,
			});
			footBs.d.elem.appendChild(pg.d.elem);
		}
	}

	get q(): DataView {
		return <DataView>super.q;
	}

	saveWindowState(win: Win): void {
		if (win.id.length < 1) {
			logger.warning('saveWindowState: No window exists for given ID.');
			return;
		}
		const comp = uiRepo.component(win.id);
		if (!comp) {
			logger.warning('saveWindowState: No component exists for given ID.');
			return;
		}
		comp.title = win.title();
		// FIXME: Not persisting min/max state right now due to how the window
		//        currently renders its minimized state. If the window is
		//        initially rendered minimized, its children are visible.
		//        It's bad.
		const pos = win.position();
		comp.offset = {
			x: pos.x() / window.innerWidth,
			y: pos.y() / window.innerHeight,
			unit: UnitOfMeasurement.Pixel,
		};
		const rect = win.rect();
		comp.dimensions = {
			height: rect.height,
			width: rect.width,
			unit: UnitOfMeasurement.Pixel,
		};
		uiRepo.saveComponent(comp);
	}

	selectedIndexes(id: string): list<ModelIndex> {
		const viewItem = this.item(id);
		if (!viewItem || !viewItem.table) {
			return new list();
		}
		const sel = viewItem.table.selectionModel();
		if (!sel) {
			return new list();
		}
		return sel.selectedIndexes();
	}

	setColumnFilterActivated(fil: FilterMdl | null, active: boolean): void {
		const item = this.item(fil);
		if (!item) {
			// Nothing to do.
			logger.error('setColumnFilterActivated: No item for filter');
			return;
		}
		if (!active && (fil && (item.activatedColumnFilter !== fil))) {
			// Attempt to deactivate an inactive column filter
			logger.info('setColumnFilterActivated: column filter is already deactivated');
			return;
		}
		const expr = fil && fil.expression();
		if (!expr) {
			logger.info('setColumnFilterActivated: filter has no related Expression');
			return;
		}
		const lhs = expr.lhs;
		if (lhs.length < 1) {
			logger.info('setColumnFilterActivated: related Expression lhs is empty');
			return;
		}
		const rhs = expr.rhs.trim();
		if (active && (rhs.length < 1)) {
			logger.info('setColumnFilterActivated: related Expression rhs is empty');
			return;
		}
		// lhs should be a path to entity property:
		//
		// Schema.Namespace         EntityType.Name    EntityType.Property[i].Name
		//       |                                |         |
		// v----------------------------------v v----v v----------v
		// Fociis.OData.Service.Models.Listblox.Parcel.house_number
		// ^------------------------------------------------------^
		// Fully-qualified Entity Property Name (what lhs should be)
		const parts = lhs.split('.');
		const path = parts.slice(0, parts.length - 1).join('.');
		const propName = parts[parts.length - 1];
		const et = this.od.entityType(
			path,
		);
		if (!et) {
			logger.warning('setColumnFilterActivated: EntityType not found for path "%s"', path);
			return;
		}
		const propIdx = et.propertyIndex(
			propName,
		);
		if (propIdx < 0) {
			logger.warning('setColumnFilterActivated: Entity Property not found for path "%s"', lhs);
			return;
		}
		if (!item.table) {
			logger.warning('setColumnFilterActivated: Item has no table.');
			return;
		}
		item.table.setHeaderInputText(
			propIdx,
			Orientation.Horizontal,
			active ? rhs : '',
			active,
		);
		if (active) {
			item.activatedColumnFilter = fil;
			item.win.raise();
		} else if (fil && (item.activatedColumnFilter === fil)) {
			item.activatedColumnFilter = null;
		}
	}

	showNoParcelTagsMessage(): void {
		const q = this.q;
		q.closeDialog();
		this.dialog = new MessageBox({
			buttons: StandardButton.Ok,
			text: 'No parcel tags yet',
			title: 'oh noes',
		});
		this.dialog.open(
			q,
			'closeDialog',
		);
	}
}

export interface DataViewOpts extends ParcelViewOpts {
	dd: DataViewPrivate;
}

@OBJ
export class DataView extends ParcelView {
	constructor(opts: Partial<DataViewOpts> = {}) {
		opts.attributes = ParcelView.mergeAttributes(
			opts.attributes,
			['id', 'id_lb-data-view'],
		);
		opts.dd = opts.dd || new DataViewPrivate();
		super(opts);
	}

	@SLOT
	protected activateColumnFilter(fil: FilterMdl | null): void {
		this.d.setColumnFilterActivated(
			fil,
			true,
		);
	}

	@SLOT
	addFilter(filter: FilterMdl): void {
		filter.setDataFilter(true);
		super.addFilter(filter);
	}

	addTableView(id: string, headerLabels: Array<string>): DataViewItem {
		const d = this.d;
		const item = d.newViewItem(id);
		item.win.setWidth(Math.min(window.innerWidth, 680));
		item.win.setHeight(Math.min(window.innerHeight, 381));
		const tbl = new TableView({
			parent: item.win,
		});
		item.table = tbl;
		tbl.setHeaderDocked(
			Orientation.Horizontal,
			false,
		);
		tbl.setHeaderInputShown(
			Orientation.Horizontal,
			true,
		);
		d.placePagination(item.win, item.table);
		item.win.show();
		item.toolBarAxn = d.addToolBarAction(
			id,
			'table_rows',
		);
		tbl.setColumnCount(headerLabels.length);
		tbl.setHorizontalHeaderLabels(headerLabels);
		const et = d.od.entityType(id);
		const es = et && et.entitySet();
		if (et && es && es.includeInServiceDocument !== false) {
			const editOpts: Array<[string, string]> = [];
			const rwPerm = `${ODataVocabCoreTerm.Permissions}/${ODataCorePermissions.ReadWrite}`;
			// const qn = et.qualifiedName();
			for (const prop of et.propertyList) {
				for (const anno of prop.annotationList) {
					if (anno.enumMember === rwPerm) {
						editOpts.push([`${es.name}/${prop.name}`, prop.name]);
						break;
					}
				}
			}
			const combo = ((editOpts.length > 0) && d.editView) ?
				d.editView.comboBox() :
				null;
			if ((editOpts.length > 0) && combo) {
				combo.clear();
				combo.addItem('---', '');
				for (const [val, label] of editOpts) {
					combo.addItem(label, val);
				}
			}
		}
		const pg = tbl.pagination();
		if (pg) {
			pg.setHasNextPage(false);
			pg.setHasPreviousPage(false);
			pg.setSelectedPerPageOption(0);
			Obj.connect(
				pg, 'perPageChanged',
				this, 'tablePaginationPerPageChanged',
			);
			Obj.connect(
				pg, 'navigationClicked',
				this, 'tablePaginationNavigationClicked',
			);
		}
		Obj.connect(
			tbl, 'headerInputIconActivated',
			this, 'tableHeaderInputSubmitted',
		);
		Obj.connect(
			tbl, 'headerInputReturnPressed',
			this, 'tableHeaderInputSubmitted',
		);
		Obj.connect(
			tbl, 'tmpStuffChanged',
			this, 'tableSelectionChanged',
		);
		return item;
	}

	@SLOT
	closeDialog(): void {
		const d = this.d;
		if (!d.dialog) {
			return;
		}
		d.dialog.hide();
		d.dialog.destroy();
		d.dialog = null;
	}

	@SLOT
	protected async completerActivated(index: number): Promise<void> {
		const d = this.d;
		if ((index >= 0) && (index < d.places.size())) {
			this.closeCompleter();
			await this.createFiltersFromPlaces(
				await d.places.at(index).pk(),
			);
		}
	}

	get d(): DataViewPrivate {
		return <DataViewPrivate>super.d;
	}

	@SLOT
	protected deactivateColumnFilter(fil: FilterMdl | null): void {
		this.d.setColumnFilterActivated(
			fil,
			false,
		);
	}

	@SLOT
	private dialogButtonClicked(button: AbstractButton): void {
		const d = this.d;
		let role = ButtonRole.InvalidRole;
		if (d.dialog) {
			role = d.dialog.buttonRole(button);
			d.dialog.destroy();
		}
		d.dialog = null;
		const tag = d.dialogTag;
		d.dialogTag = null;
		if (!tag) {
			return;
		}
		if (role === ButtonRole.YesRole) {
			svc.d.batchCreateParcelTags(
				tag.tagK,
				tag.tagV,
				tag.enabled,
			);
		}
	}

	@SLOT
	private editViewSubmitted(): void {
		const d = this.d;
		if (!d.editView) {
			return;
		}
		const combo = d.editView.comboBox();
		if (!combo) {
			return;
		}
		const val = combo.currentValue();
		if (val === '') {
			d.dialog = new MessageBox({
				buttons: StandardButton.Ok,
			});
			d.dialog.setDefaultButton(StandardButton.Ok);
			d.dialog.setText(`There is no parcel field currently selected.`);
			Obj.connect(
				d.dialog, 'finished',
				this, 'closeDialog',
			);
			d.dialog.open();
			return;
		}
		// Fociis.OData.Model.public.parcel_set/house_number
		const parts = val.split('/');
		assert(parts.length >= 2);
		const entitySetName = parts[0];
		d.entitySetCount(entitySetName).then(ct => {
			d.dialog = new MessageBox({
				buttons: StandardButton.No | StandardButton.Yes,
			});
			d.dialog.setDefaultButton(
				StandardButton.No,
			);
			d.dialog.setText(`Modify all ${numberFormat(ct)} parcel records for current query?`);
			Obj.connect(
				d.dialog, 'accepted',
				this, 'submitEditForm',
			);
			Obj.connect(
				d.dialog, 'rejected',
				this, 'closeDialog',
			);
			d.dialog.open();
		});
	}

	@SLOT
	private filterListItemActivated(item: FancyListItem): void {
		const d = this.d;
		const idx = item.index();
		if ((idx < 0) || (idx >= d.filters.size())) {
			logger.error('filterListItemActivated: Item index out of range: %s', idx);
			return;
		}
		const fil = d.filters.at(idx);
		if (fil.geometry()) {
			// Geometry filter
			this.flyToFilter(fil);
		} else {
			// Probably a table column filter
			if (d.item(fil)) {
				this.activateColumnFilter(fil);
			} else {
				// Not populated yet. Let's change that.
				const expr = fil.expression();
				if (!expr) {
					logger.warning('filterListItemActivated: Filter has no related Expression');
					return;
				}
				if (expr.lhs.length > 0) {
					const item = d.addEntityTableViewViaEntityPropertyPath(
						expr.lhs,
					);
					assert(item);
					this.updateTableView(item).then(() => this.activateColumnFilter(fil));
				} else {
					logger.warning('filterListItemActivated: Filter Expression lhs is empty');
					this.activateColumnFilter(fil);
				}
			}
		}
	}

	@SLOT
	private filterListMenuClosed(): void {
		this.d.destroyFilterListMenu();
	}

	@SLOT
	private filterListMenuSelectionChanged(index: number): void {
		const d = this.d;
		if (!((index >= 0) && (index < d.filterListMenu.options.size()) && (d.filterListMenu.listItemIndex >= 0) && (d.filterListMenu.listItemIndex < d.filters.size()))) {
			return;
		}
		const fil = d.filters.at(
			d.filterListMenu.listItemIndex,
		);
		const opt = d.filterListMenu.options.at(
			index,
		);
		switch (opt) {
			case FilterListOption.Negate: {
				const expr = fil.expression();
				if (expr) {
					fil.setExpression({
						...expr,
						negated: !expr.negated,
					});
				} else {
					d.openMessageBox(
						'Negating a geometry filter is not supported',
						'Oops',
					);
				}
				break;
			}
			case FilterListOption.Delete: {
				this.deleteFilter(
					fil,
				);
				break;
			}
		}
		d.destroyFilterListMenu();
	}

	@SLOT
	private filterListOptionButtonTriggered(index: number) {
		if (index < 0) {
			return;
		}
		const d = this.d;
		d.destroyFilterListMenu();
		d.filterListMenu.menu = new Menu();
		Obj.connect(
			d.filterListMenu.menu, 'selectionChanged',
			this, 'filterListMenuSelectionChanged',
		);
		Obj.connect(
			d.filterListMenu.menu, 'closed',
			this, 'filterListMenuClosed',
		);
		d.filterListMenu.listItemIndex = index;
		d.filterListMenu.options.extend([
			FilterListOption.Negate,
			FilterListOption.Delete,
		]);
		for (const opt of d.filterListMenu.options) {
			d.filterListMenu.menu.addItem(
				FilterListOption[opt],
			);
		}
		d.filterListMenu.menu.setPosition(
			App.lastCursorPos(),
		);
		d.filterListMenu.menu.show();
	}

	@SLOT
	private mapLoaded(): void {
		const d = this.d;
		d.pkBs.pkFetcher = async (z: number, x: number, y: number) => {
			return await doAreaPkStuff(z, x, y);
		};
		d.pkBs.pkSetter = (pks: Iterable<AreaPk>, selected: boolean) => {
			if (d.map) {
				d.map.setFeatureState(
					pks,
					{selected},
					MapSourceId.Parcel,
					MapLayerId.ParcelSourceArea,
				);
			}
		};
		d.pkBs.enabled = true;
		this.updateQuery();
	}

	@SLOT
	protected async newTagSubmitted(): Promise<void> {
		const d = this.d;
		if (!d.tagView) {
			return;
		}
		const createView = d.tagView.createView();
		if (!createView) {
			return;
		}
		const text = createView.tag().trim();
		if (text.length < 1) {
			return;
		}
		const parts = text.split('=');
		if (parts.length !== 2) {
			logger.warning('newTagSubmitted: Invalid tag split');
			return;
		}
		let err: Error | null = null;
		let tag: Tag | null = null;
		try {
			tag = await Tag.create({
				k: parts[0],
				v: parts[1],
				enabled: createView.isEnabledChecked(),
			});
		} catch (exc) {
			err = exc;
		}
		if (err) {
			d.openMessageBox(
				(err.message.length > 0) ?
					err.message :
					'Something bad happened during that operation and that\'s all I know.',
				'Oops',
			);
		} else {
			// Sanity check
			assert(tag);
			d.tags.append(tag);
			d.tagView.addTag(tag);
			createView.reset();
		}
	}

	@SLOT
	protected parcelTagViewClosed(): void {
		this.d.destroyParcelTagMenu();
	}

	@SLOT
	private async parcelTagViewRemoveClicked(index: number): Promise<void> {
		const d = this.d;
		if ((index >= 0) && (index < d.parcelTagMenu.parcelTags.size())) {
			const pt = d.parcelTagMenu.parcelTags.takeAt(index);
			await pt.delete();
			pt.destroy();
		}
	}

	protected async setColumnFilter(entityPropName: string, text: string): Promise<void> {
		const d = this.d;
		const fils = d.filtersForId(entityPropName);
		let activatedFil: FilterMdl | null = null;
		for (const fil of fils) {
			if (d.isActivatedColumnFilter(fil)) {
				activatedFil = fil;
				break;
			}
		}
		text = text.trim();
		if (text.length < 1) {
			if (activatedFil) {
				await this.deleteFilter(activatedFil);
			}
			return;
		}
		if (activatedFil) {
			const expr = activatedFil.expression();
			const negated = expr ?
				expr.negated :
				false;
			await this.updateFilter(
				activatedFil,
				{
					expression: {
						lhs: entityPropName,
						operator: 'contains',
						rhs: text,
						negated,
					},
				});
		} else {
			activatedFil = await this.createFilter({
				dataFilter: true,
				enabled: true,
				expression: {
					lhs: entityPropName,
					operator: 'contains',
					rhs: text,
					negated: false,
				},
			});
			// We're about to deactivate this filter anyway so, instead of
			// changing the logic over in that routine to accommodate for a
			// potentially newly-created filter, we just sort of pretend this
			// filter existed the whole.
			const vi = d.item(entityPropName);
			if (vi) {
				vi.activatedColumnFilter = activatedFil;
			}
		}
		this.deactivateColumnFilter(
			activatedFil,
		);
	}

	showInfoAt(coord: GeoCoordinate, showIgnoreButton?: boolean): void {
		super.showInfoAt(
			coord,
			(showIgnoreButton === undefined) ?
				false :
				showIgnoreButton,
		);
	}

	setMap(map: ParcelMap | null): void {
		const d = this.d;
		if (map === d.map) {
			return;
		}
		if (d.map) {
			Obj.disconnect(
				d.map, 'loaded',
				this, 'mapLoaded',
			);
		}
		super.setMap(map);
		if (d.map) {
			Obj.connect(
				d.map, 'loaded',
				this, 'mapLoaded',
			);
		}
	}

	@SLOT
	protected async submitEditForm(): Promise<void> {
		const d = this.d;
		if (!d.editView) {
			return;
		}
		const combo = d.editView.comboBox();
		const edit = d.editView.lineEdit();
		if (!combo || !edit) {
			return;
		}
		const field = combo.currentValue();
		if (field === '') {
			return;
		}
		const parts = field.split('/');
		assert(parts.length >= 2);
		const entitySetName = parts[0];
		const propName = parts[parts.length - 1];
		let err: Error | null = null;
		try {
			const resp = await svc.od.updateEntitySet(
				entitySetName,
				{[propName]: edit.text().trim()},
			);
			if (isErrorResponse(resp)) {
				err = new Error(
					resp.data.error.message,
				);
			}
		} catch (exc) {
			err = exc;
		}
		if (err) {
			d.openMessageBox(
				(err.message.length > 0) ?
					err.message :
					'Something bad happened during that operation and that\'s all I know.',
				'Oops',
			);
		} else {
			await this.updateQuery();
			d.editView.clearContents();
		}
	}

	@SLOT
	protected tableHeaderInputSubmitted(section: number, orient: Orientation, text: string): void {
		const s = this.sender();
		const tbl = isTableView(s) && s;
		if (!tbl) {
			logger.warning('tableHeaderInputSubmitted: Sender is null');
			return;
		}
		const d = this.d;
		const item = d.item(tbl);
		if (!item) {
			logger.warning('tableHeaderInputSubmitted: No view item for table');
			return;
		}
		const name = d.entityPropertyName(
			item.id,
			section,
		);
		if (name.length < 1) {
			logger.warning(
				'tableHeaderInputSubmitted: No property for %s',
				item.id,
			);
			return;
		}
		this.setColumnFilter(
			`${item.id}.${name}`,
			text,
		);
	}

	@SLOT
	tablePaginationNavigationClicked(which: WhichPage): void {
		const obj = this.sender();
		let tbl: TableView | null = null;
		let curr = obj;
		while (curr) {
			if (curr instanceof TableView) {
				tbl = curr;
				break;
			}
			curr = curr.parent();
		}
		if (!tbl) {
			logger.error('tablePaginationNavigationClicked: Missing TableView');
			return;
		}
		const d = this.d;
		const item = d.item(tbl);
		if (!item) {
			logger.error('tablePaginationNavigationClicked: Missing ViewItem');
			return;
		}
		const pg = tbl.pagination();
		if (!pg) {
			logger.error('tablePaginationNavigationClicked: Missing Pagination');
			return;
		}
		let newSkip: number;
		switch (which) {
			case WhichPage.NextPage: {
				newSkip = item.sysQuery.skip + item.sysQuery.top;
				break;
			}
			case WhichPage.PrevPage: {
				newSkip = item.sysQuery.skip - item.sysQuery.top;
				break;
			}
			case WhichPage.FirstPage: {
				newSkip = 0;
				break;
			}
			case WhichPage.LastPage: {
				newSkip = item.lastTotalObjectCount - item.sysQuery.top;
				break;
			}
		}
		newSkip = Math.max(0, newSkip);
		if (newSkip !== item.sysQuery.skip) {
			item.sysQuery.skip = newSkip;
			const id = d.itemId(item);
			assert(id.length > 0);
			this.updateTableView(
				item,
			);
		}
	}

	@SLOT
	tablePaginationPerPageChanged(index: number): void {
		const obj = this.sender();
		let tbl: TableView | null = null;
		let curr = obj;
		while (curr) {
			if (curr instanceof TableView) {
				tbl = curr;
				break;
			}
			curr = curr.parent();
		}
		if (!tbl) {
			logger.error('tablePaginationPerPageChanged: Missing TableView');
			return;
		}
		const d = this.d;
		const item = d.item(tbl);
		if (!item) {
			logger.error('tablePaginationPerPageChanged: Missing ViewItem');
			return;
		}
		const pg = tbl.pagination();
		if (!pg) {
			logger.error('tablePaginationPerPageChanged: Missing Pagination');
			return;
		}
		const opt = pg.perPageOption(index);
		const num = Number.parseInt((index >= 0) ? opt : '10');
		if (!isNumber(num)) {
			logger.warning('tablePaginationPerPageChanged: Invalid per page number');
			return;
		}
		if (item.sysQuery.top !== num) {
			item.sysQuery.top = num;
			const id = d.itemId(item);
			assert(id.length > 0);
			this.updateTableView(
				item,
			);
		}
	}

	@SLOT
	protected tableSelectionChanged(table: TableView, selected: ItemSelection): void {
		const d = this.d;
		const viewItem = d.item(table);
		if (!viewItem) {
			return;
		}
		const sel = table.selectionModel();
		viewItem.win.setActionGroupEnabled(
			(!sel && selected.size() === 1) || (!!sel && sel.selection().size() === 1),
		);
	}

	@SLOT
	private async tagListItemDeleteBtnClicked(index: number): Promise<void> {
		const d = this.d;
		if ((index < 0) || (index >= d.tags.size())) {
			return;
		}
		let err: Error | null = null;
		const tag = d.tags.at(index);
		try {
			await tag.delete();
		} catch (exc) {
			err = exc;
		}
		if (err) {
			d.openMessageBox(
				(err.message.length > 0) ?
					err.message :
					'Something bad happened during that operation and that\'s all I know.',
				'Oops',
			);
		} else {
			this.takeTag(index);
			tag.destroy();
		}
	}

	@SLOT
	private tagListItemClicked(item: FancyListItem): void {
		const d = this.d;
		if (!d.tagView) {
			return;
		}
		const listView = d.tagView.listView();
		if (!listView) {
			return;
		}
		const idx = listView.index(item);
		if ((idx < 0) || (idx >= d.tags.size())) {
			return;
		}

		let es: EntitySet | null = null;
		for (const sch of d.od.dataServices.schemaList) {
			for (const ec of sch.entityContainerList) {
				for (const ees of ec.entitySetList) {
					if (ees.includeInServiceDocument !== false) {
						es = ees;
						break;
					}
				}
			}
		}
		if (!es) {
			logger.warning('tagListItemClicked: No EntitySet included in Service Document.');
			return;
		}
		d.entitySetCount(es.name).then(ct => {
			const tag = d.tags.at(idx);
			d.dialogTag = {
				tagK: tag.k(),
				tagV: tag.v(),
				enabled: true,
			};
			d.openMessageBox(
				`Tag all ${numberFormat(ct)} parcels for current query?`,
				'',
				StandardButton.No | StandardButton.Yes,
				StandardButton.No,
				'dialogButtonClicked',
			);
		});
	}

	@SLOT
	private async tagListItemEnabledToggled(index: number, enabled: boolean): Promise<void> {
		const d = this.d;
		if ((index < 0) || (index >= d.tags.size())) {
			return;
		}
		const tag = d.tags.at(index);
		await tag.setEnabled(enabled);
	}

	@SLOT
	takeTag(index: number): Tag | null {
		const d = this.d;
		if ((index < 0) || (index >= d.tags.size())) {
			return null;
		}
		if (d.tagView) {
			d.tagView.removeTag(index);
		}
		return d.tags.takeAt(index);
	}

	@SLOT
	protected async textInputTextChanged(text: string): Promise<void> {
		const d = this.d;
		if (!d.textInput) {
			return;
		}
		if (text.trim().length > 0) {
			d.places = await Place.list(text);
			const items: Array<ICompleterItem> = [];
			for (const obj of d.places) {
				items.push({
					label: await obj.label(),
					text: await obj.name(),
				});
			}
			const compl = d.textInput.completer();
			if (compl) {
				compl.setItems(items);
				compl.show();
				compl.updatePosition();
			}
		} else {
			this.closeCompleter();
		}
	}

	@SLOT
	protected toolBarActionGroupTriggered(action: Action): void {
		const d = this.d;
		for (const item of d.items.values()) {
			if (item.toolBarAxn === action) {
				const vis = !item.win.isVisible();
				item.win.setVisible(
					vis,
				);
				if (vis) {
					item.win.raise();
				}
			}
		}
	}

	@SLOT
	async updatePkBs(): Promise<void> {
		const d = this.d;
		let haveGeom = false;
		for (const fil of d.filters) {
			if (!fil.isDeleted() && fil.isEnabled() && fil.geometry()) {
				haveGeom = true;
				break;
			}
		}
		if (!haveGeom) {
			d.pkBs.unload();
			return;
		}
		return super.updatePkBs();
	}

	@SLOT
	async updateQuery(opts: Partial<{items: Map<string, DataViewItem>;}> = {}): Promise<void> {
		const d = this.d;
		const items = opts.items || d.items;
		for (const [id, item] of items) {
			item.sysQuery.skip = 0;
			setTimeout(
				() => this.updateTableView(
					item,
				),
			);
		}
	}

	async updateTableView(item: DataViewItem): Promise<void> {
		const d = this.d;
		d.semaphore.acquire();
		try {
			await this._updateTableView(
				item,
			);
		} catch (exc) {
			logger.error(
				'Exception during query update attempt: %s',
				exc,
				exc,
			);
		} finally {
			d.semaphore.release();
		}
	}

	protected async _updateTableView(item: DataViewItem, opts: Partial<IODataSystemQuery> = {}): Promise<void> {
		if (!item.table) {
			return;
		}
		const d = this.d;
		const es = d.entitySetForEntityType(
			item.id,
		);
		if (!es) {
			logger.error('_updateTableView: No EntitySet returned for %s', item.id);
			return;
		}
		item.table.showProgressIndicator();
		const objs = await d.entityCollection(
			es.qualifiedName(),
			{
				...item.sysQuery,
				...opts,
			},
		);
		item.table.setData(
			d.entityPropertyNames(item.id),
			objs,
		);
		if (objs['@odata.count'] !== undefined) {
			item.lastTotalObjectCount = objs['@odata.count'];
			if (item.sysQuery.skip >= item.lastTotalObjectCount) {
				item.sysQuery.skip = Math.max(0, item.lastTotalObjectCount - item.sysQuery.top);
			}
			const pg = item.table.pagination();
			if (pg) {
				const totalCount = objs['@odata.count'];
				const startIndex = item.sysQuery.skip;
				const endIndex = Math.min(totalCount, startIndex + item.sysQuery.top);
				pg.setTotalText(
					`${numberFormat(startIndex + 1)} - ${numberFormat(endIndex)} of ${numberFormat(totalCount)}`,
				);
				pg.setHasPreviousPage(startIndex > 0);
				pg.setHasNextPage(endIndex < totalCount);
			}
		}
		item.table.hideProgressIndicator();
	}

	@SLOT
	protected async winActionTriggered(winId: string, axnTyp: AxnTyp): Promise<void> {
		const d = this.d;
		const viewItem = d.item(winId);
		if (!viewItem) {
			logger.warning('winActionTriggered: No view item for "%s"', winId);
			return;
		}
		switch (axnTyp) {
			case AxnTyp.Tag: {
				const idx = d.firstSelectedIndex(winId);
				if (!idx.isValid()) {
					return;
				}
				const val = d.entityKeyValue(
					winId,
					idx.row(),
				);
				if (val) {
					await d.handleTagAction(
						String(val),
					);
				}
				break;
			}
			case AxnTyp.Lookup: {
				if (!viewItem.table) {
					logger.warning('winActionTriggered: View item has no table.');
					return;
				}
				const idx = d.firstSelectedIndex(winId);
				if (!idx.isValid()) {
					return;
				}
				const keyVal = d.entityKeyValue(
					winId,
					idx.row(),
				);
				if (!keyVal) {
					logger.warning('winActionTriggered: No key value for "%s"', winId);
					return;
				}
				try {
					await d.handleLookupAction(
						winId,
						keyVal,
					);
				} catch (exc) {
					logger.error('winActionTriggered: %s', exc, exc);
				}
				break;
			}
			case AxnTyp.Fly: {
				if (!viewItem.table) {
					logger.warning(
						'winActionTriggered: TableView was not found for id "%s"',
						winId,
					);
					return;
				}
				const sel = viewItem.table.selectionModel();
				if (!sel || !sel.hasSelection()) {
					logger.warning(
						'winActionTriggered: TableView has no selection model or no current selection "%s"',
						winId,
					);
					return;
				}
				const selIdx = sel.selectedIndexes();
				// Sanity check
				if (selIdx.isEmpty()) {
					logger.error(
						'winActionTriggered: Selection index list is empty during operation in which it is expected to have at least one item',
					);
					return;
				}
				const idx = selIdx.first();
				if (!idx.isValid()) {
					logger.error(
						'winActionTriggered: Top selection index is invalid.',
					);
					return;
				}
				const keyVal = d.entityKeyValue(
					winId,
					idx.row(),
				);
				if (!keyVal) {
					logger.warning('winActionTriggered: No key value for "%s"', winId);
					return;
				}
				await d.handleFlyAction(
					winId,
					keyVal,
				);
				break;
			}
		}
	}

	@SLOT
	protected winStateChanged(): void {
		const s = this.sender();
		const win = s && (s instanceof Win) && s;
		if (!win) {
			return;
		}
		this.d.saveWindowState(win);
	}
}

function isTableView(obj: any): obj is TableView {
	return !!obj && (obj instanceof TableView);
}

@OBJ
class Semaphore extends Obj {
	private count: number;

	constructor(opts: Partial<ObjOpts> = {}) {
		super(opts);
		this.count = 0;
	}

	acquire(): void {
		this.setCount(this.count + 1);
	}

	@SIGNAL
	private acquired(): void {
	}

	private countChanged(previousCount: number): void {
		if ((previousCount === 0) && (this.count > 0)) {
			this.acquired();
		} else if ((previousCount > 0) && (this.count === 0)) {
			this.released();
		}
	}

	release(): void {
		this.setCount(this.count - 1);
	}

	@SIGNAL
	private released(): void {
	}

	private setCount(count: number): void {
		if (count === this.count) {
			return;
		}
		if (count < 0) {
			logger.error('Semaphore.setCount: Value below zero: %s', count);
			return;
		}
		const prev = this.count;
		this.count = count;
		this.countChanged(prev);
	}
}
