import {
	MDCComponent,
	MDCFoundation,
	SpecificEventListener,
} from '@material/base';

interface MDCMenuDimensions {
	width: number;
	height: number;
}

export interface MDCMenuDistance {
	top: number;
	right: number;
	bottom: number;
	left: number;
}

interface MDCMenuPoint {
	x: number;
	y: number;
}

const cssClasses = {
	ANCHOR: 'mdc-menu-surface--anchor',
	ANIMATING_CLOSED: 'mdc-menu-surface--animating-closed',
	ANIMATING_OPEN: 'mdc-menu-surface--animating-open',
	FIXED: 'mdc-menu-surface--fixed',
	IS_OPEN_BELOW: 'mdc-menu-surface--is-open-below',
	OPEN: 'mdc-menu-surface--open',
	ROOT: 'mdc-menu-surface',
};
const strings = {
	CLOSED_EVENT: 'MDCMenuSurface:closed',
	CLOSING_EVENT: 'MDCMenuSurface:closing',
	OPENED_EVENT: 'MDCMenuSurface:opened',
	FOCUSABLE_ELEMENTS: [
		'button:not(:disabled)',
		'[href]:not([aria-disabled="true"])',
		'input:not(:disabled)',
		'select:not(:disabled)',
		'textarea:not(:disabled)',
		'[tabindex]:not([tabindex="-1"]):not([aria-disabled="true"])',
	].join(', '),
};
const numbers = {
	TRANSITION_OPEN_DURATION: 120,
	TRANSITION_CLOSE_DURATION: 75,
	MARGIN_TO_EDGE: 32,
	ANCHOR_TO_MENU_SURFACE_WIDTH_RATIO: 0.67,
};

enum CornerBit {
	BOTTOM = 1,
	CENTER = 2,
	RIGHT = 4,
	FLIP_RTL = 8,
}

export enum Corner {
	TOP_LEFT = 0,
	TOP_RIGHT = CornerBit.RIGHT,
	BOTTOM_LEFT = CornerBit.BOTTOM,
	BOTTOM_RIGHT = CornerBit.BOTTOM | CornerBit.RIGHT,
	TOP_START = CornerBit.FLIP_RTL,
	TOP_END = CornerBit.FLIP_RTL | CornerBit.RIGHT,
	BOTTOM_START = CornerBit.BOTTOM | CornerBit.FLIP_RTL,
	BOTTOM_END = CornerBit.BOTTOM | CornerBit.RIGHT | CornerBit.FLIP_RTL,
}

interface MDCMenuSurfaceAdapter {
	addClass(className: string): void;
	removeClass(className: string): void;
	hasClass(className: string): boolean;
	hasAnchor(): boolean;
	isElementInContainer(el: Element): boolean;
	isFocused(): boolean;
	isRtl(): boolean;
	getInnerDimensions(): MDCMenuDimensions;
	getAnchorDimensions(): ClientRect | null;
	getWindowDimensions(): MDCMenuDimensions;
	getBodyDimensions(): MDCMenuDimensions;
	getWindowScroll(): MDCMenuPoint;
	setPosition(position: Partial<MDCMenuDistance>): void;
	setMaxHeight(height: string): void;
	setTransformOrigin(origin: string): void;
	saveFocus(): void;
	restoreFocus(): void;
	notifyClose(): void;
	notifyClosing(): void;
	notifyOpen(): void;
}

interface AutoLayoutMeasurements {
	anchorSize: MDCMenuDimensions;
	bodySize: MDCMenuDimensions;
	surfaceSize: MDCMenuDimensions;
	viewportDistance: MDCMenuDistance;
	viewportSize: MDCMenuDimensions;
	windowScroll: MDCMenuPoint;
}

export class MDCMenuSurfaceFoundation extends MDCFoundation<MDCMenuSurfaceAdapter> {
	static get cssClasses() {
		return cssClasses;
	}

	static get strings() {
		return strings;
	}

	static get numbers() {
		return numbers;
	}

	static get Corner() {
		return Corner;
	}

	static get defaultAdapter(): MDCMenuSurfaceAdapter {
		return {
			addClass: () => undefined,
			removeClass: () => undefined,
			hasClass: () => false,
			hasAnchor: () => false,
			isElementInContainer: () => false,
			isFocused: () => false,
			isRtl: () => false,
			getInnerDimensions: () => ({height: 0, width: 0}),
			getAnchorDimensions: () => null,
			getWindowDimensions: () => ({height: 0, width: 0}),
			getBodyDimensions: () => ({height: 0, width: 0}),
			getWindowScroll: () => ({x: 0, y: 0}),
			setPosition: () => undefined,
			setMaxHeight: () => undefined,
			setTransformOrigin: () => undefined,
			saveFocus: () => undefined,
			restoreFocus: () => undefined,
			notifyClose: () => undefined,
			notifyOpen: () => undefined,
			notifyClosing: () => undefined,
		};
	}

	private isSurfaceOpen = false;
	private isQuickOpen = false;
	private isHoistedElement = false;
	private isFixedPosition = false;
	private openAnimationEndTimerId = 0;
	private closeAnimationEndTimerId = 0;
	private animationRequestId = 0;
	private anchorCorner: Corner = Corner.TOP_START;
	private originCorner: Corner = Corner.TOP_START;
	private readonly anchorMargin:
		MDCMenuDistance = {top: 0, right: 0, bottom: 0, left: 0};
	private readonly position: MDCMenuPoint = {x: 0, y: 0};
	private dimensions!: MDCMenuDimensions;         // assigned in open()
	private measurements!: AutoLayoutMeasurements;  // assigned in open()
	constructor(adapter?: Partial<MDCMenuSurfaceAdapter>) {
		super({...MDCMenuSurfaceFoundation.defaultAdapter, ...adapter});
	}

	init() {
		const {ROOT, OPEN} = MDCMenuSurfaceFoundation.cssClasses;
		if (!this.adapter.hasClass(ROOT)) {
			throw new Error(`${ROOT} class required in root element.`);
		}
		if (this.adapter.hasClass(OPEN)) {
			this.isSurfaceOpen = true;
		}
	}

	destroy() {
		clearTimeout(this.openAnimationEndTimerId);
		clearTimeout(this.closeAnimationEndTimerId);
		// Cancel any currently running animations.
		cancelAnimationFrame(this.animationRequestId);
	}

	setAnchorCorner(corner: Corner) {
		this.anchorCorner = corner;
	}

	flipCornerHorizontally() {
		this.originCorner = this.originCorner ^ CornerBit.RIGHT;
	}

	setAnchorMargin(margin: Partial<MDCMenuDistance>) {
		this.anchorMargin.top = margin.top || 0;
		this.anchorMargin.right = margin.right || 0;
		this.anchorMargin.bottom = margin.bottom || 0;
		this.anchorMargin.left = margin.left || 0;
	}

	/** Used to indicate if the menu-surface is hoisted to the body. */
	setIsHoisted(isHoisted: boolean) {
		this.isHoistedElement = isHoisted;
	}

	/** Used to set the menu-surface calculations based on a fixed position menu. */
	setFixedPosition(isFixedPosition: boolean) {
		this.isFixedPosition = isFixedPosition;
	}

	/** Sets the menu-surface position on the page. */
	setAbsolutePosition(x: number, y: number) {
		this.position.x = this.isFinite(x) ? x : 0;
		this.position.y = this.isFinite(y) ? y : 0;
	}

	setQuickOpen(quickOpen: boolean) {
		this.isQuickOpen = quickOpen;
	}

	isOpen() {
		return this.isSurfaceOpen;
	}

	open() {
		if (this.isSurfaceOpen) {
			return;
		}
		this.adapter.saveFocus();
		if (this.isQuickOpen) {
			this.isSurfaceOpen = true;
			this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.OPEN);
			this.dimensions = this.adapter.getInnerDimensions();
			this.autoposition();
			this.adapter.notifyOpen();
		} else {
			this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.ANIMATING_OPEN);
			this.animationRequestId = requestAnimationFrame(() => {
				this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.OPEN);
				this.dimensions = this.adapter.getInnerDimensions();
				this.autoposition();
				this.openAnimationEndTimerId = setTimeout(() => {
					this.openAnimationEndTimerId = 0;
					this.adapter.removeClass(
						MDCMenuSurfaceFoundation.cssClasses.ANIMATING_OPEN);
					this.adapter.notifyOpen();
				}, numbers.TRANSITION_OPEN_DURATION);
			});
			this.isSurfaceOpen = true;
		}
	}

	close(skipRestoreFocus = false) {
		if (!this.isSurfaceOpen) {
			return;
		}
		this.adapter.notifyClosing();
		if (this.isQuickOpen) {
			this.isSurfaceOpen = false;
			if (!skipRestoreFocus) {
				this.maybeRestoreFocus();
			}
			this.adapter.removeClass(MDCMenuSurfaceFoundation.cssClasses.OPEN);
			this.adapter.removeClass(
				MDCMenuSurfaceFoundation.cssClasses.IS_OPEN_BELOW);
			this.adapter.notifyClose();
			return;
		}
		this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.ANIMATING_CLOSED);
		requestAnimationFrame(() => {
			this.adapter.removeClass(MDCMenuSurfaceFoundation.cssClasses.OPEN);
			this.adapter.removeClass(
				MDCMenuSurfaceFoundation.cssClasses.IS_OPEN_BELOW);
			this.closeAnimationEndTimerId = setTimeout(() => {
				this.closeAnimationEndTimerId = 0;
				this.adapter.removeClass(
					MDCMenuSurfaceFoundation.cssClasses.ANIMATING_CLOSED);
				this.adapter.notifyClose();
			}, numbers.TRANSITION_CLOSE_DURATION);
		});
		this.isSurfaceOpen = false;
		if (!skipRestoreFocus) {
			this.maybeRestoreFocus();
		}
	}

	/** Handle clicks and close if not within menu-surface element. */
	handleBodyClick(evt: MouseEvent) {
		const el = evt.target as Element;
		if (this.adapter.isElementInContainer(el)) {
			return;
		}
		this.close();
	}

	/** Handle keys that close the surface. */
	handleKeydown(evt: KeyboardEvent) {
		const {keyCode, key} = evt;
		const isEscape = key === 'Escape' || keyCode === 27;
		if (isEscape) {
			this.close();
		}
	}

	private autoposition() {
		// Compute measurements for autoposition methods reuse.
		this.measurements = this.getAutoLayoutmeasurements();
		const corner = this.getoriginCorner();
		const maxMenuSurfaceHeight = this.getMenuSurfaceMaxHeight(corner);
		const verticalAlignment =
			this.hasBit(corner, CornerBit.BOTTOM) ? 'bottom' : 'top';
		let horizontalAlignment =
			this.hasBit(corner, CornerBit.RIGHT) ? 'right' : 'left';
		const horizontalOffset = this.getHorizontalOriginOffset(corner);
		const verticalOffset = this.getVerticalOriginOffset(corner);
		const {anchorSize, surfaceSize} = this.measurements;
		const position: Partial<MDCMenuDistance> = {
			[horizontalAlignment]: horizontalOffset,
			[verticalAlignment]: verticalOffset,
		};
		// Center align when anchor width is comparable or greater than menu surface, otherwise keep corner.
		if (anchorSize.width / surfaceSize.width > numbers.ANCHOR_TO_MENU_SURFACE_WIDTH_RATIO) {
			horizontalAlignment = 'center';
		}
		// If the menu-surface has been hoisted to the body, it's no longer relative to the anchor element
		if (this.isHoistedElement || this.isFixedPosition) {
			this.adjustPositionForHoistedElement(position);
		}
		this.adapter.setTransformOrigin(
			`${horizontalAlignment} ${verticalAlignment}`);
		this.adapter.setPosition(position);
		this.adapter.setMaxHeight(
			maxMenuSurfaceHeight ? maxMenuSurfaceHeight + 'px' : '');
		// If it is opened from the top then add is-open-below class
		if (!this.hasBit(corner, CornerBit.BOTTOM)) {
			this.adapter.addClass(MDCMenuSurfaceFoundation.cssClasses.IS_OPEN_BELOW);
		}
	}

	private getAutoLayoutmeasurements(): AutoLayoutMeasurements {
		let anchorRect = this.adapter.getAnchorDimensions();
		const bodySize = this.adapter.getBodyDimensions();
		const viewportSize = this.adapter.getWindowDimensions();
		const windowScroll = this.adapter.getWindowScroll();
		if (!anchorRect) {
			anchorRect = {
				top: this.position.y,
				right: this.position.x,
				bottom: this.position.y,
				left: this.position.x,
				width: 0,
				height: 0,
			};
		}
		return {
			anchorSize: anchorRect,
			bodySize,
			surfaceSize: this.dimensions,
			viewportDistance: {
				top: anchorRect.top,
				right: viewportSize.width - anchorRect.right,
				bottom: viewportSize.height - anchorRect.bottom,
				left: anchorRect.left,
			},
			viewportSize,
			windowScroll,
		};
	}

	private getoriginCorner(): Corner {
		let corner = this.originCorner;
		const {viewportDistance, anchorSize, surfaceSize} = this.measurements;
		const {MARGIN_TO_EDGE} = MDCMenuSurfaceFoundation.numbers;
		const isAnchoredToBottom = this.hasBit(this.anchorCorner, CornerBit.BOTTOM);
		let availableTop;
		let availableBottom;
		if (isAnchoredToBottom) {
			availableTop =
				viewportDistance.top - MARGIN_TO_EDGE + this.anchorMargin.bottom;
			availableBottom =
				viewportDistance.bottom - MARGIN_TO_EDGE - this.anchorMargin.bottom;
		} else {
			availableTop =
				viewportDistance.top - MARGIN_TO_EDGE + this.anchorMargin.top;
			availableBottom = viewportDistance.bottom - MARGIN_TO_EDGE +
				anchorSize.height - this.anchorMargin.top;
		}
		const isAvailableBottom = availableBottom - surfaceSize.height > 0;
		if (!isAvailableBottom && availableTop > availableBottom) {
			// Attach bottom side of surface to the anchor.
			corner = this.setBit(corner, CornerBit.BOTTOM);
		}
		const isRtl = this.adapter.isRtl();
		const isFlipRtl = this.hasBit(this.anchorCorner, CornerBit.FLIP_RTL);
		const hasRightBit = this.hasBit(this.anchorCorner, CornerBit.RIGHT) ||
			this.hasBit(corner, CornerBit.RIGHT);
		// Whether surface attached to right side of anchor element.
		let isAnchoredToRight;
		// Anchored to start
		if (isRtl && isFlipRtl) {
			isAnchoredToRight = !hasRightBit;
		} else {
			// Anchored to right
			isAnchoredToRight = hasRightBit;
		}
		let availableLeft;
		let availableRight;
		if (isAnchoredToRight) {
			availableLeft =
				viewportDistance.left + anchorSize.width + this.anchorMargin.right;
			availableRight = viewportDistance.right - this.anchorMargin.right;
		} else {
			availableLeft = viewportDistance.left + this.anchorMargin.left;
			availableRight =
				viewportDistance.right + anchorSize.width - this.anchorMargin.left;
		}
		const isAvailableLeft = availableLeft - surfaceSize.width > 0;
		const isAvailableRight = availableRight - surfaceSize.width > 0;
		const isOriginCornerAlignedToEnd =
			this.hasBit(corner, CornerBit.FLIP_RTL) &&
			this.hasBit(corner, CornerBit.RIGHT);
		if (isAvailableRight && isOriginCornerAlignedToEnd && isRtl ||
			!isAvailableLeft && isOriginCornerAlignedToEnd) {
			// Attach left side of surface to the anchor.
			corner = this.unsetBit(corner, CornerBit.RIGHT);
		} else if (
			isAvailableLeft && isAnchoredToRight && isRtl ||
			(isAvailableLeft && !isAnchoredToRight && hasRightBit) ||
			(!isAvailableRight && availableLeft >= availableRight)) {
			// Attach right side of surface to the anchor.
			corner = this.setBit(corner, CornerBit.RIGHT);
		}
		return corner;
	}

	private getMenuSurfaceMaxHeight(corner: Corner): number {
		const {viewportDistance} = this.measurements;
		let maxHeight;
		const isBottomAligned = this.hasBit(corner, CornerBit.BOTTOM);
		const isBottomAnchored = this.hasBit(this.anchorCorner, CornerBit.BOTTOM);
		const {MARGIN_TO_EDGE} = MDCMenuSurfaceFoundation.numbers;
		// When maximum height is not specified, it is handled from CSS.
		if (isBottomAligned) {
			maxHeight = viewportDistance.top + this.anchorMargin.top - MARGIN_TO_EDGE;
			if (!isBottomAnchored) {
				maxHeight += this.measurements.anchorSize.height;
			}
		} else {
			maxHeight = viewportDistance.bottom - this.anchorMargin.bottom +
				this.measurements.anchorSize.height - MARGIN_TO_EDGE;
			if (isBottomAnchored) {
				maxHeight -= this.measurements.anchorSize.height;
			}
		}
		return maxHeight;
	}

	private getHorizontalOriginOffset(corner: Corner): number {
		const {anchorSize} = this.measurements;
		// isRightAligned corresponds to using the 'right' property on the surface.
		const isRightAligned = this.hasBit(corner, CornerBit.RIGHT);
		const avoidHorizontalOverlap =
			this.hasBit(this.anchorCorner, CornerBit.RIGHT);
		if (isRightAligned) {
			const rightOffset = avoidHorizontalOverlap ?
				anchorSize.width - this.anchorMargin.left :
				this.anchorMargin.right;
			// For hoisted or fixed elements, adjust the offset by the difference
			// between viewport width and body width so when we calculate the right
			// value (`adjustPositionForHoistedElement`) based on the element
			// position, the right property is correct.
			if (this.isHoistedElement || this.isFixedPosition) {
				return rightOffset -
					(this.measurements.viewportSize.width -
						this.measurements.bodySize.width);
			}
			return rightOffset;
		}
		return avoidHorizontalOverlap ? anchorSize.width - this.anchorMargin.right :
			this.anchorMargin.left;
	}

	private getVerticalOriginOffset(corner: Corner): number {
		const {anchorSize} = this.measurements;
		const isBottomAligned = this.hasBit(corner, CornerBit.BOTTOM);
		const avoidVerticalOverlap =
			this.hasBit(this.anchorCorner, CornerBit.BOTTOM);
		let y;
		if (isBottomAligned) {
			y = avoidVerticalOverlap ? anchorSize.height - this.anchorMargin.top :
				-this.anchorMargin.bottom;
		} else {
			y = avoidVerticalOverlap ?
				(anchorSize.height + this.anchorMargin.bottom) :
				this.anchorMargin.top;
		}
		return y;
	}

	/** Calculates the offsets for positioning the menu-surface when the menu-surface has been hoisted to the body. */
	private adjustPositionForHoistedElement(position: Partial<MDCMenuDistance>) {
		const {windowScroll, viewportDistance} = this.measurements;
		const props = Object.keys(position) as Array<keyof Partial<MDCMenuDistance>>;
		for (const prop of props) {
			let value = position[prop] || 0;
			// Hoisted surfaces need to have the anchor elements location on the page added to the
			// position properties for proper alignment on the body.
			value += viewportDistance[prop];
			// Surfaces that are absolutely positioned need to have additional calculations for scroll
			// and bottom positioning.
			if (!this.isFixedPosition) {
				if (prop === 'top') {
					value += windowScroll.y;
				} else if (prop === 'bottom') {
					value -= windowScroll.y;
				} else if (prop === 'left') {
					value += windowScroll.x;
				} else { // prop === 'right'
					value -= windowScroll.x;
				}
			}
			position[prop] = value;
		}
	}

	private maybeRestoreFocus() {
		const isRootFocused = this.adapter.isFocused();
		const childHasFocus = document.activeElement &&
			this.adapter.isElementInContainer(document.activeElement);
		if (isRootFocused || childHasFocus) {
			this.adapter.restoreFocus();
		}
	}

	private hasBit(corner: Corner, bit: CornerBit): boolean {
		return Boolean(corner & bit);
	}

	private setBit(corner: Corner, bit: CornerBit): Corner {
		return corner | bit;
	}

	private unsetBit(corner: Corner, bit: CornerBit): Corner {
		return corner ^ bit;
	}

	private isFinite(num: number): boolean {
		return typeof num === 'number' && isFinite(num);
	}
}

type RegisterFunction = () => void;
export type MDCMenuSurfaceFactory = (el: Element, foundation?: MDCMenuSurfaceFoundation) => MDCMenuSurface;

export class MDCMenuSurface extends MDCComponent<MDCMenuSurfaceFoundation> {
	static attachTo(root: Element): MDCMenuSurface {
		return new MDCMenuSurface(root);
	}

	anchorElement!: Element | null; // assigned in initialSyncWithDOM()
	private previousFocus?: HTMLElement | SVGElement | null;
	private handleKeydown!:
		SpecificEventListener<'keydown'>;  // assigned in initialSyncWithDOM()
	private handleBodyClick!:
		SpecificEventListener<'click'>;  // assigned in initialSyncWithDOM()
	private registerBodyClickListener!:
		RegisterFunction;  // assigned in initialSyncWithDOM()
	private deregisterBodyClickListener!:
		RegisterFunction;  // assigned in initialSyncWithDOM()
	initialSyncWithDOM() {
		const parentEl = this.root.parentElement;
		this.anchorElement = parentEl && parentEl.classList.contains(cssClasses.ANCHOR) ? parentEl : null;
		if (this.root.classList.contains(cssClasses.FIXED)) {
			this.setFixedPosition(true);
		}
		this.handleKeydown = (event) => {
			this.foundation.handleKeydown(event);
		};
		this.handleBodyClick = (event) => {
			this.foundation.handleBodyClick(event);
		};
		// capture so that no race between handleBodyClick and quickOpen when
		// menusurface opened on button click which registers this listener
		this.registerBodyClickListener = () => {
			document.body.addEventListener(
				'click', this.handleBodyClick, {capture: true});
		};
		this.deregisterBodyClickListener = () => {
			document.body.removeEventListener(
				'click', this.handleBodyClick, {capture: true});
		};
		this.listen('keydown', this.handleKeydown);
		this.listen(strings.OPENED_EVENT, this.registerBodyClickListener);
		this.listen(strings.CLOSED_EVENT, this.deregisterBodyClickListener);
	}

	destroy() {
		this.unlisten('keydown', this.handleKeydown);
		this.unlisten(strings.OPENED_EVENT, this.registerBodyClickListener);
		this.unlisten(strings.CLOSED_EVENT, this.deregisterBodyClickListener);
		super.destroy();
	}

	isOpen(): boolean {
		return this.foundation.isOpen();
	}

	open() {
		this.foundation.open();
	}

	close(skipRestoreFocus = false) {
		this.foundation.close(skipRestoreFocus);
	}

	set quickOpen(quickOpen: boolean) {
		this.foundation.setQuickOpen(quickOpen);
	}

	/** Sets the foundation to use page offsets for an positioning when the menu is hoisted to the body. */
	setIsHoisted(isHoisted: boolean) {
		this.foundation.setIsHoisted(isHoisted);
	}

	/** Sets the element that the menu-surface is anchored to. */
	setMenuSurfaceAnchorElement(element: Element) {
		this.anchorElement = element;
	}

	/** Sets the menu-surface to position: fixed. */
	setFixedPosition(isFixed: boolean) {
		if (isFixed) {
			this.root.classList.add(cssClasses.FIXED);
		} else {
			this.root.classList.remove(cssClasses.FIXED);
		}
		this.foundation.setFixedPosition(isFixed);
	}

	/** Sets the absolute x/y position to position based on. Requires the menu to be hoisted. */
	setAbsolutePosition(x: number, y: number) {
		this.foundation.setAbsolutePosition(x, y);
		this.setIsHoisted(true);
	}

	setAnchorCorner(corner: Corner) {
		this.foundation.setAnchorCorner(corner);
	}

	setAnchorMargin(margin: Partial<MDCMenuDistance>) {
		this.foundation.setAnchorMargin(margin);
	}

	getDefaultFoundation() {
		// DO NOT INLINE this variable. For backward compatibility, foundations take a Partial<MDCFooAdapter>.
		// To ensure we don't accidentally omit any methods, we need a separate, strongly typed adapter variable.
		const adapter: MDCMenuSurfaceAdapter = {
			addClass: (className) => this.root.classList.add(className),
			removeClass: (className) => this.root.classList.remove(className),
			hasClass: (className) => this.root.classList.contains(className),
			hasAnchor: () => !!this.anchorElement,
			notifyClose: () =>
				this.emit(MDCMenuSurfaceFoundation.strings.CLOSED_EVENT, {}),
			notifyClosing: () => {
				this.emit(MDCMenuSurfaceFoundation.strings.CLOSING_EVENT, {});
			},
			notifyOpen: () =>
				this.emit(MDCMenuSurfaceFoundation.strings.OPENED_EVENT, {}),
			isElementInContainer: (el) => this.root.contains(el),
			isRtl: () =>
				getComputedStyle(this.root).getPropertyValue('direction') === 'rtl',
			setTransformOrigin: (origin) => {
				const propertyName =
					`${getCorrectPropertyName(window, 'transform')}-origin`;
				(this.root as HTMLElement).style.setProperty(propertyName, origin);
			},
			isFocused: () => document.activeElement === this.root,
			saveFocus: () => {
				this.previousFocus =
					document.activeElement as HTMLElement | SVGElement | null;
			},
			restoreFocus: () => {
				if (this.root.contains(document.activeElement)) {
					if (this.previousFocus && this.previousFocus.focus) {
						this.previousFocus.focus();
					}
				}
			},
			getInnerDimensions: () => {
				return {
					width: (this.root as HTMLElement).offsetWidth,
					height: (this.root as HTMLElement).offsetHeight,
				};
			},
			getAnchorDimensions: () => this.anchorElement ?
				this.anchorElement.getBoundingClientRect() :
				null,
			getWindowDimensions: () => {
				return {width: window.innerWidth, height: window.innerHeight};
			},
			getBodyDimensions: () => {
				return {width: document.body.clientWidth, height: document.body.clientHeight};
			},
			getWindowScroll: () => {
				return {x: window.pageXOffset, y: window.pageYOffset};
			},
			setPosition: (position) => {
				const rootHTML = this.root as HTMLElement;
				rootHTML.style.left = 'left' in position ? `${position.left}px` : '';
				rootHTML.style.right = 'right' in position ? `${position.right}px` : '';
				rootHTML.style.top = 'top' in position ? `${position.top}px` : '';
				rootHTML.style.bottom =
					'bottom' in position ? `${position.bottom}px` : '';
			},
			setMaxHeight: (height) => {
				(this.root as HTMLElement).style.maxHeight = height;
			},
		};
		return new MDCMenuSurfaceFoundation(adapter);
	}
}

interface CssVendorProperty {
	prefixed: PrefixedCssPropertyName;
	standard: StandardCssPropertyName;
}

type CssVendorPropertyMap = { [K in StandardCssPropertyName]: CssVendorProperty };
type PrefixedCssPropertyName =
	'-webkit-animation' | '-webkit-transform' | '-webkit-transition';
type StandardCssPropertyName =
	'animation' | 'transform' | 'transition';
const cssPropertyNameMap: CssVendorPropertyMap = {
	animation: {
		prefixed: '-webkit-animation',
		standard: 'animation',
	},
	transform: {
		prefixed: '-webkit-transform',
		standard: 'transform',
	},
	transition: {
		prefixed: '-webkit-transition',
		standard: 'transition',
	},
};

function getCorrectPropertyName(windowObj: Window, cssProperty: StandardCssPropertyName):
	StandardCssPropertyName | PrefixedCssPropertyName {
	if (isWindow(windowObj) && cssProperty in cssPropertyNameMap) {
		const el = windowObj.document.createElement('div');
		const {standard, prefixed} = cssPropertyNameMap[cssProperty];
		const isStandard = standard in el.style;
		return isStandard ? standard : prefixed;
	}
	return cssProperty;
}

function isWindow(windowObj: Window): boolean {
	return Boolean(windowObj.document) && typeof windowObj.document.createElement === 'function';
}
